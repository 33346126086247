/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// custom styles for the NotificationItem
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import ArgonAlert from "components/ArgonAlert";
import AlertsApi from "api/alertsApi";
import { convertTimeStamp, timeAgo } from "services/dateUtils";
function NotificationItem({ userId, alertId, image, title, createdDate }) {
  const alertsApi = AlertsApi();
  const handleAlertDismissed = async (userId, alertId) => {
    try {
      const response = await alertsApi.dismissAlert(alertId);
      console.log("Alert dismissed:", response);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ArgonAlert
      color="secondary"
      dismissible
      onDismiss={() => handleAlertDismissed(userId, alertId)}
    >
      <ArgonBox>
        <ArgonTypography
          variant="button"
          color="white"
          textTransform="capitalize"
          fontWeight="regular"
        >
          <strong>{title[0]}</strong>
        </ArgonTypography>
        <ArgonTypography
          color="white"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            wordWrap: "break-word",
          }}
          variant="button"
          fontWeight="regular"
        >
          {title[1]}
        </ArgonTypography>
        <ArgonTypography
          color="white"
          variant="caption"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
          }}
        >
          <ArgonTypography variant="button" color="white">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </ArgonTypography>
          {timeAgo(createdDate)}
        </ArgonTypography>
      </ArgonBox>
    </ArgonAlert>
  );
}

// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
