/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// General page components
import PaymentItem from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/SubscriptionDetailsCard/components/RecentSubscriptionInvoicesItem";

function RecentSubscriptionInvoices({ invoices, setInvoices }) {
  return (
    <Card>
      <Divider />
      <ArgonBox pb={2} px={2}>
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          {/* Loop through invoices */}
        </ArgonBox>
      </ArgonBox>
      {invoices?.map((invoice) => {
        return (
          <ArgonBox pb={2}>
            <PaymentItem invoice={invoice} />
          </ArgonBox>
        );
      })}
    </Card>
  );
}

export default RecentSubscriptionInvoices;
