import BookingsApi from "api/bookingsApi";

import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import Swal from "sweetalert2";
import convertToOptions from "services/convertooptions";
import ArgonAlert from "components/ArgonAlert";
import { showDeleteConfirmation } from "services/confirmDelete";
import HostHiveCaption from "components/HostHiveCaption";
import useMediaQuery from "@mui/material/useMediaQuery";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import { check } from "prettier";
import HostHiveAlert from "components/HostHiveAlert";
import GuestBookApi from "api/guestBookApi";
import PropertyIntegrationSchema from "schemas/propertyIntegrationSettingsSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import HostHiveDialog from "components/HostHiveDialog";

import CompaniesApi from "api/companyApi";
import ChecklistsApi from "api/checkListsApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import ApplicationApi from "api/applicationApi";
import ContactSupportApi from "api/contactSupportApi";
import { set } from "lodash";
import DisplayEntry from "../../DisplayEntry";
function IntegrationItem({
  integration,
  position,
  propertyId,
  companyId,
  triggerIntegrationFetch,
  setTriggerIntegrationFetch,
  setAvailableIntegrations,
  availableIntegrations,
}) {
  const settingsApi = SettingsApi();
  const bookingsApi = BookingsApi();
  const [integrationEnabled, setIntegrationEnabled] = useState(
    integration?.integrationData?.syncEnabled || false
  );
  const [appointmentIntegrationCounts, setAppointmentIntegrationCounts] =
    useState(0);

  const { t } = useTranslation("common");
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);
  const guestBookApi = GuestBookApi();
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedIntegrationType, setSelectedIntegrationType] = useState({
    id: integration?.id || "",
    name: integration?.name || "",
  });
  const [integrationUrl, setIntegrationUrl] = useState(
    integration?.integrationData?.integrationUrl || ""
  );
  const handleCloseIntegrationModal = () => {
    setIntegrationModalVisible(false);
  };
  const handdleIntegrationModalSelected = (integration) => {};

  const handleIntegrationModalSave = async () => {
    console.log("Integration Modal Save");
  };

  const handleEditIntegration = () => {
    setIntegrationModalVisible(true);
  };

  const [newIntegrationUrl, setNewIntegrationUrl] = useState("");
  const [newIntegrationModalVisible, setNewIntegrationModalVisible] =
    useState(false);
  const handleCreateNewIntegration = () => {
    console.log("Create New Integration");
    setSelectedIntegrationType({
      id: "",
      name: "",
    });
    setNewIntegrationModalVisible(true);
  };

  const handleCloseNewIntegrationModal = () => {
    setNewIntegrationModalVisible(false);
  };

  const showClearAlert = (title, message, action) => {
    Swal.fire(title, message, action);
  };

  // integrationTypes are availableIntegrationTypes where disabled is false
  const integrationTypes = availableIntegrations.filter(
    (integration) => integration.disabled === false
  );

  const existingIntegrationTypes = availableIntegrations.filter(
    (integration) => integration.disabled === true
  );

  useEffect(() => {
    async function fetchAppointmentIntegrationCounts() {
      try {
        const queryParams = {
          propertyId: propertyId,
          isIntegration: true,
          integrationTypeId: integration.id,
        };
        const response = await bookingsApi.getEventsToClear(queryParams);
        // console.log("INT response", response);
        setAppointmentIntegrationCounts(response.data.count);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAppointmentIntegrationCounts();
  }, []);

  useEffect(() => {
    console.log(integrationTypes);

    if (newIntegrationUrl.length > 0) {
      // Check if the new integration URL matches any of the integration types
      const matchedIntegration = integrationTypes.find(
        (integration) => newIntegrationUrl.includes(integration.urlIdentifier) // Assuming each integration type has a unique URL identifier
      );

      if (matchedIntegration) {
        setSelectedIntegrationType(matchedIntegration);
      } else if (existingIntegrationTypes.length > 0) {
        setSelectedIntegrationType({
          id: null,
          name: "Unavailable - Integration Type is already in use.",
        });
      } else {
        console.log("No Integration Type Found");
      }
    }
  }, [
    newIntegrationUrl,
    integrationTypes,
    existingIntegrationTypes,
    setSelectedIntegrationType,
  ]);
  const [showTestUrlAlert, setShowTestUrlAlert] = useState(false);
  const [testUrlAlertMessage, setTestUrlAlertMessage] = useState(null);
  const [testUrlAlertColor, setTestUrlAlertColor] = useState("success");

  const confirmUserAction = async () => {
    try {
      await showDeleteConfirmation(
        `Are you sure you want to clear all ${appointmentIntegrationCounts} appointments?`,
        "Clear Appointments",
        "Yes, clear all appointments"
      );
      console.log(`User confirmed deletion!`);
      setAppointmentIntegrationCounts(0);
      return true;
    } catch (response) {
      console.log("User cancelled deletion!");
      return false;
    }
  };
  const handleTestIcalUrl = async (submission = false) => {
    setShowTestUrlAlert(false);
    setTestUrlAlertMessage(null);
    setTestUrlAlertColor("success");
    if (integrationUrl === null || integrationUrl === undefined) {
      setTestUrlAlertMessage(
        "Integration URL cannot be empty or uknown, please input a value and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
    }
    let integrationUrlData = {
      url: integrationUrl,
    };
    try {
      const response = await guestBookApi.testIcalUrl(integrationUrlData);
      if (response.status >= 200 && response.status < 300) {
        if (submission === true) {
          return true;
        }
        setTestUrlAlertMessage("Integration URL is valid!");
        setTestUrlAlertColor("success");
        setShowTestUrlAlert(true);
      }
    } catch (error) {
      setTestUrlAlertMessage(
        "Integration URL is invalid, please check the URL and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
      return false;
    }
  };
  const handleDeleteIntegration = async () => {
    try {
      await settingsApi.deleteIntegrationSetting(
        integration.integrationData.id
      );
      setTriggerIntegrationFetch(!triggerIntegrationFetch);
      handleCloseIntegrationModal();
    } catch (error) {
      console.log("Error Deleting Integration", error);
    }
  };

  const updatePropertyIntegrationEnabled = async (e) => {
    setIntegrationEnabled(!integrationEnabled);

    try {
      await settingsApi.updatePropertyIntegrationEnabled(
        integration.integrationData.id,
        e.target.checked
      );
    } catch (error) {
      console.error("Error updating integration enabled status", error);
    }
  };

  const getBookingsToClear = async () => {
    try {
      const response = await bookingsApi.getIntegrationBookings(
        propertyId,
        integration.id
      );
      console.log("response", response);
      const deleteConfirmation = await confirmUserAction();
      if (deleteConfirmation) {
        console.log("Delete bookings");
        let payload = {
          companyId: companyId,
          payload: response.data.bookingIds,
        };
        if (response.data.count === 0) {
          console.log("Count of ids", response.data.count);
          showClearAlert("Error", "No appointments to clear", "error");
          return;
        }
        try {
          const deleteResponse = await bookingsApi.clearIntegrationBookings(
            propertyId,
            integration.id,
            payload
          );
          if (deleteResponse.status >= 200) {
            showClearAlert(
              "Success",
              "Clearing service appointments",
              "success"
            );
          } else {
            showClearAlert(
              "Error",
              "Error clearing service appointments",
              "error"
            );
          }
        } catch (error) {}
      } else {
        console.log("Do not delete");
      }
    } catch (error) {
      console.log("error", error);
      console.log("User cancelled deletion!");
    }
  };

  return (
    <>
      <HostHiveDialog
        onDelete={handleDeleteIntegration}
        includeDelete={false}
        includeClose={true}
        includeSave={false}
        open={integrationModalVisible}
        onClose={() => {
          handleCloseIntegrationModal();
        }}
        onSave={handleIntegrationModalSave}
        title="Integration Details"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        maxWidth="sm"
        dialogObjects={
          <>
            {showTestUrlAlert ? (
              <ArgonAlert
                color={testUrlAlertColor}
                dismissible
                onDismiss={() => {
                  setTestUrlAlertMessage(null);
                  setShowTestUrlAlert(false);
                }}
              >
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {testUrlAlertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                  }}
                >
                  <ArgonBox>
                    {/* <DisplayEntry
                    entryKey={selectedIntegration?.name}
                    // lookupKey="servicePartner"
                  /> */}

                    <ArgonTypography variant="caption" fontWeight="bold">
                      Enabled
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox>
                    <Switch
                      checked={integrationEnabled}
                      onChange={(e) => updatePropertyIntegrationEnabled(e)}
                    />
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonInput
                  fullWidth
                  value={integrationUrl}
                  onChange={(e) => setIntegrationUrl(e.target.value)}
                  multiline
                  disabled={true}
                  // disabled
                />
              </Grid>
              <Grid item xs={12}>
                <ArgonTypography variant="caption" fontWeight="bold">
                  {selectedIntegrationType?.name}
                </ArgonTypography>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ArgonButton
                    //   disabled={settingsDisabled}
                    variant="gradient"
                    color="success"
                    onClick={handleTestIcalUrl}
                  >
                    {t("buttons.test")}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <ArgonBox>
                  <ArgonBox>
                    <DisplayEntry
                      entryKey={`Clear integration Appointments (${appointmentIntegrationCounts})`}
                      // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                      // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                      lookupKey="clearIntegrationAppointments"
                    />
                  </ArgonBox>

                  <ArgonBox height="100%">
                    <ArgonButton
                      disabled={appointmentIntegrationCounts === 0}
                      variant="outlined"
                      color="error"
                      onClick={getBookingsToClear}
                      sx={{
                        ml: "auto",
                        py: 1.25,
                        pr: 1.5,
                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                      startIcon={
                        <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                          close
                        </Icon>
                      }
                    >
                      Delete Forever
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
            </Grid>
          </>
        }
      />

      <Grid item xs={12}>
        <ArgonBox
          display="flex"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "1rem",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <ArgonBox>
            <ArgonTypography
              component="label"
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {integration.name}
            </ArgonTypography>
            <ArgonBox>
              <ArgonTypography></ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          {integration?.disabled === true ? (
            <ArgonBox
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                alignContent: "center",
              }}
              onClick={() => handleEditIntegration(integration)}
            >
              <ArgonBox>
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  edit
                </Icon>
              </ArgonBox>
              <ArgonBox>
                <ArgonTypography fontSize="12">Edit</ArgonTypography>
              </ArgonBox>
              {/* outlined add icon */}
            </ArgonBox>
          ) : (
            <ArgonBox
              onClick={() => handdleIntegrationModalSelected(integration)}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                add_outline
              </Icon>
              {/* outlined add icon */}
            </ArgonBox>
          )}
        </ArgonBox>
      </Grid>
    </>
  );
}
export default IntegrationItem;
