import * as Yup from "yup";

const PropertyIntegrationSchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  defaultChecklist: Yup.string().nullable(true),
  defaultServiceName: Yup.string().required("Service name is required"),
  defaultServiceProvider: Yup.string().nullable(true),
  integrationType: Yup.string().required("Integration type is required"),
  integrationUrl: Yup.string().required("Integration url is required"),
  companyId: Yup.string().required("CompanyId is required"),
  createdBy: Yup.string(),
  created: Yup.string(),
  lastModifiedBy: Yup.string(),
  lastModifiedDate: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).nullable(false),
});

export default PropertyIntegrationSchema;
