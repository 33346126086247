/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import InventoryApi from "api/inventoryApi";
import ArgonAlert from "components/ArgonAlert";

import { useNavigate } from "react-router-dom";
import ViewInventoryItem from "layouts/administration/inventory/components/ViewInventoryItem";
import { useTranslation } from "react-i18next";
function InventoryItemPage({}) {
  const { inventoryItemId } = useParams();
  const { t } = useTranslation("common");
  const [inventoryItem, setInventoryItem] = useState(null);
  const inventoryApi = new InventoryApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(false);
  useEffect(() => {
    async function fetchInventoryItem() {
      setIsLoading(true);
      console.log("inventoryItemId", inventoryItemId);
      const queryParams = {
        includeUsages: true,
        includeReplenishments: true,
      };
      try {
        const response = await inventoryApi.getInventoryItem(
          inventoryItemId,
          queryParams
        );
        console.log("response", response);
        setInventoryItem(response.data);
      } catch (error) {
        console.log("error", error);
        setAlertColor("error");
        setAlertMessage(`Error fetching inventory item: ${error.message}`);
        setShowAlert(true);
      }
      setIsLoading(false);
    }
    if (inventoryItemId) {
      fetchInventoryItem();
    }
  }, [triggerFetch]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={1} mb={20}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              {showAlert ? (
                <ArgonAlert color={alertColor} dismissible>
                  <ArgonBox display="flex" justifyContent="flex-end">
                    <ArgonBox>
                      <ArgonTypography variant="caption" color="white">
                        {alertMessage}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonAlert>
              ) : null}
              <ArgonBox p={2}>
                <ArgonBox>
                  {inventoryItem ? (
                    <>
                      <ViewInventoryItem
                        inventoryItem={inventoryItem}
                        fetchInventoryItems={() =>
                          setTriggerFetch(!triggerFetch)
                        }
                      />
                    </>
                  ) : (
                    <HostHiveLoading
                      message={t("components.inventory.loading.title")}
                      elipse={true}
                    />
                  )}
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default InventoryItemPage;
