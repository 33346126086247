/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveAlert from "components/HostHiveAlert";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/CompanySettingsDataCard";
function Header({}) {
  const [visible, setVisible] = useState(true);
  const [companySettingsDialogOpen, setCompanySettingsDialogOpen] =
    useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const handleSetVisible = () => setVisible(!visible);
  const handleCompanySettingsSubmitClicked = () => {
    console.log("handleCompanySettingsSubmitClicked");
  };
  return (
    <Card
      id="profile"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={companySettingsDialogOpen}
        onClose={() => {
          setCompanySettingsDialogOpen(false);
        }}
        onSave={handleCompanySettingsSubmitClicked}
        title="Company Settings"
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <SettingsDataCard
            title="Company Settings"
            companyDetails={company}
            handleClose={() => {
              setCompanySettingsDialogOpen(false);
            }}
          />
        }
      />
      <ArgonBox p={2}>
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            {/* <ArgonAvatar
              src={burceMars}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
            /> */}
          </Grid>
          <Grid item>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                {company?.name}
              </ArgonTypography>
              {/* <ArgonTypography
                variant="button"
                color="text"
                fontWeight="medium"
              >
                CEO / Co-Founder
              </ArgonTypography> */}
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3} sx={{ ml: "auto" }}>
            {/* <ArgonBox
              display="flex"
              justifyContent={{ md: "flex-end" }}
              alignItems="center"
              lineHeight={1}
            >
              <ArgonTypography variant="caption" fontWeight="regular">
                Switch to {visible ? "invisible" : "visible"}
              </ArgonTypography>
              <ArgonBox mx={1}>
                <Switch checked={visible} onChange={handleSetVisible} />
              </ArgonBox>
            </ArgonBox> */}
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

export default Header;
