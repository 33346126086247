/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DefaultPricingCard from "layouts/pages/pricing-page/components/DefaultPricingCard";

function PricingCards({ prices, currentPlan }) {
  const [starter, premium] = prices;
  return (
    <ArgonBox position="relative" zIndex={10} mt={-18} px={{ xs: 1, sm: 0 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} lg={6}>
          <DefaultPricingCard
            title="free"
            subText="Free Forever"
            price={{ currency: "$", value: starter }}
            specifications={[
              { label: "Automatic Appointment Scheduling", includes: true },
              { label: "Property Photos, notes & documents", includes: true },
              { label: "Multi language", includes: true },
              { label: "Cleaner notification", includes: true },
              { label: "Appointment Service Reports", includes: false },
              { label: "Service Report Checklists", includes: false },
              { label: "Finance Integration & analytics", includes: false },
              { label: "Inventory Management", includes: false },
              { label: "Property Tasks", includes: false },
              { label: "Time Tracking", includes: false },
              { label: "Unlimited users", includes: false },
            ]}
            action={{
              type: "internal",
              subscriptionType: "free",
              route: "/",
              color: "dark",
              disabled:
                currentPlan === null
                  ? false
                  : currentPlan === "free"
                  ? true
                  : false,
              label:
                currentPlan === null
                  ? "Select"
                  : currentPlan === "free"
                  ? "Current Plan"
                  : "Downgrade",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DefaultPricingCard
            title="Per Property"
            subText="30 day free trial"
            price={{ currency: "$", value: premium }}
            specifications={[
              { label: "Automatic Appointment Scheduling", includes: true },
              { label: "Property Photos, notes & documents", includes: true },
              { label: "Multi language", includes: true },
              { label: "Cleaner notification", includes: true },
              { label: "Appointment Service Reports", includes: true },
              { label: "Service Report Checklists", includes: true },
              { label: "Finance Integration & analytics", includes: true },
              { label: "Inventory Management", includes: true },
              { label: "Property Tasks", includes: true },
              { label: "Time Tracking", includes: true },
              { label: "Unlimited users", includes: true },
            ]}
            action={{
              type: "internal",
              subscriptionType: "paid",
              route: "/",
              color: "info",
              label:
                currentPlan === null
                  ? "Select"
                  : currentPlan === "paid"
                  ? "Current Plan"
                  : "Upgrade",
              disabled:
                currentPlan === null
                  ? false
                  : currentPlan === "paid"
                  ? true
                  : false,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} lg={4}>
          <DefaultPricingCard
            title="enterprise"
            price={{ currency: "$", value: enterprise }}
            specifications={[
              { label: "Unlimited team members", includes: true },
              { label: "100GB Cloud storage", includes: true },
              { label: "Integration help", includes: true },
              { label: "Sketch Files", includes: true },
              { label: "API Access", includes: true },
              { label: "Complete documentation", includes: true },
            ]}
            action={{
              type: "internal",
              route: "/",
              color: "dark",
              label: "Join",
            }}
          />
        </Grid> */}
      </Grid>
    </ArgonBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
