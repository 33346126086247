/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import FinanaceReport from "layouts/administration/accounting/components/FinanaceReport";
import useMediaQuery from "@mui/material/useMediaQuery";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import IntegratedTransactions from "layouts/administration/accounting/components/IntegratedTransactions";
import ArgonDatePicker from "components/ArgonDatePicker";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "layouts/administration/accounting/overview/components/DefaultStatisticsCard";
import DefaultLineChart from "layouts/administration/accounting/overview/components/DefaultLineChart";
import HorizontalBarChart from "layouts/administration/accounting/overview/components/HorizontalBarChart";
import PropertySpendTable from "layouts/administration/accounting/overview/components/PropertySpendTable";

import ComplexTransactionCard from "layouts/administration/accounting/overview/components/ComplexTransactionCard";
import HostHiveDataTable from "components/HostHiveDataTable";
// Overview page components
import SpendingCategories from "layouts/administration/accounting/overview/components/SpendingCategories";
import NewTransactionItem from "layouts/administration/accounting/components/NewTransactionItem";
// Data
import defaultLineChartData from "layouts/administration/accounting/overview/data/defaultLineChartData";
import horizontalBarChartData from "layouts/administration/accounting/overview/data/horizontalBarChartData";
import HostHiveDialog from "components/HostHiveDialog";
import AccountingApi from "api/accountingApi";
import { generateColumns } from "layouts/administration/accounting/overview/components/transactionsColumns";
import HostHiveLoading from "components/HostHiveLoading";
import PropertySpend from "layouts/administration/accounting/overview/components/PropertySpend";
import FilterCard from "./components/FilterCard";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
function Transactions({ propertyId = null }) {
  const { t } = useTranslation("common");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const accountingApi = AccountingApi();
  const [generateReportOpen, setGenerateReportOpen] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [property, setProperty] = useState(null);
  const [loadingPendingApprovalSize, setLoadingPendingApprovalSize] =
    useState(false);
  const [fetchTransactions, setFetchTransactions] = useState(false);
  const triggerFetch = () => setFetchTransactions(!fetchTransactions);
  const transactionColumns = generateColumns({
    triggerFetch,
  });
  const [reportQueryParams, setReportQueryParams] = useState(null);
  const [transactionsTableData, setTransactionsTableData] = useState({
    columns: transactionColumns,
    rows: [
      {
        id: "",
        summary: "",
        description: "",
        created: "",
        amount: "",
        companyId: "",
      },
    ],
  });
  const [pendingApprovalLength, setPendingApprovalLength] = useState(0);
  const [loadingTransactionData, setLoadingTransactionData] = useState(false);
  const [sendingReport, setSendingReport] = useState(false);
  const [dataCardInfo, setDataCardInfo] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [stringDateRange, setStringDateRange] = useState(null);
  const [simpleTransactions, setSimpleTransactions] = useState([]);
  useEffect(() => {
    async function fetchTransactions() {
      setLoadingTransactionData(true);
      setLoadingPendingApprovalSize(true);
      try {
        let params = {
          companyId: company?.id,
          detailedSummary: true,
        };

        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }
        // const response = {
        //   status: 200,
        // };

        const response = await accountingApi.getTransactions(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");
          setDataCardInfo(response.data?.summary);
          setTransactionsTableData({
            columns: transactionsTableData?.columns,
            rows: response.data?.items,
          });
          setSimpleTransactions(response.data?.items);
          setPendingApprovalLength(response.data?.pendingApprovalLength);
          setStringDateRange(response.data?.dateRange);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        console.log("Error getting booking report", error);
      }
      setLoadingTransactionData(false);
      setLoadingPendingApprovalSize(false);
    }
    fetchTransactions();
  }, [fetchTransactions, startDate, endDate]);

  const [barChartData, setBarChartData] = useState(null);
  const [incomeBarChartData, setIncomeBarChartData] = useState(null);
  const [loadingBarChartData, setLoadingBarChartData] = useState(false);
  useEffect(() => {
    async function fetchHorizontalChartData() {
      setLoadingBarChartData(true);
      try {
        let params = {
          companyId: company?.id,
          formatUi: true,
          transactionType: "expense",
        };

        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }

        let paramsIncome = {
          companyId: company?.id,
          formatUi: true,
          transactionType: "income",
        };

        if (startDate) {
          paramsIncome.startDate = startDate;
        }
        if (endDate) {
          paramsIncome.endDate = endDate;
        }

        // const response = {
        //   status: 200,
        // };

        const response = await accountingApi.getCategorySummary(params);

        const responseIncome = await accountingApi.getCategorySummary(
          paramsIncome
        );

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");
          const formattedData = formatHorizontalBarChartData(
            response.data,
            "expense"
          );
          setBarChartData(formattedData);
        }

        if (responseIncome.status >= 200 && responseIncome.status < 300) {
          // setBookingReports(response.data);

          const formattedIncomeData = formatHorizontalBarChartData(
            responseIncome.data,
            "income"
          );
          setIncomeBarChartData(formattedIncomeData);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingBarChartData(false);
    }
    fetchHorizontalChartData();
  }, [startDate, endDate]);
  const [loadingLinearChartData, setLoadingLinearChartData] = useState(false);
  const [linearChartData, setLinearChartData] = useState(null);
  useEffect(() => {
    async function fetchLinearChartData() {
      setLoadingLinearChartData(true);
      try {
        let params = {
          companyId: company?.id,
        };

        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }
        // const response = {
        //   status: 200,
        // };

        const response = await accountingApi.getIncomeVsSpend(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");
          //const formattedData = formatHorizontalBarChartData(response.data);
          setLinearChartData(response.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingLinearChartData(false);
    }
    fetchLinearChartData();
  }, [startDate, endDate]);

  //getPropertiesNet

  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState("6 May - 7 May");
  const [customersDropdownValue, setCustomersDropdownValue] =
    useState("6 May - 7 May");
  const [revenueDropdownValue, setRevenueDropdownValue] =
    useState("6 May - 7 May");

  const [lineChartValue, setLineChartDropdownValue] = useState("6 May - 7 May");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  const [lineChartDropdown, setLineChartDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) =>
    setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) =>
    setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) =>
    setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  const openLineChartDropdown = ({ currentTarget }) =>
    setLineChartDropdown(currentTarget);
  const closeLineChartDropdown = ({ currentTarget }) => {
    setLineChartDropdown(null);
    setLineChartDropdownValue(currentTarget.innerText || salesDropdownValue);
  };

  const formatHorizontalBarChartData = (data, transactionType) => {
    const formattedData = {
      labels: data?.labels,
      datasets: [
        {
          label: transactionType === "expense" ? "Spend" : "Income",
          color: transactionType === "expense" ? "dark" : "info",
          data: data?.datasets,
        },
      ],
    };
    return formattedData;
  };

  const handleShareReport = async () => {
    setSendingReport(true);
    try {
      const response = await accountingApi.sendFinanceReport(reportQueryParams);
      console.log(response);
      // Show alert to check email for report
      setSendingReport(false);
      alert("Report sent to your email");
    } catch (error) {
      console.error(error);
    }
    console.log(reportQueryParams);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  const testProfitExpenses = [
    {
      color: "info",
      content: t("accounting.transactions.income"),
      id: "1",
    },
    {
      color: "dark",
      content: t("accounting.transactions.spend"),
      id: "2",
    },
    // {
    //   color: "primary",
    //   content: "Profit",
    //   id: "3",
    // },
  ];

  const [newTransactionItemDiaglog, setNewTransactionItemDiaglog] =
    useState(false);
  const handleCancelClicked = () => {
    setNewTransactionItemDiaglog(false);
  };
  const handleSubmitClicked = async (values) => {
    console.log("submit clicked", values);
    //setNewTransactionItemDiaglog(false);
  };

  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [startFilterStage, setStartFilterStage] = useState(null);
  const [endFilterStage, setEndFilterStage] = useState(null);
  const handleChangeFilterDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (setStartDate && setEndDate) {
      if (param === "startDate") {
        setStartFilterStage(useDate);
      } else {
        setEndFilterStage(useDate);
      }
    }
  };

  const handleApplyFilterDate = () => {
    if (setStartDate && setEndDate) {
      setStartDate(startFilterStage);
      setEndDate(endFilterStage);
      setDateFilterOpen(false);
    }
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <HostHiveDialog
            open={dateFilterOpen}
            onClose={() => setDateFilterOpen(false)}
            onSave={handleApplyFilterDate}
            fullWidth={true}
            includeClose={true}
            includeSave={true}
            maxWidth="md"
            title="Adjust Dates"
            fullScreen={false}
            cancelButtonText={t("buttons.cancel")}
            submitButtonText={t("buttons.apply")}
            dialogObjects={
              // <Grid container spacing={2} alignItems="center">
              //   <Grid item xs={12}>
              //     <PropertySelect
              //       property={property}
              //       setProperty={setProperty}
              //       filterSelection={true}
              //     />
              //   </Grid>
              // </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      From Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "startDate")}
                    />
                  </ArgonBox>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      To Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "endDate")}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>
            }
          />
          <HostHiveDialog
            open={newTransactionItemDiaglog}
            // onClose={handleCancelClicked}
            // onSave={handleSubmitClicked}
            includeSave={false}
            includeClose={false}
            title={t("accounting.transactions.dialog.title")}
            fullScreen={false}
            maxWidth="sm"
            cancelButtonText="Cancel"
            submitButtonText="Save"
            dialogObjects={
              <NewTransactionItem
                onClose={() => setNewTransactionItemDiaglog(false)}
                setFetchTransactions={() =>
                  setFetchTransactions(!fetchTransactions)
                }
              />
            }
          />
          <HostHiveDialog
            open={generateReportOpen}
            onClose={() => setGenerateReportOpen(false)}
            onSave={handleShareReport}
            includeSave={true}
            saveDisabled={sendingReport}
            includeClose={true}
            title="Generate Report"
            fullScreen={false}
            maxWidth="lg"
            cancelButtonText="Close"
            submitButtonText={sendingReport ? "Sending..." : "Download Report"}
            dialogObjects={
              <>
                <FinanaceReport
                  reportQueryParams={reportQueryParams}
                  setReportQueryParams={setReportQueryParams}
                />
              </>
            }
          />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ArgonBox position="relative">
                <Card
                  sx={{
                    py: 2,
                    px: 2,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    justifyContent: { xs: "center" },
                  }}
                >
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                        <ArgonTypography variant="h5" fontWeight="medium">
                          Finance
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          color="text"
                          fontWeight="medium"
                        >
                          Manage the financial aspects of your property
                          management business.
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>

                    <Grid item xs={12}>
                      <ArgonBox width="100%" textAlign="right" lineHeight={1}>
                        <ArgonBox>
                          <ArgonButton
                            variant="gradient"
                            color="primary"
                            // onClick={handleSearchClicked}
                            // disabled={loadingTransactions}
                            onClick={() => setGenerateReportOpen(true)}
                          >
                            Generate Report
                          </ArgonButton>
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>

                    <Grid item xs={12}>
                      <ArgonBox width="100%" textAlign="right" lineHeight={1}>
                        <ArgonBox>
                          <ArgonButton
                            variant="outlined"
                            color="primary"
                            // onClick={handleSearchClicked}
                            // disabled={loadingTransactions}
                            onClick={() => setDateFilterOpen(true)}
                          >
                            Adjust Dates
                          </ArgonButton>
                        </ArgonBox>
                        <ArgonTypography
                          variant="caption"
                          color="secondary"
                          // sx={{ cursor: "pointer" }}
                          // onClick={() => setDateFilterOpen(true)}
                        >
                          {stringDateRange?.startDate
                            ? stringDateRange?.startDate
                            : null}{" "}
                          -{" "}
                          {stringDateRange?.endDate
                            ? stringDateRange?.endDate
                            : null}
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </Card>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title={t("accounting.transactions.income")}
                count={
                  dataCardInfo
                    ? `$${dataCardInfo?.income?.toLocaleString()}`
                    : "-"
                }
                // percentage={{
                //   color: "success",
                //   value: "+55%",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
                stringDateRange={stringDateRange}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                loadingTransactionData={loadingTransactionData}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title={t("accounting.transactions.spend")}
                count={
                  dataCardInfo
                    ? `$${dataCardInfo?.spend.toLocaleString()}`
                    : "-"
                }
                // percentage={{
                //   color: "success",
                //   value: "+12%",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
                stringDateRange={stringDateRange}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                loadingTransactionData={loadingTransactionData}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title={t("accounting.transactions.companyNet")}
                count={
                  dataCardInfo
                    ? `$${dataCardInfo?.netProfit.toLocaleString()}`
                    : "-"
                }
                // percentage={{
                //   color: "secondary",
                //   value: "+$213",
                //   label: "since last month",
                // }}
                dropdown={{
                  action: openRevenueDropdown,
                  menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                  value: revenueDropdownValue,
                }}
                stringDateRange={stringDateRange}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                loadingTransactionData={loadingTransactionData}
              />
            </Grid>
          </Grid>
        </ArgonBox>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6} lg={4}>
              <SpendingCategories />
            </Grid> */}
            <Grid item xs={12} sm={6} lg={8}>
              {linearChartData ? (
                <DefaultLineChart
                  title={t("accounting.transactions.incomeVsSpend")}
                  description={
                    <ArgonBox display="flex" justifyContent="space-between">
                      <ArgonBox display="flex" ml={-1}>
                        {testProfitExpenses.map((property) => (
                          <ArgonBadgeDot
                            color={property?.color}
                            size="sm"
                            badgeContent={property?.content}
                          />
                        ))}
                      </ArgonBox>
                      {/* <ArgonBox mt={-5.25} mr={-1}>
                        <Tooltip
                          title=""
                          placement="left"
                          arrow
                        >
                          <ArgonBox width="100%" lineHeight={1}>
                            <ArgonTypography
                              variant="caption"
                              color="secondary"
                              sx={{ cursor: "pointer" }}
                              onClick={openLineChartDropdown}
                            >
                              {lineChartValue}
                            </ArgonTypography>
                            {renderMenu(
                              lineChartDropdown,
                              closeLineChartDropdown
                            )}
                          </ArgonBox>
                        </Tooltip>
                      </ArgonBox> */}
                    </ArgonBox>
                  }
                  chart={linearChartData}
                />
              ) : null}
            </Grid>
            <Grid item xs={12} lg={4}>
              <PropertySpend
                startDate={startDate}
                endDate={endDate}
                stringDateRange={stringDateRange}
              />
            </Grid>
          </Grid>
        </ArgonBox>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              {incomeBarChartData ? (
                <HorizontalBarChart
                  title="Income by Category"
                  chart={incomeBarChartData}
                  transactionType="income"
                />
              ) : null}
            </Grid>
            <Grid item xs={12} lg={4}>
              {barChartData ? (
                <HorizontalBarChart
                  title={t("accounting.transactions.spendByCategory")}
                  chart={barChartData}
                  transactionType="expense"
                />
              ) : null}
            </Grid>

            <Grid item xs={12} lg={4}>
              <IntegratedTransactions
                title="Integrated Transactions"
                date="23 - 30 March 2021"
                // setExternalFetch={setExternalFetch}
                // externalFetch={externalFetch}
                loadingPendingApprovalSize={loadingPendingApprovalSize}
                pendingApprovalLength={pendingApprovalLength}
                setPendingApprovalLength={setPendingApprovalLength}
              />
            </Grid>
          </Grid>
        </ArgonBox>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <ArgonBox py={1}>
                {loadingTransactionData ? (
                  <HostHiveLoading
                    message={t(
                      "accounting.transactions.loadingCompanTransactions"
                    )}
                    elipse={true}
                  />
                ) : isSmallScreen ? (
                  <ArgonBox>
                    {simpleTransactions?.map((transaction, key) => (
                      // <UpcomingAssignmentItem
                      //   booking={assignment}
                      //   handleViewClicked={() => handleViewClicked(assignment?.id)}
                      // />
                      <ArgonBox pb={2}>
                        <ComplexTransactionCard transaction={transaction} />
                      </ArgonBox>
                    ))}
                  </ArgonBox>
                ) : (
                  <HostHiveDataTable
                    header={t("accounting.transactions.title")}
                    table={transactionsTableData}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    canSearch
                    isSorted={true}
                    noEndBorder
                    newEntryAction={() => setNewTransactionItemDiaglog(true)}
                    newEntryText={t("accounting.transactions.newTransaction")}
                    newEntryColor="info"
                    newEntryVariant="outlined"
                    exportToCsv={() => console.log("export")}
                    importFromFile={() => console.log("import")}
                    // setProperty={setProperty}
                    // property={property}
                  />
                )}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Transactions;
