/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import HostHiveDialog from "components/HostHiveDialog";
// import ViewTransactionItem from "layouts/administration/accounting/components/ViewTransactionItem";
import ContactItem from "layouts/administration/customerAddressBook/components/ContactItem/index.js";
import ContactsApi from "api/companyAddressBookApi";
import ContactCard from "layouts/management/components/ContactCard";
import { showDeleteConfirmation } from "services/confirmDelete";

function ViewDataItem({
  value,
  suffix,
  prefix,
  itemDetails,
  id,
  // sxValue = null,
  // onClick = null,
  color = "text",
  triggerFetch = null,
}) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [contactPayload, setContactPayload] = useState(itemDetails);
  const contactsApi = ContactsApi();
  const updateContact = async () => {
    let payload = {
      name: contactPayload.name,
      email: contactPayload.email,
    };
    if (contactPayload.phone) {
      // payload.phone = contactPayload.phone; as int
      payload.phone = contactPayload.phone;
    }
    try {
      await contactsApi.updateContact(id, payload);
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.log(error);
    }

    setContactDialogOpen(false);
  };

  const confirmUserAction = async () => {
    try {
      await showDeleteConfirmation(
        "Delete",
        `Are you sure you want delete ${itemDetails.name}'s contact?`,
        "Yes, Delete Contact"
      );
      console.log(`User confirmed deletion!`);

      return true;
    } catch (response) {
      console.log("User cancelled deletion!");
      return false;
    }
  };

  const handleDeleteContact = async () => {
    const deleteConfirmation = await confirmUserAction();
    if (deleteConfirmation) {
      try {
        await contactsApi.deleteContact(id);
        if (triggerFetch) {
          triggerFetch();
        }
        setContactDialogOpen(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      setContactDialogOpen(false);
    }
  };

  const [onEdit, setOnEdit] = useState(false);

  return (
    <>
      <HostHiveDialog
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
        onSave={() => setOnEdit(true)}
        includeSave={true}
        includeClose={false}
        includeDelete={true}
        showFullScreenArrow={false}
        onDelete={handleDeleteContact}
        title="View Contact"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Edit"
        dialogObjects={
          <ContactCard
            contact={itemDetails}
            onClose={() => setContactDialogOpen(false)}
            onEdit={onEdit}
            setOnEdit={setOnEdit}
          />
        }
      />
      <ArgonTypography
        variant="caption"
        fontWeight="medium"
        color={color}
        sx={{
          ":hover": { textDecoration: "underline" },
          cursor: "pointer",
        }}
        onClick={() => setContactDialogOpen(true)}
      >
        {prefix && (
          <ArgonTypography variant="caption" fontWeight="medium" color={color}>
            &nbsp;&nbsp;{prefix}
          </ArgonTypography>
        )}
        {value}
        {suffix && (
          <ArgonTypography variant="caption" fontWeight="medium" color={color}>
            &nbsp;&nbsp;{suffix}
          </ArgonTypography>
        )}
      </ArgonTypography>
    </>
  );
}

// Setting default values for the props of ViewDataItem
ViewDataItem.defaultProps = {
  suffix: "",
};

// Typechecking props for the ViewDataItem
ViewDataItem.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ViewDataItem;
