// AIzaSyAjiNu5eIZF2usKN1ifJD8928eQGuDeLmk

import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import AddressInput from "layouts/management/components/PropertySettingsDataCard/components";
import { Grid } from "@mui/material";
import ArgonButton from "components/ArgonButton";
const PropertyDetailsInput = ({ onSave = () => {} }) => {
  const [address, setAddress] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");

  const initialProperty = {
    name: "",
    address: "",
    formattedAddress: "",
    bed: "",
    bath: "",
    sqft: "",
    id: "",
  };

  const [property, setProperty] = useState(initialProperty);

  useEffect(() => {
    property.propertyAddress = address;
    property.formattedAddress = formattedAddress;

    console.log(property);
  }, [address, formattedAddress]);
  return (
    <ArgonBox sx={{ marginBottom: "30px" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ArgonInput
            label="Name"
            value={property.name}
            placeholder="Name"
            onChange={(e) => {
              console.log(e.target.value);
              setProperty({ ...property, name: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AddressInput
            address={address}
            setAddress={setAddress}
            formattedAddress={formattedAddress}
            setFormattedAddress={setFormattedAddress}
          />
        </Grid>
        <Grid item xs={4}>
          <ArgonInput
            label="Bed"
            value={property.bed}
            placeholder="Bed"
            onChange={(e) => {
              console.log(e.target.value);
              setProperty({ ...property, bed: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ArgonInput
            label="Bath"
            value={property.bath}
            placeholder="Bath"
            onChange={(e) => {
              console.log(e.target.value);
              setProperty({ ...property, bath: e.target.value });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ArgonInput
            label="Sqft"
            value={property.sqft}
            placeholder="Sqft"
            onChange={(e) => {
              console.log(e.target.value);
              setProperty({ ...property, sqft: e.target.value });
            }}
          />
        </Grid>
      </Grid>
      <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mt={2}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "flex-end", // Changed from "center" to "flex-end"
          }}
        >
          <Grid item>
            {" "}
            {/* Changed from xs={12} to unspecified size to allow natural content size */}
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => {
                onSave(property);
                // Optionally reset the property state here
                setProperty(initialProperty);
                setFormattedAddress("");
                setAddress("");
              }}
            >
              Add
            </ArgonButton>
          </Grid>
        </Grid>
      </ArgonBox>
    </ArgonBox>
  );
};

export default PropertyDetailsInput;
