import React, { useState } from 'react';
import CompanyContext from './CompanyContext';

export function CompanyContextProvider(props) {
  const [company, setCompany] = useState(null);
  
  const contextValue = {
    company, setCompany
  };

  return (
    <CompanyContext.Provider value={contextValue}>
      {props.children}
    </CompanyContext.Provider>
  );
}
