/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
// Custom styles for ComplexProjectCard
function ComplexProjectCard({ id, name }) {
  const navigate = useNavigate();
  const { borderWidth } = borders;
  // // Badges
  // const propertyOpen = (
  //   <ArgonBadge
  //     variant="contained"
  //     color="success"
  //     badgeContent="ready"
  //     container
  //   />
  // );
  // const propertyOccupied = (
  //   <ArgonBadge
  //     variant="contained"
  //     color="caution"
  //     badgeContent="occupied"
  //     container
  //   />
  // );

  // const propertyError = (
  //   <ArgonBadge
  //     variant="contained"
  //     color="error"
  //     badgeContent="pending"
  //     container
  //   />
  // );
  // const propertyPending = (
  //   <ArgonBadge
  //     variant="contained"
  //     color="warning"
  //     badgeContent="pending"
  //     container
  //   />
  // );
  // const propertyRequireService = (
  //   <ArgonBadge
  //     variant="contained"
  //     color="error"
  //     badgeContent="Service Required"
  //     container
  //   />
  // );

  // const readStatus = (status) => {
  //   status = status.toLowerCase();
  //   if (status === "ready") {
  //     return propertyOpen;
  //   }
  //   if (status === "occupied") {
  //     return propertyOccupied;
  //   }
  //   if (status === "pending") {
  //     return propertyPending;
  //   }
  //   if (status === "error") {
  //     return propertyError;
  //   }
  //   if (status === "serviceRequired") {
  //     return propertyRequireService;
  //   } else {
  //     return propertyPending;
  //   }
  // };

  // const navigateToProperty = (id) => {
  //   navigate(`/properties/${id}}`);
  // };
  // const [occupancyStatusColor, setOccupancyStatusColor] = useState("error");
  // const [occupancyStatusContent, setOccupancyStatusContent] = useState("");
  // useEffect(() => {
  //   setOccupancyStatusColor(occupancyStatusDetails?.color || "error");
  //   setOccupancyStatusContent(occupancyStatusDetails?.displayName || "UNKNOWN");
  // }, [id]);

  return (
    <Card
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <ArgonBox p={2}>
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox mb={1} lineHeight={0}>
              <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
              >
                <MuiLink
                  href={`/service-partners/${id}/settings-overview`}
                  rel="noreferrer"
                >
                  {name}
                </MuiLink>
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
        </ArgonBox>
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                Properties
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                2
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          {/* <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                Alerts
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                2
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox> */}
          {/* <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                Sqft
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {sqft}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox> */}
        </ArgonBox>
        <Divider />
        {/* <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
            <ArgonTypography variant="button" fontWeight="medium">
              <ArgonBadge
                variant="contained"
                color={occupancyStatusColor}
                badgeContent={occupancyStatusContent}
                container
              />
            </ArgonTypography>
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              color="secondary"
            >
              Occupancy Status
            </ArgonTypography>
          </ArgonBox>
          {status ? (
            <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
              <ArgonTypography variant="button" fontWeight="medium">
                {status}
              </ArgonTypography>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="secondary"
              >
                Status
              </ArgonTypography>
            </ArgonBox>
          ) : null}
        </ArgonBox> */}
        <ArgonButton
          component="a"
          href="https://google.com" //{action.route}
          color="info" //{action.color}
          size="small"
        >
          View
        </ArgonButton>
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  description: PropTypes.node.isRequired,
  occupancyStatus: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
