/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Pricing page components
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";
import ContactSupportApi from "api/contactSupportApi";
function Faq() {
  const [collapse, setCollapse] = useState(false);
  const contactSupportApi = new ContactSupportApi();
  const faqs = [
    {
      collapse: 1,
      title: "What platforms does HostHive integrate with?",
      description:
        "Our service integrates seamlessly with major rental platforms like Airbnb, Guest, Guesty For Hosts and VRBO. We are constantly looking to expand our compatibility to make your management tasks easier. If you would like us to integrate with a specific platform, please let us know.",
    },
    {
      collapse: 2,
      title: "How does the cleaning team scheduling work?",
      description:
        "Our scheduling system automatically updates based on the integration events from your rental platforms. Once a booking is made, the cleaning team's schedule is adjusted accordingly and all parties are notified, ensuring your property is always guest-ready.",
    },
    {
      collapse: 3,
      title: "Is financial transaction management available?",
      description:
        "Yes, our product optionally integrates with Plaid to import and manage your financial transactions, giving you a comprehensive overview of your financial health.",
    },
    {
      collapse: 4,
      title: "Can I manage multiple properties with your service?",
      description:
        "Absolutely! Our service is designed to handle multiple properties efficiently, allowing you to scale your operations without compromising on organization or functionality.",
    },
    {
      collapse: 5,
      title: "What customer support options are available?",
      description:
        "We offer support primarily through our ticketing system. Our team is dedicated to assisting you promptly and effectively with any issues or questions you might have.",
    },
    {
      collapse: 6,
      title: "How secure is my data with HostHive?",
      description:
        "Data security is a top priority for us. We employ advanced encryption methods and comply with all relevant regulations to ensure your data is protected at all times.",
    },
    {
      collapse: 7,
      title: "Are there any tutorials or guides available to get started?",
      description:
        "Yes, we provide detailed tutorials and guides that help new users familiarize themselves with all aspects of our service. These resources are available directly within our platform.",
    },
    {
      collapse: 8,
      title: "How can I provide feedback or suggest new features?",
      description:
        "We love hearing from our users! Please feel free to reach out via our support channels or directly through the feedback option in your dashboard. Your suggestions help us grow and improve.",
    },
    {
      collapse: 9,
      title: "What are the subscription plans available?",
      description:
        "Our pricing model is based on the number of properties you manage.",
    },
    {
      collapse: 10,
      title: "How much is HostHive?",
      description:
        "Pricing is based on the number of properties you manage, with rates as low as $6.00 per property. Your first property is free, excluding premium features. To access premium features or add more properties, simply upgrade to a paid plan. The subscription cost will be calculated based on the number of properties multiplied by the per property price.",
    },
  ];
  const paidTierOfferings = [
    {
      id: 1,
      title: "Multiple Integrations per Property:",
      description:
        "Seamlessly connect with various platforms to manage all your properties from one place.",
    },
    {
      id: 2,
      title: "Financial Transaction Management:",
      description:
        "Keep track of your finances with our integrated tools for better financial oversight.",
    },
    {
      id: 3,
      title: "Task Management:",
      description:
        "Assign and manage tasks efficiently within your team to ensure everything runs smoothly.",
    },
    {
      id: 4,
      title: "Time Tracking:",
      description:
        "Monitor the time spent on each task to optimize workflows and productivity.",
    },
    {
      id: 5,
      title: "Appointment Photo Verification:",
      description:
        "Verify task completion with photo uploads to maintain quality standards.",
    },
    {
      id: 6,
      title: "Appointment Checklist:",
      description:
        "Use checklists to ensure no detail is overlooked during property management tasks.",
    },
    {
      id: 7,
      title: "Comments for Collaboration:",
      description:
        "Communicate effectively within your team by adding comments throughout the application.",
    },
    {
      id: 8,
      title: "Live in-app Chat (Coming Soon):",
      description:
        "Connect with your team members in real-time to address urgent issues or questions.",
    },
  ];
  return (
    <ArgonBox mt={10}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6}>
          <ArgonTypography
            variant="h2"
            align="center"
            fontWeight="bold"
            gutterBottom
          >
            Frequently Asked Questions
          </ArgonTypography>
          <ArgonBox mb={2}>
            <ArgonTypography
              variant="body2"
              align="center"
              color="text"
            ></ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={10}>
          {faqs.map((faq) => (
            <FaqCollapse
              key={faq.collapse}
              title={faq.title}
              open={collapse === faq.collapse}
              onClick={() =>
                collapse === faq.collapse
                  ? setCollapse(false)
                  : setCollapse(faq.collapse)
              }
            >
              {faq.description}
            </FaqCollapse>
          ))}

          <FaqCollapse
            key={1001}
            title="What is included in the Paid tier?"
            open={collapse === 1001}
            onClick={() =>
              collapse === 1001 ? setCollapse(false) : setCollapse(1001)
            }
          >
            The Paid tier offers a comprehensive suite of features designed to
            streamline property management. This includes:
            <ArgonBox
              sx={{
                marginTop: 5,
                backgroundColor: "rgba(0,0,0,0.1)",
                borderRadius: 8,
                padding: 4,
              }}
            >
              <ul>
                {paidTierOfferings.map((offering) => (
                  <li key={offering.d}>
                    <ArgonTypography
                      variant="body2"
                      color="text"
                      fontWeight="bold"
                    >
                      {offering.title}
                    </ArgonTypography>
                    <ArgonTypography variant="body2" color="text">
                      {offering.description}
                    </ArgonTypography>
                  </li>
                ))}
              </ul>
            </ArgonBox>
          </FaqCollapse>
        </Grid>
      </Grid>
    </ArgonBox>
  );
}

export default Faq;
