/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import BookingsApi from "api/bookingsApi";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ServicePartnersSelect from "layouts/management/components/ServicePartnerSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import CategoriesApi from "api/categoriesApi";
import ChecklistsApi from "api/checkListsApi";
import CategoryDialog from "layouts/management/components/CategoryDialog";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import HostHiveDialog from "components/HostHiveDialog";

import ChecklistItemDialog from "layouts/management/company/settings/components/ServiceChecklists/components/ChecklistItemDialog";

import { useTranslation } from "react-i18next";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import { set } from "lodash";
function EventOptions({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  responseData,
  setResponseData,
  isInCreationMode,
  handleRefetchBookings = null,
}) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const bookingsApi = BookingsApi();
  const { t } = useTranslation("common");
  const categoriesApi = CategoriesApi();
  const checklistsApi = ChecklistsApi();
  const [checklist, setChecklist] = useState(
    {
      value: bookingPayload?.checklistId,
      label: userSelections[bookingPayload?.checklistId],
    } || null
  );
  const [checklists, setChecklists] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [fetchCategories, setFetchCategories] = useState(false);
  const [fetchChecklists, setFetchChecklists] = useState(false);
  useEffect(() => {
    setNextDisabled(false);
    setBookingPayload({ ...bookingPayload, checklistId: checklist?.value });
    setUserSelections({
      ...userSelections,
      [checklist?.value]: checklist?.label,
    });

    setResponseData({
      ...responseData,
      checklists: checklists,
    });
  }, [checklist]);
  const [category, setCategory] = useState(
    {
      value: bookingPayload?.categoryId,
      label: userSelections[bookingPayload?.categoryId],
    } || null
  );

  useEffect(() => {
    async function fetchCategories() {
      if (company) {
        try {
          const queryParams = {
            companyId: company?.id,
            type: "serviceAssignments",
          };

          const response = await categoriesApi.getCategories(queryParams);
          const categoriesResponse = response.data;

          let options = categoriesResponse.map((category) => ({
            color: category?.color,
            value: category.id,
            text: category.name,
            label: (
              <ArgonBadgeDot
                color={category?.color}
                size="md"
                badgeContent={category?.name}
              />
            ),
          }));

          options.push({
            value: "new",
            label: `+ ${t("components.category.create.createNew")}`,
          });
          setCategories(options);
        } catch (error) {
          console.error(error);
        }
      }
    }
    async function fetchChecklists() {
      if (company) {
        try {
          const queryParams = {
            companyId: company?.id,
          };

          const response = await checklistsApi.getChecklists(queryParams);
          const checklistsResponse = response.data;

          let options = checklistsResponse.map((checklist) => ({
            value: checklist.id,
            label: checklist.name,
          }));
          options.push({
            value: "new",
            label: `+ Create New Checklist`,
          });
          setChecklists(options);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchCategories();
    fetchChecklists();
  }, [fetchCategories, fetchChecklists]);

  const updateBooking = async (key, value) => {
    if (key === "checklists") {
      console.log("Updating checklistId to", value);
      const response = await bookingsApi.updateBookingChecklist(
        bookingPayload?.id,
        value
      );
    } else {
      console.log("Updating categoryId to", value);
      try {
        const response = await bookingsApi.updateBookingCategory(
          bookingPayload?.id,
          value
        );
      } catch (error) {
        console.error(error);
      }
    }

    // if (handleRefetchBookings) {
    //   handleRefetchBookings();
    // }
  };

  const handleCategorySelected = (e) => {
    if (e.value === "new") {
      setIsNewCategory(true);
    } else {
      setIsNewCategory(false);
      console.log("category selected", e);
      setCategory(e);
      setBookingPayload({ ...bookingPayload, categoryId: e?.value });
      setUserSelections({
        ...userSelections,
        [e?.value]: e?.label,
      });
    }

    if (!isInCreationMode) {
      updateBooking("categoryId", e?.value);
    }
  };

  const [isNewChecklist, setIsNewChecklist] = useState(false);

  const handleChecklistSelected = (e) => {
    if (e.value === "new") {
      setIsNewChecklist(true);
    } else {
      setIsNewChecklist(false);
      console.log("checklist selected", e);
      setChecklist(e);
      setBookingPayload({ ...bookingPayload, checklistId: e?.value });
      setUserSelections({
        ...userSelections,
        [e?.value]: e?.label,
      });
    }

    if (!isInCreationMode) {
      updateBooking("checklists", e?.value);
    }
  };

  return (
    <Grid container spacing={2}>
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={isNewCategory}
        onClose={() => {
          setIsNewCategory(false);
        }}
        title={t("components.category.create.createNew")}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <CategoryDialog
            categoryType="serviceAssignments"
            companyId={company?.id}
            handleCategorySelected={handleCategorySelected}
            setCategoryDetails={setCategoryDetails}
            handleSelection={true}
            // fetchServiceNames={() =>
            //   setCreatedServiceName(!createdServiceName)
            // }
            fetchCategories={() => setFetchCategories(!fetchCategories)}
            onClose={() => {
              setIsNewCategory(false);
            }}
            // snackBarOpen={openSuccessSB}
          />
        }
      />
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={isNewChecklist}
        onClose={() => {
          setIsNewChecklist(false);
        }}
        title={t("components.checklist.create.createNewChecklist")}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <ChecklistItemDialog
            checklistItem={checklist}
            setChecklistItem={setChecklist}
            onClose={() => setIsNewChecklist(false)}
            companyId={company?.id}
            setChecklist={setChecklist}
            // fetchChecklists={() => setUpdatedChecklist(!updatedChecklist)}
          />
        }
      />

      <Grid item xs={12}>
        <ArgonBox lineHeight={0} pt={2}>
          {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Select the checklist to be used for this service.
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox lineHeight={0}>
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={1}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Checklist (Optional)
            </ArgonTypography>
            <HostHiveKbHelp lookupKey="checklist" marginBottom={0} />
          </ArgonBox>

          <ArgonSelect
            menuPlacement="bottom"
            defaultValue={{
              value: "none",
              label: t("components.category.select"),
            }}
            value={checklist}
            options={checklists}
            onChange={handleChecklistSelected}
            fullWidth
          />
        </ArgonBox>
      </Grid>
      <Grid item xs={12}>
        <ArgonBox lineHeight={0} pt={2}>
          {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Select the category for this service.
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox lineHeight={0}>
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={1}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Service Assignment {t("components.category.title")} (Optional)
            </ArgonTypography>
            <HostHiveKbHelp lookupKey="checklist" marginBottom={0} />
          </ArgonBox>

          <ArgonSelect
            menuPlacement="bottom"
            defaultValue={{
              value: "none",
              label: t("components.category.select"),
            }}
            value={category}
            options={categories}
            onChange={handleCategorySelected}
            fullWidth
          />
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

// typechecking props for EventOptions
EventOptions.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EventOptions;
