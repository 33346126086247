/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useMemo, useState, useEffect } from "react";
import HostHiveLoading from "components/HostHiveLoading";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import Table from "examples/Tables/Table";

import GradientLineChart from "layouts/dashboards/hosthive-home/components/GradientLineChart";
import HostHiveDialog from "components/HostHiveDialog";
import Card from "@mui/material/Card";
import ConfirmationLists from "layouts/management/schedulePage/components/ConfirmationLists";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboards/default/components/Slider";
import TeamMembers from "layouts/dashboards/default/components/TeamMembers";
import TodoList from "layouts/dashboards/default/components/TodoList";
import ProgressTrack from "layouts/dashboards/default/components/ProgressTrack";
import BalanceCard from "layouts/dashboards/default/components/BalanceCard";
import CryptoCard from "layouts/dashboards/default/components/CryptoCard";

// Pages layout components
import Post from "layouts/pages/profile/teams/components/Post";
import CompanyApi from "api/companyApi";

// Data

import {
  columns,
  smColumns,
} from "layouts/management/bookingReports/propertyBookingReportList/components/propertyBookingReportColumns";

import projectsTableData from "layouts/dashboards/default/data/projectsTableData";
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import authorsTableData from "layouts/dashboards/default/data/authorsTableData";
import categoriesListData from "layouts/dashboards/default/data/categoriesListData";
import BookingsApi from "api/bookingsApi";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
} from "services/dateUtils";
import { useParams, useNavigate } from "react-router-dom";
import DashboardApi from "api/dashboardApi";
import Calendar from "layouts/management/calendar";
import { useTranslation } from "react-i18next";
import BookingReportApi from "api/bookingReportApi";
import HostHiveDataTable from "components/HostHiveDataTable";

function Default() {
  const bookingReportApi = BookingReportApi();
  const [bookingReportData, setBookingReportData] = useState([]);
  const [bookingReportTableData, setBookingReportTableData] = useState({
    columns: columns,
    rows: [
      {
        summary: "",
        serviceScheduleDate: "",
        status: "",
        assigneeName: "",
      },
    ],
  });
  const { size } = typography;
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const companyApi = CompanyApi();
  const bookingsApi = BookingsApi();
  const dashboardApi = DashboardApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const { tokenCode } = useParams();
  const [invitationResponseLoading, setInvitationResponseLoading] =
    useState(false);
  const [invitationAcceptedShowing, setInvitationAcceptedShowing] =
    useState(false);
  const [acceptedPartnerInfo, setAcceptedPartnerInfo] = useState(null);
  const [invitationMessage, setInvitationMessage] = useState(null);
  const [invitationErrorMessage, setInvitationErrorMessage] = useState(null);
  useEffect(() => {
    async function fetchBookingReports() {
      try {
        const params = {};
        const response = await bookingReportApi.getBookingReports(params);
        console.log("Booking report response", response);
        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          // setPropertyInfo(response.data?.propertyInfo);
          setBookingReportTableData({
            columns: bookingReportTableData?.columns,
            rows: response.data,
          });
          setBookingReportData(response.data);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        console.log("Error getting booking report", error);
      }
    }
    fetchBookingReports();
  }, []);
  const [headerTiles, setHeaderTiles] = useState([
    {
      title: "",
      icon: "",
      iconColor: "",
      primaryNumber: "",
      secondaryNumber: "",
      changePercentage: "",
      secondaryNumberColor: "",
      secondaryNumberText: "",
    },
    {
      title: "",
      icon: "",
      iconColor: "",
      primaryNumber: "",
      secondaryNumber: "",
      changePercentage: "",
      secondaryNumberColor: "",
      secondaryNumberText: "",
    },
    {
      title: "",
      icon: "",
      iconColor: "",
      primaryNumber: "",
      secondaryNumber: "",
      changePercentage: "",
      secondaryNumberColor: "",
      secondaryNumberText: "",
    },
    {
      title: "",
      icon: "",
      iconColor: "",
      primaryNumber: "",
      secondaryNumber: "",
      changePercentage: "",
      secondaryNumberColor: "",
      secondaryNumberText: "",
    },
  ]);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [externalFetch, setExternalFetch] = useState(false);
  const translateDateValue = (key, isMonth = true) => {
    let loweKey = key.toLowerCase();
    let translatedValue = "";
    if (isMonth) {
      translatedValue = t(`calendar.months.${loweKey}`);
    } else {
      translatedValue = t(`calendar.days.${loweKey}`);
    }

    return translatedValue;
  };
  const CalendarComponent = isLoadingBookings ? (
    <HostHiveLoading />
  ) : (
    <Calendar
      header={{
        title: `${translateDateValue(monthName)}`,
        date: `${translateDateValue(`${dayOfWeek}`, false)}, ${year}`,
      }}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek",
      }}
      initialView="dayGridMonth"
      initialDate={formattedDate}
      fixedWeekCount={false}
      editable={true}
      selectable={true}
      selectMirror={true}
      externalFetch={externalFetch}
      setIsLoadingBooking={setIsLoadingBooking}
    />
  );
  const [bookingChartData, setBookingChartData] = useState(null);
  useEffect(() => {
    async function fetchVolumeBooking() {
      try {
        const response = await bookingsApi.getBookingsVolume();
        console.log(response.data, "response.data");
        setBookingChartData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchVolumeBooking();
  }, []);

  useEffect(() => {
    const invitationAcceptedCard = async () => {
      setInvitationResponseLoading(true);
      setInvitationAcceptedShowing(true);
      try {
        const response = await companyApi.acceptCompanyPartnershipViaEmail(
          tokenCode
        );
        if (response.status >= 200 && response.status < 300) {
          setAcceptedPartnerInfo(response.data);
          console.log("Invitation has been accepted");
          setInvitationMessage(
            `You are now a service partner of ${response.data?.name} !`
          );
        }
      } catch (error) {
        console.log(error);
        console.log("Error accepting invitation");
        setInvitationMessage(
          `Error accepting invitation: ${error?.response.data?.detail}`
        );
        setInvitationResponseLoading(false);
      }
      setInvitationResponseLoading(false);
      //navigate("/");
    };
    if (tokenCode) {
      invitationAcceptedCard();
    }
  }, []);

  useEffect(() => {
    const loadDashboard = async () => {
      try {
        const response = await dashboardApi.getDashboard();
        console.log(response.data, "response.data");
        const responseData = response.data;
        setHeaderTiles(responseData?.headerTiles);
      } catch (error) {
        console.error(error);
      }
    };
    loadDashboard();
  }, []);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox>
        <HostHiveDialog
          open={invitationAcceptedShowing}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          maxWidth="md"
          onClose={() => {
            setInvitationAcceptedShowing(false);
            navigate("/");
          }}
          title="Service Partner invitation"
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Save"
          showStatusBadge={false}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <Card>
              {invitationResponseLoading ? (
                <HostHiveLoading
                  message={t("company.acceptingInvitation.title")}
                />
              ) : (
                <>
                  <ArgonBox display="flex" justifyContent="center">
                    <ArgonTypography variant="h5" fontWeight="regular">
                      {invitationMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </>
              )}
            </Card>
          }
        />
      </ArgonBox>
      <ArgonBox py={3}>
        {/* <Grid container spacing={3} mb={3}>
          {headerTiles.length > 0 &&
            headerTiles?.map((headerTile, key) => (
              <Grid item xs={12} md={6} lg={3}>
                <DetailedStatisticsCard
                  title={t(headerTile?.title)}
                  count={headerTile?.primaryNumber}
                  icon={{
                    color: headerTile?.iconColor,
                    component: <i className={headerTile?.icon} />,
                  }}
                  percentage={{
                    color: headerTile?.secondaryNumberColor,
                    count:
                      headerTile?.numberTypeIcon === "$"
                        ? `${headerTile?.numberTypeIcon}${headerTile?.secondaryNumber}`
                        : `${headerTile?.secondaryNumber}${headerTile?.numberTypeIcon}`, // `${headerTile?.secondaryNumber}${headerTile?.numberTypeIcon}`,
                    text: t(headerTile?.secondaryNumberText),
                  }}
                />
              </Grid>
            ))}
        </Grid> */}
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={6}>
            {CalendarComponent}
          </Grid>
          <Grid item xs={12} lg={6}>
            <ArgonBox>
              <ArgonBox pb={2}>
                {bookingChartData ? (
                  <GradientLineChart
                    title={t("dashboard.volumeGraph.title")}
                    description={
                      <ArgonBox display="flex" alignItems="center">
                        <ArgonBox
                          fontSize={size.lg}
                          color="success"
                          mb={0.3}
                          mr={0.5}
                          lineHeight={0}
                        >
                          <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
                        </ArgonBox>
                        <ArgonTypography
                          variant="button"
                          color="text"
                          fontWeight="medium"
                        >
                          4% more{" "}
                          <ArgonTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                          >
                            in 2022
                          </ArgonTypography>
                        </ArgonTypography>
                      </ArgonBox>
                    }
                    chart={bookingChartData}
                  />
                ) : null}
              </ArgonBox>
              <ArgonBox>
                {/* <TeamMembers /> */}
                <ConfirmationLists
                  title={t(
                    "components.serviceAppointments.toBeConfirmed.title"
                  )}
                  date="23 - 30 March 2021"
                  setExternalFetch={setExternalFetch}
                  externalFetch={externalFetch}
                />
              </ArgonBox>
            </ArgonBox>
          </Grid>
          <Grid item xs={12} height="max-content">
            <ArgonBox
              sx={{
                "& .MuiTableContainer-root": {
                  p: 3,
                },
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {/* <Table
                columns={projectsTableData.columns}
                rows={projectsTableData.rows}
                title="Service Reports"
              /> */}
              <HostHiveDataTable
                navigateTo={{
                  url: "/service-reports",
                  title: "Navigate to Service Reports page",
                }}
                header="Service Reports"
                table={bookingReportTableData}
                canSearch
              />
            </ArgonBox>
          </Grid>
        </Grid>
        {/* <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={4}>
            <TeamMembers />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TodoList />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <ProgressTrack />
          </Grid>
        </Grid>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={5}>
            <Post />
          </Grid>
          <Grid container item xs={12} lg={7} spacing={3}>
            <Grid item xs={12} height="max-content">
              <ArgonBox
                sx={{
                  "& .MuiTableContainer-root": {
                    p: 3,
                  },
                  "& .MuiTableRow-root:not(:last-child)": {
                    "& td": {
                      borderBottom: ({
                        borders: { borderWidth, borderColor },
                      }) => `${borderWidth[1]} solid ${borderColor}`,
                    },
                  },
                }}
              >
                <Table
                  columns={projectsTableData.columns}
                  rows={projectsTableData.rows}
                />
              </ArgonBox>
            </Grid>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <BalanceCard />
              </Grid>
              <Grid item xs={12} md={6}>
                <CryptoCard />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SalesTable title="Sales by Country" rows={salesTableData} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ArgonBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table
                columns={authorsTableData.columns}
                rows={authorsTableData.rows}
              />
            </ArgonBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoriesList
              title="categories"
              categories={categoriesListData}
            />
          </Grid>
        </Grid> */}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
