/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Argon Dashboard 2 PRO MUI are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 PRO MUI layouts
import VideoLibrary from "layouts/management/components/VideoLibrary";
import Landing from "layouts/dashboards/landing";
import Default from "layouts/dashboards/hosthive-home";
import AppHomePage from "layouts/dashboards/appHomepage";
import Automotive from "layouts/dashboards/automotive";
import SmartHome from "layouts/dashboards/smart-home";
import VRDefault from "layouts/dashboards/virtual-reality/vr-default";
import VRInfo from "layouts/dashboards/virtual-reality/vr-info";
import CRM from "layouts/dashboards/crm";
import ProfileOverview from "layouts/management/users/profile-overview";
import NewUser from "layouts/management/users/new-user";
// import PricingPage from "layouts/pages/pricing-page";
import Teams from "layouts/pages/profile/teams";
import AllProjects from "layouts/pages/profile/all-projects";
import Reports from "layouts/pages/users/reports";
import Settings from "layouts/pages/account/settings";
import Billing from "layouts/pages/account/billing";
import Invoice from "layouts/pages/account/invoice";
import Security from "layouts/pages/account/security";
import General from "layouts/pages/projects/general";
import Timeline from "layouts/pages/projects/timeline";
import NewProject from "layouts/pages/projects/new-project";
import Widgets from "layouts/pages/widgets";
import Charts from "layouts/pages/charts";
import SweetAlerts from "layouts/pages/sweet-alerts";
import Notifications from "layouts/management/notifications";
import PricingPage from "layouts/pages/pricing-page";
import RTL from "layouts/pages/rtl";
import Kanban from "layouts/applications/kanban";
import Wizard from "layouts/applications/wizard";
// import DataTables from "layouts/applications/data-tables";
//import Calendar from "layouts/applications/calendar";
import Analytics from "layouts/applications/analytics";
import Overview from "layouts/ecommerce/overview";
import NewBookingReport from "layouts/management/components/NewBookingReport";
// import FrequentlyAskedQuestions from "layouts/pages/faq";
import PublicSupport from "layouts/pages/account-support-faq";
import MobileApps from "layouts/pages/MobileApps";
import TestNewEventWizard from "layouts/management/components/TestNewEventWizard";
// import TestBulkTransactionManagement from "layouts/management/components/TestBulkTransactionManagementV2";
import EditProduct from "layouts/ecommerce/products/edit-product";
import ProductPage from "layouts/ecommerce/products/product-page";
import ProductsList from "layouts/ecommerce/products/products-list";
import OrderList from "layouts/ecommerce/orders/order-list";
import OrderDetails from "layouts/ecommerce/orders/order-details";
import Referral from "layouts/ecommerce/referral";
import SignUp from "layouts/authentication/sign-up";
import SignInBasic from "layouts/authentication/sign-in/basic";
import SignIn from "layouts/authentication/sign-in/new";
import SignInCover from "layouts/authentication/sign-in/cover";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import GetSupport from "layouts/administration/support";
// import SignUpBasic from "layouts/authentication/sign-up/basic";
// import SignUpCover from "layouts/authentication/sign-up/cover";
import EmailSignin from "layouts/authentication/emailsignin";
import ServiceInvoicingDashboard from "layouts/management/serviceInvoicing";
import CustomerDataTables from "layouts/hostHiveAdministration/customers/companyPage";
import CompanyProperties from "layouts/hostHiveAdministration/customers/companyProperties";
import CompanyUsers from "layouts/hostHiveAdministration/customers/companyUsers";
import CompanyDashboard from "layouts/hostHiveAdministration/customers/companyDashboard";
import CompanyMessages from "layouts/hostHiveAdministration/customers/messages";
//EmailSignin
//import SignUpIllustration from "layouts/authentication/sign-up/illustration";
import ResetBasic from "layouts/authentication/reset-password/basic";
import SubscribeToBlog from "layouts/administration/newUserSubscription/subscribe/basic";
import NewPassword from "layouts/authentication/new-password";
import AuthRedirect from "layouts/authentication/authRedirect";
import ResetCover from "layouts/authentication/reset-password/cover";
import ResetIllustration from "layouts/authentication/reset-password/illustration";
import LockBasic from "layouts/authentication/lock/basic";
import LockCover from "layouts/authentication/lock/cover";
import LockIllustration from "layouts/authentication/lock/illustration";
import VerificationBasic from "layouts/authentication/2-step-verification/basic";
import VerificationCover from "layouts/authentication/2-step-verification/cover";
import VerificationIllustration from "layouts/authentication/2-step-verification/illustration";
import Error404 from "layouts/authentication/error/404";
import Error500 from "layouts/authentication/error/500";
import Messages from "layouts/messages";
import Inventory from "layouts/administration/inventory";
import InventoryItemPage from "layouts/management/inventoryItem";
// import TestIntegrationSelect from "layouts/management/components/TestNewIntegrationSelect";
//import Transactions from "layouts/administration/accounting/Transactions/Transactions";
import Transactions from "layouts/administration/accounting/overview";
import Contacts from "layouts/administration/customerAddressBook";
import FinanaceReport from "layouts/administration/accounting/components/FinanaceReport";
import BulkTransactionManagement from "layouts/administration/accounting/bulkTransactionManagement";
import TestProperties from "layouts/management/components/TestProperties";
import TestTypography from "layouts/management/components/TestTypography";
import TestPlaid from "layouts/management/components/TestPlaid";
import TestFileUpload from "layouts/management/components/TestFileUpload";
//TestPlaid
import TestDynamicSettings from "layouts/management/components/TestDynamicSettings";
import CategorySettings from "layouts/management/components/CategorySettings";
import TestNewChecklist from "layouts/management/components/TestNewChecklist";
import BookingReports from "layouts/management/bookingReports/bookingReportList";
import PropertyBookingReportList from "layouts/management/bookingReports/propertyBookingReportList";
import TasksCenterPage from "layouts/administration/taskCenter";
import TaskItemPage from "layouts/administration/taskCenter/TaskItemPage";
import TimeTrackingPage from "layouts/administration/timeTracking";
import SupoprtItemPage from "layouts/administration/support/SupoprtItemPage";
//SupoprtItemPage
import NewProperty from "layouts/management/properties/new-property";
import EditProperty from "layouts/management/properties/edit-property";
import CompanySettings from "layouts/management/company/settings";
import PropertiesList from "layouts/management/properties/properties-list";
import PropertyGroups from "layouts/management/properties/property-groups";
import PropertyOverview from "layouts/management/properties/property-overview";
import ServicePartnersList from "layouts/management/servicePartners/service-partners-list";
import Team from "layouts/management/team";
import SchedulePage from "layouts/management/schedulePage";
import Icons from "layouts/management/schedulePage/icons";
import ProtectedRoute from "./ProtectedRoute";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import BookingReportPage from "layouts/management/bookingReports";
//import { GetTranslation } from "services/getTranslationForRoutes";
import { useTranslation } from "react-i18next";

// function GetTranslation({ key }) {
//   const { t } = useTranslation("common");
//   console.log("key", key);
//   //const translation = t(key);
//   return "Dashboard";
// }
const routes = [
  {
    name: "mobile-apps",
    key: "mobile-apps",
    route: "/marketing/mobile-apps",
    component: <MobileApps />,
  },
  {
    name: "login",
    key: "login",
    route: "/authentication/login",
    // component: <SignInBasic />,
    component: <SignIn />,
  },

  // {
  //   name: "login",
  //   key: "login",
  //   route: "/authentication/new-login",
  //   component: <SignIn />,
  // },

  {
    name: "login",
    key: "login",
    route: "/authentication/login/:redirectUrl",
    component: <SignInBasic />,
  },

  {
    name: "signup",
    key: "signup",
    route: "/authentication/sign-up",
    component: <EmailSignin />,
  },
  {
    name: "reset-password",
    key: "reset-password",
    route: "/authentication/reset-password",
    component: <ResetBasic />,
  },
  {
    name: "error-404",
    key: "error-404",
    route: "/authentication/error/404",
    component: <Error404 />,
  },
  {
    name: "new-password",
    key: "new-password",
    route: "/authentication/new-password/:oobCode",
    component: <NewPassword />,
  },
  {
    name: "blog-subscription",
    key: "blog-subscription",
    route: "/opt-in/:oobCode",
    component: <SubscribeToBlog />,
  },

  {
    name: "Basic",
    key: "basic",
    route: "/authentication/verification",
    component: <VerificationBasic />,
  },
  {
    name: "test-typography",
    key: "test-typography",
    route: "/typography",
    component: (
      <ProtectedRoute>
        <TestTypography />
      </ProtectedRoute>
    ),
  },
  //TestIntegrationSelect
  // {
  //   name: "integration-select",
  //   key: "integration-select",
  //   route: "/integration-select",
  //   component: (
  //     <ProtectedRoute>
  //       <TestIntegrationSelect />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    name: "test-event-card",
    key: "test-event-card",
    route: "/test-event-card",
    component: (
      <ProtectedRoute>
        <TestNewEventWizard />
      </ProtectedRoute>
    ),
  },
  {
    name: "test-plaid",
    key: "test-plaid",
    route: "/test-plaid",
    component: (
      <ProtectedRoute>
        <TestPlaid />
      </ProtectedRoute>
    ),
  },
  {
    name: "App Administration",
    key: "app-Administration",
    route: "/administration/customers",
    component: (
      <ProtectedRoute>
        <CustomerDataTables />
      </ProtectedRoute>
    ),
  },
  {
    name: "App Administration Company Service - Properties",
    key: "app-Administration-Company-Service-Properties",
    route: "/administration/customers/:companyId/properties",
    component: (
      <ProtectedRoute>
        <CompanyProperties />
      </ProtectedRoute>
    ),
  },
  {
    name: "App Administration Message Service",
    key: "app-Administration-Message-Service",
    route: "/administration/messages",
    component: (
      <ProtectedRoute>
        <CompanyMessages />
      </ProtectedRoute>
    ),
  },
  {
    name: "App Administration Company Service - Users",
    key: "app-Administration-Company-Service-Users",
    route: "/administration/customers/:companyId/users",
    component: (
      <ProtectedRoute>
        <CompanyUsers />
      </ProtectedRoute>
    ),
  },
  {
    name: "Company Service Dashboard",
    key: "app-Administration-Company-Service-Dashboard",
    route: "/administration/customers/:companyId",
    component: (
      <ProtectedRoute>
        <CompanyDashboard />
      </ProtectedRoute>
    ),
  },
  {
    name: "test-file-upload",
    key: "test-file-upload",
    route: "/test-file-upload",
    component: (
      <ProtectedRoute>
        <TestFileUpload />
      </ProtectedRoute>
    ),
  },

  {
    name: "video-libray",
    key: "video-libray",
    route: "/video-resources",
    component: (
      <ProtectedRoute>
        <VideoLibrary />
      </ProtectedRoute>
    ),
  },
  // {
  //   name: "test-finance-approval-table",
  //   key: "test-finance-approval-table",
  //   route: "/test-finance-approval-table",
  //   component: (
  //     <ProtectedRoute>
  //       <TestBulkTransactionManagement />
  //     </ProtectedRoute>
  //   ),
  // },
  //PricingPage
  {
    name: "subscription-landing",
    key: "subscription-landing",
    route: "/subscription",
    component: (
      <ProtectedRoute>
        <PricingPage />
      </ProtectedRoute>
    ),
  },
  {
    name: "test-checklist",
    key: "test-checklist",
    route: "/test-checklist",
    component: (
      <ProtectedRoute>
        <TestNewChecklist />
      </ProtectedRoute>
    ),
  },
  {
    name: "test-settings",
    key: "test-settings",
    route: "/test-settings",
    component: (
      <ProtectedRoute>
        <TestDynamicSettings />
      </ProtectedRoute>
    ),
  },
  //TestDynamicSettings

  {
    name: "app-AuthRedirect",
    key: "app-AuthRedirect",
    route: "/deeplink/:redirectUrl",
    component: <AuthRedirect />,
  },

  {
    name: "property-bookingReports",
    key: "property-bookingReports",
    route: "/service-reports/:propertyId",
    component: (
      <ProtectedRoute routeLookUpKey="serviceReports">
        <PropertyBookingReportList />
      </ProtectedRoute>
    ),
  },
  // {
  //   name: "property-bookingReports",
  //   key: "property-bookingReports",
  //   route: "/properties/bookingReport",
  //   component: (
  //     <ProtectedRoute>
  //       <BookingReports />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    name: "view-bookingReport2",
    key: "view-bookingReport2",
    route: "/service-reports/:propertyId/:bookingReportId",
    component: (
      <ProtectedRoute routeLookUpKey="serviceReports">
        <BookingReportPage />
      </ProtectedRoute>
    ),
  },

  {
    type: "collapse",
    name: "home",
    key: "main-dashboard",
    route: "/",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-shop"
      />
    ),
    component: (
      <ProtectedRoute routeLookUpKey="common">
        <AppHomePage />
      </ProtectedRoute>
    ),
    protect: true,
    noCollapse: true,
  },
  { type: "title", title: "Management", key: "title-management" },

  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="warning"
        fontSize="14px"
        className="ni ni-calendar-grid-58"
      />
    ),
    noCollapse: true,
    name: "Calendar",
    key: "calendar",
    route: "/calendar",
    component: (
      <ProtectedRoute routeLookUpKey="calendar">
        <SchedulePage />
      </ProtectedRoute>
    ),
  },
  {
    name: "service-appointment-direct",
    key: "service-appointment-direct",
    route: "/calendar/:appointmentId",
    component: (
      <ProtectedRoute routeLookUpKey="calendar">
        <SchedulePage />
      </ProtectedRoute>
    ),
  },
  {
    name: "service-appointment-create",
    key: "service-appointment-create",
    route: "/calendar/create",
    component: (
      <ProtectedRoute>
        <SchedulePage createAppointment={true} />
      </ProtectedRoute>
    ),
  },
  {
    name: "service-partners-accept-invitation",
    key: "service-partners-accept-invitation",
    route: "/service-partners/accept-invitation/:tokenCode",
    component: (
      <ProtectedRoute>
        <AppHomePage />
      </ProtectedRoute>
    ),
  },

  {
    name: "create-new-property",
    key: "create-new-property",
    route: "/properties/create",
    component: (
      <ProtectedRoute>
        <PropertiesList createProperty={true} />
      </ProtectedRoute>
    ),
  },

  {
    name: "create-new-property",
    key: "create-new-property",
    route: "/property-group-settings",
    component: (
      <ProtectedRoute>
        <PropertiesList propertyGroupSettings={true} />
      </ProtectedRoute>
    ),
  },

  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-building"
      />
    ),
    noCollapse: true,
    name: "Properties",
    key: "properties-list",
    route: "/properties",
    component: (
      <ProtectedRoute routeLookUpKey="properties">
        <PropertiesList />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-building"
      />
    ),
    noCollapse: true,
    name: "Properties",
    key: "properties-list",
    route: "/groups/properties",
    component: (
      <ProtectedRoute routeLookUpKey="properties">
        <PropertyGroups />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="info"
        fontSize="14px"
        className="ni ni-folder-17"
      />
    ),
    noCollapse: true,
    name: "Service Reports",
    key: "service-reports",
    route: "/service-reports",
    component: (
      <ProtectedRoute routeLookUpKey="serviceReports">
        <PropertyBookingReportList />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "collapse",
  //   icon: (
  //     <ArgonBox
  //       component="i"
  //       color="primary"
  //       fontSize="14px"
  //       className="ni ni-building"
  //     />
  //   ),
  //   noCollapse: true,
  //   name: "Service Partners",
  //   key: "Service-Partners-list",
  //   route: "/service-partners",
  //   component: (
  //     <ProtectedRoute>
  //       <ServicePartnersList />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    name: "property-page",
    key: "property-page",
    route: "/properties/:id",
    component: (
      <ProtectedRoute routeLookUpKey="properties">
        <PropertyOverview />
      </ProtectedRoute>
    ),
  },
  {
    name: "subscription-signup-thank-you",
    key: "subscription-signup-thank-you",
    route: "/sign-up/:email",
    component: <SignUp />,
  },
  {
    name: "subscription-signup-thank-you",
    key: "subscription-signup-thank-you",
    route: "/sign-up",
    component: <SignUp />,
  },
  // {
  //   name: "blog-signup-thank-you",
  //   key: "blog-signup-thank-you",
  //   route: "/subscribe/:email",
  //   component: (
  //     <ProtectedRoute>
  //       <SubscriptionEvent />
  //     </ProtectedRoute>
  //   ),
  // },

  // {
  //   name: "property-page",
  //   key: "service-partners-property-page",
  //   route: "/service-partners/:companyId/properties/:id",
  //   component: (
  //     <ProtectedRoute>
  //       <PropertyOverview />
  //     </ProtectedRoute>
  //   ),
  // },

  { type: "title", title: "Administration", key: "title-administration" },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="success"
        fontSize="14px"
        className="ni ni-books"
      />
    ),
    noCollapse: true,
    name: "Tasks",
    key: "Tasks-Page",
    route: "/tasks",
    component: (
      <ProtectedRoute routeLookUpKey="tasks">
        <TasksCenterPage />
      </ProtectedRoute>
    ),
  },
  {
    name: "Create Task",
    key: "create-item",
    route: "/tasks/create",
    component: (
      <ProtectedRoute routeLookUpKey="tasks">
        <TasksCenterPage createTask={true} />
      </ProtectedRoute>
    ),
  },
  {
    name: "Task item",
    key: "Task-item",
    route: "/tasks/:taskItemId",
    component: (
      <ProtectedRoute routeLookUpKey="tasks">
        <TaskItemPage />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="info"
        fontSize="14px"
        className="ni ni-chart-bar-32"
      />
    ),
    noCollapse: true,
    name: "Inventory",
    key: "Inventory-dashboard",
    route: "/inventory",
    component: (
      <ProtectedRoute routeLookUpKey="inventory">
        <Inventory />
      </ProtectedRoute>
    ),
  },
  {
    name: "Inventory",
    key: "Inventory-item",
    route: "/inventory/:inventoryItemId",
    component: (
      <ProtectedRoute routeLookUpKey="inventory">
        <InventoryItemPage />
      </ProtectedRoute>
    ),
  },
  {
    name: "Messages",
    key: "messages",
    route: "/messages",
    component: (
      <ProtectedRoute routeLookUpKey="messages">
        <Messages />
      </ProtectedRoute>
    ),
  },
  {
    name: "ViewNotifications",
    key: "view-notifications",
    route: "/notifications",
    component: (
      <ProtectedRoute routeLookUpKey="common">
        <Notifications />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="error"
        fontSize="14px"
        className="ni ni-money-coins"
      />
    ),
    noCollapse: true,
    name: "Finance",
    key: "Finance",
    route: "/finance",
    component: (
      <ProtectedRoute
        routeLookUpKey="finance"
        // permittedRoles={["admin", "manager"]}
      >
        <Transactions />
      </ProtectedRoute>
    ),
  },

  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="error"
        fontSize="14px"
        className="ni ni-money-coins"
      />
    ),
    noCollapse: true,
    name: "Company Address Book",
    key: "company-address-book",
    route: "/contacts",
    component: (
      <ProtectedRoute
        routeLookUpKey="company"
        // permittedRoles={["admin", "manager"]}
      >
        <Contacts />
      </ProtectedRoute>
    ),
  },
  {
    name: "finance-report",
    key: "finance-report",
    route: "/finance/report",
    component: (
      <ProtectedRoute routeLookUpKey="finance">
        <FinanaceReport />
      </ProtectedRoute>
    ),
  },

  // {
  //   name: "Task Center",
  //   key: "Task-center",
  //   route: "/tasks/:companyId/:propertyId",
  //   component: (
  //     <ProtectedRoute>
  //       <TaskCenter />
  //     </ProtectedRoute>
  //   ),
  // },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-user-run"
      />
    ),
    name: "My Team",
    key: "my-team-list",
    route: "/users",
    component: (
      <ProtectedRoute routeLookUpKey="company">
        <Team />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    name: "new-user",
    key: "new-user",
    route: "/users/new-user",
    component: (
      <ProtectedRoute routeLookUpKey="company">
        <NewUser />
      </ProtectedRoute>
    ),
  },
  {
    type: "collapse",
    icon: (
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-watch-time"
      />
    ),
    name: "Time Tracking",
    key: "my-team-time-tracking",
    route: "/time-tracking",
    component: (
      <ProtectedRoute routeLookUpKey="timeTracking">
        <TimeTrackingPage />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    name: "user-page",
    key: "user-page",
    route: "/my-profile",
    component: (
      <ProtectedRoute routeLookUpKey="common">
        <ProfileOverview />
      </ProtectedRoute>
    ),
  },

  { type: "title", title: "Payments", key: "title-payments" },
  {
    type: "collapse",
    name: "Service Invoicing",
    //briefcase-24
    key: "servicenvoicing",
    icon: (
      <ArgonBox
        component="i"
        color="inherit"
        fontSize="14px"
        className="ni ni-briefcase-24"
      />
    ),
    route: "/service-invoicing",
    component: (
      <ProtectedRoute routeLookUpKey="serviceInvoicing">
        <ServiceInvoicingDashboard />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    name: "service-invoicing-create",
    key: "service-invoicing-create",
    route: "/service-invoicing/create/:appointmentId",
    component: (
      <ProtectedRoute>
        <ServiceInvoicingDashboard createPaymentRequest={true} />
      </ProtectedRoute>
    ),
  },

  { type: "title", title: "Settings", key: "settings" },
  {
    type: "collapse",
    name: "Company Settings",
    key: "company-settings-tab",
    icon: (
      <ArgonBox
        component="i"
        color="inherit"
        fontSize="14px"
        className="ni ni-settings-gear-65"
      />
    ),
    route: "/company/settings",
    component: (
      <ProtectedRoute routeLookUpKey="company">
        <CompanySettings />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Support Center",
    key: "support-center",
    icon: (
      <ArgonBox
        component="i"
        color="inherit"
        fontSize="14px"
        className="ni ni-settings-gear-65"
      />
    ),
    route: "/support",
    component: (
      <ProtectedRoute routeLookUpKey="common">
        <GetSupport />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
  {
    name: "Support Item",
    key: "Support-item",
    route: "/support/:supportId",
    component: (
      <ProtectedRoute routeLookUpKey="common">
        <SupoprtItemPage />
      </ProtectedRoute>
    ),
  },
  // {
  //   type: "collapse",
  //   name: "Category Settings",
  //   key: "category-settings-tab",
  //   icon: (
  //     <ArgonBox
  //       component="i"
  //       color="inherit"
  //       fontSize="14px"
  //       className="ni ni-settings-gear-65"
  //     />
  //   ),
  //   route: "/company/settings/categories",
  //   component: (
  //     <ProtectedRoute>
  //       <CategorySettings />
  //     </ProtectedRoute>
  //   ),
  //   noCollapse: true,
  // },
  {
    name: "Frequently Asked Questions-Apple",
    key: "frequency-asked-questions-apple",
    route: "/faq",
    component: <PublicSupport />,
    noCollapse: true,
  },
  {
    name: "Frequently Asked Questions",
    key: "frequency-asked-questions",
    route: "/frequently-asked-questions",
    component: <PublicSupport hideHelp={true} />,
    noCollapse: true,
  },
  {
    name: "Company Settings",
    key: "company-id-settings",
    route: "/company/settings/:id",
    component: (
      <ProtectedRoute routeLookUpKey="company">
        <CompanySettings />
      </ProtectedRoute>
    ),
    noCollapse: true,
  },
];

export default routes;
