/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import FormField from "layouts/management/users/new-user/components/FormField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import HostHiveLoading from "components/HostHiveLoading";
import CompaniesApi from "api/companyApi";
import ChangeRequestsApi from "api/changeRequestsApi";
function ServiceAppointmentChangeRequest({
  onClose,
  bookingInfo = null,
  changeRequestData = null,
  setFetchChangeRequestDetails = null,
}) {
  const resetTheme = createTheme();
  const [error, setError] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const changeRequestsApi = ChangeRequestsApi();
  const [startTime, setStartTime] = useState(
    bookingInfo?.timeObject?.startTime || null
  );

  const [startDate, setStartDate] = useState(
    //    bookingInfo?.serviceScheduleDate || scheduleStartDate || null
    dayjs(bookingInfo?.serviceScheduleDate || null)
    //
  );

  useEffect(() => {
    console.log(changeRequestData);
  }, []);

  const handleDateChange = (date) => {
    console.log(date);
    setStartDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const handleTimeChange = (time) => {
    setStartTime(dayjs(time).format("HH:mm"));
  };

  const intervalOptions = [
    { value: "minutes", label: "Minutes" },
    { value: "hours", label: "Hours" },
    { value: "days", label: "Days" },
  ];

  const handleSubmitClicked = async () => {
    if (changeRequestData?.id) {
      try {
        const response = await changeRequestsApi.cancelChangeRequest(
          changeRequestData?.id
        );
        if (setFetchChangeRequestDetails) {
          setFetchChangeRequestDetails();
        }
        if (response.status >= 200 && response.status < 300) {
          onClose();
        }
      } catch (error) {
        setError(error.response.data.detail);
        setLoadingResponse(false);
        console.error(error);
      }
      return;
    }
    //setLoadingResponse(!loadingResponse);
    try {
      // console.log("bookingInfo", bookingInfo);
      let payload = {
        entityId: bookingInfo?.id,
        entityType: "booking",
        companyId: bookingInfo?.companyId,
        changeType: "serviceScheduleDate",
        changeRequestDetails: {
          before: {
            serviceScheduleDate: bookingInfo?.serviceScheduleDate,
            timeObject: bookingInfo?.timeObject,
          },
          after: {
            serviceScheduleDate: startDate,
            timeObject: {
              startTime: startTime,
            },
          },
        },
      };
      console.log("payload", bookingInfo);
      const response = await changeRequestsApi.createChangeRequest(payload);
      setFetchChangeRequestDetails();
      if (response.status >= 200 && response.status < 300) {
        onClose();
      }
    } catch (error) {
      setError(error.response.data.detail);
      setLoadingResponse(false);
      console.error(error);
    }
  };

  return (
    <>
      {loadingResponse ? (
        <HostHiveLoading message="Submitting change Request..." />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <ArgonBox ml={2.5} lineHeight={0} display="inline-block">
                <ArgonBox pt={2}>
                  <ArgonBox display="flex">
                    <ArgonBox>
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Service:
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox pl={6}>
                      <ArgonTypography component="label" variant="caption">
                        {bookingInfo?.title}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>

                  <ArgonBox pt={1}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Date & Time:{" "}
                    </ArgonTypography>
                    <ArgonTypography component="label" variant="caption">
                      {bookingInfo?.serviceScheduleDate}{" "}
                      {bookingInfo?.timeObject?.startTime
                        ? bookingInfo?.timeObject?.startTime
                        : null}
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox pt={1}>
                    <ArgonBox display="flex">
                      <ArgonBox>
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Location:
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox pl={4.3}>
                        <ArgonBox>
                          <ArgonTypography component="label" variant="caption">
                            {
                              bookingInfo?.propertyInfo?.propertyAddress
                                ?.address1
                            }
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox>
                          <ArgonTypography component="label" variant="caption">
                            {bookingInfo?.propertyAddress?.city},{" "}
                            {bookingInfo?.propertyAddress?.state},{" "}
                            {bookingInfo?.propertyAddress?.zipCode},{" "}
                            {bookingInfo?.propertyAddress?.country
                              ? bookingInfo?.propertyAddress?.country
                              : "USA"}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="bold" color="error">
                  {" "}
                  {error}
                </ArgonTypography>

                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Service Date
                    </ArgonTypography>
                  </ArgonBox>
                  {changeRequestData?.id ? (
                    changeRequestData?.changeRequestDetails?.after
                      ?.serviceScheduleDate
                  ) : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={handleDateChange}
                        value={startDate}
                        orientation="landscape"
                      />
                    </LocalizationProvider>
                  )}
                </ArgonBox>
              </ArgonBox>
            </Grid>

            <Grid item xs={12}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="bold" color="error">
                  {" "}
                  {error}
                </ArgonTypography>

                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Service Time
                    </ArgonTypography>
                  </ArgonBox>
                  {changeRequestData?.id ? null : (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <ThemeProvider theme={resetTheme}>
                        <TimePicker
                          value={
                            startTime
                              ? dayjs()
                                  .hour(startTime.split(":")[0])
                                  .minute(startTime.split(":")[1])
                              : null
                          }
                          onChange={handleTimeChange}
                          sx={{
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                borderColor: "#dee2e6",
                              },
                          }}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  )}
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox p={1}>
                    <ArgonBox
                      display="flex"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox pr={2}>
                        <ArgonButton
                          variant="gradient"
                          color="dark"
                          onClick={onClose}
                        >
                          Close
                        </ArgonButton>
                      </ArgonBox>
                      <ArgonBox pr={2}>
                        <ArgonButton
                          variant="gradient"
                          color={changeRequestData?.id ? "error" : "success"}
                          onClick={handleSubmitClicked}
                        >
                          {changeRequestData?.id ? "Cancel Request" : "Submit"}
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  {/* Display role checkboxes here based on viewMode */}
                </ArgonBox>
              </ArgonBox>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default ServiceAppointmentChangeRequest;
