import * as Yup from "yup";

const PropertyTaskSchema = Yup.object().shape({
  summary: Yup.string().required("Summary is required"),
  description: Yup.string().required("Description is required"),
  priority: Yup.string()
    .oneOf(["low", "medium", "high"])
    .required("Priority is required")
    .default("medium"),
  companyId: Yup.string().required("CompanyId is required"),
  propertyId: Yup.string().nullable(true),
  status: Yup.string(),
  categoryId: Yup.string().nullable(true),
  subAssigneeId: Yup.string().nullable(true),
  comments: Yup.string().nullable(true),
});

export default PropertyTaskSchema;
