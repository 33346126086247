import { useState, useEffect } from "react";
// @mui material components

import HostHiveDialog from "components/HostHiveDialog";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import ArgonSelect from "components/ArgonSelect";
import CategoriesApi from "api/categoriesApi";

import CategorySchema from "schemas/categorySchema";
import { useFormik } from "formik";

import CategoryDialog from "../CategoryDialog";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveKbHelp from "components/HostHiveKbHelp";

import { useTranslation } from "react-i18next";
import ArgonBadgeDot from "components/ArgonBadgeDot";
function CategorySelect({
  categoryType,
  companyId,
  setCategory,
  category,
  categoryItem = null,
  optional = false,
  menuPlacement = "bottom",
  includeTitle = true,
  handleCategoryChange,
  entityId,
  categories,
  setCategories,
}) {
  const { t } = useTranslation("common");
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const categoriesApi = CategoriesApi();
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [fetchCategories, setFetchCategories] = useState(false);
  useEffect(() => {
    async function fetchCategories() {
      if (companyId) {
        try {
          const queryParams = {
            companyId: companyId,
            type: categoryType,
          };

          const response = await categoriesApi.getCategories(queryParams);
          const categoriesResponse = response.data;

          let options = categoriesResponse.map((category) => ({
            value: category.id,
            label: (
              <ArgonBadgeDot
                color={category?.color}
                size="md"
                badgeContent={category?.name}
              />
            ),
          }));

          options.push({
            value: "new",
            label: `+ ${t("components.category.create.createNew")}`,
          });
          if (setCategories) {
            setCategories(options);
          }
          setCategoriesOptions(options);

          console.log(options);
        } catch (error) {
          console.error(error);
        }
      }
    }

    fetchCategories();
  }, [companyId, fetchCategories]);

  const handleCategorySelected = (e) => {
    if (handleCategoryChange) {
      handleCategoryChange(e.value, entityId);
      return;
    }
    if (e.value === "new") {
      setIsNewCategory(true);
    } else {
      setIsNewCategory(false);
      setCategory(e);
    }

    // other logic you may need when a company is selected
  };

  // generate options array, including the "Create New" option

  const [newCategory, setNewCategroy] = useState("");
  const handleSaveNewCategory = async (values, actions) => {
    console.log(values);
    //const response = await categoriesApi.createCategory(values, actions);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      name: categoryItem?.name || "",
      companyId: categoryItem?.companyId || "",
      type: categoryItem?.type || categoryType,
      status: categoryItem?.status || "active",
    },
    validationSchema: CategorySchema,
    handleSaveNewCategory,
  });
  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      {renderSuccessSB}

      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={isNewCategory}
        onClose={() => {
          setIsNewCategory(false);
        }}
        title={t("components.category.create.createNew")}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <CategoryDialog
            categoryType={categoryType}
            companyId={companyId}
            handleCategorySelected={handleCategorySelected}
            setCategoryDetails={setCategoryDetails}
            handleSelection={true}
            // fetchServiceNames={() =>
            //   setCreatedServiceName(!createdServiceName)
            // }
            fetchCategories={() => setFetchCategories(!fetchCategories)}
            onClose={() => {
              setIsNewCategory(false);
            }}
            snackBarOpen={openSuccessSB}
          />
        }
      />
      {includeTitle ? (
        <ArgonBox mb={1} ml={0.5} mt={1} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {categoryType === "accounting" ? "Finance" : categoryType}{" "}
            {optional ? "(Optional)" : null}
            {t("components.category.title")}
          </ArgonTypography>
          <HostHiveKbHelp lookupKey="checklist" marginBottom={0} />
        </ArgonBox>
      ) : null}

      <ArgonSelect
        menuPlacement={menuPlacement}
        defaultValue={{ value: "none", label: t("components.category.select") }}
        value={category}
        options={categoriesOptions}
        onChange={handleCategorySelected}
        fullWidth
      />
    </ArgonBox>
  );
}

export default CategorySelect;
