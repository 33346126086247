/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ServiceNameApi from "api/service-nameApi";
import CompaniesApi from "api/companyApi";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import PropertyContext from "context/PropertyContext";
import { useTranslation } from "react-i18next";
function ServicePartnerItemDialog({
  onClose,
  handlePartnerSelected,
  handleSelection = false,
  fetchServicePartners,
  snackBarOpen,
  setServiceNameDetails,
}) {
  const { t } = useTranslation("common");
  const [newServicePartner, setNewServicePartner] = useState();
  const [isEdit, setIsEdit] = useState(false);

  const companiesApi = CompaniesApi();
  const toggleLoading = () => setLoadingResponse((prevState) => !prevState);

  const handleSubmitClicked = async () => {
    toggleLoading();

    const commonResponseTasks = async (response, actionVerb) => {
      if (response.status >= 200 && response.status < 300) {
        // setCompanyServiceNames((prevServiceNames) => [
        //   ...(Array.isArray(prevServiceNames) ? prevServiceNames : []),
        //   response.data,
        // ]);

        if (handleSelection) {
          handlePartnerSelected({
            label: `${response.data.name}`, //response.data.name + " - $" + response.data.cost,
            value: response.data.id,
            warning: response.data?.warning || null,
          });
        }
        if (snackBarOpen) {
          snackBarOpen(
            `Service name ${actionVerb}`,
            `Successfully ${actionVerb} service partner ${newServicePartner}`
          );
        }
        onClose();
      }
    };

    const createServicePartner = async () => {
      try {
        const response = await companiesApi.createServicePartner(
          newServicePartner
        );
        await commonResponseTasks(response, "created");
      } catch (error) {
        console.error("Error adding service partner:", error);
      }
    };

    await createServicePartner();
  };

  const [loadingResponse, setLoadingResponse] = useState(false);
  return (
    <>
      {loadingResponse ? (
        <>
          <ArgonBox display="flex">
            <ArgonTypography variant="caption"></ArgonTypography>
            <HostHiveLoading
              message={`${
                isEdit ? t("adverbs.updating") : t("adverbs.addingNew")
              }${" "}
              ${t("components.serviceName.title")}`}
              elipse={true}
            />
          </ArgonBox>
        </>
      ) : (
        <>
          <ArgonBox p={2} lineHeight={1}>
            <ArgonBox mb={2}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Email address of your service partner
                  </ArgonTypography>
                </ArgonBox>
                <ArgonInput
                  placeholder="eg. busybee@hosthive.io"
                  type="text"
                  value={newServicePartner}
                  onChange={(e) => setNewServicePartner(e.target.value)}
                />
              </ArgonBox>
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox p={1}>
                <ArgonBox
                  display="flex"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={onClose}
                    >
                      {t("buttons.cancel")}
                    </ArgonButton>
                  </ArgonBox>
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmitClicked}
                    >
                      Add Partner
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
              {/* Display role checkboxes here based on viewMode */}
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
}

export default ServicePartnerItemDialog;
