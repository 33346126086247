/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import ServicePartnerItemDialog from "./components/ServicePartnerItemDialog";
import ServicePartnerDialog from "layouts/management/components/ServicePartnerDialog";
import ServicePartnerItem from "./components/ServicePartnerItem";
import HostHiveLoading from "components/HostHiveLoading";
import CompanyApi from "api/companyApi";
import { useParams } from "react-router-dom";
import DynamicSettings from "layouts/management/components/DynamicSettings";
import HostHiveSnackbar from "components/HostHiveSnackbar";
function ApplicationSettings({}) {
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const companyApi = CompanyApi();
  const [servicePartners, setServicePartners] = useState([]);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [servicePartner, setServicePartner] = useState({});
  const [servicePartnerDialogOpen, setServiceNameDialogOpen] = useState(false);
  const [servicePartnersLoading, setServicePartnersLoading] = useState(true);
  const [invitedCompany, setInvitedCompany] = useState({});

  const handleSubmitClicked = () => {
    setServiceNameDialogOpen(false);
  };

  const handleCancelClicked = () => {
    setServiceNameDialogOpen(false);
  };
  return (
    <Card
      id="application-settings"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <ArgonBox p={3}>
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">Application Settings</ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox
          p={2}
          mt={1}
          width="100%"
          //height="26.25rem"
          height="auto"
          style={{ overflow: "auto" }}
        >
          {renderSuccessSB}
          <DynamicSettings
            settingsType="companies"
            entityId={company?.id}
            snackBarOpen={openSuccessSB}
            userCompanyId={company?.id}
          />
          <DynamicSettings
            settingsType="serviceAppointments"
            entityId={company?.id}
            snackBarOpen={openSuccessSB}
            userCompanyId={company?.id}
          />
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ApplicationSettings;
