import { useState, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

import CustomerSelection from "layouts/management/serviceInvoicing/components/CreateInvoiceWizard/components/CustomerSelection";

import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import ServiceInvoicingApi from "api/serviceInvoicing";
import { t } from "i18next";
import HostHiveDialog from "components/HostHiveDialog";
import InvoiceLineItemComponent from "layouts/management/serviceInvoicing/components/CreateInvoiceWizard/components/InvoiceLineItems";
import ReviewPage from "layouts/management/serviceInvoicing/components/Review";
function getSteps() {
  return ["Customer Information", "Invoice Line Items", "Review"];
}

function getStepContent(
  stepIndex,
  setNextDisabled,
  invoicePayload,
  setInvoicePayload,
  error,
  showAlert
) {
  console.log("Step Index", stepIndex);
  switch (stepIndex) {
    case 0:
      return (
        <CustomerSelection
          setNextDisabled={setNextDisabled}
          invoicePayload={invoicePayload}
          setInvoicePayload={setInvoicePayload}
        />
      );
    case 1:
      return (
        <InvoiceLineItemComponent
          setNextDisabled={setNextDisabled}
          invoicePayload={invoicePayload}
          setInvoicePayload={setInvoicePayload}
        />
      );
    case 2:
      return (
        <ReviewPage
          setNextDisabled={setNextDisabled}
          invoicePayload={invoicePayload}
          setInvoicePayload={setInvoicePayload}
          error={error}
          showAlert={showAlert}
        />
      );
    default:
      return null;
  }
}

function CreateInvoiceWizard({
  closeWizard,
  fetchInvoices,
  customerInfo = null,
}) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const serviceInvoicingApi = new ServiceInvoicingApi();
  const [saving, setSaving] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  // const [bulkChangePayloadMapping, setBulkChangePayloadMapping] = useState({}); // eslint-disable-line
  const [activeStep, setActiveStep] = useState(0);
  const [invoicePayload, setInvoicePayload] = useState({
    companyName: customerInfo?.companyName || null,
    email: customerInfo?.companyEmail || null,
  });
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [userSelections, setUserSelections] = useState({});
  const [responseData, setResponseData] = useState({});
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleSave = async () => {
    // delete isOwner from invoicePayload
    console.log("Invoice Payload created", invoicePayload);
    setSaving(true);
    try {
      const response = await serviceInvoicingApi.createInvoice(invoicePayload);
      console.log("Response", response);
      fetchInvoices();

      closeWizard();
    } catch (error) {
      console.log("Error creating invoice", error);
      setError(error);
    }
  };
  const submitForm = async () => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));
    // handleSave();
    // actions.setSubmitting(false);
    // actions.resetForm();

    setActiveStep(0);

    closeWizard();
  };

  const handleSubmit = (values, actions) => {
    console.log("Active Step", activeStep, "Steps Length", steps.length - 1);
    if (isLastStep) {
      submitForm();
    } else {
      console.log("Active Step", activeStep, "Steps Length", steps.length - 1);
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("Invoce Payload", invoicePayload);
    // console.log("User Selections", userSelections);
  }, [invoicePayload]);
  const handleNextClicked = () => {
    console.log("Next Clicked", activeStep, steps.length - 1, isLastStep);
    if (isLastStep) {
      handleSave();
      // closeWizard();
    } else {
      setActiveStep(activeStep + 1);
      setNextDisabled(true);
    }
  };
  return (
    <>
      <Card
        sx={{
          display: "grid",
          alignItems: "center",
          position: "relative",
          height: "6rem",
          borderRadius: "lg",
          mb: 3,
        }}
      >
        <Stepper activeStep={activeStep} sx={{ margin: 0 }} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form id={formId} autoComplete="off">
            <Card sx={{ height: "100%" }}>
              <ArgonBox p={2}>
                <ArgonBox>
                  {getStepContent(
                    activeStep,
                    setNextDisabled,
                    invoicePayload,
                    setInvoicePayload,
                    error,
                    showAlert,
                    customerInfo
                  )}
                  <ArgonBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <ArgonButton
                      variant="gradient"
                      color="light"
                      onClick={closeWizard}
                    >
                      Cancel
                    </ArgonButton>
                    {activeStep === 0 ? (
                      <ArgonBox />
                    ) : (
                      <ArgonButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        Back
                      </ArgonButton>
                    )}
                    {/* <ArgonButton
                      // disabled={nextDisabled}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                        console.log("Button Clicked", activeStep);
                        setNextDisabled(isLastStep ? false : true);
                        // isLastStep && handleSave();
                      }}
                    >
                      {isLastStep ? "Create" : "Next"}
                    </ArgonButton> */}
                    <ArgonButton
                      disabled={saving || nextDisabled}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        // setActiveStep(activeStep + 1);
                        // setNextDisabled(true);
                        handleNextClicked();
                      }}
                    >
                      {isLastStep
                        ? saving
                          ? "Creating..."
                          : "Create"
                        : "Next"}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateInvoiceWizard;
