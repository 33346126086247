import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import PlaidApi from "api/plaidApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function TestPlaid({ buttonOnly = false }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [linkToken, setLinkToken] = useState(null);
  const plaidApi = PlaidApi();

  const generateToken = async () => {
    const companyData = {
      companyId: company.id,
      companyName: company.name,
    };
    const response = await plaidApi.linkToken(companyData);
    const data = response.data;
    setLinkToken(data.linkToken);
  };

  useEffect(() => {
    generateToken();
  }, []);

  const onSuccess = async (public_token, metadata) => {
    const publicTokenData = {
      publicToken: public_token,
      metadata: metadata,
      companyId: company.id,
    };
    try {
      const response = await plaidApi.exchangePublicToken(publicTokenData);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <>
      {!buttonOnly ? (
        <>
          <DashboardLayout>
            <ArgonBox mt={3} mb={4}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={9}>
                  <Card sx={{ overflow: "visible" }}>
                    <ArgonBox p={2} lineHeight={1}>
                      <ArgonButton
                        variant="gradient"
                        color="dark"
                        onClick={() => open()}
                        disabled={!ready || !linkToken}
                      >
                        Link Account
                      </ArgonButton>
                    </ArgonBox>
                  </Card>
                </Grid>
              </Grid>
            </ArgonBox>
          </DashboardLayout>
        </>
      ) : (
        <>
          <ArgonButton
            variant="gradient"
            color="dark"
            onClick={() => open()}
            disabled={!ready || !linkToken}
          >
            Link Account
          </ArgonButton>
        </>
      )}
    </>
  );
}

export default TestPlaid;
