import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import Completion from "layouts/management/bookingReports/propertyBookingReportList/components/Progress";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";

export const columns = [
  {
    Header: "Summary",
    accessor: "summary",
    Cell: ({ value, row }) => {
      const [name, data] = value;
      const navigate = useNavigate();
      return name ? (
        <DefaultCell
          value={typeof value === "string" ? value : name}
          sxValue={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={() => navigate(`/support/${row.original.id}`)}
        />
      ) : null;
    },
  },
  {
    Header: "Service Desk Key",
    accessor: "serviceDeskKey",
    Cell: ({ value }) => {
      const [name, data] = value;
      const navigate = useNavigate();
      return name ? (
        <DefaultCell
          value={typeof value === "string" ? value : name}
          sxValue={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={() => navigate(`${data.reportUrl}`)}
        />
      ) : null;
    },
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;
      let statusText;

      status = (
        // <StatusCell
        //   icon={value?.icon}
        //   color={value?.color}
        //   status={value?.status}
        // />
        <ArgonBadge
          color={value === "resolved" ? "success" : "info"}
          size="xs"
          badgeContent={value}
          container
        />
      );

      return status;
    },
  },

  // {
  //   Header: "status",
  //   accessor: "statusInfo",
  //   Cell: ({ value }) => {
  //     let status;
  //     let statusText;

  //     status = (
  //       <StatusCell
  //         icon={value?.icon}
  //         color={value?.color}
  //         status={value?.status}
  //       />
  //     );

  //     return status;
  //   },
  // },
  // {
  //   Header: "Progress",
  //   accessor: "progress",
  //   Cell: ({ value }) => {
  //     let status;
  //     let statusText;

  //     status = value ? (
  //       <Completion value={value?.value} color={value?.color} />
  //     ) : null;

  //     return status;
  //   },
  // },
  // {
  //   Header: "Property",
  //   accessor: "propertyInfo",
  //   Cell: ({ value }) => <DefaultCell value={value?.name} />,
  // },
  // {
  //   Header: "Servicer",
  //   accessor: "assigneeName",
  //   Cell: ({ value }) => <DefaultCell value={value} />,
  // },
  //   {
  //     Header: "product",
  //     accessor: "product",
  //     Cell: ({ value }) => {
  //       const [name, data] = value;

  //       return (
  //         <DefaultCell
  //           value={typeof value === "string" ? value : name}
  //           suffix={data.suffix || false}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     Header: "revenue",
  //     accessor: "revenue",
  //     Cell: ({ value }) => <DefaultCell value={value} />,
  //   },
];

export const smColumns = [
  {
    Header: "Summary",
    accessor: "summary",
    Cell: ({ value }) => {
      const [name, data] = value;
      const navigate = useNavigate();
      return (
        <DefaultCell
          value={typeof value === "string" ? value : name}
          sxValue={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={() => navigate(`${data.reportUrl}`)}
        />
      );
    },
  },

  {
    Header: "Service Date",
    accessor: "serviceScheduleDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "complete") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="check"
            color="success"
            status="Service Complete"
          />
        );
      } else if (value === "open") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="loop"
            color="warning"
            status="In Progress"
          />
        );
      } else if (value === "pendingApproval") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="approval"
            color="warning"
            status="Pending Approval"
          />
        );
      } else {
        status = null;
      }

      return status;
    },
  },
  // ,
  // {
  //   Header: "Servicer",
  //   accessor: "assigneeName",
  //   Cell: ({ value }) => <DefaultCell value={value} />,
  // },
  //   {
  //     Header: "product",
  //     accessor: "product",
  //     Cell: ({ value }) => {
  //       const [name, data] = value;

  //       return (
  //         <DefaultCell
  //           value={typeof value === "string" ? value : name}
  //           suffix={data.suffix || false}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     Header: "revenue",
  //     accessor: "revenue",
  //     Cell: ({ value }) => <DefaultCell value={value} />,
  //   },
];
