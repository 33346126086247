import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChecklistItemDialog from "./ChecklistItemDialog";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import InventoryDataCard from "layouts/management/components/InventoryDataCard";
import { showDeleteConfirmation } from "services/confirmDelete";
import ChecklistsApi from "api/checkListsApi";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";
function ChecklistItem({ checklistDetails, fetchChecklists, openSuccessSB }) {
  const { t } = useTranslation("common");
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();
  const checklistApi = new ChecklistsApi();
  const [checklistDialogOpen, setChecklistDialogOpen] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handleDeleteAction = async (id) => {
    try {
      await showDeleteConfirmation(); // Wait for the promise to resolve/reject
      console.log(`User confirmed deletion! ${id}`);

      const deleteResponse = await checklistApi.deleteChecklist(id);
      if (deleteResponse.status === 200) {
        console.log("Checklist deleted successfully");
        fetchChecklists();
      }

      // Proceed with delete action
    } catch (error) {
      console.log("User cancelled deletion!");
    }
    setOpenMenu(null);
  };

  const renderMenu = (id) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* <MenuItem onClick={() => handleViewChecklistItemDetails(id)}>
        View
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          setChecklistDialogOpen(true);
          handleCloseMenu();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteAction(id);
          handleCloseMenu();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        mt: 2,
        ml: 3,
        pl: 0.5,
      }}
    >
      <div
        component="li"
        style={{
          width: "100%",
          pr: 2,
          mb: 2,
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <div style={{ width: "100%", pl: 1, ml: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
              ml: 3,
              pl: 0.5,
              alignItems: { xs: "flex-start", sm: "center" },
              overflow: "hidden",
            }}
          >
            <HostHiveDialog
              open={checklistDialogOpen}
              onClose={() => setChecklistDialogOpen(false)}
              includeSave={false}
              includeClose={false}
              title={t("components.checklist.edit.editChecklist")}
              fullScreen={false}
              cancelButtonText="Cancel"
              submitButtonText="Save"
              dialogObjects={
                <ChecklistItemDialog
                  checklistDetails={checklistDetails}
                  onClose={() => setChecklistDialogOpen(false)}
                  companyId={checklistDetails?.companyId}
                  fetchChecklists={fetchChecklists}
                  snackBarOpen={openSuccessSB}
                />
              }
            />
            {/* Summary */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {checklistDetails?.name}
              </ArgonTypography>
            </div>

            {renderMenu(checklistDetails?.id)}
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </div>
          </div>
        </div>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

// Setting default values for the props of Todo
ChecklistItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
ChecklistItem.propTypes = {
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  currentCount: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default ChecklistItem;
