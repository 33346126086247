/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
import ServiceAppointmentSettingsDialog from "../ServiceAppointmentSettings";
import SettingsApi from "api/settingsApi";
import HostHiveLoading from "components/HostHiveLoading";
import DynamicSettings from "layouts/management/components/DynamicSettings";

function PlatformSettings({ userId, userDetails }) {
  const settingsApi = new SettingsApi();
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [propertyUpdate, setPropertyUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(true);
  const [
    serviceAppointmentSettinsDialogOpen,
    setServiceAppointmentSettinsDialogOpen,
  ] = useState(false);
  const [userSettings, setUserSettings] = useState({});
  const [loadServiceAppointmentSettings, setLoadServiceAppointmentSettings] =
    useState(false);
  const [settingsLoading, setSettingsLoading] = useState(false);
  useEffect(() => {
    async function fetchUserSettings() {
      try {
        const queryParams = {
          forUi: true,
        };
        const response = await settingsApi.getSetting(
          "users",
          userId,
          queryParams
        );
        if (response.status >= 200 && response.status < 300) {
          setUserSettings(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 404) {
          setUserSettings({});
        }
      }

      setSettingsLoading(false);
    }
    fetchUserSettings();
  }, [loadServiceAppointmentSettings]);

  return (
    <>
      <Card>
        <ArgonBox pt={2} px={2}>
          <ArgonTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            platform settings
          </ArgonTypography>
        </ArgonBox>

        <>
          <DynamicSettings
            settingsType="users"
            entityId={userId}
            userCompanyId={userDetails?.companyId}
          />
        </>
      </Card>
    </>
  );
}

export default PlatformSettings;
