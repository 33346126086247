/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";

function TransactionItem({
  title,
  date,
  summary,
  description,
  amount,
  noDivider,
}) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleCloseMenu}>Action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Another action</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Something else here</MenuItem>
    </Menu>
  );

  const amountColor = amount > 0 ? "text" : "error";
  const AmountColorTransform = () => {
    if (amount > 0) {
      return (
        <ArgonTypography variant="caption" fontWeight="bold" color="success">
          ${amount}
        </ArgonTypography>
      );
    } else {
      console.log("amount", amount);
      return (
        <ArgonTypography variant="caption" fontWeight="bold" color="error">
          ${amount}
        </ArgonTypography>
      );
    }
  };
  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };
  return (
    <ArgonBox
      display="flex"
      alignItems={{ xs: "flex-start", sm: "center" }}
      flexDirection={{ xs: "column", sm: "row" }}
      mt={2}
      ml={3}
      pl={0.5}
    >
      <ArgonBox
        component="li"
        width="100%"
        pr={2}
        mb={2}
        sx={{ listStyle: "none" }}
      >
        <ArgonBox width="100%" pl={1} ml={2}>
          <ArgonBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            mt={2}
            ml={3}
            pl={0.5}
            alignItems={{ xs: "flex-start", sm: "center" }}
            overflow="hidden"
          >
            {/* Date */}
            <ArgonBox lineHeight={1} mb={{ xs: 1, sm: 0 }} flex={1}>
              
              <ArgonTypography variant="caption" fontWeight="bold" color="text">
                {formateDate(date)}
              </ArgonTypography>
            </ArgonBox>

            {/* Summary */}
            <ArgonBox
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
              flex={1}
            >
              
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {summary}
              </ArgonTypography>
            </ArgonBox>

            {/* Description */}
            <ArgonBox
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
              flex={1}
            >
              
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {description}
              </ArgonTypography>
            </ArgonBox>

            {/* Amount */}
            <ArgonBox mx={{ xs: 0, sm: "auto" }} lineHeight={1} flex={1}>
              
              <AmountColorTransform amount={amount} />
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        <Divider sx={{ marginBottom: 0 }} />
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of Todo
TransactionItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
TransactionItem.propTypes = {
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default TransactionItem;
