import { useState, useEffect, useContext } from "react";
// @mui material components

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ChecklistsApi from "api/checkListsApi";
import ChecklistItemDialog from "layouts/management/company/settings/components/ServiceChecklists/components/ChecklistItemDialog";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import Icon from "@mui/material/Icon";

import convertToOptions from "services/convertooptions";
function ChecklistSelect({
  companyId,
  setChecklists,
  checklists,
  checklist,
  setChecklist,
  loading = false,
  optional = false,
  disabled = false,
}) {
  const [isChecklist, setIsChecklist] = useState(false);
  const { t } = useTranslation("common");
  const checklistsApi = ChecklistsApi();
  useEffect(() => {
    async function fetchChecklists() {
      try {
        const queryParams = {
          companyId: companyId,
        };

        const response = await checklistsApi.getChecklists(queryParams);

        let selectionOptions = convertToOptions(response.data);

        selectionOptions.push(
          {
            value: null,
            label: (
              <>
                <Icon>block</Icon> Clear Selection
              </>
            ),
          },
          {
            value: "new",
            label: `+ ${t("components.checklist.create.createNewChecklist")}`,
          }
        );

        setChecklists(selectionOptions);
      } catch (error) {
        console.error(error);
      }
    }

    fetchChecklists();
  }, []);

  const handleChecklistSelected = (e) => {
    if (e.value === "new") {
      setIsChecklist(true);
    } else {
      setIsChecklist(false);
      console.log(e.value);
      setChecklist(e);
    }
    // other logic you may need when a company is selected
  };

  // generate options array, including the "Create New" option
  const options = checklists?.map((checklist) => ({
    value: checklist.id,
    label: checklist.name,
  }));
  const ClearSelection = ({}) => {
    return (
      <>
        <ArgonTypography component="label" variant="caption">
          <Icon>block</Icon>Clear Selection
        </ArgonTypography>
      </>
    );
  };

  const [checklistItem, setChecklistItem] = useState({});
  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={isChecklist}
        onClose={() => {
          setIsChecklist(false);
        }}
        title={t("components.checklist.create.createNewChecklist")}
        fullScreen={false}
        cancelButtonText={t("buttons.close")}
        submitButtonText={t("buttons.create")}
        maxWidth="sm"
        dialogObjects={
          <ChecklistItemDialog
            checklistItem={checklistItem}
            setChecklistItem={setChecklistItem}
            onClose={() => setIsChecklist(false)}
            companyId={companyId}
            setChecklist={setChecklist}
            //fetchChecklists={() => setUpdatedChecklist(!updatedChecklist)}
            //snackBarOpen={openSuccessSB}
          />
        }
      />
      <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          {t("components.checklist.title")} {optional ? "(Optional)" : null}
        </ArgonTypography>
        <HostHiveKbHelp lookupKey="checklist" marginBottom={0} />
      </ArgonBox>
      {loading ? (
        <HostHiveLoading />
      ) : (
        <>
          {disabled ? (
            <>
              <ArgonSelect />
            </>
          ) : (
            <>
              <ArgonSelect
                defaultValue={{ value: "none", label: "Select a Checklist" }}
                options={checklists}
                onChange={handleChecklistSelected}
                value={checklist}
              />
            </>
          )}
        </>
      )}
    </ArgonBox>
  );
}

export default ChecklistSelect;
