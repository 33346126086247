import Separator from "layouts/authentication/components/Separator";

import React, { useState, useContext, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  Paper,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import link from "assets/theme/components/link";
import BetaAgreement from "layouts/authentication/sign-in/basic/betaAgreement";

import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Icon from "@mui/material/Icon";
import { icon } from "leaflet";
import hostHiveTransparent_white from "assets/hostHiveTransparent_white.png";
import CompaniesApi from "api/companyApi";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";
import { UserAuth } from "context/AuthContext";
import axios from "axios";
import getApiUrl from "services/apiBaseUrl";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { min } from "lodash";

const RootContainer = styled(Grid)(({ theme }) => ({
  height: "100vh",
  overflow: "auto", // Ensure the container can scroll on mobile
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column", // Stack items vertically on mobile
  },
}));

const LeftSide = styled(Grid)({
  backgroundColor: "#00446d",
  color: "#fff",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: "64px 32px",
});

const RightSide = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "64px 32px",
});

const Form = styled(Box)({
  width: "100%",
  maxWidth: "320px",
});

const Logo = styled(Typography)({
  marginBottom: "32px",
});

const SignIn = () => {
  //   const classes = useStyles();
  const [t, i18n] = useTranslation("common");
  const { signIn, user, isAuthenticated, setCompany } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const theme = useTheme();

  const { redirectUrl } = useParams();
  const apiUrl = getApiUrl();
  const [rememberMe, setRememberMe] = useState(false);
  const companiesApi = new CompaniesApi();

  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [error, setError] = useState(false);

  const [agreeToBeta, setAgreeToBeta] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [betaPdfOpen, setBetaPdfOpen] = useState(false);
  const [termsPdfOpen, setTermsPdfOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log("Production");
    } else {
      setEmail(process.env.REACT_APP_USERNAME);
      setPassword(process.env.REACT_APP_PASSWORD);
    }
  }, []);

  useEffect(() => {
    const userAuthenticated = isAuthenticated(user);
    if (userAuthenticated) {
      setUserAuthenticated(true);
      console.log("User is authenticated");
      if (redirectUrl) {
        // Decoding
        const decodedURL = atob(redirectUrl);

        navigate(decodedURL);
      } else {
        navigate("/");
      }
    } else {
      console.log("User is not authenticated authenticated", user);
    }
  }, [user]);

  const PdfIframeViewer = ({ url }) => (
    <iframe
      src={url}
      // src="/Users/mitch2/Documents/Github/HostHive/frontend/hh-frontend-ui/src/layouts/authentication/sign-in/basic/hh_beta.pdf"
      style={{ width: "100%", height: "100vh" }}
      frameBorder="0"
    ></iframe>
  );

  const marketingTiles = [
    {
      id: 1,
      title: "Automatically schedule cleanings for all your properties",
      link: "https://www.hosthive.io",
      icon: "schedule",
    },
    {
      id: 2,
      title: "Detailed checklist and photo verification for each service",
      link: "https://www.hosthive.io",
      icon: "camera",
    },
    {
      id: 3,
      title: "Collaborate with your team to manage your properties",
      link: "https://www.hosthive.io",
      icon: "group",
      description:
        "Foster a connected team environment with tools that enable seamless communication and management of property-related tasks.",
    },
    {
      id: 4,
      title: "Organize and track all your expenses in one place",
      link: "https://www.hosthive.io",
      icon: "account_balance",
      description:
        "Keep a close eye on your expenditures with integrated expense tracking, simplifying your financial oversight.",
    },
    {
      id: 5,
      title: "Send and receive payments from your customers",
      link: "https://www.hosthive.io",
      icon: "attach_money",
      description:
        "Facilitate swift and secure transactions with automated invoicing and payment systems that save time and reduce errors.",
    },
    {
      id: 6,
      title: "Comprehensive Task Management: No Task Overlooked",
      link: "https://www.hosthive.io",
      icon: "check_circle",
      description:
        "Manage every detail with a robust task management system that ensures no job is missed and everything runs smoothly.",
    },
  ];

  const handleFormSubmit = async (e) => {
    setSignInLoading(true);
    e.preventDefault();
    setError("");
    try {
      const resp = await signIn(email, password, rememberMe);

      if (resp._tokenResponse.idToken) {
        const url = `${apiUrl}/users/${resp.user.uid}?includeDetails=true`;

        const accessToken = resp._tokenResponse.idToken;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
          },
        });
        setCompany(response.data.userCompanyDetails);
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(response.data.userCompanyDetails)
        );

        localStorage.setItem("userDetails", JSON.stringify(response.data));

        localStorage.setItem(
          "language",
          JSON.stringify(response.data.userSettings.preferredLanguage)
        );
        i18n.changeLanguage(response.data.userSettings.preferredLanguage);
        if (redirectUrl) {
          const decodedURL = atob(redirectUrl);

          navigate(decodedURL);
        } else {
          navigate("/");
        }
        //navigate("/");
      }
    } catch (e) {
      setSignInLoading(false);
      setError(e.message);
      console.log(e.message);
    }
  };

  const TileCard = ({ tile }) => {
    return (
      <Card
        sx={{
          height: "250px", // Adjust the height as needed
          width: "200px", // Adjust the width as needed
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f5f5f5",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",

          //   m: 2, // Add margin for spacing between cards
        }}
      >
        <CardActionArea sx={{ height: "100%", width: "100%" }}>
          <Box p={2} display="flex" justifyContent="center">
            <Box
              display="grid"
              justifyContent="center"
              alignItems="center"
              //   sx={{
              //     bgcolor: "primary.main",
              //     color: "white",
              //     width: "rem",
              //     height: "4rem",
              //     boxShadow: 3,
              //     borderRadius: 1,
              //     variant: "gradient",
              //   }}
            >
              <Icon fontSize="default">{tile.icon}</Icon>
            </Box>
          </Box>
          <CardContent
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            <ArgonTypography
              //   sx={{
              //     fontSize: "1.5rem",
              //     fontWeight: "bold",
              //     color: "#333",
              //   }}
              //   gutterBottom
              variant="caption"
              //   component="h2"
            >
              {tile.title}
            </ArgonTypography>
            <ArgonTypography
              sx={{
                marginTop: "8px",
                fontSize: "1rem",
                color: "#666",
              }}
              variant="body2"
              color="textSecondary"
              component="p"
            >
              Learn More
            </ArgonTypography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <RootContainer container>
      <HostHiveDialog
        open={betaPdfOpen}
        onClose={() => {
          setBetaPdfOpen(false);
        }}
        onSave={() => {
          setAgreeToBeta(true);
          setBetaPdfOpen(false);
        }}
        onDelete={() => {
          setAgreeToBeta(false);
          setBetaPdfOpen(false);
        }}
        title="Beta Agreement"
        fullScreen={true}
        cancelButtonText="I do not Agree"
        submitButtonText="I Agree"
        deleteButtonText="I do not Agree"
        includeSave={true}
        includeClose={false}
        includeDelete={true}
        dialogObjects={<BetaAgreement />}
      />

      <HostHiveDialog
        open={termsPdfOpen}
        onClose={() => {
          setTermsPdfOpen(false);
        }}
        onSave={() => {
          setAgreeToTerms(true);
          setTermsPdfOpen(false);
        }}
        onDelete={() => {
          setAgreeToTerms(false);
          setTermsPdfOpen(false);
        }}
        title="Terms of Service"
        fullScreen={true}
        cancelButtonText="I do not Agree"
        submitButtonText="I Agree"
        deleteButtonText="I do not Agree"
        includeSave={true}
        includeClose={false}
        includeDelete={true}
        dialogObjects={
          <PdfIframeViewer url="https://app.termly.io/document/terms-of-service/274ffdda-d1cd-44b1-b88e-0b5bfe885470" />
        }
      />
      <LeftSide item xs={12} md={6}>
        <ArgonBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            marginBottom: "-50px",
            // width: "100%",
            // marginBottom: "5px",
            // height: "100%",
          }}
        >
          <img src={hostHiveTransparent_white} alt="HostHive" width="50%" />
          {/* <ArgonTypography
            variant="h4"
            component="div"
            className={Logo}
            color="white"
          >
            HostHive
          </ArgonTypography> */}
        </ArgonBox>
        <ArgonBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "50px",
            // height: "100%",
          }}
        >
          <ArgonTypography variant="h6" color="white">
            Rental Management: Simplified
          </ArgonTypography>
        </ArgonBox>

        {signInLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            component="form"
            role="form"
            onSubmit={handleFormSubmit}
            sx={{
              minWidth: "320px",
            }}
          >
            <ArgonBox mb={2}>
              <ArgonInput
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </ArgonBox>
            <ArgonBox mb={2}>
              <ArgonInput
                type="password"
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
              />
            </ArgonBox>

            <ArgonBox mb={2}>
              {error && (
                <ArgonTypography
                  variant="caption"
                  color="error"
                  sx={{
                    ":hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                >
                  There was an error signing in. Please try again.
                </ArgonTypography>
              )}
            </ArgonBox>

            <ArgonBox mb={2}>
              <ArgonTypography
                variant="caption"
                textTransform="capitalize"
                color="white"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() => navigate("/authentication/reset-password")}
                //title={title}
              >
                Forgot Password?
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" pb={2}>
              <Checkbox
                checked={agreeToBeta}
                onChange={() => setAgreeToBeta(!agreeToBeta)}
              />
              {/* <PdfIframeViewer file="HostHive_beta_agreement.pdf" /> */}
              <ArgonTypography
                variant="caption"
                textTransform="capitalize"
                color="white"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   window.open(
                //     "https://www.hosthive.io/terms-of-service",
                //     "_blank"
                //   )
                // }
                onClick={() => setBetaPdfOpen(true)}
              >
                I Agree to Beta Agreement
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox display="flex" alignItems="center" pb={2}>
              <Checkbox
                checked={agreeToTerms}
                onChange={() => setAgreeToTerms(!agreeToTerms)}
              />
              {/* <PdfIframeViewer file="HostHive_beta_agreement.pdf" /> */}
              <ArgonTypography
                variant="caption"
                textTransform="capitalize"
                color="white"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   window.open(
                //     "https://www.hosthive.io/terms-of-service",
                //     "_blank"
                //   )
                // }
                onClick={() => setTermsPdfOpen(true)}
              >
                I Agree to Terms of Service
              </ArgonTypography>
            </ArgonBox>

            {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember my username"
          /> */}
            <ArgonButton
              type="submit"
              fullWidth
              variant="contained"
              color="white"
              disabled={!agreeToBeta || !agreeToTerms}
            >
              Login
            </ArgonButton>

            <ArgonBox
              sx={{
                marginTop: "20px",
              }}
            >
              <ArgonButton
                component={Link}
                href="https://www.hosthive.io/join-today"
                rel="noreferrer"
                variant="contained"
                fullWidth
                color="black"
                // disabled={!agreeToBeta || !agreeToTerms}
              >
                Sign Up
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        )}
      </LeftSide>
      <RightSide item xs={12} md={6}>
        <ArgonBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "32px",
          }}
        >
          <ArgonBox
            sx={{
              marginBottom: "32px",
            }}
          >
            <ArgonTypography variant="h5" gutterBottom>
              Get more out of HostHive
            </ArgonTypography>
            <ArgonTypography variant="body1">
              See maximum growth with these helpful tips:
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            marginBottom: "32px",
            // maxHeight: "800px",
            // minHeight: "800px",
            // overflowY: "auto",
          }}
        >
          <Grid container spacing={2} justifyContent="center">
            {marketingTiles.map((tile, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={6}
                // md={4}
                lg={4}
                display="flex"
                justifyContent="center"
              >
                <TileCard tile={tile} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <ArgonBox mt={4}>
          <ArgonButton
            onClick={() => navigate("/frequently-asked-questions")}
            variant="contained"
            fullWidth
            sx={{
              backgroundColor: "#00446d",
              color: "#fff",
            }}
          >
            Frequently Asked Questions
          </ArgonButton>
        </ArgonBox>
      </RightSide>
    </RootContainer>
  );
};

export default SignIn;
