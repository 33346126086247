/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// import {
//   Card,
//   Grid,
//   Tooltip,
//   Icon,
//   FormControl,
//   Select,
//   MenuItem,
//   Divider,
//   TextareaAutosize,
// } from "@mui/material";
// @mui material components
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";

import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import ViewTransactionItem from "layouts/administration/accounting/components/ViewTransactionItem";

// import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
// Custom styles for ComplexProjectCard
function ComplexTransactionCard({ transaction }) {
  const {
    id,
    summary,
    created,
    amount,
    categoryName,
    propertyName,
    categoryInfo,
  } = transaction;

  const [viewTransactionItemDiaglog, setViewTransactionItemDiaglog] =
    useState(false);
  return (
    <>
      <HostHiveDialog
        open={viewTransactionItemDiaglog}
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        includeSave={false}
        includeClose={false}
        title="View Transaction Item"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ViewTransactionItem
            onClose={() => setViewTransactionItemDiaglog(false)}
            itemDetails={transaction}
            id={id}
            // setFetchTransactions={() =>
            //   setFetchTransactions(!fetchTransactions)
            // }
            // triggerFetch={triggerFetch}
          />
        }
      />
      <Card
        sx={{
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
      >
        <ArgonBox p={2}>
          <ArgonBox display="flex" alignItems="center">
            <ArgonBox ml={2} lineHeight={0}>
              <ArgonBox lineHeight={0}>
                {/* <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
              > */}
                <ArgonTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    ":hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                  onClick={() => setViewTransactionItemDiaglog(true)}
                  //title={title}
                >
                  {/* <MuiLink
                  href={`/properties/${id}`}
                  rel="noreferrer"
                  onClick={() => console.log("Clicked title")}
                >
                  {title}
                </MuiLink> */}
                  {summary}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox mb={1}>
                <ArgonTypography
                  variant="button"
                  color="text"
                  fontWeight="medium"
                >
                  {convertTimeStamp(created, "America/New_York")}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox display="flex" justifyContent="space-between">
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                >
                  Amount
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color={amount > 0 ? "success" : "error"}
                >
                  ${amount.toLocaleString()}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
          <Divider />
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArgonBox
              display="flex"
              lineHeight={0}
              justifyContent="space-between"
            >
              <ArgonBadge
                variant="contained"
                container
                badgeContent={categoryInfo?.categoryName}
                color={categoryInfo?.categoryColor}
              />
            </ArgonBox>
            <ArgonBox>
              <IconButton>
                <Tooltip
                  title="Schedule Appointment"
                  placement="top"
                  // onClick={() => handleReportClicked()}
                >
                  {/* <Icon color="info"> add</Icon> */}
                  <ArgonBadge
                    color="info"
                    size="xs"
                    badgeContent="View"
                    container
                    onClick={() => setViewTransactionItemDiaglog(true)}
                  />
                </Tooltip>
              </IconButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
}

export default ComplexTransactionCard;
