import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import GroupsApi from "api/groupsApi";
import { useMemo, useState, useEffect, useContext } from "react";

function MultiSelection({
  selectedIds,
  handleSelection,
  entities,
  objectName,
}) {
  return (
    <ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ArgonTypography variant="h6" fontWeight="medium">
            Select
          </ArgonTypography>
        </Grid>

        {entities?.map((entity) => (
          <Grid item xs={12} md={4} key={entity.value}>
            <ArgonBox display="flex" alignItems="center">
              <Checkbox
                checked={selectedIds.includes(entity.value)}
                onChange={(event) => handleSelection(entity, event, objectName)}
              />
              <ArgonBox ml={1}>
                <ArgonTypography variant="caption" fontWeight="medium">
                  {entity.label}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Grid>
        ))}
      </Grid>
    </ArgonBox>
  );
}

export default MultiSelection;
