/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useMemo, useEffect, useState, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link, useNavigate } from "react-router-dom";
import BookingsApi from "api/bookingsApi";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { set } from "lodash";
import ConfirmationDataCard from "layouts/management/components/ConfirmationDataCard";
import { useTranslation } from "react-i18next";
function ConfirmationLists({
  title,
  date,
  setExternalFetch,
  externalFetch,
  confirmationBookings,
  setConfirmationBookings,
}) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();

  const [declineMessage, setDeclineMessage] = useState(false);
  const [eventDataCardOpen, setEventDataCardOpen] = useState(false);
  const [declineBookingInfo, setDeclineBookingInfo] = useState({});

  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [bookingInfo, setBookingInfo] = useState({});
  const [propertyInfo, setPropertyInfo] = useState({});
  const [showAssignmentsConfirmation, setShowAssignmentsConfirmation] =
    useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleConfirmBooking = async (id) => {
    console.log("Booking confirmed", id);
    const status = "confirmed";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      // Remove the confirmed booking from the confirmationBookings list
      setConfirmationBookings((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== id)
      );
      setExternalFetch(!externalFetch);
    } catch (error) {
      console.error("Error updating booking status", error);
    }
  };
  const handleViewBooking = (bookingData) => {
    console.log("Booking declined", bookingData);
    setBookingInfo(bookingData);
    setPropertyInfo(bookingData?.propertyInfo);
    setEventDataCardOpen(true);
    // setDeclineBookingInfo(bookingData);
    // const status = "declined";
    // setEventDataCardOpen(true);
  };
  const handleDeclineBooking = (bookingData) => {
    console.log("Booking declined", bookingData);
    setBookingInfo(bookingData);
    setPropertyInfo(bookingData?.propertyInfo);
    setEventDataCardOpen(true);
    // setDeclineBookingInfo(bookingData);
    // const status = "declined";
    // setEventDataCardOpen(true);
  };
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const [showAcceptAll, setShowAcceptAll] = useState(false);
  // const limitedRankings = showAll
  //   ? confirmationBookings
  //   : confirmationBookings?.slice(0, 4);
  // const renderRankings = limitedRankings.map((booking, key) => (
  //   <ArgonBox key={booking?.id} component="li" pt={1} pr={2}>
  //     <ConfirmationDataCard
  //       booking={booking}
  //       handleDeclineBooking={() => handleDeclineBooking(booking)}
  //       handleConfirmBooking={() => handleConfirmBooking(booking?.id)}
  //       handleViewBooking={() => handleViewBooking(booking)}
  //     />
  //     {key === confirmationBookings.length - 1 ? null : (
  //       <Divider
  //         sx={{
  //           mt: 2,
  //           mb: 1,
  //         }}
  //       />
  //     )}
  //   </ArgonBox>
  // ));

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedIds(
      event.target.checked ? confirmationBookings.map((item) => item.id) : []
    );
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  const handleSubmit = async () => {
    console.log(selectedIds);
    try {
      const response = await bookingsApi.updateBookingStatuses({
        bookingIds: selectedIds,
        status: "confirmed",
      });
      console.log(response);
      // Assuming the API call was successful, filter out the confirmed bookings from the confirmationBookings list
      setConfirmationBookings((prevBookings) =>
        prevBookings.filter((booking) => !selectedIds.includes(booking.id))
      );
      setExternalFetch(!externalFetch); // trigger an external fetch if needed
    } catch (error) {
      console.error("Error updating booking statuses", error);
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <ArgonBox display="flex" justifyContent="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              }
              label={t("generalWords.selectAll")}
            />
          </ArgonBox>
        </Grid>

        {/* <Grid item xs={12}>
                  <ArgonBox display="flex" justifyContent="center">
                    <ArgonButton
                      onClick={handleSubmit}
                      color="primary"
                      variant="outlined"
                    >
                      Accept Selected
                    </ArgonButton>
                  </ArgonBox>
                </Grid> */}

        <Grid item xs={12}>
          {confirmationBookings?.map((booking) => (
            <ArgonBox
              component="div"
              display="flex"
              flexDirection="column"
              p={2}
              m={0}
              key={booking?.id}
            >
              <ArgonBox>
                <ArgonBox>
                  <FormControlLabel
                    key={booking.id}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(booking.id)}
                        onChange={(event) =>
                          handleCheckboxChange(event, booking.id)
                        }
                      />
                    }
                    label={booking.title}
                  />
                </ArgonBox>

                {/* <ArgonBox>{booking?.title}</ArgonBox> */}

                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ArgonBox pl={2} display="flex" alignItems="center">
                    <ArgonBox display="flex" flexDirection="column">
                      <ArgonTypography variant="caption" color="text">
                        {booking?.serviceScheduleDate}{" "}
                        {booking?.serviceScheduleTime ? " @ " : null}
                        {booking?.serviceScheduleTime}
                      </ArgonTypography>
                      <ArgonTypography
                        variant="caption"
                        color="text"
                      ></ArgonTypography>
                      <ArgonBox>
                        {/* <ArgonBox>
                  <ArgonTypography component="label" variant="caption">
                    {propertyAddress?.address1}
                  </ArgonTypography>
                </ArgonBox> */}
                        <ArgonBox>
                          <ArgonTypography component="label" variant="caption">
                            {booking?.propertyAddress?.city},{" "}
                            {booking?.propertyAddress?.state},{" "}
                            {booking?.propertyAddress?.zipCode}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                      {/* <ArgonTypography variant="caption" color="text">
                KMB Realty LLC. - {name}
              </ArgonTypography> */}
                    </ArgonBox>
                  </ArgonBox>
                  <ArgonBox display="flex">
                    <ArgonBox mr={2}>
                      <ArgonButton
                        onClick={() => handleDeclineBooking(booking)}
                        variant="outlined"
                        color="error"
                        size="small"
                        iconOnly
                        circular
                        sx={({ functions: { pxToRem } }) => ({
                          width: pxToRem(34),
                          minWidth: pxToRem(34),
                          height: pxToRem(34),
                          minHeight: pxToRem(34),
                        })}
                      >
                        <CloseIcon>{booking?.icon}</CloseIcon>
                      </ArgonButton>
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonButton
                        onClick={() => handleConfirmBooking(booking?.id)}
                        variant="outlined"
                        color="success"
                        size="small"
                        iconOnly
                        circular
                        sx={({ functions: { pxToRem } }) => ({
                          width: pxToRem(34),
                          minWidth: pxToRem(34),
                          height: pxToRem(34),
                          minHeight: pxToRem(34),
                        })}
                      >
                        <DoneIcon>{booking?.icon}</DoneIcon>
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
                <Divider
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                />
              </ArgonBox>
            </ArgonBox>
          ))}
        </Grid>

        <Grid item xs={12}>
          <ArgonBox display="flex" justifyContent="center">
            <ArgonButton
              disabled={selectedIds.length < 1}
              onClick={handleSubmit}
              color="success"
              variant="gradient"
            >
              Accept Selected ({selectedIds.length})
            </ArgonButton>
          </ArgonBox>
        </Grid>
      </Grid>
    </>
  );
}

export default ConfirmationLists;
