const routeProtection = {
  common: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  commonPlus: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: ["free"],
  },
  company: {
    permittedRoles: ["admin"],
    notEligibleSubscriptionTiers: [],
  },
  finance: {
    permittedRoles: ["admin", "manager"],
    notEligibleSubscriptionTiers: ["free"],
  },
  tasks: {
    permittedRoles: ["admin", "manager"],
    notEligibleSubscriptionTiers: ["free"],
  },
  timeTracking: {
    permittedRoles: ["admin", "manager"],
    notEligibleSubscriptionTiers: ["free"],
  },
  inventory: {
    permittedRoles: ["admin", "manager"],
    notEligibleSubscriptionTiers: ["free"],
  },
  calendar: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  calendarPlus: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: ["free"],
  },
  serviceReports: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  serviceInvoicing: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  properties: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  propertiesPlus: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
  settings: {
    permittedRoles: ["all"],
    notEligibleSubscriptionTiers: [],
  },
};

export const RouteProtection = (lookUpKey) => {
  // Look up the service info using the key provided
  return routeProtection[lookUpKey];
};
