// AIzaSyAjiNu5eIZF2usKN1ifJD8928eQGuDeLmk

import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import PropertyDetailsInput from "./components/PropertyDetailsInput";
import PropertyLine from "./components/PropertyLine";
const MultiplePropertyCreation = ({ newProperties, setNewProperties }) => {
  // const [newProperties, setNewProperties] = useState([]);

  const addPropertyToNewProperties = (property) => {
    // generate random id
    property.id = Math.random().toString(36).substring(7);
    setNewProperties([...newProperties, property]);
  };

  return (
    <ArgonBox>
      <PropertyDetailsInput onSave={addPropertyToNewProperties} />

      <ArgonTypography variant="h6">Properties to be added</ArgonTypography>
      {newProperties.map((property) => (
        <PropertyLine
          property={property}
          newProperties={newProperties}
          setNewProperties={setNewProperties}
        />
      ))}
    </ArgonBox>
  );
};

export default MultiplePropertyCreation;
