/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
//import TimelineItem from "examples/Timeline/TimelineItem";
import AssignmentsTimelineItem from "layouts/management/components/AssignmentsTimelineItem";
import UpcomingAssignmentItem from "layouts/management/components/UpcomingAssignmentItem";
import { useMemo, useState, useEffect, useContext } from "react";
import BookingsApi from "api/bookingsApi";
import { UserAuth } from "context/AuthContext";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import BookingReportApi from "api/bookingReportApi";
import ServiceReportListItem from "layouts/management/components/ServiceReportsListItem";
import { useTranslation } from "react-i18next";
function ServiceReports({ property, propertyId, propertyName }) {
  const { t } = useTranslation("common");
  const [menu, setMenu] = useState(null);
  const bookingReportApi = BookingReportApi();
  const navigate = useNavigate();
  const openMenu = (event) => setMenu(event.currentTarget);
  const [bookingReports, setBookingReports] = useState([]);
  const closeMenu = () => setMenu(null);
  const [propertyInfo, setPropertyInfo] = useState({});
  const [bookingReportData, setBookingReportData] = useState([]);

  useEffect(() => {
    async function fetchBookingReports() {
      try {
        const params = {
          propertyId: propertyId,
        };
        const response = await bookingReportApi.getBookingReports(params);
        console.log("Booking report response", response);
        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          // setPropertyInfo(response.data?.propertyInfo);
          setBookingReportData(response?.data);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        console.log("Error getting booking report", error);
      }
    }
    fetchBookingReports();
  }, []);

  const assignmentIcon = (assignmentStatus) => {
    if (assignmentStatus === "pending") {
      return "notifications";
    } else if (assignmentStatus === "completed") {
      return "verified";
    } else if (assignmentStatus === "inprogress") {
      return "access_time";
    } else if (assignmentStatus === "scheduled") {
      return "calendar_today";
    } else if (assignmentStatus === "confirmed") {
      return "event_available";
    } else if (assignmentStatus === "cancelled") {
      return "cancel";
    } else {
      return "notifications";
    }
  };

  const assignmentColor = (assignmentStatus) => {
    if (assignmentStatus === "pending") {
      return "secondary";
    } else if (assignmentStatus === "completed") {
      return "success";
    } else if (assignmentStatus === "inprogress") {
      return "info";
    } else if (assignmentStatus === "scheduled") {
      return "warning";
    } else if (assignmentStatus === "confirmed") {
      return "primary";
    } else if (assignmentStatus === "cancelled") {
      return "error";
    } else {
      return "secondary";
    }
  };
  const handleViewClicked = (report) => {
    const [name, data] = report?.summary;
    console.log("handleViewClicked", data);
    navigate(`${data.reportUrl}`);
  };
  return (
    <>
      <ArgonBox display="flex">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium">
            {t("components.serviceReports.title")}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <Tooltip
            title={`Open List of Reports for ${propertyName}`}
            placement="top"
            arrow
          >
            <IconButton
              onClick={() => navigate(`/service-reports/${propertyId}`)}
            >
              <Icon sx={{ fontWeight: "bold" }}>arrow_outward</Icon>
            </IconButton>
          </Tooltip>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox mt={2}>
        {bookingReportData?.map((reportData, key) => (
          // <UpcomingAssignmentItem
          //   booking={assignment}
          //   handleViewClicked={() => handleViewClicked(assignment?.id)}
          // />
          // <AssignmentsTimelineItem
          //   color={assignmentColor(assignment?.status)}
          //   icon={assignmentIcon(assignment?.status)}
          //   title={assignment?.title}
          //   dateTime={assignment?.serviceScheduleDate}
          // />
          <ServiceReportListItem
            report={reportData}
            handleViewClicked={() => handleViewClicked(reportData)}
          />
        ))}
      </ArgonBox>
    </>
  );
}

export default ServiceReports;
