import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function AdministrationApi() {
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  const axiosPrivate = useAxiosPrivate();

  const getReachability = () => {
    return axiosPrivate.get(`/admin/reach`);
  };

  const getCompanies = () => {
    return axiosPrivate.get(`/admin/companies`);
  };

  const getMessages = (queryParams) => {
    return axiosPrivate.get(`/admin/messages`, { params: queryParams });
  };

  const deleteCompany = (companyId) => {
    return axiosPrivate.delete(`/admin/companies/${companyId}`);
  };

  const getProperties = (companyId) => {
    // /{companyId}/properties
    return axiosPrivate.get(`/admin/${companyId}/properties`);
  };

  const getUsers = (companyId) => {
    // /{companyId}/properties
    return axiosPrivate.get(`/admin/${companyId}/users`);
  };

  const updateUserRole = (companyId, userId, roleName) => {
    return axiosPrivate.put(
      `/admin/${companyId}/users/${userId}/role/${roleName}`
    );
  };

  const deleteUser = (companyId, userId) => {
    return axiosPrivate.delete(`/admin/${companyId}/users/${userId}`);
  };

  //resetPassword
  const resetPassword = (companyId, userId, tempPassword) => {
    //"/{companyId}/users/{userId}/password/{tempPassword}
    return axiosPrivate.put(
      `/admin/${companyId}/users/${userId}/password/${tempPassword}`
    );
  };

  const updateUserEmail = (companyId, userId, email) => {
    return axiosPrivate.put(
      `/admin/${companyId}/users/${userId}/email/${email}`
    );
  };

  const createCompany = (company) => {
    return axiosPrivate.post(`/admin/companies`, company);
  };

  const deleteAllProperties = (companyId) => {
    // @router.delete("/{companyId}/properties")
    return axiosPrivate.delete(`/admin/${companyId}/properties`);
  };

  const updateCompanyEmail = (companyId, email) => {
    // @router.delete("/{companyId}/properties")
    return axiosPrivate.put(`/admin/${companyId}/email/${email}`);
  };

  const updateCompanyName = (companyId, name) => {
    // @router.delete("/{companyId}/properties")
    return axiosPrivate.put(`/admin/${companyId}/name/${name}`);
  };

  const downgradeCompany = (companyId) => {
    return axiosPrivate.put(`/admin/${companyId}/downgrade`);
  };

  const removePhantomUsers = () => {
    return axiosPrivate.delete(`/admin/phantom/users`);
  };

  const putRoute = (route) => {
    return axiosPrivate.put(`/admin/${route}`);
  };

  return {
    getReachability,
    getCompanies,
    getProperties,
    getUsers,
    updateUserRole,
    deleteUser,
    resetPassword,
    updateUserEmail,
    deleteCompany,
    createCompany,
    deleteAllProperties,
    updateCompanyEmail,
    updateCompanyName,
    downgradeCompany,
    removePhantomUsers,
    getMessages,
    putRoute,
  };
}

export default AdministrationApi;
