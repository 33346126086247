import React from "react";
// import {
//   Grid,
//   Paper,
//   Typography,
//   List,
//   ListItem,
//   ListItemText,
// } from "@material-ui/core";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
import ArgonBox from "components/ArgonBox";
const ReviewPage = ({
  setNextDisabled,
  setInvoicePayload,
  invoicePayload,
  error,
  showAlert,
}) => {
  //   const data = {
  //     email: "m.boulay114@icloud.com",
  //     companyName: "Noelle's Company",
  //     items: [
  //       {
  //         amount: 300,
  //         currency: "usd",
  //         description: "Service charge for cleaning",
  //       },
  //       {
  //         amount: 30,
  //         currency: "usd",
  //         description: "take out trash monthly",
  //       },
  //     ],
  //   };
  setNextDisabled(false);
  return (
    <Paper style={{ padding: 16 }}>
      {showAlert ? (
        <ArgonBox p={2}>
          <ArgonAlert color="error" dismissible>
            <ArgonTypography variant="caption" color="white">
              {error?.response?.data?.detail}
            </ArgonTypography>
          </ArgonAlert>
        </ArgonBox>
      ) : null}
      <Typography variant="h5" style={{ marginBottom: 16 }}>
        Review Your Information
      </Typography>

      <Typography variant="h6">Contact Information</Typography>
      <Typography>Email: {invoicePayload?.email}</Typography>
      <Typography>Company Name: {invoicePayload?.companyName}</Typography>

      <Typography variant="h6" style={{ marginTop: 16 }}>
        Invoice Items
      </Typography>
      <List>
        {invoicePayload?.items.map((item, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={`${item.description}`}
              secondary={`Amount: $${
                item.amount
              } ${item.currency.toUpperCase()}`}
            />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

// Example Usage

export default ReviewPage;
