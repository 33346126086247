/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// import {
//   Card,
//   Grid,
//   Tooltip,
//   Icon,
//   FormControl,
//   Select,
//   MenuItem,
//   Divider,
//   TextareaAutosize,
// } from "@mui/material";
// @mui material components
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";

import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
// Custom styles for ComplexProjectCard
function ComplexProjectCard({ id, bookingReport, propertyId }) {
  const navigate = useNavigate();

  // Badges
  const propertyOpen = (
    <ArgonBadge
      variant="contained"
      color="success"
      badgeContent="ready"
      container
    />
  );
  const propertyOccupied = (
    <ArgonBadge
      variant="contained"
      color="caution"
      badgeContent="occupied"
      container
    />
  );

  const propertyError = (
    <ArgonBadge
      variant="contained"
      color="error"
      badgeContent="pending"
      container
    />
  );
  const propertyPending = (
    <ArgonBadge
      variant="contained"
      color="warning"
      badgeContent="pending"
      container
    />
  );
  const propertyRequireService = (
    <ArgonBadge
      variant="contained"
      color="error"
      badgeContent="Service Required"
      container
    />
  );

  const readStatus = (status) => {
    status = status.toLowerCase();
    if (status === "ready") {
      return propertyOpen;
    }
    if (status === "occupied") {
      return propertyOccupied;
    }
    if (status === "pending") {
      return propertyPending;
    }
    if (status === "error") {
      return propertyError;
    }
    if (status === "serviceRequired") {
      return propertyRequireService;
    } else {
      return propertyPending;
    }
  };

  const navigateToProperty = (id) => {
    navigate(`/properties/${id}}`);
  };
  const [occupancyStatusColor, setOccupancyStatusColor] = useState("error");
  const [occupancyStatusContent, setOccupancyStatusContent] = useState("");
  // useEffect(() => {
  //   setOccupancyStatusColor(property?.occupancyStatusDetails?.color || "error");
  //   setOccupancyStatusContent(
  //     property?.occupancyStatusDetails?.displayName || "UNKNOWN"
  //   );
  // }, [id]);

  const [showPropertyDataCard, setShowPropertyDataCard] = useState(false);
  const handleReportClicked = () => {
    console.log(
      `/service-reports/${bookingReport?.propertyId}/${bookingReport?.bookingId}`
    );
    ///service-reports/:propertyId/:bookingReportId
    navigate(
      `/service-reports/${bookingReport?.propertyId}/${bookingReport?.bookingId}`
    );
  };
  const [propertyDetails, setPropertyDetails] = useState({});
  return (
    <Card
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <ArgonBox p={2}>
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox lineHeight={0}>
              {/* <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
              > */}
              <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() => handleReportClicked()}
                //title={title}
              >
                {/* <MuiLink
                  href={`/properties/${id}`}
                  rel="noreferrer"
                  onClick={() => console.log("Clicked title")}
                >
                  {title}
                </MuiLink> */}
                {bookingReport?.summary[0]}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={1}>
              <ArgonTypography
                variant="button"
                color="text"
                fontWeight="medium"
              >
                {bookingReport?.serviceScheduleDate}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
        </ArgonBox>
        <ArgonBox ml={2} lineHeight={0} pb={1}>
          <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                Property
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {bookingReport?.propertyName}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
         
        </ArgonBox>
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                Servicer
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {bookingReport?.assigneeName}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
         
        </ArgonBox>
        <Divider />
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArgonBox
            display="flex"
            lineHeight={0}
            justifyContent="space-between"
          >
            <StatusCell
              icon={bookingReport?.statusInfo?.icon}
              color={bookingReport?.statusInfo?.color}
              status={bookingReport?.statusInfo?.status}
            />
          </ArgonBox>
          <ArgonBox>
            <IconButton>
              <Tooltip
                title="Schedule Appointment"
                placement="top"
                onClick={() => handleReportClicked()}
              >
                {/* <Icon color="info"> add</Icon> */}
                <ArgonBadge
                  color="info"
                  size="xs"
                  badgeContent="View"
                  container
                  //onClick={handleDeleteNote}
                />
              </Tooltip>
            </IconButton>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ComplexProjectCard;
