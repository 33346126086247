// AIzaSyAjiNu5eIZF2usKN1ifJD8928eQGuDeLmk

import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import { Grid } from "@mui/material";
import ArgonButton from "components/ArgonButton";
const PropertyLine = ({ property, newProperties, setNewProperties }) => {
  const handleDelete = () => {
    const updatedProperties = newProperties.filter((p) => p.id !== property.id);
    setNewProperties(updatedProperties);
  };
  return (
    <ArgonBox
      sx={{
        paddingLeft: "20px",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ArgonTypography variant="body2">
            {property.name} | {property.bed} | {property.bath} | {property.sqft}
          </ArgonTypography>
        </Grid>
        <Grid item xs={12}>
          <ArgonTypography variant="caption" fontWeight="medium">
            {property.formattedAddress}
          </ArgonTypography>
        </Grid>
      </Grid>
      <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
        mt={2}
      >
        <Grid
          container
          spacing={2}
          sx={{
            justifyContent: "flex-end", // Changed from "center" to "flex-end"
          }}
        >
          <Grid item>
            {" "}
            {/* Changed from xs={12} to unspecified size to allow natural content size */}
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </ArgonButton>
          </Grid>
        </Grid>
      </ArgonBox>
    </ArgonBox>
  );
};

export default PropertyLine;
