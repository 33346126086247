/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import Link from "@mui/material/Link";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import ArgonInput from "components/ArgonInput";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/CompanySettingsDataCard";
import ArgonButton from "components/ArgonButton";
import { Tooltip } from "@mui/material";
import CompaniesApi from "api/companyApi";
import HostHiveLoading from "components/HostHiveLoading";
// Custom styles for ComplexProjectCard
function BillingInfoCard({ title }) {
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const companiesApi = CompaniesApi();
  const [companySettingsDialogOpen, setCompanySettingsDialogOpen] =
    useState(false);
  const handleCompanySettingsSubmitClicked = () => {
    console.log("handleCompanySettingsSubmitClicked");
  };

  const [copyIcon, setCopyIcon] = useState("copy");
  const [companyInviteCode, setCompanyInviteCode] = useState(
    company?.inviteCode
  );
  const [companyPortalLoading, setCompanyPortalLoading] = useState(false);
  const [inviteCodeCopied, setInviteCodeCopied] = useState(false);
  const handleCopyInviteCodeClicked = () => {
    setInviteCodeCopied(true);
    setCopyIcon("check");

    console.log("handleCopyInviteCodeClicked", company);
    navigator.clipboard.writeText(company?.inviteCode);
    //Sleep for 1 second to allow user to see the check icon
    setTimeout(() => {
      setCopyIcon("copy");
      setInviteCodeCopied(false);
    }, 1000);
    //setCopyIcon("copy");
  };
  const handleEditInfoClicked = async () => {
    if (!company?.id) {
      // Alert the user or set an error state here
      return;
    }
    setCompanyPortalLoading(true);

    try {
      const response = await companiesApi.getCompanyPaymentPortal(company.id);

      if (response.status >= 200 && response.status < 300) {
        // Redirect to the payment portal URL
        window.location.href = `${response.data.portalUrl}/customer/update`;
      } else {
        // Handle other statuses here, perhaps show an error message
      }
    } catch (error) {
      // Handle errors here, perhaps show an error message
    } finally {
      //setCompanyPortalLoading(false);
    }
  };
  const [dashbaordPortalLoading, setDashboardPortalLoading] = useState(false);
  const handleDashboardClicked = async () => {
    if (!company?.id) {
      // Alert the user or set an error state here
      return;
    }
    setDashboardPortalLoading(true);

    try {
      const response = await companiesApi.getCompanyPaymentPortal(company.id);

      if (response.status >= 200 && response.status < 300) {
        // Redirect to the payment portal URL
        window.location.href = `${response.data.portalUrl}`;
      } else {
        // Handle other statuses here, perhaps show an error message
      }
    } catch (error) {
      // Handle errors here, perhaps show an error message
    } finally {
      //setCompanyPortalLoading(false);
    }
  };

  return (
    <ArgonBox p={2}>
      <ArgonBox display="flex" alignItems="center">
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
        <HostHiveDialog
          includeDelete={false}
          includeSave={false}
          includeClose={false}
          open={companySettingsDialogOpen}
          onClose={() => {
            setCompanySettingsDialogOpen(false);
          }}
          onSave={handleCompanySettingsSubmitClicked}
          title="Company Settings"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <SettingsDataCard
              title="Company Settings"
              companyDetails={company}
              handleClose={() => {
                setCompanySettingsDialogOpen(false);
              }}
            />
          }
        />
      </ArgonBox>
      <ArgonBox ml={2} lineHeight={0}>
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Contact Name
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              {company?.name}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Email
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              {company?.email}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        {company?.address ? (
          <>
            <ArgonBox display="flex" justifyContent="space-between">
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                >
                  Billing Address
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <ArgonBox justifyContent="flex-end">
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {company?.address?.address1}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox justifyContent="flex-end">
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {company?.address?.city}{" "}
                    {company?.address?.city ? ", " : ""}
                    {company?.address?.state}
                    {company?.address?.state ? ", " : ""}
                    {company?.address?.zipCode}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </>
        ) : null}
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Invite Code
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex">
            <ArgonInput
              disabled
              value={
                !companyInviteCode ? company?.inviteCode : companyInviteCode
              }
            />
            <ArgonTypography
              variant="body1"
              color="secondary"
              sx={{ cursor: "pointer", lineHeight: 0 }}
            >
              <Tooltip
                title={inviteCodeCopied ? "Copied!" : "Copy invite code"}
                placement="top"
                //onClick={handleCopyInviteCodeClicked}
                onClick={handleCopyInviteCodeClicked}
              >
                <Icon>{copyIcon}</Icon>
              </Tooltip>
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
      <Divider />
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
          {/* <ArgonTypography variant="button" fontWeight="medium">
            <ArgonBadge
              variant="contained"
              color="success"
              badgeContent="Active"
              container
            />
          </ArgonTypography> */}
          <ArgonTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
          >
            Company Settings Dashboard
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
          {/* <ArgonTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            sx={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => setCompanySettingsDialogOpen(true)}
            //title={title}
          >
            Edit Info
          </ArgonTypography> */}
          {dashbaordPortalLoading ? (
            <HostHiveLoading message="Loading Company Dashbaord..." />
          ) : (
            <ArgonButton
              // component={Link}
              // href={company?.portalUrl}
              disabled={!company?.id}
              onClick={handleDashboardClicked}
              rel="noreferrer"
              variant="gradient"
              color="dark"
              fullWidth
            >
              Open
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>

      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
          {/* <ArgonTypography variant="button" fontWeight="medium">
            <ArgonBadge
              variant="contained"
              color="success"
              badgeContent="Active"
              container
            />
          </ArgonTypography> */}
          <ArgonTypography
            variant="button"
            fontWeight="medium"
            color="secondary"
          >
            Account Details
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
          {/* <ArgonTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            sx={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => setCompanySettingsDialogOpen(true)}
            //title={title}
          >
            Edit Info
          </ArgonTypography> */}
          {companyPortalLoading ? (
            <HostHiveLoading message="Loading edit settings..." />
          ) : (
            <ArgonButton
              // component={Link}
              // href={company?.portalUrl}
              disabled={!company?.id}
              onClick={handleEditInfoClicked}
              rel="noreferrer"
              variant="gradient"
              color="dark"
              fullWidth
            >
              Make Changes
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of ComplexProjectCard
BillingInfoCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
BillingInfoCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
};

export default BillingInfoCard;
