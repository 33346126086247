import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import { IconButton } from "@mui/material";
// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import ArgonEditor from "components/ArgonEditor";
import ArgonSnackbar from "components/ArgonSnackbar";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

import PropertyContext from "context/PropertyContext";
// sweetalert2 components
import Swal from "sweetalert2";

import useMediaQuery from "@mui/material/useMediaQuery";
import NoteItem from "./components/NoteItem";
import NotesApi from "api/notesApi";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
import NoteSchema from "schemas/noteSchema";
import { useFormik } from "formik";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";
function NotesDataCard({
  title,
  isCreateNoteModalOpen,
  setIsCreateNoteModalOpen,
  propertyId,
  companyId,
  handleClose,
  ...rest
}) {
  const { t } = useTranslation("common");
  const notesApi = NotesApi();
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();

  // const { property, propertyNotes, setPropertyNotes } =
  //   useContext(PropertyContext);

  const [propertyNotes, setPropertyNotes] = useState([]);

  const [newNote, setNewNote] = useState("");

  const handleCreateNoteClose = () => {
    setIsCreateNoteModalOpen(false);
  };
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function fetchNotes() {
      setLoading(true);
      try {
        const response = await notesApi.getNotes({ propertyId });
        setPropertyNotes(response.data);
      } catch (error) {
        console.log("error", error);
      }
      setLoading(false);
    }
    if (propertyId) {
      fetchNotes();
    }
  }, []);

  const onSubmit = async (values, actions) => {
    setSaving(true);
    console.log("??", values);
    try {
      const notesResponse = await notesApi.createNote(values);
      if (notesResponse.status >= 200 && notesResponse.status < 300) {
        setPropertyNotes((prevNotes) => [...prevNotes, notesResponse.data]);
        setSaving(false);
        setIsCreateNoteModalOpen(false);
        setNewNote("");
      }
      console.log("notesResponse", notesResponse);
    } catch (error) {
      console.log("error", error);
    }
    actions.resetForm();
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: companyId || "",
      propertyId: propertyId || "",
      note: "",
      status: "active",
      // Add more fields here
    },
    validationSchema: NoteSchema,
    onSubmit,
  });

  return (
    <ArgonBox mt={6} mb={3}>
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        saveType="submit"
        open={isCreateNoteModalOpen}
        onClose={handleCreateNoteClose}
        onSave={onSubmit}
        title={`${t("generalWords.create")} ${t("components.notes.note")}`}
        fullScreen={false}
        cancelButtonText={t("buttons.close")}
        submitButtonText={`${t("buttons.save")} ${t("components.notes.note")}`}
        dialogObjects={
          <form onSubmit={handleSubmit}>
            <>
              <ArgonInput
                placeholder={`${t("generalWords.typeHere")}...`}
                multiline
                name="note"
                value={values.note}
                onChange={handleChange}
                onBlur={handleBlur}
                // Add error handling from Formik state
                error={touched.note && Boolean(errors.note)}
                helperText={touched.note && errors.note}
                // value={newNote}
                // onChange={(e) => setNewNote(e.target.value)}
              />
              <HostHiveErrorMessage message={errors.note} />
              <ArgonBox justifyContent="center" display="flex" p={2}>
                <ArgonBox display="flex">
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={handleCreateNoteClose}
                      disabled={saving}
                    >
                      {t("buttons.close")}
                    </ArgonButton>
                  </ArgonBox>
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      type="submit"
                      disabled={saving}
                    >
                      {`${t("buttons.save")} ${t("components.notes.note")}`}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </>
          </form>
        }
      />

      {/* propertyNotes */}
      {loading ? (
        <HostHiveLoading message="Loading latest notes for property" />
      ) : (
        <>
          {propertyNotes?.length < 1 && (
            <ArgonBox display="flex" justifyContent="center">
              <ArgonButton variant="outlined" color="info">
                {t("components.notes.noNote")}
              </ArgonButton>
            </ArgonBox>
          )}

          {propertyNotes?.map((propertyNote) => (
            <ArgonBox pl={2}>
              <NoteItem
                key={propertyNote?.id}
                noteItem={propertyNote}
                propertyNotes={propertyNotes}
                setPropertyNotes={setPropertyNotes}
                openSuccessSB={openSuccessSB}
              />
            </ArgonBox>
          ))}
        </>
      )}

      {renderSuccessSB}
    </ArgonBox>
  );
}

export default NotesDataCard;
