/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ComplexProjectCard from "layouts/management/bookingReports/propertyBookingReportList/components/ComplexProjectCard";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import HostHiveDataTable from "components/HostHiveDataTable";
import useMediaQuery from "@mui/material/useMediaQuery";
// Data
//import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import BookingReportApi from "api/bookingReportApi";
import {
  columns,
  smColumns,
} from "layouts/management/bookingReports/propertyBookingReportList/components/propertyBookingReportColumns";
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";

const dataTableData = {
  columns: [],

  rows: [],
};

function PropertyBookingReportList() {
  const [menu, setMenu] = useState(null);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  console.log("isSmallScreen", isSmallScreen);
  const bookingReportApi = BookingReportApi();
  const { propertyId } = useParams();
  const openMenu = (event) => setMenu(event.currentTarget);
  const [bookingReports, setBookingReports] = useState([]);
  const closeMenu = () => setMenu(null);
  const [propertyInfo, setPropertyInfo] = useState({});
  const [bookingReportData, setBookingReportData] = useState([]);
  const [bookingReportTableData, setBookingReportTableData] = useState({
    columns: columns,
    rows: [
      {
        summary: "",
        serviceScheduleDate: "",
        status: "",
        assigneeName: "",
      },
    ],
  });

  useEffect(() => {
    async function fetchBookingReports() {
      try {
        const params = {
          propertyId: propertyId,
        };
        const response = await bookingReportApi.getBookingReports(params);
        console.log("Booking report response", response);
        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          // setPropertyInfo(response.data?.propertyInfo);
          setBookingReportTableData({
            columns: bookingReportTableData?.columns,
            rows: response.data,
          });
          setBookingReportData(response.data);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        console.log("Error getting booking report", error);
      }
    }
    fetchBookingReports();
  }, []);
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <ArgonTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </ArgonTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox my={3}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          {/* <ArgonButton variant="outlined" color="white">
            New order
          </ArgonButton> */}
          {/* <ArgonBox display="flex">
            <ArgonButton variant="outlined" color="white" onClick={openMenu}>
              Filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </ArgonButton>
            {renderMenu}
            <ArgonBox ml={1}>
              <ArgonButton variant="outlined" color="white">
                <Icon>description</Icon>
                &nbsp;Export CSV
              </ArgonButton>
            </ArgonBox>
          </ArgonBox> */}
        </ArgonBox>

        <Card>
          {isSmallScreen ? (
            <>
              <ArgonBox>
                {bookingReportData?.map((bookingReport, key) => (
                  // <UpcomingAssignmentItem
                  //   booking={assignment}
                  //   handleViewClicked={() => handleViewClicked(assignment?.id)}
                  // />
                  <ArgonBox pb={2}>
                    <ComplexProjectCard
                      propertyId={propertyId}
                      bookingReport={bookingReport}
                      id={bookingReport?.bookingId}
                    />
                  </ArgonBox>
                ))}
              </ArgonBox>
            </>
          ) : (
            <>
              <HostHiveDataTable
                header={`${propertyInfo?.name || ""} Service Reports`}
                table={bookingReportTableData}
                canSearch
              />
            </>
          )}
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PropertyBookingReportList;
