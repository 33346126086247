import { useState, useEffect, useContext } from "react";
import "./comment.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonProgress from "components/ArgonProgress";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import ArgonAlert from "components/ArgonAlert";
import {
  acceptedImageMimeTypes,
  acceptedImageSizeInMb,
  acceptedVideoMimeTypes,
  acceptedVideoSizeInMb,
  acceptedDocumentMimeTypes,
  acceptedPropertyImageSizeInMb,
} from "services/acceptedMimeTypes";
import convertToOptions from "services/convertooptions";
import useMediaQuery from "@mui/material/useMediaQuery";
import statusBadges from "services/statusBadges";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonTimePicker from "components/ArgonTimePicker";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveImages from "components/HostHiveImages";
import HostHiveChecklist from "components/HostHiveChecklist";
import CommentsApi from "api/commentsApi";

import DialogTitle from "@mui/material/DialogTitle";

import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import BookingReportApi from "api/bookingReportApi";
import BookingReportCompletedDataCard from "layouts/management/components/BookingReportDataCard/BookingReportCompletedDataCard";
import { showInvoiceConfirmation } from "services/navigateToInvoicing";
function BookingReportDataCard({
  propertyId,
  companyId,
  bookingReport,
  onClose,
}) {
  const navigate = useNavigate();
  const commentsApi = CommentsApi();
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const bookingReportApi = BookingReportApi();
  const [availableServiceNames, setAvailableServiceNames] = useState([]);
  const [editorValue, setEditorValue] = useState(bookingReport?.comments);
  const [bookingImages, setBookingImages] = useState(
    bookingReport?.images || []
  );

  console.log("bookingReport", bookingReport);

  const selectedServicer = "Empire Cleaning";
  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [photoProgress, setPhotoProgress] = useState(0);
  const [comments, setComments] = useState([]);
  const [erroredPhotos, setErroredPhotos] = useState([]);
  // useEffect(() => {
  //   console.log("Booking report", bookingReport);
  //   async function fetchComments() {
  //     try {
  //       const response = await commentsApi.getComments(
  //         bookingReport?.bookingId,
  //         "serviceReport"
  //       );

  //       setComments(response.data.items);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   }
  //   fetchComments();
  // }, []);
  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);

    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    const photoLength = uploadedImagesArray.length;
    const maxSizeInMB = acceptedImageSizeInMb; // Replace x with the maximum file size in MB
    const allowedMimeTypes = acceptedImageMimeTypes; // Add or remove MIME types as needed

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      const image = uploadedImagesArray[i];

      if (image.size > maxSizeInMB * 1024 * 1024) {
        console.log(`Error: File size exceeds ${maxSizeInMB}MB`);
        let imageSize = image.size / (1024 * 1024);
        imageSize = imageSize.toFixed(2);
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - File size exceeds ${maxSizeInMB}MB - ${imageSize}MB`,
        ]);
        continue; // Skip this file
      }

      // Check MIME type
      if (!allowedMimeTypes.includes(image.type)) {
        console.log(
          `Error: Invalid file type. Allowed types are: ${allowedMimeTypes.join(
            ", "
          )}`
        );
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - Invalid file type - ${image.type}`,
        ]);
        continue; // Skip this file
      }

      const fileType = image.type;
      const fileName = image.name;
      console.log("image", image);
      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await bookingReportApi.updateBookingReportImages(
          bookingReport?.bookingId,
          bookingReport?.companyId,
          bookingReport?.propertyId,
          fileName,
          fileType,
          bookingReport?.summary,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setBookingImages(response.data.images);
        openSuccessSB(
          `Image uploaded successfully`,
          `Successfully uploaded image`
        );
      } catch (error) {
        console.error(error);
      }
    }

    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", fileName);
    try {
      const response = await bookingReportApi.deleteBookingReportImage(
        bookingReport?.bookingId,
        bookingReport?.companyId,
        bookingReport?.propertyId,
        fileName
      );

      // Update the bookingImages state by removing the image at the specified index
      setBookingImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
    } catch (error) {
      console.error(error);
    }
  };
  const [serviceReportSummary, setServiceReportSummary] = useState(
    bookingReport?.summary
  );
  const handleSummaryInput = (event) => {
    setServiceReportSummary(event.target.value);
  };

  const [checklistName, setChecklistName] = useState(
    bookingReport?.checklistInfo?.name
  );
  const [checklistItems, setChecklistItems] = useState(
    bookingReport?.checklistInfo?.checklist || []
  );

  const [completingReport, setCompletingReport] = useState(false);

  const [bookingReportStatus, setBookingReportStatus] = useState(
    bookingReport?.status
  );

  const [bookingReportStatusColor, setBookingReportStatusColor] = useState(
    bookingReport?.statusColor
  );

  const confirmUserAction = async () => {
    try {
      await showInvoiceConfirmation(
        `Service report completed and sent to customer!`,
        "Would you like to send an invoice to the customer?",
        "Yes, send invoice"
      );
      console.log(`User confirmed deletion!`);
      // setAppointmentIntegrationCounts(0);
      return true;
    } catch (response) {
      // console.log("User cancelled deletion!");
      return false;
    }
  };
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const handleSaveBookingReport = async (bookingStatus = "open") => {
    let completeReport = false;
    let payload = {
      bookingId: bookingReport?.bookingId,
      companyId: bookingReport?.companyId,

      propertyId: bookingReport?.propertyId,
      serviceScheduleDate: bookingReport?.serviceScheduleDate,
      summary: serviceReportSummary,
      comments: editorValue,
      status: bookingReportStatus?.value,
      assigneeId: bookingReport?.assigneeId,
      // checklistInfo: {
      //   checklistId: bookingReport?.checklistId,
      //   checklist: checklistItems,
      // },
      status: bookingStatus,
    };

    if (bookingReport?.checklistId) {
      payload.checklistInfo = {
        checklistId: bookingReport?.checklistId,
        checklist: checklistItems,
      };
      payload.checklistId = bookingReport?.checklistId;
      // payload.checklistId = bookingReport?.checklistId,
    }

    if (bookingStatus === "complete") {
      completeReport = true;
    }
    console.log("-->", payload);
    try {
      const response = await bookingReportApi.upsertBookingReport(
        bookingReport?.bookingId,
        payload
      );

      console.log(response);

      openSuccessSB(
        `Report saved successfully`,
        `Successfully saved service report`
      );
      if (completeReport === true) {
        setCompletingReport(true);
      }
      setBookingReportStatus(bookingStatus);
      if (completeReport === true) {
        setBookingReportStatusColor("success");
        const confirmationNavigation = await confirmUserAction();
        if (confirmationNavigation) {
          ///service-invoicing/create/:appointmentId
          navigate(`/service-invoicing/create/${bookingReport?.bookingId}`);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDismissPhotoUploadError = (index) => {
    const newErroredPhotos = [...erroredPhotos];
    newErroredPhotos.splice(index, 1);
    setErroredPhotos(newErroredPhotos);
  };

  return (
    <>
      {bookingReportStatus === "complete" ? (
        <>
          <BookingReportCompletedDataCard bookingReport={bookingReport} />
        </>
      ) : (
        <>
          {completingReport ? (
            <>
              <ArgonBox display="flex">
                <ArgonTypography variant="caption">
                  Completing service report...
                </ArgonTypography>
                <HostHiveLoading />
              </ArgonBox>
            </>
          ) : (
            <>
              <ArgonBox>
                <Card>
                  {erroredPhotos.length > 0 ? (
                    <ArgonBox p={2}>
                      {erroredPhotos.map((photo, index) => (
                        <ArgonAlert
                          key={index}
                          color="error"
                          dismissible
                          onDismiss={() => handleDismissPhotoUploadError(index)}
                        >
                          <ArgonTypography variant="caption" color="white">
                            {photo}
                          </ArgonTypography>
                        </ArgonAlert>
                      ))}
                    </ArgonBox>
                  ) : null}
                  {renderSuccessSB}
                  <ArgonBox display="flex" justifyContent="space-between">
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={9}>
                        <ArgonBox display="flex">
                          <DialogTitle>Service Report</DialogTitle>

                          <IconButton>
                            <Tooltip title="KB Article" placement="top">
                              <Icon>help</Icon>
                            </Tooltip>
                          </IconButton>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <ArgonBox display="flex">
                          <ArgonBox>
                            <Tooltip
                              title="Report status"
                              placement="top"
                              arrow
                            >
                              <ArgonBadge
                                size="lg"
                                hhFontSize={17}
                                variant="contained"
                                color={bookingReportStatusColor}
                                badgeContent={bookingReportStatus}
                              />
                            </Tooltip>
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                  <ArgonBox pl={2}>
                    <div style={{ flexGrow: 1 }}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start" // Align content to the top
                        alignItems="stretch" // Stretch child items to full width
                        height="100%"
                        p={2} // Add padding around to give some space
                      >
                        <ArgonBox
                          mb={2} // Increase bottom margin for better spacing
                          lineHeight="normal"
                          display="block" // Using block for full width
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            color="primary" // Highlight label for better visibility
                          >
                            Summary
                          </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox mb={2}>
                          {" "}
                          <ArgonInput
                            id="summary"
                            fullWidth // Use the full width of the container
                            placeholder="Enter the summary here..."
                            value={serviceReportSummary}
                            onChange={(e) => handleSummaryInput(e)}
                          />
                        </ArgonBox>
                      </ArgonBox>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start" // Align content to the top
                        alignItems="stretch" // Stretch child items to full width
                        height="100%"
                        p={2} // Add padding around to give some space
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          mb={2} // Add some margin-bottom for better spacing
                        >
                          Service Photos
                        </ArgonTypography>

                        <ArgonBox
                          component="input"
                          name="file"
                          type="file"
                          multiple
                          onChange={uploadHandler}
                          mb={2} // Add some margin-bottom for better spacing
                        />

                        {photoUploadInProgress && (
                          <ArgonProgress
                            value={photoProgress}
                            color="success"
                          />
                        )}
                      </ArgonBox>
                      <ArgonBox mt={3}>
                        {" "}
                        <Grid container spacing={3}>
                          <Grid item xs={12} lg={6} xl={5}>
                            <ArgonBox pt={2.5} px={2}>
                              {" "}
                              <HostHiveImages
                                images={bookingImages}
                                handleDeleteImage={handleImageDelete}
                                showNoImagesMessage={true}
                                imageGridSizeXs={2.4}
                                imageGridSizeMd={2.4}
                              />
                            </ArgonBox>
                          </Grid>

                          {/* If you have more grid items, you can add them here */}
                        </Grid>
                      </ArgonBox>
                      {bookingReport?.checklistId && (
                        <HostHiveChecklist
                          checklistName={checklistName}
                          checklistItems={checklistItems}
                          setChecklistItems={setChecklistItems}
                        />
                      )}

                      <HostHiveCommentSection
                        title="Service Comments"
                        // comments={comments}
                        // setComments={setComments}
                        entityType="serviceAppointment"
                        entityId={bookingReport?.bookingId}
                      />
                      {/* Action Buttons */}
                      <ArgonBox pt={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <ArgonButton
                              variant="gradient"
                              color="dark"
                              onClick={() => navigate(-1)}
                            >
                              Return
                            </ArgonButton>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <ArgonButton
                              variant="outlined"
                              color="info"
                              onClick={() => handleSaveBookingReport()}
                            >
                              Save
                            </ArgonButton>
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <ArgonButton
                              variant="gradient"
                              color="success"
                              onClick={() =>
                                handleSaveBookingReport(
                                  bookingReportStatus === "open"
                                    ? "pendingApproval"
                                    : "complete"
                                )
                              }
                            >
                              {bookingReportStatus === "open"
                                ? "Submit"
                                : "Complete"}
                            </ArgonButton>
                          </Grid>
                        </Grid>
                      </ArgonBox>
                    </div>
                  </ArgonBox>
                  <ArgonBox pl={2}>
                    <ArgonTypography component="label" variant="caption">
                      {bookingReportStatus === "open"
                        ? "* Open Reports will be sent for approval"
                        : "* Completed reports will close the assignement."}
                    </ArgonTypography>
                  </ArgonBox>
                </Card>
              </ArgonBox>
            </>
          )}
        </>
      )}
    </>
  );
}

export default BookingReportDataCard;
