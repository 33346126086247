import * as React from "react";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import ContactsApi from "api/companyAddressBookApi";
import HostHiveDialog from "components/HostHiveDialog";
import ContactItem from "layouts/administration/customerAddressBook/components/ContactItem/index.js";
import ContactCard from "layouts/management/components/ContactCard";
import ArgonBox from "components/ArgonBox";
import CardActionArea from "@mui/material/CardActionArea";
import Divider from "@mui/material/Divider";
import ArgonInput from "components/ArgonInput";
import Icon from "@mui/material/Icon";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export default function ContactSearch({
  searchContactSelected,
  setSearchContactSelected,
  onClose,
}) {
  const [companyContacts, setCompanyContacts] = useState([]);
  const contactsApi = ContactsApi();
  const [fetchContacts, setFetchContacts] = useState(false);
  const triggerFetch = () => setFetchContacts(!fetchContacts);
  const [loadingContacts, setLoadingContacts] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  useEffect(() => {
    async function fetchContacts() {
      setLoadingContacts(true);
      //   setLoadingPendingApprovalSize(true);
      try {
        let params = { search: searchText };
        const response = await contactsApi.getContacts(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");
          setCompanyContacts(response.data);
        } else {
          console.log("Error getting contacts");
        }
      } catch (error) {
        console.log("Error getting contacts", error);
      }
      setLoadingContacts(false);
    }
    fetchContacts();
  }, [fetchContacts, searchText]);

  const handleSearchContact = (contact) => {
    setSearchContactSelected({
      ...searchContactSelected,
      companyName: contact.name,
      email: contact.email,
      phone: contact?.phone || null,
    });
    onClose();
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(0); // reset to the first page
    setSearchPerformed(true);
  };

  return (
    <>
      <ArgonInput
        startAdornment={
          <Icon fontSize="small" style={{ marginRight: "6px" }}>
            search
          </Icon>
        }
        value={searchText}
        onChange={handleSearchTextChange}
      />
      <ArgonBox pt={2} height="auto" style={{ overflow: "auto" }}>
        {companyContacts?.map((contact, key) => (
          // <UpcomingAssignmentItem
          //   booking={assignment}
          //   handleViewClicked={() => handleViewClicked(assignment?.id)}
          // />
          <CardActionArea onClick={() => handleSearchContact(contact)}>
            <ContactCard
              contact={contact}
              includeClose={false}
              includeContactButton={false}
            />
            <Divider />
          </CardActionArea>
        ))}
      </ArgonBox>
    </>
  );
}
