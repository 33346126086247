/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// import {
//   Card,
//   Grid,
//   Tooltip,
//   Icon,
//   FormControl,
//   Select,
//   MenuItem,
//   Divider,
//   TextareaAutosize,
// } from "@mui/material";
// @mui material components
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";

import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import ViewTransactionItem from "layouts/administration/accounting/components/ViewTransactionItem";
import { useTranslation } from "react-i18next";
// import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
// Custom styles for ComplexProjectCard
function ComplexTaskCard({ task }) {
  const {
    id,
    summary,
    created,
    amount,
    ticketNumber,
    propertyName,
    priorityInfo,
    statusInfo,
    recurrenceId
  } = task;

  const navigate = useNavigate();

  const { t } = useTranslation("common");

//   {
//     "id": "fe35d93e-a622-4b3c-86ab-6a97cb97524c",
//     "companyId": "679e15de-bd0d-4ba7-a893-ab0cf54ed843",
//     "propertyId": "26cfe1c2-3bfe-4edb-a5aa-5a65931823cd",
//     "summary": "testing 2",
//     "description": "test",
//     "priority": "medium",
//     "status": "open",
//     "recurrenceId": null,
//     "serviceDate": null,
//     "assigneeId": "679e15de-bd0d-4ba7-a893-ab0cf54ed843",
//     "subAssigneeId": "RTJi0e89SnQSXw9ogzFFWw4bk1D3",
//     "ticketNumber": "000049",
//     "created": 1708550545775,
//     "createdBy": "A0Es7JtFj6fzF5XsgesDLc2KwK72",
//     "lastModified": 1708550545775,
//     "lastModifiedBy": "A0Es7JtFj6fzF5XsgesDLc2KwK72",
//     "dueDate": "2024-02-22T00:00:00",
//     "propertyName": "3021 BOP",
//     "statusInfo": {
//         "status": "open",
//         "color": "warning"
//     },
//     "priorityInfo": {
//         "priority": "medium",
//         "color": "warning",
//         "icon": "keyboard_arrow_up"
//     },
//     "ticketNumberInfo": [
//         49,
//         "000049",
//         {
//             "itemUrl": "/tasks/fe35d93e-a622-4b3c-86ab-6a97cb97524c"
//         }
//     ],
//     "propertyInfo": {
//         "propertyId": "26cfe1c2-3bfe-4edb-a5aa-5a65931823cd",
//         "propertyName": "3021 BOP"
//     }
// },

  return (
    <>
      
      <Card
        sx={{
          boxShadow: ({ boxShadows: { md } }) => md,
        }}
      >
        <ArgonBox p={2}>
          
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox display="flex" justifyContent="space-between">
              <ArgonBox pt={2}>
              <ArgonTypography
                  variant="button"
                  textTransform="capitalize"
                  fontWeight="medium"
                  sx={{
                    ":hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/tasks/${id}`)}

                  >
                 
                  #{ticketNumber} - {summary}
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox display="flex" alignItems="center">
              <IconButton>
                <Tooltip
                  title={`Created
              ${convertTimeStamp(created)}`}
                  placement="top"
                >
                  <Icon color="info"> info</Icon>
                </Tooltip>
              </IconButton>
            </ArgonBox>
              
            </ArgonBox>
          </ArgonBox>

          


          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox display="flex" justifyContent="space-between" pt={2}>
             

              <ArgonBox
              display="flex"
              lineHeight={0}
              justifyContent="space-between"
            >
              <ArgonBadge
                variant="contained"
                container
              badgeContent={t(`components.tasks.status.${statusInfo.status}`)}  
                color={statusInfo.color}
              />
            </ArgonBox>

              <ArgonBox display="flex" alignItems="center">
              <ArgonBox mr={1}>
                <ArgonBox variant="outlined" color={priorityInfo?.color} size="medium" iconOnly>
                  <Icon sx={{ fontWeight: "bold" }}>{priorityInfo?.icon}</Icon>
                </ArgonBox>
              </ArgonBox>
              <ArgonTypography
                  variant="caption"
                  fontWeight="medium"
                  color="text"
                  textTransform="capitalize"
                  sx={{ lineHeight: 0 }}
                >
                  {priorityInfo?.priority}
                </ArgonTypography>
            </ArgonBox>
              
            </ArgonBox>
          </ArgonBox>
          <ArgonBox ml={2} lineHeight={0}>
          
            {/* <ArgonBox display="flex" justifyContent="space-between">
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                >
                  Property
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  // color={amount > 0 ? "success" : "error"}
                >
                  {propertyName}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox> */}
          </ArgonBox>
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox display="flex" justifyContent="space-between" pt={2}>
             

              <ArgonBox
              display="flex"
              lineHeight={0}
              justifyContent="space-between"
            >
              {
                recurrenceId ? (
                  <>
                  <ArgonBox display="flex" alignItems="center">
                  <ArgonBox mr={1} display="flex">
                    <ArgonBox variant="outlined" color="info" size="medium" iconOnly>
                      <Icon sx={{ fontWeight: "bold" }}>repeat</Icon>
                    </ArgonBox>
                    {/* Repeats date */}
                    <ArgonBox pt={1} pl={1}>
                    <ArgonTypography variant="caption" fontWeight="medium" color="text" textTransform="capitalize" sx={{ lineHeight: 0 }}>
                      Repeats
                      </ArgonTypography>
                    </ArgonBox>
                    </ArgonBox>
                    </ArgonBox>
                  </>
                ): (null)}
              
            </ArgonBox>

              
              
            </ArgonBox>
          </ArgonBox>
          <Divider />
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >

<ArgonBox pl={2}>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color="text"
                >
                  {propertyName}
                </ArgonTypography>
              </ArgonBox>
            
            <ArgonBox>
              <IconButton onClick={() => navigate(`/tasks/${id}`)}>
                <Tooltip
                  title="Schedule Appointment"
                  placement="top"
                  onClick={() => navigate(`/tasks/${id}`)}
                >
                  {/* <Icon color="info"> add</Icon> */}
                  <ArgonBadge
                    color="info"
                    size="xs"
                    badgeContent="View"
                    container
                    onClick={() => navigate(`/tasks/${id}`)}
                  />
                </Tooltip>
              </IconButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </>
  );
}

export default ComplexTaskCard;
