import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import UsersApi from "api/usersApi";
import { useFormik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  phoneNumber: Yup.string().required("Phone Number is required"),
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
});

function SettingsDataCard({ title, handleClose, userDetails, setUserDetails }) {
  // APIs
  const usersApi = UsersApi();

  const [isDirty, setIsDirty] = useState(false);

  // Media Queries
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isSaving, setIsSaving] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      phoneNumber: userDetails.phoneNumber,
      email: userDetails.email,
      location: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await usersApi.updateUser(userDetails.id, values);
        if (response.status >= 200 && response.status <= 300) {
          setUserDetails(values);
        }
      } catch (error) {
        console.log(error);
      }
      handleClose();
    },
  });

  return (
    <Card
      sx={{
        p: isSmallScreen ? 1 : 2,
        width: "150%",
        overflow: "visible",
        width: "100%",
        height: "100%",
        minWidth: "600px",
        "@media (max-width: 600px)": {
          minWidth: "auto",
        },
      }}
    >
      <ArgonBox p={2}>
        <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
          {title}
        </ArgonTypography>
      </ArgonBox>
      <form onSubmit={formik.handleSubmit}>
        <ArgonBox p={2}>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <ArgonBox pt={2} px={2}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                gutterBottom
              >
                Full Name:
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} px={2}>
              <ArgonTypography variant="caption" color="text">
                <ArgonInput
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} px={2}>
              <ArgonTypography variant="caption" color="text">
                <ArgonInput
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <ArgonBox pt={2} px={2}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                gutterBottom
              >
                Phone Number:
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} px={2}>
              <ArgonTypography variant="caption" color="text">
                <ArgonInput
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                />
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <ArgonBox pt={2} px={2}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                gutterBottom
              >
                Email Address:
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox pt={2} px={2}>
              <ArgonTypography variant="caption" color="text">
                <ArgonInput
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </form>

      <ArgonBox pt={1.25} pb={3} px={3} mt={3} lineHeight={1}>
        <ArgonBox>
          <ArgonBox display="flex" justifyContent="space-between" py={1}>
            <ArgonBox>
              <ArgonButton
                variant="gradient"
                color="dark"
                onClick={handleClose}
              >
                Close
              </ArgonButton>
            </ArgonBox>

            <ArgonBox>
              <ArgonButton variant="gradient" color="info" type="submit">
                Save1
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

SettingsDataCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SettingsDataCard;
