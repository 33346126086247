/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// react-router-dom components
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ArgonAlert from "components/ArgonAlert";
import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import ArgonSelect from "components/ArgonSelect";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import CompaniesApi from "api/companyApi";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InfoWidgets from "./components/InfoWidget";
import ComplexProjectCard from "layouts/management/properties/properties-list/components/ComplexProjectCard";
import PricingNotice from "layouts/management/properties/properties-list/components/PricingNotice";
import CompanyFilterSelect from "layouts/management/components/CompanyFilterSelect";
import FormDialog from "./components/TestDiaglog";
import { useParams, useNavigate } from "react-router-dom";
import PropertyContext from "context/PropertyContext";
import PropertiesApi from "api/propertiesApi";
import PricingCalculator from "layouts/management/components/SubscriptionPriceCalculator";
// Data

import HostHiveLoading from "components/HostHiveLoading";
// Images

import logoSlack from "assets/images/small-logos/logo-slack.svg";
import HostHiveDialog from "components/HostHiveDialog";
import NewProperty from "layouts/management/properties/new-property";
import ArgonInput from "components/ArgonInput";
import { setIn } from "formik";
import { useTranslation } from "react-i18next";
import PropertyGroups from "layouts/management/properties/property-groups";

import IconButton from "@mui/material/IconButton";
// import VideoLibraryIcon from "@mui/material/icons/VideoLibrary";
import { makeStyles } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import HowToVideoButton from "layouts/management/components/VideoIconButton";

import { VideoLibraryHelper } from "services/videoLibraryHelper";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import MultiplePropertyCreation from "layouts/management/components/MultiplePropertyCreation";
import { set } from "lodash";
import { use } from "i18next";

function PropertiesList({
  createProperty = false,
  propertyGroupSettings = false,
}) {
  //const { companyId } = useParams();
  const { t } = useTranslation("common");

  const createPropertyVideoId = VideoLibraryHelper("createProperty").videoId;

  const companiesApi = CompaniesApi();
  const propertiesApi = new PropertiesApi();
  const navigate = useNavigate();
  const [pricingCalculatorOpen, setPricingCalculatorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [properties, setProperties] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [propertyDialogOpen, setPropertyDialogOpen] = useState(false);
  const [bulkCreateDialogOpen, setBulkCreateDialogOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // const [company, setCompany] = useState(null);

  const [currentPage, setCurrentPage] = useState(0); // Add current page state
  const limit = 100; // You can adjust this to fit your needs
  const [searchText, setSearchText] = useState("");
  //const [companySearch, setCompanySearch] = useState({});
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [activeOnly, setActiveOnly] = useState(true);
  const [property, setProperty] = useState(null);
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  useEffect(() => {
    const loadPropertiesData = async () => {
      try {
        setIsLoading(true);
        const queryParams = {
          limit,
          skip: currentPage * limit,
          search: searchText,
          companyId: company?.id,
          ownerOnly: false,
          status: activeOnly ? "active" : null,
        };

        const propertiesResponse = await propertiesApi.getProperties(
          queryParams
        );

        if (
          propertiesResponse.status >= 200 &&
          propertiesResponse.status < 300
        ) {
          const propertyQuery = propertiesResponse.data;
          setProperties(propertyQuery);
          // if (searchPerformed) {
          //   console.log(propertyQuery);
          //   setProperties(propertyQuery);
          //   setSearchPerformed(false);
          // } else {
          //   setProperties((prevProperties) => [
          //     ...prevProperties,
          //     ...propertyQuery,
          //   ]);
          // }
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
        setLoadingMore(false);
      }
    };
    loadPropertiesData();

    if (createProperty) {
      setPropertyDialogOpen(true);
    }

    if (propertyGroupSettings === true) {
      setTabValue(1);
    }
  }, [currentPage, searchText, activeOnly]);

  const handleNextPage = () => {
    setLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1); // Create a function to handle page changes
  };

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue);
  };

  const navigateToBookingReports = () => {
    navigate(`/properties/bookingReport`);
  };

  const handleExportClicked = () => {
    console.log("Export clicked");
    setDialogOpen(true);
  };

  const handleCancelClicked = () => {
    setPropertyDialogOpen(false);
  };
  const handleSubmitClicked = () => {
    setPropertyDialogOpen(false);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(0); // reset to the first page
    setSearchPerformed(true);
  };

  const [priceIncreaseNoticeOpen, setPriceIncreaseNoticeOpen] = useState(false);
  const ListOfProperties = () => {
    return <></>;
  };
  const helpIntegrationLookupKey = "properties";

  const [newProperties, setNewProperties] = useState([]);
  const handleSaveBulkProperties = async () => {
    console.log(newProperties);
    // responseDetailslet response = await propertiesApi.createProperties(newProperties);
    // let responseDetails = response.data;
    // if (newProperties.length === 1) {
    //   navigate(`/properties/${responseDetails[0].id}`);
    // } else {
    //   setBulkCreateDialogOpen(false);
    // }
  };
  useEffect(() => {
    console.log(newProperties);
  }, [newProperties]);
  return (
    <ArgonBox pt={6}>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        {/* bulkCreateDialogOpen */}
        <HostHiveDialog
          open={bulkCreateDialogOpen}
          includeSave={true}
          includeClose={true}
          onClose={() => setBulkCreateDialogOpen(false)}
          onSave={handleSaveBulkProperties}
          title="Create New Property"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <MultiplePropertyCreation
              newProperties={newProperties}
              setNewProperties={setNewProperties}
            />
          }
        />
        <HostHiveDialog
          open={propertyDialogOpen}
          includeSave={false}
          includeClose={false}
          onClose={handleCancelClicked}
          onSave={handleSubmitClicked}
          title="Create New Property"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <SettingsDataCard
              title="Property Settings"
              setProperty={setProperty}
              property={property}
              isCreation={true}
              company={company}
              handleClose={handleCancelClicked}
            />
          }
        />

        <HostHiveDialog
          open={pricingCalculatorOpen}
          includeSave={false}
          includeClose={true}
          onClose={() => setPricingCalculatorOpen(false)}
          onSave={handleSubmitClicked}
          title="Pricing Calculator"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          submitButtonText="Save"
          dialogObjects={<PricingCalculator />}
        />

        <HostHiveDialog
          open={priceIncreaseNoticeOpen}
          includeSave={false}
          includeClose={false}
          onClose={handleCancelClicked}
          onSave={handleSubmitClicked}
          title={t("properties.priceIncreaseNotice.dataCardHeader")}
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <Card
              sx={{
                width: "150%",
                overflow: "visible",
                width: "100%",
                height: "100%",
                minWidth: "600px",
                "@media (max-width: 600px)": {
                  minWidth: "auto",
                },
              }}
            >
              {/* <ArgonBox p={2}>
                <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                  Terms and conditions
                </ArgonTypography>
              </ArgonBox> */}

              <ArgonBox p={2}>
                <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                  <PricingNotice />
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox pt={1.25} pb={3} px={3} mt={3} lineHeight={1}>
                <ArgonBox>
                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    py={1}
                  >
                    <ArgonBox>
                      <ArgonButton
                        variant="gradient"
                        color="dark"
                        onClick={() => setPriceIncreaseNoticeOpen(false)}
                      >
                        {t("buttons.close")}
                      </ArgonButton>
                    </ArgonBox>

                    <ArgonBox>
                      <ArgonButton
                        variant="outlined"
                        color="error"
                        onClick={() => {
                          setPropertyDialogOpen(true);
                          setPriceIncreaseNoticeOpen(false);
                        }}
                      >
                        {t("buttons.agree")}
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Card>
          }
        />

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sx={{ ml: "auto" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
              >
                <Tab
                  label={`Properties (${properties?.length})`}
                  icon={
                    <i
                      className="ni ni-shop"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
                <Tab
                  label={`Property Group Settings `}
                  icon={
                    <i
                      className="ni ni-settings-gear-65"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
        {tabValue === 0 ? (
          <ArgonBox my={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Card
                  sx={{
                    height: "100%",
                    width: "100%",

                    boxShadow: ({ boxShadows: { md } }) => md,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                      <ArgonBox lineHeight={1} p={3}>
                        <ArgonBox display="flex">
                          <ArgonBox>
                            <ArgonTypography variant="h5" fontWeight="medium">
                              {t("properties.propertiesListHeader")}
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonBox pl={2}>
                            {/* <HowToVideoButton videoId={createPropertyVideoId} /> */}
                            {helpIntegrationLookupKey ? (
                              <HostHiveKbHelp
                                // helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
                                size="medium"
                                lookupKey={helpIntegrationLookupKey}
                              />
                            ) : null}
                          </ArgonBox>
                        </ArgonBox>
                        <ArgonBox>
                          <ArgonTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            {t("properties.propertiesListSubHeader")}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                      <ArgonBox lineHeight={1} pl={3}>
                        {/* <ArgonButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={() => setPropertyDialogOpen(true)}
                        >
                          How to video
                        </ArgonButton> */}
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ArgonBox lineHeight={1} p={3} alignItems="center">
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <ArgonBox display="flex">
                              <ArgonBox pt={1}>
                                <ArgonButton
                                  variant="gradient"
                                  color="info"
                                  size="small"
                                  onClick={() => setPropertyDialogOpen(true)}
                                >
                                  {t("properties.buttons.addProperty")}
                                </ArgonButton>
                              </ArgonBox>
                              <ArgonBox pl={2}>
                                <HowToVideoButton
                                  videoId={createPropertyVideoId}
                                />
                              </ArgonBox>
                            </ArgonBox>
                          </Grid>

                          {/* <Grid item xs={4}>
                          <ArgonButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => setPricingCalculatorOpen(true)}
                          >
                            Calculator
                          </ArgonButton>
                        </Grid> */}
                        </Grid>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12}>
                      {showAlert ? (
                        <ArgonBox p={2}>
                          <ArgonAlert color="error" dismissible>
                            <ArgonTypography variant="caption" color="white">
                              {error?.response?.data?.detail}
                            </ArgonTypography>
                          </ArgonAlert>
                        </ArgonBox>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ArgonBox p={2}>
                        <ArgonInput
                          placeholder={t("properties.searchPlaceholder")}
                          startAdornment={
                            <Icon
                              fontSize="small"
                              style={{ marginRight: "6px" }}
                            >
                              search
                            </Icon>
                          }
                          value={searchText}
                          onChange={handleSearchTextChange}
                        />
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ArgonBox display="flex" p={2}>
                        <Checkbox
                          checked={activeOnly}
                          onChange={() => setActiveOnly(!activeOnly)}
                        />

                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Active Only
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                  </Grid>

                  {properties.length > 0 ? null : (
                    <Card>
                      <ArgonBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        p={3}
                      >
                        <ArgonBox lineHeight={1}>
                          <ArgonTypography variant="h5" fontWeight="medium">
                            {t("properties.noPropertiesFound")}
                          </ArgonTypography>
                          <ArgonTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          >
                            {t("properties.noPropertiesGuidance")}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </Card>
                  )}

                  <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1}>
                    <Grid container spacing={3}>
                      {properties?.map((property, key) => (
                        <Grid item xs={12} md={6} lg={4} key={property?.id}>
                          <ComplexProjectCard
                            // title={name} // Assuming the property name should be the title
                            // description={occupancyStatus} // Assuming the description in the properties object should be the card's description
                            // status={status} // Assuming the serviceScheduleDate is the dateTime for the card
                            // Assuming members, images, dropdown action and menu aren't contained in the properties object
                            // bed={bed}
                            id={property?.id}
                            setProperties={setProperties}
                            // bath={bath}
                            // sqft={sqft}
                            // occupancyStatusDetails={occupancyStatusDetails}
                            // image={logoSlack}
                            // companyInfo={propertyCompanyInfo}
                            // isOwner={isOwner}
                            property={property}
                            setError={setError}
                            setShowAlert={() => setShowAlert(!showAlert)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </ArgonBox>
                  {loadingMore ? <HostHiveLoading /> : null}
                </Card>
              </Grid>
            </Grid>
          </ArgonBox>
        ) : (
          <PropertyGroups includeDashboardLayout={false} />
        )}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ArgonBox display="flex" justifyContent="center" pt={2}></ArgonBox>
          </Grid>
        </Grid>

        <Footer />
      </DashboardLayout>
    </ArgonBox>
  );
}

export default PropertiesList;
