import * as Yup from "yup";

const NoteSchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  note: Yup.string().required("Note content is required"),
  propertyId: Yup.string().required("PropertyId is required"),
  companyId: Yup.string().required("CompanyId is required"),
  createdBy: Yup.string(),
  created: Yup.string(),
  lastModifiedBy: Yup.string(),
  lastModifiedDate: Yup.string(),
  status: Yup.string().oneOf(["active", "inactive"]).nullable(false),
});

export default NoteSchema;
