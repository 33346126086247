import { useState, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import convertToOptions from "services/convertooptions";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import SavingDataCard from "../SavingCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import statusBadges from "services/statusBadges";
import Switch from "@mui/material/Switch";
import FormField from "../FormField";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonTimePicker from "components/ArgonTimePicker";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import convertToSelector from "services/selector";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";

import CompaniesApi from "api/companyApi";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import PropertyInfo from "layouts/management/properties/new-property/components/PropertyInfo";
import CompanySelect from "layouts/management/components/CompanySelect";
import PropertySelect from "layouts/management/components/PropertySelect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import HostHiveLoading from "components/HostHiveLoading";
import { set } from "lodash";
import ServiceAppointmentChangeRequest from "layouts/management/components/ServiceAppointmentChangeRequest";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";

import { useTranslation } from "react-i18next";
function EditEventDataCard({
  handleDateChange,
  startDate,
  handleTimeChange,
  startTime,
  recurrenceAllowed,
  bookingInfo,

  setRecurrenceConfigurationOpen,
  repeatEvent,
  setRepeatEvent,
  estimatedDurationValue,
  setEstimatedDurationValue,
  estimatedDurationInterval,
  setEstimatedDurationInterval,
  availableServiceNames,
  setAvailableServiceNames,
  availableChecklists,
  setAvailableChecklists,
  selectedChecklist,
  setSelectedChecklist,
  availableServicers,
  selectedServicer,
  setSelectedServicer,
  availableSubAssigneeUsers,
  selectedSubAssignee,
  setSelectedSubAssignee,
  showSubAssignee,
  setManualAssign,
  manualAssign,
  namedServicer,
  setNamedServicer,
  bookingNotes,
  setBookingNotes,
  isLoading,
  category,
  setCategory,
  resetTheme,
  intervalOptions,
  serviceName,
  setServiceName,
}) {
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("copmanyDetails"));
  return (
    <>
      <ArgonBox display="flex">
        <ArgonBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("serviceAssignments.eventDataCard.serviceDate")}
                  </ArgonTypography>
                </ArgonBox>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={handleDateChange}
                    value={startDate}
                    orientation="landscape"
                  />
                </LocalizationProvider>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("serviceAssignments.eventDataCard.serviceScheduleTime")}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox display="flex">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ThemeProvider theme={resetTheme}>
                      <TimePicker
                        value={
                          startTime
                            ? dayjs()
                                .hour(startTime.split(":")[0])
                                .minute(startTime.split(":")[1])
                            : null
                        }
                        onChange={handleTimeChange}
                        sx={{
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "#dee2e6",
                            },
                        }}
                      />
                    </ThemeProvider>
                  </LocalizationProvider>
                  {recurrenceAllowed ? (
                    <ArgonBox display="flex">
                      <ArgonBox pl={2}>
                        <Switch
                          disabled={repeatEvent && bookingInfo?.id != null}
                          checked={repeatEvent}
                          onChange={() => setRepeatEvent(!repeatEvent)}
                        />
                      </ArgonBox>
                      <ArgonBox pl={1}>
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("serviceAssignments.eventDataCard.repeat")}
                        </ArgonTypography>
                      </ArgonBox>
                    </ArgonBox>
                  ) : null}
                </ArgonBox>
              </ArgonBox>
            </Grid>
            {repeatEvent ? (
              <>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("serviceAssignments.eventDataCard.repeats")}:
                    </ArgonTypography>

                    <ArgonTypography component="label" variant="caption">
                      {" "}
                      {t("serviceAssignments.eventDataCard.every")}{" "}
                      {bookingInfo?.recurrenceData?.recurrenceIntervalValue}{" "}
                      {bookingInfo?.recurrenceData?.recurrenceIntervalDisplay}{" "}
                      {bookingInfo?.recurrenceData?.endDate
                        ? `until ${bookingInfo.recurrenceData.endDate}`
                        : ""}
                    </ArgonTypography>
                    <ArgonTypography
                      pl={3}
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => setRecurrenceConfigurationOpen(true)}
                    >
                      {t("generalWords.edit")}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
              </>
            ) : null}

            <Grid item xs={12}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {t("serviceAssignments.eventDataCard.estimatedDuration")}
                  </ArgonTypography>
                </ArgonBox>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ArgonInput
                      value={estimatedDurationValue}
                      onChange={(e) =>
                        setEstimatedDurationValue(e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonSelect
                      options={intervalOptions}
                      value={estimatedDurationInterval}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <ArgonBox pr={1}>
            <ServiceNameSelect
              serviceNames={availableServiceNames}
              setServiceNames={setAvailableServiceNames}
              companyId={company?.id}
              serviceName={serviceName}
              setServiceName={setServiceName}
              loading={isLoading}
            />
          </ArgonBox>
        </Grid>

        <Grid item xs={12} sm={6}>
          <ArgonBox pr={1}>
            <ChecklistSelect
              companyId={company?.id}
              checklists={availableChecklists}
              setChecklists={setAvailableChecklists}
              checklist={selectedChecklist}
              setChecklist={setSelectedChecklist}
              loading={isLoading}
            />
          </ArgonBox>
        </Grid>
      </Grid>

      <ArgonBox pt={2}>
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {t("serviceAssignments.eventDataCard.assignee")}{" "}
          </ArgonTypography>
        </ArgonBox>
        {isLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox display="flex">
            <ArgonBox>
              {manualAssign ? (
                <ArgonInput
                  value={namedServicer}
                  onChange={(e) => setNamedServicer(e.target.value)}
                />
              ) : (
                <ArgonSelect
                  value={selectedServicer}
                  options={convertToOptions(availableServicers)}
                  onChange={(event) => setSelectedServicer(event)}
                />
              )}
            </ArgonBox>
            <ArgonBox pl={2} display="flex">
              <ArgonBox>
                <Switch
                  checked={manualAssign}
                  onChange={() => setManualAssign(!manualAssign)}
                />
              </ArgonBox>
              <ArgonBox pl={2}>
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  {t("serviceAssignments.eventDataCard.manuallyAssign")}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        )}
      </ArgonBox>

      {showSubAssignee ? (
        <>
          <ArgonBox pt={2}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                {t("serviceAssignments.eventDataCard.assignToUser")}
              </ArgonTypography>
            </ArgonBox>
            {isLoading ? (
              <HostHiveLoading />
            ) : (
              <ArgonBox display="flex">
                <ArgonBox>
                  <ArgonSelect
                    value={selectedSubAssignee}
                    options={convertToOptions(availableSubAssigneeUsers, true)}
                    onChange={(event) => setSelectedSubAssignee(event)}
                  />
                </ArgonBox>
              </ArgonBox>
            )}
          </ArgonBox>
        </>
      ) : null}

      <ArgonBox pt={2}>
        <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {t("serviceAssignments.eventDataCard.serviceComments")}
          </ArgonTypography>
        </ArgonBox>

        <ArgonEditor value={bookingNotes} onChange={setBookingNotes} />
      </ArgonBox>
      <Grid container spacing={3} pb={3}>
        <Grid item xs={12} sm={6}>
          <ArgonBox pr={1}>
            <CategorySelect
              category={category}
              setCategory={setCategory}
              menuPlacement="top"
              companyId={company?.id}
              categoryType="serviceAssignments"
            />
          </ArgonBox>
        </Grid>
      </Grid>
    </>
  );
}

export default EditEventDataCard;
