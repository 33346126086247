import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ServiceInvoicingApi() {
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  const axiosPrivate = useAxiosPrivate();
  // const isAuthorized = isRouteAuthorized("finance", role, subscriptionTier);

  // if (!isAuthorized) {
  //   return null;
  // }
  const getInvoices = (queryParams) => {
    return axiosPrivate.get(`/service-invoicing`, { params: queryParams });
  };

  const createAccount = () => {
    return axiosPrivate.post(`/service-invoicing`, null);
  };

  const createAccessLink = () => {
    return axiosPrivate.post(`/service-invoicing/access-link/create`);
  };

  const createInvoice = (invoiceData) => {
    return axiosPrivate.post(`/service-invoicing/invoice/create`, invoiceData);
  };

  const getAccountStatus = (queryParams) => {
    return axiosPrivate.get(`/service-invoicing/account-status`, {
      params: queryParams,
    });
  };

  const getLoginLink = (queryParams) => {
    return axiosPrivate.get(`/service-invoicing/login-link`, {
      params: queryParams,
    });
  };

  const getAccountBalance = (queryParams) => {
    return axiosPrivate.get(`/service-invoicing/account-balance`, {
      params: queryParams,
    });
  };

  const getOpenTotal = (queryParams) => {
    return axiosPrivate.get(`/service-invoicing/open-total`, {
      params: queryParams,
    });
  };

  const payoutAccount = (payoutData) => {
    return axiosPrivate.put(`/service-invoicing/payout-now`, payoutData);
  };

  const refundInvoice = (invoiceId) => {
    return axiosPrivate.put(`/service-invoicing/refund/${invoiceId}`, null);
  };

  return {
    getInvoices,
    createAccount,
    createAccessLink,
    getAccountStatus,
    getLoginLink,
    getAccountBalance,
    getOpenTotal,
    createInvoice,
    payoutAccount,
    refundInvoice,
  };
}

export default ServiceInvoicingApi;
