/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";

function DefaultCell({
  value,
  suffix,
  prefix,
  sxValue = null,
  onClick = null,
  color = "text",
}) {
  return (
    <ArgonTypography
      variant="caption"
      fontWeight="medium"
      color={color}
      sx={sxValue}
      onClick={onClick}
    >
      {prefix && (
        <ArgonTypography variant="caption" fontWeight="medium" color={color}>
          &nbsp;&nbsp;{prefix}
        </ArgonTypography>
      )}
      {value}
      {suffix && (
        <ArgonTypography variant="caption" fontWeight="medium" color={color}>
          &nbsp;&nbsp;{suffix}
        </ArgonTypography>
      )}
    </ArgonTypography>
  );
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
