// API client for /notes endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function IamApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("common", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  // const getNotes = (queryParams) => {
  //   return axiosPrivate.get(`/notes`, { params: queryParams });
  // };

  const createMembership = (membershipData) => {
    return axiosPrivate.post(`/memberships`, membershipData);
  };

  const getUserMembership = (userId) => {
    return axiosPrivate.get(`/memberships/${userId}`);
  };

  const updateUserMembership = (userId, membershipData) => {
    return axiosPrivate.put(`/memberships/${userId}`, membershipData);
  };

  // const getNote = (noteId) => {
  //   return axiosPrivate.get(`/notes/${noteId}`);
  // };

  // const updateNote = (noteId, noteData) => {
  //   console.log(noteData);
  //   return axiosPrivate.put(`/notes/${noteId}`, noteData);
  // };

  // const deleteNote = (noteId) => {
  //   return axiosPrivate.delete(`/notes/${noteId}`);
  // };

  return {
    createMembership,
    getUserMembership,
    updateUserMembership,
  };
}

export default IamApi;
