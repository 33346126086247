/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// import { confirmPasswordReset } from "firebase/auth";
// import { auth } from "layouts/authentication/firebase/firebase";
// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import UsersApi from "api/usersApi";
import HostHiveLoading from "components/HostHiveLoading";
import { UserAuth } from "context/AuthContext";
// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg";

function NewPassword() {
  const { updatePassword, signIn } = UserAuth();
  const navigate = useNavigate();
  const { oobCode } = useParams();
  //const oobCode = "sadkjgbfilksadgiusdagfujksadgf";
  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
  const usersApi = UsersApi();
  const [readyForRedirect, setReadyForRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [updatePasswordInProgress, setUpdatePasswordInProgress] =
    useState(false);
  const onSubmit = async (values, actions) => {
    setUpdatePasswordInProgress(true);
    try {
      const resp = await updatePassword(oobCode, values.password);
      console.log(resp);
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      console.log("Redirecting to home page");
      setReadyForRedirect(true);
      setUpdatePasswordInProgress(false);
      //navigate("/");
      actions.resetForm();
    }, 3000);
  };

  useEffect(() => {
    if (readyForRedirect) {
      console.log("Redirecting to home page");
      navigate("/");
    }
  }, [readyForRedirect]);

  const EmailSchema = Yup.object().shape({
    password: Yup.string()
      .min(5)
      .matches(passwordRules, {
        message: "Please create a stronger password",
      })
      .required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: EmailSchema,
    onSubmit,
  });

  return (
    <BasicLayout
      image={bgImage}
      button={{ variant: "gradient", color: "dark" }}
    >
      <Card>
        <ArgonBox pt={3} px={3} pb={1} textAlign="center">
          <ArgonTypography variant="h4" fontWeight="bold" textGradient>
            Update Password
          </ArgonTypography>
          {/* <ArgonTypography variant="body2" color="text">
            You will receive an e-mail in maximum 60 seconds
          </ArgonTypography> */}
        </ArgonBox>
        {updatePasswordInProgress ? (
          <HostHiveLoading message="Redirecting to home page" elipse={true} />
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <ArgonBox p={3}>
                <ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonInput
                      placeholder="New Password"
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />

                    {errors.password && touched.password && (
                      // <p className="error">{errors.name}</p>
                      <HostHiveErrorMessage message={errors.password} />
                    )}
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonInput
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />

                    {errors.confirmPassword && touched.confirmPassword && (
                      // <p className="error">{errors.name}</p>
                      <HostHiveErrorMessage message={errors.confirmPassword} />
                    )}
                  </ArgonBox>
                  <ArgonBox mt={5} mb={1}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      size="large"
                      fullWidth
                      type="submit"
                    >
                      Reset password
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </form>
          </>
        )}
      </Card>
    </BasicLayout>
  );
}

export default NewPassword;
