import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "./context/AuthContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { RouteProtection } from "services/routeProtectionHelper";

const ProtectedRoute = ({ children, routeLookUpKey }) => {
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  if (pending) {
    return (
      <Box sx={{ display: "flex" }}>
        <CircularProgress />
      </Box>
    );
  }

  const protector = RouteProtection(routeLookUpKey);

  let requiredRoles = protector?.permittedRoles;
  let notEligibleSubscriptionTiers = protector?.notEligibleSubscriptionTiers;

  // console.log("Required Roles: ", requiredRoles);
  // console.log("Not Eligible Subscription Tier: ", notEligibleSubscriptionTiers);
  // console.log("User Role: ", role);
  // if (requiredRoles) {
  //   console.log("has role: ", requiredRoles.includes(role));
  // }

  if (!user) {
    return <Navigate to="/authentication/login" />;
  }

  if (requiredRoles && requiredRoles.length > 0) {
    if (requiredRoles[0] === "all" || requiredRoles.includes(role)) {
      console.log("Has role", requiredRoles, role);
    } else {
      return <Navigate to="/" />;
    }
  } else {
    // Handle the case where requiredRoles is empty or undefined
    console.log("No role requirements specified");
  }

  if (
    notEligibleSubscriptionTiers &&
    notEligibleSubscriptionTiers.includes(subscriptionTier)
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
