// API client for /notes endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function NotesApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized(
    "propertiesPlus",
    role,
    subscriptionTier
  );

  if (!isAuthorized) {
    return null;
  }
  const baseUrl = "/documents";
  const getDocuments = (queryParams) => {
    return axiosPrivate.get(`${baseUrl}`, { params: queryParams });
  };

  const createDocument = (documentData) => {
    return axiosPrivate.post(`${baseUrl}`, documentData);
  };

  const getDocument = (documentId) => {
    return axiosPrivate.get(`${baseUrl}/${documentId}`);
  };

  const getDocumentPutUrl = (documentId) => {
    return axiosPrivate.put(`${baseUrl}/${documentId}/url`);
  };

  const updateDocument = (documentId, documentData) => {
    return axiosPrivate.put(`${baseUrl}/${documentId}`, documentData);
  };

  const deleteDocument = (documentId) => {
    return axiosPrivate.delete(`${baseUrl}/${documentId}`);
  };

  return {
    getDocuments,
    createDocument,
    getDocument,
    updateDocument,
    deleteDocument,
    getDocumentPutUrl,
  };
}

export default NotesApi;
