export const acceptedImageMimeTypes = [
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/bmp",
  "image/svg+xml",
  "image/webp",
  "image/tiff",
  "image/heic",
  "image/heif",
];

export const acceptedImageSizeInMb = 15;

export const acceptedPropertyImageSizeInMb = 20;

export const acceptedDocumentSizeInMb = 5;

export const acceptedVideoMimeTypes = [
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-flv",
  "video/x-ms-wmv",
  "video/webm",
  "video/3gpp",
  "video/3gpp2",
  "video/ogg",
  "video/x-matroska",
  "video/h264",
  "video/h265",
  "video/av1",
  "video/mpeg",
  "video/mpeg2",
  "video/mpeg4",
  "video/mpegts",
  "video/mpegurl",
  "video/MP2T",
  "video/3gpp-tt",
  "video/3gpp2-tt",
];

export const acceptedVideoSizeInMb = 100;

export const acceptedDocumentMimeTypes = [
  // Microsoft MIME Types
  "application/msword", // .doc
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
  "application/vnd.ms-excel", // .xls
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
  "application/vnd.ms-powerpoint", // .ppt
  "application/vnd.openxmlformats-officedocument.presentationml.presentation", // .pptx

  // Google MIME Types (for native Google formats; converted types will use Microsoft MIMEs)
  "application/vnd.google-apps.document",
  "application/vnd.google-apps.spreadsheet",
  "application/vnd.google-apps.presentation",

  // Apple MIME Types
  "application/x-iwork-pages-sffpages",
  "application/x-iwork-numbers-sffnumbers",
  "application/x-iwork-keynote-sffkey",

  "application/pdf",
  "application/rtf",

  "text/plain",
  "text/csv",
  "text/tab-separated-values",
  "text/xml",
  "text/html",
  "application/zip",
  "text/markdown",

  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.presentation",
];
