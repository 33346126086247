import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Faq from "layouts/pages/account-support-faq/components/Faq";
import Header from "./components/Header";
import Footer from "examples/Footer";

function MobileApps({}) {
  return (
    <PageLayout>
      <Header />
      <Container>
        {/* <PricingCards prices={prices} /> */}
        {/* <TrustedBrands /> */}
        <Faq />
      </Container>
      <ArgonBox mt={8} mb={10}>
        <Footer />
      </ArgonBox>
    </PageLayout>
  );
}

export default MobileApps;
