/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MuiLink from "@mui/material/Link";

// react-router-dom components
import { Link, useLocation } from "react-router-dom";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
const handleVisibleClick = (data) => {
  return (
    <MuiLink href={`/properties/${data.id}`} rel="noreferrer">
      Test
    </MuiLink>
  );
};


function ActionCell(event) {
  let data = event.data;
  let url = `/properties/${data.id}`
  return (
    <ArgonBox display="flex" alignItems="center">
      <ArgonTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Preview property" placement="top">
          <MuiLink href={url} rel="noreferrer">
            <Icon onClick={() => handleVisibleClick(data)}>visibility</Icon>
          </MuiLink>
        </Tooltip>
      </ArgonTypography>
      <ArgonBox mx={2}>
        <ArgonTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Edit property" placement="top">
            <Icon>edit</Icon>
          </Tooltip>
        </ArgonTypography>
      </ArgonBox>
      <ArgonTypography variant="body1" color="secondary" sx={{ cursor: "pointer", lineHeight: 0 }}>
        <Tooltip title="Delete property" placement="left">
          <Icon>delete</Icon>
        </Tooltip>
      </ArgonTypography>
    </ArgonBox>
  );
}

export default ActionCell;
