/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import ClearIcon from "@mui/icons-material/Clear";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
// Timeline context
import { useTimeline } from "examples/Timeline/context";
//import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
// Custom styles for the TimelineItem
import {
  timelineItem,
  timelineItemIcon,
} from "examples/Timeline/TimelineItem/styles";
import { useState } from "react";
import HostHiveDialog from "components/HostHiveDialog";
function TimelineItem({
  propertyDetails,
  color,
  icon,
  id,
  status,
  title,
  priority,
  dateTime,
  description,
  ticketNumber,
  lastItem,
}) {
  const isDark = useTimeline();

  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={handleCloseMenu}>Update Status</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Close</MenuItem>
      <MenuItem onClick={handleScheduleService}>Schedule Service</MenuItem>
    </Menu>
  );

  //onclick={handleScheduleService}

  const handleScheduleService = () => {
    console.log("Schedule Service");
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const handleCancelClicked = () => {
    setOpen(false);
  };
  const handleSubmitClicked = () => {
    setOpen(false);
  };

  return (
    <>
      <ArgonBox
        position="relative"
        sx={(theme) => timelineItem(theme, { lastItem })}
      >
        <ArgonBox
          bgColor={isDark ? "dark" : "white"}
          width="1.625rem"
          height="1.625rem"
          borderRadius="50%"
          position="absolute"
          top="3.25%"
          left="2px"
          zIndex={2}
        >
          <Icon color={color}> circle</Icon>

          <HostHiveDialog
            open={open}
            fullWidth={true}
            maxWidth="md"
            onClose={handleCancelClicked}
            onSave={handleSubmitClicked}
            title="Schedule service"
            fullScreen={false}
            cancelButtonText="Cancel"
            submitButtonText="Save"
            dialogObjects={
              <EventDataCard
                isCreation={true}
                propertyInfo={{
                  label: propertyDetails?.name,
                  value: propertyDetails?.id,
                }}
                handleClose={() => {
                  setOpen(false);
                }}
              />
            }
          />
        </ArgonBox>
        <ArgonBox
          ml={5.75}
          pt={description ? 0.7 : 0.5}
          lineHeight={0}
          //maxWidth="30rem"
        >
          <ArgonBox justifyContent="space-between" display="flex">
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color={isDark ? "white" : "dark"}
              >
                {title}
              </ArgonTypography>
            </ArgonBox>
            {renderMenu()}
            <ArgonTypography
              color="secondary"
              onClick={handleOpenMenu}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          </ArgonBox>

          <ArgonBox mt={0.5}>
            <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color={isDark ? "secondary" : "text"}
            >
              {convertTimeStamp(dateTime, "America/New_York")}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox mt={2} mb={1.5}>
            {description ? (
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {description}
              </ArgonTypography>
            ) : null}
          </ArgonBox>
          <ArgonBox mt={2} mb={1.5}>
            {ticketNumber ? (
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                #{ticketNumber}
              </ArgonTypography>
            ) : null}
          </ArgonBox>
          <ArgonBox
            display="flex"
            pb={lastItem ? 1 : 2}
            justifyContent="space-between"
          >
            {/* {renderBadges} */}
            <ArgonBox key={id}>
              <ArgonBadge
                color={color}
                size="xs"
                badgeContent={status}
                container
              />
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  lastItem: PropTypes.bool,
};

export default TimelineItem;
