/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";

import { useState, useEffect } from "react";
// @mui material components
import SupportTicketSchema from "schemas/newSupportTicket";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import { setIn, useFormik } from "formik";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import CompanyApi from "api/companyApi";
import PropertySelect from "layouts/management/components/PropertySelect";
import BookingsApi from "api/bookingsApi";
import ServiceNameApi from "api/service-nameApi";
import SettingsApi from "api/settingsApi";
import ArgonAlert from "components/ArgonAlert";
import ChecklistsApi from "api/checkListsApi";
import ApplicationApi from "api/applicationApi";
import PropertiesApi from "api/propertiesApi";
import AccountingApi from "api/accountingApi";
import ContactSupportApi from "api/contactSupportApi";
import { UserAuth } from "context/AuthContext";
function SupportTicket({
  onClose,
  fetchSupportItem,
  propertyInfo,
  supportItem,
  edit = false,
  includePropertySelect = true,
}) {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const contactSupportApi = ContactSupportApi();
  const [property, setProperty] = useState(null);
  const [createIntegrationSettings, setCreateIntegrationSettings] =
    useState(false);
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  // useEffect(() => {
  //   async function fetchProperties() {
  //     if (propertyInfo) {
  //       setProperty({
  //         value: propertyInfo?.propertyId,
  //         label: propertyInfo?.propertyName,
  //       });
  //     }
  //   }

  //   fetchProperties();
  // }, [property]);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("warning");
  const [alertMessage, setAlertMessage] = useState(
    "Some long alert message about something that went wrong."
  );
  const onSubmit = async (values, actions) => {
    // if (!property?.value && !propertyInfo?.propertyId) {
    //   console.log(propertyInfo);
    //   console.error("property is required");
    //   setAlertColor("error");
    //   setAlertMessage("Property selection is Required");
    //   setShowAlert(true);
    //   return;
    // }
    if (property?.value && !propertyInfo?.propertyId) {
      values.propertyId = property?.value;
      values.propertyInfo = {
        name: property?.label,
        id: property?.value,
      };
    }

    if (values.propertyId === "") {
      delete values.propertyId;
    }

    values.companyId = company?.id;
    values.customerInfo = {
      companyName: company?.name,
      name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      email: userDetails?.email,
    };
    console.log(values);
    setIsSaving(true);
    try {
      let response;
      if (edit) {
        response = await contactSupportApi.updateSupportTicket(
          supportItem?.id,
          values
        );
      } else {
        response = await contactSupportApi.createSupportTicket(values);
      }
      //  response = await propertyApi.createProperty(values);
      if (response.status >= 200 && response.status < 300) {
        console.log("response", response);
        if (fetchSupportItem) {
          fetchSupportItem();
        }
      }
      setIsSaving(false);
      actions.resetForm();
      if (onClose) {
        onClose();
      }
    } catch (error) {
      setAlertColor("error");
      setAlertMessage(error.response.data.detail);
      setShowAlert(true);

      setIsSaving(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: company?.id,
      propertyId: property?.id || propertyInfo?.propertyId || "",
      summary: supportItem?.summary || "",
      description: supportItem?.description || "",
      status: "active",
      // Add more fields here
    },
    validationSchema: SupportTicketSchema,
    onSubmit,
  });
  const [category, setCategory] = useState({});

  const [transactionType, setTransactionType] = useState({
    value: "expense",
    label: t("accounting.general.expense"),
  });

  return (
    <form onSubmit={handleSubmit}>
      {showAlert ? (
        <ArgonAlert color={alertColor} dismissible>
          <ArgonBox display="flex" justifyContent="flex-end">
            <ArgonBox>
              <ArgonTypography variant="caption" color="white">
                {alertMessage}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </ArgonAlert>
      ) : null}
      <ArgonBox p={2} lineHeight={1}>
        <ArgonBox>
          {propertyInfo?.propertyName ? (
            <>{propertyInfo?.propertyName}</>
          ) : // <PropertySelect
          //   menuPlacement="bottom"
          //   property={property}
          //   setProperty={setProperty}
          //   companyId={company?.id}
          // />
          null}
        </ArgonBox>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        ></ArgonBox>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("generalWords.summary")}
            </ArgonTypography>
          </ArgonBox>
          <ArgonInput
            name="summary"
            type="text"
            label="Item Name"
            placeholder="Unable to find my property"
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSaving}
            // Add error handling from Formik state
            error={touched.summary && Boolean(errors.summary)}
            helperText={touched.summary && errors.summary}
            // onChange={(e) => console.log(e)}
          />
          {errors.summary && touched.summary && (
            // <p className="error">{errors.name}</p>
            <HostHiveErrorMessage message={errors.summary} />
          )}
        </ArgonBox>

        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("generalWords.description")}
            </ArgonTypography>
          </ArgonBox>
          <ArgonInput
            name="description"
            type="text"
            label="Item Name"
            placeholder="I cannot find my newly created property"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            disabled={isSaving}
            rows={4}
            // Add error handling from Formik state
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
            // onChange={(e) => console.log(e)}
          />
          {errors.description && touched.description && (
            // <p className="error">{errors.name}</p>
            <HostHiveErrorMessage message={errors.description} />
          )}
        </ArgonBox>
        {!edit ? (
          <ArgonBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            {includePropertySelect ? (
              <PropertySelect
                optional={true}
                menuPlacement="bottom"
                property={property}
                setProperty={setProperty}
                companyId={company?.id}
              />
            ) : null}
          </ArgonBox>
        ) : null}
      </ArgonBox>

      <ArgonBox justifyContent="center" display="flex" p={2}>
        <ArgonBox display="flex">
          <ArgonBox pr={2}>
            <ArgonButton
              variant="gradient"
              color="dark"
              onClick={() => onClose()}
              disabled={isSaving}
            >
              {t("buttons.cancel")}
            </ArgonButton>
          </ArgonBox>
          <ArgonBox pr={2}>
            <ArgonButton
              variant="gradient"
              color="info"
              type="submit"
              disabled={isSaving}
            >
              {isSaving
                ? !edit
                  ? "Creating..."
                  : "Updating..."
                : !edit
                ? t("buttons.create")
                : t("buttons.update")}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </form>
  );
}

export default SupportTicket;
