/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";
import Icon from "@mui/material/Icon";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import ContactSearch from "layouts/management/components/ContactSearch";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// NewUser page components

import ArgonSelect from "components/ArgonSelect";
import CategoryDialog from "layouts/management/components/CategoryDialog";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import HostHiveDialog from "components/HostHiveDialog";

import { useTranslation } from "react-i18next";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import { set } from "lodash";
import FormField from "../FormField";
function EventOptions({ setNextDisabled, invoicePayload, setInvoicePayload }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const { t } = useTranslation("common");
  if (setNextDisabled) {
    setNextDisabled(false);
  }
  // setNextDisabled(false);
  const [searchContactOpen, setSearchContactOpen] = useState(false);
  // const [searchContactSelected, setSearchContactSelected] = useState(null);

  // const handleSearchContact = () => {
  //   setInvoicePayload({
  //     ...invoicePayload,
  //     companyName: searchContactSelected.name,
  //     email: searchContactSelected.email,
  //     phone: searchContactSelected?.phone,
  //   });
  // };
  const createNewContact = async () => {
    console.log("createNewContact");
  };
  return (
    <>
      <HostHiveDialog
        open={searchContactOpen}
        onSave={() => setSearchContactOpen(false)}
        onClose={createNewContact}
        includeSave={true}
        includeClose={false}
        includeDelete={false}
        submitButtonColor="dark"
        showFullScreenArrow={false}
        title="Search Contact"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Close"
        dialogObjects={
          <>
            <ContactSearch
              searchContactSelected={invoicePayload}
              setSearchContactSelected={setInvoicePayload}
              onClose={() => setSearchContactOpen(false)}
            />
          </>
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ArgonBox lineHeight={0} pt={2}>
            {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Enter the contact information for the person or company you are
              invoicing.
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <ArgonBox lineHeight={0} pt={2} pl={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Name
                </ArgonTypography>
              </ArgonBox>
              <Grid container spacing={3}>
                <Grid item xs={10}>
                  <ArgonInput
                    value={invoicePayload?.companyName || ""}
                    placeholder="eg. HostHive/ Joe Green"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setInvoicePayload({
                        ...invoicePayload,
                        companyName: e.target.value,
                      });
                      console.log(invoicePayload);
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ArgonButton
                    variant="gradient"
                    color="primary"
                    onClick={() => setSearchContactOpen(true)}
                    sx={{
                      ml: "auto",
                      alignSelf: "flex-start",
                      py: 1.25,
                      pr: 1.5,

                      borderRadius: "8px",
                      borderWidth: "1px",
                      transition: "background-color 0.3s, transform 0.2s",
                      ":hover": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        transform: "scale(1.02)",
                      },
                    }}
                    startIcon={
                      <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                        search
                      </Icon>
                    }
                  >
                    Find
                  </ArgonButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            ></Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Email"
                placeholder="eg. busy-bee@hosthive.io"
                value={invoicePayload?.email || ""}
                onChange={(e) => {
                  console.log(e.target.value);
                  setInvoicePayload({
                    ...invoicePayload,
                    email: e.target.value,
                  });
                  console.log(invoicePayload);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Phone Number"
                placeholder="eg. (954) 123-4567"
                value={invoicePayload?.phone || ""}
                onChange={(e) => {
                  console.log(e.target.value);
                  setInvoicePayload({
                    ...invoicePayload,
                    phone: e.target.value,
                  });
                  console.log(invoicePayload);
                }}
              />
            </Grid>
          </Grid>
        </ArgonBox>
      </Grid>
    </>
  );
}

// typechecking props for EventOptions
EventOptions.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EventOptions;
