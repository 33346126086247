/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ComplexProjectCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard";
import BillingInfoCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/BillingCard";
import SubscriptionDetailsCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/SubscriptionDetailsCard";
import PropertiesCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/PropertyCard";
import NextPaymentCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/NextPaymentCard";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import { useParams } from "react-router-dom";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveAlert from "components/HostHiveAlert";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";
function Account({}) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  return (
    <Card
      id="account-settings"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <ArgonBox p={3}>
        <ArgonBox>
          <ArgonTypography variant="h5">Account Settings</ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ComplexProjectCard>
                <BillingInfoCard title="Billing info" />
              </ComplexProjectCard>
            </Grid>

            <Grid item xs={12} md={6}>
              <ComplexProjectCard>
                <SubscriptionDetailsCard title="Subscription Details" />
              </ComplexProjectCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ComplexProjectCard>
                <PropertiesCard
                  title="Properties"
                  companyId={company?.id}
                  isCompanySettingsRoute={true}
                />
              </ComplexProjectCard>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <ComplexProjectCard>
                <NextPaymentCard title="Next Payment" />
              </ComplexProjectCard>
            </Grid> */}
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default Account;
