/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import HostHiveLoading from "components/HostHiveLoading";
import { UserAuth } from "context/AuthContext";
import { auth } from "layouts/authentication/firebase/firebase";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import github from "assets/images/logos/github.svg";
import google from "assets/images/logos/google.svg";

import CompaniesApi from "api/companyApi";

import axios from "axios";
import getApiUrl from "services/apiBaseUrl";
import { useParams } from "react-router-dom";

const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg";

function AuthRedirect() {
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const { signIn, user, isAuthenticated } = UserAuth();
  const navigate = useNavigate();

  const { redirectUrl } = useParams();

  useEffect(() => {
    // Encoding
    // const encodedURL = btoa(
    //   "/calendar/5178e3af-9c44-409f-a3bb-319e4cfe7663-2024-01-02_2024-01-06"
    // );

    // Decoding
    const decodedURL = atob(redirectUrl);

    console.log("decodedURL", decodedURL);

    const userAuthenticated = isAuthenticated(user);
    console.log("redirectUrl", redirectUrl);
    if (userAuthenticated) {
      setUserAuthenticated(true);
      console.log("User is authenticated");
      navigate(decodedURL);
    } else {
      console.log("User is not authenticated authenticated", user);
      console.log("redirectUrl", redirectUrl);
      navigate(`/authentication/login/${redirectUrl}`);
    }
  }, [user]);

  const redirect = (path) => {
    console.log("Redirecting...", path);
  };

  return <Card></Card>;
}

export default AuthRedirect;
