/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import StatusCell from "layouts/management/serviceInvoicing/components/StatusCell";
import InvoiceStatusCell from "layouts/management/components/InvoiceStatusCell";
// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { convertTimeStamp } from "services/dateUtils";
// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";

function PaymentItem({ invoice }) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleRefund = () => {
    console.log("refund", invoice?.id);
    handleCloseMenu();
  };
  const handleMarkAsPaid = () => {
    console.log("mark as paid", invoice?.id);
    handleCloseMenu();
  };

  const handleSendReminder = () => {
    console.log("send reminder", invoice?.id);
    handleCloseMenu();
  };

  const handleResendReciept = () => {
    console.log("resend reciept", invoice?.id);
    handleCloseMenu();
  };

  const handleViewInvoiceClicked = () => {
    let url = invoice?.hostedInvoiceUrl;
    window.open(url, "_blank");
    // window.location.href = url;
  };

  const formatDate = (periodDate) => {
    let convertedDate = convertTimeStamp(periodDate);
    const date = new Date(convertedDate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      // style={{ marginTop: 16, paddingLeft: 4, paddingRight: 4 }}
    >
      {/* Date */}
      <Grid item xs={12} sm={1.4}>
        <ArgonTypography variant="caption" fontWeight="bold" color="text">
          {formatDate(invoice?.periodStart)}
        </ArgonTypography>
      </Grid>

      {/* Amount */}
      <Grid item xs={12} sm={0.5}>
        <ArgonTypography
          variant="caption"
          fontWeight="bold"
          color={invoice?.amountDue > 0 ? "success" : "error"}
        >
          ${invoice?.amountDue}
        </ArgonTypography>
      </Grid>

      {/* Status */}
      <Grid item xs={12} sm={1}>
        <ArgonBadge
          color={invoice?.statusInfo?.color}
          badgeContent={invoice?.statusInfo?.status}
          // container
          variant="contained"
          size="lg"
        />
      </Grid>

      <Grid item xs={12} sm={8}>
        <ArgonTypography variant="caption">
          {invoice?.description}
        </ArgonTypography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <IconButton>
          <Tooltip
            title="View Invoice"
            placement="top"
            onClick={handleViewInvoiceClicked}
          >
            <Icon color="text" onClick={handleViewInvoiceClicked}>
              open_in_new
            </Icon>
          </Tooltip>
        </IconButton>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of Todo
PaymentItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
PaymentItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  noDivider: PropTypes.bool,
};

export default PaymentItem;
