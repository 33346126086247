import React from "react";
import i18n from "./i18n";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { AuthContextProvider } from "./context/AuthContext";
import { PropertyContextProvider } from "./context/PropertyContextProvider";
import { CompanyContextProvider } from "context/CompanyContextProvider";
import { CompanyDetailsProvider } from "context/CompanyDetailsContext";
import { I18nextProvider } from "react-i18next";
import { ApplicationContextProvider } from "./context/ApplicationContextProvider";

import App from "App";

// Soft UI Context Provider
import { ArgonControllerProvider } from "context";

// react-perfect-scrollbar component
import PerfectScrollbar from "react-perfect-scrollbar";

// react-perfect-scrollbar styles
import "react-perfect-scrollbar/dist/css/styles.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <I18nextProvider i18n={i18n}>
    <BrowserRouter>
      <ArgonControllerProvider>
        <PerfectScrollbar>
          <AuthContextProvider>
            <CompanyContextProvider>
              <CompanyDetailsProvider>
                <PropertyContextProvider>
                  <ApplicationContextProvider>
                    <App />
                  </ApplicationContextProvider>
                </PropertyContextProvider>
              </CompanyDetailsProvider>
            </CompanyContextProvider>
          </AuthContextProvider>
        </PerfectScrollbar>
      </ArgonControllerProvider>
    </BrowserRouter>
  </I18nextProvider>
);
