import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonTagInput from "components/ArgonTagInput";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import ArgonTypography from "components/ArgonTypography";

import ArgonInput from "components/ArgonInput";
import { v4 as uuidv4 } from "uuid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HostHiveDialog from "components/HostHiveDialog";
import ChecklistItemDialog from "layouts/management/company/settings/components/ServiceChecklists/components/ChecklistItemDialogTest";
export default function TestNewChecklist() {
  const [checklistDialogOpen, setChecklistDialogOpen] = useState(false);
  const [checklistDetails, setChecklistDetails] = useState({
    checklist: [
      {
        id: "1",
        summary: "Kitchen",
        items: [
          { id: "item1", itemName: "wash dishes", checked: false },
          { id: "item2", itemName: "clean counters", checked: false },
          { id: "item3", itemName: "clean stove", checked: false },
        ],
      },
      {
        id: "2",
        summary: "Bathroom",
        items: [
          { id: "item4", itemName: "clean toilet", checked: false },
          { id: "item5", itemName: "clean sink", checked: false },
          { id: "item6", itemName: "clean shower", checked: false },
        ],
      },
    ],
    companyId: "679e15de-bd0d-4ba7-a893-ab0cf54ed843",
    name: "Standard checklist test",
    status: "active",
    id: "6016f86a-0466-4017-86b7-4644b0f02e9b",
  });
  const fetchChecklists = () => {
    console.log("fetchChecklists");
  };
  return (
    <DashboardLayout>
      <HostHiveDialog
        open={checklistDialogOpen}
        onClose={() => setChecklistDialogOpen(false)}
        includeSave={false}
        includeClose={false}
        title="Edit Checklist"
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ChecklistItemDialog
            checklistDetails={checklistDetails}
            onClose={() => setChecklistDialogOpen(false)}
            companyId={checklistDetails?.companyId}
            fetchChecklists={fetchChecklists}
            snackBarOpen={null}
          />
        }
      />
      <Card>
        <ArgonButton
          variant="outlined"
          color="primary"
          onClick={() => setChecklistDialogOpen(true)}
        >
          {" "}
          Open
        </ArgonButton>
      </Card>
    </DashboardLayout>
  );
}
