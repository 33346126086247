/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonAlert from "components/ArgonAlert";
import Card from "@mui/material/Card";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AnimatedStatisticsCard from "examples/Cards/StatisticsCards/AnimatedStatisticsCard";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import AnnouncementCard from "examples/Cards/AnnouncementCard";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
import { useNavigate, useParams } from "react-router-dom";
import { onboardStripeConnect } from "services/onboardStripeConnect";
// General page components
import RecentServicePayments from "layouts/management/serviceInvoicing/components/RecentServicePayments";
import HostHiveDialog from "components/HostHiveDialog";
import BookingsApi from "api/bookingsApi";
// Data
import progressLineChartData from "layouts/pages/projects/general/data/progressLineChartData";
import progressDoughnutChartData from "layouts/pages/projects/general/data/progressDoughnutChartData";
import DefaultCounterCard from "layouts/management/serviceInvoicing/components/DefaultCounterCard";
// Images
import team3 from "assets/images/team-3.jpg";
import OptionsCard from "layouts/management/serviceInvoicing/components/OptionsCard";
import ArgonButton from "components/ArgonButton";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import ArgonTypography from "components/ArgonTypography";
import Stack from "@mui/material/Stack";
import ServiceInvoicingApi from "api/serviceInvoicing";
import CreateInvoiceWizard from "layouts/management/serviceInvoicing/components/CreateInvoiceWizard";
import InvoicePreview from "layouts/management/serviceInvoicing/components/InvoicePreview";
import { has } from "lodash";
function ServiceInvoicingDashboard({ createPaymentRequest = false }) {
  const serviceInvoicingApi = ServiceInvoicingApi();
  const { appointmentId } = useParams();
  const bookingsApi = new BookingsApi();
  const [hasGoodStandingAccount, setHasGoodStandingAccount] = useState(false);
  const [onBoardAccount, setOnBoardAccount] = useState(false);
  const [creatingAccount, setCreatingAccount] = useState(false);
  const [resumingOnboarding, setResumingOnboarding] = useState(false);
  const [finishOnboarding, setFinishOnboarding] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [pendingPayoutBalance, setPendingPayoutBalance] = useState(0);
  const [openTotal, setOpenTotal] = useState(0);
  const navigate = useNavigate();
  const [createInvoiceOpen, setCreateInvoiceOpen] = useState(false);
  const [triggerFetchInvoices, setTriggerFetchInvoices] = useState(false);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [pendingAmountInTransit, setPendingAmountInTransit] = useState(0);
  const [createPaymentRequestCompanyInfo, setCreatePaymentRequestCompanyInfo] =
    useState(null);
  const confirmOnboardStripAccount = async () => {
    try {
      await onboardStripeConnect();
      console.log(`User confirmed deletion!`);
      // setAppointmentIntegrationCounts(0);
      return true;
    } catch (response) {
      // console.log("User cancelled deletion!");
      return false;
    }
  };

  const handleOnboardAccount = async () => {
    setCreatingAccount(true);
    try {
      const response = await serviceInvoicingApi.createAccount();
      console.log(response);
      window.location.href = response.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const [goodStandingAccountLoading, setGoodStandingAccountLoading] =
    useState(true);

  useEffect(() => {
    async function fetchAccount() {
      setGoodStandingAccountLoading(true);
      const queryParams = {
        confirmOnboardComplete: true,
      };
      try {
        const response = await serviceInvoicingApi.getAccountStatus(
          queryParams
        );
        console.log(response);
        if (response.data.onboardComplete === true) {
          setOnBoardAccount(false);
          setHasGoodStandingAccount(true);
        } else {
          setFinishOnboarding(true);
        }
      } catch (error) {
        console.log("Found error", error);
        if (error?.response?.status === 404) {
          setOnBoardAccount(true);
        }
        setHasGoodStandingAccount(false);
      }
      setGoodStandingAccountLoading(false);
    }

    async function fetchInvoices() {
      const queryParams = {
        skip: 0,
        limit: 10,
      };
      try {
        const response = await serviceInvoicingApi.getInvoices(queryParams);
        console.log("invoices", response?.data);
        setInvoices(response?.data);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchAccountBalance() {
      const queryParams = {};
      try {
        const response = await serviceInvoicingApi.getAccountBalance(
          queryParams
        );
        console.log("Account balance", response);
        setPendingPayoutBalance(response?.data?.availableBalance);
        setPendingAmountInTransit(response?.data?.pendingPayout);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchOpenTotal() {
      const queryParams = {};
      try {
        const response = await serviceInvoicingApi.getOpenTotal(queryParams);
        console.log("Open total", response);
        setOpenTotal(response?.data?.openInvoices);
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchBookingDetails() {
      const queryParams = {};
      try {
        const response = await bookingsApi.getBookingOwnerInfo(appointmentId);
        console.log("Owner Info", response?.data);
        // setOpenTotal(response?.data?.openInvoices);
        setCreateInvoiceOpen(true);
      } catch (error) {
        console.log(error);
      }
    }

    async function paymentRequestFromParams() {
      if (hasGoodStandingAccount) {
        fetchBookingDetails();
      }
    }

    async function confirmOnboardStripAccount() {
      const confirmationNavigation = await confirmOnboardStripAccount();
      if (confirmationNavigation) {
        ///service-invoicing/create/:appointmentId
        handleOnboardAccount();
      } else {
        navigate("/service-invoicing");
      }
    }

    fetchAccount();
    fetchInvoices();
    fetchAccountBalance();
    fetchOpenTotal();
  }, [triggerFetchInvoices]);

  useEffect(() => {
    async function confirmOnboardStripeAccount() {
      console.log("Confirming if user wants to onboard stripe account");
    }

    async function fetchBookingDetails() {
      const queryParams = {};
      try {
        const response = await bookingsApi.getBookingOwnerInfo(appointmentId);
        console.log("Owner Info", response?.data);
        setCreatePaymentRequestCompanyInfo(response?.data);

        // setOpenTotal(response?.data?.openInvoices);
        setCreateInvoiceOpen(true);
      } catch (error) {
        console.log(error);
        setCreateInvoiceOpen(true);
      }
    }

    if (
      !goodStandingAccountLoading &&
      !hasGoodStandingAccount &&
      createPaymentRequest
    ) {
      confirmOnboardStripeAccount();
    }

    if (
      !goodStandingAccountLoading &&
      hasGoodStandingAccount &&
      createPaymentRequest
    ) {
      fetchBookingDetails();
    }
  }, [goodStandingAccountLoading]);

  const ViewLinkModal = ({ title, handleOnClick }) => {
    return (
      <ArgonTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
        sx={{ ":hover": { textDecoration: "underline" }, cursor: "pointer" }}
        onClick={handleOnClick}
        title={title}
      >
        {title}
      </ArgonTypography>
    );
  };

  const handleLoginLink = async () => {
    try {
      const response = await serviceInvoicingApi.getLoginLink();
      window.location.href = response.data.url;
    } catch (error) {
      console.log(error);
    }
  };

  const handleResumeOnboarding = async () => {
    setResumingOnboarding(true);
    try {
      const response = await serviceInvoicingApi.createAccessLink();
      console.log(response);
      window.location.href = response.data.url;
    } catch (error) {
      console.log(error);
    }
  };
  const handlePayoutNow = async () => {
    try {
      const response = await serviceInvoicingApi.payoutAccount();
      console.log("payout response", response);
      setPendingPayoutBalance(0);
    } catch (error) {
      console.log(error);
      setError(error);
      setShowAlert(true);
    }
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={3}>
        <HostHiveDialog
          includeDelete={false}
          includeSave={false}
          includeClose={false}
          open={createInvoiceOpen}
          onClose={() => {
            {
              setCreateInvoiceOpen(false);
            }
          }}
          title="Create Invoice"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Create"
          maxWidth="lg"
          dialogObjects={
            <>
              <CreateInvoiceWizard
                closeWizard={() => {
                  setCreateInvoiceOpen(false);
                  setCreatePaymentRequestCompanyInfo(null);
                  navigate("/service-invoicing");
                }}
                fetchInvoices={() =>
                  setTriggerFetchInvoices(!triggerFetchInvoices)
                }
                customerInfo={createPaymentRequestCompanyInfo}
              />
            </>
          }
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <ArgonBox
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                p={3}
              >
                {/* {renderSuccessSB} */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <ArgonBox lineHeight={1}>
                      <ArgonTypography variant="h5" fontWeight="medium">
                        Service Invoicing
                      </ArgonTypography>
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {/* {t("components.tasks.subText")} */}
                        Recieve payments for services rendered
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ArgonBox width="100%" textAlign="right" lineHeight={1}>
                      <ArgonBox>
                        {onBoardAccount ? (
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            // onClick={handleSearchClicked}
                            disabled={hasGoodStandingAccount || creatingAccount}
                            onClick={handleOnboardAccount}
                          >
                            {creatingAccount
                              ? "Creating account..."
                              : "Start Collecting Payments"}
                          </ArgonButton>
                        ) : null}
                        {hasGoodStandingAccount ? (
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            onClick={() => setCreateInvoiceOpen(true)}
                            disabled={!hasGoodStandingAccount}
                            // onClick={() => setDateFilterOpen(true)}
                          >
                            New Payment Request
                          </ArgonButton>
                        ) : null}
                        {finishOnboarding ? (
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            onClick={handleResumeOnboarding}
                            disabled={resumingOnboarding}
                            // onClick={() => setDateFilterOpen(true)}
                          >
                            {resumingOnboarding
                              ? "Resuming Onboarding..."
                              : "Resume Onboarding"}
                          </ArgonButton>
                        ) : null}
                      </ArgonBox>
                      {hasGoodStandingAccount ? (
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          gap={1}
                          pt={2}
                        >
                          <ViewLinkModal
                            title="View Stripe Account Dashboard"
                            handleOnClick={handleLoginLink}
                          />
                          {/* View Stripe account */}
                        </ArgonBox>
                      ) : null}
                      {/* <ArgonBox
                        display="flex"
                        flexDirection="column"
                        gap={1}
                        pt={1}
                      >
                        <ViewLinkModal title="View Stripe account" />
                      </ArgonBox> */}
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>
          <Grid item xs={12}>
            {showAlert ? (
              <ArgonBox p={2}>
                <ArgonAlert color="error" dismissible>
                  <ArgonTypography variant="caption" color="white">
                    {error?.response?.data?.detail}
                  </ArgonTypography>
                </ArgonAlert>
              </ArgonBox>
            ) : null}
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DefaultCounterCard
                  hasGoodStandingAccount={hasGoodStandingAccount}
                  thisWeek={{
                    count: openTotal,
                    prefix: "$",
                    title: "Pending Invoices",
                    // action: (
                    //   <ArgonButton
                    //     variant="gradient"
                    //     color="info"
                    //     size="small"
                    //     // onClick={() => setOpen(true)}
                    //   >
                    //     View
                    //   </ArgonButton>
                    // ),
                  }}
                  pendindAvailableBalance={{
                    count: pendingAmountInTransit,
                    prefix: "$",
                    title: "Amount in transit",
                  }}
                  balance={{
                    count: pendingPayoutBalance,
                    prefix: "$",
                    title: "Available for Payout",
                    action: (
                      <ArgonButton
                        variant="gradient"
                        color="success"
                        size="small"
                        disabled={
                          !hasGoodStandingAccount || pendingPayoutBalance < 1
                        }
                        onClick={handlePayoutNow}
                      >
                        Pay out now
                      </ArgonButton>
                    ),
                  }}
                  // openInvoices={{
                  //   // sum open invoices
                  //   count: 100,
                  //   prefix: "$",
                  //   title: "Pending Invoices",
                  // }}
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ArgonBox my={3}>
                <RecentServicePayments
                  invoices={invoices}
                  setInvoices={setInvoices}
                />
              </ArgonBox>
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ServiceInvoicingDashboard;
