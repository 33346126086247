import { useState, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import convertToOptions from "services/convertooptions";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import SavingDataCard from "../SavingCard";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import BookingsApi from "api/bookingsApi";
import ChecklistsApi from "api/checkListsApi";
import CompaniesApi from "api/companyApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import HostHiveDialog from "components/HostHiveDialog";
import PropertySelect from "layouts/management/components/PropertySelect";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material";
import ServiceAppointmentChangeRequest from "layouts/management/components/ServiceAppointmentChangeRequest";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
import { useTranslation } from "react-i18next";
import EditEventDataCard from "layouts/management/calendar/components/EditEventDataCard";
function EventDataCard({
  onClose,
  bookingInfo = null,
  isCreation,
  scheduleStartDate,
  propertyInfo = null,
  setRefetchBookings = null,
  setExternalFetch = null,
  confirmBookingsFetch = null,
  setConfirmBookingsFetch = null,
  readOnly = false,
  disableViewPropertyDetails = false,
}) {
  const convertToDateFormat = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const resetTheme = createTheme();

  const checkListsApi = new ChecklistsApi();
  const serviceNamesApi = new ServiceNameApi();
  const companiesApi = new CompaniesApi();
  const usersApi = new UsersApi();
  const [categories, setCategories] = useState([]);
  const [serviceReportOpen, setServiceReportOpen] = useState(false);
  const [bookingNotes, setBookingNotes] = useState(
    bookingInfo?.bookingNotes || null
  );
  const [customStatusColor, setCustomStatusColor] = useState(
    bookingInfo?.status
  );
  const [declineAppointmentOpen, setDeclineAppointmentOpen] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [property, setProperty] = useState({});
  const [startTime, setStartTime] = useState(
    bookingInfo?.timeObject?.startTime || null
  );
  const [viewMode, setViewMode] = useState(true);
  const [isLoading, setIsloading] = useState(true);
  const [availableServiceNames, setAvailableServiceNames] = useState([]);
  const [availableChecklists, setAvailableChecklists] = useState([]);
  const [availableServicers, setAvailableServicers] = useState([]);
  const [serviceName, setServiceName] = useState(null);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedServicer, setSelectedServicer] = useState(null);
  const [savingAssignment, setSavingAssignment] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [propertySelectionEnabled, setPropertySelectionEnabled] =
    useState(true);
  const [recurrenceSeriesDialogOpen, setRecurrenceSeriesDialogOpen] =
    useState(false);
  const [recurrenceConfigurationOpen, setRecurrenceConfigurationOpen] =
    useState(false);
  const [recurrenceData, setRecurrenceData] = useState(null); // [startDate, endDate, interval, intervalType, recurrenceType
  const [repeatEvent, setRepeatEvent] = useState(false);
  const [recurrenceAllowed, setRecurrenceAllowed] = useState(true);
  const [updateSeries, setUpdateSeries] = useState(null);
  const [startDate, setStartDate] = useState(
    //    bookingInfo?.serviceScheduleDate || scheduleStartDate || null
    dayjs(
      bookingInfo?.serviceScheduleDate || convertToDateFormat(scheduleStartDate)
    )
    //
  );

  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [isAppointmentOwner, setIsAppointmentOwner] = useState(false);

  const [requestChangesDialogOpen, setRequestChangesDialogOpen] =
    useState(false);
  const [showSubAssignee, setShowSubAssignee] = useState(false);
  useState(() => {
    if (bookingInfo?.id) {
      if (bookingInfo?.isOwner === true) {
        setIsAppointmentOwner(true);
      } else {
        setIsAppointmentOwner(false);
      }

      if (bookingInfo?.isIntegration === true) {
        setRecurrenceAllowed(false);
      }
      if (bookingInfo?.recurrenceId) {
        setRepeatEvent(true);
        setRecurrenceData(bookingInfo?.recurrenceData);
      } else {
        console.log("bookingInfo", bookingInfo?.recurrenceId);
      }
    }
  }, []);

  const [selectedSubAssignee, setSelectedSubAssignee] = useState(null);
  const [availableSubAssigneeUsers, setAvailableSubAssigneeUsers] = useState(
    []
  );
  const [category, setCategory] = useState(null);
  // useEffect(() => {
  //   const loadCompanyDetails = async () => {
  //     setIsloading(true);
  //     const queryParams = {
  //       includeSelfAsPartner: true,
  //     };

  //     const checklistResponse = await checkListsApi.getChecklists({
  //       companyId: company?.id,
  //     });

  //     const serviceNamesResponse = await serviceNamesApi.getServiceNames({
  //       companyId: company?.id,
  //     });

  //     const servicePartnersResponse = await companiesApi.getCompanyPartnerships(
  //       company?.id,
  //       {}
  //     );

  //     const companyUsersResponse = await usersApi.getUsers({
  //       companyId: company?.id,
  //     });
  //     // Set available options
  //     setAvailableChecklists(checklistResponse?.data || []);
  //     setAvailableServiceNames(serviceNamesResponse?.data || []);
  //     setAvailableServicers(servicePartnersResponse?.data || []);
  //     setAvailableSubAssigneeUsers(companyUsersResponse?.data || []);

  //     // Find and set selected serviceName
  //     const matchingServiceName = serviceNamesResponse?.data?.find(
  //       (service) => service.id === bookingInfo?.serviceName
  //     );
  //     if (matchingServiceName) {
  //       setServiceName({
  //         label: matchingServiceName.name,
  //         value: matchingServiceName.id,
  //       });
  //       console.log(
  //         matchingServiceName?.id,
  //         "matchingServiceName",
  //         serviceName
  //       );
  //     }

  //     // Find and set selected checklist
  //     const matchingChecklist = checklistResponse?.data?.find(
  //       (checklist) => checklist.id === bookingInfo?.checklistId
  //     );
  //     if (matchingChecklist) {
  //       setSelectedChecklist({
  //         label: matchingChecklist.name,
  //         value: matchingChecklist.id,
  //       });
  //     }

  //     // Find and set selected servicer
  //     const matchingServicer = servicePartnersResponse?.data?.find(
  //       (servicer) => servicer.id === bookingInfo?.assigneeId
  //     );
  //     if (matchingServicer) {
  //       setSelectedServicer({
  //         label: matchingServicer.name,
  //         value: matchingServicer.id,
  //       });
  //     }

  //     const matchingSubAssignee = companyUsersResponse?.data?.find(
  //       (servicerSubAssignee) =>
  //         servicerSubAssignee.id === bookingInfo?.assigneeSubId
  //     );

  //     if (matchingSubAssignee) {
  //       setSelectedSubAssignee({
  //         label: matchingSubAssignee.email,
  //         value: matchingServicer.id,
  //       });
  //     }

  //     setIsloading(false);
  //   };
  //   if (bookingInfo?.id) {
  //     setViewMode(true);
  //     console.log("bookingInfo", bookingInfo);
  //   } else {
  //     setViewMode(false);
  //   }

  //   if (propertyInfo || bookingInfo?.propertyId) {
  //     console.log(bookingInfo);
  //     setProperty(bookingInfo?.propertyInfo);
  //     setPropertySelectionEnabled(false);
  //     setSaveDisabled(false);
  //   } else {
  //     setPropertySelectionEnabled(true);
  //     setSaveDisabled(true);
  //     console.log("-----", propertyInfo);
  //   }

  //   loadCompanyDetails();
  // }, []);

  useEffect(() => {
    const loadCompanyDetails = async () => {
      setIsloading(true);

      const companyId = company?.id;
      if (!companyId) {
        setIsloading(false);
        return;
      }

      try {
        const [
          checklistResponse,
          serviceNamesResponse,
          servicePartnersResponse,
          companyUsersResponse,
        ] = await Promise.all([
          checkListsApi.getChecklists({ companyId }),
          serviceNamesApi.getServiceNames({ companyId }),
          companiesApi.getCompanyPartnerships(companyId, {}),
          usersApi.getUsers({ companyId }),
        ]);

        setAvailableChecklists(checklistResponse?.data || []);
        setAvailableServiceNames(serviceNamesResponse?.data || []);
        setAvailableServicers(servicePartnersResponse?.data || []);
        setAvailableSubAssigneeUsers(companyUsersResponse?.data || []);

        setSelectedFromResponse(
          checklistResponse,
          "checklistId",
          setSelectedChecklist,
          "name"
        );
        setSelectedFromResponse(
          serviceNamesResponse,
          "serviceNameId",
          setServiceName,
          "name"
        );
        setSelectedFromResponse(
          servicePartnersResponse,
          "assignedCompanyId",
          setSelectedServicer,
          "name"
        );
        setSelectedFromResponse(
          companyUsersResponse,
          "assignedUserSubId",
          setSelectedSubAssignee,
          "email"
        );
      } catch (error) {
        console.error(error);
      } finally {
        setIsloading(false);
      }
    };

    const setSelectedFromResponse = (
      response,
      bookingInfoKey,
      setState,
      labelKey
    ) => {
      const matchingItem = response?.data?.find(
        (item) => item.id === bookingInfo?.[bookingInfoKey]
      );

      if (matchingItem) {
        setState({
          label: matchingItem[labelKey],
          value: matchingItem.id,
        });
      }
    };

    updateViewMode();
    updatePropertyInfo();

    loadCompanyDetails();
  }, []); // Assuming all dependencies like company, bookingInfo are stable

  const updateViewMode = () => {
    if (bookingInfo?.id) {
      setViewMode(true);
      console.log("bookingInfo", bookingInfo);
    } else {
      setViewMode(false);
    }
  };

  const updatePropertyInfo = () => {
    if (propertyInfo || bookingInfo?.propertyId) {
      console.log(bookingInfo);
      setProperty(bookingInfo?.propertyInfo);
      setPropertySelectionEnabled(false);
      setSaveDisabled(false);
    } else {
      setPropertySelectionEnabled(true);
      setSaveDisabled(true);
      console.log("-----", propertyInfo);
    }
  };

  useEffect(() => {
    if (propertyInfo?.id) {
      setProperty(propertyInfo);
    } else {
      console.log("propertyInfo-->", property);
      if (property?.value) {
        setProperty({
          id: property?.value,
          name: property?.label,
        });
      }
    }

    if (property?.id || property?.value) {
      setSaveDisabled(false);
    }
  }, [property]);

  const bookingsApi = BookingsApi();
  const [namedServicer, setNamedServicer] = useState(
    bookingInfo?.assigneeName || ""
  );

  const [manualAssign, setManualAssign] = useState(
    () => !!bookingInfo?.assigneeName
  );

  useEffect(() => {
    if (selectedServicer?.value) {
      if (company?.id == selectedServicer?.value) {
        setShowSubAssignee(true);
      } else {
        setShowSubAssignee(false);
      }
    }
    if (manualAssign) {
      setShowSubAssignee(false);
      setSelectedSubAssignee(null);
    }
  }, [
    selectedServicer,
    selectedSubAssignee,
    availableSubAssigneeUsers,
    bookingInfo?.assigneeSubId,
    manualAssign,
  ]);
  const handleSubmitClicked = async () => {
    console.log("Date---", convertToDateFormat(startDate));
    // Initialize bookingPayload as an empty object
    let bookingPayload = {};

    // Populate timeObject fields that are common to both scenarios (create and update)
    const timeObject = {
      startTime: startTime,
      date: convertToDateFormat(startDate),
      timeZone: property?.timeZone || "America/New_York",
      estimatedDuration: {
        value: estimatedDurationValue,
        interval: estimatedDurationInterval?.value,
      },
    };
    console.log("TimeObject", timeObject);
    bookingPayload.timeObject = timeObject;

    // If there is an existing booking (update scenario)
    if (bookingInfo?.id) {
      // Populate bookingPayload with existing booking data
      console.log("Booking before", bookingInfo);
      //delete propertyInfo from bookingInfo;
      delete bookingInfo.propertyInfo;
      delete bookingInfo?.assigneeInfo;
      delete bookingInfo.isOwner;
      delete bookingInfo.title;
      delete bookingInfo.lastUpdated;
      bookingPayload = { ...bookingInfo };
      bookingPayload.timeObject = timeObject;

      bookingPayload.serviceScheduleDate = convertToDateFormat(startDate);
      // Update fields conditionally, only if they have changed
      if (bookingInfo?.checklistId !== selectedChecklist?.value) {
        bookingPayload.checklistId = selectedChecklist?.value;
      }
      if (bookingInfo?.serviceNameId !== serviceName?.value) {
        bookingPayload.serviceNameId = serviceName?.value;
      }
      // if (
      //   bookingInfo?.assigneeId !==
      //   (manualAssign ? namedServicer : selectedServicer?.value)
      // ) {
      //   bookingPayload.assigneeId = manualAssign
      //     ? namedServicer?.value
      //     : selectedServicer?.value;
      // }

      if (manualAssign) {
        bookingPayload.assigneeId = null;
        bookingPayload.assigneeName = namedServicer;
      } else {
        bookingPayload.assigneeId = selectedServicer?.value;
        bookingPayload.assigneeName = null;
      }

      if (company?.id == selectedServicer?.value) {
        if (selectedSubAssignee?.value) {
          bookingPayload.assigneeSubId = selectedSubAssignee?.value;
        } else {
          console.log("No sub assignee selected");
        }
      } else {
        console.log("No bueno");
      }

      bookingPayload.bookingNotes = bookingNotes;
      if (startTime) {
        bookingPayload.timeObject.startTime = startTime;
      }

      if (bookingInfo?.recurrenceId || recurrenceData) {
        bookingPayload.recurrenceData = recurrenceData;
        if (updateSeries) {
          bookingPayload.recurrenceData.updateSeries = updateSeries;
        }
        console.log("recurrenceData", bookingPayload);
      } else {
        console.log("No recurrence data");
      }

      // if (startDate) {
      //   bookingPayload.timeObject.date = convertToDateFormat(startDate);
      //   bookingPayload.serviceScheduleDate = convertToDateFormat(startDate);
      // }
      // Add more conditional updates for other fields as needed
    }
    // If this is a new booking (create scenario)
    else {
      // Populate fields that are specific to creating a new booking
      bookingPayload.propertyId = property?.id;
      bookingPayload.companyId = company?.id;
      bookingPayload.serviceNameId = serviceName?.value;
      bookingPayload.serviceScheduleDate = convertToDateFormat(startDate);

      // Conditionally set checklistId, assignee, and notes
      if (selectedChecklist?.value) {
        bookingPayload.checklistId = selectedChecklist?.value;
      }
      if (manualAssign) {
        bookingPayload.assigneeName = namedServicer;
        bookingPayload.assigneeId = null;
      } else {
        bookingPayload.assigneeId = selectedServicer?.value;
        bookingPayload.assigneeName = null;

        if (company?.id == selectedServicer?.value) {
          if (selectedSubAssignee?.value) {
            bookingPayload.assigneeSubId = selectedSubAssignee?.value;
          } else {
            console.log("No sub assignee selected");
          }
        } else {
          console.log("No bueno");
        }
      }
      if (bookingNotes) {
        bookingPayload.bookingNotes = bookingNotes;
      }
      if (recurrenceData) {
        bookingPayload.recurrenceData = recurrenceData;
      }
    }
    setLoadingText("Saving service assignment...");
    setSavingAssignment(true);
    // Make the API call to either update or create a booking
    try {
      let response;
      if (bookingInfo?.id) {
        console.log("update payload", bookingPayload);
        console.log("companyid", company?.id);

        response = await bookingsApi.updateBooking(
          bookingInfo?.id,
          bookingPayload
        );
        console.log("update response", response);
      } else {
        response = await bookingsApi.createBooking(bookingPayload);
        console.log("create response", response);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (setRefetchBookings) {
        setRefetchBookings();
      } else if (setExternalFetch) {
        setExternalFetch();
      } else {
        console.log("No refetch function provided");
      }

      onClose();
    }
    // onClose();
  };

  const [estimatedDurationInterval, setEstimatedDurationInterval] = useState(
    bookingInfo?.timeObject?.estimatedDuration?.interval || {
      value: "hours",
      label: t("time.selection.hours"),
    }
  );
  const [estimatedDurationValue, setEstimatedDurationValue] = useState(
    bookingInfo?.timeObject?.estimatedDuration?.value || 1
  );

  const handleDateChange = (date) => {
    setStartDate(dayjs(date).format("YYYY-MM-DD"));
  };

  const handleTimeChange = (time) => {
    let timeString = dayjs(time).format("HH:mm");
    setStartTime(timeString);
  };

  const intervalOptions = [
    { value: "minutes", label: t("time.selection.minutes") },
    { value: "hours", label: t("time.selection.hours") },
    { value: "days", label: t("time.selection.days") },
  ];

  const [loadingText, setLoadingText] = useState("Loading...");

  const handleCancelClicked = async () => {
    setLoadingText("Cancelling service assignment...");
    setSavingAssignment(true);
    try {
      await bookingsApi.deleteBooking(bookingInfo?.id);
    } catch (error) {
      console.error(error);
    } finally {
      if (setRefetchBookings) {
        setRefetchBookings();
      } else if (setExternalFetch) {
        setExternalFetch();
      } else {
        console.log("No refetch function provided");
      }
      onClose();
    }
  };

  const [confirmSeriesUpdateServiceName, setConfirmSeriesUpdateServiceName] =
    useState(false);
  const [confirmSeriesUpdateChecklist, setConfirmSeriesUpdateChecklist] =
    useState(false);

  const [confirmSeriesUpdateAssignee, setConfirmSeriesUpdateAssignee] =
    useState(false);

  useEffect(() => {
    if (bookingInfo?.recurrenceId) {
      if (bookingInfo?.serviceNameId !== serviceName?.value) {
        setConfirmSeriesUpdateServiceName(true);
        console.log("Service name updated");
      } else {
        setConfirmSeriesUpdateServiceName(false);
        console.log(bookingInfo?.serviceNameId, serviceName?.value);
      }

      if (bookingInfo?.checklistId !== selectedChecklist?.value) {
        setConfirmSeriesUpdateChecklist(true);
        console.log("Checklist updated");
      } else {
        setConfirmSeriesUpdateChecklist(false);
        console.log(bookingInfo?.checklistId, selectedChecklist?.value);
      }

      if (bookingInfo?.assigneeId !== selectedServicer?.value) {
        setConfirmSeriesUpdateAssignee(true);
        console.log("Assignee updated");
      } else {
        setConfirmSeriesUpdateAssignee(false);
        console.log(bookingInfo?.assigneeId, selectedServicer?.value);
      }
    }

    //assigneeId = selectedServicer?.value;
  }, [serviceName, selectedChecklist?.value, selectedServicer?.value]);

  const handleStartAppointment = async () => {
    setLoadingText("Updating appointment status");
    setSavingAssignment(true);

    console.log("Appointment in progress", bookingInfo?.id);
    const status = "inProgress";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        bookingInfo?.id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      // Remove the confirmed booking from the confirmationBookings list
      // setConfirmationBookings((prevBookings) =>
      //   prevBookings.filter((booking) => booking.id !== id)
      // );
      if (setRefetchBookings) {
        setRefetchBookings();
      } else if (setExternalFetch) {
        setExternalFetch();
      } else {
        console.log("No refetch function provided");
      }
    } catch (error) {
      console.error("Error updating booking status", error);
    }
    onClose();
  };

  const handleConfirmBooking = async () => {
    setLoadingText("Confirming service assignment...");
    setSavingAssignment(true);

    console.log("Booking confirmed", bookingInfo?.id);
    const status = "confirmed";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        bookingInfo?.id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      // Remove the confirmed booking from the confirmationBookings list
      // setConfirmationBookings((prevBookings) =>
      //   prevBookings.filter((booking) => booking.id !== id)
      // );
      if (setRefetchBookings) {
        setRefetchBookings();
      } else if (setExternalFetch) {
        setExternalFetch();
      } else {
        console.log("No refetch function provided");
      }
    } catch (error) {
      console.error("Error updating booking status", error);
    }
    onClose();
  };

  return (
    <>
      <div style={{ height: "100%", overflow: "auto" }}>
        <ArgonBox>
          <HostHiveDialog
            open={serviceReportOpen}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="md"
            // onClose={handleCancelClicked}
            // onSave={handleSubmitClicked}
            title="Booking Report"
            fullScreen={false}
            // cancelButtonText="Cancel"
            // submitButtonText="Save"
            showStatusBadge={true}
            statusColor="success"
            statusContent="Confirmed"
            dialogObjects={
              <BookingReportDataCard
                propertyId={property?.id}
                companyId={company?.id}
                bookingReportId={bookingInfo?.id}
                onClose={() => setServiceReportOpen(false)}
              />
            }
          />
          <HostHiveDialog
            open={recurrenceConfigurationOpen}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="sm"
            // onClose={handleCancelClicked}
            // onSave={handleSubmitClicked}
            title="Recurrence Configuration"
            fullScreen={false}
            // cancelButtonText="Cancel"
            // submitButtonText="Save"
            showStatusBadge={true}
            statusColor="success"
            statusContent="Confirmed"
            dialogObjects={
              <RecurrenceDialog
                onClose={() => setRecurrenceConfigurationOpen(false)}
                setRecurrenceData={setRecurrenceData}
                recurrenceData={recurrenceData}
              />
            }
          />
          <HostHiveDialog
            open={recurrenceSeriesDialogOpen}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="sm"
            title="Recurrence Series Configuration"
            fullScreen={false}
            showStatusBadge={false}
            statusColor="success"
            statusContent="Confirmed"
            dialogObjects={
              <RecurrenceSeriesDialog
                onClose={() => setRecurrenceSeriesDialogOpen(false)}
                setUpdateSeries={setUpdateSeries}
                handleSubmitClicked={handleSubmitClicked}
                allAppointmentsText="All New & Existing Appointments"
                onlyNewAppointmentsText="Only New Appointments"
                submiteButtonText="Apply"
                allAppointmentsValue="existingAndNew"
                onlyNewValue="onlyNew"
              />
            }
          />
          <HostHiveDialog
            open={declineAppointmentOpen}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="md"
            title="Decline Service Appointment"
            fullScreen={false}
            cancelButtonText="Cancel"
            submitButtonText="Save"
            showStatusBadge={false}
            statusColor="success"
            statusContent={bookingInfo?.status}
            dialogObjects={
              <DeclineMessageDataCard
                confirmBookingsFetch={confirmBookingsFetch}
                setConfirmBookingsFetch={setConfirmBookingsFetch}
                eventDataCardClose={onClose}
                bookingInfo={bookingInfo}
                onClose={() => {
                  setDeclineAppointmentOpen(false);
                }}
                setExternalFetch={
                  setExternalFetch
                    ? setExternalFetch
                    : setRefetchBookings
                    ? setRefetchBookings
                    : null
                }
              />
            }
          />
          <HostHiveDialog
            open={requestChangesDialogOpen}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="sm"
            // onClose={handleCancelClicked}
            // onSave={handleSubmitClicked}
            title="Change Request"
            fullScreen={false}
            // cancelButtonText="Cancel"
            // submitButtonText="Save"
            showStatusBadge={false}
            // statusColor="success"
            // statusContent="Confirmed"
            dialogObjects={
              <ServiceAppointmentChangeRequest
                bookingInfo={bookingInfo}
                onClose={() => setRequestChangesDialogOpen(false)}
              />
            }
          />
        </ArgonBox>

        <ArgonBox>
          {viewMode ? (
            <>
              <ViewEventDataCard
                onClose={onClose}
                bookingInfo={bookingInfo}
                isAppointmentOwner={isAppointmentOwner}
                savingAssignment={savingAssignment}
                handleCancelClicked={handleCancelClicked}
                setViewMode={setViewMode}
                setDeclineAppointmentOpen={setDeclineAppointmentOpen}
                handleConfirmBooking={handleConfirmBooking}
                setRequestChangesDialogOpen={setRequestChangesDialogOpen}
                manualAssign={manualAssign}
                namedServicer={namedServicer}
                handleStartAppointment={handleStartAppointment}
                loadingText={loadingText}
                readOnly={readOnly}
                selectedSubAssignee={selectedSubAssignee}
                company={company}
                disableViewPropertyDetails={disableViewPropertyDetails}
              />
            </>
          ) : (
            <Card>
              {!savingAssignment ? (
                <>
                  {!bookingInfo?.id ? (
                    <></>
                  ) : (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%"
                        >
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            mt={3}
                            lineHeight={0}
                            display="inline-block"
                          ></ArgonBox>
                          <ArgonButton
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleCancelClicked}
                          >
                            {t("buttons.cancel")}{" "}
                            {t("serviceAssignments.appointmentSingularLower")}
                          </ArgonButton>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%"
                        >
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            mt={3}
                            lineHeight={0}
                            display="inline-block"
                          ></ArgonBox>
                          <ArgonButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => setViewMode(true)}
                          >
                            {t("buttons.cancel")} {t("generalWords.edit")}
                          </ArgonButton>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  )}

                  <ArgonBox>
                    {propertySelectionEnabled ? (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <PropertySelect
                            menuPlacement="bottom"
                            property={property}
                            setProperty={setProperty}
                            companyId={company?.id}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <ArgonBox pr={1}>
                            <ArgonBox
                              ml={0.5}
                              lineHeight={0}
                              display="inline-block"
                            >
                              <ArgonTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t("serviceAssignments.eventDataCard.property")}
                                :
                              </ArgonTypography>

                              <ArgonTypography
                                component="label"
                                variant="caption"
                                textTransform="capitalize"
                              >
                                {" "}
                                {bookingInfo?.propertyName}
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    )}
                  </ArgonBox>
                  {saveDisabled ? null : (
                    <EditEventDataCard
                      handleDateChange={handleDateChange}
                      startDate={startDate}
                      handleTimeChange={handleTimeChange}
                      startTime={startTime}
                      recurrenceAllowed={recurrenceAllowed}
                      bookingInfo={bookingInfo}
                      //setBookingInfo={setBookingInfo}
                      setRecurrenceConfigurationOpen={
                        setRecurrenceConfigurationOpen
                      }
                      repeatEvent={repeatEvent}
                      setRepeatEvent={setRepeatEvent}
                      estimatedDurationValue={estimatedDurationValue}
                      setEstimatedDurationValue={setEstimatedDurationValue}
                      estimatedDurationInterval={estimatedDurationInterval}
                      setEstimatedDurationInterval={
                        setEstimatedDurationInterval
                      }
                      availableServiceNames={availableServiceNames}
                      setAvailableServiceNames={setAvailableServiceNames}
                      availableChecklists={availableChecklists}
                      setAvailableChecklists={setAvailableChecklists}
                      selectedChecklist={selectedChecklist}
                      setSelectedChecklist={setSelectedChecklist}
                      availableServicers={availableServicers}
                      selectedServicer={selectedServicer}
                      setSelectedServicer={setSelectedServicer}
                      availableSubAssigneeUsers={availableSubAssigneeUsers}
                      selectedSubAssignee={selectedSubAssignee}
                      setSelectedSubAssignee={setSelectedSubAssignee}
                      showSubAssignee={showSubAssignee}
                      setManualAssign={setManualAssign}
                      manualAssign={manualAssign}
                      namedServicer={namedServicer}
                      setNamedServicer={setNamedServicer}
                      bookingNotes={bookingNotes}
                      setBookingNotes={setBookingNotes}
                      isLoading={isLoading}
                      category={category}
                      setCategory={setCategory}
                      resetTheme={resetTheme}
                      intervalOptions={intervalOptions}
                      serviceName={serviceName}
                      setServiceName={setServiceName}
                    />
                  )}

                  <Grid container spacing={3} pb={3}>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        ></ArgonBox>
                        <ArgonButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={onClose}
                        >
                          {t("buttons.close")}
                        </ArgonButton>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        ></ArgonBox>
                        <ArgonButton
                          variant="outlined"
                          color="info"
                          size="small"
                          onClick={() => {
                            if (
                              (confirmSeriesUpdateServiceName === true ||
                                confirmSeriesUpdateChecklist === true ||
                                confirmSeriesUpdateAssignee === true) &&
                              bookingInfo?.recurrenceId
                            ) {
                              setRecurrenceSeriesDialogOpen(true);
                            } else {
                              handleSubmitClicked();
                            }
                          }}
                          disabled={saveDisabled}
                        >
                          {t("buttons.save")}
                        </ArgonButton>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <SavingDataCard loadingText={loadingText} />
              )}
            </Card>
          )}
        </ArgonBox>
      </div>
    </>
  );
}

export default EventDataCard;
