/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import UsersApi from "api/usersApi";
import { set } from "lodash";
// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg";

function Basic() {
  const navigate = useNavigate();
  const usersApi = UsersApi();
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const [otp, setOtp] = useState("");
  const inputRef = useRef([]);
  const [userEmail, setUserEmail] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [codeResent, setCodeResent] = useState(false);
  useEffect(() => {
    if (validationError && otp.length > 0) {
      setValidationError(false);
    }
    if (codeResent === true) {
      setValidationError(false);
      // 5 second timer to reset codeResent
      setTimeout(() => {
        setCodeResent(false);
      }, 5000);
    }
  }, [otp, codeResent]);
  const handleChangeDigit = (event) => {
    const value = event.target.value;
    // Allow only numbers and limit length to 6
    if (value === "" || (/^[0-9]+$/.test(value) && value.length <= 6)) {
      setOtp(value);
    }
  };
  const onSubmit = async (values, actions) => {
    try {
      const response = await usersApi.generateOTP(values.email);
      setUserEmail(values.email);
      console.log("response", response);
    } catch (error) {
      console.error(error);
    }
    setResetPasswordInProgress(true);
    actions.resetForm();
  };

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required"),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      // Add more fields here
    },
    validationSchema: EmailSchema,
    onSubmit,
  });
  const verifyOTP = async () => {
    const otpString = Object.values(otp).join("");
    console.log("Verifying OTP:", otpString);

    try {
      const response = await usersApi.verifyOTP(userEmail, otpString);
      setOtp("");
      if (response.status === 200) {
        const resetCode = response.data.resetCode;
        // Navigate to /authentication/new-password/:oobCode
        navigate(`/authentication/new-password/${resetCode}`);
        // navigate("/auth/reset-password/new-password");
      }
    } catch (error) {
      setValidationError(true);
      setOtp("");
      console.error(error);
    }
    // Add your verification logic here
  };

  const generateNewOTP = async () => {
    try {
      await usersApi.generateOTP(userEmail);
      setCodeResent(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BasicLayout
      image={bgImage}
      button={{ variant: "gradient", color: "dark" }}
    >
      <Card>
        <ArgonBox pt={3} px={3} pb={1} textAlign="center">
          <ArgonTypography variant="h4" fontWeight="bold" textGradient>
            Password Reset
          </ArgonTypography>
        </ArgonBox>
        {resetPasswordInProgress ? (
          <>
            <ArgonBox pt={3} px={3} pb={3} textAlign="center">
              <ArgonTypography variant="body2" color="text">
                Please check your inbox for a password reset email.
              </ArgonTypography>
            </ArgonBox>
            <Card>
              <ArgonBox textAlign="center" p={6}>
                <ArgonBox mb={3} px={1}>
                  <ArgonTypography variant="h2" fontWeight="bold">
                    2-Step Verification
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mb={2}>
                  <Grid container spacing={2}>
                    <ArgonInput
                      size="large"
                      label="Enter OTP"
                      value={otp}
                      // onChange={handleChangeDigit}
                      inputProps={{ maxLength: 6 }}
                      // inputProps={{ maxLength: 1 }}
                      // value={otp["digit" + (index + 1)]}
                      onChange={(e) => handleChangeDigit(e)}
                      // ref={(el) => (inputRef.current[index] = el)}
                    />
                  </Grid>
                </ArgonBox>
                {validationError && (
                  <ArgonTypography
                    variant="button"
                    color="error"
                    fontWeight="regular"
                  >
                    Incorrect verification code.
                  </ArgonTypography>
                )}
                {codeResent && (
                  <ArgonTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    New code has been sent to {userEmail}.
                  </ArgonTypography>
                )}
                <ArgonBox mb={2} mt={2}>
                  <ArgonButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={verifyOTP}
                  >
                    Verify
                  </ArgonButton>
                </ArgonBox>
                <ArgonTypography
                  component="a"
                  href="#verification"
                  variant="button"
                  sx={{
                    ":hover": {
                      textDecoration: "underline",
                      color: "primary.main",
                    },
                    cursor: "pointer",
                  }}
                  onClick={generateNewOTP}
                >
                  Resend a new code to {userEmail}
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <ArgonBox p={3}>
              <ArgonBox>
                <ArgonBox mb={2}>
                  <ArgonInput
                    placeholder="bee@hosthive.io"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // Add error handling from Formik state
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  {errors.email && touched.email && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.email} />
                  )}
                </ArgonBox>
                <ArgonBox mt={5} mb={1}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    size="large"
                    fullWidth
                    type="submit"
                  >
                    Send
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </form>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
