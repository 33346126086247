/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/pricing-page/components/Header";
import Footer from "layouts/pages/pricing-page/components/Footer";
import PricingCards from "layouts/pages/pricing-page/components/PricingCards";
import TrustedBrands from "layouts/pages/pricing-page/components/TrustedBrands";
import Faq from "layouts/pages/pricing-page/components/Faq";
import SubscriptionsApi from "api/subscriptionsApi";
import CompaniesApi from "api/companyApi";

function PricingPage() {
  const companiesApi = CompaniesApi();
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["0", "6"]);
  const subscriptionsApi = SubscriptionsApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [error, setError] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  useEffect(() => {
    const loadData = async () => {
      try {
        const companiesResponse = await companiesApi.getCompanySubscription(
          company?.id
        );

        if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
          // setCompanySubscription(companiesResponse.data);
          // setSubscriptionStatus(companiesResponse.data.subscriptionStatus);
          // console.log("setCompanySubscription.data", companiesResponse.data);
          setSubscriptionTier(companiesResponse.data.subscriptionTier);
          setSubscriptionStatus(companiesResponse.data.subscriptionStatus);
        }
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };
    loadData();
  }, []);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    setPrices(["0", "12"]);
  };

  return (
    <PageLayout>
      <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
      <Container>
        <PricingCards prices={prices} currentPlan={subscriptionTier} />
        {/* <TrustedBrands /> */}
        {/* <Faq /> */}
      </Container>
      <ArgonBox mt={8}>{/* <Footer /> */}</ArgonBox>
    </PageLayout>
  );
}

export default PricingPage;
