/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ServiceNameItem from "./components/ServiceNameItem";

import ServiceNameItemDialog from "./components/ServiceNameItemDialog";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import ServiceNameApi from "api/service-nameApi";
import { useParams } from "react-router-dom";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveAlert from "components/HostHiveAlert";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";
function ServiceNames({}) {
  // const { companyId } = useParams();
  const serviceNamesApi = new ServiceNameApi();
  const [checklistItem, setChecklistItem] = useState({});
  const [serviceNameDialogOpen, setServiceNameDialogOpen] = useState(false);
  const [serviceNamesLoading, setServiceNamesLoading] = useState(false);
  const [serviceNames, setServiceNames] = useState([]);
  const [createdServiceName, setCreatedServiceName] = useState(false);
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const { alertContent, renderAlert } = HostHiveAlert();
  const [showAlert, setShowAlert] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    async function fetchServiceNames() {
      setServiceNamesLoading(true);
      // const companyIdToUse = companyId ? companyId : company.id;
      try {
        const queryParams = {
          limit: 10,
          companyId: company?.id,
        };

        const response = await serviceNamesApi.getServiceNames(queryParams);
        setServiceNames(response.data);

        setServiceNamesLoading(false);
      } catch (error) {
        alertContent(error.message, "https://google.com");
        setShowAlert(true);
        console.error(error);
        setServiceNamesLoading(false);
      }
    }

    fetchServiceNames();
  }, [createdServiceName]);

  return (
    <Card
      id="service-names"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      {renderSuccessSB}
      {showAlert && renderAlert}

      <HostHiveDialog
        includeDelete={false}
        includeClose={false}
        includeSave={false}
        open={serviceNameDialogOpen}
        onClose={() => {
          setServiceNameDialogOpen(false);
        }}
        title="Create a new service name"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <ServiceNameItemDialog
            companyId={company?.id}
            fetchServiceNames={() => setCreatedServiceName(!createdServiceName)}
            onClose={() => {
              setServiceNameDialogOpen(false);
            }}
            snackBarOpen={openSuccessSB}
          />
        }
      />
      <ArgonBox p={3}>
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">Service Names</ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => setServiceNameDialogOpen(true)}
            >
              {" "}
              + Add Service Name
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        {serviceNamesLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            p={2}
            mt={1}
            width="100%"
            //height="26.25rem"
            height="auto"
            style={{ overflow: "auto" }}
          >
            {serviceNames?.map((serviceName) => (
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                key={serviceName?.id}
              >
                <ServiceNameItem
                  serviceName={serviceName}
                  fetchServiceNames={() =>
                    setCreatedServiceName(!createdServiceName)
                  }
                  openSuccessSB={openSuccessSB}
                />
              </ArgonBox>
            ))}
          </ArgonBox>
        )}
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}></ArgonBox>
    </Card>
  );
}

export default ServiceNames;
