import React from "react";

import ArgonTypography from "components/ArgonTypography";
import { useTranslation } from "react-i18next";
function PricingNotice({ button, image, children }) {
  const { t } = useTranslation("common");
  return (
    <div>
      <ArgonTypography variant="h4" gutterBottom>
        {t("properties.priceIncreaseNotice.header")}
      </ArgonTypography>

      <ArgonTypography variant="body2" paragraph>
        {t("properties.priceIncreaseNotice.subHeader")}
      </ArgonTypography>

      <ArgonTypography variant="body2" paragraph>
        - {t("properties.priceIncreaseNotice.firstPropertyBilled")} $6{" "}
        {t("generalWords.currency")} {t("generalWords.each")}.
      </ArgonTypography>

      <ArgonTypography variant="body2" paragraph>
        {t("properties.priceIncreaseNotice.subStatement")}
      </ArgonTypography>

      {/* <ArgonTypography variant="body2" paragraph>
        {t("properties.priceIncreaseNotice.agreeStatement")}
      </ArgonTypography> */}

      {/* Insert your Agree and Cancel buttons here */}
    </div>
  );
}

export default PricingNotice;
