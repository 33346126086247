/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import appStoreBadge from "assets/appStoreBadge.png";
import googlePlayBadge from "assets/googlePlayBadge.png";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Argon Dashboard 2 PRO MUI page layout routes
import pageRoutes from "page.routes";
import ContactSupportApi from "api/contactSupportApi";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { useState, useEffect } from "react";
// Images
import bgImage from "assets/images/pricing-header-bg.jpg";
import ArgonInput from "components/ArgonInput";
import { m } from "framer-motion";
import ArgonButton from "components/ArgonButton";
import { set } from "lodash";
import link from "assets/theme/components/link";

function Header({ tabValue, tabHandler }) {
  const [controller] = useArgonController();

  const storeImages = [
    {
      id: 1,
      image: appStoreBadge,
      link: "https://apps.apple.com/us/app/hosthive/id6495964980",
    },
    {
      id: 2,
      image: googlePlayBadge,
      link: "https://play.google.com/store/apps/details?id=com.v1.hostHive&hl=en",
    },
  ];
  return (
    <>
      <ArgonBox
        position="relative"
        // height="50vh"
        overflow="hidden"
        pt={12}
        pb={20}
        sx={({
          palette: { gradients },
          functions: { linearGradient, rgba },
        }) => ({
          backgroundImage: `${linearGradient(
            rgba(gradients.info.main, 0.5),
            rgba(gradients.info.state, 0.5)
          )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          width: "100%",
        })}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Grid item xs={10} lg={5}>
            <ArgonBox mt={3} mb={1}>
              <ArgonTypography variant="h3" color="white" fontWeight="bold">
                Get the HostHive App
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={2}>
              <ArgonTypography
                variant="body2"
                color="white"
                fontWeight="regular"
              >
                Available on both iOS and Android
              </ArgonTypography>
            </ArgonBox>
            <Grid
              container
              item
              // xs={12}
              // sm={10}
              // md={8}
              // lg={7}
              // sx={{ mx: "auto" }}
            >
              {storeImages.map((store) => (
                <Grid item xs={12} md={6} key={store.id}>
                  <ArgonBox>
                    <img
                      src={store.image}
                      alt="store"
                      onClick={() => window.open(store.link, "_blank")}
                      style={{ cursor: "pointer" }}
                    />
                  </ArgonBox>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ArgonBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
