import { useState, useEffect } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// CreateEventWizard page components
import DateSelection from "layouts/management/calendar/components/CreateEventWizard/components/DateSelection";
import PropertySelection from "layouts/management/calendar/components/CreateEventWizard/components/PropertySelection";
import ServiceNameSelection from "layouts/management/calendar/components/CreateEventWizard/components/ServiceNameSelection";
import SelectServicePartner from "layouts/management/calendar/components/CreateEventWizard/components/ServicePartnerSelection";
import EventOptions from "layouts/management/calendar/components/CreateEventWizard/components/EventOptions";
import { UserAuth } from "context/AuthContext";

import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
// CreateEventWizard layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import { t } from "i18next";

function getSteps() {
  return [
    "Date & Time",
    "Property Selection",
    "Service Name",
    "Service Partner",
    "Options",
    "Review",
  ];
}

function getStepContent(
  stepIndex,
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  userSelections,
  setUserSelections,
  responseData,
  setResponseData,
  property,
  serviceGapDate,
  subscriptionTier,
  setActiveStep
) {
  console.log("Step Index", stepIndex);
  const companyHasPlusSubscription = () => {
    if (subscriptionTier === "free" || subscriptionTier === null) {
      return false;
    } else {
      return true;
    }
  };
  // if stepIndex is 4 but hasPaidSubscription, skip to stepIndex 5
  if (stepIndex === 4 && !companyHasPlusSubscription()) {
    stepIndex = 5;
    setActiveStep(5);
  }

  switch (stepIndex) {
    case 0:
      return (
        <DateSelection
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isOwner={true}
          serviceGapDate={serviceGapDate}
        />
      );
    case 1:
      return (
        <PropertySelection
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isOwner={true}
          propertyInfo={property}
        />
      );
    case 2:
      return (
        <ServiceNameSelection
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isOwner={true}
        />
      );
    case 3:
      return (
        <SelectServicePartner
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isOwner={true}
        />
      );
    case 4:
      return (
        <EventOptions
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isOwner={true}
        />
      );
    case 5:
      return (
        <ViewEventDataCard
          setNextDisabled={setNextDisabled}
          bookingPayload={bookingPayload}
          setBookingPayload={setBookingPayload}
          setUserSelections={setUserSelections}
          userSelections={userSelections}
          responseData={responseData}
          setResponseData={setResponseData}
          isInCreationMode={true}
          subscriptionTier={subscriptionTier}
        />
      );
    default:
      return null;
  }
}

function CreateEventWizard({ closeWizard, propertyId, serviceGapDate }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [property, setProperty] = useState(null);

  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();

  const propertiesApi = new PropertiesApi();
  const bookingsApi = new BookingsApi();
  useEffect(() => {
    async function fetchProperty() {
      const queryParams = {
        companyId: company?.id,
        propertyId: propertyId,
      };
      const response = await propertiesApi.getProperty(propertyId);
      setProperty(response.data);
    }
    if (propertyId) {
      fetchProperty();
    }
  }, []);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [propertyAppMatch, setPropertyAppMatch] = useState({});
  const [nextDisabled, setNextDisabled] = useState(true);
  // const [bulkChangePayloadMapping, setBulkChangePayloadMapping] = useState({}); // eslint-disable-line
  const [activeStep, setActiveStep] = useState(0);
  const [bookingPayload, setBookingPayload] = useState({
    companyId: company?.id,
    isOwner: true,
  });
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [userSelections, setUserSelections] = useState({});
  const [responseData, setResponseData] = useState({});
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  const handleSave = async () => {
    // delete isOwner from bookingPayload
    delete bookingPayload.isOwner;
    if (!bookingPayload.checklistId) {
      delete bookingPayload.checklistId;
    }

    bookingPayload.assigneeId = bookingPayload.assignedCompanyId;
    delete bookingPayload.assignedCompanyId;

    if (bookingPayload.assignedCompanySubId) {
      bookingPayload.assigneeSubId = bookingPayload.assignedCompanySubId;
      delete bookingPayload.assignedCompanySubId;
    }

    // if (bookingPayload.serviceScheduleTime) {
    //   bookingPayload.serviceScheduleTime = bookingPayload.serviceTime;
    //   // delete bookingPayload.serviceTime;
    // }
    try {
      const response = await bookingsApi.createBooking(bookingPayload);
      console.log("Booking Created", response);
    } catch (error) {
      console.log("Error Creating Booking", error);
    }
  };
  const submitForm = async () => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));
    // handleSave();
    // actions.setSubmitting(false);
    // actions.resetForm();

    setActiveStep(0);

    closeWizard();
  };

  const handleSubmit = (values, actions) => {
    console.log("Active Step", activeStep, "Steps Length", steps.length - 1);
    if (isLastStep) {
      submitForm();
    } else {
      console.log("Active Step", activeStep, "Steps Length", steps.length - 1);
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  useEffect(() => {
    console.log("Booking Payload", bookingPayload);
    // console.log("User Selections", userSelections);
  }, [bookingPayload]);
  const handleNextClicked = () => {
    console.log("Next Clicked", activeStep, steps.length - 1, isLastStep);
    if (isLastStep) {
      handleSave();
      closeWizard();
    } else {
      setActiveStep(activeStep + 1);
      setNextDisabled(true);
    }
  };
  return (
    <>
      <Card
        sx={{
          display: "grid",
          alignItems: "center",
          position: "relative",
          height: "6rem",
          borderRadius: "lg",
          mb: 3,
        }}
      >
        <Stepper activeStep={activeStep} sx={{ margin: 0 }} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form id={formId} autoComplete="off">
            <Card sx={{ height: "100%" }}>
              <ArgonBox p={2}>
                <ArgonBox>
                  {getStepContent(
                    activeStep,
                    setNextDisabled,
                    bookingPayload,
                    setBookingPayload,
                    userSelections,
                    setUserSelections,
                    responseData,
                    setResponseData,
                    property,
                    serviceGapDate,
                    subscriptionTier,
                    setActiveStep
                  )}
                  <ArgonBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <ArgonButton
                      variant="gradient"
                      color="light"
                      onClick={closeWizard}
                    >
                      Cancel
                    </ArgonButton>
                    {activeStep === 0 ? (
                      <ArgonBox />
                    ) : (
                      <ArgonButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        Back
                      </ArgonButton>
                    )}
                    {/* <ArgonButton
                      // disabled={nextDisabled}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                        console.log("Button Clicked", activeStep);
                        setNextDisabled(isLastStep ? false : true);
                        // isLastStep && handleSave();
                      }}
                    >
                      {isLastStep ? "Create" : "Next"}
                    </ArgonButton> */}
                    <ArgonButton
                      disabled={nextDisabled}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        // setActiveStep(activeStep + 1);
                        // setNextDisabled(true);
                        handleNextClicked();
                      }}
                    >
                      {isLastStep ? "Create" : "Next"}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default CreateEventWizard;
