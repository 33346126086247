/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "layouts/management/components/TableCell/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import { useNavigate } from "react-router-dom";
import PriorityCell from "layouts/administration/taskCenter/components/PriorityCell";
import ArgonBadge from "components/ArgonBadge";
import i18n from "i18next";
import ViewDataItem from "../ViewDataItem";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
export const generateColumns = ({ handleRefreshLists }) => {
  const columns = [
    // {
    //   Header: i18n.t("general.person.name", { ns: "common" }),
    //   accessor: "fullName",
    //   Cell: ({ value }) => {
    //     const [name, data] = value;
    //     const navigate = useNavigate();
    //     return (
    //       <DefaultCell
    //         value={typeof value === "string" ? value : name}
    //         sxValue={{
    //           ":hover": { textDecoration: "underline" },
    //           cursor: "pointer",
    //         }}
    //         onClick={() => console.log("clicked")}
    //       />
    //     );
    //   },
    // },
    {
      Header: i18n.t("general.person.name", { ns: "common" }),
      accessor: "fullName",
      Cell: ({ value, row }) => {
        const [name, data] = value;
        console.log("Row", row);
        return (
          <ViewDataItem
            value={typeof value === "string" ? value : name}
            itemDetails={row?.original}
            id={row?.original?.id}
            user={row?.original}
            handleRefreshLists={handleRefreshLists}
          />
        );
      },
    },
    {
      Header: i18n.t("general.emailAddress", { ns: "common" }),
      accessor: "email",
      Cell: ({ value }) => {
        let status;
        let statusText;

        status = (
          <DefaultCell
            value={value}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => console.log("clicked")}
          />
        );

        return status;
      },
    },
    {
      Header: i18n.t("general.person.roleName", { ns: "common" }),
      accessor: "applicationRole",
      Cell: ({ value }) => {
        let priority;
        let statusText;

        priority = (
          <ArgonBadge
            color="info"
            size="xs"
            badgeContent={i18n.t(`components.users.team.roles.${value}`, {
              ns: "common",
            })}
            container
          />
        );

        return priority;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        let status;
        let statusText;

        status = (
          <ArgonBadge
            variant="contained"
            color={value === "active" ? "success" : "error"}
            badgeContent={value}
            container
          />
        );

        return status;
      },
    },
    // {
    //   Header: "Action",
    //   accessor: "action",
    //   Cell: ({ value, row }) => {
    //     return (
    //       <ActionCell
    //         row={row}
    //         // onEdit={handleEditClick}
    //         // editModeRowId={editModeRowId}
    //         // setEditModeRowId={setEditModeRowId}
    //         // triggerTableUpdate={setUpdateTable}
    //       />
    //     );
    //   },
    // },
  ];
  return columns;
};

{
  /* <ArgonBadge
  variant="contained"
  color="success"
  badgeContent="ready"
  container
/>; */
}
