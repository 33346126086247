// App.js
import React from "react";

import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ArgonBox from "components/ArgonBox";

import borders from "assets/theme/base/borders";
import MessagesApi from "api/messagesApi";
import {
  Box,
  Card,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  CardContent,
  CardActions,
  Paper,
} from "@mui/material";
import ArgonInput from "components/ArgonInput";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonSelect from "components/ArgonSelect";
import MessageItem from "./components/MessageItem";
import AdministrationApi from "api/administrationApi";
import MultiSelection from "layouts/management/components/MultiSelect";
import { set } from "lodash";
import ArgonButton from "components/ArgonButton";
function CompanyMessages() {
  const { borderWidth, borderColor } = borders;
  const [permitted, setPermitted] = useState(false);
  const administrationApi = new AdministrationApi();
  const [messages, setMessages] = useState({
    messages: [],
    total: 0,
  });

  const attemptReachability = async () => {
    try {
      await administrationApi.getReachability();
      setPermitted(true);
      console.log("Permitted");
      getCompanies();
      fetchMessages();
    } catch (error) {
      console.error(error);
    }
  };
  const [companies, setCompanies] = useState([]);
  const [subject, setSubject] = useState("");
  const sortByOptions = [
    { value: "created", label: "Created" },
    { value: "subject", label: "Subject" },
  ];
  const sortOrderOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedSortBy, setSelectedSortBy] = useState(sortByOptions[0]);
  const [selectedSortOrder, setSelectedSortOrder] = useState(
    sortOrderOptions[1]
  );
  const [selectedCompany, setSelectedCompany] = useState();
  const getCompanies = async () => {
    try {
      const response = await administrationApi.getCompanies();
      setCompanies(response.data.companies);
      let companyOptions = response.data.companies.map((company) => ({
        value: company.id,
        label: company.name,
      }));

      companyOptions.unshift({ value: null, label: "All" });
      setCompanyOptions(companyOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMessages = async () => {
    console.log("fetching messages");
    if (subject.length === 0) {
      setSubject("");
    }

    // sortBy: str = None,
    // sortOrder: str = None,
    let queryParams = {
      subject: subject,
      sortBy: selectedSortBy.value,
      sortOrder: selectedSortOrder.value,
    };
    if (selectedCompany?.value) {
      console.log("selectedCompany", selectedCompany);
      queryParams = {
        ...queryParams,
        companyId: selectedCompany.value,
      };
    }
    const response = await administrationApi.getMessages(queryParams);
    console.log("response", response.data);

    setMessages({
      messages: response.data.messages,
      total: response.data.total,
    });
    // const queryParams = new URLSearchParams(filters).toString();
    // const response = await fetch(`/api/messages?${queryParams}`);
    // const data = await response.json();
    // setMessages(data.messages);
  };

  useEffect(() => {
    attemptReachability();
  }, [subject, selectedSortBy, selectedSortOrder, selectedCompany]);
  const [multiSelectionOpen, setMultiSelectionOpen] = useState(false);
  const handleSelectionSaved = () => {
    console.log("selectedIds", selectedIds);
    // setMultiSelectionOpen(false);
  };
  const [selectedIds, setSelectedIds] = useState([]);
  const [entities, setEntities] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);
  const [objectName, setObjectName] = useState("");

  const handleOpenMultiSelection = (objectName) => {
    // selectedIds, setSelectedIds, entities;
    if (objectName === "company") {
      setObjectName(objectName);
      setEntities(companyOptions);
      setSelectedIds(companyIds);
      setMultiSelectionOpen(true);
    }
  };

  const handleSelection = (entity, event, objectName) => {
    if (objectName === "company") {
      if (event.target.checked) {
        setCompanyIds([...companyIds, entity.value]);
      } else {
        setCompanyIds(companyIds.filter((id) => id !== entity.value));
      }
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* <DashboardNavbar /> */}
        <ArgonBox mt={1} mb={20}>
          <Grid container justifyContent="center">
            <Grid item xs={12} lg={8}>
              <Card sx={{ mb: 3, overflow: "visible", boxShadow: 3 }}>
                <ArgonBox mb={3}>
                  <ArgonBox
                    sx={{
                      padding: "1rem",
                    }}
                  >
                    <ArgonTypography
                      variant="h4"
                      fontWeight="bold"
                      color="text"
                    >
                      Messages
                    </ArgonTypography>
                  </ArgonBox>

                  <Grid container spacing={3}>
                    {/* Filter options */}
                    <Grid item xs={12} lg={6}>
                      <ArgonBox
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ArgonInput
                          placeholder="Search by subject"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                        >
                          Search
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ArgonBox
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ArgonSelect
                          options={companyOptions}
                          onChange={(e) => setSelectedCompany(e)}
                        />
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                        >
                          Company
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    {/* <Grid item xs={12} lg={4}>
                      <ArgonBox
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ArgonButton
                          onClick={() => handleOpenMultiSelection("company")}
                        >
                          Select Companies
                        </ArgonButton>
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                        >
                          Companies
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid> */}
                    <Grid item xs={12} lg={6}>
                      <ArgonBox
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ArgonSelect
                          options={sortOrderOptions}
                          onChange={(e) => setSelectedSortOrder(e)}
                        />
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                        >
                          Sort Order
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <ArgonBox
                        sx={{
                          padding: "1rem",
                        }}
                      >
                        <ArgonSelect
                          options={sortByOptions}
                          onChange={(e) => setSelectedSortBy(e)}
                        />
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                        >
                          Sort By
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Card>
            </Grid>
            <Grid item xs={12} lg={8}>
              {messages.messages.map((message) => (
                <MessageItem message={message} />
              ))}
            </Grid>
          </Grid>
        </ArgonBox>
        <Footer />
      </DashboardLayout>
      <HostHiveDialog
        open={multiSelectionOpen}
        fullWidth={true}
        includeClose={true}
        includeSave={true}
        maxWidth="md"
        title="Select"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <MultiSelection
            selectedIds={selectedIds}
            handleSelection={handleSelection}
            entities={entities}
          />
        }
        onClose={() => setMultiSelectionOpen(false)}
        onSave={handleSelectionSaved}
      />
    </>
  );
}

function MainContent() {
  return (
    <div className="main-content">
      {/* Conversation */}
      <Message />
      {/* ... Add more Message components as needed */}
    </div>
  );
}

function Message() {
  return (
    <div className="message">
      Sample Conversation Message lasfhvilasfhbvlkasfbhvklasdbhvklasdbvl
      aksdhgvialsdhvblasd
    </div>
  );
}

export default CompanyMessages;
