/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import IconButton from "@mui/material/IconButton";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import CategoriesApi from "api/categoriesApi";

import HostHiveLoading from "components/HostHiveLoading";

import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveDialog from "components/HostHiveDialog";
import CategoryDialog from "layouts/management/components/CategoryDialog";

import { Icon } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import CategoryItem from "./components/CategoryItem";
function CategorySettings({}) {
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const [categorySettingsLoading, setCategorySettingsLoading] = useState(true);

  const categoriesApi = new CategoriesApi();
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [sidenavColor, setSidenavColor] = useState("primary");
  const [showColors, setShowColors] = useState(null);

  const [colorData, setColorData] = useState(null);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [refetchCategories, setRefetchCategories] = useState(false);
  useEffect(() => {
    async function fetchCategories() {
      setCategorySettingsLoading(true);
      try {
        const params = {
          companyId: company?.id,
          settingUiView: true,
        };

        const response = await categoriesApi.getCategories(params);

        if (response.status >= 200 && response.status < 300) {
          setColorData(response.data);
        } else {
          console.log("Error getting Categories");
        }
      } catch (error) {
        console.log("Error getting Categories", error);
      }
      setCategorySettingsLoading(false);
    }
    fetchCategories();
  }, [refetchCategories]);

  const handleFetchCategories = () => {
    setRefetchCategories(!refetchCategories);
  };

  const sidenavColors = [
    "primary",
    "dark",
    "info",
    "success",
    "warning",
    "error",
  ];

  const [updatedPayload, setUpdatedPayload] = useState([]);

  const handleColorChange = (color, id, categoryType) => {
    setColorData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[categoryType].forEach((item) => {
        if (item.id === id) {
          item.color = color;
        }
      });

      setUpdatedPayload((prevPayload) => [
        ...prevPayload,
        { id: id, color: color, type: categoryType },
      ]);

      return updatedData;
    });
  };

  const handleNameChange = (name, id, categoryType) => {
    setColorData((prevData) => {
      const updatedData = { ...prevData };
      updatedData[categoryType].forEach((item) => {
        if (item.id === id) {
          item.name = name;
        }
      });

      setUpdatedPayload((prevPayload) => [
        ...prevPayload,
        { id: id, name: name, type: categoryType },
      ]);

      return updatedData;
    });
  };

  const handleSave = async () => {
    let dataPayload = {
      payload: updatedPayload,
    };
    try {
      const response = await categoriesApi.bulkEditColors(dataPayload);
      if (response.status >= 200 && response.status < 300) {
        console.log("Successfully Updated category Colors");
        setShowColors(null);
        setUpdatedPayload([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategoryDelete = async (id) => {
    console.log("Deleting category", id);
    // try {
    //   const response = await categoriesApi.deleteCategory(id);
    //   if (response.status >= 200 && response.status < 300) {
    //     console.log("Successfully Deleted category");
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    // Remove the category from the colorData
    setShowColors(null);
    // setColorData((prevData) => {
    //   const updatedData = { ...prevData };
    //   Object.keys(updatedData).forEach((key) => {
    //     updatedData[key] = updatedData[key].filter((item) => item.id !== id);
    //   });
    //   return updatedData;
    // });
  };

  const [newCategoryItemOpen, setNewCategoryItemOpen] = useState(false);
  return (
    <Card
      id="category-settings"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      {renderSuccessSB}
      <ArgonBox p={3} overflow="auto" maxHeight="40vh">
        <HostHiveDialog
          open={newCategoryItemOpen}
          includeSave={false}
          includeClose={false}
          title="Add Category"
          fullScreen={false}
          onClose={() => setNewCategoryItemOpen(false)}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <CategoryDialog
              companyId={company?.id}
              snackBarOpen={openSuccessSB}
              onClose={() => setNewCategoryItemOpen(false)}
              fetchCategories={handleFetchCategories}
            />
          }
        />
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">Category Settings</ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => setNewCategoryItemOpen(true)}
            >
              {" "}
              + Add Category
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        {categorySettingsLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            p={2}
            mt={1}
            width="100%"
            // height={propertySettings.length > 1 ? "26.25rem" : "auto"}
            height="auto"
            style={{ overflow: "auto" }}
          >
            {colorData ? (
              <>
                {Object.entries(colorData).map(([key, items]) => (
                  <div key={key}>
                    <ArgonBox pb={2}>
                      <ArgonTypography textTransform="capitalize" variant="h6">
                        {key}
                      </ArgonTypography>
                      <Grid container spacing={1}>
                        {items.map((item) => (
                          <Grid item xs={12} key={item.id}>
                            <CategoryItem
                              key={item.id}
                              category={item}
                              fetchCategories={handleFetchCategories}
                            />
                          </Grid>
                        ))}
                        {/* {renderItems(key, items)} */}
                        {/* <CategoryItem /> */}
                      </Grid>
                    </ArgonBox>
                  </div>
                ))}
              </>
            ) : null}
          </ArgonBox>
        )}
        <ArgonBox pr={2}>
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={handleSave}
            disabled={updatedPayload.length === 0}
          >
            Save
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default CategorySettings;
