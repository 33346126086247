/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import HostHiveDialog from "components/HostHiveDialog";
// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import InventoryDataCard from "layouts/management/components/InventoryDataCard";
import UserDialog from "layouts/management/users/new-user/userDialog";
import UsersApi from "api/usersApi";

function UserItem({
  user,
  setUser,
  handleCancelClicked,
  handleSubmitClicked,
  setRefreshList,
  ...rest
}) {
  const usersApi = new UsersApi();
  const { borderWidth } = borders;

  const navigate = useNavigate();

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handleViewUserItem = (userId) => {
    navigate(`/users/${userId}`);
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await usersApi.deleteUser(userId);
    } catch {
      console.log("Error deleting user");
    } finally {
      setRefreshList();
      setUserDialogOpen(false);
      handleCloseMenu();
    }
  };

  const renderMenu = (id) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setUserDialogOpen(true) && handleCloseMenu();
        }}
      >
        View
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleDeleteUser(id) && handleCloseMenu();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );
  const [inventoryItemModalOpen, setInventoryItemModalOpen] = useState(false);

  // <div
  //   style={{
  //     display: "flex",
  //     alignItems: { xs: "flex-start", sm: "center" },
  //     flexDirection: { xs: "column", sm: "row" },
  //     mt: 2,
  //     ml: 3,
  //     pl: 0.5,
  //   }}
  // >
  //   <div
  //     component="li"
  //     style={{
  //       width: "100%",
  //       pr: 2,
  //       mb: 2,
  //       listStyle: "none",
  //       maxHeight: "200px",
  //       overflowY: "scroll",
  //     }}
  //   >
  //     <div style={{ width: "100%", pl: 1, ml: 2 }}>
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: { xs: "column", sm: "row" },
  //           mt: 2,
  //           ml: 3,
  //           pl: 0.5,
  //           alignItems: { xs: "flex-start", sm: "center" },
  //           overflow: "hidden",
  //         }}
  //       >
  //         {/* Date */}
  //         <div style={{ lineHeight: 1, mb: { xs: 1, sm: 0 }, flex: 1 }}>
  //           <ArgonTypography variant="caption" fontWeight="bold" color="text">
  //             {firstName}
  //           </ArgonTypography>
  //         </div>

  //         {/* Summary */}
  //         <div
  //           style={{
  //             ml: { xs: 0, sm: "auto" },
  //             mb: { xs: 1, sm: 0 },
  //             lineHeight: 1,
  //             flex: 1,
  //           }}
  //         >
  //           <ArgonTypography
  //             variant="caption"
  //             fontWeight="bold"
  //             color="text"
  //             style={{ wordWrap: "break-word" }}
  //           >
  //             {lastName}
  //           </ArgonTypography>
  //         </div>

  //         {/* Description */}
  //         <div
  //           style={{
  //             ml: { xs: 0, sm: "auto" },
  //             mb: { xs: 1, sm: 0 },
  //             lineHeight: 1,
  //             flex: 1,
  //           }}
  //         >
  //           <ArgonTypography
  //             variant="caption"
  //             fontWeight="bold"
  //             color="text"
  //             style={{ wordWrap: "break-word" }}
  //           >
  //             {email}
  //           </ArgonTypography>
  //         </div>

  //         {renderMenu(id)}
  //         <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
  //           <ArgonTypography
  //             color="text"
  //             onClick={handleOpenMenu}
  //             sx={{ lineHeight: 0, cursor: "pointer" }}
  //           >
  //             <Icon fontSize="default">more_vert</Icon>
  //           </ArgonTypography>
  //         </div>
  //       </div>
  //     </div>
  //     <Divider style={{ marginBottom: 0 }} />
  //   </div>
  // </div>
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  return (
    <ArgonBox
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      alignItems={{ xs: "flex-start", sm: "center" }}
      mt={2}
      ml={3}
    >
      <ArgonBox
        component="li"
        width="100%"
        pr={2}
        mb={2}
        style={{ listStyle: "none", maxHeight: "200px", overflowY: "scroll" }}
      >
        <HostHiveDialog
          open={userDialogOpen}
          includeClose={false}
          includeSave={false}
          onClose={() => setUserDialogOpen(false)}
          onSave={handleSubmitClicked}
          title="View User"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <UserDialog
              user={user}
              setUser={setUser}
              handleSubmitClicked={handleSubmitClicked}
              handleClose={() => setUserDialogOpen(false)}
              setRefreshList={setRefreshList}
            />
          }
        />
        <ArgonBox width="100%" pl={1} ml={2}>
          <ArgonBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            mt={2}
            ml={3}
            alignItems={{ xs: "flex-start", sm: "center" }}
            overflow="hidden"
          >
            <ArgonBox lineHeight={1} mb={{ xs: 1, sm: 0 }} flexBasis="25%">
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                noWrap
              >
                {user?.firstName} {user?.lastName}
              </ArgonTypography>
            </ArgonBox>

            {/* <ArgonBox
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
              flexBasis="25%"
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                noWrap
              >
                {user?.lastName}
              </ArgonTypography>
            </ArgonBox> */}

            <ArgonBox
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
              flexBasis="25%"
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                noWrap
              >
                {user?.email}
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox
              ml={{ xs: 0, sm: "auto" }}
              mb={{ xs: 1, sm: 0 }}
              lineHeight={1}
              flexBasis="25%"
            >
              <ArgonBadge
                color="info"
                badgeContent={user?.applicationRole?.displayName}
              />
              {/* <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                noWrap
              >
                {user?.applicationRole?.displayName}
              </ArgonTypography> */}
            </ArgonBox>

            {/* {user?.applicationRole?.roleName} */}

            {renderMenu(user?.id)}
            <ArgonBox mx={{ xs: 0, sm: "auto" }} lineHeight={1} flexBasis="25%">
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        <Divider style={{ marginBottom: 0 }} />
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of Todo
UserItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
UserItem.propTypes = {
  name: PropTypes.string.isRequired,

  id: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default UserItem;
