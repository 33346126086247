import ArgonAlert from "components/ArgonAlert";

/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonEditor from "components/ArgonEditor";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import PropertyTaskSchema from "schemas/propertyTaskSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import TasksApi from "api/propertyTasksApi";
import { Grid } from "@mui/material";
import HostHiveLoading from "components/HostHiveLoading";
import PropertySelect from "layouts/management/components/PropertySelect";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import HostHiveDialog from "components/HostHiveDialog";
import { update } from "lodash";
import { intervalDisplayValue } from "services/dateUtils";
import UsersSelect from "layouts/management/components/UsersSelect";
function TaskItemDialog({
  taskItem,
  propertyId = null,
  onClose,
  reloadTimeLine = null,
  openSuccessSB = null,
  // openErrorSB = null,
  edit = false,
}) {
  const tasksApi = TasksApi();
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    console.log("companyId", company?.id);
    console.log("Task item", taskItem);
  }, []);
  const [newDueDate, setNewDueDate] = useState(null);
  const [startDate, setStartDate] = useState();
  const [recurrenceConfigurationOpen, setRecurrenceConfigurationOpen] =
    useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [selectedBadge, setSelectedBadge] = useState("primary");
  const [prioritySelected, setPrioritySelected] = useState("low");
  const [lowPrioritySelectColor, setLowPriorityColor] = useState("success");
  const [mediumPrioritySelectColor, setMediumPriorityColor] =
    useState("warning");
  const [highPrioritySelectColor, setHighPriorityColor] = useState("error");
  const [recurrenceData, setRecurrenceData] = useState(null);
  const [updatedAssignee, setUpdatedAssignee] = useState(null);
  const [subAssigneeName, setSubAssigneeName] = useState(null);

  const handleLowPrioritySelected = () => {
    setLowPriorityColor("success");
    setMediumPriorityColor("primary");
    setHighPriorityColor("primary");
    setPrioritySelected("low");
  };

  const handleMediumPrioritySelected = () => {
    setLowPriorityColor("primary");
    setMediumPriorityColor("warning");
    setHighPriorityColor("primary");
    setPrioritySelected("medium");
  };

  const handleHighPrioritySelected = () => {
    setLowPriorityColor("primary");
    setMediumPriorityColor("primary");
    setHighPriorityColor("error");
    setPrioritySelected("high");
  };
  const [category, setCategory] = useState(null);
  useEffect(() => {
    if (category) {
      setShowCategoryError(false);
    }
  }, [category]);
  const [showCategoryError, setShowCategoryError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [property, setProperty] = useState(null);

  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("error");
  const [alertMessage, setAlertMessage] = useState(
    "Hmm... Something isn't correct. Please check your inputs and try again."
  );
  const onSubmit = async (values, actions) => {
    // if (!category) {
    //   setShowCategoryError(true);
    //   return;
    // }
    setIsSaving(true);
    if (newDueDate) {
      values.dueDate = dayjs(newDueDate).format("YYYY-MM-DD");
    }
    if (recurrenceData) {
      values.recurrenceData = recurrenceData;
    }
    if (updatedAssignee) {
      values.subAssigneeId = updatedAssignee?.value;
    }
    values.priority = taskPriority?.value;
    values.categoryId = category?.value;
    values.assigneeId = company?.id;
    values.status = taskStatus?.value;
    if (!propertyId) {
      values.propertyId = property?.value;
    } else {
      values.propertyId = propertyId;
    }
    let response;
    if (edit) {
      values.id = taskItem?.id;
      values.ticketNumber = taskItem?.ticketNumber;
      try {
        response = await tasksApi.updateTask(taskItem?.id, values);
        if (reloadTimeLine) {
          reloadTimeLine();
        }
        if (openSuccessSB) {
          openSuccessSB(
            "Success",
            edit ? "Task updated successfully" : "Task created successfully"
          );
        }

        onClose();
        setIsSaving(false);
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        setShowAlert(true);
      }
    } else {
      try {
        response = await tasksApi.createTask(values);

        if (reloadTimeLine) {
          reloadTimeLine();
        }
        if (openSuccessSB) {
          openSuccessSB(
            "Success",
            edit ? "Task updated successfully" : "Task created successfully"
          );
        }

        onClose();
        setIsSaving(false);
      } catch (error) {
        console.log(error?.data?.detail);
        setErrorMessage(error?.data?.detail || "");
        setIsSaving(false);
        setShowAlert(true);
      }
    }
  };

  const priorities = [
    {
      value: "low",
      label: t("components.tasks.priority.low"),
    },
    {
      value: "medium",
      label: t("components.tasks.priority.medium"),
    },
    {
      value: "high",
      label: t("components.tasks.priority.high"),
    },
  ];

  const [taskPriority, setTaskPriority] = useState({
    value: "medium",
    label: t("components.tasks.priority.medium"),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      summary: taskItem?.summary || "",
      description: taskItem?.description || "",
      companyId: taskItem?.companyId || company?.id,
      //propertyId: taskItem?.propertyId || property?.value,
      assigneeId: taskItem?.assigneeId || company?.id,
      subAssigneeId: taskItem?.subAssigneeId || null,
      comments: taskItem?.comments || null,
      categoryId: taskItem?.categoryId || null,
    },
    validationSchema: PropertyTaskSchema,
    onSubmit,
  });

  const [taskStatusOptions, setTaskStatusOptions] = useState([
    {
      label: t("components.tasks.status.open"),
      value: "open",
    },
    {
      label: t("components.tasks.status.inProgress"),
      value: "inProgress",
    },
    {
      label: t("components.tasks.status.closed"),
      value: "closed",
    },
    {
      label: t("components.tasks.status.onHold"),
      value: "onHold",
    },
  ]);

  const [taskStatus, setTaskStatus] = useState({
    label: t("components.tasks.status.open"),
    value: "open",
  });

  // def interval_display_value(value):
  //   if value == "weekly":
  //       return "Weeks"
  //   elif value == "monthly":
  //       return "Months"
  //   elif value == "daily":
  //       return "Days"
  //   elif value == "yearly":
  //       return "Years"
  //   else:
  //       return "Unknown"
  // const intervalDisplayValue = (value, intervalValue) => {
  //   if (value === "weekly") {
  //     return "Weeks";
  //   } else if (value === "monthly") {
  //     if (intervalValue === "1") {
  //       return "Month";
  //     } else {
  //       return "Months";
  //     }
  //   } else if (value === "daily") {
  //     return "Days";
  //   } else if (value === "yearly") {
  //     return "Years";
  //   }
  // };
  return (
    <>
      {isSaving ? (
        <HostHiveLoading
          message={t("components.tasks.savingTask")}
          elipse={true}
        />
      ) : (
        <>
          {showAlert ? (
            <ArgonAlert color={alertColor} dismissible>
              <ArgonBox display="flex" justifyContent="flex-end">
                <ArgonBox>
                  <ArgonTypography variant="caption" color="white">
                    {alertMessage}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonAlert>
          ) : null}
          <form onSubmit={handleSubmit}>
            <ArgonBox p={2} lineHeight={1}>
              <HostHiveDialog
                open={recurrenceConfigurationOpen}
                fullWidth={true}
                includeSave={false}
                includeClose={false}
                maxWidth="sm"
                // onClose={handleCancelClicked}
                // onSave={handleSubmitClicked}
                title="Recurrence Configuration"
                fullScreen={false}
                // cancelButtonText="Cancel"
                // submitButtonText="Save"
                showStatusBadge={false}
                statusColor="success"
                statusContent="Confirmed"
                dialogObjects={
                  <RecurrenceDialog
                    onClose={() => setRecurrenceConfigurationOpen(false)}
                    setRecurrenceData={setRecurrenceData}
                    recurrenceData={recurrenceData}
                  />
                }
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {t("generalWords.summary")}
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonInput
                        name="summary"
                        placeholder="eg. bee@hosthive.io"
                        type="text"
                        value={values.summary}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // Add error handling from Formik state
                        error={touched.summary && Boolean(errors.summary)}
                        helperText={touched.summary && errors.summary}
                      />
                      {errors.summary && touched.summary && (
                        // <p className="error">{errors.name}</p>
                        <HostHiveErrorMessage message={errors.summary} />
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {t("generalWords.description")}
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonInput
                        name="description"
                        placeholder="eg. bee@hosthive.io"
                        type="text"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        multiline
                        rows={4}
                        // Add error handling from Formik state
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                      />
                      {errors.description && touched.description && (
                        // <p className="error">{errors.name}</p>
                        <HostHiveErrorMessage message={errors.description} />
                      )}
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                {propertyId ? null : (
                  <Grid item xs={12} md={6}>
                    <PropertySelect
                      companyId={company?.id}
                      setProperty={setProperty}
                    />
                  </Grid>
                )}
                {!edit ? (
                  <Grid item xs={12} md={6}>
                    <ArgonBox>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {t("components.tasks.priority.title")}
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonSelect
                          name="priority"
                          placeholder="eg."
                          options={priorities}
                          value={taskPriority}
                          onChange={(e) => setTaskPriority(e)}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                ) : null}

                {!edit ? (
                  <Grid item xs={12} md={6}>
                    <ArgonBox mb={2}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {t("generalWords.status")}
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonSelect
                          name="status"
                          placeholder="eg."
                          options={taskStatusOptions}
                          value={taskStatus}
                          onChange={(e) => setTaskStatus(e)}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                ) : null}
                {!edit ? (
                  <Grid item xs={12} md={6}>
                    <UsersSelect
                      setUser={setUpdatedAssignee}
                      user={updatedAssignee}
                      companyId={company?.id}
                    />
                  </Grid>
                ) : null}
                {!edit ? (
                  <Grid item xs={12} md={6}>
                    <ArgonBox mb={2}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Due Date (Optional)
                          </ArgonTypography>
                        </ArgonBox>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={(newValue) =>
                              setNewDueDate(dayjs(newValue))
                            }
                            value={startDate}
                            orientation="landscape"
                          />
                        </LocalizationProvider>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                ) : null}
                {!edit ? (
                  <Grid item xs={12} md={6}>
                    <ArgonBox mb={2}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Repeat (Optional)
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox display="flex">
                          <ArgonBox>
                            <ArgonTypography
                              variant="caption"
                              textTransform="capitalize"
                            >
                              {" "}
                              {recurrenceData
                                ? t("serviceAssignments.eventDataCard.every")
                                : null}{" "}
                              {recurrenceData?.recurrenceIntervalValue}{" "}
                              {intervalDisplayValue(
                                recurrenceData?.recurrenceInterval,
                                recurrenceData?.recurrenceIntervalValue
                              )}{" "}
                              {recurrenceData?.endDate
                                ? `until ${recurrenceData.endDate}`
                                : ""}
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonBox pl={1}>
                            <ArgonTypography
                              variant="caption"
                              textTransform="capitalize"
                              sx={{
                                ":hover": {
                                  textDecoration: "underline",
                                  color: "primary.main",
                                },
                                cursor: "pointer",
                                fontStyle: "italic",
                              }}
                              onClick={() =>
                                setRecurrenceConfigurationOpen(true)
                              }
                            >
                              {t("generalWords.edit")}
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                ) : null}
              </Grid>

              {/* <CategorySelect
                setCategory={setCategory}
                category={category}
                categoryType="task"
                companyId={company?.id}
              />
              {showCategoryError ? (
                <HostHiveErrorMessage message="Category selection is required." />
              ) : null} */}

              {/* <ArgonBox mb={2}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      {t("generalWords.comments")}
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonEditor value={editorValue} onChange={setEditorValue} />
                </ArgonBox>
              </ArgonBox> */}

              <ArgonBox mb={2}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                ></ArgonBox>
              </ArgonBox>

              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox p={1}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  ></ArgonBox>
                </ArgonBox>
                {/* Display role checkboxes here based on viewMode */}
              </ArgonBox>
            </ArgonBox>
            <ArgonBox justifyContent="center" display="flex" p={2}>
              <ArgonBox display="flex">
                <ArgonBox pr={2}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={onClose}
                  >
                    {t("buttons.cancel")}
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox pr={2}>
                  <ArgonButton variant="gradient" color="info" type="submit">
                    {t("buttons.save")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </form>
        </>
      )}
    </>
  );
}

export default TaskItemDialog;
