/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import convertToSelector from "services/selector";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonProgress from "components/ArgonProgress";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Argon Dashboard 2 PRO MUI example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HostHiveImages from "components/HostHiveImages";
// Argon Dashboard 2 PRO MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import NotesDataCard from "layouts/management/components/Notes";
import DocumentsDataCard from "layouts/management/components/Documents";
import PropertyContext from "context/PropertyContext";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import ArgonSelect from "components/ArgonSelect";
import OccupancyStatusDialog from "layouts/management/components/OccupancyStatusDialog";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";
import ArgonInput from "components/ArgonInput";
import ArgonDatePicker from "components/ArgonDatePicker";
function Header({
  title,
  subtitle,
  limit,
  setLimit,
  setApplyCategoryOpen,
  setApplyPropertyOpen,
  selectedIds,
  setSelectedIds,
  handleSelectAllChange,
  selectAll,
  handleSave,
  handleDecline,
  saveDisabled,
  saving,
  handleDeleteSelected,
  transactionType,
  setTransactionType,
  setMatchOpen,
  searchField,
  setSearchField,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  resultsCount,
  loadingTransactions,
}) {
  const { t } = useTranslation("common");

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [searchStage, setSearchStage] = useState(null);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* setTaskItemDiaglogOpen */}
      <MenuItem>Edit Task Details</MenuItem>
    </Menu>
  );

  const limitOptions = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 400, value: 400 },
  ];

  const transactionTypeOptions = [
    { label: "All", value: "all" },
    { label: "Income", value: "income" },
    { label: "Expense", value: "expense" },
  ];

  const handleSearchClicked = () => {
    setSearchField(searchStage);
    setSearchStage(null);
  };

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const handleSetStartDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (param === "startDate") {
      setStartDate(useDate);
    } else {
      setEndDate(useDate);
    }
  };

  const resetSearch = () => {
    setSearchField(null);
    setStartDate(null);
    setEndDate(null);
    setSearchStage("");
  };

  return (
    <ArgonBox position="relative">
      <Card
        sx={{
          py: 3,
          px: 3,
          boxShadow: ({ boxShadows: { md } }) => md,
          justifyContent: { xs: "center" },
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item sm={12}>
            <Grid container spacing={3} alignItems="center">
              {renderMenu()}

              <Grid
                item
                lg={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ArgonButton
                      variant="gradient"
                      color="primary"
                      onClick={() => setMatchOpen(true)}
                      sx={{
                        ml: "auto",
                        alignSelf: "flex-start",
                        py: 1.25,
                        pr: 1.5,
                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                      startIcon={
                        <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                          auto_fix_high
                        </Icon>
                      }
                    >
                      Import Match
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} xl={2.4}>
                    <ArgonBox pl={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />
                        }
                        label={t("generalWords.selectAll")}
                      />
                    </ArgonBox>
                    <ArgonBox pl={4.5}>
                      <ArgonTypography variant="caption" fontWeight="bold">
                        {` Selected (${selectedIds.length})`}
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} xl={2.4}>
                    <ArgonButton
                      disabled={selectedIds.length === 0}
                      variant="gradient"
                      color="primary"
                      onClick={() => setApplyPropertyOpen(true)}
                      sx={{
                        ml: "auto",
                        alignSelf: "flex-start",
                        py: 1.25,
                        pr: 1.5,
                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                      startIcon={
                        <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                          house
                        </Icon>
                      }
                    >
                      Apply Property
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} xl={2.4}>
                    <ArgonButton
                      disabled={selectedIds.length === 0}
                      variant="gradient"
                      color="primary"
                      onClick={() => setApplyCategoryOpen(true)}
                      sx={{
                        ml: "auto",
                        alignSelf: "flex-start",
                        py: 1.25,
                        pr: 1.5,
                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                      startIcon={
                        <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                          category
                        </Icon>
                      }
                    >
                      Apply Category
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} xl={2.4}>
                    <ArgonButton
                      disabled={selectedIds.length === 0}
                      variant="gradient"
                      color="error"
                      onClick={handleDeleteSelected}
                      sx={{
                        ml: "auto",
                        alignSelf: "flex-start",
                        py: 1.25,
                        pr: 1.5,
                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      Decline Selected
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} xl={2.4}>
                    <ArgonButton
                      disabled={saveDisabled || saving}
                      variant="gradient"
                      color="success"
                      onClick={handleSave}
                      sx={{
                        ml: "auto",

                        borderRadius: "8px",
                        borderWidth: "1px",
                        transition: "background-color 0.3s, transform 0.2s",
                        ":hover": {
                          backgroundColor: "rgba(0,0,0,0.05)",
                          transform: "scale(1.02)",
                        },
                      }}
                    >
                      {saving ? "Approving..." : "Approve Selected"}
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <ArgonTypography variant="caption" fontWeight="bold">
                      Display
                    </ArgonTypography>
                    <ArgonSelect
                      value={{ label: limit, value: limit }}
                      onChange={(e) => setLimit(e.value)}
                      options={limitOptions}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <ArgonTypography
                      variant="caption"
                      fontWeight="bold"
                      disabled={loadingTransactions}
                    >
                      Transaction Type
                    </ArgonTypography>
                    <ArgonSelect
                      value={{
                        label: transactionType.toUpperCase(),
                        value: transactionType,
                      }}
                      onChange={(e) => setTransactionType(e.value)}
                      options={transactionTypeOptions}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <ArgonTypography variant="caption" fontWeight="bold">
                      FROM
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleSetStartDate(e, "startDate")}
                      disabled={loadingTransactions}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <ArgonTypography variant="caption" fontWeight="bold">
                      TO
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleSetStartDate(e, "endDate")}
                      disabled={loadingTransactions}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <ArgonTypography variant="caption" fontWeight="bold">
                      Search
                    </ArgonTypography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={8}>
                        <ArgonInput
                          value={searchStage}
                          onChange={(e) => setSearchStage(e.target.value)}
                          disabled={loadingTransactions}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <ArgonButton
                          variant="gradient"
                          color="warning"
                          onClick={handleSearchClicked}
                          disabled={loadingTransactions}
                        >
                          Find
                        </ArgonButton>
                        {/* <ArgonTypography variant="caption" fontWeight="bold">
                          {` Results found (${resultsCount})`}
                        </ArgonTypography> */}
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <ArgonButton
                          variant="gradient"
                          color="light"
                          onClick={resetSearch}
                        >
                          Reset
                        </ArgonButton>
                      </Grid>
                      <Grid item xs={12}>
                        <ArgonTypography variant="caption" fontWeight="bold">
                          {`${
                            loadingTransactions ? "-" : resultsCount
                          } Results found`}
                        </ArgonTypography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </ArgonBox>
  );
}

export default Header;
