/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import convertToSelector from "services/selector";
import ArgonAlert from "components/ArgonAlert";
import {
  acceptedImageMimeTypes,
  acceptedImageSizeInMb,
  acceptedVideoMimeTypes,
  acceptedVideoSizeInMb,
  acceptedDocumentMimeTypes,
  acceptedPropertyImageSizeInMb,
} from "services/acceptedMimeTypes";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonProgress from "components/ArgonProgress";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Argon Dashboard 2 PRO MUI example components
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HostHiveImages from "components/HostHiveImages";
// Argon Dashboard 2 PRO MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import NotesDataCard from "layouts/management/components/Notes";
import DocumentsDataCard from "layouts/management/components/Documents";
import PropertyContext from "context/PropertyContext";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import ArgonSelect from "components/ArgonSelect";
import OccupancyStatusDialog from "layouts/management/components/OccupancyStatusDialog";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";

function Header({
  isLoading,
  title,
  subtitle,
  property,
  setProperty,
  propertyStatus = null,
  setPropertyStatus,
}) {
  const { t } = useTranslation("common");
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const propertiesApi = new PropertiesApi();
  const bookingsApi = new BookingsApi();
  const { occupancyStatusTypes, setOccupancyStatusTypes } =
    useContext(PropertyContext);

  const [occupancyStatusColor, setOccupancyStatusColor] = useState("info");
  const [occupancyStatusContent, setOccupancyStatusContent] = useState(null);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [showAddress, setShowAddress] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [documentsOpen, setDocumentsOpen] = useState(false);
  const [propertyPhotosOpen, setPropertyPhotosOpen] = useState(false);
  const [notesOpen, setNotesOpen] = useState(false);
  const [isCreateNoteModalOpen, setIsCreateNoteModalOpen] = useState(false);
  const [propertySettingsOpen, setPropertySettingsOpen] = useState(false);
  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [erroredPhotos, setErroredPhotos] = useState([]);
  const [documentUploadDialogOpen, setDocumentUploadDialogOpen] =
    useState(false);
  const [updateOccupancyStatusLoading, setUpdateOccupancyStatusLoading] =
    useState(false);
  const [updateOccupancyStatusSelect, setUpdateOccupancyStatusSelect] =
    useState(false);
  const [updatedOccupancyStatus, setUpdatedOccupancyStatus] = useState(null);
  useEffect(() => {
    console.log("Property ->", property);
    setOccupancyStatusColor(property?.occupancyStatusDetails?.color || "error");
    setOccupancyStatusContent(
      property?.occupancyStatusDetails?.displayName || null
    );
    setPropertyImages(property?.images || []);
  }, [property]);
  const [showPropertyShare, setShowPropertyShare] = useState(false);
  const handleShowAddress = () => setShowAddress(!showAddress);
  const handlePropertySettingsSubmitClicked = () => {
    console.log("Property Settings Submit Clicked");
  };
  const handleOpenMenu = () => {
    console.log("clicked");
  };
  const getPropertyCalendarUrl = async () => {
    const response = await bookingsApi.getBookingCalendarUrl(property?.id);
  };
  const PropertyShareDialog = ({}) => {
    return (
      <>
        <ArgonBox>
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={getPropertyCalendarUrl}
          >
            {t(
              "properties.propertyOverview.header.propertyShareDialog.exportPropertyCalendar"
            )}
          </ArgonButton>
        </ArgonBox>
      </>
    );
  };

  const ViewLinkModal = ({ handleOpen, title }) => {
    return (
      <ArgonTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
        sx={{ ":hover": { textDecoration: "underline" }, cursor: "pointer" }}
        onClick={handleOpen}
        title={title}
      >
        {t("hostHiveSpecificActions.view")} {title}
      </ArgonTypography>
    );
  };

  const [occupancyStatusDialogOpen, setOccupancyStatusDialogOpen] =
    useState(false);
  const handleUpdatedOccupancyStatus = async () => {
    setUpdateOccupancyStatusLoading(true);
    try {
      console.log("handleUpdatedOccupancyStatus", updatedOccupancyStatus);
      const resp = await propertiesApi.updatePropertyOccupancyStatus(
        property.id,
        updatedOccupancyStatus.key
      );
      if (resp.status >= 200 && resp.status < 300) {
        setOccupancyStatusColor(updatedOccupancyStatus.color);
        setOccupancyStatusContent(updatedOccupancyStatus.displayName);
        setUpdateOccupancyStatusSelect(false);
        setUpdateOccupancyStatusLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const [propertyImages, setPropertyImages] = useState(property?.images || []);

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", index, fileName);
    try {
      const response = await propertiesApi.deletePropertyImage(
        property?.companyId,
        property?.id,
        fileName
      );

      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
      // Update the bookingImages state by removing the image at the specified index
      setPropertyImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
    } catch (error) {
      console.error(error);
    }
  };
  const [photoProgress, setPhotoProgress] = useState(0);
  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);
    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    //const photoProgress = 0;
    const photoLength = uploadedImagesArray.length;
    const maxSizeInMB = acceptedPropertyImageSizeInMb; // Replace x with the maximum file size in MB
    const allowedMimeTypes = acceptedImageMimeTypes; // Add or remove MIME types as needed
    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      console.log(photoProgress, "% photoProgress");
      const image = uploadedImagesArray[i];

      // Check file size
      if (image.size > maxSizeInMB * 1024 * 1024) {
        console.log(`Error: File size exceeds ${maxSizeInMB} MB`);
        let imageSize = image.size / (1024 * 1024);

        // round to 2 decimal places
        imageSize = imageSize.toFixed(2);
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - File size exceeds ${maxSizeInMB}MB - ${imageSize}MB`,
        ]);
        continue; // Skip this file
      }

      // Check MIME type
      if (!allowedMimeTypes.includes(image.type)) {
        console.log(
          `Error: Invalid file type. Allowed types are: ${allowedMimeTypes.join(
            ", "
          )}`
        );
        setErroredPhotos((prevErroredPhotos) => [
          ...prevErroredPhotos,
          `${image.name} - Invalid file type - ${image.type}`,
        ]);
        continue; // Skip this file
      }
      const fileType = image.type;
      const fileName = image.name;
      console.log("image", image);
      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await propertiesApi.updatePropertyImages(
          property?.companyId,
          property?.id,
          fileName,
          fileType,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setPropertyImages(response.data.images);
        openSuccessSB(`Image added successfully`, `Successfully added image`);
      } catch (error) {
        console.error(error);
      }
    }
    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleReactivateProperty = async () => {
    try {
      const resp = await propertiesApi.reactivateProperty(property.id);
      if (resp.status >= 200 && resp.status < 300) {
        openSuccessSB(
          `Property reactivated successfully`,
          `Successfully reactivated property`
        );
        setPropertyStatus("active");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDismissPhotoUploadError = (index) => {
    const newErroredPhotos = [...erroredPhotos];
    newErroredPhotos.splice(index, 1);
    setErroredPhotos(newErroredPhotos);
  };

  return (
    <ArgonBox position="relative">
      {/* <DashboardNavbar absolute light /> */}
      <ArgonBox height="100px" />
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        open={showPropertyShare}
        onClose={() => {
          setShowPropertyShare(false);
        }}
        title={t(
          "properties.propertyOverview.header.propertyShareDialog.title"
        )}
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={<PropertyShareDialog />}
      />
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        open={propertyPhotosOpen}
        onClose={() => {
          setPropertyPhotosOpen(false);
        }}
        title={t("properties.propertyOverview.viewPhotos")}
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            <Card>
              {erroredPhotos.length > 0 ? (
                <ArgonBox p={2}>
                  {erroredPhotos.map((photo, index) => (
                    <ArgonAlert
                      key={index}
                      color="error"
                      dismissible
                      onDismiss={() => handleDismissPhotoUploadError(index)}
                    >
                      <ArgonTypography variant="caption" color="white">
                        {photo}
                      </ArgonTypography>
                    </ArgonAlert>
                  ))}
                </ArgonBox>
              ) : null}

              <ArgonBox display="flex">
                <ArgonBox
                  p={2}
                  component="input"
                  name="file"
                  type="file"
                  multiple
                  onChange={uploadHandler}
                />

                {photoUploadInProgress ? (
                  <>{t("generalWords.uploading")}..</>
                ) : (
                  <></>
                )}
              </ArgonBox>
              {photoUploadInProgress ? (
                <>
                  <ArgonProgress value={photoProgress} color="success" />
                </>
              ) : (
                <></>
              )}

              <HostHiveImages
                images={propertyImages}
                handleDeleteImage={handleImageDelete}
                imageGridSizeXs={12}
                imageGridSizeMd={6}
              />
            </Card>
          </>
        }
      />
      <HostHiveDialog
        includeDelete={false}
        includeSave={true}
        onSave={handleUpdatedOccupancyStatus}
        open={updateOccupancyStatusSelect}
        onClose={() => {
          setUpdateOccupancyStatusSelect(false);
        }}
        title={`${t("generalWords.update")} ${t(
          "properties.propertiesDataCard.occupancyStatus"
        )}`}
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText={t("buttons.close")}
        submitButtonText={t("buttons.save")}
        dialogObjects={
          <OccupancyStatusDialog
            occupancyStatusTypes={occupancyStatusTypes}
            propertyId={property?.id}
            currentStatus={property?.occupancyStatusDetails}
            updatedOccupancyStatus={updatedOccupancyStatus}
            setUpdatedOccupancyStatus={setUpdatedOccupancyStatus}
          />
        }
      />

      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        open={notesOpen}
        onClose={() => {
          setNotesOpen(false);
        }}
        title={t("properties.propertyOverview.propertyNotes")}
        fullScreen={false}
        cancelButtonText={t("buttons.close")}
        submitButtonText="Save"
        customButton={
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={() => setIsCreateNoteModalOpen(true)}
          >
            {`${t("buttons.create")} ${t("components.notes.note")}`}
          </ArgonButton>
        }
        dialogObjects={
          <NotesDataCard
            isCreateNoteModalOpen={isCreateNoteModalOpen}
            setIsCreateNoteModalOpen={setIsCreateNoteModalOpen}
            companyId={property?.companyId}
            title={t("components.notes.title")}
            propertyId={property?.id}
            handleClose={() => {
              setNotesOpen(false);
            }}
          />
        }
      />

      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={propertySettingsOpen}
        onClose={() => {
          setPropertySettingsOpen(false);
        }}
        onSave={handlePropertySettingsSubmitClicked}
        title={t("properties.propertySettings")}
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <SettingsDataCard
            property={property}
            setProperty={setProperty}
            title={t("properties.propertySettings")}
            propertyId={property?.id}
            handleClose={() => {
              setPropertySettingsOpen(false);
            }}
          />
        }
      />

      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        customButton={
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={() => setDocumentUploadDialogOpen(true)}
          >
            {t("buttons.uploadDocument")}
          </ArgonButton>
        }
        open={documentsOpen}
        onClose={() => {
          setDocumentsOpen(false);
        }}
        onSave={handlePropertySettingsSubmitClicked}
        title={`${t("properties.property")} ${t("components.documents.title")}`}
        fullScreen={false}
        cancelButtonText={t("buttons.close")}
        submitButtonText="Save"
        dialogObjects={
          <DocumentsDataCard
            title={t("components.documents.title")}
            documentUploadDialogOpen={documentUploadDialogOpen}
            setDocumentUploadDialogOpen={setDocumentUploadDialogOpen}
            companyId={property?.companyId}
            propertyId={property?.id}
            property={property}
            handleClose={() => {
              setDocumentsOpen(false);
            }}
          />
        }
      />

      <Card
        sx={{
          py: 2,
          px: 2,
          boxShadow: ({ boxShadows: { md } }) => md,
          justifyContent: { xs: "center" },
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={12} md={3}>
            {renderSuccessSB}
            <ArgonBox display="flex" flexDirection="column" gap={0.5}>
              <ArgonTypography variant="h5" fontWeight="medium">
                {title}
              </ArgonTypography>
              {/* <ArgonTypography
                variant="button"
                color="text"
                fontWeight="medium"
              >
                {subtitle}
              </ArgonTypography> */}
              {propertyStatus ? (
                <ArgonTypography
                  variant="caption"
                  textTransform="capitalize"
                  sx={{
                    ":hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                  onClick={() => setPropertySettingsOpen(true)}
                >
                  {t("properties.propertyOverview.editPropertySettings")}
                </ArgonTypography>
              ) : null}
            </ArgonBox>
          </Grid>
          {propertyStatus === "active" ? (
            <>
              <Grid item sm={12} md={3}>
                <Divider light orientation="vertical" />
                <ArgonBox display="flex" flexDirection="column" gap={0.5}>
                  <ArgonTypography variant="button" fontWeight="medium">
                    {t("properties.propertiesDataCard.bed")}: {property?.bed}
                  </ArgonTypography>
                  <ArgonTypography variant="button" fontWeight="medium">
                    {t("properties.propertiesDataCard.bath")}: {property?.bath}
                  </ArgonTypography>
                  <ArgonTypography variant="button" fontWeight="medium">
                    {t("properties.propertiesDataCard.sqft")}: {property?.sqft}
                  </ArgonTypography>
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonTypography variant="button" fontWeight="medium">
                      {showAddress ? (
                        <>
                          {property?.propertyAddress?.address1}
                          <br />
                          {property?.propertyAddress?.city},{" "}
                          {property?.propertyAddress?.state}{" "}
                          {property?.propertyAddress?.zipCode}
                        </>
                      ) : (
                        <i>
                          {t("properties.propertiesDataCard.addressHidden")}
                        </i>
                      )}
                    </ArgonTypography>
                    <Tooltip
                      title={showAddress ? "Hide Address" : "Reveal Address"}
                      placement="top"
                      onClick={handleShowAddress}
                    >
                      <IconButton size="small">
                        <Icon>
                          {showAddress ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </Tooltip>
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item sm={12} md={3}>
                <Divider light orientation="vertical" />
                <ArgonBox display="flex" flexDirection="column" gap={1}>
                  <ViewLinkModal
                    title={t("components.notes.title")}
                    handleOpen={() => setNotesOpen(true)}
                  />
                  <ViewLinkModal
                    title={t("components.documents.title")}
                    handleOpen={() => setDocumentsOpen(true)}
                  />
                  <ArgonTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    sx={{
                      ":hover": { textDecoration: "underline" },
                      cursor: "pointer",
                    }}
                    onClick={() => setPropertyPhotosOpen(true)}
                    title={t("properties.propertyOverview.viewPhotos")}
                  >
                    {t("properties.propertyOverview.viewPhotos")}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>

              <Grid item md={3} sm={12}>
                <Divider light orientation="vertical" />
                <ArgonBox display="flex" gap={2} alignItems="center">
                  {updateOccupancyStatusSelect ||
                  updateOccupancyStatusLoading ? (
                    <HostHiveLoading />
                  ) : occupancyStatusContent ? (
                    <>
                      <Tooltip
                        title="Update Occupancy Status"
                        placement="top"
                        onClick={() =>
                          setUpdateOccupancyStatusSelect(
                            !updateOccupancyStatusSelect
                          )
                        }
                      >
                        <IconButton>
                          <ArgonBadge
                            variant="contained"
                            color={occupancyStatusColor}
                            badgeContent={t(
                              `properties.propertiesDataCard.occupancyStatuses.${occupancyStatusContent.toLowerCase()}`
                            )}
                            container
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : null}
                  <Tooltip
                    title="Export property calendar"
                    placement="top"
                    onClick={() => setShowPropertyShare(true)}
                  >
                    <IconButton>
                      <Icon>ios_share</Icon>
                    </IconButton>
                  </Tooltip>
                </ArgonBox>
              </Grid>
            </>
          ) : propertyStatus === "inactive" ? (
            <>
              <Grid item sm={12} md={3}>
                <ArgonButton
                  variant="gradient"
                  color="warning"
                  onClick={() => handleReactivateProperty()}
                >
                  Activate Property
                </ArgonButton>
              </Grid>
            </>
          ) : (
            <ArgonBox p={3}>
              <HostHiveLoading
                message="Loading property details"
                elipse={true}
              />
            </ArgonBox>
          )}
        </Grid>
      </Card>
    </ArgonBox>
  );
}

export default Header;
