/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @asseinfo/react-kanban components
import Board from "@asseinfo/react-kanban";

// html-react-parser components
import parse from "html-react-parser";
// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";
import FormField from "layouts/management/properties/new-property/components/FormField";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ServiceNameApi from "api/service-nameApi";
import ChecklistsApi from "api/checkListsApi";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";

// Kanban application components
import Header from "layouts/applications/kanban/components/Header";
import { useParams } from "react-router-dom";

// Data
import boards from "layouts/applications/kanban/data";
import i18next from "i18next";

function TestTypography({ editMode = false }) {
  const { t } = useTranslation("common");

  const niIcons = [
    "active-40",
    "air-baloon",
    "album-2",
    "align-center",
    "align-left-2",
    "ambulance",
    "app",
    "archive-2",
    "atom",
    "badge",
    "bag-17",
    "basket",
    "bell-55",
    "bold-down",
    "bold-left",
    "bold-right",
    "bold-up",
    "bold",
    "book-bookmark",
    "books",
    "box-2",
    "briefcase-24",
    "building",
    "bulb-61",
    "bullet-list-67",
    "bus-front-12",
    "button-pause",
    "button-play",
    "button-power",
    "calendar-grid-58",
    "camera-compact",
    "caps-small",
    "cart",
    "chart-bar-32",
    "chart-pie-35",
    "chat-round",
    "check-bold",
    "circle-08",
    "cloud-download-95",
    "cloud-upload-96",
    "compass-04",
    "controller",
    "credit-card",
    "curved-next",
    "delivery-fast",
    "diamond",
    "email-83",
    "fat-add",
    "fat-delete",
    "fat-remove",
    "favourite-28",
    "folder-17",
    "glasses-2",
    "hat-3",
    "headphones",
    "html5",
    "istanbul",
    "key-25",
    "laptop",
    "like-2",
    "lock-circle-open",
    "map-big",
    "mobile-button",
    "money-coins",
    "note-03",
    "notification-70",
    "palette",
    "paper-diploma",
    "pin-3",
    "planet",
    "ruler-pencil",
    "satisfied",
    "scissors",
    "send",
    "settings-gear-65",
    "settings",
    "single-02",
    "single-copy-04",
    "sound-wave",
    "spaceship",
    "square-pin",
    "support-16",
    "tablet-button",
    "tag",
    "tie-bow",
    "time-alarm",
    "trophy",
    "tv-2",
    "umbrella-13",
    "user-run",
    "vector",
    "watch-time",
    "world",
    "zoom-split-in",
    "collection",
    "image",
    "shop",
    "ungroup",
    "world-2",
    "ui-04",
  ];

  const colors = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];

  return (
    <DashboardLayout>
      <ArgonBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography>Default</ArgonTypography>
                <ArgonTypography>{t("welcome")}</ArgonTypography>
                <ArgonTypography variant="button">Button</ArgonTypography>
                <ArgonTypography variant="caption">Caption</ArgonTypography>
                <ArgonTypography variant="body2">body2</ArgonTypography>
                <ArgonTypography variant="body1">body1</ArgonTypography>
                <ArgonTypography variant="h1">Test h1</ArgonTypography>
                <ArgonTypography variant="h2">Test h2</ArgonTypography>
                <ArgonTypography variant="h3">Test h3</ArgonTypography>
                <ArgonTypography variant="h4">Test h4</ArgonTypography>
                <ArgonTypography variant="h5">Test h5</ArgonTypography>
                <ArgonTypography variant="h6">Test h6</ArgonTypography>

                {niIcons.map((icon, iconIndex) => (
                  <>
                    <ArgonBox p={1}>
                      <ArgonTypography variant="h6">{icon}</ArgonTypography>
                      {colors.map((color, colorIndex) => (
                        <ArgonBox
                          pl={1}
                          key={`${iconIndex}-${colorIndex}`}
                          alignItems="center"
                          justifyContent="space-between"
                          className={`ni ni-${icon}`}
                          color={color}
                          mb={2}
                        >
                          {/* You can use both icon and color variables here if needed */}
                        </ArgonBox>
                      ))}
                    </ArgonBox>
                  </>
                ))}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default TestTypography;
