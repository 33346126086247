import React from "react";
import { useMemo, useState, useEffect, useContext } from "react";

import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
function PricingCalculator({ button, image, children }) {
  const [count, setCount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const calculateCost = (inputCount) => {
    let count = parseInt(inputCount, 10);
    let totalCost = 0;

    // First 1 is $12
    if (count >= 1) {
      totalCost += 12;
      count -= 1;
    }

    // Next 3 are $8 each
    if (count >= 3) {
      totalCost += 3 * 8;
      count -= 3;
    } else {
      totalCost += count * 8;
      count = 0;
    }

    // After 5, each additional is $5
    totalCost += count * 5;

    return totalCost;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setCount(value);
    const cost = calculateCost(value);
    setTotalCost(cost);
  };
  return (
    <div>
      <ArgonTypography>Subscription Calculator</ArgonTypography>
      <ArgonTypography>
        Enter the number of subscriptions:
        <ArgonInput type="number" value={count} onChange={handleInputChange} />
      </ArgonTypography>
      <ArgonTypography>
        Estimated Monthly base subscription Cost: ${totalCost}
      </ArgonTypography>
    </div>
  );
}

export default PricingCalculator;
