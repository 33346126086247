// API client for /notes endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function MessagesApi() {
  const axiosPrivate = useAxiosPrivate();

  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();

  // const isAuthorized = isRouteAuthorized(
  //   "propertiesPlus",
  //   role,
  //   subscriptionTier
  // );

  // if (!isAuthorized) {
  //   return null;
  // }

  const getMessages = (queryParams) => {
    return axiosPrivate.get(`/messages`, { params: queryParams });
  };

  const createMessage = (messageData) => {
    return axiosPrivate.post(`/messages`, messageData);
  };

  const getMessage = (messageId) => {
    return axiosPrivate.get(`/messages/${messageId}`);
  };

  const deleteMessage = (messageId) => {
    return axiosPrivate.delete(`/messages/${messageId}`);
  };

  return {
    createMessage,
    getMessages,
    getMessage,
    deleteMessage,
  };
}

export default MessagesApi;
