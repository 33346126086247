import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ContactsApi() {
  const axiosPrivate = useAxiosPrivate();

  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();

  // const isAuthorized = isRouteAuthorized("Contact", role, subscriptionTier);

  // if (!isAuthorized) {
  //   return null;
  // }

  const getContacts = (queryParams) => {
    return axiosPrivate.get(`/contacts`, { params: queryParams });
  };

  const createContact = (contactData) => {
    return axiosPrivate.post(`/contacts`, contactData);
  };

  const getContact = (contactId) => {
    return axiosPrivate.get(`/contacts/${contactId}`);
  };

  const updateContact = (contactId, contactData) => {
    return axiosPrivate.put(`/contacts/${contactId}`, contactData);
  };

  const deleteContact = (contactId) => {
    return axiosPrivate.delete(`/contacts/${contactId}`);
  };

  ///property/{property_id}/{contact_company_id}

  const getPropertyContact = (propertyId) => {
    return axiosPrivate.get(`/contacts/property/${propertyId}`);
  };

  return {
    getContacts,
    createContact,
    getContact,
    updateContact,
    deleteContact,
    getPropertyContact,
  };
}

export default ContactsApi;
