/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";

import { useState, useEffect } from "react";
// @mui material components
import TransactionItemSchema from "schemas/newTransaction";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import { setIn, useFormik } from "formik";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import CompanyApi from "api/companyApi";
import PropertySelect from "layouts/management/components/PropertySelect";
import BookingsApi from "api/bookingsApi";
import ServiceNameApi from "api/service-nameApi";
import SettingsApi from "api/settingsApi";
import ArgonAlert from "components/ArgonAlert";
import ChecklistsApi from "api/checkListsApi";
import ApplicationApi from "api/applicationApi";
import PropertiesApi from "api/propertiesApi";
import AccountingApi from "api/accountingApi";
import { set } from "lodash";
function NewTransactionItem({ onClose, setFetchTransactions, propertyInfo }) {
  const navigate = useNavigate();
  const accountingApi = AccountingApi();
  const [property, setProperty] = useState({});
  const [createIntegrationSettings, setCreateIntegrationSettings] =
    useState(false);
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  // useEffect(() => {
  //   async function fetchProperties() {
  //     if (propertyInfo) {
  //       setProperty({
  //         value: propertyInfo?.propertyId,
  //         label: propertyInfo?.propertyName,
  //       });
  //     }
  //   }

  //   fetchProperties();
  // }, [property]);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("warning");
  const [transactionDate, setTransactionDate] = useState(null);
  const [alertMessage, setAlertMessage] = useState(
    "Some long alert message about something that went wrong."
  );

    useEffect(() => {

      // Convert 2024-03-14T01:57:31.392Z to 2024-03-14
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let useDate = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
      setTransactionDate(useDate);
      
      console.log("setting the date",useDate)
    },[]);

  const onSubmit = async (values, actions) => {
    if (!property?.value && !propertyInfo?.propertyId) {
      console.log(propertyInfo);
      console.error("property is required");
      setAlertColor("error");
      setAlertMessage("Property selection is Required");
      setShowAlert(true);
      return;
    }
    if (property?.value && !propertyInfo?.propertyId) {
      values.propertyId = property?.value;
    }

    if (!category?.value) {
      values.categoryId = "none";
    } else {
      values.categoryId = category?.value;
    }

    if (transactionType?.value === "expense") {
      values.amount = -Math.abs(values.amount);
    } else {
      values.amount = Math.abs(values.amount);
    }

    values.transactionDate = transactionDate;
    values.companyId = company?.id;
    console.log(values);
    setIsSaving(true);
    try {
      let response;
      response = await accountingApi.createTransaction(values);
      //  response = await propertyApi.createProperty(values);
      if (response.status >= 200 && response.status < 300) {
        console.log("response", response);
        setFetchTransactions();
      }
      setIsSaving(false);

      onClose();
    } catch (error) {
      setAlertColor("error");
      setAlertMessage("Error saving transaction");
      setShowAlert(true);

      setIsSaving(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: company?.id,
      propertyId: property?.id || propertyInfo?.propertyId || "",
      summary: "",
      description: "",
      amount: "",
      categoryId: "",
      status: "active",
      // Add more fields here
    },
    validationSchema: TransactionItemSchema,
    onSubmit,
  });
  const [category, setCategory] = useState({});

  const [transactionType, setTransactionType] = useState({
    value: "expense",
    label: t("accounting.general.expense"),
  });

  const handleServiceDateSelection = (date) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    console.log("transaction date",useDate);
    setTransactionDate(useDate);
    // setServiceDate(useDate);
    // setBookingPayload({ ...bookingPayload, serviceScheduleDate: useDate });
    // if (!isInCreationMode) {
    //   updateBooking("serviceScheduleDate", useDate);
    // }
    // setUserSelections({
    //   ...userSelections,
    //   serviceScheduleDate: useDate,
    // });
  };
  return (
    <>
      {isSaving ? (
        <HostHiveLoading message={t("generalWords.saving")} elipse={true} />
      ) : (
        <form onSubmit={handleSubmit}>
          {showAlert ? (
            <ArgonAlert color={alertColor} dismissible>
              <ArgonBox display="flex" justifyContent="flex-end">
                <ArgonBox>
                  <ArgonTypography variant="caption" color="white">
                    {alertMessage}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonAlert>
          ) : null}
          <ArgonBox p={2} lineHeight={1}>
            <ArgonBox>
              {propertyInfo?.propertyName ? (
                <>{propertyInfo?.propertyName}</>
              ) : (
                <PropertySelect
                  menuPlacement="bottom"
                  property={property}
                  setProperty={setProperty}
                  companyId={company?.id}
                />
              )}
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("accounting.transactions.dialog.transactionType")}
                </ArgonTypography>
              </ArgonBox>
              <ArgonSelect
                value={transactionType}
                onChange={(e) => setTransactionType(e)}
                options={[
                  {
                    value: "income",
                    label: t("accounting.transactions.income"),
                  },
                  { value: "expense", label: t("accounting.general.expense") },
                ]}
              />
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Date
                </ArgonTypography>
              </ArgonBox>
              <ArgonDatePicker
                input={{ placeholder: "Select a date" }}
                onChange={(e) => handleServiceDateSelection(e)}
                enableTime={true}
                value={transactionDate}
              />
        
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("generalWords.summary")}
                </ArgonTypography>
              </ArgonBox>
              <ArgonInput
                name="summary"
                type="text"
                label="Item Name"
                placeholder="Linen Sheets"
                value={values.summary}
                onChange={handleChange}
                onBlur={handleBlur}
                // Add error handling from Formik state
                error={touched.summary && Boolean(errors.summary)}
                helperText={touched.summary && errors.summary}
                // onChange={(e) => console.log(e)}
              />
              {errors.summary && touched.summary && (
                // <p className="error">{errors.name}</p>
                <HostHiveErrorMessage message={errors.summary} />
              )}
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("generalWords.description")}
                </ArgonTypography>
              </ArgonBox>
              <ArgonInput
                name="description"
                type="text"
                label="Item Name"
                placeholder="Linen Sheets"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                // Add error handling from Formik state
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
                // onChange={(e) => console.log(e)}
              />
              {errors.description && touched.description && (
                // <p className="error">{errors.name}</p>
                <HostHiveErrorMessage message={errors.description} />
              )}
            </ArgonBox>
            

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {t("accounting.general.amount")}
                </ArgonTypography>
              </ArgonBox>
              <ArgonInput
                name="amount"
                type="text"
                placeholder="38.49"
                value={values.amount}
                onChange={handleChange}
                onBlur={handleBlur}
                // Add error handling from Formik state
                error={touched.amount && Boolean(errors.amount)}
                helperText={touched.amount && errors.amount}
                // onChange={(e) => console.log(e)}
              />
              {errors.amount && touched.amount && (
                // <p className="error">{errors.name}</p>
                <HostHiveErrorMessage message={errors.amount} />
              )}
            </ArgonBox>
            {/* <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
          >
            Category
          </ArgonTypography>
        </ArgonBox>
        <ArgonSelect
          defaultValue={{ value: "none", label: "Select a category" }}
          // options={companies.map((company) => ({
          //   value: company.id,
          //   label: company.name,
          // }))}
          // onChange={(e) => handleCompanySelected(e)}
        />
      </ArgonBox> */}
            <CategorySelect
              menuPlacement="top"
              categoryType="accounting"
              setCategory={setCategory}
              category={category}
              companyId={company?.id}
            />
          </ArgonBox>

          <ArgonBox justifyContent="center" display="flex" p={2}>
            <ArgonBox display="flex">
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={() => onClose()}
                >
                  {t("buttons.cancel")}
                </ArgonButton>
              </ArgonBox>
              <ArgonBox pr={2}>
                <ArgonButton variant="gradient" color="info" type="submit">
                  {t("buttons.save")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </form>
      )}
    </>
  );
}

export default NewTransactionItem;
