/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import PropertySelect from "layouts/management/components/PropertySelect";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import InventoryItem from "../InventoryItem";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
// Argon Dashboard 2 PRO MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import InventoryItemDialog from "layouts/administration/inventory/components/InventoryItemDialog";
import { Grid } from "@mui/material";
import HostHiveDataTable from "components/HostHiveDataTable";
import InventoryApi from "api/inventoryApi";

import { columns } from "layouts/administration/inventory/components/InventoryTable/components/inventoryColumns";
import { use } from "i18next";
import PropertiesApi from "api/propertiesApi";
import { property } from "lodash";
import { useTranslation } from "react-i18next";
function InventoryTable({ triggerFetch, toggleFetch }) {
  const { t } = useTranslation("common");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [openMenu, setOpenMenu] = useState(null);
  const [camera, setCamera] = useState(0);
  const [searchText, setSearchText] = useState("");
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const inventoryApi = new InventoryApi();
  const propertiesApi = new PropertiesApi();
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState([
    {
      label: t("properties.allProperties"),
      value: null,
    },
  ]);
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.md
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetCamera = (event, newCamera) => setCamera(newCamera);
  const handleOpenMenu = ({ currentTarget }) => setOpenMenu(currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const [newInventoryItemDiaglog, setNewInventoryItemDiaglog] = useState(false);
  const handleCancelClicked = () => {
    setNewInventoryItemDiaglog(false);
  };
  const handleSubmitClicked = () => {
    setNewInventoryItemDiaglog(false);
  };

  const [propertyFilter, setPropertyFilter] = useState(null);

  const [inventoryTableData, setInventoryTableData] = useState({
    columns: columns,
    rows: [
      {
        count: "",
        status: "",
        itemName: "",
        currentBatchNumber: 0,
      },
    ],
  });

  useEffect(() => {
    async function fetchProperties() {
      let queryParams = {
        limit: 100,
        companyId: company?.id,
        optionSelections: true,
      };

      try {
        const response = await propertiesApi.getProperties(queryParams);
        if (response.status >= 200 && response.status < 300) {
          //setPropertyOptions(response.data);
          setPropertyOptions((prevOptions) => [
            ...prevOptions,
            ...response.data,
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchProperties();
  }, [triggerFetch]);

  const [propertyDetails, setPropertyDetails] = useState({
    label: t("properties.allProperties"),
    value: null,
  });

  useEffect(() => {
    async function fetchInventoryItems() {
      setLoadingTableData(true);
      let queryParams = {
        limit: 100,
        companyId: company?.id,
      };
      if (propertyDetails) {
        console.log("propertyDetails", propertyDetails);
        queryParams.propertyId = propertyDetails?.value;
        try {
          const response = await inventoryApi.getInventoryItems(queryParams);
          if (response.status >= 200 && response.status < 300) {
            console.log("response inventory", response?.data);
            setInventoryTableData({
              columns: inventoryTableData?.columns,
              rows: response?.data,
            });
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        try {
          const response = await inventoryApi.getInventoryItems(queryParams);
          if (response.status >= 200 && response.status < 300) {
            console.log("response", response);
            setInventoryTableData({
              columns: inventoryTableData?.columns,
              rows: response?.data,
            });
          }
        } catch (error) {
          console.error(error);
        }
      }
      setLoadingTableData(false);
    }

    fetchInventoryItems();
  }, [propertyDetails, triggerFetch]);

  return (
    <Card>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <HostHiveDialog
          open={newInventoryItemDiaglog}
          onClose={handleCancelClicked}
          onSave={handleSubmitClicked}
          includeSave={false}
          includeClose={false}
          maxWidth="xs"
          title={`${t("buttons.add")} ${t("components.inventory.newItem")}`}
          fullScreen={false}
          cancelButtonText={t("buttons.cancel")}
          submitButtonText={t("buttons.save")}
          dialogObjects={
            <InventoryItemDialog
              handleClose={() => setNewInventoryItemDiaglog(false)}
              propertyId={propertyDetails?.value}
              fetchInventoryItems={toggleFetch}
            />
          }
        />
        {/* <ArgonTypography variant="h6">
          {propertyDetails?.name} Inventory
        </ArgonTypography> */}
      </ArgonBox>

      <Grid container spacing={3} mb={3}>
        <Grid item xs={12}>
          {/* {renderTimelineItems} */}
          <>
            {loadingTableData ? (
              <HostHiveLoading />
            ) : (
              <HostHiveDataTable
                newEntryAction={() => setNewInventoryItemDiaglog(true)}
                newEntryText={t("components.inventory.newItem")}
                newEntryColor="info"
                newEntryVariant="outlined"
                header={`${propertyDetails?.label} ${t(
                  "components.inventory.title"
                )}`}
                table={inventoryTableData}
                entriesPerPage={false}
                canSearch
                exportToCsv={() => console.log("export to csv")}
                importFromFile={() => console.log("import from file")}
                property={propertyDetails}
                setProperty={setPropertyDetails}
              />
            )}
          </>
        </Grid>
      </Grid>
    </Card>
  );
}

export default InventoryTable;
