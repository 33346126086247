/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useParams, useNavigate } from "react-router-dom";
import CompaniesApi from "api/companyApi";
// Data

import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";
import { generateColumns } from "layouts/hostHiveAdministration/customers/companyPage/components/companiesColumns";
import ArgonButton from "components/ArgonButton";
function CompanyDashboard() {
  const administrationApi = new AdministrationApi();
  const companiesApi = new CompaniesApi();
  const navigate = useNavigate();
  const [permitted, setPermitted] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [properties, setProperties] = useState([]);
  const [users, setUsers] = useState([]);
  const { companyId } = useParams();
  const [company, setCompany] = useState({});
  const attemptReachability = async () => {
    try {
      await administrationApi.getReachability();
      setPermitted(true);
      console.log("Permitted");
      getCompany();
      getProperties();
      getUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const getProperties = async () => {
    try {
      const response = await administrationApi.getProperties(companyId);
      setProperties(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      const response = await administrationApi.getUsers(companyId);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCompany = async () => {
    try {
      const response = await companiesApi.getCompany(companyId);
      setCompany(response.data);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    attemptReachability();
  }, []);

  const deleteCompany = async () => {
    try {
      await administrationApi.deleteCompany(companyId);
      navigate("/administration/customers");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {permitted && (
        <DashboardLayout>
          <ArgonBox pt={6} pb={3}>
            <Card>
              {/* Navigate back to Customer page */}
              <ArgonBox p={3} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => navigate("/administration/customers")}
                >
                  Back to Customers
                </ArgonButton>
              </ArgonBox>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  {company?.name}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox p={3} lineHeight={1}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <ArgonButton
                        variant="gradient"
                        color="error"
                        onClick={() =>
                          ///administration/customers/
                          deleteCompany()
                        }
                      >
                        Delete Company
                      </ArgonButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                        Properties
                      </ArgonTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                        {properties?.length}
                      </ArgonTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <ArgonButton
                        variant="gradient"
                        color="secondary"
                        onClick={() =>
                          ///administration/customers/
                          navigate(
                            `/administration/customers/${companyId}/properties`
                          )
                        }
                      >
                        View Properties
                      </ArgonButton>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                        Users
                      </ArgonTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <ArgonTypography variant="h6" fontWeight="medium">
                        {users.length}
                      </ArgonTypography>
                    </Grid>
                    <Grid item xs={12}>
                      <ArgonButton
                        variant="gradient"
                        color="secondary"
                        onClick={() =>
                          ///administration/customers/
                          navigate(
                            `/administration/customers/${companyId}/users`
                          )
                        }
                      >
                        View Users
                      </ArgonButton>
                    </Grid>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </ArgonBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default CompanyDashboard;
