import * as Yup from "yup";

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  companyName: Yup.string().required("Company is required"),
});

export default SignUpSchema;
