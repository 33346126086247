import { useState, useEffect, useContext } from "react";
import HostHiveCommentSection from "components/HostHiveCommentSection";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";

import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import HostHiveImages from "components/HostHiveImages";

import convertToOptions from "services/convertooptions";
import useMediaQuery from "@mui/material/useMediaQuery";
import statusBadges from "services/statusBadges";
import Menu from "@mui/material/Menu";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonTimePicker from "components/ArgonTimePicker";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";

import DialogTitle from "@mui/material/DialogTitle";

import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import BookingReportApi from "api/bookingReportApi";
function BookingReportCompletedDataCard({
  propertyId,
  companyId,
  bookingReport,
  onClose,
}) {
  const navigate = useNavigate();
  const bookingReportApi = BookingReportApi();
  const [availableServiceNames, setAvailableServiceNames] = useState([]);
  const [editorValue, setEditorValue] = useState(bookingReport?.comments || "");
  const [bookingImages, setBookingImages] = useState(
    bookingReport?.images || []
  );
  console.log("Booking report", bookingReport);
  const [openMenu, setOpenMenu] = useState(null);

  // const isAppointmentOwner = bookingPayload?.isOwner;
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleNavigateToServiceInvoicing = () => {
    navigate(`/service-invoicing/create/${bookingReport?.bookingId}`);
  };
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleNavigateToServiceInvoicing() && handleCloseMenu();
        }}
      >
        Invoice Service
      </MenuItem>
      <MenuItem
        onClick={() => navigate(`/calendar/${bookingReport?.bookingId}`)}
      >
        Open Appointment
      </MenuItem>
    </Menu>
  );

  // const selectedServicer = "Empire Cleaning";
  const [erroredPhotos, setErroredPhotos] = useState([]);
  useEffect(() => {
    console.log("Booking report", bookingReport);
  }, []);
  const uploadHandler = async (event) => {
    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      const image = uploadedImagesArray[i];
      const fileType = image.type;
      const fileName = image.name;
      console.log("image", image);
      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await bookingReportApi.updateBookingReportImages(
          bookingReport?.bookingInfo?.id,
          bookingReport?.bookingInfo?.companyId,
          bookingReport?.bookingInfo?.propertyId,
          fileName,
          fileType,
          bookingReport?.bookingReport?.summary,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setBookingImages(response.data.images);
      } catch (error) {
        console.error(error);
      }
    }

    // Update the bookingImages state with the uploaded images
  };

  const [serviceReportSummary, setServiceReportSummary] = useState(
    bookingReport?.bookingReport?.summary
  );
  const handleSummaryInput = (event) => {
    setServiceReportSummary(event.target.value);
  };

  const [listedItem, setListedItem] = useState("");
  const [checklistName, setChecklistName] = useState(
    bookingReport?.checklistInfo?.name
  );
  const [checklistItems, setChecklistItems] = useState(
    bookingReport?.checklistInfo?.checklist || []
  );
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllToggle = (checklistId) => {
    console.log(checklistId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) => ({
                ...item,
                checked: !selectAll,
              })),
            }
          : checklist
      )
    );
    setSelectAll((prev) => !prev);
  };

  const handleIndividualToggle = (checklistId, itemId) => {
    console.log(checklistId, itemId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) =>
                item.id === itemId ? { ...item, checked: !item.checked } : item
              ),
            }
          : checklist
      )
    );
  };

  const totalCheckedCount = checklistItems.reduce(
    (acc, { items }) => acc + items.filter((item) => item.checked).length,
    0
  );
  const totalItemCount = checklistItems.reduce(
    (acc, { items }) => acc + items.length,
    0
  );

  const [bookingReportStatus, setBookingReportStatus] = useState(
    bookingReport?.bookingReport?.statusSelectValue
  );
  const [imageXL, setImageXL] = useState(false);
  //const [completeReport, setCompleteReport] = useState(false);

  const handleSaveBookingReport = async (completeReport = false) => {
    const payload = {
      bookingId: bookingReport?.bookingInfo?.id,
      companyId: bookingReport?.bookingInfo?.companyId,
      propertyId: bookingReport?.bookingInfo?.propertyId,
      summary: serviceReportSummary,
      comments: editorValue,
      status: bookingReportStatus?.value,
      checklistInfo: {
        checklistId: bookingReport?.checklistInfo?.id,
        checklist: checklistItems,
      },
    };
    if (completeReport === true) {
      payload.status = "complete";
    } else {
      payload.status = "open";
    }
    console.log("-->", payload);
    try {
      const response = await bookingReportApi.upsertBookingReport(
        bookingReport?.bookingInfo?.id,
        payload
      );

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {renderMenu()}
      <ArgonBox>
        <Card>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <ArgonBox display="flex">
                <DialogTitle>Service Report</DialogTitle>

                <IconButton>
                  <Tooltip title="KB Article" placement="top">
                    <Icon>help</Icon>
                  </Tooltip>
                </IconButton>
              </ArgonBox>
            </Grid>

            <Grid
              item
              lg={6}
              sm={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ArgonBox p={2}>
                <ArgonButton
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenMenu}
                  sx={{
                    ml: "auto",
                    alignSelf: "flex-start",
                    py: 1.25,
                    pr: 1.5,
                    borderRadius: "8px",
                    borderWidth: "1px",
                    transition: "background-color 0.3s, transform 0.2s",
                    ":hover": {
                      backgroundColor: "rgba(0,0,0,0.05)",
                      transform: "scale(1.02)",
                    },
                  }}
                  startIcon={
                    <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                      settings
                    </Icon>
                  }
                >
                  Report Options
                </ArgonButton>
              </ArgonBox>
            </Grid>
          </Grid>

          <ArgonBox pl={2}>
            <div style={{ flexGrow: 1 }}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Summary
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {bookingReport?.summary}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Property
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {bookingReport?.propertyName}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Status
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <Tooltip title="Report status" placement="top" arrow>
                    <ArgonBadge
                      size="lg"
                      hhFontSize={17}
                      variant="contained"
                      color={bookingReport?.statusInfo?.color}
                      badgeContent={bookingReport?.statusInfo?.status}
                    />
                  </Tooltip>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Service Photos
                  </ArgonTypography>
                  {bookingImages.length > 0 ? (
                    <ArgonBox display="flex" pt={2}>
                      <ArgonBox>
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Photo Grid
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox pl={2}>
                        <Switch
                          checked={!imageXL}
                          onChange={() => setImageXL(!imageXL)}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  ) : (
                    <ArgonBox pl={1}>
                      <ArgonTypography component="label" variant="caption">
                        No photos uploaded
                      </ArgonTypography>
                    </ArgonBox>
                  )}

                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} xl={5}>
                      <HostHiveImages
                        images={bookingImages}
                        showNoImagesMessage={true}
                        imageGridSizeXs={imageXL ? 12 : 4}
                        imageGridSizeMd={imageXL ? 12 : 4}
                      />
                    </Grid>
                  </Grid>
                </ArgonBox>
              </ArgonBox>

              {bookingReport?.checklistId && (
                <>
                  <ArgonBox mb={1}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {checklistName ? "Checklist: " : null}
                            {checklistName}{" "}
                          </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                          {totalCheckedCount === totalItemCount ? (
                            <ArgonTypography
                              component="label"
                              variant="caption"
                              fontWeight="regular"
                              color="success"
                              sx={{ fontStyle: "italic" }}
                            >
                              All items complete
                            </ArgonTypography>
                          ) : (
                            <>
                              <ArgonTypography
                                component="label"
                                variant="caption"
                                fontWeight="regular"
                                color="warning"
                                sx={{ fontStyle: "italic" }}
                              >
                                ({totalCheckedCount}/{totalItemCount}){" "}
                                {(
                                  (totalCheckedCount / totalItemCount) *
                                  100
                                ).toFixed(2)}
                                % complete
                              </ArgonTypography>
                            </>
                          )}
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        View Checklist Items
                      </ArgonTypography>
                    </AccordionSummary>
                    <ArgonBox mb={2}>
                      <ArgonBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-end"
                        height="100%"
                      >
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          //mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          {checklistItems?.map(({ summary, id, items }) => {
                            const selectedCount = items.filter(
                              (item) => item.checked
                            ).length;
                            const totalCount = items.length;
                            return (
                              <ArgonBox
                                display="flex"
                                flexDirection="column"
                                justifyContent="flex-end"
                                height="100%"
                                pb={1}
                              >
                                <ArgonBox pl={1}>
                                  <ArgonTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="text"
                                    textTransform="uppercase"
                                  >
                                    {summary} ({selectedCount}/{totalCount})
                                  </ArgonTypography>
                                </ArgonBox>

                                {items?.map((item) => {
                                  return (
                                    <ArgonBox
                                      display="flex"
                                      justifyContent="space-between"
                                      p={1}
                                    >
                                      <ArgonBox pl={3}>
                                        <ArgonTypography
                                          component="label"
                                          variant="caption"
                                        >
                                          {item.checked ? "✅" : " "}{" "}
                                          {item.itemName}
                                        </ArgonTypography>
                                      </ArgonBox>
                                    </ArgonBox>
                                  );
                                })}
                              </ArgonBox>
                            );
                          })}
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </Accordion>
                </>
              )}

              <HostHiveCommentSection
                title="Service Comments"
                // comments={comments}
                // setComments={setComments}
                entityType="serviceAppointment"
                entityId={bookingReport?.bookingId}
              />
            </div>
          </ArgonBox>
        </Card>
      </ArgonBox>
    </>
  );
}

export default BookingReportCompletedDataCard;
