import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function GuestBookApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("properties", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getGuestBooks = (queryParams) => {
    return axiosPrivate.get(`/guestbook`, { params: queryParams });
  };

  const createGuestBook = (guestBookData) => {
    return axiosPrivate.post(`/guestbook`, guestBookData);
  };

  const getGuestBook = (guestBookId) => {
    return axiosPrivate.get(`/guestbook/${guestBookId}`);
  };
  const testIcalUrl = (guestBookData) => {
    return axiosPrivate.put(`/guestbook/test/ical-url`, guestBookData);
  };
  const upsertGuestBook = (propertyId) => {
    return axiosPrivate.put(`/guestbook/${propertyId}`);
  };

  const deleteGuestBook = (guestBookId) => {
    return axiosPrivate.delete(`/guestbook/${guestBookId}`);
  };

  return {
    getGuestBooks,
    createGuestBook,
    getGuestBook,
    upsertGuestBook,
    deleteGuestBook,
    testIcalUrl,
  };
}

export default GuestBookApi;
