/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import Badge from "@mui/material/Badge";
// react-router components
import { useLocation, Link, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { convertTimeStamp, timeAgo } from "services/dateUtils";
// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";

// Argon Dashboard 2 PRO MUI example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import Grid from "@mui/material/Grid";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
import SettingsApi from "api/settingsApi";
// Argon Dashboard 2 PRO MUI context
import {
  useArgonController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";

import { UserAuth } from "context/AuthContext";

// Images
import team2 from "assets/images/team-2.jpg";

import AlertsApi from "api/alertsApi";
import { useTranslation } from "react-i18next";
import { isNull, set } from "lodash";

function DashboardNavbar({ absolute, light, isMini }) {
  const [t, i18n] = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const settingsApi = SettingsApi();

  const [language, setLanguage] = useState(isNull);
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const navigate = useNavigate();
  const alertsApi = AlertsApi();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useArgonController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } =
    controller;
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { logout, user, subscriptionTier } = UserAuth();
  const [alerts, setAlerts] = useState([]);
  const [alertsCount, setAlertsCount] = useState(0);
  useEffect(() => {
    let currentlySelectLanguage = localStorage.getItem("language");

    // Check if the retrieved item is not null and is a string
    if (
      currentlySelectLanguage &&
      typeof currentlySelectLanguage === "string"
    ) {
      try {
        // Attempt to parse the JSON string
        currentlySelectLanguage = JSON.parse(currentlySelectLanguage);
        setLanguage(currentlySelectLanguage);
      } catch (error) {
        // Log the error if JSON parsing fails
        console.error("Error parsing language from localStorage:", error);
      }
    } else {
      // Handle the case where the language is not set in localStorage
      // For example, set a default language
      setLanguage("en"); // replace `defaultLanguage` with your default language
    }
    // const currentlySelectLanguage = JSON.parse(
    //   localStorage.getItem("language")
    // );
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    async function fetchUserAlerts() {
      try {
        const queryParams = {
          limit: 1000,
        };
        const response = await alertsApi.getAlerts(queryParams);
        if (response.status >= 200 && response.status < 300) {
          setAlerts([
            ...response.data.userAlerts,
            ...response.data.companyAlerts,
          ]);
          setAlertsCount(
            response.data.userAlerts.length + response.data.companyAlerts.length
          );
        }
        // console.log("Navbar alerts", response);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUserAlerts();

    // Set up the interval to poll fetchUserAlerts every x seconds
    const interval = setInterval(() => {
      // console.log("fetching alerts");
      fetchUserAlerts();
    }, 30 * 1000); // Replace x with the desired number of seconds

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Cleanup function to clear the interval and remove the event listener
    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleTransparentNavbar);
    };

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const [languageOpen, setLanguageOpen] = useState(false);
  const handleLanguageMenu = (event) => {
    setLanguageOpen(event.currentTarget);
  };
  const handleMessagesClicked = () => {
    navigate("/messages");
  };
  const handleNotificationOpenMenu = (event) =>
    setOpenNotificationMenu(event.currentTarget);
  const handleNotificationCloseMenu = () => setOpenNotificationMenu(false);

  const handleUserOpenMenu = (event) => setOpenUserMenu(event.currentTarget);
  const handleUserCloseMenu = () => setOpenUserMenu(false);
  const handleLanguageMenuClose = () => setLanguageOpen(false);
  const supportedLanguages = [
    {
      code: "en",
      name: t("navBar.languageMenu.options.english"),
      icon: US,
    },
    {
      code: "pt",
      name: t("navBar.languageMenu.options.portuguese"),
      icon: BR,
    },
    {
      code: "es",
      name: t("navBar.languageMenu.options.spanish"),
    },
    ,
    {
      code: "fr",
      name: t("navBar.languageMenu.options.french"),
    },
  ];
  const getAlertIcon = (alertType) => {
    if (alertType === "booking") {
      return "calendar_today";
    }
    if (alertType === "finance") {
      return "attach_money";
    }
    if (alertType === "invoice") {
      return "receipt";
    }
  };
  const handleLanguageUpdated = async (languageCode, languageName) => {
    try {
      let payload = {
        preferredLanguage: languageCode,
        companyId: company?.id,
      };
      const response = await settingsApi.updateUserLanguage(user.uid, payload);
      console.log(response);
      localStorage.setItem("language", JSON.stringify(languageCode));
      const resp = await i18n.changeLanguage(languageCode);
      console.log("resp", resp);
      if (resp) {
        const newLanguageName = supportedLanguages.find(
          (language) => language.code === languageCode
        );
        openSuccessSB(
          t("navBar.languageMenu.success.title"),
          `${t(
            "navBar.languageMenu.success.content"
          )} ${languageCode.toUpperCase()}`
        );
      }
    } catch (error) {
      console.log("error updating language");
      console.error(error);
    }
  };

  // Render the notifications menu
  const renderNotificationMenu = () => (
    <Menu
      anchorEl={openNotificationMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openNotificationMenu)}
      onClose={handleNotificationCloseMenu}
      sx={{ mt: 2 }}
    >
      {alerts.map((alert) => (
        <NotificationItem
          image={<img src={team2} alt="person" />}
          title={[`${alert.subject}`, `${alert.content}`]}
          icon={getAlertIcon(alert.alertType)}
          createdDate={alert.created}
          onClick={handleNotificationCloseMenu}
        />
      ))}
      <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
        <ArgonTypography
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={navigateToNotifications}
          //title={title}
        >
          View all
        </ArgonTypography>
      </ArgonBox>
    </Menu>
  );

  const renderLanguageMenu = () => (
    <Menu
      anchorEl={languageOpen}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(languageOpen)}
      onClose={handleLanguageMenuClose}
      sx={{ mt: 2 }}
    >
      {supportedLanguages?.map((language, key) => (
        <MenuItem
          onClick={() => {
            handleLanguageUpdated(language?.code, language?.name);
            console.log("Language Changed to", language?.code);
            setLanguageOpen(false);
          }}
        >
          <ArgonBox
            display="flex"
            alignItems="center"
            width="max-content"
            pb={2}
            pl={1}
            pr={1}
            sx={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
          >
            <ArgonBox
              component="img"
              src={language?.icon ? language?.icon : null}
              //alt={content}
              width="1.5rem"
              height="auto"
            />{" "}
            <ArgonBox display="flex" flexDirection="column" ml={3}>
              <ArgonTypography
                variant="caption"
                color="text"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {language?.name}
              </ArgonTypography>
            </ArgonBox>
            {language === language?.code ? (
              // <ArgonBadgeDot color="info" size="xs" sx={{ px: 0 }} />
              <ArgonBox
                component="i"
                display="inline-block"
                width="0.375rem"
                height="0.375rem"
                borderRadius="50%"
                bgColor="info"
                //variant={variant}
                ml={1}
              />
            ) : null}
          </ArgonBox>
        </MenuItem>
      ))}
    </Menu>
  );
  const renderUserMenu = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleUserCloseMenu}
      sx={{ mt: 2 }}
    >
      <ArgonBox display="flex" flexDirection="column" lineHeight={0} pb={2}>
        <ArgonTypography
          variant="button"
          fontWeight="bold"
          color="text"
          textTransform="capitalize"
          sx={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={navigateToMyProfile}
          //title={title}
        >
          My profile
        </ArgonTypography>
        <ArgonTypography
          variant="caption"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"

          //title={title}
        >
          {company?.name} - {userDetails?.applicationRole?.displayName}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox display="flex" flexDirection="column" lineHeight={0} pb={1}>
        <ArgonTypography
          variant="caption"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"

          // onClick={logout}
          //title={title}
        >
          Premium Subscription: {subscriptionTier === "free" ? "False" : "True"}
        </ArgonTypography>
      </ArgonBox>

      <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
        <ArgonTypography
          variant="button"
          fontWeight="regular"
          color="text"
          textTransform="capitalize"
          sx={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={logout}
          //title={title}
        >
          Log out
        </ArgonTypography>
      </ArgonBox>
    </Menu>
  );

  const navigateToNotifications = () => {
    navigate("/notifications");
  };

  const navigateToMyProfile = () => {
    navigate("/my-profile");
  };

  return (
    // <AppBar
    //   position={absolute ? "absolute" : navbarType}
    //   color="inherit"
    //   sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    // >
    //   <Toolbar sx={(theme) => navbarContainer(theme, { navbarType })}>
    //     {renderSuccessSB}
    //     <ArgonBox
    //       color={light && transparentNavbar ? "white" : "dark"}
    //       mb={{ xs: 1, md: 0 }}
    //       sx={(theme) => navbarRow(theme, { isMini })}
    //     >
    //       {/* <Breadcrumbs
    //         icon="home"
    //         title={route[route.length - 1]}
    //         route={route}
    //         light={transparentNavbar ? light : false}
    //       /> */}
    //       {/* <Icon
    //         fontSize="medium"
    //         sx={navbarDesktopMenu}
    //         onClick={handleMiniSidenav}
    //       >
    //         {miniSidenav ? "menu_open" : "menu"}
    //       </Icon> */}
    //     </ArgonBox>
    //     {isMini ? null : (
    //       <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
    //         <ArgonBox color={light ? "white" : "inherit"}>
    //           {!user?.uid ? (
    //             <Link to="/authentication/sign-in/basic">
    //               <IconButton sx={navbarIconButton} size="small">
    //                 <Icon
    //                   sx={({ palette: { dark, white } }) => ({
    //                     color:
    //                       light && transparentNavbar ? white.main : dark.main,
    //                   })}
    //                 >
    //                   account_circle
    //                 </Icon>
    //                 <ArgonTypography
    //                   variant="button"
    //                   fontWeight="medium"
    //                   color={light && transparentNavbar ? "white" : "dark"}
    //                 >
    //                   Sign in
    //                 </ArgonTypography>
    //               </IconButton>
    //             </Link>
    //           ) : null}
    //           {/* <IconButton
    //             size="small"
    //             color={light && transparentNavbar ? "white" : "dark"}
    //             sx={navbarMobileMenu}
    //             onClick={handleMiniSidenav}
    //           >
    //             <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
    //           </IconButton> */}
    //           {user?.uid ? (
    //             <IconButton
    //               sx={navbarIconButton}
    //               size="small"
    //               onClick={handleUserOpenMenu}
    //             >
    //               <Icon
    //                 sx={({ palette: { dark, white } }) => ({
    //                   color:
    //                     light && transparentNavbar ? white.main : dark.main,
    //                 })}
    //               >
    //                 account_circle
    //               </Icon>
    //             </IconButton>
    //           ) : null}

    //           <IconButton
    //             size="small"
    //             color={light && transparentNavbar ? "white" : "dark"}
    //             sx={navbarIconButton}
    //             aria-controls="notification-menu"
    //             aria-haspopup="true"
    //             variant="contained"
    //             onClick={handleNotificationOpenMenu}
    //           >
    //             <Icon>notifications</Icon>
    //           </IconButton>
    //           <IconButton
    //             size="small"
    //             color={light && transparentNavbar ? "white" : "dark"}
    //             sx={navbarIconButton}
    //             aria-controls="notification-menu"
    //             aria-haspopup="true"
    //             variant="contained"
    //             onClick={handleNotificationOpenMenu}
    //           >
    //             <Icon>mail</Icon>
    //           </IconButton>
    //           <IconButton
    //             size="small"
    //             color={light && transparentNavbar ? "white" : "dark"}
    //             sx={navbarIconButton}
    //             aria-controls="notification-menu"
    //             aria-haspopup="true"
    //             variant="contained"
    //             onClick={handleLanguageMenu}
    //           >
    //             <Icon>language</Icon>
    //           </IconButton>
    //           {renderNotificationMenu()}
    //           {renderLanguageMenu()}
    //           {renderUserMenu()}
    //         </ArgonBox>
    //       </ArgonBox>
    //     )}
    //   </Toolbar>
    // </AppBar>
    <>
      <ArgonBox sx={(theme) => navbarRow(theme, { isMini })}>
        <ArgonBox color={light ? "white" : "inherit"}>
          {!user?.uid ? (
            <Link to="/authentication/sign-in/basic">
              <IconButton sx={navbarIconButton} size="small">
                <Icon
                  sx={({ palette: { dark, white } }) => ({
                    color: light && transparentNavbar ? white.main : dark.main,
                  })}
                >
                  account_circle
                </Icon>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  color={light && transparentNavbar ? "white" : "dark"}
                >
                  Sign in
                </ArgonTypography>
              </IconButton>
            </Link>
          ) : null}
          {/* <IconButton
                size="small"
                color={light && transparentNavbar ? "white" : "dark"}
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon>{miniSidenav ? "menu_open" : "menu"}</Icon>
              </IconButton> */}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {user?.uid ? (
                <IconButton
                  sx={navbarIconButton}
                  size="small"
                  onClick={handleUserOpenMenu}
                >
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color:
                        light && transparentNavbar ? white.main : dark.main,
                    })}
                  >
                    account_circle
                  </Icon>
                </IconButton>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              {/* <IconButton
                size="small"
                color={light && transparentNavbar ? "white" : "dark"}
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleNotificationOpenMenu}
              >
                <Icon>notifications</Icon>
              </IconButton> */}
              <IconButton
                size="small"
                color={light && transparentNavbar ? "white" : "dark"}
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleNotificationOpenMenu}
              >
                <Badge
                  badgeContent={alertsCount} // displays the alertsCount state
                  color="error" // the color for the badge, 'error' typically represents red
                >
                  <Icon>notifications</Icon>
                </Badge>
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                size="small"
                color={light && transparentNavbar ? "white" : "dark"}
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleMessagesClicked}
              >
                <Icon>mail</Icon>
              </IconButton>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                size="small"
                color={light && transparentNavbar ? "white" : "dark"}
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleLanguageMenu}
              >
                <Icon>language</Icon>
              </IconButton>
            </Grid>
          </Grid>

          {renderNotificationMenu()}
          {renderLanguageMenu()}
          {renderUserMenu()}
        </ArgonBox>
      </ArgonBox>
    </>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: true,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
