/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";
import Grid from "@mui/material/Grid";

import { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { v4 as uuidv4 } from "uuid";
import ChecklistsApi from "api/checkListsApi";
import PropertyContext from "context/PropertyContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
function ChecklistItemDialog({
  companyId,
  fetchChecklists,
  onClose,
  checklistDetails,
  snackBarOpen,
  setChecklist,
}) {
  const [listedItem, setListedItem] = useState("");
  const checklistApi = new ChecklistsApi();
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation("common");
  const {
    property,
    setProperty,
    propertySettings,
    setPropertySettings,
    applicationIntegrationTypes,
    propertyServicePartners,
    companyChecklists,
    companyServiceNames,
    setCompanyServiceNames,
    setCompanyChecklists,
  } = useContext(PropertyContext);

  const [newChecklistSection, setNewChecklistSection] = useState("");
  const [newChecklistItem, setNewChecklistItem] = useState("");
  const [checklistName, setChecklistName] = useState("");
  const [checklistItems, setChecklistItems] = useState([
    // {
    //   id: "1",
    //   summary: "Kitchen",
    //   items: ["wash dishes", "clean counters", "clean stove"],
    // },
    // {
    //   id: "2",
    //   summary: "Bathroom",
    //   items: ["clean toilet", "clean sink", "clean shower"],
    // },
  ]);

  useEffect(() => {
    if (checklistDetails) {
      setChecklistName(checklistDetails?.name);
      setChecklistItems(checklistDetails?.checklist);
      setIsEdit(true);
    }
  }, []);

  const handleSaveChecklistItem = () => {
    if (listedItem) {
      setChecklistItems((prevItems) => [
        ...prevItems,
        { id: uuidv4(), summary: listedItem },
      ]);
      setListedItem(""); // Clear the input after saving
    }
  };

  const [loadingResponse, setLoadingResponse] = useState(false);

  const toggleLoading = () => setLoadingResponse((prevState) => !prevState);

  const handleSubmitClicked = async () => {
    toggleLoading();

    const commonResponseTasks = async (response, actionVerb) => {
      if (response.status >= 200 && response.status < 300) {
        if (setChecklist) {
          setChecklist({
            label: response.data.name,
            value: response.data.id,
          });
          //Update companyChecklists arrary with new checklist
          setCompanyChecklists((prevChecklists) => [
            ...(Array.isArray(prevChecklists) ? prevChecklists : []),
            response.data,
          ]);
        }
        if (fetchChecklists) {
          fetchChecklists();
        }
        if (snackBarOpen) {
          snackBarOpen(
            `${t("components.checklist.title")} ${actionVerb}`,
            `${t("generalWords.successfully")} ${actionVerb} ${t(
              "components.checklist.title"
            )} ${checklistName}`
          );
        }
        onClose();
      }
    };

    const createChecklist = async () => {
      try {
        const checklistPayload = {
          name: checklistName,
          checklist: checklistItems.map((section) => ({
            id: section.id || uuidv4(),
            items: section.items,
            summary: section.summary,
          })),
          companyId: companyId,
          status: "active",
        };
        const response = await checklistApi.createChecklist(checklistPayload);
        await commonResponseTasks(response, t("generalWords.created"));
      } catch (error) {
        console.error("Error creating checklist:", error);
      }
    };

    const updateChecklist = async () => {
      try {
        const checklistPayload = {
          name: checklistName,
          checklist: checklistItems.map((section) => ({
            id: section.id,
            items: section.items,
            summary: section.summary,
          })),
          companyId: companyId,
          status: "active",
        };
        console.log("Checklist", checklistItems);
        console.log("checklistPayload", checklistPayload);

        const response = await checklistApi.updateChecklist(
          checklistDetails.id,
          checklistPayload
        );
        await commonResponseTasks(response, t("generalWords.updated"));
      } catch (error) {
        console.error("Error updating checklist:", error);
      }
    };

    if (isEdit) {
      console.log("Editing checklist:");
      await updateChecklist();
    } else {
      console.log("Creating checklist:");
      await createChecklist();
    }
  };

  const handleUpdateClicked = async () => {
    toggleLoading();
    const checklistPayload = {
      name: checklistName,
      checklist: checklistItems,
      companyId: companyId,
      status: "active",
    };

    console.log("???", checklistPayload);
  };

  const handleDeleteChecklistSection = (id) => {
    console.log(id);
    setChecklistItems((prevItems) => {
      return prevItems.filter((item) => item.id !== id);
    });
  };

  const removeItemFromChecklistSection = (id, item) => {
    console.log(id, item);
    const newChecklistItems = [...checklistItems];
    const checklistSection = newChecklistItems.find((item) => item.id === id);
    checklistSection.items = checklistSection.items.filter(
      (checklistItem) => checklistItem !== item
    );
    setChecklistItems(newChecklistItems);
  };

  const addItemToChecklistSection = (id) => {
    console.log(id);
    const newChecklistItems = [...checklistItems];
    const checklistSection = newChecklistItems.find((item) => item.id === id);
    checklistSection.items.push(newChecklistItem);
    setChecklistItems(newChecklistItems);
    setNewChecklistItem("");
  };

  const addChecklistSection = () => {
    setChecklistItems((prevItems) => [
      ...prevItems,
      { id: uuidv4(), summary: newChecklistSection, items: [] },
    ]);
    setNewChecklistSection("");
  };

  return (
    <ArgonBox p={2} lineHeight={1}>
      <ArgonBox mb={2}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("generalWords.name")}
            </ArgonTypography>
          </ArgonBox>
          <ArgonInput
            placeholder={t("placeHolders.checklists.turnoverChecklist")}
            type="text"
            value={checklistName}
            onChange={(e) => setChecklistName(e.target.value)}
          />
        </ArgonBox>
      </ArgonBox>

      <ArgonBox mb={2}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("components.checklist.dialog.addChecklistArea")}
            </ArgonTypography>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              component="form"
              role="form"
            >
              <ArgonBox p={1}>
                <ArgonInput
                  placeholder="Kitchen"
                  type="text"
                  value={newChecklistSection}
                  onChange={(e) => setNewChecklistSection(e.target.value)}
                />
              </ArgonBox>
              <ArgonBox p={1}>
                <ArgonButton
                  disabled={newChecklistSection === ""}
                  onClick={() => addChecklistSection()}
                  type="submit"
                >
                  {t("buttons.add")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>

            {checklistItems?.map(({ summary, id, items }) => (
              <ArgonBox display="flex" justifyContent="space-between" p={1}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={9}>
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                          textTransform="uppercase"
                        >
                          {summary}
                        </ArgonTypography>
                      </Grid>
                      <Grid item xs={3}>
                        <Tooltip title="Remove" placement="top" color="error">
                          <IconButton
                            onClick={() => handleDeleteChecklistSection(id)}
                          >
                            <HighlightOffOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>

                    {/*  */}
                  </AccordionSummary>
                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    component="form"
                    role="form"
                  >
                    <ArgonBox p={1}>
                      <ArgonInput
                        placeholder={t("placeHolders.checklists.washDishes")}
                        type="text"
                        value={newChecklistItem}
                        onChange={(e) => setNewChecklistItem(e.target.value)}
                      />
                    </ArgonBox>
                    <ArgonBox p={1}>
                      <ArgonButton
                        disabled={newChecklistItem === ""}
                        onClick={() => addItemToChecklistSection(id)}
                        type="submit"
                      >
                        {t("buttons.add")}
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                  {items?.map((item) => (
                    <AccordionDetails>
                      <ArgonBox
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                      >
                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {item}
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox>
                          <Tooltip title="Remove" placement="top">
                            <IconButton
                              onClick={() =>
                                removeItemFromChecklistSection(id, item)
                              }
                            >
                              <HighlightOffOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </ArgonBox>
                      </ArgonBox>
                    </AccordionDetails>
                  ))}
                  <ArgonBox
                    display="flex"
                    justifyContent="center"
                    mt={2}
                    mb={2}
                  >
                    {/* <ArgonButton
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteChecklistSection(id)}
                    >
                      {t("buttons.delete")}
                    </ArgonButton> */}
                  </ArgonBox>
                </Accordion>
              </ArgonBox>
            ))}
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        <ArgonBox p={1}>
          <ArgonBox display="flex" justifyContent="flex-end" height="100%">
            <ArgonBox pr={2}>
              <ArgonButton variant="gradient" color="dark" onClick={onClose}>
                {t("buttons.cancel")}
              </ArgonButton>
            </ArgonBox>
            <ArgonBox pr={2}>
              <ArgonButton
                variant="gradient"
                color="info"
                onClick={handleSubmitClicked}
              >
                {isEdit ? t("buttons.update") : t("buttons.create")}
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        {/* Display role checkboxes here based on viewMode */}
      </ArgonBox>
    </ArgonBox>
  );
}

export default ChecklistItemDialog;
