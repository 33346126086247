import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
// react-router-dom components
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";

import IconButton from "@mui/material/IconButton";
// import VideoLibraryIcon from "@mui/material/icons/VideoLibrary";

import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { keyframes } from "@mui/system";
import HostHiveDialog from "components/HostHiveDialog";
import VideoEmbed from "layouts/management/components/VideoEmbed/index.js";
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

function HowToVideoButton({ videoId }) {
  const [watchVideoOpen, setWatchVideoOpen] = useState(false);

  const VideoComponent = () => {
    return (
      <HostHiveDialog
        open={watchVideoOpen}
        includeSave={false}
        includeClose={true}
        onClose={() => setWatchVideoOpen(false)}
        // onSave={handleSubmitClicked} "pcTVMbmOR9g"
        title="How to Guide"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            <VideoEmbed videoId={videoId} />
          </>
        }
      />
    );
  };

  return (
    <>
      <Tooltip title="Watch how-to guide" arrow>
        <Zoom in={true}>
          <IconButton
            sx={{
              position: "relative",
              animation: `${pulse} 2s infinite`,
              // additional styles if needed
            }}
            color="primary"
            onClick={() => setWatchVideoOpen(true)}
          >
            {/* <VideoLibraryIcon fontSize="large" /> */}
            <Icon fontSize="large">video_library_icon</Icon>
          </IconButton>
        </Zoom>
      </Tooltip>
      <VideoComponent />
    </>
  );
}

export default HowToVideoButton;
