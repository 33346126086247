/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useMemo, useEffect, useState, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
// @mui material components
import ArgonSelect from "components/ArgonSelect";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import SyncIcon from "@mui/icons-material/Sync";
import Menu from "@mui/material/Menu";
import { showDeleteConfirmation } from "services/confirmDelete";
import Swal from "sweetalert2";
import { MenuItem } from "@mui/material";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link, useNavigate } from "react-router-dom";
import AccountingApi from "api/accountingApi";
import BookingsApi from "api/bookingsApi";
// import TransactionsApi from "api/transactionsApi";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
import PlaidIntegration from "layouts/management/components/PlaidIntegration";
import { set } from "lodash";
import PlaidApi from "api/plaidApi";
import ApproveTransactions from "layouts/administration/accounting/components/ApproveTransactions";
import HostHiveLoading from "components/HostHiveLoading";

import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import BulkTransactionManagement from "layouts/administration/accounting/bulkTransactionManagement";
function IntegratedTransactions({
  title,
  date,
  setExternalFetch,
  externalFetch,
  pendingApprovalLength,
  setPendingApprovalLength,
  loadingPendingApprovalSize,
}) {
  const { t } = useTranslation("common");
  const accountingApi = new AccountingApi();
  const plaidApi = new PlaidApi();

  const [companyInstitutions, setCompanyInstitutions] = useState([]);
  // const [fetchTransactionsToApprove, setFetchTransactionsToApprove] =
  //   useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const [integrationSettingsOpen, setIntegrationSettingsOpen] = useState(false);

  // const fetchSyncStatus = async () => {
  //   const queryParams = {
  //     includeAccounts: false,
  //   };
  //   try {
  //     const response = await accountingApi.getInstitutions(company.id);
  //     console.log("getSyncStatus response", response);
  //     // setCompanyInstitutions(response.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    const fetchConnectedAccounts = async () => {
      try {
        const queryParams = {
          includeAccounts: true,
        };
        const response = await plaidApi.getInstitutions(
          queryParams,
          company.id
        );
        console.log("getInstitutions response", response);
        setCompanyInstitutions(response.data.institutions);
        // if length of response.data is < 1, then console.log("No bookings to confirm")
      } catch (error) {
        console.error(error);
      }
    };

    fetchConnectedAccounts();
  }, []);

  const [transactionsOpen, setTransactionsOpen] = useState(false);

  const handleSyncTransactions = async (companyInstitution) => {
    const queryParams = {
      companyId: company?.id,
      institutionId: companyInstitution.institutionId,
    };
    try {
      const response = await plaidApi.syncTransactions(queryParams);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteInstitution = async (companyInstitution) => {
    const queryParams = {
      companyId: company?.id,
      institutionId: companyInstitution.institutionId,
    };
    try {
      await showDeleteConfirmation(
        `Are you sure you want to Disconnect ${companyInstitution.institutionName}?`,
        "Disconnect Bank Institution",
        "Yes, Disconnect Bank Institution"
      ); // Wait for the promise to resolve/reject
      console.log(
        `User confirmed deletion! ${companyInstitution.institutionId}`
      );
      try {
        const response = await plaidApi.deleteInstitution(queryParams);
        console.log("deleteInstitution response", response);

        setCompanyInstitutions(
          companyInstitutions.filter(
            (institution) =>
              institution.institutionId !== companyInstitution.institutionId
          )
        );
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.log("User cancelled deletion!");
      return;
    }
  };

  const [openMenu, setOpenMenu] = useState(null);
  const [workingInstitution, setWorkingInstitution] = useState(null);
  const handleOpenMenu = (event, companyInstitution) => {
    setWorkingInstitution(companyInstitution);
    setOpenMenu(event.currentTarget);
  };
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* <PlaidIntegration buttonOnly={true} closeMenu={handleCloseMenu} /> */}
      <MenuItem
        onClick={() => {
          handleSyncTransactions(workingInstitution);
          handleCloseMenu();
        }}
      >
        Sync Transactions
      </MenuItem>

      <MenuItem
        onClick={() => {
          handleDeleteInstitution(workingInstitution);
          handleCloseMenu();
        }}
      >
        Disconnect Integration
      </MenuItem>
    </Menu>
  );

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <ArgonBox>
          {/* <ArgonButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleCreateBookingClicked}
          >
            + {t("serviceAssignments.buttons.addNewAssignment")}
          </ArgonButton> */}
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={() => setIntegrationSettingsOpen(true)}
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
              pr: 1.5,
              borderRadius: "8px",
              borderWidth: "1px",
              transition: "background-color 0.3s, transform 0.2s",
              ":hover": {
                backgroundColor: "rgba(0,0,0,0.05)",
                transform: "scale(1.02)",
              },
            }}
            startIcon={
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                account_balance
              </Icon>
            }
          >
            Connected Accounts
          </ArgonButton>
        </ArgonBox>
        <HostHiveDialog
          open={integrationSettingsOpen}
          fullWidth={true}
          // onSave={handleSubmit}
          onClose={() => setIntegrationSettingsOpen(false)}
          includeSave={false}
          includeClose={true}
          maxWidth="md"
          title="Banking Integration Settings"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          submitButtonText="Approve Selected"
          showStatusBadge={false}
          dialogObjects={
            <>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <PlaidIntegration
                    buttonOnly={true}
                    setCompanyInstitutions={setCompanyInstitutions}
                  />
                </Grid>

                <Grid item xs={12}>
                  {companyInstitutions?.map((companyInstitution) => (
                    <ArgonBox
                      component="div"
                      display="flex"
                      flexDirection="column"
                      p={2}
                      m={0}
                      key={companyInstitution.institutionId}
                    >
                      <ArgonBox>
                        {/* <ArgonBox>{booking?.title}</ArgonBox> */}

                        <ArgonBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <ArgonBox pl={2} display="flex" alignItems="center">
                            <ArgonBox display="flex" flexDirection="column">
                              <ArgonTypography variant="h5" fontWeight="bold">
                                {companyInstitution?.institutionName}
                              </ArgonTypography>
                              {/* <ArgonTypography variant="caption" color="text">
                                {connectedAccount?.subtype}
                              </ArgonTypography> */}
                              <ArgonBox>
                                {companyInstitution.accounts.map((account) => (
                                  <ArgonBox key={account.id} pl={2}>
                                    <ArgonTypography
                                      variant="caption"
                                      color="text"
                                    >
                                      {`${account.name} - ${account.mask}`}
                                    </ArgonTypography>
                                  </ArgonBox>
                                ))}
                              </ArgonBox>
                              <ArgonBox key={companyInstitution.id} pl={2}>
                                <ArgonTypography variant="caption" color="text">
                                  Last Sync:{" "}
                                  {companyInstitution?.lastSync
                                    ? convertTimeStamp(
                                        companyInstitution.lastSync,
                                        "America/New_York"
                                      )
                                    : null}
                                </ArgonTypography>
                              </ArgonBox>
                            </ArgonBox>
                          </ArgonBox>

                          <ArgonBox display="flex">
                            {renderMenu(companyInstitution)}

                            <ArgonBox>
                              <ArgonButton
                                onClick={(event) =>
                                  handleOpenMenu(event, companyInstitution)
                                }
                                variant="outlined"
                                color="secondary"
                              >
                                Edit
                              </ArgonButton>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 1,
                          }}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  ))}
                </Grid>
              </Grid>
            </>
          }
        />

        <HostHiveDialog
          open={transactionsOpen}
          includeDelete={false}
          fullWidth={true}
          onClose={() => setTransactionsOpen(false)}
          includeSave={false}
          includeClose={true}
          maxWidth="lg"
          title="Imported Transactions"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          showStatusBadge={false}
          dialogObjects={
            <BulkTransactionManagement
              inDialog={true}
              handleClose={() => setTransactionsOpen(false)}
            />
          }
        />
      </ArgonBox>
      <ArgonBox display="flex" justifyContent="center" mt={1}>
        <ArgonTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Imported Transactions
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        {loadingPendingApprovalSize ? (
          <ArgonBox p={2}>
            <HostHiveLoading
              message="Loading New Imported Transactions"
              elipse={true}
            />
          </ArgonBox>
        ) : pendingApprovalLength > 0 ? (
          <ArgonBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            <ArgonBox display="flex" justifyContent="center" mt={2}>
              <ArgonTypography variant="h6" color="text" fontWeight="bold">
                Pending Approval: {pendingApprovalLength}
              </ArgonTypography>
              <ArgonBox pl={2}>
                <ArgonButton
                  onClick={() => setTransactionsOpen(true)}
                  color="primary"
                  variant="outlined"
                  disabled={pendingApprovalLength < 1}
                >
                  {t("buttons.open")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        ) : (
          <>
            <ArgonBox display="flex" justifyContent="center" mt={2}>
              <ArgonButton color="primary" variant="outlined">
                No Transactions to Approve
              </ArgonButton>
            </ArgonBox>
          </>
        )}
      </ArgonBox>
    </Card>
  );
}

export default IntegratedTransactions;
