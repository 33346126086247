/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
// @mui material components
import ArgonAlert from "components/ArgonAlert";
import ArgonSelect from "components/ArgonSelect";
import HostHiveLoading from "components/HostHiveLoading";
import InventoryApi from "api/inventoryApi";
import Checkbox from "@mui/material/Checkbox";
import BookingsApi from "api/bookingsApi";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonDatePicker from "components/ArgonDatePicker";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonButton from "components/ArgonButton";

import CategorySelect from "layouts/management/components/CategorySelect";
import PropertySelect from "layouts/management/components/PropertySelect";
import InventoryUsageSchema from "schemas/inventoryUsageSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import { useTranslation } from "react-i18next";
import { Check, CheckBox } from "@mui/icons-material";
function InventoryUsageDialog({
  handleClose,
  inventoryItem,
  propertyId = null,
  fetchInventoryItems,
}) {
  const { t } = useTranslation("common");
  const bookingsApi = new BookingsApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const inventoryApi = new InventoryApi();
  const [alertColor, setAlertColor] = useState("error");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [category, setCategory] = useState({});
  const [accountingCategory, setAccountingCategory] = useState({});
  const [property, setProperty] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null);
  const [startFilterStage, setStartFilterStage] = useState(null);
  const [endFilterStage, setEndFilterStage] = useState(null);
  const [assignedToProperty, setAssignedToProperty] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [bookingUsage, setBookingUsage] = useState(true);
  const [bookingUsageInfo, setBookingUsageInfo] = useState({
    label: "Select Appointment",
    value: null,
  });
  const [bookingOptions, setBookingOptions] = useState([]);
  useEffect(() => {
    if (property?.value) {
      setProperty({
        id: property?.value,
        name: property?.label,
      });
    }
  }, [property]);

  useEffect(() => {
    if (category?.value) {
      console.log(category);
      setProperty({
        id: category?.value,
        name: category?.label,
      });
    }
  }, [category]);
  useEffect(() => {
    async function fetchLatestPropertyBookings() {
      try {
        let queryParams = {
          propertyId: property?.id,
          companyId: company?.id,
          limit: 5,
        };
        if (startDate) {
          queryParams.startDate = startDate;
        }
        if (endDate) {
          queryParams.endDate = endDate;
        }
        const response = await bookingsApi.getBookings(queryParams);
        console.log(response.data);
        if (response.status >= 200 && response.status < 300) {
          const bookings = response.data;
          const bookingOptions = bookings.map((booking) => {
            return {
              label: `${booking.serviceScheduleDate} ${booking.title}`,
              value: booking.id,
            };
          });

          bookingOptions.push({
            label: "*Search for Appointment",
            value: "search",
          });

          setBookingOptions(bookingOptions);
          setBookingUsageInfo(bookingOptions[0]);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (property?.id) {
      fetchLatestPropertyBookings();
    }
  }, [property, startDate, endDate]);
  const DisplayEntry = ({ entryKey }) => {
    return (
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}
        </ArgonTypography>
      </ArgonBox>
    );
  };
  const onSubmit = async (values, actions) => {
    // if (!propertyId) {
    //   if (assignedToProperty) {
    //     values.propertyId = property?.id;
    //   } else {
    //     delete values.propertyId;
    //   }
    // } else {
    //   values.propertyId = propertyId;
    // }
    if (!property?.id) {
      setAlertColor("error");
      setAlertMessage("Please select a property to assign this usage to.");
      setShowAlert(true);
    } else {
      values.propertyId = property?.id;
    }

    if (bookingUsage) {
      if (bookingUsageInfo?.value) {
        values.bookingId = bookingUsageInfo?.value;
      } else {
        setAlertColor("error");
        setAlertMessage("Please select a booking to assign this usage to.");
        setShowAlert(true);
      }
    }
    let params = {};
    if (applyTransactionToProperty) {
      console.log(accountingCategory);
      if (accountingCategory?.value) {
        params.accountingCategoryId = accountingCategory?.value;
      } else {
        setAlertColor("error");
        setAlertMessage("Please select a category to assign this usage to.");
        setShowAlert(true);
      }
    }

    console.log(params);

    let updatedUsageAmount = parseFloat(values.usageAmount);
    if (updatedUsageAmount > 0) {
      values.usageAmount = updatedUsageAmount * -1;
    } else {
      values.usageAmount = updatedUsageAmount;
      console.log("updatedUsageAmount", updatedUsageAmount);
    }

    if (values.comment == "") {
      delete values.comment;
    }

    setIsSaving(true);
    try {
      console.log(values);
      const response = await inventoryApi.createInventoryUsage(values, params);
      if (response.status >= 200 && response.status < 300) {
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
      setAlertColor("error");
      setAlertMessage(`Error saving inventory usage: ${error.message}`);
      setShowAlert(true);
    }
    if (fetchInventoryItems) {
      fetchInventoryItems();
    }
    setIsSaving(false);
    handleClose();
    setProperty({});
    actions.resetForm();
  };
  const [bookingSearchOpen, setBookingSearchOpen] = useState(false);
  const [applyTransactionToProperty, setApplyTransactionToProperty] =
    useState(true);
  const handleBookingSelected = (event) => {
    if (event.value == "search") {
      setBookingSearchOpen(true);
    } else {
      setBookingUsageInfo(event);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      comment: null,
      propertyId: inventoryItem?.propertyId || null,
      companyId: inventoryItem?.companyId,
      inventoryId: inventoryItem?.id,
      usageAmount: 1,
      bookingId: inventoryItem?.bookingId || null,
    },
    //validationSchema: InventoryUsageSchema,
    onSubmit,
  });

  const handleChangeFilterDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (setStartDate && setEndDate) {
      if (param === "startDate") {
        setStartFilterStage(useDate);
      } else {
        setEndFilterStage(useDate);
      }
    }
  };

  const handleApplyFilterDate = () => {
    if (setStartDate && setEndDate) {
      setStartDate(startFilterStage);
      setEndDate(endFilterStage);
      setBookingSearchOpen(false);
    }
  };

  return (
    <>
      {isSaving ? (
        <>
          <ArgonBox display="flex">
            <ArgonTypography variant="caption">
              {t("adverbs.saving")} {t("components.inventory.usage.title")}
            </ArgonTypography>
            <HostHiveLoading />
          </ArgonBox>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <>
            <HostHiveDialog
              open={bookingSearchOpen}
              onClose={() => setBookingSearchOpen(false)}
              onSave={handleApplyFilterDate}
              fullWidth={true}
              includeClose={true}
              includeSave={true}
              maxWidth="md"
              title="Search Appointment"
              fullScreen={false}
              cancelButtonText={t("buttons.cancel")}
              submitButtonText={t("buttons.apply")}
              dialogObjects={
                // <Grid container spacing={2} alignItems="center">
                //   <Grid item xs={12}>
                //     <PropertySelect
                //       property={property}
                //       setProperty={setProperty}
                //       filterSelection={true}
                //     />
                //   </Grid>
                // </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <ArgonBox lineHeight={1}>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        color="text"
                        textTransform="capitalize"
                      >
                        From Date
                      </ArgonTypography>
                      <ArgonDatePicker
                        input={{ placeholder: "Select a date" }}
                        onChange={(e) => handleChangeFilterDate(e, "startDate")}
                      />
                    </ArgonBox>
                    <ArgonBox lineHeight={1}>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        color="text"
                        textTransform="capitalize"
                      >
                        To Date
                      </ArgonTypography>
                      <ArgonDatePicker
                        input={{ placeholder: "Select a date" }}
                        onChange={(e) => handleChangeFilterDate(e, "endDate")}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              }
            />
            {showAlert ? (
              <ArgonAlert color={alertColor} dismissible>
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {alertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry
                    entryKey={t("components.inventory.usage.usageAmount")}
                  />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="usageAmount"
                      type="number"
                      placeholder="1"
                      value={values.usageAmount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.usageAmount && Boolean(errors.usageAmount)}
                      helperText={touched.usageAmount && errors.usageAmount}
                    />
                  </ArgonBox>
                  {errors.usageAmount && touched.usageAmount && (
                    <HostHiveErrorMessage message={errors.usageAmount} />
                  )}
                </ArgonBox>
              </Grid>
              {/* <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry entryKey="Price" />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="price"
                      placeholder="12.00"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.price && Boolean(errors.price)}
                      helperText={touched.price && errors.price}
                    />
                  </ArgonBox>
                  {errors.price && touched.price && (
                    <HostHiveErrorMessage message={errors.price} />
                  )}
                </ArgonBox>
              </Grid> */}
              <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("generalWords.comment")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="comment"
                      placeholder="Used for Booking"
                      value={values.comment}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.comment && Boolean(errors.comment)}
                      helperText={touched.comment && errors.comment}
                    />
                  </ArgonBox>
                  {errors.comment && touched.comment && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.comment} />
                  )}
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <ArgonBox>
                  <PropertySelect
                    menuPlacement="top"
                    property={property}
                    setProperty={setProperty}
                    companyId={inventoryItem?.companyId}
                  />
                </ArgonBox>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <ArgonBox pb={1} pt={1}>
                  {property?.id ? (
                    <>
                      <ArgonBox display="flex">
                        <Checkbox
                          checked={bookingUsage}
                          onChange={() => setBookingUsage(!bookingUsage)}
                        />

                        <DisplayEntry entryKey="Apply Usage to Appointment?" />
                      </ArgonBox>
                      {bookingUsage ? (
                        <ArgonSelect
                          disabled={true}
                          value={bookingUsageInfo}
                          onChange={(e) => handleBookingSelected(e)}
                          options={bookingOptions}
                        />
                      ) : null}
                    </>
                  ) : null}
                </ArgonBox>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <ArgonBox>
                  {property?.id ? (
                    <>
                      <ArgonBox display="flex">
                        <Checkbox
                          checked={applyTransactionToProperty}
                          onChange={() =>
                            setApplyTransactionToProperty(
                              !applyTransactionToProperty
                            )
                          }
                        />
                        <DisplayEntry entryKey="Record usage amount in finance?" />
                      </ArgonBox>
                      {applyTransactionToProperty ? (
                        <CategorySelect
                          menuPlacement="top"
                          categoryType="accounting"
                          setCategory={setAccountingCategory}
                          category={accountingCategory}
                          companyId={company?.id}
                        />
                      ) : null}
                    </>
                  ) : null}
                </ArgonBox>
              </Grid>
            </Grid>

            <ArgonBox justifyContent="center" display="flex" p={2}>
              <ArgonBox display="flex">
                <ArgonBox pr={2}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={handleClose}
                  >
                    {t("buttons.cancel")}
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox pr={2}>
                  <ArgonButton variant="gradient" color="info" type="submit">
                    {t("buttons.save")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </>
        </form>
      )}
    </>
  );
}

export default InventoryUsageDialog;
