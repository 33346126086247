/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import AdministrationApi from "api/administrationApi";
import { IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonInput from "components/ArgonInput";
import Grid from "@mui/material/Grid";
import ArgonButton from "components/ArgonButton";
function ActionCell({ id, details, triggerFetch }) {
  const administrationApi = new AdministrationApi();
  const [editCompanyOpen, setEditCompanyOpen] = useState(false);
  const navigate = useNavigate();
  const deleteCompany = async () => {
    try {
      // await administrationApi.deleteCompany(id);
      console.log("Deleted", id);
      // navigate("/administration/customers");
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [companyName, setCompanyName] = useState(details.name);
  const [companyEmail, setCompanyEmail] = useState(details.email);

  const handleSaveCompanyName = async () => {
    try {
      await administrationApi.updateCompanyName(id, companyName);
      console.log("Updated", id);
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveCompanyEmail = async () => {
    try {
      await administrationApi.updateCompanyEmail(id, companyEmail);
      console.log("Updated", id);
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownGradeCompany = async () => {
    try {
      await administrationApi.downgradeCompany(id);
      console.log("Downgraded", id);
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const naviageToCompany = () => {
    navigate(`/administration/customers/${id}`);
  };
  const navigateToStripe = () => {
    let environment = details.environment;
    let stripeUrl;
    if (environment === "dev") {
      stripeUrl = `https://dashboard.stripe.com/test/customers/${details.customerPaymentId}`;
    } else {
      stripeUrl = `https://dashboard.stripe.com/customers/${details.customerPaymentId}`;
    }
    // open in new tab
    window.open(stripeUrl, "_blank");
  };

  //https://dashboard.stripe.com/customers/cus_QDOyswsgHDYJIm
  return (
    <>
      <HostHiveDialog
        open={editCompanyOpen}
        onClose={() => setEditCompanyOpen(false)}
        // onSave={() => updateUserEmail()}
        includeSave={false}
        includeClose={true}
        title="Update Company"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ArgonBox
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonTypography variant="h6" color="secondary">
                      View {details.name} Company Page
                    </ArgonTypography>
                    <ArgonBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ArgonButton
                        variant="gradient"
                        color="info"
                        onClick={() => naviageToCompany()}
                      >
                        Go
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonTypography variant="h6" color="secondary">
                      View customer on Stripe
                    </ArgonTypography>
                    <ArgonBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ArgonButton
                        variant="gradient"
                        color="info"
                        onClick={() => navigateToStripe()}
                      >
                        Go
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonTypography variant="h6" color="secondary">
                      Company Name
                    </ArgonTypography>
                    <ArgonBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ArgonBox>
                        <ArgonInput
                          fullWidth
                          label="New Name"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </ArgonBox>
                      <ArgonBox>
                        <ArgonButton
                          variant="gradient"
                          color="secondary"
                          onClick={() => handleSaveCompanyName()}
                        >
                          Save
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonTypography variant="h6" color="secondary">
                      Company Email
                    </ArgonTypography>
                    <ArgonBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ArgonBox>
                        <ArgonInput
                          fullWidth
                          label="New Email"
                          value={companyEmail}
                          onChange={(e) => setCompanyEmail(e.target.value)}
                        />
                      </ArgonBox>
                      <ArgonBox>
                        <ArgonButton
                          variant="gradient"
                          color="secondary"
                          onClick={() => handleSaveCompanyEmail()}
                        >
                          Save
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  <ArgonBox>
                    <ArgonTypography variant="h6" color="secondary">
                      Downgrade to Free
                    </ArgonTypography>
                    <ArgonBox
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <ArgonButton
                        variant="gradient"
                        color="info"
                        onClick={() => handleDownGradeCompany()}
                      >
                        Downgrade
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </>
        }
      />
      <ArgonBox display="flex" alignItems="center">
        {/* <ArgonTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="View Company" placement="top">
            <IconButton
              onClick={() => navigate(`/administration/customers/${id}`)}
            >
              <Icon>visibility</Icon>
            </IconButton>
          </Tooltip>
        </ArgonTypography> */}
        <ArgonBox mx={2}>
          <ArgonTypography
            variant="body1"
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip title="Edit Company" placement="top">
              <IconButton onClick={() => setEditCompanyOpen(true)}>
                <Icon>edit</Icon>
              </IconButton>
            </Tooltip>
          </ArgonTypography>
        </ArgonBox>
        <ArgonTypography
          variant="body1"
          color="secondary"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title="Delete Company" placement="left">
            <IconButton onClick={() => deleteCompany()}>
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
        </ArgonTypography>
      </ArgonBox>
    </>
  );
}

export default ActionCell;
