// API client for /notes endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function NotesApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized(
    "propertiesPlus",
    role,
    subscriptionTier
  );

  if (!isAuthorized) {
    return null;
  }

  const getNotes = (queryParams) => {
    return axiosPrivate.get(`/notes`, { params: queryParams });
  };

  const createNote = (noteData) => {
    return axiosPrivate.post(`/notes`, noteData);
  };

  const getNote = (noteId) => {
    return axiosPrivate.get(`/notes/${noteId}`);
  };

  const updateNote = (noteId, noteData) => {
    console.log(noteData);
    return axiosPrivate.put(`/notes/${noteId}`, noteData);
  };

  const deleteNote = (noteId) => {
    return axiosPrivate.delete(`/notes/${noteId}`);
  };

  return {
    getNotes,
    createNote,
    getNote,
    updateNote,
    deleteNote,
  };
}

export default NotesApi;
