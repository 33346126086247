import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function SubscriptionsApi() {
  const axiosPrivate = useAxiosPrivate();

  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();
  // const isAuthorized = isRouteAuthorized("company", role, subscriptionTier);

  // if (!isAuthorized) {
  //   console.log("Unauthorized", role, subscriptionTier, user, pending);
  //   return null;
  // }
  const baseUrl = "/subscriptions";
  const getCompanies = (queryParams) => {
    return axiosPrivate.get(`${baseUrl}`, { params: queryParams });
  };

  const createCheckoutSession = (companyId, subscriptionType) => {
    return axiosPrivate.post(
      `${baseUrl}/checkout-session/${companyId}?subscriptionType=${subscriptionType}`
    );
  };

  ///invoices/{company_id}

  const getCompanyInvoices = (companyId, queryParams) => {
    return axiosPrivate.get(`${baseUrl}/invoices/${companyId}`, {
      params: queryParams,
    });
  };

  const addItemToSubscription = (companyId) => {
    return axiosPrivate.put(`${baseUrl}/item/${companyId}/add`);
  };

  const removeItemFromSubscription = (companyId) => {
    return axiosPrivate.put(`${baseUrl}/item/${companyId}/remove`);
  };

  const activateSubscription = (companyId) => {
    return axiosPrivate.put(`${baseUrl}/${companyId}/start`);
  };

  return {
    createCheckoutSession,
    removeItemFromSubscription,
    addItemToSubscription,
    activateSubscription,
    getCompanyInvoices,
  };
}

export default SubscriptionsApi;
