/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import Completion from "layouts/management/bookingReports/propertyBookingReportList/components/Progress";

import { useNavigate } from "react-router-dom";

export const columns = [
  {
    Header: "Summary",
    accessor: "summary",
    Cell: ({ value }) => {
      const [name, data] = value;
      const navigate = useNavigate();
      return (
        <DefaultCell
          value={typeof value === "string" ? value : name}
          sxValue={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={() => navigate(`${data.reportUrl}`)}
        />
      );
    },
  },

  {
    Header: "Service Date",
    accessor: "serviceScheduleDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "status",
    accessor: "statusInfo",
    Cell: ({ value }) => {
      let status;
      let statusText;

      status = (
        <StatusCell
          icon={value?.icon}
          color={value?.color}
          status={value?.status}
        />
      );

      return status;
    },
  },
  {
    Header: "Progress",
    accessor: "progress",
    Cell: ({ value }) => {
      let status;
      let statusText;

      status = value ? (
        <Completion value={value?.value} color={value?.color} />
      ) : null;

      return status;
    },
  },
  {
    Header: "Property",
    accessor: "propertyInfo",
    Cell: ({ value }) => <DefaultCell value={value?.name} />,
  },
  {
    Header: "Servicer",
    accessor: "assigneeName",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  //   {
  //     Header: "product",
  //     accessor: "product",
  //     Cell: ({ value }) => {
  //       const [name, data] = value;

  //       return (
  //         <DefaultCell
  //           value={typeof value === "string" ? value : name}
  //           suffix={data.suffix || false}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     Header: "revenue",
  //     accessor: "revenue",
  //     Cell: ({ value }) => <DefaultCell value={value} />,
  //   },
];

export const smColumns = [
  {
    Header: "Summary",
    accessor: "summary",
    Cell: ({ value }) => {
      const [name, data] = value;
      const navigate = useNavigate();
      return (
        <DefaultCell
          value={typeof value === "string" ? value : name}
          sxValue={{
            ":hover": { textDecoration: "underline" },
            cursor: "pointer",
          }}
          onClick={() => navigate(`${data.reportUrl}`)}
        />
      );
    },
  },

  {
    Header: "Service Date",
    accessor: "serviceScheduleDate",
    Cell: ({ value }) => <DefaultCell value={value} />,
  },
  {
    Header: "status",
    accessor: "status",
    Cell: ({ value }) => {
      let status;

      if (value === "complete") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="check"
            color="success"
            status="Service Complete"
          />
        );
      } else if (value === "open") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="loop"
            color="warning"
            status="In Progress"
          />
        );
      } else if (value === "pendingApproval") {
        status = (
          <StatusCell
            includeStatusText={false}
            icon="approval"
            color="warning"
            status="Pending Approval"
          />
        );
      } else {
        status = null;
      }

      return status;
    },
  },
  // ,
  // {
  //   Header: "Servicer",
  //   accessor: "assigneeName",
  //   Cell: ({ value }) => <DefaultCell value={value} />,
  // },
  //   {
  //     Header: "product",
  //     accessor: "product",
  //     Cell: ({ value }) => {
  //       const [name, data] = value;

  //       return (
  //         <DefaultCell
  //           value={typeof value === "string" ? value : name}
  //           suffix={data.suffix || false}
  //         />
  //       );
  //     },
  //   },
  //   {
  //     Header: "revenue",
  //     accessor: "revenue",
  //     Cell: ({ value }) => <DefaultCell value={value} />,
  //   },
];
