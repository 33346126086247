/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import { useNavigate } from "react-router-dom";
import PriorityCell from "layouts/administration/taskCenter/components/PriorityCell";
import ArgonBadge from "components/ArgonBadge";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import { use } from "i18next";
import i18n from "i18next";
export const columns = [
  {
    Header: i18n.t("general.columns.name", { ns: "common" }),
    accessor: "itemNameInfo",
    Cell: ({ value }) => {
      const navigate = useNavigate();
      if (value) {
        const [name, data] = value;

        return (
          <DefaultCell
            value={typeof value === "string" ? value : name}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => navigate(`${data.itemUrl}`)}
          />
        );
      }
      return;
    },
  },

  // {
  //   Header: "status",
  //   accessor: "statusInfo",
  //   Cell: ({ value }) => {
  //     let status;
  //     let statusText;

  //     status = (
  //       // <StatusCell
  //       //   icon={value?.icon}
  //       //   color={value?.color}
  //       //   status={value?.status}
  //       // />
  //       <ArgonBadge
  //         color={value?.color}
  //         size="xs"
  //         badgeContent={value?.status}
  //         container
  //       />
  //     );

  //     return status;
  //   },
  // },
  {
    Header: i18n.t("general.columns.count", { ns: "common" }),
    accessor: "count",
    Cell: ({ value }) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: i18n.t("properties.property", { ns: "common" }),
    accessor: "propertyInfo",
    Cell: ({ value }) => {
      return <DefaultCell value={value?.name} />;
    },
  },
  {
    Header: i18n.t("components.category.title", { ns: "common" }),
    accessor: "categoryInfo",
    Cell: ({ value }) => {
      return (
        <ArgonBadge
          variant="contained"
          container
          badgeContent={value?.categoryName}
          color={value?.categoryColor}
        />
      );
    },
  },
];
