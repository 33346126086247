/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// import {
//   Card,
//   Grid,
//   Tooltip,
//   Icon,
//   FormControl,
//   Select,
//   MenuItem,
//   Divider,
//   TextareaAutosize,
// } from "@mui/material";
// @mui material components
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";

import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import ViewTransactionItem from "layouts/administration/accounting/components/ViewTransactionItem";
import Grid from "@mui/material/Grid";

// import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
// Custom styles for ComplexProjectCard
function ListEventCard({ booking, handleBookingClicked }) {
  const [viewTransactionItemDiaglog, setViewTransactionItemDiaglog] =
    useState(false);
  //handleBookingClicked(null, rest?.appointmentId)
  const viewEvent = () => {
    handleBookingClicked(null, booking?.id);
  };
  return (
    <>
      <Card
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
          backgroundColor: "background.paper",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={() => viewEvent()}
      >
        <ArgonBox p={2} ml={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ArgonTypography
                variant="h5"
                fontWeight="medium"
                sx={{
                  color: "info.main",
                  textTransform: "capitalize",
                  //   ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() => viewEvent()}
              >
                {booking.serviceName}
              </ArgonTypography>
            </Grid>
            <Grid item xs={12}>
              <ArgonBadge
                variant="contained"
                container
                badgeContent={booking.statusInfo?.text}
                color={booking.statusInfo?.color}
              />
            </Grid>
            <Grid item xs={12}>
              <ArgonBox display="flex" alignItems="center">
                <ArgonBox mr={2}>
                  <Icon fontSize="default">access_alarm</Icon>
                </ArgonBox>
                <ArgonTypography
                  variant="body1"
                  fontWeight="regular"
                  color="text.primary"
                >
                  {`${booking.serviceScheduleDate} - ${
                    booking.serviceScheduleTime || "10:00 AM"
                  }`}
                </ArgonTypography>
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              <ArgonBox display="flex" alignItems="center">
                <ArgonBox mr={2}>
                  <Icon fontSize="default">person</Icon>
                </ArgonBox>
                <ArgonTypography
                  variant="body1"
                  fontWeight="regular"
                  color="text.primary"
                >
                  {booking.assignedCompany}
                </ArgonTypography>
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              <ArgonBox display="flex" alignItems="center">
                <ArgonBox mr={2}>
                  <Icon fontSize="default">location_on</Icon>
                </ArgonBox>

                <ArgonTypography
                  variant="body1"
                  fontWeight="regular"
                  color="text.primary"
                >
                  {`${booking.propertyAddress.address1}, ${booking.propertyAddress.city}`}
                </ArgonTypography>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
    </>
  );
}

export default ListEventCard;
