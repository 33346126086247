/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import convertToSelector from "services/selector";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonProgress from "components/ArgonProgress";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Argon Dashboard 2 PRO MUI example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HostHiveImages from "components/HostHiveImages";
// Argon Dashboard 2 PRO MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import NotesDataCard from "layouts/management/components/Notes";
import DocumentsDataCard from "layouts/management/components/Documents";
import PropertyContext from "context/PropertyContext";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import ArgonSelect from "components/ArgonSelect";
import OccupancyStatusDialog from "layouts/management/components/OccupancyStatusDialog";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";
import TasksApi from "api/propertyTasksApi";
import ContactSupportApi from "api/contactSupportApi";

function Header({ title, subtitle, supportItem, fetchSupportItem }) {
  const { t } = useTranslation("common");
  const tasksApi = new TasksApi();
  const contactSupportApi = new ContactSupportApi();
  const [taskStatusSelect, setTaskStatusSelect] = useState({
    label: (
      <ArgonBadge
        color={supportItem?.statusInfoSelect?.color}
        size="xs"
        badgeContent={t(
          `components.tasks.status.${supportItem?.statusInfoSelect?.value}`
        )}
        container
      />
    ),
    value: supportItem?.statusInfoSelect?.value,
  });
  const statusOptions = supportItem?.statusInfoOptions?.map((status) => {
    return {
      label: (
        <ArgonBadge
          color={status?.color}
          size="xs"
          badgeContent={t(`components.tasks.status.${status?.value}`)}
          container
        />
      ),
      value: status?.value,
    };
  });

  const [savingStatusChange, setSavingStatusChange] = useState(false);
  const handleTaskStatusSelectChange = async (event) => {
    setSavingStatusChange(true);
    let value = event.value;

    try {
      const response = await tasksApi.updateTaskStatus(supportItem?.id, value);

      let statusInfo = supportItem?.statusInfoOptions?.find(
        (status) => status.value == value
      );

      console.log("statusInfo", statusInfo);
      setTaskStatusSelect({
        label: (
          <ArgonBadge
            color={statusInfo?.color}
            size="xs"
            badgeContent={t(`components.tasks.status.${statusInfo?.value}`)}
            container
          />
        ),
        value: statusInfo?.value,
      });
    } catch (error) {
      console.error(error);
    }
    setSavingStatusChange(false);
  };
  const resolvedStatus = "resolved";
  const resolveTicket = async () => {
    console.log("resolve ticket");
    try {
      const response = await contactSupportApi.resolveSupportTicket(
        supportItem?.id
      );
    } catch (error) {
      console.error(error);
    }
    if (fetchSupportItem) {
      fetchSupportItem();
    }
  };

  return (
    <ArgonBox position="relative">
      <Card
        sx={{
          py: 3,
          px: 3,
          boxShadow: ({ boxShadows: { md } }) => md,
          justifyContent: { xs: "center" },
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={5} sm={12}>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography variant="h4" fontWeight="bold">
                {title}
              </ArgonTypography>
              <ArgonTypography
                variant="h6"
                color="textSecondary"
                fontWeight="medium"
              >
                {subtitle}
              </ArgonTypography>
            </ArgonBox>
          </Grid>

          <Grid item lg={1} sm={12}>
            <Divider light orientation="vertical" />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6}>
                {/* <ArgonBox display="flex" alignItems="center">
                  <ArgonButton
                    variant="outlined"
                    color={supportItem?.priorityInfo?.color}
                    size="medium"
                    iconOnly
                  >
                    <Icon sx={{ fontWeight: "bold" }}>
                      {supportItem?.priorityInfo?.icon}
                    </Icon>
                  </ArgonButton>
                  <ArgonTypography
                    variant="body1"
                    fontWeight="medium"
                    color="textSecondary"
                    textTransform="capitalize"
                    sx={{ lineHeight: 0, marginLeft: "8px" }}
                  >
                    {supportItem?.priorityInfo?.priority}
                  </ArgonTypography>
                </ArgonBox> */}
              </Grid>

              <Grid item xs={12} md={8} xl={6}>
                {/* <ArgonBox
                  height="100%"
                  mt={0.5}
                  lineHeight={1}
                  display="flex"
                  width="100%"
                  pr={2}
                > */}
                <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                  <ArgonButton
                    disabled={resolvedStatus === supportItem?.status}
                    variant="outlined"
                    color="success"
                    onClick={() => resolveTicket()}
                    sx={{
                      ml: "auto",
                      alignSelf: "flex-start",
                      py: 1.25,
                      pr: 1.5,
                      borderRadius: "8px",
                      borderWidth: "1px",
                      transition: "background-color 0.3s, transform 0.2s",
                      ":hover": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        transform: "scale(1.02)",
                      },
                    }}
                    startIcon={
                      <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                        check
                      </Icon>
                    }
                  >
                    {supportItem?.status === "resolved"
                      ? "Resolved"
                      : "Mark as Resolved"}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </ArgonBox>
  );
}

export default Header;
