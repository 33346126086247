/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonAlert from "components/ArgonAlert";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import HostHiveLoading from "components/HostHiveLoading";
import CompaniesApi from "api/companyApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
function RecurrenceDialog({
  onClose,
  setRecurrenceData,
  recurrenceData,
  includeStartingOn = true,
}) {
  const [error, setError] = useState(null);
  const [loadingResponse, setLoadingResponse] = useState(false);
  const convertToDateFormat = (date) => {
    return dayjs(date).format("YYYY-MM-DD");
  };
  const handleSubmitClicked = async () => {
    console.log(recurrenceValue);
    if (!recurrenceValue || recurrenceValue === "" || recurrenceValue < 1) {
      setError("Valid Recurrence value is required");
      setShowError(true);
      return;
    }

    let recurrencePayload = {};
    recurrencePayload.recurrenceInterval = recurrenceInterval?.value;
    recurrencePayload.recurrenceIntervalValue = recurrenceValue;
    if (endDate) {
      recurrencePayload.endDate = endDate;
    }

    if (startDate) {
      recurrencePayload.startDate = startDate;
    }

    console.log(recurrencePayload);
    setRecurrenceData(recurrencePayload);
    if (onClose) {
      onClose();
    }
  };
  const [recurrenceValue, setRecurrenceValue] = useState(null);

  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [showError, setShowError] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const handleDateChange = (date) => {
    setEndDate(dayjs(date).format("YYYY-MM-DD"));
  };
  const handleStartDateChange = (date) => {
    let useDate = dayjs(date).format("YYYY-MM-DD");

    // if the useDate is in the past, console.log an error
    if (dayjs(useDate).isBefore(dayjs(), "day")) {
      // set startDate to Today
      // setStartDate(dayjs().format("YYYY-MM-DD"));
      setSaveDisabled(true);
      setError("Start date cannot be in the past");
      setStartDate(null);
      setShowError(true);
      return;
    } else {
      setStartDate(dayjs(date).format("YYYY-MM-DD"));
    }
  };
  const [recurrenceDoesNotEnd, setRecurrenceDoesNotEnd] = useState(false);
  const intervalOptions = [
    { value: "daily", label: "Day" },
    { value: "weekly", label: "Week" },
    { value: "monthly", label: "Month" },
    { value: "yearly", label: "Year" },
  ];

  const [recurrenceInterval, setRecurrenceInterval] = useState(null);

  useEffect(() => {
    console.log(recurrenceData);
    const matchingIntervalOption = intervalOptions.find(
      (option) => option.value === recurrenceData?.recurrenceInterval
    );

    setRecurrenceInterval(matchingIntervalOption || intervalOptions[0] || null);

    if (recurrenceData?.endDate === undefined) {
      setRecurrenceDoesNotEnd(true);
    } else {
      setEndDate(dayjs(convertToDateFormat(recurrenceData?.endDate)));
    }
    setRecurrenceValue(recurrenceData?.recurrenceIntervalValue || null);
    if (startDate) {
      setSaveDisabled(false);
    }
  }, [recurrenceData, startDate]);

  return (
    <>
      {loadingResponse ? (
        <>
          <ArgonBox display="flex">
            <ArgonTypography variant="caption">
              Updating recurrence data...
            </ArgonTypography>
            <HostHiveLoading />
          </ArgonBox>
        </>
      ) : (
        <>
          <ArgonBox p={2} lineHeight={1}>
            {/* <ArgonTypography variant="h5" fontWeight="bold" color="error">
              {" "}
              {error}
            </ArgonTypography> */}
            {showError ? (
              <ArgonAlert
                color="error"
                dismissible
                onDismiss={() => {
                  setShowError(false);
                  setError(null);
                }}
              >
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {error}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}

            <ArgonBox mb={2}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <Grid container spacing={1}>
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Repeat every
                    </ArgonTypography>
                  </ArgonBox>
                  <Grid item xs={6}>
                    <ArgonBox display="flex">
                      <ArgonBox>
                        <ArgonInput
                          placeholder="eg. 1"
                          type="number"
                          value={recurrenceValue}
                          onChange={(e) => {
                            setRecurrenceValue(e.target.value);
                          }}
                        />
                      </ArgonBox>
                      <ArgonBox pl={2}>
                        <ArgonSelect
                          options={intervalOptions}
                          onChange={(e) => setRecurrenceInterval(e)}
                          value={recurrenceInterval}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                </Grid>
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                ></ArgonBox>
                <Grid container spacing={1}>
                  {includeStartingOn ? (
                    <Grid item xs={12}>
                      <ArgonBox display="flex">
                        {" "}
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Starting on
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox
                          mb={1}
                          ml={0.5}
                          mt={3}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <ArgonBox display="flex">
                            <ArgonBox>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  onChange={handleStartDateChange}
                                  value={startDate}
                                  orientation="landscape"
                                />
                              </LocalizationProvider>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <ArgonBox display="flex">
                      {" "}
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Ending on
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonBox display="flex">
                          {!recurrenceDoesNotEnd ? (
                            <ArgonBox>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  onChange={handleDateChange}
                                  value={endDate}
                                  orientation="landscape"
                                />
                              </LocalizationProvider>
                            </ArgonBox>
                          ) : (
                            <></>
                          )}
                          <ArgonBox display="flex">
                            <ArgonBox pl={2}>
                              <Switch
                                checked={recurrenceDoesNotEnd}
                                onChange={() =>
                                  setRecurrenceDoesNotEnd(!recurrenceDoesNotEnd)
                                }
                              />
                            </ArgonBox>
                            <ArgonBox pl={1}>
                              <ArgonTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                Does not end
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox p={1}>
                <ArgonBox
                  display="flex"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={onClose}
                    >
                      Cancel
                    </ArgonButton>
                  </ArgonBox>
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmitClicked}
                      disabled={saveDisabled}
                    >
                      Update
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
              {/* Display role checkboxes here based on viewMode */}
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
}

export default RecurrenceDialog;
