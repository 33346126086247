const updateCalendarEvents = (
  data,
  availableServiceNames,
  availableChecklist,
  availableServicePartners
) => {
  const updatedData = data.map((obj) => {
    // const serviceName = availableServiceNames?.find(
    //   (service) => service.id === obj?.serviceName
    // );

    // const serviceNameInfo = {
    //   id: serviceName?.id,
    //   name: serviceName?.name,
    // };

    let start = obj.serviceScheduleDate;
    let end = obj.serviceScheduleDate;
    //let title = obj.serviceName;
    let id = obj.id;
    return {
      ...obj,
      start: start,
      end: end,
      //title: !obj.title ? serviceName.name : obj.title,
      id: id,
    };
  });

  return updatedData;
};

export default updateCalendarEvents;
