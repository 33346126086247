/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ServiceNameItemDialog from "layouts/management/company/settings/components/ServiceNames/components/ServiceNameItemDialog";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ServiceNameApi from "api/service-nameApi";
import BookingsApi from "api/bookingsApi";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import HostHiveDialog from "components/HostHiveDialog";
function SelectServiceName({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  responseData,
  setResponseData,
  isInCreationMode,
  handleRefetchBookings = null,
}) {
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const serviceNamesApi = ServiceNameApi();
  const bookingsApi = BookingsApi();
  const [serviceName, setServiceName] = useState(
    {
      value: bookingPayload?.serviceNameId,
      label: userSelections[bookingPayload?.serviceNameId],
    } || null
  );
  const [serviceNames, setServiceNames] = useState([]);
  // useEffect(() => {
  //   setNextDisabled(false);
  //   setBookingPayload({ ...bookingPayload, serviceNameId: serviceName?.value });
  //   setUserSelections({
  //     ...userSelections,
  //     [serviceName?.value]: serviceName?.label,
  //   });
  //   setResponseData({
  //     ...responseData,
  //     serviceNames: serviceNames,
  //   });
  // }, [serviceName]);
  const [fetchServiceNames, setFetchServiceNames] = useState(false);
  useEffect(() => {
    async function fetchServiceNames() {
      if (company) {
        try {
          const queryParams = {
            companyId: company?.id,
          };

          const response = await serviceNamesApi.getServiceNames(queryParams);
          const serviceNamesResponse = response.data;

          let options = serviceNamesResponse.map((serviceName) => ({
            value: serviceName.id,
            label: serviceName.name,
          }));
          options.push({
            value: "new",
            label: `+ Create New Service Name`,
          });
          setServiceNames(options);
        } catch (error) {
          console.error(error);
        }
      }
    }
    fetchServiceNames();
  }, [fetchServiceNames]);
  const [isNewServiceName, setIsNewServiceName] = useState(false);
  const [serviceNameDetails, setServiceNameDetails] = useState(null);
  const updateBooking = async (key, value) => {
    if (key === "serviceName") {
      console.log("Updating serviceName to", value);
      const response = await bookingsApi.updateBookingServiceName(
        bookingPayload?.id,
        value
      );
    }

    // if (handleRefetchBookings) {
    //   handleRefetchBookings();
    // }
  };

  const handleServiceNameSelected = (event) => {
    if (event.value === "new") {
      setIsNewServiceName(true);
    } else {
      setIsNewServiceName(false);
      setNextDisabled(false);
      setBookingPayload({ ...bookingPayload, serviceNameId: event?.value });
      setUserSelections({
        ...userSelections,
        [event?.value]: event?.label,
      });
      // setResponseData({
      //   ...responseData,
      //   serviceNames: serviceNames,
      // });
      setServiceName(event);
      if (!isInCreationMode) {
        updateBooking("serviceName", event.value);
      }
    }
  };

  return (
    // <ArgonBox>
    //   <ArgonBox lineHeight={0}>
    //     {/* <ArgonTypography variant="h5" fontWeight="bold">
    //       File Upload
    //     </ArgonTypography> */}
    //     <ArgonTypography variant="button" fontWeight="regular" color="text">
    //       Select the service name for this event.
    //     </ArgonTypography>
    //   </ArgonBox>
    //   <ArgonBox lineHeight={0}>
    //     <ServiceNameSelect
    //       // serviceNames={availableServiceNames}
    //       setServiceNames={setServiceNames}
    //       companyId={company?.id}
    //       serviceName={serviceName}
    //       setServiceName={setServiceName}
    //       // loading={isLoading}
    //     />
    //   </ArgonBox>
    // </ArgonBox>
    <Grid container spacing={2}>
      <HostHiveDialog
        includeDelete={false}
        includeClose={false}
        includeSave={false}
        open={isNewServiceName}
        onClose={() => {
          setIsNewServiceName(false);
        }}
        title={t("components.serviceName.create.createNewServiceName")}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <ServiceNameItemDialog
            companyId={company?.id}
            handleServiceNameSelected={handleServiceNameSelected}
            setServiceNameDetails={setServiceNameDetails}
            handleSelection={true}
            onClose={() => {
              setIsNewServiceName(false);
            }}
          />
        }
      />

      <Grid item xs={12}>
        <ArgonBox lineHeight={0} pt={2}>
          {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Select the service name for this event.
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox lineHeight={0}>
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={1}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Service Name
            </ArgonTypography>
            <HostHiveKbHelp lookupKey="serviceName" marginBottom={0} />
          </ArgonBox>

          <ArgonSelect
            menuPlacement="bottom"
            defaultValue={{
              value: "none",
              label: t("components.serviceName.select"),
            }}
            value={serviceName}
            options={serviceNames}
            onChange={(e) => handleServiceNameSelected(e)}
            fullWidth
          />
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

// typechecking props for SelectServiceName
SelectServiceName.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SelectServiceName;
