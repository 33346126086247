/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";

import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArgonButton from "components/ArgonButton";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import ArgonBadge from "components/ArgonBadge";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import GroupLinks from "layouts/management/properties/property-groups/components/ComplexProjectCard/components/GroupLinks";
import GroupSettingCard from "layouts/management/properties/property-groups/components/ComplexProjectCard/components/GroupSettingCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonAvatar from "components/ArgonAvatar";
import { useTranslation } from "react-i18next";

import HostHiveDialog from "components/HostHiveDialog";
import GroupsApi from "api/groupsApi";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({
  group,
  id,
  setGroups,
  triggerRefretchOfGroups = null,
}) {
  const groupsApi = GroupsApi();
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const [groupNameOpen, setGroupNameOpen] = useState(false);
  const [propertyGroupAssociationOpen, setPropertyGroupAssociationOpen] =
    useState(false);
  const [newGroupSettingOpen, setNewGroupSettingOpen] = useState(false);
  const [groupAssociations, setGroupAssociations] = useState([]);
  const [propertiesAdded, setPropertiesAdded] = useState([]);
  const [propertiesRemoved, setPropertiesRemoved] = useState([]);
  const [newGroupName, setNewGroupName] = useState(group.name);
  const [newGroupSetting, setNewGroupSetting] = useState(null);
  const handleGroupAssociationUpdated = async () => {
    // console.log("groupAssociations updated", groupAssociations);
    console.log("propertiesAdded", propertiesAdded);
    console.log("propertiesRemoved", propertiesRemoved);

    let associationUpdatePayload = {
      added: propertiesAdded,
      removed: propertiesRemoved,
    };
    try {
      const response = await groupsApi.updateGroupPropertyAssociations(
        group.id,
        associationUpdatePayload
      );

      setPropertiesAdded([]);
      setPropertiesRemoved([]);
    } catch (error) {
      console.log(error);
    }
    setPropertyGroupAssociationOpen(false);
  };
  const [savingName, setSavingName] = useState(false);
  const handleGroupNameUpdated = async () => {
    setSavingName(true);
    console.log("newGroupName", newGroupName);
    try {
      const response = await groupsApi.updateGroupName(group.id, {
        name: newGroupName,
      });

      setGroupNameOpen(false);
      setSavingName(false);

      setGroups((prevGroups) => {
        const updatedGroups = prevGroups.map((g) => {
          if (g.id === group.id) {
            return { ...g, name: newGroupName };
          }
          return g;
        });
        return updatedGroups;
      });
    } catch (error) {
      console.log(error);
      setSavingName(false);
    }
  };
  const [savingNewSetting, setSavingNewSetting] = useState(false);
  const handleGroupSettingUpdated = async () => {
    setSavingNewSetting(true);
    if (newGroupSetting.id !== undefined) {
      console.log("updating", newGroupSetting);
      try {
        const response = await groupsApi.updateGroupSettings(
          newGroupSetting.id,
          newGroupSetting
        );
        if (triggerRefretchOfGroups) {
          triggerRefretchOfGroups();
        }
        setNewGroupSetting(null);
        setNewGroupSettingOpen(false);
        setSavingNewSetting(false);
      } catch (error) {
        console.log(error);
        setSavingNewSetting(false);
        setNewGroupSetting(null);
      }
      return;
    }

    let newGroupSettingRequest = {
      key: newGroupSetting.key,
      value: newGroupSetting.value,
      groupId: group.id,
      isInternal: newGroupSetting.isInternal,
    };
    if (newGroupSetting?.type !== undefined) {
      newGroupSettingRequest.type = newGroupSetting.type;
    }
    console.log("here", newGroupSettingRequest);

    try {
      const response = await groupsApi.createGroupSetting(
        newGroupSettingRequest
      );
      if (triggerRefretchOfGroups) {
        triggerRefretchOfGroups();
      }
      setNewGroupSetting(null);
      setNewGroupSettingOpen(false);
      setSavingNewSetting(false);
    } catch (error) {
      console.log(error);
      setSavingNewSetting(false);
      setNewGroupSetting(null);
    }
  };

  const updateGroupStatus = async () => {
    let status = group.status === "active" ? "inactive" : "active";
    try {
      const response = await groupsApi.updateGroupStatus(group.id, status);
      if (triggerRefretchOfGroups) {
        triggerRefretchOfGroups();
      }
      // setGroups((prevGroups) => {
      //   const updatedGroups = prevGroups.map((g) => {
      //     if (g.id === group.id) {
      //       return { ...g, status: status };
      //     }
      //     return g;
      //   });
      //   return updatedGroups;
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const renderMenu = (id, property) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={() => setGroupNameOpen(true)}>
        Update Group Name
      </MenuItem>
      <MenuItem onClick={() => setPropertyGroupAssociationOpen(true)}>
        Update Property Group Association
      </MenuItem>
      <MenuItem onClick={() => updateGroupStatus()}>
        {group.status === "active" ? "Deactivate" : "Activate"} Property Group
      </MenuItem>
      <Divider />
      <MenuItem
        onClick={() => {
          setNewGroupSetting(null);
          setDeleteEnabled(false);
          setNewGroupSettingOpen(true);
        }}
      >
        Add new Definition
      </MenuItem>
    </Menu>
  );
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const handleEditSetting = (groupSetting) => {
    console.log("groupSetting", groupSetting);
    setDeleteEnabled(true);

    let newGroupSetting = {
      ...groupSetting,
      groupId: group.id,
    };
    setNewGroupSetting(newGroupSetting);
    setNewGroupSettingOpen(true);
  };

  const viewLink = (linkUrl) => {
    // window.location.href = linkUrl;
    window.open(linkUrl, "_blank");
  };
  const [showSecret, setShowSecret] = useState(false);
  const [workingSecretId, setWorkingSecretId] = useState(null);
  const handleViewSecret = (id) => {
    if (showSecret === true) {
      setShowSecret(false);
      setWorkingSecretId(null);
    } else {
      setShowSecret(true);
      setWorkingSecretId(id);
    }
  };

  const handleSettingDelete = async () => {
    console.log("settingId", newGroupSetting?.id);
    try {
      const response = await groupsApi.deleteGroupSetting(newGroupSetting.id);
      setNewGroupSetting(null);
      setNewGroupSettingOpen(false);
      if (triggerRefretchOfGroups) {
        triggerRefretchOfGroups();
      }
    } catch (error) {
      console.log(error);
      setNewGroupSetting(null);
      // setNewGroupSettingOpen(false);
    }
  };

  return (
    <Card>
      <HostHiveDialog
        open={propertyGroupAssociationOpen}
        fullWidth={true}
        includeClose={true}
        includeSave={true}
        maxWidth="md"
        title={group.name}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <GroupLinks
            group={group}
            groupAssociations={groupAssociations}
            setGroupAssociations={setGroupAssociations}
            propertiesAdded={propertiesAdded}
            setPropertiesAdded={setPropertiesAdded}
            propertiesRemoved={propertiesRemoved}
            setPropertiesRemoved={setPropertiesRemoved}
          />
        }
        onClose={() => setPropertyGroupAssociationOpen(false)}
        onSave={handleGroupAssociationUpdated}
      />
      <HostHiveDialog
        open={newGroupSettingOpen}
        onDelete={handleSettingDelete}
        fullWidth={true}
        includeClose={true}
        includeDelete={deleteEnabled}
        includeSave={true}
        saveDisabled={savingNewSetting}
        maxWidth="md"
        title={group.name}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText={savingNewSetting ? "Saving..." : "Save"}
        dialogObjects={
          <>
            <GroupSettingCard
              newGroupSetting={newGroupSetting}
              setNewGroupSetting={setNewGroupSetting}
              savingNewSetting={savingNewSetting}
            />
          </>
        }
        onClose={() => setNewGroupSettingOpen(false)}
        onSave={handleGroupSettingUpdated}
      />
      <HostHiveDialog
        open={groupNameOpen}
        fullWidth={true}
        includeClose={true}
        includeSave={true}
        saveDisabled={savingName}
        maxWidth="md"
        title={group.name}
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText={savingName ? "Saving..." : "Save"}
        dialogObjects={
          <>
            <ArgonBox display="flex" alignItems="center">
              <ArgonInput
                value={newGroupName}
                onChange={(e) => setNewGroupName(e.target.value)}
                disabled={savingName}
              />
            </ArgonBox>
          </>
        }
        onClose={() => setGroupNameOpen(false)}
        onSave={handleGroupNameUpdated}
      />
      <ArgonBox p={2}>
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox mb={1} lineHeight={0}>
              <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
              >
                {group.name}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBadge
              variant="contained"
              color={group.status === "active" ? "success" : "warning"}
              badgeContent={group.status}
              container
            />
          </ArgonBox>

          {renderMenu(id, group)}

          <ArgonTypography
            color="secondary"
            onClick={handleOpenMenu}
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
            }}
          >
            <Icon fontSize="default" sx={{ cursor: "pointer" }}>
              more_vert
            </Icon>
          </ArgonTypography>
        </ArgonBox>
        <Divider />
        <Grid container spacing={2}>
          {group?.groupSettings?.map((groupSetting, key) => (
            <>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      gap={0.5}
                      pl={2}
                    >
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {groupSetting?.key}
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {groupSetting?.type === "link" ? (
                        <>
                          <ArgonTypography
                            variant="caption"
                            textTransform="capitalize"
                            sx={{
                              ":hover": { textDecoration: "underline" },
                              cursor: "pointer",
                            }}
                            onClick={() => viewLink(groupSetting.value)}
                          >
                            Open Link
                          </ArgonTypography>
                        </>
                      ) : groupSetting.type === "password" ||
                        groupSetting.type === "door_access_code" ||
                        groupSetting.type === "gate_access_code" ? (
                        <ArgonBox display="flex" alignItems="center">
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                              <ArgonTypography
                                variant="button"
                                fontWeight="medium"
                              >
                                {showSecret &&
                                workingSecretId === groupSetting.id ? (
                                  <>{groupSetting.value}</>
                                ) : (
                                  <i>Reveal to see</i>
                                )}
                              </ArgonTypography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                              <Tooltip
                                title={
                                  showSecret &&
                                  workingSecretId === groupSetting.id
                                    ? "Hide Secret"
                                    : "Reveal Secret"
                                }
                                placement="top"
                                onClick={() =>
                                  handleViewSecret(groupSetting.id)
                                }
                              >
                                <IconButton size="small">
                                  <Icon>
                                    {showSecret &&
                                    workingSecretId === groupSetting.id
                                      ? "visibility"
                                      : "visibility_off"}
                                  </Icon>
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </ArgonBox>
                      ) : (
                        groupSetting.value
                      )}
                    </ArgonTypography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <ArgonBox width="100%" textAlign="right" lineHeight={1}>
                      <ArgonButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        onClick={() => handleEditSetting(groupSetting)}
                      >
                        Edit
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </ArgonBox>
    </Card>
  );
}

export default ComplexProjectCard;
