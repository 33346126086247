/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ArgonAlert from "components/ArgonAlert";
import Checkbox from "@mui/material/Checkbox";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import ArgonSelect from "components/ArgonSelect";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import CompaniesApi from "api/companyApi";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import InfoWidgets from "./components/InfoWidget";
import ComplexProjectCard from "layouts/management/properties/property-groups/components/ComplexProjectCard";
import PricingNotice from "layouts/management/properties/properties-list/components/PricingNotice";
import CompanyFilterSelect from "layouts/management/components/CompanyFilterSelect";
import FormDialog from "./components/TestDiaglog";
import { useParams, useNavigate } from "react-router-dom";
import PropertyContext from "context/PropertyContext";
import PropertiesApi from "api/propertiesApi";
import GroupsApi from "api/groupsApi";
import PricingCalculator from "layouts/management/components/SubscriptionPriceCalculator";
// Data

import HostHiveLoading from "components/HostHiveLoading";
// Images

import logoSlack from "assets/images/small-logos/logo-slack.svg";
import HostHiveDialog from "components/HostHiveDialog";
import NewProperty from "layouts/management/properties/new-property";
import ArgonInput from "components/ArgonInput";
import { setIn } from "formik";
import { useTranslation } from "react-i18next";
import { VideoLibraryHelper } from "services/videoLibraryHelper";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import HowToVideoButton from "layouts/management/components/VideoIconButton";
function PropertyGroups({ includeDashboardLayout = true }) {
  //const { companyId } = useParams();
  const { t } = useTranslation("common");

  const companiesApi = CompaniesApi();
  const propertiesApi = new PropertiesApi();
  const groupsApi = new GroupsApi();
  const navigate = useNavigate();
  const [pricingCalculatorOpen, setPricingCalculatorOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [properties, setProperties] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newGroupDialogOpen, setNewGroupDialogOpen] = useState(false);
  const [companies, setCompanies] = useState([]);

  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const [slackBotMenu, setSlackBotMenu] = useState(null);
  // const [company, setCompany] = useState(null);

  const [currentPage, setCurrentPage] = useState(0); // Add current page state
  const limit = 100; // You can adjust this to fit your needs
  const [searchText, setSearchText] = useState("");
  //const [companySearch, setCompanySearch] = useState({});
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [activeOnly, setActiveOnly] = useState(true);
  const [groups, setGroups] = useState([]);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const helpIntegrationLookupKey = "properties";
  const createPropertyVideoId = VideoLibraryHelper("createProperty").videoId;
  useEffect(() => {
    const loadPropertiesData = async () => {
      try {
        setIsLoading(true);
        const queryParams = {
          limit,
          skip: currentPage * limit,
          search: searchText,
          companyId: company?.id,
          ownerOnly: false,
          status: activeOnly ? "active" : null,
        };

        const propertiesResponse = await propertiesApi.getProperties(
          queryParams
        );

        if (
          propertiesResponse.status >= 200 &&
          propertiesResponse.status < 300
        ) {
          const propertyQuery = propertiesResponse.data;
          setProperties(propertyQuery);
          // if (searchPerformed) {
          //   console.log(propertyQuery);
          //   setProperties(propertyQuery);
          //   setSearchPerformed(false);
          // } else {
          //   setProperties((prevProperties) => [
          //     ...prevProperties,
          //     ...propertyQuery,
          //   ]);
          // }
        }
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
        setLoadingMore(false);
      }
    };

    async function loadGroups() {
      try {
        const queryParams = {
          companyId: company?.id,
          status: activeOnly ? "active" : null,
          name: searchText,
        };
        const groups = await groupsApi.getGroups(queryParams);
        setGroups(groups?.data);
      } catch (error) {
        console.log(error);
      }
    }

    loadGroups();
  }, [currentPage, searchText, activeOnly, triggerRefetch]);

  const handleNextPage = () => {
    setLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1); // Create a function to handle page changes
  };

  const navigateToBookingReports = () => {
    navigate(`/properties/bookingReport`);
  };

  const handleExportClicked = () => {
    console.log("Export clicked");
    setDialogOpen(true);
  };

  const handleCancelClicked = () => {
    console.log("Cancel clicked");
    setNewGroupDialogOpen(false);
  };
  const handleCreateGroup = async () => {
    console.log("Submit clicked");
    try {
      const response = await groupsApi.createGroup({
        name: newGroupName,
        companyId: company?.id,
      });
      setNewGroupName("");
      setTriggerRefetch(!triggerRefetch);
      setNewGroupDialogOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(0); // reset to the first page
    setSearchPerformed(true);
  };

  const [priceIncreaseNoticeOpen, setPriceIncreaseNoticeOpen] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");
  const [savingName, setSavingName] = useState(false);
  const PropertySettings = () => {
    return (
      <ArgonBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card
              sx={{
                height: "100%",
                width: "100%",

                boxShadow: ({ boxShadows: { md } }) => md,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                  <ArgonBox lineHeight={1} p={3}>
                    <ArgonTypography variant="h5" fontWeight="medium">
                      Property Groups
                    </ArgonTypography>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    ></ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12} md={4}>
                  <ArgonBox lineHeight={1} p={3} alignItems="center">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <ArgonBox display="flex">
                          <ArgonBox pt={1}>
                            <ArgonButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={() => setNewGroupDialogOpen(true)}
                            >
                              Create New Group
                            </ArgonButton>
                          </ArgonBox>
                          <ArgonBox pl={2}>
                            <HowToVideoButton videoId={createPropertyVideoId} />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>

                      {/* <Grid item xs={4}>
                          <ArgonButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => setPricingCalculatorOpen(true)}
                          >
                            Calculator
                          </ArgonButton>
                        </Grid> */}
                    </Grid>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  {showAlert ? (
                    <ArgonBox p={2}>
                      <ArgonAlert color="error" dismissible>
                        <ArgonTypography variant="caption" color="white">
                          {error?.response?.data?.detail}
                        </ArgonTypography>
                      </ArgonAlert>
                    </ArgonBox>
                  ) : null}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ArgonBox p={2}>
                    <ArgonInput
                      placeholder={t("properties.searchPlaceholder")}
                      startAdornment={
                        <Icon fontSize="small" style={{ marginRight: "6px" }}>
                          search
                        </Icon>
                      }
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ArgonBox display="flex" p={2}>
                    <Checkbox
                      checked={activeOnly}
                      onChange={() => setActiveOnly(!activeOnly)}
                    />

                    <ArgonBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Active Only
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
              </Grid>

              {groups.length > 0 ? null : (
                <Card>
                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    p={3}
                  >
                    <ArgonBox lineHeight={1}>
                      <ArgonTypography variant="h5" fontWeight="medium">
                        {t("properties.noPropertiesFound")}
                      </ArgonTypography>
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {t("properties.noPropertiesGuidance")}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </Card>
              )}

              <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1}>
                <Grid container spacing={3}>
                  {groups?.map((group, key) => (
                    <Grid item xs={12} key={key}>
                      <ComplexProjectCard
                        // title={name} // Assuming the property name should be the title
                        // description={occupancyStatus} // Assuming the description in the properties object should be the card's description
                        // status={status} // Assuming the serviceScheduleDate is the dateTime for the card
                        // Assuming members, images, dropdown action and menu aren't contained in the properties object
                        // bed={bed}
                        id={group?.id}
                        setGroups={setGroups}
                        triggerRefretchOfGroups={() =>
                          setTriggerRefetch(!triggerRefetch)
                        }
                        group={group}
                        setError={setError}
                        setShowAlert={() => setShowAlert(!showAlert)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    );
  };
  return (
    <>
      {includeDashboardLayout ? (
        <ArgonBox pt={6}>
          <DashboardLayout>
            {/* <DashboardNavbar /> */}
            <HostHiveDialog
              open={newGroupDialogOpen}
              includeSave={true}
              includeClose={true}
              onClose={handleCancelClicked}
              onSave={handleCreateGroup}
              title="Create Group"
              fullScreen={false}
              cancelButtonText="Cancel"
              submitButtonText="Save"
              dialogObjects={
                <>
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonInput
                      value={newGroupName}
                      onChange={(e) => setNewGroupName(e.target.value)}
                      disabled={savingName}
                    />
                  </ArgonBox>
                </>
              }
            />
            <ArgonBox my={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Card
                    sx={{
                      height: "100%",
                      width: "100%",

                      boxShadow: ({ boxShadows: { md } }) => md,
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={8}>
                        <ArgonBox lineHeight={1} p={3}>
                          <ArgonTypography variant="h5" fontWeight="medium">
                            Property Groups
                          </ArgonTypography>
                          <ArgonTypography
                            variant="button"
                            fontWeight="regular"
                            color="text"
                          ></ArgonTypography>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <ArgonBox lineHeight={1} p={3} alignItems="center">
                          <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                              <ArgonButton
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={() => setNewGroupDialogOpen(true)}
                              >
                                Create New Group
                              </ArgonButton>
                            </Grid>

                            {/* <Grid item xs={4}>
                          <ArgonButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => setPricingCalculatorOpen(true)}
                          >
                            Calculator
                          </ArgonButton>
                        </Grid> */}
                          </Grid>
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12}>
                        {showAlert ? (
                          <ArgonBox p={2}>
                            <ArgonAlert color="error" dismissible>
                              <ArgonTypography variant="caption" color="white">
                                {error?.response?.data?.detail}
                              </ArgonTypography>
                            </ArgonAlert>
                          </ArgonBox>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <ArgonBox p={2}>
                          <ArgonInput
                            placeholder={t("properties.searchPlaceholder")}
                            startAdornment={
                              <Icon
                                fontSize="small"
                                style={{ marginRight: "6px" }}
                              >
                                search
                              </Icon>
                            }
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ArgonBox display="flex" p={2}>
                          <Checkbox
                            checked={activeOnly}
                            onChange={() => setActiveOnly(!activeOnly)}
                          />

                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <ArgonTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Active Only
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>

                    {groups.length > 0 ? null : (
                      <Card>
                        <ArgonBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-start"
                          p={3}
                        >
                          <ArgonBox lineHeight={1}>
                            <ArgonTypography variant="h5" fontWeight="medium">
                              {t("properties.noPropertiesFound")}
                            </ArgonTypography>
                            <ArgonTypography
                              variant="button"
                              fontWeight="regular"
                              color="text"
                            >
                              {t("properties.noPropertiesGuidance")}
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>
                      </Card>
                    )}

                    <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1}>
                      <Grid container spacing={3}>
                        {groups?.map((group, key) => (
                          <Grid item xs={12} key={key}>
                            <ComplexProjectCard
                              // title={name} // Assuming the property name should be the title
                              // description={occupancyStatus} // Assuming the description in the properties object should be the card's description
                              // status={status} // Assuming the serviceScheduleDate is the dateTime for the card
                              // Assuming members, images, dropdown action and menu aren't contained in the properties object
                              // bed={bed}
                              id={group?.id}
                              setGroups={setGroups}
                              triggerRefretchOfGroups={() =>
                                setTriggerRefetch(!triggerRefetch)
                              }
                              group={group}
                              setError={setError}
                              setShowAlert={() => setShowAlert(!showAlert)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </ArgonBox>
                  </Card>
                </Grid>
              </Grid>
            </ArgonBox>

            <Footer />
          </DashboardLayout>
        </ArgonBox>
      ) : (
        <ArgonBox my={3}>
          <HostHiveDialog
            open={newGroupDialogOpen}
            includeSave={true}
            includeClose={true}
            onClose={handleCancelClicked}
            onSave={handleCreateGroup}
            title="Create Group"
            fullScreen={false}
            cancelButtonText="Cancel"
            submitButtonText="Save"
            dialogObjects={
              <>
                <ArgonBox display="flex" alignItems="center">
                  <ArgonInput
                    value={newGroupName}
                    onChange={(e) => setNewGroupName(e.target.value)}
                    disabled={savingName}
                  />
                </ArgonBox>
              </>
            }
          />
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card
                sx={{
                  height: "100%",
                  width: "100%",

                  boxShadow: ({ boxShadows: { md } }) => md,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <ArgonBox lineHeight={1} p={3}>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <ArgonTypography variant="h5" fontWeight="medium">
                            Property Groups
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox pl={2}>
                          {/* <HowToVideoButton videoId={createPropertyVideoId} /> */}
                          {helpIntegrationLookupKey ? (
                            <HostHiveKbHelp
                              // helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
                              size="medium"
                              lookupKey={helpIntegrationLookupKey}
                            />
                          ) : null}
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <ArgonBox lineHeight={1} p={3} alignItems="center">
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                          <ArgonBox display="flex">
                            <ArgonBox pt={1}>
                              <ArgonButton
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={() => setNewGroupDialogOpen(true)}
                              >
                                Create New Group
                              </ArgonButton>
                            </ArgonBox>
                            <ArgonBox pl={2}>
                              {/* <HowToVideoButton
                                videoId={createPropertyVideoId}
                              /> */}
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>

                        {/* <Grid item xs={4}>
                          <ArgonButton
                            variant="outlined"
                            color="info"
                            size="small"
                            onClick={() => setPricingCalculatorOpen(true)}
                          >
                            Calculator
                          </ArgonButton>
                        </Grid> */}
                      </Grid>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12}>
                    {showAlert ? (
                      <ArgonBox p={2}>
                        <ArgonAlert color="error" dismissible>
                          <ArgonTypography variant="caption" color="white">
                            {error?.response?.data?.detail}
                          </ArgonTypography>
                        </ArgonAlert>
                      </ArgonBox>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ArgonBox p={2}>
                      <ArgonInput
                        placeholder={t("properties.searchPlaceholder")}
                        startAdornment={
                          <Icon fontSize="small" style={{ marginRight: "6px" }}>
                            search
                          </Icon>
                        }
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ArgonBox display="flex" p={2}>
                      <Checkbox
                        checked={activeOnly}
                        onChange={() => setActiveOnly(!activeOnly)}
                      />

                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Active Only
                        </ArgonTypography>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                </Grid>

                <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1}>
                  <Grid container spacing={3}>
                    {groups?.map((group, key) => (
                      <Grid item xs={12} key={key}>
                        <ComplexProjectCard
                          // title={name} // Assuming the property name should be the title
                          // description={occupancyStatus} // Assuming the description in the properties object should be the card's description
                          // status={status} // Assuming the serviceScheduleDate is the dateTime for the card
                          // Assuming members, images, dropdown action and menu aren't contained in the properties object
                          // bed={bed}
                          id={group?.id}
                          setGroups={setGroups}
                          triggerRefretchOfGroups={() =>
                            setTriggerRefetch(!triggerRefetch)
                          }
                          group={group}
                          setError={setError}
                          setShowAlert={() => setShowAlert(!showAlert)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      )}
    </>
  );
}

export default PropertyGroups;
