import useAxiosPrivate from "hooks/useAxiosPrivate";
import ArgonSnackbar from "components/ArgonSnackbar";
import { useEffect, useState } from "react";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function PropertiesApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("properties", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getProperties = (queryParams) => {
    return axiosPrivate.get(`/properties`, { params: queryParams });
  };

  const createProperty = (propertyData) => {
    return axiosPrivate.post(`/properties`, propertyData);
  };

  const getProperty = (propertyId) => {
    return axiosPrivate.get(`/properties/${propertyId}`);
  };

  const updateProperty = (propertyId, propertyData) => {
    return axiosPrivate.put(`/properties/${propertyId}`, propertyData);
  };

  ///status/deactivate
  const getPropertyCounts = (companyId) => {
    return axiosPrivate.get(`/properties/property-counts/${companyId}`);
  };
  // /{property_id}/status/reactivate
  const reactivateProperty = (propertyId) => {
    return axiosPrivate.put(`/properties/${propertyId}/status/active`);
  };

  const deactivateProperty = (propertyId) => {
    return axiosPrivate.put(`/properties/${propertyId}/status/inactive`);
  };

  const updatePropertyImages = (
    companyId,
    propertyId,
    fileName,
    fileType,
    image
  ) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.put(
      `/properties/${propertyId}/images?name=${fileName}&type=${fileType}&companyId=${companyId}`,
      image,
      { headers }
    );
  };

  const deletePropertyImage = (companyId, propertyId, fileName) => {
    return axiosPrivate.delete(
      `/properties/images/delete?companyId=${companyId}&propertyId=${propertyId}&fileName=${fileName}`
    );
  };

  const updatePropertyOccupancyStatus = (propertyId, occupancyStatus) => {
    return axiosPrivate.put(
      `/properties/${propertyId}/occupancyStatus/${occupancyStatus}`
    );
  };

  const deleteProperty = (propertyId) => {
    return axiosPrivate.delete(`/properties/${propertyId}`);
  };

  return {
    getProperties,
    createProperty,
    getProperty,
    updateProperty,
    deleteProperty,
    updatePropertyOccupancyStatus,
    updatePropertyImages,
    deletePropertyImage,
    getPropertyCounts,
    reactivateProperty,
    deactivateProperty,
  };
}

export default PropertiesApi;
