// API client for /notes endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function CommentsApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  // const isAuthorized = isRouteAuthorized("commonPlus", role, subscriptionTier);

  // if (!isAuthorized) {
  //   return null;
  // }

  const getComments = (entityId, entityType) => {
    return axiosPrivate.get(`/comments/${entityType}/${entityId}`);
  };

  const addComment = (entityId, commentData) => {
    return axiosPrivate.put(`/comments/${entityId}/add`, commentData);
  };

  const removeComment = (entityId, entityType, entityItemId) => {
    return axiosPrivate.delete(
      `/comments/${entityType}/${entityId}/${entityItemId}`
    );
  };

  const editComment = (entityId, entityType, entityItemId, commentData) => {
    return axiosPrivate.put(
      `/comments/${entityType}/${entityId}/edit/${entityItemId}`,
      commentData
    );
  };

  const commentSubscription = (commentData) => {
    return axiosPrivate.put(`/comments/subscription`, commentData);
  };

  const confirmUserSubscription = (entityId, entityType, userId) => {
    return axiosPrivate.get(
      `/comments/subscription/${entityType}/${entityId}/user/${userId}`
    );
  };

  return {
    getComments,
    addComment,
    removeComment,
    editComment,
    commentSubscription,
    confirmUserSubscription,
  };
}

export default CommentsApi;
