import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function UsersApi() {
  const axiosPrivate = useAxiosPrivate();
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  // const isAuthorized = isRouteAuthorized("company", role, subscriptionTier);

  // if (!isAuthorized) {
  //   return null;
  // }
  const baseRoute = "/users";
  const getUsers = (queryParams) => {
    return axiosPrivate.get(`${baseRoute}`, { params: queryParams });
  };

  const createUser = (userData, queryParams) => {
    return axiosPrivate.post(`${baseRoute}`, userData, {
      params: queryParams,
    });
  };

  const getUser = (userId, queryParams) => {
    return axiosPrivate.get(`${baseRoute}/${userId}`, { params: queryParams });
  };

  const updateUser = (userId, userData) => {
    return axiosPrivate.put(`${baseRoute}/${userId}`, userData);
  };

  const deleteUser = (userId) => {
    return axiosPrivate.delete(`${baseRoute}/${userId}`);
  };

  const updateUserStatus = (userId, updatedStatus) => {
    return axiosPrivate.put(`${baseRoute}/${userId}/${updatedStatus}`);
  };

  const subscribeUser = (email, oobCode) => {
    return axiosPrivate.put(`${baseRoute}/subscribe/to-blog`, {
      email: email,
      accessCode: oobCode,
    });
  };

  const updateUserProfileImage = (
    userId,
    companyId,
    fileType,
    profileImage
  ) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.put(
      `${baseRoute}/${userId}/profileImage?type=${fileType}&companyId=${companyId}`,
      profileImage,
      { headers }
    );
  };

  const deleteUserProfileImage = (userId, companyId) => {
    return axiosPrivate.delete(
      `${baseRoute}/${userId}/profileImage?companyId=${companyId}`
    );
  };

  const forgotPassword = (email) => {
    return axiosPrivate.post(`${baseRoute}/forgotPassword/${email}`);
  };

  const generateOTP = (email) => {
    return axiosPrivate.post(`${baseRoute}/generate-otp/${email}`);
  };
  const verifyOTP = (email, otp) => {
    return axiosPrivate.post(`${baseRoute}/verify-otp/${otp}/${email}`);
  };

  return {
    getUsers,
    createUser,
    getUser,
    updateUser,
    deleteUser,
    updateUserStatus,
    updateUserProfileImage,
    deleteUserProfileImage,
    forgotPassword,
    subscribeUser,
    verifyOTP,
    generateOTP,
  };
}

export default UsersApi;
