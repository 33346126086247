import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function CompaniesApi() {
  const axiosPrivate = useAxiosPrivate();

  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();

  // const isAuthorized = isRouteAuthorized("company", role, subscriptionTier);

  // if (!isAuthorized) {
  //   return null;
  // }

  const getCompanies = (queryParams) => {
    return axiosPrivate.get(`/companies`, { params: queryParams });
  };

  const createCompany = (companyData) => {
    return axiosPrivate.post(`/companies`, companyData);
  };

  const getCompany = (companyId) => {
    return axiosPrivate.get(`/companies/${companyId}`);
  };

  const getCompanyPaymentPortal = (companyId) => {
    return axiosPrivate.get(`/companies/${companyId}/paymentPortal`);
  };

  const getCompanySubscription = (companyId, queryParams = null) => {
    return axiosPrivate.get(`/companies/${companyId}/subscription`, {
      params: queryParams,
    });
  };

  const updateCompany = (companyId, companyData) => {
    return axiosPrivate.put(`/companies/${companyId}`, companyData);
  };

  const deleteCompany = (companyId) => {
    return axiosPrivate.delete(`/companies/${companyId}`);
  };

  const getCompanyPartnerships = (companyId, queryParams) => {
    return axiosPrivate.get(`/companies/${companyId}/partnerships`, {
      params: queryParams,
    });
  };
  const addCompanyPartnership = (companyId, partnershipData) => {
    return axiosPrivate.put(
      `/companies/${companyId}/partnerships/add`,
      partnershipData
    );
  };

  const acceptCompanyPartnershipViaEmail = (inviteTokenId) => {
    return axiosPrivate.put(
      `/companies/invite-via-email-accepted/${inviteTokenId}`
    );
  };

  const createServicePartner = (email) => {
    return axiosPrivate.put(`/companies/partnerships/${email}`);
  };

  const addCompanyPartnershipViaEmail = (companyId, partnershipData) => {
    return axiosPrivate.put(
      `/companies/${companyId}/partnerships/inviteViaEmail`,
      partnershipData
    );
  };
  const removeCompanyPartnership = (companyId, partnershipData) => {
    return axiosPrivate.put(
      `/companies/${companyId}/partnerships/remove`,
      partnershipData
    );
  };
  return {
    getCompanies,
    getCompanySubscription,
    createCompany,
    getCompany,
    updateCompany,
    deleteCompany,
    getCompanyPartnerships,
    addCompanyPartnership,
    removeCompanyPartnership,
    getCompanyPaymentPortal,
    addCompanyPartnershipViaEmail,
    acceptCompanyPartnershipViaEmail,
    createServicePartner,
  };
}

export default CompaniesApi;
