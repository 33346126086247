import { useEffect, useState } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import CompaniesApi from "api/companyApi";
import UsersApi from "api/usersApi";
function UsersSelect({
  setUser,
  user,
  menuPlacement = "bottom",
  companyId,
  setUsers = null,
}) {
  const usersApi = UsersApi();
  const [usersOptions, setUsersOptions] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    async function fetchUsers() {
      try {
        const queryParams = {
          companyId: companyId,
        };
        const response = await usersApi.getUsers(queryParams);
        let usersResponse = response.data;
        const usersOptions = usersResponse.map((user) => ({
          value: user.id,
          label: user.email,
        }));
        setUsersOptions(usersOptions);
        if (setUsers) {
          setUsers(usersOptions);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsers();
  }, []);

  const handleUserSelected = (selectedOption) => {
    setUser(selectedOption);
  };

  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
      pb={2}
    >
      <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          Assignee
        </ArgonTypography>
      </ArgonBox>
      <ArgonSelect
        menuPlacement={menuPlacement}
        value={user ? user : { value: "none", label: "Select a User" }}
        options={usersOptions}
        onChange={handleUserSelected}
      />
    </ArgonBox>
  );
}

export default UsersSelect;
