import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function CategoriesApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("common", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getCategories = (queryParams) => {
    return axiosPrivate.get(`/categories`, { params: queryParams });
  };

  const createCategory = (categoryData) => {
    return axiosPrivate.post(`/categories`, categoryData);
  };

  const getCategory = (categoryId) => {
    return axiosPrivate.get(`/categories/${categoryId}`);
  };

  const updateCategory = (categoryId, categoryData) => {
    return axiosPrivate.put(`/categories/${categoryId}`, categoryData);
  };

  const updateCategoryStatus = (categoryId, updatedStatus) => {
    return axiosPrivate.put(`/categories/${categoryId}/${updatedStatus}`);
  };

  const deleteCategory = (categoryId) => {
    return axiosPrivate.delete(`/categories/${categoryId}`);
  };

  const bulkEditColors = (categoryData) => {
    return axiosPrivate.put(`/categories/bulk-edit/colors`, categoryData);
  };

  return {
    getCategories,
    createCategory,
    getCategory,
    updateCategory,
    deleteCategory,
    updateCategoryStatus,
    bulkEditColors,
  };
}

export default CategoriesApi;
