/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import EventCalendar from "examples/Calendar";

// Calendar application components
import Header from "layouts/management/schedulePage/components/Header";
import NextEvents from "layouts/management/schedulePage/components/NextEvents";
import ProductivityChart from "layouts/management/schedulePage/components/ProductivityChart";

// Data
import calendarEventsData from "layouts/management/schedulePage/data/calendarEventsData";

function Icons() {
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox
        component="i"
        color="primary"
        fontSize="14px"
        className="ni ni-shop"
      ></ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Icons;
