import * as Yup from "yup";

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().required("Email is required"),
  phoneNumber: Yup.string().nullable(true),
  status: Yup.string().oneOf(["active", "inactive", "pending"]).nullable(true),
  // companyName: Yup.string().required("Company is required"),
});

export default UserSchema;
