/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
// Custom styles for ComplexProjectCard
function NextPaymentCard({ title }) {
  const [viewInvoicePreviewDialogOpen, setViewInvoicePreviewDialogOpen] =
    useState(false);
  return (
    <ArgonBox p={2}>
      <ArgonBox display="flex" alignItems="center">
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
      </ArgonBox>
      <ArgonBox ml={2} lineHeight={0}>
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Charge Date
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              2023-Feb-01
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Estimated Amount
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              $24.00
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        {/* <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Sqft
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              1
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox> */}
      </ArgonBox>
      <Divider />
      <ArgonBox
        ml={2}
        lineHeight={0}
        display="flex"
        justifyContent="space-between"
      >
        <ArgonBox>
          <ArgonTypography variant="button" fontWeight="medium" color="text">
            Preview Invoice
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <ArgonTypography
            variant="button"
            fontWeight="regular"
            color="text"
            textTransform="capitalize"
            sx={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => setViewInvoicePreviewDialogOpen(true)}
            //title={title}
          >
            View
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of ComplexProjectCard
NextPaymentCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
NextPaymentCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
};

export default NextPaymentCard;
