/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAlert from "components/ArgonAlert";
import ArgonButton from "components/ArgonButton";
import ArgonSnackbar from "components/ArgonSnackbar";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AlertsApi from "api/alertsApi";
import { UserAuth } from "context/AuthContext";
import { useState, useEffect } from "react";
import NotificationItem from "layouts/management/notifications/components/NotificationItem";
import HostHiveLoading from "components/HostHiveLoading";
// react-router components
import { useLocation, Link } from "react-router-dom";
function Notifications() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [loading, setLoading] = useState(true);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const [alerts, setAlerts] = useState([]);
  const [userAlerts, setUserAlerts] = useState([]);
  const [companyAlerts, setCompanyAlerts] = useState([]);
  const alertsApi = AlertsApi();
  const { logout, user } = UserAuth();
  const [dimissingAlerts, setDismissingAlerts] = useState(false);
  useEffect(() => {
    async function fetchUserAlerts() {
      try {
        const queryParams = {
          limit: 1000,
        };
        const response = await alertsApi.getAlerts(queryParams);
        if (response.status >= 200 && response.status < 300) {
          console.log(response.data?.userAlerts);
          setUserAlerts(response.data.userAlerts);
          setCompanyAlerts(response.data.companyAlerts);

          // compine userAlerts and companyAlerts into alerts
          setAlerts([
            ...response.data.userAlerts,
            ...response.data.companyAlerts,
          ]);

          setLoading(false);
        }
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUserAlerts();
  }, []);

  // const alert

  const handleDismissAll = async () => {
    setDismissingAlerts(true);
    try {
      const response = await alertsApi.dismissAllAlerts({
        alertIds: alerts.map((alert) => alert.alertId),
      });
      if (response.status >= 200 && response.status < 300) {
        // openSuccessSB();
        setAlerts([]);
      }
    } catch (error) {
      console.error(error);
    }
    setDismissingAlerts(false);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* <ArgonBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <ArgonBox p={2}>
                <ArgonTypography variant="h5">Notifications</ArgonTypography>
              </ArgonBox>
              <ArgonBox pt={2} px={2}>
                {loading ? (
                  <HostHiveLoading />
                ) : (
                  <>
                    {alerts.map((alert) => (
                      <NotificationItem
                        //image={<img src={team2} alt="person" />}
                        title={[
                          `${alert.alertData.subject}`,
                          `${alert.alertData.content}`,
                        ]}
                        date={`${alert.timeAgo}`}
                        //onClick={handleCloseMenu}
                      />
                    ))}
                  </>
                )}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox> */}
      <ArgonBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ minHeight: "100vh", width: "100%" }}>
              <ArgonBox p={2} display="flex" justifyContent="space-between">
                <ArgonTypography variant="h5">Notifications</ArgonTypography>
                <ArgonBox width="100%" textAlign="right" lineHeight={1}>
                  <ArgonBox>
                    <ArgonButton
                      disabled={dimissingAlerts || alerts?.length === 0}
                      variant="gradient"
                      color="primary"
                      onClick={handleDismissAll}
                    >
                      {dimissingAlerts
                        ? `Dismissing (${alerts.length})...`
                        : "Dismiss All"}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox pt={2} px={2}>
                {loading ? (
                  <HostHiveLoading />
                ) : (
                  <>
                    {alerts?.map((alert) => (
                      <>
                        <NotificationItem
                          //image={<img src={team2} alt="person" />}
                          title={[`${alert.subject}`, `${alert.content}`]}
                          createdDate={alert?.created}
                          redirectUrl={`${alert?.redirectUrl}`}
                          alertId={`${alert.alertId}`}
                          userId={`${alert.userId}`}
                          //onClick={handleCloseMenu}
                        />
                      </>
                    ))}
                  </>
                )}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Notifications;
