/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import HostHiveDialog from "components/HostHiveDialog";
import ViewTransactionItem from "layouts/administration/accounting/components/ViewTransactionItem";
function ViewDataItem({
  value,
  suffix,
  prefix,
  itemDetails,
  id,
  // sxValue = null,
  // onClick = null,
  color = "text",
  triggerFetch = null,
}) {
  const [viewTransactionItemDiaglog, setViewTransactionItemDiaglog] =
    useState(false);

  return (
    <>
      <HostHiveDialog
        open={viewTransactionItemDiaglog}
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        includeSave={false}
        includeClose={false}
        title="View Transaction Item"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ViewTransactionItem
            onClose={() => setViewTransactionItemDiaglog(false)}
            itemDetails={itemDetails}
            id={id}
            // setFetchTransactions={() =>
            //   setFetchTransactions(!fetchTransactions)
            // }
            triggerFetch={triggerFetch}
          />
        }
      />
      <ArgonTypography
        variant="caption"
        fontWeight="medium"
        color={color}
        sx={{
          ":hover": { textDecoration: "underline" },
          cursor: "pointer",
        }}
        onClick={() => setViewTransactionItemDiaglog(true)}
      >
        {prefix && (
          <ArgonTypography variant="caption" fontWeight="medium" color={color}>
            &nbsp;&nbsp;{prefix}
          </ArgonTypography>
        )}
        {value}
        {suffix && (
          <ArgonTypography variant="caption" fontWeight="medium" color={color}>
            &nbsp;&nbsp;{suffix}
          </ArgonTypography>
        )}
      </ArgonTypography>
    </>
  );
}

// Setting default values for the props of ViewDataItem
ViewDataItem.defaultProps = {
  suffix: "",
};

// Typechecking props for the ViewDataItem
ViewDataItem.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default ViewDataItem;
