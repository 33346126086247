const getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_API_URL_PROD; // Replace with your production API URL
  } else {
    // console.log("development", process.env.REACT_APP_API_URL_DEV);
    return process.env.REACT_APP_API_URL_DEV; // Replace with your development API URL
    // return process.env.REACT_APP_API_URL_DEV;
  }
};

export default getApiUrl;
