/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "layouts/administration/taskCenter/components/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import { useNavigate } from "react-router-dom";
import PriorityCell from "layouts/administration/taskCenter/components/PriorityCell";
import ArgonBadge from "components/ArgonBadge";
import i18n from "i18next";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
export const columns = [
  {
    Header: i18n.t("components.tasks.ticketNumber", { ns: "common" }),
    accessor: "ticketNumberInfo",
    Cell: ({ value }) => {
      const navigate = useNavigate();
      if (value) {
        const [sort, name, data] = value;

        return (
          <DefaultCell
            value={typeof value === "string" ? `#${value}` : `#${name}`}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => navigate(`${data.itemUrl}`)}
          />
        );
      }
      return;
    },
  },
  {
    Header: i18n.t("generalWords.status", { ns: "common" }),
    accessor: "statusInfo",
    Cell: ({ value }) => {
      if (value) {
        let status;
        let statusText;

        status = (
          // <StatusCell
          //   icon={value?.icon}
          //   color={value?.color}
          //   status={value?.status}
          // />
          <ArgonBadge
            color={value?.color}
            size="xs"
            badgeContent={
              value?.status
                ? i18n.t(`components.tasks.status.${value?.status}`, {
                    ns: "common",
                  })
                : ""
            }
            container
          />
        );

        return status;
      }
    },
  },
  {
    Header: i18n.t("components.tasks.priority.title", { ns: "common" }),
    accessor: "priorityInfo",
    Cell: ({ value }) => {
      let priority;
      let statusText;

      priority = (
        <PriorityCell
          icon={value?.icon}
          color={value?.color}
          status={
            value?.priority
              ? i18n.t(`components.tasks.priority.${value?.priority}`, {
                  ns: "common",
                })
              : ""
          }
        />
      );

      return priority;
    },
  },

  {
    Header: i18n.t("general.columns.summary", { ns: "common" }),
    accessor: "summary",
    Cell: ({ value, row }) => {
      const navigate = useNavigate();
      if (value) {
        const [name, data] = value;

        const [v1, v2, ticketData] = row?.original?.ticketNumberInfo;
        return (
          <DefaultCell
            value={typeof value === "string" ? value : name}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => navigate(`${ticketData?.itemUrl}`)}
          />
        );
      }
    },
  },
  {
    Header: i18n.t("properties.property", { ns: "common" }),
    accessor: "propertyInfo",
    Cell: ({ value }) => {
      //const [name, data] = value;
      const navigate = useNavigate();
      return (
        <DefaultCell
          value={value?.propertyName}
          // sxValue={{
          //   ":hover": { textDecoration: "underline" },
          //   cursor: "pointer",
          // }}
          // onClick={() => console.log("clicked")}
        />
      );
    },
  },

  // {
  //   Header: "Description",
  //   accessor: "description",
  //   Cell: ({ value }) => <DefaultCell value={value} />,
  // },
  {
    Header: i18n.t("general.columns.created", { ns: "common" }),
    accessor: "created",
    Cell: ({ value }) => {
      if (value) {
        return <DefaultCell value={convertTimeStamp(value)} />;
      }
    },
  },
];
