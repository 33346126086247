import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { over } from "lodash";
import Link from "@mui/material/Link";
import { KbHelper } from "services/knowledgeBaseHelper";
const HostHiveKbHelp = ({
  size = "small",

  marginBottom = 1,
  lookupKey,
}) => {
  const info = KbHelper(lookupKey);

  let helpUrl = info?.kbHelpUrl;
  let toolTipText = info?.kbHelpToolTip;

  return (
    <IconButton
      component={Link}
      href={helpUrl}
      target="_blank"
      rel="noreferrer"
      color="dark"
      size={size}
      fullWidth
      sx={{ mb: marginBottom }}
    >
      <Tooltip
        title={`${toolTipText ? toolTipText : ""} ${
          helpUrl ? "Click to learn more." : ""
        }`}
        placement="top"
      >
        <Icon>help</Icon>
      </Tooltip>
    </IconButton>
  );
};
export default HostHiveKbHelp;
