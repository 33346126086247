/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
// @mui material components
import ArgonAlert from "components/ArgonAlert";
import HostHiveLoading from "components/HostHiveLoading";
import InventoryApi from "api/inventoryApi";

import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";

import ArgonButton from "components/ArgonButton";

import CategorySelect from "layouts/management/components/CategorySelect";
import PropertySelect from "layouts/management/components/PropertySelect";
import InventoryItemSchema from "schemas/inventoryItemSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import ArgonBadgeDot from "components/ArgonBadgeDot";
function InventoryItemDialog({
  handleClose,
  inventoryItem = null,
  propertyId = null,
  fetchInventoryItems = null,
  edit = false,
}) {
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const inventoryApi = new InventoryApi();
  const [alertColor, setAlertColor] = useState("error");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [category, setCategory] = useState(null);
  const [property, setProperty] = useState({});

  const [error, setError] = useState(null);

  const [assignedToProperty, setAssignedToProperty] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showCategoryError, setShowCategoryError] = useState(false);
  useEffect(() => {
    if (category) {
      setShowCategoryError(false);
    }
  }, [category]);

  useEffect(() => {
    if (inventoryItem?.categoryId) {
      setCategory({
        value: inventoryItem?.categoryId,
        label: (
          <ArgonBadgeDot
            color={inventoryItem?.categoryInfo?.categoryColor}
            size="md"
            badgeContent={inventoryItem?.categoryInfo?.categoryName}
          />
        ),
      });
    } else {
      console.log("no category", inventoryItem);
    }
  }, []);

  useEffect(() => {
    if (property?.value) {
      setProperty({
        id: property?.value,
        name: property?.label,
      });
    }
  }, [property]);

  // useEffect(() => {
  //   if (category?.value) {
  //     console.log(category);
  //     setCategory({
  //       id: category?.value,
  //       name: category?.label,
  //     });
  //   }
  // }, [category]);

  const DisplayEntry = ({ entryKey }) => {
    return (
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}
        </ArgonTypography>
      </ArgonBox>
    );
  };
  const onSubmit = async (values, actions) => {
    if (!category) {
      setShowCategoryError(true);
      return;
    }
    values.companyId = company?.id;

    if (!propertyId) {
      if (assignedToProperty) {
        values.propertyId = property?.id;
      } else {
        delete values.propertyId;
      }
    } else {
      values.propertyId = propertyId;
    }
    if (values.price !== "") {
      values.price = parseFloat(values.price);
    } else {
      delete values.price;
    }

    values.categoryId = category?.value;

    setIsSaving(true);
    try {
      console.log(values);
      let response;
      if (edit) {
        response = await inventoryApi.updateInventoryItem(
          inventoryItem?.id,
          values
        );
      } else {
        response = await inventoryApi.createInventoryItem(values);
      }

      if (response.status >= 200 && response.status < 300) {
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
    if (fetchInventoryItems) {
      fetchInventoryItems();
    }
    setIsSaving(false);
    handleClose();
    setProperty({});
    actions.resetForm();
  };
  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      itemName: inventoryItem?.itemName || "",
      propertyId: inventoryItem?.propertyId || propertyId || "",
      companyId: inventoryItem?.companyId || company?.id || "",
      count: inventoryItem?.count || "",
      price: inventoryItem?.price || "",
      status: inventoryItem?.status || "active",
      categoryId: inventoryItem?.categoryId || "",
    },
    validationSchema: InventoryItemSchema,
    onSubmit,
  });

  return (
    <>
      {isSaving ? (
        <HostHiveLoading
          message={`${t("adverbs.saving")} ${t(
            "components.inventory.iventoryItem"
          )}`}
          elipse={true}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <>
            {showAlert ? (
              <ArgonAlert color={alertColor} dismissible>
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {alertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry
                    entryKey={t("components.inventory.dialog.itemName")}
                  />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="itemName"
                      placeholder="Linen Sheets"
                      value={values.itemName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.itemName && Boolean(errors.itemName)}
                      helperText={touched.itemName && errors.itemName}
                    />
                  </ArgonBox>
                  {errors.itemName && touched.itemName && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.itemName} />
                  )}
                </ArgonBox>
              </Grid>
              {edit ? null : (
                <Grid item xs={12}>
                  <ArgonBox>
                    <DisplayEntry
                      entryKey={t("components.inventory.quantity")}
                    />
                    <ArgonBox display="flex">
                      <ArgonInput
                        name="count"
                        type="number"
                        placeholder="1"
                        value={values.count}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // Add error handling from Formik state
                        error={touched.count && Boolean(errors.count)}
                        helperText={touched.count && errors.count}
                      />
                    </ArgonBox>
                    {errors.count && touched.count && (
                      <HostHiveErrorMessage message={errors.count} />
                    )}
                  </ArgonBox>
                </Grid>
              )}
              {edit ? null : (
                <Grid item xs={12}>
                  <ArgonBox>
                    <DisplayEntry entryKey={t("components.inventory.price")} />
                    <ArgonBox display="flex">
                      <ArgonInput
                        name="price"
                        placeholder="12.00"
                        value={values.price}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // Add error handling from Formik state
                        error={touched.price && Boolean(errors.price)}
                        helperText={touched.price && errors.price}
                      />
                    </ArgonBox>
                    {errors.price && touched.price && (
                      <HostHiveErrorMessage message={errors.price} />
                    )}
                  </ArgonBox>
                </Grid>
              )}
            </Grid>
            <Divider />
            {propertyId || edit ? null : (
              <Grid item xs={12}>
                <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonBox display="flex">
                    <ArgonBox p={1}>
                      <Switch
                        checked={assignedToProperty}
                        onChange={() =>
                          setAssignedToProperty(!assignedToProperty)
                        }
                      />
                    </ArgonBox>
                    <ArgonBox p={1}>
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {t("components.inventory.dialog.assignToProperty")}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
                {assignedToProperty ? (
                  <PropertySelect
                    menuPlacement="top"
                    property={property}
                    setProperty={setProperty}
                    companyId={company?.id}
                  />
                ) : null}
              </Grid>
            )}
            <Grid item xs={12}>
              {/* <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <ArgonBox display="flex">
                  <ArgonBox p={1}>
                    <Switch
                      checked={assignedToProperty}
                      onChange={() =>
                        setAssignedToProperty(!assignedToProperty)
                      }
                    />
                  </ArgonBox>
                  <ArgonBox p={1}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Assign to Property
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox> */}
              <CategorySelect
                menuPlacement="top"
                categoryType="inventory"
                setCategory={setCategory}
                category={category}
                companyId={company?.id}
              />
              {showCategoryError ? (
                <HostHiveErrorMessage message="Category selection is required." />
              ) : null}
            </Grid>

            <ArgonBox justifyContent="center" display="flex" p={2}>
              <ArgonBox display="flex">
                <ArgonBox pr={2}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={handleClose}
                  >
                    Cancel
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox pr={2}>
                  <ArgonButton variant="gradient" color="info" type="submit">
                    Save
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </>
        </form>
      )}
    </>
  );
}

export default InventoryItemDialog;
