/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import { useNavigate } from "react-router-dom";
import PriorityCell from "layouts/administration/taskCenter/components/PriorityCell";
import ArgonBadge from "components/ArgonBadge";
import i18n from "i18next";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
export const columns = [
  // {
  //   Header: i18n.t("components.tasks.ticketNumber", { ns: "common" }),
  //   accessor: "ticketNumberInfo",
  //   Cell: ({ value }) => {
  //     const navigate = useNavigate();
  //     if (value) {
  //       const [sort, name, data] = value;

  //       return (
  //         <DefaultCell
  //           value={typeof value === "string" ? `#${value}` : `#${name}`}
  //           sxValue={{
  //             ":hover": { textDecoration: "underline" },
  //             cursor: "pointer",
  //           }}
  //           onClick={() => navigate(`${data.itemUrl}`)}
  //         />
  //       );
  //     }
  //     return;
  //   },
  // },
  {
    Header: "Log Date",
    accessor: "created",
    Cell: ({ value }) => {
      if (value) {
        return <DefaultCell value={convertTimeStamp(value)} />;
      }
    },
  },
  //totalTime

  // {
  //   Header: i18n.t("general.columns.summary", { ns: "common" }),
  //   accessor: "summary",
  //   Cell: ({ value, row }) => {
  //     const navigate = useNavigate();
  //     if (value) {
  //       return <DefaultCell value={value} />;
  //     }
  //   },
  // },
  {
    Header: "Logged Time",
    accessor: "totalTime",
    Cell: ({ value }) => {
      if (value) {
        return <DefaultCell value={value} />;
      }
    },
  },
  {
    Header: "User",
    accessor: "userFullName",
    Cell: ({ value }) => {
      if (value) {
        return <DefaultCell value={value} />;
      }
    },
  },
  {
    Header: i18n.t("properties.property", { ns: "common" }),
    accessor: "propertyName",
    Cell: ({ value }) => {
      if (value) {
        return <DefaultCell value={value} />;
      }
    },
  },
  {
    Header: "Log Type",
    accessor: "logTypeDisplay",
    Cell: ({ value, row }) => {
      const navigate = useNavigate();
      if (value) {
        const [name, data] = value;

        return <DefaultCell value={value} />;
      }
    },
  },
  {
    Header: i18n.t("components.category.title", { ns: "common" }),
    accessor: "categoryInfo",
    Cell: ({ value }) => {
      return (
        <ArgonBadge
          variant="contained"
          container
          badgeContent={value?.categoryName}
          color={value?.categoryColor}
        />
      );
    },
  },
  {
    Header: "Linked Details",
    accessor: "entityInfo",
    Cell: ({ value, row }) => {
      const navigate = useNavigate();
      if (value) {
        const [name, data] = value;

        const [v1, v2, entityData] = row?.original?.entityInfo;
        return (
          <DefaultCell
            value={v2}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() => navigate(`${entityData?.itemUrl}`)}
          />
        );
      }
    },
  },
];
