/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useState, useEffect, useContext } from "react";
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import PlatformSettings from "layouts/management/users/profile-overview/components/PlatformSettings";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "./components/ProfileInfoCard";
import MiniInfoCard from "examples/Cards/InfoCards/MiniInfoCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import UpcomingAssignments from "layouts/management/users/profile-overview/components/UpcomingAssignments";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/management/users/profile-overview/components/Header";
import { UserAuth } from "context/AuthContext";
// Data
import profilesListData from "layouts/pages/profile/profile-overview/data/profilesListData";
import { useParams } from "react-router-dom";
import UsersApi from "api/usersApi";
import HostHiveqlApi from "api/hosthiveqlApi";

import default_profile_image from "assets/images/default_profile_image.jpeg";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function Overview() {
  const { logout, user } = UserAuth();
  const [profileSettingsOpen, setProfileSettingsOpen] = useState(false);
  const userId = user?.uid;
  const usersApi = UsersApi();
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    applicationRole: {
      displayName: "",
      roleName: "",
    },
  });
  const hostHiveqlApi = HostHiveqlApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const applicationStoreUserDetails = JSON.parse(
    localStorage.getItem("userDetails")
  );
  const [loadServiceAppointmentSettings, setLoadServiceAppointmentSettings] =
    useState(false);
  useEffect(() => {
    async function fetchUser() {
      try {
        const response = await usersApi.getUser(userId);
        console.log("user response.data", response.data);
        if (response.data) {
          setUserDetails(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch user:", error);
      }
    }

    fetchUser();
  }, [loadServiceAppointmentSettings]);

  const profilePictureCheck = () => {
    if (userDetails.profileImage) {
      return userDetails.profileImage;
    } else {
      return default_profile_image;
    }
  };
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({
          functions: { rgba, linearGradient },
          palette: { gradients },
        }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      {userDetails ? (
        <>
          <Header
            fullName={`${userDetails?.firstName} ${userDetails?.lastName}`}
            role={applicationStoreUserDetails?.applicationRole?.displayName}
            company={company}
            profileImage={profilePictureCheck()}
          />

          <ArgonBox mt={5} mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <PlatformSettings userId={userId} userDetails={userDetails} />
              </Grid>
              <Grid item xs={12} md={4}>
                <ProfileInfoCard
                  title="profile information"
                  userDetails={userDetails}
                  setUserDetails={setUserDetails}
                  info={{
                    fullName: "Phil knight",
                    mobile: "(44) 123 1234 123",
                    email: "alecthompson@mail.com",
                    location: "USA",
                  }}
                  action={{ route: "", tooltip: "Edit Profile" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UpcomingAssignments
                  title="Upcoming assignments"
                  profiles={profilesListData}
                />
              </Grid>
            </Grid>
          </ArgonBox>

          <Footer />
        </>
      ) : (
        <>Loading...</>
      )}
    </DashboardLayout>
  );
}

export default Overview;
