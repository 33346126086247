/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ImportMatchWizard page components
import FileUpload from "layouts/management/components/ImportMatchWizard/components/FileUpload";
import MatchProperty from "layouts/management/components/ImportMatchWizard/components/MatchProperty";
import ReviewImportedTransactions from "layouts/management/components/ImportMatchWizard/components/ReviewImportedTransactions";
import Submit from "layouts/management/components/ImportMatchWizard/components/Submit";

// ImportMatchWizard layout schemas for form and form feilds
import validations from "layouts/pages/users/new-user/schemas/validations";
import form from "layouts/pages/users/new-user/schemas/form";
import initialValues from "layouts/pages/users/new-user/schemas/initialValues";

function getSteps() {
  return ["File Upload", "Property Match", "Review", "Submit"];
}

function getStepContent(
  stepIndex,
  formData,
  uploadResponse,
  setUploadResponse,
  propertyAppMatch,
  setPropertyAppMatch,
  bulkChangePayloadMapping,
  setBulkChangePayloadMapping,
  setNextDisabled,
  submitForm
) {
  switch (stepIndex) {
    case 0:
      return (
        <FileUpload
          formData={formData}
          setUploadResponse={setUploadResponse}
          setNextDisabled={setNextDisabled}
        />
      );
    case 1:
      return (
        <MatchProperty
          formData={formData}
          uploadResponse={uploadResponse}
          propertyAppMatch={propertyAppMatch}
          setPropertyAppMatch={setPropertyAppMatch}
          bulkChangePayloadMapping={bulkChangePayloadMapping}
          setBulkChangePayloadMapping={setBulkChangePayloadMapping}
          setNextDisabled={setNextDisabled}
        />
      );
    case 2:
      return (
        <ReviewImportedTransactions
          formData={formData}
          uploadResponse={uploadResponse}
          propertyAppMatch={propertyAppMatch}
          bulkChangePayloadMapping={bulkChangePayloadMapping}
          setBulkChangePayloadMapping={setBulkChangePayloadMapping}
          setNextDisabled={setNextDisabled}
          submitForm={submitForm}
        />
      );
    case 3:
      return (
        <Submit setNextDisabled={setNextDisabled} submitForm={submitForm} />
      );
    default:
      return null;
  }
}

function ImportMatchWizard({
  bulkChangePayloadMapping,
  setBulkChangePayloadMapping,
  handleSave,
  closeWizard,
}) {
  const [uploadResponse, setUploadResponse] = useState(null);
  const [propertyAppMatch, setPropertyAppMatch] = useState({});
  const [nextDisabled, setNextDisabled] = useState(true);
  // const [bulkChangePayloadMapping, setBulkChangePayloadMapping] = useState({}); // eslint-disable-line
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async () => {
    await sleep(1000);

    // eslint-disable-next-line no-alert
    // alert(JSON.stringify(values, null, 2));
    handleSave();
    // actions.setSubmitting(false);
    // actions.resetForm();

    setActiveStep(0);

    closeWizard();
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm();
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <Card
        sx={{
          display: "grid",
          alignItems: "center",
          position: "relative",
          height: "6rem",
          borderRadius: "lg",
          mb: 3,
        }}
      >
        <Stepper activeStep={activeStep} sx={{ margin: 0 }} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Card>
      <Formik
        initialValues={initialValues}
        validationSchema={currentValidation}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting }) => (
          <Form id={formId} autoComplete="off">
            <Card sx={{ height: "100%" }}>
              <ArgonBox p={2}>
                <ArgonBox>
                  {getStepContent(
                    activeStep,
                    {
                      values,
                      touched,
                      formField,
                      errors,
                    },
                    uploadResponse,
                    setUploadResponse,
                    propertyAppMatch,
                    setPropertyAppMatch,
                    bulkChangePayloadMapping,
                    setBulkChangePayloadMapping,
                    setNextDisabled,
                    submitForm
                  )}
                  <ArgonBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <ArgonBox />
                    ) : (
                      <ArgonButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        Back
                      </ArgonButton>
                    )}
                    <ArgonButton
                      disabled={nextDisabled}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                        setNextDisabled(true);
                      }}
                    >
                      {isLastStep ? "Send" : "Next"}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default ImportMatchWizard;
