// AIzaSyAjiNu5eIZF2usKN1ifJD8928eQGuDeLmk

import React, { useState, useEffect } from "react";
import { useLoadScript } from "@react-google-maps/api";
import ArgonInput from "components/ArgonInput";

const AddressInput = ({
  address,
  setAddress,
  formattedAddress = null,
  setFormattedAddress,
}) => {
  // console.log(process.env.REACT_GOOGLE_API_KEY);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_KEY, // Be sure to replace with your actual API key
    libraries: ["places"],
  });

  const [input, setInput] = useState(formattedAddress);
  const [suggestions, setSuggestions] = useState([]);
  const [fetchSuggestions, setFetchSuggestions] = useState(true);

  useEffect(() => {
    setInput(formattedAddress);
  }, [formattedAddress]);

  useEffect(() => {
    if (!isLoaded || !fetchSuggestions || !input || input === formattedAddress)
      return;

    if (input.length > 2) {
      const autocompleteService =
        new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  }, [input, isLoaded, fetchSuggestions, formattedAddress]);

  const selectSuggestion = (suggestion) => {
    const placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
    placesService.getDetails(
      {
        placeId: suggestion.place_id,
        fields: ["formatted_address", "address_components"],
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const address = place.formatted_address;
          setInput(address);
          setFormattedAddress(address);

          const addressParts = place.address_components;
          const postalCodeObj = addressParts.find((component) =>
            component.types.includes("postal_code")
          );
          const propertyAddress = {
            address1: `${addressParts[0]["long_name"]} ${addressParts[1]["short_name"]}`,
            city: addressParts[2]["long_name"],
            state: addressParts[5]["short_name"],
            zipCode: postalCodeObj ? postalCodeObj.long_name : "",
            country: addressParts[6]["short_name"],
          };

          setAddress(propertyAddress);
        }
        setSuggestions([]);
        setFetchSuggestions(false);
      }
    );
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    if (formattedAddress !== e.target.value) {
      // Only fetch suggestions if the input differs from the formattedAddress
      setFetchSuggestions(true);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ArgonInput
        type="text"
        value={input || ""}
        onChange={handleChange}
        placeholder="Enter property address"
        style={{ width: 300, height: 40, fontSize: 16 }}
      />
      {fetchSuggestions && suggestions.length > 0 && (
        <div
          style={{
            position: "absolute",
            zIndex: 1,
            backgroundColor: "white",
            border: "1px solid #ccc",
            width: 300,
          }}
        >
          {suggestions.map((suggestion) => (
            <div
              key={suggestion.place_id}
              onClick={() => selectSuggestion(suggestion)}
              style={{
                padding: 10,
                borderBottom: "1px solid #ccc",
                cursor: "pointer",
              }}
            >
              {suggestion.description}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AddressInput;
