/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import TableCell from "@mui/material/TableCell";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { Grid } from "@mui/material";
function PropertySpendTableCell({ title, content, image, noBorder, ...rest }) {
  let template;

  template = (
    <TableCell {...rest} align="left" sx={{ border: noBorder && 0 }}>
      <ArgonBox display="flex" flexDirection="column">
        <ArgonTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}:
        </ArgonTypography>
        <Grid container>
          <Grid item xs={12} lg={8}>
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {content}
            </ArgonTypography>
          </Grid>
          <Grid item xs={12} lg={4}>
            {title.toLowerCase() === "amount" ? (
              <ArgonButton variant="outlined" color="secondary" size="small">
                View
              </ArgonButton>
            ) : null}
          </Grid>
        </Grid>
      </ArgonBox>
    </TableCell>
  );

  return template;
}

// Setting default values for the props of PropertySpendTableCell
PropertySpendTableCell.defaultProps = {
  image: "",
  noBorder: false,
};

// Typechecking props for the PropertySpendTableCell
PropertySpendTableCell.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  image: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default PropertySpendTableCell;
