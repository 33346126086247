import useAxiosPrivate from "hooks/useAxiosPrivate";

function HostHiveqlApi() {
  const axiosPrivate = useAxiosPrivate();

  const hhqlGetCompany = (id, queryParams) => {
    return axiosPrivate.get(`/hhql/get-company/${id}`, { params: queryParams });
  };

  const hhqlGetProperty = (id, queryParams) => {
    return axiosPrivate.get(`/hhql/get-property/${id}`, {
      params: queryParams,
    });
  };

  const hhqlGetProperties = (queryParams) => {
    return axiosPrivate.get(`/hhql/get-properties`, { params: queryParams });
  };

  const hhqlGetPropertyInventoryItems = (queryParams, propertyId) => {
    return axiosPrivate.get(`/hhql/get-property-inventory/${propertyId}`, {
      params: queryParams,
    });
  };

  const hhqlGetCompanyInventoryItems = (queryParams, companyId) => {
    return axiosPrivate.get(`/hhql/get-company-inventory/${companyId}`, {
      params: queryParams,
    });
  };

  const hhqlGetUsers = (companyId, queryParams) => {
    return axiosPrivate.get(`/hhql/get-users/${companyId}`, {
      params: queryParams,
    });
  };

  const hhqlGetUser = (userId) => {
    return axiosPrivate.get(`/hhql/get-user/${userId}`);
  };

  const hhqlGetInventoryItem = (inventoryItemId) => {
    return axiosPrivate.get(`/hhql/get-inventory-item/${inventoryItemId}`);
  };

  const hhqlGetBookingReport = (bookingReportId) => {
    return axiosPrivate.get(`/hhql/get-booking-report/${bookingReportId}`);
  };

  //hhql/get-inventory-item/1c186e85-9d7f-480e-9b1e-26998a2b12ce

  return {
    hhqlGetProperty,
    hhqlGetProperties,
    hhqlGetUsers,
    hhqlGetUser,
    hhqlGetInventoryItem,
    hhqlGetCompany,
    hhqlGetPropertyInventoryItems,
    hhqlGetCompanyInventoryItems,
    hhqlGetBookingReport,
  };
}

export default HostHiveqlApi;
