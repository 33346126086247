/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ivancik from "assets/images/ivancik.jpg";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WeatherCard from "examples/Cards/WeatherCard";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import ThinBarChart from "layouts/administration/inventory/components/ThinBarChart";
import ControllerCard from "examples/Cards/ControllerCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Inventory dashboard components
import Cameras from "layouts/dashboards/smart-home/components/Cameras";
import InventoryTable from "layouts/administration/inventory/components/InventoryTable";
import TemperatureSlider from "layouts/dashboards/smart-home/components/TemperatureSlider";

// Data
import reportsDoughnutChartData from "layouts/dashboards/smart-home/data/reportsDoughnutChartData";
//import thinBarChartData from "layouts/dashboards/smart-home/data/thinBarChartData";
import controllerCardIcons from "layouts/dashboards/smart-home/data/controllerCardIcons";
import { useParams } from "react-router-dom";
// Images
import iconSunCloud from "assets/images/small-logos/icon-sun-cloud.png";
import HostHiveLoading from "components/HostHiveLoading";
import InventoryApi from "api/inventoryApi";

import { useTranslation } from "react-i18next";
function Inventory() {
  const thinBarChartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: {
      label: "Watts",
      data: [150, 230, 380, 220, 420, 200, 70, 500],
    },
  };
  const { t } = useTranslation("common");
  const [propertyBarChartData, setPropertyBarChartData] = useState(null);
  const [categoryBarChartData, setCategoryBarChartData] = useState(null);
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const [propertyDetails, setPropertyDetails] = useState({});
  const { propertyId } = useParams();
  const [temperature, setTemperature] = useState(21);
  const {
    humidityIconLight,
    temperatureIconLight,
    airConditionerIconLight,
    lightsIconLight,
    wifiIconLight,
    humidityIconDark,
    airConditionerIconDark,
    lightsIconDark,
    wifiIconDark,
  } = controllerCardIcons;

  // Controller cards states
  const [humidityState, setHumidityState] = useState(false);
  const [temperatureState, setTemperatureState] = useState(true);
  const [airConditionerState, setAirConditionerState] = useState(false);
  const [lightsStata, setLightsStata] = useState(false);
  const [wifiState, setWifiState] = useState(true);
  const [inventoryItemDetails, setInventoryItemDetails] = useState({});
  const [inventoryReplenishments, setInventoryReplenishments] = useState([]);
  const inventoryApi = new InventoryApi();
  const [inventoryItemDetailsLoading, setInventoryItemDetailsLoading] =
    useState(true);
  const [inventoryReplenishmentsLoading, setInventoryReplenishmentsLoading] =
    useState(true);
  const [fetchInventoryItems, setFetchInventoryItems] = useState(false);
  const [inventoryDashboardItems, setInventoryDashboardItems] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(false);
  useEffect(() => {
    async function fetchInventoryItems() {
      let queryParams = {
        limit: 100,
      };
      try {
        const response = await inventoryApi.getInventorySummaryDashboard();
        console.log("response", response);
        setInventoryDashboardItems(response.data);
      } catch (error) {}
    }

    fetchInventoryItems();
  }, [triggerFetch]);

  useEffect(() => {
    async function fetchData() {
      let queryParams = {
        limit: 100,
      };
      try {
        const response = await inventoryApi.getInventoryUsageSummary();
        console.log("response", response);
        setPropertyBarChartData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function fetchData() {
      let queryParams = {
        limit: 100,
        key: "categoryId",
      };
      try {
        const response = await inventoryApi.getInventoryUsageSummary(
          queryParams
        );
        console.log("response", response);
        setCategoryBarChartData(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  const toggleFetch = () => setTriggerFetch(!triggerFetch);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox pt={3}>
        <ArgonBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={
                  inventoryDashboardItems
                    ? inventoryDashboardItems?.usesToday
                    : 0
                }
                suffix=""
                title={t("components.inventory.tileTop.usesToday")}
                description=""
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={
                  inventoryDashboardItems
                    ? inventoryDashboardItems?.lastWeekUses
                    : 0
                }
                suffix=""
                title={t("components.inventory.tileTop.lastWeekUses")}
                description=""
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={
                  inventoryDashboardItems
                    ? inventoryDashboardItems?.stockLeft
                    : 0
                }
                suffix=""
                title={t("components.inventory.tileTop.stockLeft")}
                description=""
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={
                  inventoryDashboardItems
                    ? inventoryDashboardItems?.itemsRunningLow?.count
                    : 0
                }
                suffix=""
                title={t("components.inventory.tileTop.itemsRunningLow")}
                description=""
              />
            </Grid>

            <Grid item xs={12} xl={7}>
              <InventoryTable
                triggerFetch={triggerFetch}
                toggleFetch={toggleFetch}
              />
            </Grid>
            <Grid item xs={12} xl={5}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <BackgroundBlogCard
                    image={ivancik}
                    title="AI Insights!"
                    description={
                      <>
                        Based on usage, we recommend you to buy more of these.
                      </>
                    }
                    buttonText="read more"
                    action={{
                      type: "internal",
                      route: "/dashboards/crm",
                      label: "read more",
                    }}
                  />
                </Grid> */}

                <Grid item xs={12}>
                  {propertyBarChartData ? (
                    <ThinBarChart
                      title={t("components.inventory.usageByProperty")}
                      chart={propertyBarChartData}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {categoryBarChartData ? (
                    <ThinBarChart
                      title={t("components.inventory.usageByCategory")}
                      chart={categoryBarChartData}
                    />
                  ) : null}
                </Grid>

                {/* <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={
                      inventoryDashboardItems
                        ? inventoryDashboardItems?.usesToday
                        : 0
                    }
                    suffix=""
                    title="Uses today"
                    description=""
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={
                      inventoryDashboardItems
                        ? inventoryDashboardItems?.lastWeekUses
                        : 0
                    }
                    suffix=""
                    title="Last Week Uses"
                    description=""
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={
                      inventoryDashboardItems
                        ? inventoryDashboardItems?.stockLeft
                        : 0
                    }
                    suffix=""
                    title="Stock Left"
                    description=""
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DefaultCounterCard
                    count={
                      inventoryDashboardItems
                        ? inventoryDashboardItems?.itemsRunningLow?.count
                        : 0
                    }
                    suffix=""
                    title="Items Running Low"
                    description=""
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox my={6} width="100%">
        <Divider />
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Inventory;
