/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import PropertiesApi from "api/propertiesApi";
import PropertySelect from "layouts/management/components/PropertySelect";
import convertToOptions from "services/convertooptions";
function SelectProperty({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  responseData,
  setResponseData,
  isInCreationMode,
  propertyInfo,
}) {
  const MemoizedArgonSelect = React.memo(ArgonSelect);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const propertiesApi = new PropertiesApi();
  const [properties, setProperties] = useState([]);
  const [property, setProperty] = useState(
    {
      value: bookingPayload?.propertyId,
      label: userSelections[bookingPayload?.propertyId],
    } || {
        value: propertyInfo?.id,
        label: propertyInfo?.name,
      } ||
      null
  );

  useEffect(() => {
    async function fetchProperties() {
      const queryParams = {
        companyId: company?.id,
      };
      const response = await propertiesApi.getProperties(queryParams);
      const options = convertToOptions(response.data);
      setProperties(options);
      console.log("Properties", properties);
      setResponseData({ ...responseData, properties: options });
    }
    fetchProperties();
  }, []);

  // setNextDisabled to false when property is selected
  useEffect(() => {
    if (bookingPayload?.propertyId) {
      setNextDisabled(false);
    }
  }, [bookingPayload]);

  useEffect(() => {
    if (propertyInfo?.id) {
      setNextDisabled(false);
      setProperty({
        value: propertyInfo?.id,
        label: propertyInfo?.name,
      });
      setUserSelections({
        ...userSelections,
        [propertyInfo?.id]: propertyInfo?.name,
      });

      setBookingPayload({ ...bookingPayload, propertyId: propertyInfo?.id });
    }
  }, [propertyInfo]);

  const handlePropertySelection = (e) => {
    setProperty(e);
    setBookingPayload({ ...bookingPayload, propertyId: e?.value });
    setUserSelections({
      ...userSelections,
      [e?.value]: e?.label,
    });
    setNextDisabled(false);
  };

  return (
    <ArgonBox>
      <ArgonBox lineHeight={0} pb={2}>
        {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
        <ArgonTypography variant="button" fontWeight="regular" color="text">
          Select the property where the service will be performed.
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox lineHeight={0}>
        {/* <PropertySelect
          menuPlacement="bottom"
          property={property}
          setProperty={setProperty}
          companyId={company?.id}
        /> */}
        <ArgonSelect
          value={property}
          onChange={(e) => handlePropertySelection(e)}
          options={properties}
        />
      </ArgonBox>
    </ArgonBox>
  );
}

// typechecking props for SelectProperty
SelectProperty.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SelectProperty;
