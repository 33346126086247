import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import Divider from "@mui/material/Divider";
import PropertiesApi from "api/propertiesApi";
import HostHiveLoading from "components/HostHiveLoading";
// import CompanySelect from "../CategorySelect/CompanySelect";
import CompaniesApi from "api/companyApi";
function SettingsDataCard({ title, companyDetails, handleClose }) {
  const companiesApi = CompaniesApi();
  const [isSaving, setIsSaving] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyInviteCode, setCompanyInviteCode] = useState("");
  const [companyAddress, setCompanyAddress] = useState({});
  const [copyIcon, setCopyIcon] = useState("copy");
  useEffect(() => {
    async function fetchCompanyDetails() {
      setCompanyDetailsLoading(true);
      // const companyIdToUse = companyId ? companyId : company.id;
      try {
        const response = await companiesApi.getCompany(companyDetails?.id);
        if (response.status >= 200 && response.status < 300) {
          setCompany(response.data);
          setCompanyName(response.data.name);
          setCompanyEmail(response.data.email);
          setCompanyInviteCode(response.data.inviteCode);
          setCompanyPhone(response.data.phoneNumber);
          setCompanyAddress(response.data.address);
          console.log("companiesResponse.data", response.data);
        }

        setCompanyDetailsLoading(false);
      } catch (error) {
        //alertContent(error.message, "https://google.com");
        //setShowAlert(true);
        console.error(error);
        setCompanyDetailsLoading(false);
      }
    }

    fetchCompanyDetails();
  }, [companyDetails]);

  const handleCopyInviteCodeClicked = () => {
    setCopyIcon("check");
    console.log("handleCopyInviteCodeClicked");
    navigator.clipboard.writeText(companyInviteCode);
    //Sleep for 1 second to allow user to see the check icon
    setTimeout(() => {
      setCopyIcon("copy");
    }, 500);
    //setCopyIcon("copy");
  };

  const DisplayEntry = ({ entryKey }) => {
    return (
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}
        </ArgonTypography>
      </ArgonBox>
    );
  };

  return (
    <>
      {companyDetailsLoading ? (
        <HostHiveLoading />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ArgonBox>
                <DisplayEntry entryKey="Company Name" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyDetails?.name}
                    onChange={(event) => setCompanyName(event.target.value)}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={6}>
              <ArgonBox>
                <DisplayEntry entryKey="Email" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyEmail}
                    //onChange={handlePropertyBedChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>

            <Grid item xs={6}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="Phone number" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyPhone}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12}>
              <ArgonBox>
                <DisplayEntry entryKey="Invite Code" />
                <ArgonBox display="flex">
                  <ArgonInput disabled value={companyInviteCode} />
                  <ArgonTypography
                    variant="body1"
                    color="secondary"
                    sx={{ cursor: "pointer", lineHeight: 0 }}
                  >
                    <Tooltip
                      title="Copy invite code"
                      placement="top"
                      //onClick={handleCopyInviteCodeClicked}
                      onClick={handleCopyInviteCodeClicked}
                    >
                      <Icon>{copyIcon}</Icon>
                    </Tooltip>
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="Address1" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyAddress?.address1}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="Address2" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyAddress?.address2}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="City" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyAddress?.city}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="State" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyAddress?.state}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <ArgonBox pb={1}>
                <DisplayEntry entryKey="Zip Code" />
                <ArgonBox display="flex">
                  <ArgonInput
                    value={companyAddress?.zipCode}
                    //onChange={handlePropertyBathChange}
                  />
                </ArgonBox>
              </ArgonBox>
            </Grid>
          </Grid>

          <ArgonBox justifyContent="center" display="flex" p={2}>
            <ArgonBox display="flex">
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={handleClose}
                >
                  Cancel
                </ArgonButton>
              </ArgonBox>
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="info"
                  // onClick={handleSubmitClicked}
                >
                  Save
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );

  SettingsDataCard.propTypes = {
    title: PropTypes.string.isRequired,
  };
}
export default SettingsDataCard;
