/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useMemo, useEffect, useState, useContext } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link, useNavigate } from "react-router-dom";
import BookingsApi from "api/bookingsApi";
import ChangeRequestsApi from "api/changeRequestsApi";
import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
function ChangeRequests({
  title,
  date,
  setExternalFetch,
  externalFetch,
  openRequests,
  setOpenRequests,

  changeRequests,
  setChangeRequests,
}) {
  const { t } = useTranslation("common");

  const bookingsApi = BookingsApi();
  const changeRequestsApi = ChangeRequestsApi();

  const [declineMessage, setDeclineMessage] = useState(false);
  const [eventDataCardOpen, setEventDataCardOpen] = useState(false);
  const [declineBookingInfo, setDeclineBookingInfo] = useState({});
  const [fetchChangeRequestsToApprove, setFetchChangeRequestsToApprove] =
    useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [bookingInfo, setBookingInfo] = useState({});
  const [propertyInfo, setPropertyInfo] = useState({});

  const [showAssignmentsUnassigned, setShowAssignmentsUnassigned] =
    useState(false);

  const handleChangeRequestApproval = async (id, approval) => {
    console.log("Change approved", id);
    //const approval = true;
    try {
      const updateChangeRequestResponse =
        await changeRequestsApi.updateChangeRequestApprovalState(id, approval);
      console.log(
        "Change Request approval updated",
        updateChangeRequestResponse
      );

      setChangeRequests((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== id)
      );
      if (setExternalFetch) {
        setExternalFetch(!externalFetch);
      }
    } catch (error) {
      console.error("Error updating change request approval", error);
    }
  };

  const handleViewBooking = (bookingData) => {
    console.log("Booking view", bookingData);
    setBookingInfo(bookingData);
    setPropertyInfo(bookingData?.propertyInfo);
    setEventDataCardOpen(true);
    // setDeclineBookingInfo(bookingData);
    // const status = "declined";
    // setEventDataCardOpen(true);
  };
  const navigate = useNavigate();
  const [changeRequestsOpen, setChangeRequestsOpen] = useState(false);
  const [showOpenChangeRequests, setShowOpenChangeRequests] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const handleSubmit = async () => {
    console.log(selectedIds);
    let approval = true;
    try {
      const response =
        await changeRequestsApi.updateChangeRequestApprovalStateBulk({
          entityIds: selectedIds,
          approved: approval,
        });
      console.log(response);
      // Assuming the API call was successful, filter out the confirmed bookings from the confirmationBookings list
      setChangeRequests((prevBookings) =>
        prevBookings.filter((booking) => !selectedIds.includes(booking.id))
      );
      if (setExternalFetch) {
        setExternalFetch(!externalFetch);
      }
    } catch (error) {
      console.error("Error updating change request approval", error);
    }
  };
  const handleCancelSubmit = async () => {
    console.log(selectedIdsOpen);

    try {
      const response =
        await changeRequestsApi.updateChangeRequestStatusCancelBulk({
          changeRequestIds: selectedIdsOpen,
        });
      console.log(response);
      // Assuming the API call was successful, filter out the confirmed bookings from the confirmationBookings list
      setOpenRequests((prevBookings) =>
        prevBookings.filter((booking) => !selectedIdsOpen.includes(booking.id))
      );
      if (setExternalFetch) {
        setExternalFetch(!externalFetch);
      }
    } catch (error) {
      console.error("Error updating change request approval", error);
    }
  };
  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedIds(
      event.target.checked ? changeRequests.map((item) => item.id) : []
    );
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };
  const [selectedIdsOpen, setSelectedIdsOpen] = useState([]);
  const [selectAllOpen, setSelectAllOpen] = useState(false);
  const handleSelectAllChangeOpen = (event) => {
    setSelectAllOpen(event.target.checked);
    setSelectedIdsOpen(
      event.target.checked ? openRequests.map((item) => item.id) : []
    );
  };

  const handleCheckboxChangeOpen = (event, id) => {
    if (event.target.checked) {
      setSelectedIdsOpen((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIdsOpen((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };
  const handleCancelRequest = async (id) => {
    try {
      const response = await changeRequestsApi.cancelChangeRequest(id);
      if (response.status >= 200 && response.status < 300) {
        setOpenRequests((prevBookings) =>
          prevBookings.filter((booking) => booking.id !== id)
        );
        if (setExternalFetch) {
          setExternalFetch(!externalFetch);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue);
  };

  const PendingRequests = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ArgonBox display="flex" justifyContent="center">
              {changeRequests?.length > 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                  }
                  label={t("generalWords.selectAll")}
                />
              ) : null}
            </ArgonBox>
          </Grid>

          <Grid item xs={12}>
            {changeRequests?.map((changeRequest) => (
              <ArgonBox
                component="div"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                key={changeRequest?.id}
              >
                <ArgonBox>
                  <ArgonBox>
                    <FormControlLabel
                      key={changeRequest.id}
                      control={
                        <Checkbox
                          checked={selectedIds.includes(changeRequest.id)}
                          onChange={(event) =>
                            handleCheckboxChange(event, changeRequest.id)
                          }
                        />
                      }
                      label={changeRequest?.serviceName || "No title"}
                    />
                  </ArgonBox>

                  {/* <ArgonBox>{booking?.title}</ArgonBox> */}

                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <ArgonBox pl={2} display="flex" alignItems="center">
                      <ArgonBox display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          {changeRequest?.propertyName}
                        </ArgonTypography>
                        <ArgonTypography variant="caption" color="text">
                          {changeRequest?.assignedCompany}
                        </ArgonTypography>
                        <ArgonBox>
                          {/* <ArgonBox>
                  <ArgonTypography component="label" variant="caption">
                    {propertyAddress?.address1}
                  </ArgonTypography>
                </ArgonBox> */}
                          <ArgonBox>
                            <ArgonTypography
                              component="label"
                              variant="caption"
                            >
                              Change Service date from{" "}
                              <strong>
                                {
                                  changeRequest?.changeRequestDetails?.before
                                    ?.serviceScheduleDate
                                }
                              </strong>{" "}
                              to{" "}
                              <strong>
                                {
                                  changeRequest?.changeRequestDetails?.after
                                    ?.serviceScheduleDate
                                }
                              </strong>
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>
                        {/* <ArgonTypography variant="caption" color="text">
                KMB Realty LLC. - {name}
              </ArgonTypography> */}
                      </ArgonBox>
                    </ArgonBox>
                    <ArgonBox display="flex">
                      <ArgonBox mr={2}>
                        <ArgonButton
                          onClick={() =>
                            handleChangeRequestApproval(
                              changeRequest?.id,
                              false
                            )
                          }
                          variant="outlined"
                          color="error"
                          size="small"
                          iconOnly
                          circular
                          sx={({ functions: { pxToRem } }) => ({
                            width: pxToRem(34),
                            minWidth: pxToRem(34),
                            height: pxToRem(34),
                            minHeight: pxToRem(34),
                          })}
                        >
                          <CloseIcon>test</CloseIcon>
                        </ArgonButton>
                      </ArgonBox>
                      <ArgonBox>
                        <ArgonButton
                          onClick={() =>
                            handleChangeRequestApproval(changeRequest?.id, true)
                          }
                          variant="outlined"
                          color="success"
                          size="small"
                          iconOnly
                          circular
                          sx={({ functions: { pxToRem } }) => ({
                            width: pxToRem(34),
                            minWidth: pxToRem(34),
                            height: pxToRem(34),
                            minHeight: pxToRem(34),
                          })}
                        >
                          <DoneIcon>test</DoneIcon>
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  <Divider
                    sx={{
                      mt: 2,
                      mb: 1,
                    }}
                  />
                </ArgonBox>
              </ArgonBox>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };

  const OpenRequests = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <ArgonBox display="flex" justifyContent="center">
              {openRequests?.length > 0 ? (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectAllOpen}
                      onChange={handleSelectAllChangeOpen}
                    />
                  }
                  label={t("generalWords.selectAll")}
                />
              ) : null}
            </ArgonBox>
          </Grid>

          <Grid item xs={12}>
            {openRequests?.map((changeRequest) => (
              <ArgonBox
                component="div"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                key={changeRequest?.id}
              >
                <ArgonBox>
                  <ArgonBox>
                    <FormControlLabel
                      key={changeRequest.id}
                      control={
                        <Checkbox
                          checked={selectedIdsOpen.includes(changeRequest.id)}
                          onChange={(event) =>
                            handleCheckboxChangeOpen(event, changeRequest.id)
                          }
                        />
                      }
                      label={changeRequest?.serviceName || "No title"}
                    />
                  </ArgonBox>

                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <ArgonBox pl={2} display="flex" alignItems="center">
                      <ArgonBox display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          {changeRequest?.propertyName}
                        </ArgonTypography>
                        <ArgonTypography variant="caption" color="text">
                          {changeRequest?.companyName}
                        </ArgonTypography>
                        <ArgonBox>
                          <ArgonBox>
                            <ArgonTypography
                              component="label"
                              variant="caption"
                            >
                              Change Service date from{" "}
                              <strong>
                                {
                                  changeRequest?.changeRequestDetails?.before
                                    ?.serviceScheduleDate
                                }
                              </strong>{" "}
                              to{" "}
                              <strong>
                                {
                                  changeRequest?.changeRequestDetails?.after
                                    ?.serviceScheduleDate
                                }
                              </strong>
                            </ArgonTypography>
                          </ArgonBox>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                    <ArgonBox display="flex">
                      <ArgonBox mr={2}>
                        <ArgonButton
                          onClick={() => handleCancelRequest(changeRequest?.id)}
                          variant="gradient"
                          color="error"
                          size="small"
                          sx={{
                            ml: "auto",
                            alignSelf: "flex-start",
                            py: 1.25,
                            //pr: 1.5,
                            borderRadius: "8px",
                            borderWidth: "1px",
                            transition: "background-color 0.3s, transform 0.2s",
                            ":hover": {
                              backgroundColor: "rgba(0,0,0,0.05)",
                              transform: "scale(1.02)",
                            },
                          }}
                        >
                          Cancel
                        </ArgonButton>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  <Divider
                    sx={{
                      mt: 2,
                      mb: 1,
                    }}
                  />
                </ArgonBox>
              </ArgonBox>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <HostHiveDialog
          open={showOpenChangeRequests}
          fullWidth={true}
          onSave={handleCancelSubmit}
          onClose={() => setShowOpenChangeRequests(false)}
          includeSave={true}
          includeClose={true}
          maxWidth="md"
          title="Open Change Requests"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          submitButtonText="Cancel Selected"
          submitButtonColor="error"
          showStatusBadge={false}
          saveDisabled={selectedIdsOpen.length < 1}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <ArgonBox display="flex" justifyContent="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAllOpen}
                          onChange={handleSelectAllChangeOpen}
                        />
                      }
                      label={t("generalWords.selectAll")}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  {openRequests?.map((changeRequest) => (
                    <ArgonBox
                      component="div"
                      display="flex"
                      flexDirection="column"
                      p={2}
                      m={0}
                      key={changeRequest?.id}
                    >
                      <ArgonBox>
                        <ArgonBox>
                          <FormControlLabel
                            key={changeRequest.id}
                            control={
                              <Checkbox
                                checked={selectedIdsOpen.includes(
                                  changeRequest.id
                                )}
                                onChange={(event) =>
                                  handleCheckboxChangeOpen(
                                    event,
                                    changeRequest.id
                                  )
                                }
                              />
                            }
                            label={changeRequest?.serviceName || "No title"}
                          />
                        </ArgonBox>

                        <ArgonBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <ArgonBox pl={2} display="flex" alignItems="center">
                            <ArgonBox display="flex" flexDirection="column">
                              <ArgonTypography variant="caption" color="text">
                                {changeRequest?.propertyName}
                              </ArgonTypography>
                              <ArgonBox>
                                <ArgonBox>
                                  <ArgonTypography
                                    component="label"
                                    variant="caption"
                                  >
                                    Change Service date from{" "}
                                    <strong>
                                      {
                                        changeRequest?.changeRequestDetails
                                          ?.before?.serviceScheduleDate
                                      }
                                    </strong>{" "}
                                    to{" "}
                                    <strong>
                                      {
                                        changeRequest?.changeRequestDetails
                                          ?.after?.serviceScheduleDate
                                      }
                                    </strong>
                                  </ArgonTypography>
                                </ArgonBox>
                              </ArgonBox>
                            </ArgonBox>
                          </ArgonBox>
                          <ArgonBox display="flex">
                            <ArgonBox mr={2}>
                              <ArgonButton
                                onClick={() =>
                                  handleCancelRequest(changeRequest?.id)
                                }
                                variant="gradient"
                                color="error"
                                size="small"
                                sx={{
                                  ml: "auto",
                                  alignSelf: "flex-start",
                                  py: 1.25,
                                  //pr: 1.5,
                                  borderRadius: "8px",
                                  borderWidth: "1px",
                                  transition:
                                    "background-color 0.3s, transform 0.2s",
                                  ":hover": {
                                    backgroundColor: "rgba(0,0,0,0.05)",
                                    transform: "scale(1.02)",
                                  },
                                }}
                              >
                                Cancel
                              </ArgonButton>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 1,
                          }}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  ))}
                </Grid>
              </Grid>
            </>
          }
        />
        <HostHiveDialog
          open={changeRequestsOpen}
          fullWidth={true}
          onSave={handleSubmit}
          onClose={() => setChangeRequestsOpen(false)}
          includeSave={true}
          includeClose={true}
          maxWidth="md"
          title="Change Requests"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          submitButtonText="Approve Selected"
          showStatusBadge={false}
          saveDisabled={selectedIds.length < 1}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <ArgonBox display="flex" justifyContent="center"></ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  {changeRequests?.map((changeRequest) => (
                    <ArgonBox
                      component="div"
                      display="flex"
                      flexDirection="column"
                      p={2}
                      m={0}
                      key={changeRequest?.id}
                    >
                      <ArgonBox>
                        <ArgonBox>
                          <FormControlLabel
                            key={changeRequest.id}
                            control={
                              <Checkbox
                                checked={selectedIds.includes(changeRequest.id)}
                                onChange={(event) =>
                                  handleCheckboxChange(event, changeRequest.id)
                                }
                              />
                            }
                            label={changeRequest?.serviceName || "No title"}
                          />
                        </ArgonBox>

                        {/* <ArgonBox>{booking?.title}</ArgonBox> */}

                        <ArgonBox
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <ArgonBox pl={2} display="flex" alignItems="center">
                            <ArgonBox display="flex" flexDirection="column">
                              <ArgonTypography variant="caption" color="text">
                                {changeRequest?.propertyName}
                              </ArgonTypography>
                              <ArgonTypography variant="caption" color="text">
                                {
                                  changeRequest?.entityObject?.assigneeInfo
                                    ?.name
                                }
                              </ArgonTypography>
                              <ArgonBox>
                                {/* <ArgonBox>
                  <ArgonTypography component="label" variant="caption">
                    {propertyAddress?.address1}
                  </ArgonTypography>
                </ArgonBox> */}
                                <ArgonBox>
                                  <ArgonTypography
                                    component="label"
                                    variant="caption"
                                  >
                                    Change Service date from{" "}
                                    <strong>
                                      {
                                        changeRequest?.changeRequestDetails
                                          ?.before?.serviceScheduleDate
                                      }
                                    </strong>{" "}
                                    to{" "}
                                    <strong>
                                      {
                                        changeRequest?.changeRequestDetails
                                          ?.after?.serviceScheduleDate
                                      }
                                    </strong>
                                  </ArgonTypography>
                                </ArgonBox>
                              </ArgonBox>
                              {/* <ArgonTypography variant="caption" color="text">
                KMB Realty LLC. - {name}
              </ArgonTypography> */}
                            </ArgonBox>
                          </ArgonBox>
                          <ArgonBox display="flex">
                            <ArgonBox mr={2}>
                              <ArgonButton
                                onClick={() =>
                                  handleChangeRequestApproval(
                                    changeRequest?.id,
                                    false
                                  )
                                }
                                variant="outlined"
                                color="error"
                                size="small"
                                iconOnly
                                circular
                                sx={({ functions: { pxToRem } }) => ({
                                  width: pxToRem(34),
                                  minWidth: pxToRem(34),
                                  height: pxToRem(34),
                                  minHeight: pxToRem(34),
                                })}
                              >
                                <CloseIcon>test</CloseIcon>
                              </ArgonButton>
                            </ArgonBox>
                            <ArgonBox>
                              <ArgonButton
                                onClick={() =>
                                  handleChangeRequestApproval(
                                    changeRequest?.id,
                                    true
                                  )
                                }
                                variant="outlined"
                                color="success"
                                size="small"
                                iconOnly
                                circular
                                sx={({ functions: { pxToRem } }) => ({
                                  width: pxToRem(34),
                                  minWidth: pxToRem(34),
                                  height: pxToRem(34),
                                  minHeight: pxToRem(34),
                                })}
                              >
                                <DoneIcon>test</DoneIcon>
                              </ArgonButton>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                        <Divider
                          sx={{
                            mt: 2,
                            mb: 1,
                          }}
                        />
                      </ArgonBox>
                    </ArgonBox>
                  ))}
                </Grid>
              </Grid>
            </>
          }
        />
      </ArgonBox>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
            >
              <Tab
                label={`My Open Requests (${openRequests?.length})`}
                icon={
                  <i
                    className="ni ni-app"
                    style={{ marginTop: "6px", marginRight: "8px" }}
                  />
                }
              />
              <Tab
                label={`Pending Approval (${changeRequests?.length})`}
                icon={
                  <i
                    className="ni ni-email-83"
                    style={{ marginTop: "6px", marginRight: "8px" }}
                  />
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <ArgonBox display="flex" justifyContent="center" pt={2}>
            {tabValue === 0 ? <OpenRequests /> : <PendingRequests />}
          </ArgonBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default ChangeRequests;
