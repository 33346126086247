import React, { useMemo, useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import AccountingApi from "api/accountingApi";
import { useTranslation } from "react-i18next";
import HostHiveDialog from "components/HostHiveDialog";
import PropertySelect from "layouts/management/components/PropertySelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import { convertTimeStamp } from "services/dateUtils";
import ArgonSelect from "components/ArgonSelect";

import ArgonInput from "components/ArgonInput";
import { split } from "lodash";
import { use } from "i18next";
const MemoizedArgonSelect = React.memo(ArgonSelect);
function SplitTransactionItem({
  transaction,
  memoizedCategories,
  memoizedProperties,
  splitTransactionOpen,
  setSplitTransactionOpen,
  setSelectedIds,
  setTransactions,
}) {
  const { t } = useTranslation("common");
  const accountingApi = new AccountingApi();
  const [splits, setSplits] = useState([
    {
      ...transaction,
      categoryId: "",
      amount: "",
    },
  ]);

  const handleSplitChange = (index, fieldName, value) => {
    let splitValue;
    if (fieldName === "propertyId" || fieldName === "categoryId") {
      splitValue = value.value;
    } else {
      splitValue = parseFloat(value) || null;
    }

    setSplits((splits) =>
      splits.map((split, i) =>
        i === index ? { ...split, [fieldName]: splitValue || null } : split
      )
    );
    let fieldValue;
    if (fieldName === "categoryId") {
      fieldValue = "categorySelectValue";
    } else {
      fieldValue = "propertySelectValue";
    }
    if (fieldName === "categoryId" || fieldName === "propertyId") {
      setSplits((splits) =>
        splits.map((split, i) =>
          i === index ? { ...split, [fieldValue]: value || null } : split
        )
      );
    }
  };

  const addSplit = () => {
    setSplits([...splits, { ...transaction, categoryId: "", amount: "" }]);
  };

  const removeSplit = (index) => {
    setSplits(splits.filter((_, i) => i !== index));
  };

  const [splitTotalTarget, setSplitTotalTarget] = useState(transaction.amount);
  const splitTotal = splits.reduce(
    (total, split) => total + (split.amount || 0),
    0
  );

  const handleSplitSubmit = async () => {
    const newTransactions = splits.map((split) => {
      return {
        companyId: transaction.companyId,
        amount: parseFloat(split.amount),
        categoryId: split.categoryId,
        propertyId: split.propertyId,
      };
    });
    console.log(newTransactions);
    try {
      const response = await accountingApi.splitTransaction(transaction.id, {
        newTransactions: newTransactions,
      });
      console.log("Split Transaction Response", response);
      setTransactions((prevTransactions) =>
        prevTransactions.filter(
          (exTransaction) => exTransaction.id !== transaction.id
        )
      );

      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selected) => selected.id !== transaction?.id)
      );
    } catch (error) {
      console.error(error);
    }

    setSplitTransactionOpen(false);
  };

  const [workingTransactions, setWorkingTransactions] = useState(null);
  const [splitSaveDisabled, setSplitSaveDisabled] = useState(true);
  useEffect(() => {
    // Check if split total is 0 or not within 0.01 of the target total
    const splitTotalDisable =
      Math.abs(splitTotal - splitTotalTarget) > 0.01 || splitTotal === 0;

    // Check for missing required fields in any of the splits
    const saveDisabledMissingRequiredFields = splits.some(
      (split) =>
        split.categorySelectValue?.value === undefined ||
        split.propertySelectValue?.value === undefined
    );

    // Disable the save button if either of the conditions is true
    const isSaveDisabled =
      splitTotalDisable || saveDisabledMissingRequiredFields;

    console.log("isSaveDisabled", isSaveDisabled);
    setSplitSaveDisabled(isSaveDisabled);
  }, [splitTotal, splitTotalTarget, splits]);

  return (
    <>
      <ArgonBox
        component="div"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        key={transaction?.id}
      >
        <HostHiveDialog
          open={splitTransactionOpen}
          includeDelete={false}
          deleteButtonText="Decline Selected"
          // deleteDisabled={selectedIds.length < 1}
          fullWidth={true}
          onSave={handleSplitSubmit}
          onClose={() => setSplitTransactionOpen(false)}
          includeSave={true}
          includeClose={true}
          maxWidth="md"
          title="Split Transactions"
          fullScreen={false}
          cancelButtonText={t("buttons.close")}
          submitButtonText="Approve Selected"
          saveDisabled={splitSaveDisabled}
          showStatusBadge={false}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <>
              <ArgonBox pl={2}>
                <ArgonBox>Summary: {transaction?.summary}</ArgonBox>
                <ArgonBox>Org. Amount: ${transaction?.amount}</ArgonBox>
              </ArgonBox>

              <>
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonTypography
                    variant="body1"
                    fontWeight="bold"
                    color="text"
                  >
                    Remaining to split
                  </ArgonTypography>
                  <ArgonTypography
                    pl={1}
                    variant="body1"
                    fontWeight="bold"
                    color={
                      splitTotal < 0
                        ? "error"
                        : splitTotal === 0
                        ? "text"
                        : "success"
                    }
                  >
                    $
                    {(
                      parseFloat(splitTotalTarget) - parseFloat(splitTotal)
                    ).toFixed(2)}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox p={2}>
                  {splits.map((split, index) => (
                    <React.Fragment key={index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ArgonTypography
                            variant="subtitle1"
                            fontWeight="bold"
                          >
                            Split {index + 1}
                          </ArgonTypography>
                        </Grid>

                        <Grid item xs={12} sm={5}>
                          <MemoizedArgonSelect
                            label="Property"
                            value={split.propertySelectValue}
                            onChange={(e) =>
                              handleSplitChange(index, "propertyId", e)
                            }
                            options={memoizedProperties}
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                          <MemoizedArgonSelect
                            label="Category"
                            value={split.categorySelectValue}
                            onChange={(e) =>
                              handleSplitChange(index, "categoryId", e)
                            }
                            options={memoizedCategories}
                            fullWidth
                            defaultValue={{
                              label: "Select a Category",
                              value: "none",
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                          <ArgonInput
                            label="Amount"
                            value={split.amount}
                            onChange={(e) =>
                              handleSplitChange(index, "amount", e.target.value)
                            }
                            fullWidth
                            type="number"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <ArgonButton
                            color="error"
                            onClick={() => removeSplit(index)}
                            disabled={splits.length === 1}
                          >
                            Remove Split
                          </ArgonButton>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  ))}
                  <ArgonButton color="primary" onClick={addSplit}>
                    Add Split
                  </ArgonButton>
                </ArgonBox>
              </>
            </>
          }
        />
      </ArgonBox>
    </>
  );
}

export default SplitTransactionItem;
