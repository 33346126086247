/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
// Custom styles for ComplexProjectCard
function PropertiesCard({ title, isCompanySettingsRoute = false }) {
  const propertiesApi = new PropertiesApi();
  const [propertyCounts, setPropertyCounts] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    const loadData = async () => {
      try {
        const countsResponse = await propertiesApi.getPropertyCounts(
          company?.id
        );

        if (countsResponse.status >= 200 && countsResponse.status < 300) {
          setPropertyCounts(countsResponse?.data);
          setIsLoading(false);
        }
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
      }
    };
    loadData();
  }, []);
  return (
    <ArgonBox p={2}>
      {isCompanySettingsRoute ? (
        <>
          <ArgonBox display="flex" alignItems="center">
            <ArgonBox ml={2} lineHeight={0}>
              <ArgonBox mb={1} lineHeight={0}>
                <ArgonTypography
                  variant="h6"
                  textTransform="capitalize"
                  fontWeight="medium"
                >
                  {title}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
            {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
          </ArgonBox>
        </>
      ) : null}

      <>
        {!isLoading ? (
          <>
            <ArgonBox ml={2} lineHeight={0}>
              <ArgonBox display="flex" justifyContent="space-between">
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color="text"
                  >
                    Active
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {propertyCounts?.active}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
              <ArgonBox display="flex" justifyContent="space-between">
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color="text"
                  >
                    Inactive
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {propertyCounts?.inactive}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
              {/* <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="medium" color="text">
              Sqft
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              1
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox> */}
            </ArgonBox>
            <Divider />
            <ArgonBox
              ml={2}
              lineHeight={0}
              display="flex"
              justifyContent="space-between"
            >
              <ArgonBox>
                <MuiLink href={`/properties`} rel="noreferrer">
                  <ArgonTypography
                    sx={{
                      ":hover": { textDecoration: "underline" },
                      cursor: "pointer",
                    }}
                    variant="button"
                    fontWeight="medium"
                    color="text"
                  >
                    View Properties
                  </ArgonTypography>
                </MuiLink>
              </ArgonBox>
            </ArgonBox>
          </>
        ) : (
          <HostHiveLoading />
        )}
      </>
    </ArgonBox>
  );
}

// Setting default values for the props of ComplexProjectCard
PropertiesCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
PropertiesCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
};

export default PropertiesCard;
