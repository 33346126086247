/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";

import { useState, useEffect } from "react";
// @mui material components

import {
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import Header from "layouts/administration/support/components/SupportItem/components/Header";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveLoading from "components/HostHiveLoading";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBadge from "components/ArgonBadge";
import { useTranslation } from "react-i18next";
import ReadOnlyPropertyDetails from "layouts/management/properties/properties-list/components/read-only-property-details";
import SupportTicket from "layouts/administration/support/components/SupportTicket";
function SupportItem({ supportItem, onClose, fetchSupportItem }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const navigate = useNavigate();
  const [savingAssignment, setSavingAssignment] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const { t } = useTranslation("common");
  const [showPropertyDataCard, setShowPropertyDataCard] = useState(false);
  const [supportTicketDialogOpen, setSupportTicketDialogOpen] = useState(false);
  return (
    <>
      <Header
        title={supportItem?.summary}
        subtitle={`#${supportItem?.serviceDeskKey}`}
        supportItem={supportItem}
        fetchSupportItem={fetchSupportItem}
      />
      <ArgonBox pb={2}></ArgonBox>
      <HostHiveDialog
        open={supportTicketDialogOpen}
        // onClose={handleCancelClicked}
        // onSave={handleSubmitClicked}
        includeSave={false}
        includeClose={false}
        title="View Support Case"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <SupportTicket
            onClose={() => setSupportTicketDialogOpen(false)}
            supportItem={supportItem}
            edit={true}
            fetchSupportItem={fetchSupportItem}
          />
        }
      />
      <Card>
        {!savingAssignment ? (
          <>
            <ArgonBox pl={2} pb={3}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Summary:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {supportItem?.summary}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      description:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {supportItem?.description}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Property:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": { textDecoration: "underline" },
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPropertyDataCard(true)}
                    >
                      {" "}
                      {supportItem?.propertyInfo?.name}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid> */}

                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Comments:
                    </ArgonTypography>
                  </ArgonBox>
                  <HostHiveCommentSection
                    title=""
                    entityType="supportTicket"
                    entityId={supportItem?.id}
                    disabled={supportItem?.status === "resolved"}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox pl={2} pb={3}></ArgonBox>

            <Grid container spacing={3} pb={3}>
              <Grid item xs={6}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={() => navigate(-1)}
                  >
                    Return
                  </ArgonButton>
                </ArgonBox>
              </Grid>
              <Grid item xs={6}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonButton
                    disabled={supportItem?.status === "resolved"}
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => setSupportTicketDialogOpen(true)}
                  >
                    Edit
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <ArgonTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
              {loadingText}
            </ArgonTypography>
            <HostHiveLoading />
          </>
        )}
      </Card>
    </>
  );
}

export default SupportItem;
