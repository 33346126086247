/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import HostHiveDialog from "components/HostHiveDialog";
import SupportTicket from "layouts/administration/support/components/SupportTicket";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ComplexProjectCard from "layouts/management/bookingReports/propertyBookingReportList/components/ComplexProjectCard";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import HostHiveDataTable from "components/HostHiveDataTable";
import useMediaQuery from "@mui/material/useMediaQuery";
// Data
//import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import ContactSupportApi from "api/contactSupportApi";
import {
  columns,
  smColumns,
} from "layouts/administration/support/components/supportTicketColumns";
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import { VideoLibraryHelper } from "services/videoLibraryHelper";
const dataTableData = {
  columns: [],

  rows: [],
};

function GetSupport() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const contactSupportApi = ContactSupportApi();
  const [activeOnly, setActiveOnly] = useState(true);
  const [serviceDeskTickets, setServiceDeskTickets] = useState([]);
  const getSupportVideoId = VideoLibraryHelper("getSupport").videoId;
  const [serviceDeskTableData, setServiceDeskTableData] = useState({
    columns: columns,
    rows: [
      {
        summary: "",
        serviceDeskKey: "",
        status: "",
      },
    ],
  });
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    async function fetchSupportTickets() {
      try {
        let params = {
          companyId: company?.id,
        };
        if (activeOnly) {
          params.status = "active";
        }
        const response = await contactSupportApi.getSupportTickets(params);
        console.log("Ticket response", response);
        if (response.status >= 200 && response.status < 300) {
          setServiceDeskTableData({
            columns: serviceDeskTableData?.columns,
            rows: response.data,
          });
          setServiceDeskTickets(response.data);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        console.log("Error getting booking report", error);
      }
    }
    fetchSupportTickets();
  }, [activeOnly]);
  const [newTicketDialogOpen, setNewTicketDialogOpen] = useState(false);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox my={3}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        ></ArgonBox>
        <HostHiveDialog
          open={newTicketDialogOpen}
          // onClose={handleCancelClicked}
          // onSave={handleSubmitClicked}
          includeSave={false}
          includeClose={false}
          title="New Support Case"
          fullScreen={false}
          maxWidth="sm"
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <SupportTicket
              onClose={() => setNewTicketDialogOpen(false)}
              // setFetchTransactions={() =>
              //   setFetchTransactions(!fetchTransactions)
              // }
            />
          }
        />

        <Card>
          <HostHiveDataTable
            header="Open Support Cases"
            table={serviceDeskTableData}
            canSearch
            newEntryAction={() => setNewTicketDialogOpen(true)}
            newEntryText="New Support Case"
            newEntryColor="info"
            newEntryVariant="outlined"
            activeOnly={activeOnly}
            setActiveOnly={setActiveOnly}
            newEntryVideoHelp={getSupportVideoId}
          />
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GetSupport;
