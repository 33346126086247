import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import GroupsApi from "api/groupsApi";
import { useMemo, useState, useEffect, useContext } from "react";

function GroupLinks({
  group,
  groupAssociations,
  setGroupAssociations,
  propertiesAdded,
  setPropertiesAdded,
  propertiesRemoved,
  setPropertiesRemoved,
}) {
  //   const { t } = useTranslation();
  const groupsApi = GroupsApi();
  const [properties, setProperties] = useState([]);
  const company = JSON.parse(localStorage.getItem("companyDetails"));

  useEffect(() => {
    async function loadAssociations() {
      try {
        const queryParams = {
          companyId: company?.id,
        };
        const groups = await groupsApi.getGroupPropertyAssociations(
          group.id,
          queryParams
        );
        setGroupAssociations(groups?.data);
      } catch (error) {
        console.log(error);
      }
    }
    setPropertiesAdded([]);
    setPropertiesRemoved([]);
    loadAssociations();
  }, []);

  const handlePropertySelected = (property, event) => {
    let updateAssociation = event.target.checked;
    if (updateAssociation === true) {
      setPropertiesAdded([...propertiesAdded, property.propertyId]);
      setPropertiesRemoved(
        propertiesRemoved.filter(
          (prop) => prop.propertyId !== property.propertyId
        )
      );
    } else {
      setPropertiesRemoved([...propertiesRemoved, property.propertyId]);
      setPropertiesAdded(
        propertiesAdded.filter(
          (prop) => prop.propertyId !== property.propertyId
        )
      );
    }

    setGroupAssociations(
      groupAssociations.map((groupAssociation) => {
        if (groupAssociation.propertyId === property.propertyId) {
          return {
            ...groupAssociation,
            groupId: event.target.checked ? group.id : null,
          };
        }
        return groupAssociation;
      })
    );

    console.log("groupAssociations", groupAssociations);
  };

  return (
    <ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ArgonTypography variant="h6" fontWeight="medium">
            Properties
          </ArgonTypography>
        </Grid>

        {groupAssociations?.map((property) => (
          <Grid item xs={12} md={4} key={property.id}>
            <ArgonBox display="flex" alignItems="center">
              <Checkbox
                checked={property?.groupId ? true : false}
                onChange={(event) => handlePropertySelected(property, event)}
              />
              <ArgonBox ml={1}>
                <ArgonTypography variant="caption" fontWeight="medium">
                  {property.propertyName}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Grid>
        ))}
      </Grid>
    </ArgonBox>
  );
}

export default GroupLinks;
