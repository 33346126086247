/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";
import Slider from "@mui/material/Slider";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ServicePartnersSelect from "layouts/management/components/ServicePartnerSelect";
import UsersSelect from "layouts/management/components/UsersSelect";
import CompaniesApi from "api/companyApi";
import UsersApi from "api/usersApi";
import BookingsApi from "api/bookingsApi";
import HostHiveDialog from "components/HostHiveDialog";
import ServiceNameItemDialog from "layouts/management/company/settings/components/ServiceNames/components/ServiceNameItemDialog";
import ServicePartnerItemDialog from "layouts/management/company/settings/components/ServicePartners/components/ServicePartnerItemDialog";

import { UserAuth } from "context/AuthContext";
import { set } from "lodash";

function SubAssigneeSelection({
  bookingPayload,
  setBookingPayload,
  setBookingAssignees,
  bookingAssignees,
}) {
  const companiesApi = CompaniesApi();
  const bookingsApi = BookingsApi();
  const usersApi = UsersApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [updateMaxTeamMembers, setUpdateMaxTeamMembers] = useState(false);
  const [servicePartners, setServicePartners] = useState([]);
  const [users, setUsers] = useState([]);
  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();
  const [maxSubAssignees, setMaxSubAssignees] = useState(0);
  // const [unlimitedSubAssignees, setUnlimitedSubAssignees] = useState(false);
  const [maxReached, setMaxReached] = useState(false);
  const [newMax, setNewMax] = useState(0);
  useEffect(() => {
    async function fetchUsers() {
      // console.log("Max: ", bookingPayload.maxSubAssignees);
      setMaxSubAssignees(bookingPayload?.maxSubAssignees);

      if (
        bookingPayload?.maxSubAssignees === 0 ||
        bookingPayload?.maxSubAssignees === null ||
        bookingPayload?.maxSubAssignees === undefined
      ) {
        setMaxReached(false);
      } else if (bookingAssignees.length >= bookingPayload?.maxSubAssignees) {
        setMaxReached(true);
      } else {
        setMaxReached(false);
      }

      try {
        const queryParams = {
          companyId: company?.id,
        };
        const response = await usersApi.getUsers(queryParams);
        let usersResponse = response.data;

        if (role !== "admin") {
          // Ensure usersResponse and user are defined to avoid runtime errors
          if (usersResponse && user?.uid) {
            usersResponse = usersResponse.filter(
              (userEntry) => userEntry.id === user?.uid
            );
          } else {
            // Handle the case where usersResponse or user.uid is undefined
            console.warn("usersResponse or user.uid is undefined");
          }
        }
        console.log("usersResponse", usersResponse, response.data, user?.uid);

        let usersOptions = usersResponse.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        //filter out assigneeId from bookingAssignees
        let assigneeIds = bookingAssignees.map((assignee) => assignee.value);

        usersOptions = usersOptions.filter(
          (user) => !assigneeIds.includes(user.value)
        );

        setUsers(usersOptions);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsers();
  }, []);

  const updateMaxReached = (increment) => {
    let currentCount = bookingAssignees.length;
    if (increment) {
      currentCount += 1;
    } else {
      currentCount -= 1;
    }
    if (
      maxSubAssignees === null ||
      maxSubAssignees === 0 ||
      maxSubAssignees === undefined
    ) {
      setMaxReached(false);
      return;
    }
    if (currentCount >= maxSubAssignees) {
      setMaxReached(true);
    } else {
      setMaxReached(false);
    }
  };

  const handleUserSelected = async (e) => {
    try {
      console.log(e);

      //addUserToBooking
      await bookingsApi.addUserToBooking(bookingPayload?.id, e.value);

      let updatedSelectedUsers = [...bookingAssignees];
      updatedSelectedUsers.push(e);
      setBookingAssignees(updatedSelectedUsers);

      // remove user from the list of users
      let updatedUsers = users.filter((user) => user.value !== e.value);
      setUsers(updatedUsers);
      updateMaxReached(true);
    } catch (error) {
      console.error(error);
    }
  };

  // const [bookingAssignees, setBookingAssignees] = useState([]);
  const handleRemoveFromBooking = async (userId) => {
    try {
      await bookingsApi.removeUserFromBooking(bookingPayload?.id, userId);
      let updatedSelectedUsers = bookingAssignees.filter(
        (user) => user.value !== userId
      );
      setBookingAssignees(updatedSelectedUsers);

      // add user back to the list of users
      let updatedUsers = users;
      let user = bookingAssignees.find((user) => user.value === userId);
      updatedUsers.push(user);
      setUsers(updatedUsers);
      updateMaxReached(false);
      console.log("Remove user from booking");
      // handleRefetchBookings();
    } catch (error) {
      console.error(error);
    }

    // remove userId from the list of selected users
  };

  const handleSaveMaxTeamMembers = async () => {
    try {
      await bookingsApi.updateMaxBookingAssignees(bookingPayload?.id, newMax);
      setUpdateMaxTeamMembers(false);
      setMaxSubAssignees(newMax);
      let updatedBookingPayload = { ...bookingPayload };
      updatedBookingPayload.maxSubAssignees = newMax;
      setBookingPayload(updatedBookingPayload);

      if (
        newMax === 0 ||
        newMax === null ||
        newMax === undefined ||
        bookingAssignees.length < newMax
      ) {
        setMaxReached(false);
      } else if (bookingAssignees.length >= newMax) {
        setMaxReached(true);
      }
    } catch (error) {
      console.error("Error updating max team members", error);
    }
  };

  const handleSettingChange = (newValue) => {
    // const newSettings = { ...settings };
    // newSettings[section].settings[index].value = newValue;
    // setSettings(newSettings);
    console.log(newValue);
    setNewMax(newValue);
  };

  return (
    <Grid container spacing={2}>
      <HostHiveDialog
        includeDelete={false}
        includeSave={true}
        onSave={handleSaveMaxTeamMembers}
        open={updateMaxTeamMembers}
        onClose={() => {
          setUpdateMaxTeamMembers(false);
        }}
        title="Property Details"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
            <>
              <ArgonBox>
                <ArgonBox sx={{ width: 300 }}>
                  <Slider
                    value={newMax}
                    onChange={(newValue) =>
                      handleSettingChange(newValue.target.value)
                    }
                  />
                </ArgonBox>
                <ArgonTypography variant="caption">
                  Max Team Members: {newMax}
                </ArgonTypography>
              </ArgonBox>
            </>
          </>
        }
      />
      <Grid item xs={12}>
        <ArgonBox>
          <ArgonBox lineHeight={0}>
            {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Assign a User to this Booking (Max:{" "}
              {maxSubAssignees === 0 || maxSubAssignees === null
                ? "Unlimited"
                : maxSubAssignees}
              )
            </ArgonTypography>
          </ArgonBox>
          {role === "admin" && (
            <ArgonBox>
              <ArgonTypography
                variant="caption"
                textTransform="capitalize"
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                    color: "primary.main",
                  },
                  cursor: "pointer",
                  fontStyle: "italic",
                }}
                onClick={() => setUpdateMaxTeamMembers(true)}
              >
                Edit Max Team Members
              </ArgonTypography>
            </ArgonBox>
          )}
          <ArgonBox lineHeight={1}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
              pb={2}
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Users
                </ArgonTypography>
              </ArgonBox>
              {/* {maxSubAssignees > 0 && bookingAssignees} */}

              {maxReached ? null : (
                <ArgonSelect
                  menuPlacement="bottom"
                  value={{
                    value: "none",
                    label: "Select a User",
                  }}
                  // value={user ? user : { value: "none", label: "Select a User" }}
                  options={users}
                  onChange={handleUserSelected}
                  // isMulti
                />
              )}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>

        {bookingAssignees.length > 0 ? (
          <ArgonBox pl={2}>
            <Grid container spacing={1} flexDirection="column">
              {bookingAssignees.map((assignee) => (
                <ArgonBox alignItems="center" alignContent="center">
                  <Grid>
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {assignee.label}
                    </ArgonTypography>
                    {assignee.value === user?.uid || role === "admin" ? (
                      <Icon
                        color="error"
                        fontSize="default"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                            color: "primary.main",
                          },
                          cursor: "pointer",
                          fontStyle: "italic",
                        }}
                        onClick={() => handleRemoveFromBooking(assignee.value)}
                      >
                        <RemoveCircleOutlineIcon />
                      </Icon>
                    ) : null}
                  </Grid>
                </ArgonBox>
              ))}
            </Grid>
          </ArgonBox>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default SubAssigneeSelection;
