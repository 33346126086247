/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import SettingsApi from "api/settingsApi";
function PropertyManagementItemDialog({
  assigneeServicePartnerId,
  companyId,
  propertyId,
  availableUsers,
  currentValue,
  handleClose,
  snackBarOpen,
  fetchPropertySettings,
}) {
  const settingsApi = new SettingsApi();
  const [subAssignee, setSubassignee] = useState(currentValue);
  const handleSubmitClicked = async () => {
    try {
      console.log("handleSubmitClicked", currentValue);
      // if (subAssignee.value === "") {
      //   subAssignee.value = null;
      // }
      let payload = {
        userId: subAssignee.value,
        defaultServiceProvider: assigneeServicePartnerId,
        companyId: companyId,
      };
      const response = await settingsApi.updateSubAssignee(propertyId, payload);
      if (snackBarOpen) {
        snackBarOpen(
          `Assignee updated successfully`,
          `Successfully updated property assignee`
        );
      }
      fetchPropertySettings();
      handleClose();
    } catch (error) {
      console.log("handleSubmitClicked error", error);
    }
  };

  // Update availableUsers with option to clear
  availableUsers.push({ label: "Clear", value: "" });

  return (
    <ArgonBox p={2} lineHeight={1}>
      <ArgonBox mb={2}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              Assignee
            </ArgonTypography>
          </ArgonBox>

          <ArgonSelect
            options={availableUsers}
            value={subAssignee}
            onChange={(e) => setSubassignee(e)}
          />
        </ArgonBox>
      </ArgonBox>
      <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        <ArgonBox p={1}>
          <ArgonBox display="flex" justifyContent="flex-end" height="100%">
            <ArgonBox pr={2}>
              <ArgonButton
                variant="gradient"
                color="dark"
                onClick={handleClose}
              >
                Cancel
              </ArgonButton>
            </ArgonBox>
            <ArgonBox pr={2}>
              <ArgonButton
                variant="gradient"
                color="info"
                onClick={handleSubmitClicked}
              >
                Update
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        {/* Display role checkboxes here based on viewMode */}
      </ArgonBox>
    </ArgonBox>
  );
}

export default PropertyManagementItemDialog;
