import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import Menu from "@mui/material/Menu";
import { IconButton, MenuItem } from "@mui/material";
// Data

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";

import { Grid, Icon } from "@mui/material";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonInput from "components/ArgonInput";
import { set } from "lodash";
import ArgonSelect from "components/ArgonSelect";
function PropertyItem({ property, refetchProperties }) {
  useEffect(() => {
    console.log("Property", property);
    // Generate a new password as property firstName initial + lastName + current year
  }, []);
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const administrationApi = new AdministrationApi();

  const deleteProperty = async () => {
    try {
      await administrationApi.deleteProperty(property.companyId, property.id);
      refetchProperties();
    } catch (error) {
      console.error(error);
    }
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          deleteProperty();
          setOpenMenu(null);
        }}
      >
        Delete Property
      </MenuItem>
    </Menu>
  );

  return (
    <>
      {renderMenu()}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {property.name}
            </ArgonTypography>
          </ArgonBox>
        </Grid>

        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {convertTimeStamp(property.created, "America/New_York")}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {property.status}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        {property.status !== "deleted" && (
          <Grid item xs={12} sm={3}>
            <IconButton onClick={handleOpenMenu}>
              <Icon>more_vert</Icon>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PropertyItem;
