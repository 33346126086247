/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import HostHiveModal from "components/HostHiveModal";
import SettingsDataCard from "layouts/management/components/ProfileSettingsDataCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import UsersApi from "api/usersApi";
// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import HostHiveDialog from "components/HostHiveDialog";
import UserDialog from "layouts/management/users/new-user/userDialog";
function ProfileInfoCard({ title, info, userDetails, setUserDetails, action }) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  const [profileSettingsOpen, setProfileSettingsOpen] = useState(false);

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <ArgonTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </ArgonTypography>
        <ArgonTypography
          pl={3}
          variant="caption"
          textTransform="capitalize"
          sx={{
            ":hover": {
              textDecoration: "underline",
            },
            cursor: "pointer",
          }}
          onClick={() => setProfileSettingsOpen(true)}
        >
          Edit
        </ArgonTypography>

        <HostHiveDialog
          open={profileSettingsOpen}
          includeClose={false}
          includeSave={false}
          onClose={() => {
            setProfileSettingsOpen(false);
          }}
          title="View User"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <UserDialog
              user={userDetails}
              setUser={setUserDetails}
              handleClose={() => setProfileSettingsOpen(false)}
              ownUser={true}
            />
          }
        />
      </ArgonBox>
      <ArgonBox p={2}>
        {/* <ArgonBox opacity={0.3}>
          <Divider />
        </ArgonBox> */}
        <ArgonBox>
          <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Full Name: &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{userDetails.firstName} {userDetails.lastName}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Phone Number: &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{userDetails.phoneNumber}
            </ArgonTypography>
          </ArgonBox>

          <ArgonBox display="flex" py={1} pr={2}>
            <ArgonTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Email Address: &nbsp;
            </ArgonTypography>
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{userDetails.email}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,

  info: PropTypes.objectOf(PropTypes.string).isRequired,

  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileInfoCard;
