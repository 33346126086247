/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonDatePicker from "components/ArgonDatePicker";
import { useTranslation } from "react-i18next";
import HostHiveLoading from "components/HostHiveLoading";
function DefaultStatisticsCard({
  title,
  count,
  percentage,
  dropdown,
  stringDateRange,
  setEndDate,
  setStartDate,
  loadingTransactionData,
}) {
  const { t } = useTranslation("common");
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [startFilterStage, setStartFilterStage] = useState(null);
  const [endFilterStage, setEndFilterStage] = useState(null);
  const handleChangeFilterDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (setStartDate && setEndDate) {
      if (param === "startDate") {
        setStartFilterStage(useDate);
      } else {
        setEndFilterStage(useDate);
      }
    }
  };

  const handleApplyFilterDate = () => {
    if (setStartDate && setEndDate) {
      setStartDate(startFilterStage);
      setEndDate(endFilterStage);
      setDateFilterOpen(false);
    }
  };
  return (
    <Card>
      <ArgonBox p={2}>
        <Grid container>
          <HostHiveDialog
            open={dateFilterOpen}
            onClose={() => setDateFilterOpen(false)}
            onSave={handleApplyFilterDate}
            fullWidth={true}
            includeClose={true}
            includeSave={true}
            maxWidth="md"
            title="Filter by Date"
            fullScreen={false}
            cancelButtonText={t("buttons.cancel")}
            submitButtonText={t("buttons.apply")}
            dialogObjects={
              // <Grid container spacing={2} alignItems="center">
              //   <Grid item xs={12}>
              //     <PropertySelect
              //       property={property}
              //       setProperty={setProperty}
              //       filterSelection={true}
              //     />
              //   </Grid>
              // </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      From Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "startDate")}
                    />
                  </ArgonBox>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      To Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "endDate")}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>
            }
          />
          <Grid item xs={12}>
            <ArgonBox mb={0.5} lineHeight={1}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              >
                {title}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox lineHeight={1}>
              {loadingTransactionData ? (
                <HostHiveLoading />
              ) : (
                <ArgonTypography variant="h5" fontWeight="bold">
                  {count}
                </ArgonTypography>
              )}
              <ArgonTypography
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.value}&nbsp;
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="secondary"
                >
                  {percentage.label}
                </ArgonTypography>
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={0.5} lineHeight={1}>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
                textTransform="capitalize"
              ></ArgonTypography>
            </ArgonBox>
          </Grid>
          {/* <Grid item xs={12}>
            <ArgonBox width="100%" textAlign="right" lineHeight={1}>
              <ArgonTypography
                variant="caption"
                color="secondary"
                sx={{ cursor: "pointer" }}
                onClick={() => setDateFilterOpen(true)}
              >
                {stringDateRange?.startDate ? stringDateRange?.startDate : null}{" "}
                - {stringDateRange?.endDate ? stringDateRange?.endDate : null}
              </ArgonTypography>
            </ArgonBox>
          </Grid> */}
          {/*  */}
        </Grid>
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of DefaultStatisticsCard
DefaultStatisticsCard.defaultProps = {
  percentage: {
    color: "success",
    value: "",
    label: "",
  },
  dropdown: false,
};

// Typechecking props for the DefaultStatisticsCard
DefaultStatisticsCard.propTypes = {
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
      value: PropTypes.string,
    }),
  ]),
};

export default DefaultStatisticsCard;
