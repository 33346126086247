// App.js
import React from "react";

import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";

import InventoryApi from "api/inventoryApi";
import ArgonAlert from "components/ArgonAlert";
import borders from "assets/theme/base/borders";
import ArgonButton from "components/ArgonButton";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { convertTimeStamp, timeAgo } from "services/dateUtils";
import MessagesApi from "api/messagesApi";
import {
  Box,
  Card,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  CardContent,
  CardActions,
  Paper,
} from "@mui/material";
import ArgonInput from "components/ArgonInput";
import { set } from "lodash";
import ArgonSelect from "components/ArgonSelect";
import HostHiveDialog from "components/HostHiveDialog";
function MessageItem({ message }) {
  const [showMessage, setShowMessage] = useState(false);
  return (
    <>
      <Card sx={{ mb: 3, overflow: "visible", boxShadow: 3 }}>
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <HostHiveDialog
            open={showMessage}
            fullWidth={true}
            includeClose={true}
            includeSave={false}
            maxWidth="lg"
            onClose={() => setShowMessage(false)}
            // onSave={handleSubmitClicked}
            title={message.subject}
            fullScreen={false}
            cancelButtonText="Close"
            submitButtonText="Save"
            dialogObjects={
              <Typography
                variant="body1"
                component="div"
                dangerouslySetInnerHTML={{ __html: message.message }}
              >
                {/* This children will not be rendered */}
              </Typography>
            }
          />
          <Avatar src={message.avatar} sx={{ width: 56, height: 56, mr: 2 }} />
          <Box flexGrow={1}>
            <Typography variant="h6" noWrap>
              {message.subject}
            </Typography>
            <Typography variant="body2" color="text.secondary" noWrap>
              From: {message.createdByFullName} - {timeAgo(message.created)}
            </Typography>
          </Box>
          <Tooltip
            title="More Options"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <IconButton>
              <Icon>more_vert</Icon>
            </IconButton>
          </Tooltip>
        </CardContent>
        {/* <HtmlContent html={message.message} /> */}
        <CardActions disableSpacing>
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={() => setShowMessage(true)}
          >
            Read more
          </Typography>
        </CardActions>
      </Card>
    </>
  );
}

export default MessageItem;
