import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function SettingsApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("common", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getSetting = (type, entityId, queryParams) => {
    return axiosPrivate.get(`/settings/${type}/${entityId}`, {
      params: queryParams,
    });
  };

  const getPropertyManagementSetting = (type, companyId, queryParams) => {
    return axiosPrivate.get(`/settings/management/${type}/all/${companyId}`, {
      params: queryParams,
    });
  };

  const upsertSetting = (type, entityId, settingsData) => {
    return axiosPrivate.put(`/settings/${type}/${entityId}`, settingsData);
  };

  const updateSubAssignee = (propertyId, settingsData) => {
    return axiosPrivate.put(
      `/settings/management/properties/subassignee/${propertyId}`,
      settingsData
    );
  };

  const unassignSubAssignee = (propertyId) => {
    return axiosPrivate.put(
      `/settings/management/properties/unassign/${propertyId}`
    );
  };

  const updateUserLanguage = (userId, settingsData) => {
    return axiosPrivate.put(`/settings/language/users/${userId}`, settingsData);
  };

  const deleteSetting = (type, entityId) => {
    return axiosPrivate.delete(`/settings/${type}/${entityId}`);
  };

  const getPropertyAvailbleIntegrations = (propertyId) => {
    return axiosPrivate.get(
      `/settings/integrations/available-types/${propertyId}`
    );
  };

  const updatePropertyIntegrationUrl = (integration_id, propertyData) => {
    return axiosPrivate.put(
      `/settings/integrations/${integration_id}/url`,
      propertyData
    );
  };

  //post("/integrations/{id}")

  const createIntegration = (propertyId, propertyData) => {
    return axiosPrivate.post(
      `/settings/integrations/${propertyId}`,
      propertyData
    );
  };

  const deleteIntegrationSetting = (integrationId) => {
    return axiosPrivate.delete(`/settings/integrations/${integrationId}`);
  };

  const updatePropertyIntegrationEnabled = (integration_id, status) => {
    return axiosPrivate.put(
      `/settings/integrations/${integration_id}/integration-enabled/${status}`
    );
  };

  return {
    getSetting,
    upsertSetting,
    deleteSetting,
    getPropertyManagementSetting,
    updateSubAssignee,
    unassignSubAssignee,
    updateUserLanguage,
    getPropertyAvailbleIntegrations,
    updatePropertyIntegrationUrl,
    createIntegration,
    deleteIntegrationSetting,
    updatePropertyIntegrationEnabled,
  };
}

export default SettingsApi;
