/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import convertToSelector from "services/selector";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Divider from "@mui/material/Divider";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonProgress from "components/ArgonProgress";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// Argon Dashboard 2 PRO MUI example components
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import HostHiveImages from "components/HostHiveImages";
// Argon Dashboard 2 PRO MUI base styles
import breakpoints from "assets/theme/base/breakpoints";
import HostHiveDialog from "components/HostHiveDialog";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import NotesDataCard from "layouts/management/components/Notes";
import DocumentsDataCard from "layouts/management/components/Documents";
import PropertyContext from "context/PropertyContext";
// Images
import burceMars from "assets/images/bruce-mars.jpg";
import ArgonSelect from "components/ArgonSelect";
import OccupancyStatusDialog from "layouts/management/components/OccupancyStatusDialog";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
import BookingsApi from "api/bookingsApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { useTranslation } from "react-i18next";
import TasksApi from "api/propertyTasksApi";
//  inProgress = "inProgress";
//  open = "open";
//  onHold = "onHold";
//  closed = "closed";
// if status == "open":
//         return "warning"
//     elif status == "inProgress":
//         return "info"
//     elif status == "on hold":
//         return "warning"
//     elif status == "closed":
//         return "success"
//     else:
//         return "error"
function Header({
  title,
  subtitle,
  taskItem,
  setShowEditDueDate,
  setShowEditAssignee,
  hasDueDate,
  hasAssigneeSubId,
  setTaskItemDiaglogOpen,
}) {
  const { t } = useTranslation("common");
  const tasksApi = new TasksApi();
  //taskItem?.statusInfoSelect?.color
  const [taskStatus, setTaskStatus] = useState(taskItem?.statusInfoSelect);
  const [taskStatusSelect, setTaskStatusSelect] = useState({
    label: (
      <ArgonBadge
        color={taskItem?.statusInfoSelect?.color}
        size="xs"
        badgeContent={t(
          `components.tasks.status.${taskItem?.statusInfoSelect?.value}`
        )}
        container
      />
    ),
    value: taskItem?.statusInfoSelect?.value,
  });
  const statusOptions = taskItem?.statusInfoOptions?.map((status) => {
    return {
      label: (
        <ArgonBadge
          color={status?.color}
          size="xs"
          badgeContent={t(`components.tasks.status.${status?.value}`)}
          container
        />
      ),
      value: status?.value,
    };
  });

  const [prioritySelect, setPrioritySelect] = useState({
    label: (
      <ArgonBox display="flex" alignItems="center">
        <ArgonBox
          variant="outlined"
          color={taskItem?.priorityInfo?.color}
          size="medium"
          iconOnly
        >
          <Icon sx={{ fontWeight: "bold" }}>
            {taskItem?.priorityInfo?.icon}
          </Icon>
        </ArgonBox>
        <ArgonTypography
          variant="body1"
          fontWeight="medium"
          color="textSecondary"
          textTransform="capitalize"
          sx={{ lineHeight: 0, marginLeft: "8px" }}
        >
          {taskItem?.priorityInfo?.priority}
        </ArgonTypography>
      </ArgonBox>
    ),
    value: taskItem?.priorityInfo?.priority,
  });

  const [savingStatusChange, setSavingStatusChange] = useState(false);
  const handleTaskStatusSelectChange = async (event) => {
    setSavingStatusChange(true);
    let value = event.value;

    try {
      const response = await tasksApi.updateTaskStatus(taskItem?.id, value);

      let statusInfo = taskItem?.statusInfoOptions?.find(
        (status) => status.value == value
      );

      console.log("statusInfo", statusInfo);
      setTaskStatus({
        color: statusInfo?.color,
        value: statusInfo?.value,
      });
      setTaskStatusSelect({
        label: (
          <ArgonBadge
            color={statusInfo?.color}
            size="xs"
            badgeContent={t(`components.tasks.status.${statusInfo?.value}`)}
            container
          />
        ),
        value: statusInfo?.value,
      });
    } catch (error) {
      console.error(error);
    }
    setSavingStatusChange(false);
  };
  const priority = [
    {
      id: "low",
      priority: "Low",
      color: "success",
      icon: "keyboard_double_arrow_down",
    },
    {
      id: "medium",
      priority: "Medium",
      color: "warning",
      icon: "keyboard_arrow_up",
    },
    {
      id: "high",
      priority: "High",
      color: "error",
      icon: "keyboard_double_arrow_up",
    },
  ];
  const [savingPriorityChange, setSavingPriorityChange] = useState(false);
  const handlePriotiySelectChange = async (event) => {
    setSavingPriorityChange(true);
    let value = event.value;
    console.log("value", value);
    try {
      const response = await tasksApi.updateTaskPriority(taskItem?.id, value);

      let updatedPriorityInfo = priority?.find(
        (priority) => priority.id == value
      );

      console.log("priority info", updatedPriorityInfo);
      setPrioritySelect({
        label: (
          <ArgonBox display="flex" alignItems="center">
            <ArgonBox
              variant="outlined"
              color={updatedPriorityInfo?.color}
              size="medium"
              iconOnly
            >
              <Icon sx={{ fontWeight: "bold", color: "success" }}>
                {updatedPriorityInfo?.icon}
              </Icon>
            </ArgonBox>
            <ArgonTypography
              variant="body1"
              fontWeight="medium"
              color="textSecondary"
              textTransform="capitalize"
              sx={{ lineHeight: 0, marginLeft: "8px" }}
            >
              {updatedPriorityInfo?.priority}
            </ArgonTypography>
          </ArgonBox>
        ),
        value: updatedPriorityInfo?.priority,
      });
      // setTaskStatusSelect({
      //   label: (
      //     <ArgonBadge
      //       color={statusInfo?.color}
      //       size="xs"
      //       badgeContent={t(`components.tasks.status.${statusInfo?.value}`)}
      //       container
      //     />
      //   ),
      //   value: statusInfo?.value,
      // });
    } catch (error) {
      console.error(error);
    }
    setSavingPriorityChange(false);
  };

  const priorityOptions = [
    {
      label: (
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox variant="outlined" color="success" size="medium" iconOnly>
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_down</Icon>
          </ArgonBox>
          <ArgonTypography
            variant="body2"
            fontWeight="medium"
            color="textSecondary"
            textTransform="capitalize"
            sx={{ lineHeight: 0, marginLeft: "8px" }}
          >
            Low
          </ArgonTypography>
        </ArgonBox>
      ),
      value: "low",
    },
    {
      label: (
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox variant="outlined" color="warning" size="medium" iconOnly>
            <Icon sx={{ fontWeight: "bold" }}>keyboard_arrow_up</Icon>
          </ArgonBox>
          <ArgonTypography
            variant="body2"
            fontWeight="medium"
            color="textSecondary"
            textTransform="capitalize"
            sx={{ lineHeight: 0, marginLeft: "8px" }}
          >
            Medium
          </ArgonTypography>
        </ArgonBox>
      ),
      value: "medium",
    },
    {
      label: (
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox variant="outlined" color="error" size="medium" iconOnly>
            <Icon sx={{ fontWeight: "bold" }}>keyboard_double_arrow_up</Icon>
          </ArgonBox>
          <ArgonTypography
            variant="body2"
            fontWeight="medium"
            color="textSecondary"
            textTransform="capitalize"
            sx={{ lineHeight: 0, marginLeft: "8px" }}
          >
            High
          </ArgonTypography>
        </ArgonBox>
      ),
      value: "high",
    },
  ];

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [statusSelectOpen, setStatusSelectOpen] = useState(false);
  const [taskPriorityOpen, setTaskPriorityOpen] = useState(false);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* setTaskItemDiaglogOpen */}
      <MenuItem
        onClick={() => {
          setTaskItemDiaglogOpen(true);
          handleCloseMenu();
        }}
      >
        Edit Task Details
      </MenuItem>
      <MenuItem
        onClick={() => {
          setStatusSelectOpen(true);
          handleCloseMenu();
        }}
      >
        Update Status
      </MenuItem>
      <MenuItem
        onClick={() => {
          setTaskPriorityOpen(true);
          handleCloseMenu();
        }}
      >
        Update Priority
      </MenuItem>
      <MenuItem
        onClick={() => {
          setShowEditDueDate(true);
          handleCloseMenu();
        }}
      >
        {hasDueDate ? "Change" : "Add"} Due Date
      </MenuItem>
      <MenuItem
        onClick={() => {
          setShowEditAssignee(true);
          handleCloseMenu();
        }}
      >
        {hasAssigneeSubId
          ? t("serviceAssignments.buttons.editAssignee")
          : "Add Assignee"}
      </MenuItem>
      {/* setShowEditDueDate, setShowEditAssignee, */}
    </Menu>
  );

  return (
    <ArgonBox position="relative">
      <Card
        sx={{
          py: 3,
          px: 3,
          boxShadow: ({ boxShadows: { md } }) => md,
          justifyContent: { xs: "center" },
        }}
      >
        <HostHiveDialog
          includeDelete={false}
          includeSave={true}
          open={taskPriorityOpen}
          onSave={() => {
            setTaskPriorityOpen(false);
          }}
          onClose={() => {
            setTaskPriorityOpen(false);
          }}
          title="Update Task Priority"
          fullScreen={false}
          maxWidth="xs"
          cancelButtonText="Close"
          submitButtonText="Save"
          dialogObjects={
            <>
              {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
              <ArgonSelect
                // disabled={savingStatusChange}
                options={priorityOptions}
                value={prioritySelect}
                onChange={(event) => handlePriotiySelectChange(event)}
              />
            </>
          }
        />
        <HostHiveDialog
          includeDelete={false}
          includeSave={true}
          open={statusSelectOpen}
          onSave={() => {
            setStatusSelectOpen(false);
          }}
          onClose={() => {
            setStatusSelectOpen(false);
          }}
          title="Update Task Status"
          fullScreen={false}
          maxWidth="xs"
          cancelButtonText="Close"
          submitButtonText="Save"
          dialogObjects={
            <>
              {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
              <ArgonSelect
                disabled={savingStatusChange}
                options={statusOptions}
                value={taskStatusSelect}
                onChange={(event) => handleTaskStatusSelectChange(event)}
              />
            </>
          }
        />
        <Grid container spacing={3} alignItems="center">
          <Grid item lg={5} sm={12}>
            <ArgonBox height="100%" mt={0.5} lineHeight={1}>
              <ArgonTypography variant="h4" fontWeight="bold">
                {title}
              </ArgonTypography>
              <ArgonTypography
                variant="h6"
                color="textSecondary"
                fontWeight="medium"
              >
                {subtitle}
              </ArgonTypography>
            </ArgonBox>
          </Grid>

          <Grid item lg={1} sm={12}>
            <Divider light orientation="vertical" />
          </Grid>

          <Grid item lg={6} sm={12}>
            <Grid container spacing={3} alignItems="center">
              <Grid item lg={6} md={6} sm={6}>
                {/* <ArgonBox display="flex" alignItems="center">
                  <ArgonButton
                    variant="outlined"
                    color={taskItem?.priorityInfo?.color}
                    size="medium"
                    iconOnly
                  >
                    <Icon sx={{ fontWeight: "bold" }}>
                      {taskItem?.priorityInfo?.icon}
                    </Icon>
                  </ArgonButton>
                  <ArgonTypography
                    variant="body1"
                    fontWeight="medium"
                    color="textSecondary"
                    textTransform="capitalize"
                    sx={{ lineHeight: 0, marginLeft: "8px" }}
                  >
                    {taskItem?.priorityInfo?.priority}
                  </ArgonTypography>
                </ArgonBox> */}
                {prioritySelect?.label}
              </Grid>

              <Grid item xs={12} md={8} xl={6}>
                {/* <ArgonBox
                  height="100%"
                  mt={0.5}
                  lineHeight={1}
                  display="flex"
                  width="100%"
                  pr={2}
                > */}
                <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                  {/*<IconButton>
                     <ArgonSelect
                      disabled={savingStatusChange}
                      options={statusOptions}
                      value={taskStatusSelect}
                      onChange={(event) => handleTaskStatusSelectChange(event)}
                    /> 
                  </IconButton>*/}
                  <ArgonBadge
                    color={taskStatus?.color}
                    size="xs"
                    badgeContent={t(
                      `components.tasks.status.${taskStatus?.value}`
                    )}
                    container
                  />
                  {/* <ArgonBox pl={1}>
                    {savingStatusChange ? (
                      <HostHiveLoading message="Updating" />
                    ) : null}
                  </ArgonBox> */}
                </ArgonBox>
              </Grid>
              {renderMenu()}
              <Grid
                item
                lg={12}
                sm={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <ArgonButton
                  disabled={taskItem?.status === "closed"}
                  variant="outlined"
                  color="secondary"
                  onClick={handleOpenMenu}
                  sx={{
                    ml: "auto",
                    alignSelf: "flex-start",
                    py: 1.25,
                    pr: 1.5,
                    borderRadius: "8px",
                    borderWidth: "1px",
                    transition: "background-color 0.3s, transform 0.2s",
                    ":hover": {
                      backgroundColor: "rgba(0,0,0,0.05)",
                      transform: "scale(1.02)",
                    },
                  }}
                  startIcon={
                    <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                      settings
                    </Icon>
                  }
                >
                  Task Options
                </ArgonButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </ArgonBox>
  );
}

export default Header;
