import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
// react-router-dom components
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";

import IconButton from "@mui/material/IconButton";
// import VideoLibraryIcon from "@mui/material/icons/VideoLibrary";

import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import { keyframes } from "@mui/system";
import HostHiveDialog from "components/HostHiveDialog";
import VideoEmbed from "layouts/management/components/VideoEmbed/index.js";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonBox from "components/ArgonBox";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { VideoLibraryHelper } from "services/videoLibraryHelper";
function VideoLibrary({}) {
  const [open, setOpen] = useState(false);
  const [selectedVideoId, setSelectedVideoId] = useState(null);

  const videos = [
    "createProperty",
    "addServicePartners",
    "syncCalendar",
    "taskManagement",
    "timeTracking",
    "getSupport",
  ];

  const handleOpenVideo = (videoId) => {
    setSelectedVideoId(videoId);
    setOpen(true);
    // You would typically open a modal or trigger a player to show the video here
  };
  const VideoComponent = () => {
    return (
      <HostHiveDialog
        open={open}
        includeSave={false}
        includeClose={true}
        onClose={() => setOpen(false)}
        // onSave={handleSubmitClicked} "pcTVMbmOR9g"
        title="How to Guide"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            <VideoEmbed videoId={selectedVideoId} />
          </>
        }
      />
    );
  };
  return (
    <DashboardLayout>
      <ArgonBox mt={4} mb={20}>
        <Grid container>
          {videos.map((videoKey) => {
            const video = VideoLibraryHelper(videoKey);
            return (
              <Grid item xs={12} md={6} lg={4} key={video.videoId}>
                <ArgonBox p={3}>
                  <Card>
                    <CardActionArea
                      onClick={() => handleOpenVideo(video.videoId)}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={video.thumbnailUrl}
                        alt={video.title}
                      />
                      <CardContent>
                        <ArgonTypography
                          gutterBottom
                          variant="h5"
                          component="div"
                        >
                          {video.title}
                        </ArgonTypography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </ArgonBox>
              </Grid>
            );
          })}
        </Grid>
      </ArgonBox>
      <VideoComponent />
    </DashboardLayout>
  );
}

export default VideoLibrary;
