/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BookingReportApi from "api/bookingReportApi";
function BookingReportPage() {
  const { propertyId, bookingReportId } = useParams();

  const bookingReportApi = new BookingReportApi();
  const { t } = useTranslation("common");
  const [bookingReport, setBookingReport] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchBookingReport() {
      try {
        const response = await bookingReportApi.getBookingReport(
          bookingReportId
        );
        console.log("Booking report response", response);
        let ownerSubscriptionTier = response.data.ownerSubscriptionTier;

        // check if ownerSubscriptionTier is null, free, or undefined, if so, navigate to /
        if (
          ownerSubscriptionTier === null ||
          ownerSubscriptionTier === "free" ||
          ownerSubscriptionTier === undefined
        ) {
          navigate("/");
        }

        if (response.status >= 200 && response.status < 300) {
          setBookingReport(response.data);
        } else {
          console.log("Error getting booking report");
        }
      } catch (error) {
        navigate("/authentication/error/404");
        console.log("Error getting booking report", error);
      }
    }
    fetchBookingReport();
  }, []);

  return (
    // <DashboardLayout>
    //   {/* <DashboardNavbar /> */}
    //   <ArgonBox py={3}>
    //     <BookingReportDataCard
    //       propertyId={propertyId}
    //       companyId="1"
    //       bookingReportId={bookingReportId}
    //     />
    //   </ArgonBox>
    //   <Footer />
    // </DashboardLayout>

    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={4} mb={20}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              <ArgonBox p={2}>
                <ArgonBox>
                  {bookingReport ? (
                    <BookingReportDataCard
                      propertyId={propertyId}
                      companyId="1"
                      bookingReport={bookingReport}
                    />
                  ) : (
                    <>
                      <HostHiveLoading
                        message={t(
                          "components.serviceReports.loadingServiceReport"
                        )}
                        elipse={true}
                      />
                    </>
                  )}
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BookingReportPage;
