import React from "react";

import ArgonTypography from "components/ArgonTypography";

function TermsAndConditions({ button, image, children }) {
  return (
    <div>
      <ArgonTypography variant="h4" gutterBottom>
        Terms and Conditions
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        Last updated: August 21, 2023
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Acceptance of Terms
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        By accessing or using the services offered by [Your Company], you agree
        to comply with and be bound by these terms and conditions ("Terms"). If
        you do not agree to these Terms, please do not use the services.
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Modifications to Terms
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        We reserve the right to change, modify, or revise these Terms at any
        time. Any changes made to these Terms will be effective immediately upon
        posting on this page, with an updated effective date. By continuing to
        use this service after changes are made, you agree to abide by the
        revised Terms.
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Privacy Policy
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        Your use of the services is also governed by [Your Company]'s Privacy
        Policy, which can be found at [insert Privacy Policy link here].
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Limitation of Liability
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        [Your Company] shall not be responsible for any damages or losses
        arising from your use or inability to use the services or otherwise
        arising under this agreement. [Your Company]'s total liability shall not
        exceed the amount paid by you for the service.
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Governing Law
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        These Terms are governed by the laws of the State of [Your State]
        without regard to its conflict of law provisions.
      </ArgonTypography>

      <ArgonTypography variant="h6" gutterBottom>
        Contact Information
      </ArgonTypography>

      <ArgonTypography variant="body1" paragraph>
        For any questions about these Terms, please contact us at [insert
        contact details].
      </ArgonTypography>
    </div>
  );
}

export default TermsAndConditions;
