import { useState, useEffect, useContext } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonProgress from "components/ArgonProgress";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import convertToOptions from "services/convertooptions";
import useMediaQuery from "@mui/material/useMediaQuery";
import statusBadges from "services/statusBadges";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonTimePicker from "components/ArgonTimePicker";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveImages from "components/HostHiveImages";

import DialogTitle from "@mui/material/DialogTitle";

const HostHiveChecklist = ({
  checklistName,
  checklistItems,
  setChecklistItems,
  readOnly = false,
}) => {
  const totalCheckedCount = checklistItems.reduce(
    (acc, { items }) => acc + items.filter((item) => item.checked).length,
    0
  );
  const totalItemCount = checklistItems.reduce(
    (acc, { items }) => acc + items.length,
    0
  );
  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAllToggle = (checklistId) => {
    console.log(checklistId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) => ({
                ...item,
                checked: !selectAll,
              })),
            }
          : checklist
      )
    );
    setSelectAll((prev) => !prev);
  };

  const handleIndividualToggle = (checklistId, itemId) => {
    console.log(checklistId, itemId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) =>
                item.id === itemId ? { ...item, checked: !item.checked } : item
              ),
            }
          : checklist
      )
    );
  };
  return (
    <ArgonBox display="flex" flexDirection="column" mb={2}>
      {/* Checklist Name & Progress */}
      <ArgonBox display="flex" alignItems="center" mb={2}>
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          {checklistName && `Checklist: ${checklistName}`}
        </ArgonTypography>
        <ArgonBox mx={2}>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="regular"
            color={totalCheckedCount === totalItemCount ? "success" : "warning"}
            sx={{ fontStyle: "italic" }}
          >
            ({totalCheckedCount}/{totalItemCount}){" "}
            {((totalCheckedCount / totalItemCount) * 100).toFixed(2)}% Complete
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox mb={2}>
        <ArgonProgress
          value={((totalCheckedCount / totalItemCount) * 100).toFixed(2)}
          color={
            (totalCheckedCount / totalItemCount) * 100 < 100
              ? "warning"
              : "success"
          }
        />
      </ArgonBox>

      {/* Checklist Items */}
      {checklistItems?.map(({ summary, id, items }) => {
        const selectedCount = items.filter((item) => item.checked).length;
        const totalCount = items.length;
        console.log("Items", items);
        return (
          <Accordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCount === totalCount}
                    onChange={() => handleSelectAllToggle(id)}
                  />
                }
                label={
                  <>
                    <ArgonTypography variant="caption" fontWeight="bold">
                      {summary}
                    </ArgonTypography>
                    <ArgonTypography variant="caption" fontWeight="bold" ml={2}>
                      ({selectedCount}/{totalCount})
                    </ArgonTypography>
                  </>
                }
              />
            </AccordionSummary>
            {items?.map((item) => (
              <AccordionDetails key={item.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={item.checked}
                      onChange={() => handleIndividualToggle(id, item.id)}
                    />
                  }
                  label={item.itemName}
                />
              </AccordionDetails>
            ))}
          </Accordion>
        );
      })}
    </ArgonBox>
  );
};

export default HostHiveChecklist;
