/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import InventoryDataCard from "layouts/management/components/InventoryDataCard";
import MuiLink from "@mui/material/Link";
import CompanyApi from "api/companyApi";
function ServicePartnerItem({
  name,
  id,
  email,
  isPending,
  company,
  refetchServicePartners,
}) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();
  const companyApi = CompanyApi();

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handleRemovePartnership = async () => {
    console.log("Removing partnership");
    try {
      const response = await companyApi.removeCompanyPartnership(company?.id, {
        partnerId: id,
      });
      if (response.status === 200) {
        console.log("Successfully removed partnership");
        refetchServicePartners();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResendInvite = async () => {
    console.log("Resending invite");
    const queryParams = {
      tokenId: id,
    };
    try {
      const response = await companyApi.addCompanyPartnershipViaEmail(
        company?.id,
        {
          email: name,
          tokenId: id,
        }
      );
      if (response.status === 200) {
        console.log("Successfully resent email");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancelInvite = async () => {
    console.log("Cancel invite");

    try {
      const response = await companyApi.addCompanyPartnershipViaEmail(
        company?.id,
        {
          email: name,
          tokenId: id,
          cancel: true,
        }
      );
      if (response.status === 200) {
        console.log("Successfully Cancelled invite");
        refetchServicePartners();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const renderMenu = (id) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* <MenuItem>
        <MuiLink
          href={`/service-partners/${id}/settings-overview`}
          rel="noreferrer"
        >
          View
        </MuiLink>
      </MenuItem> */}
      {/* <MenuItem onClick={handleCloseMenu}>Edit</MenuItem> */}
      {!isPending && (
        <MenuItem
          onClick={() => {
            handleCloseMenu();
            handleRemovePartnership();
          }}
        >
          Remove
        </MenuItem>
      )}
      {isPending && (
        <>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              handleResendInvite();
            }}
          >
            Resend Invite
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              cancelInvite();
            }}
          >
            Cancel Invite
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        mt: 2,
        ml: 3,
        pl: 0.5,
      }}
    >
      <div
        component="li"
        style={{
          width: "100%",
          pr: 2,
          mb: 2,
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <div style={{ width: "100%", pl: 1, ml: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
              ml: 3,
              pl: 0.5,
              alignItems: { xs: "flex-start", sm: "center" },
              overflow: "hidden",
            }}
          >
            {/* Summary */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {name}
                {"    "}
              </ArgonTypography>
              {isPending && (
                <ArgonBadge
                  variant="contained"
                  color="warning"
                  badgeContent="Pending"
                  container
                />
              )}
            </div>

            {renderMenu(id)}
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </div>

            {/* {isPending && <ArgonBox pl={2}></ArgonBox>} */}
          </div>
        </div>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

// // Setting default values for the props of Todo
// ServicePartnerItem.defaultProps = {
//   noDivider: false,
//   defaultChecked: false,
// };

// // Typechecking props for the Todo
// ServicePartnerItem.propTypes = {
//   summary: PropTypes.string.isRequired,
//   date: PropTypes.string.isRequired,
//   currentCount: PropTypes.string.isRequired,
//   amount: PropTypes.number.isRequired,
//   id: PropTypes.string.isRequired,
//   noDivider: PropTypes.bool,
// };

export default ServicePartnerItem;
