/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// custom styles for the NotificationItem
import { menuItem, menuImage } from "examples/Items/NotificationItem/styles";
import { convertTimeStamp, timeAgo } from "services/dateUtils";
import ArgonAlertCloseIcon from "components/ArgonAlert/ArgonAlertCloseIconDark";

const NotificationItem = forwardRef(
  ({ color, image, title, icon, createdDate, ...rest }, ref) => (
    <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
      <ArgonBox
        width="2.25rem"
        height="2.25rem"
        mt={0.25}
        mr={2}
        mb={0.25}
        borderRadius="lg"
        // sx={(theme) => menuImage(theme, { color })}
      >
        <Icon fontSize="default">{icon || "account_balance"}</Icon>
      </ArgonBox>
      <ArgonBox>
        <ArgonBox>
          <ArgonBox>
            <ArgonTypography
              variant="button"
              textTransform="capitalize"
              fontWeight="regular"
            >
              <strong>{title[0]}</strong>
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonTypography
              variant="button"
              textTransform="capitalize"
              fontWeight="regular"
            >
              {title[1]}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>

        <ArgonTypography
          variant="caption"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
          }}
        >
          <ArgonTypography variant="button" color="secondary">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </ArgonTypography>
          {timeAgo(createdDate)}
        </ArgonTypography>
        {/* <ArgonAlertCloseIcon>&times;</ArgonAlertCloseIcon> */}
      </ArgonBox>
    </MenuItem>
  )
);

// Setting default values for the props of NotificationItem
NotificationItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default NotificationItem;
