/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/account-support-faq/components/Header";
// import Footer from "layouts/pages/account-support-faq/components/Footer";
import Footer from "examples/Footer";
import PricingCards from "layouts/pages/account-support-faq/components/PricingCards";
import TrustedBrands from "layouts/pages/account-support-faq/components/TrustedBrands";
import Faq from "layouts/pages/account-support-faq/components/Faq";

function PublicSupport({ hideHelp = false }) {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <PageLayout>
      <Header
        tabValue={tabValue}
        tabHandler={handleSetTabValue}
        hideHelp={hideHelp}
      />
      <Container>
        {/* <PricingCards prices={prices} /> */}
        {/* <TrustedBrands /> */}
        <Faq />
      </Container>
      <ArgonBox mt={8} mb={10}>
        <Footer />
      </ArgonBox>
    </PageLayout>
  );
}

export default PublicSupport;
