import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function InventoryApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("inventory", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getInventoryItems = (queryParams) => {
    return axiosPrivate.get(`/inventory`, { params: queryParams });
  };

  const getInventoryItem = (id, queryParams) => {
    return axiosPrivate.get(`/inventory/${id}`, { params: queryParams });
  };

  const createInventoryUsage = (data, queryParams) => {
    return axiosPrivate.post(`/inventory/usages`, data, {
      params: queryParams,
    });
  };

  const createReplenishment = (data) => {
    return axiosPrivate.post(`/inventory/replenishments`, data);
  };

  const getInventoryReplenishments = (id) => {
    return axiosPrivate.get(`/inventory/${id}/replenishments`);
  };

  const updateInventoryItem = (id, data) => {
    return axiosPrivate.put(`/inventory/${id}`, data);
  };

  const getInventorySummaryDashboard = () => {
    return axiosPrivate.get(`/inventory/summary/dashboard`);
  };

  const getInventoryUsageSummary = (queryParams) => {
    return axiosPrivate.get(`/inventory/summary/usage-summary`, {
      params: queryParams,
    });
  };

  const deleteInventoryItem = (id) => {
    return axiosPrivate.delete(`/inventory/${id}`);
  };

  const createInventoryItem = (inventoryData) => {
    return axiosPrivate.post(`/inventory`, inventoryData);
  };

  return {
    getInventoryItems,
    getInventoryItem,
    updateInventoryItem,
    deleteInventoryItem,
    getInventoryReplenishments,
    createInventoryItem,
    createReplenishment,
    createInventoryUsage,
    getInventorySummaryDashboard,
    getInventoryUsageSummary,
  };
}

export default InventoryApi;
