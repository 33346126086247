/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
function DefaultCell({
  value,
  suffix,
  sxValue = null,
  onClick = null,
  copyEnabled = false,
  hidden = true,
}) {
  const [copyIcon, setCopyIcon] = useState("copy");
  const [visibilityIcon, setVisibilityIcon] = useState("visibility");
  const [valueVisible, setValueVisible] = useState(false);

  const handleVisibilityClicked = () => {
    setValueVisible(!valueVisible);
    setVisibilityIcon(valueVisible ? "visibility" : "visibility_off");
  };

  const [itemCopied, setItemCopied] = useState(false);
  const handleCopyInviteCodeClicked = () => {
    setItemCopied(true);
    setCopyIcon("check");

    // console.log("handleCopyInviteCodeClicked", company);
    navigator.clipboard.writeText(value);
    //Sleep for 1 second to allow user to see the check icon
    setTimeout(() => {
      setCopyIcon("copy");
      setItemCopied(false);
    }, 1000);
    //setCopyIcon("copy");
  };
  return (
    <ArgonTypography
      variant="caption"
      fontWeight="medium"
      color="text"
      sx={sxValue}
      onClick={onClick}
    >
      <ArgonBox
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        {hidden && !valueVisible ? "********" : value}
        {hidden && (
          <ArgonTypography
            variant="body1"
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip
              title={valueVisible ? "Hide" : "Show"}
              placement="top"
              //onClick={handleCopyInviteCodeClicked}
              onClick={handleVisibilityClicked}
            >
              <Icon>{visibilityIcon}</Icon>
            </Tooltip>
          </ArgonTypography>
        )}
        {copyEnabled && (
          <ArgonTypography
            variant="body1"
            color="secondary"
            sx={{ cursor: "pointer", lineHeight: 0 }}
          >
            <Tooltip
              title={itemCopied ? "Copied!" : "Copy"}
              placement="top"
              //onClick={handleCopyInviteCodeClicked}
              onClick={handleCopyInviteCodeClicked}
            >
              <Icon>{copyIcon}</Icon>
            </Tooltip>
          </ArgonTypography>
        )}
      </ArgonBox>
      {suffix && (
        <ArgonTypography
          variant="caption"
          fontWeight="medium"
          color="secondary"
        >
          &nbsp;&nbsp;{suffix}
        </ArgonTypography>
      )}
    </ArgonTypography>
  );
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  value: PropTypes.string.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default DefaultCell;
