import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ServiceNameApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("common", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getServiceNames = (queryParams) => {
    return axiosPrivate.get(`/service-name`, { params: queryParams });
  };

  const createServiceName = (serviceNameData) => {
    return axiosPrivate.post(`/service-name`, serviceNameData);
  };

  const getServiceName = (serviceNameId) => {
    return axiosPrivate.get(`/service-name/${serviceNameId}`);
  };

  const updateServiceName = (serviceNameId, serviceNameData) => {
    return axiosPrivate.put(`/service-name/${serviceNameId}`, serviceNameData);
  };

  const deleteServiceName = (serviceNameId) => {
    return axiosPrivate.delete(`/service-name/${serviceNameId}`);
  };

  const updateServiceNameStatus = (serviceNameId, updatedStatus) => {
    return axiosPrivate.put(`/service-name/${serviceNameId}/${updatedStatus}`);
  };
  return {
    getServiceNames,
    createServiceName,
    getServiceName,
    updateServiceName,
    deleteServiceName,
    updateServiceNameStatus,
  };
}

export default ServiceNameApi;
