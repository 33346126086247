function BetaAgreement({}) {
  // Your HTML invoice string
  const htmlContent = `
    <!DOCTYPE html>
      <html lang="en" style="font-family: Arial, sans-serif;">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>HostHive Beta Agreement</title>
      </head>

      <body>
          <main>
              <section>
                  <h1>HostHive BETA AGREEMENT</h1>
                  <p>Subject to the terms and conditions of this HostHive Beta Agreement (the “Agreement”), HostHive Aps,
                      LLC., a Florida Corporation located at 7901 4th St N STE 300, St. Petersburg, FL 33702, USA
                      (“HostHive”), agrees to provide you access to certain of its beta product offerings (each, a “Beta
                      Product”) that complement HostHive’s existing SaaS product, web design software, tools, and related
                      services (the “Services"). As a participant in HostHive’s Beta Product development program (the “Beta
                      Program”), you hereby agree to use and evaluate any Beta Products that HostHive grants you access to in
                      accordance with the terms of this Agreement.</p>

                  <p>BY CLICKING THE CHECK BOX AND FINISH BUTTON AT THE END OF THIS AGREEMENT INDICATING YOUR ACCEPTANCE OF
                      THESE TERMS, YOU HEREBY ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU ARE
                      ACCEPTING ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO
                      BIND SUCH ENTITY TO THESE TERMS, IN WHICH CASE THE TERM “YOU” SHALL REFER TO SUCH ENTITY. IF YOU DO NOT
                      HAVE SUCH AUTHORITY, OR DO NOT OTHERWISE AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS
                      AGREEMENT AND MAY NOT USE THE SERVICES DESCRIBED HEREIN. </p>

                  <p>SECTION 14 OF THIS AGREEMENT CONTAINS PROVISIONS THAT SHALL GOVERN ANY CLAIMS THAT THE PARTIES MAY HAVE
                      AGAINST EACH OTHER, INCLUDING WITHOUT LIMITATION A MANDATORY ARBITRATION PROVISION. </p>

                  <p>Now, therefore, in consideration of the mutual covenants set forth herein, you hereby agree as follows:
                  </p>

                  <p><strong>1. Confidentiality.</strong> As part of the Beta Program, <span
                          style=" font-weight: bold;">HostHive</span> may provide or allow you to access confidential or
                      otherwise non-public business, technical or other similar information, materials, and/or ideas ("<span
                          style="font-style: italic;">HostHive Proprietary Information</span>"). You acknowledge and agree
                      that Beta Products are (1) still in development and experimental form and (2) constitute <span
                          style="font-style: italic;">HostHive Proprietary Information</span>. Without limiting any other term
                      of the Agreement, you represent and warrant to keep confidential and not disclose to any third party
                      without <span style=" font-weight: bold;">HostHive</span>'s prior, written consent (email acceptable)
                      any <span style="font-style: italic;">HostHive</span> Proprietary Information, including but not limited
                      to the following: (a) the Beta Products; (b) any <span style=" font-weight: bold;">HostHive</span>
                      development, marketing, or similar ideas or plans that you learn of during your participation in the
                      Beta Program; (c) your participation in the Beta Program; or (d) any verbal or written communications
                      concerning the Beta Program or any Beta Products from <span style=" font-weight: bold;">HostHive</span>
                      or its employees, agents, contractors or other representatives ("<span
                          style="font-style: italic;"><strong>Confidentiality Obligations</strong></span>"). For the avoidance
                      of doubt, your Confidentiality Obligations shall expire when the relevant <span
                          style=" font-weight: bold;">HostHive</span> Proprietary Information becomes public knowledge,
                      provided that if the relevant <span style=" font-weight: bold;">HostHive</span> Proprietary Information
                      never becomes public knowledge, the Confidentiality Obligations shall survive the termination or
                      expiration of this Agreement. By way of illustrative example only, and without limitation, if a Beta
                      Product becomes publicly known but such Beta Product's marketing plans do not, you may disclose your
                      knowledge concerning the Beta Product, but you may not disclose your knowledge concerning such marketing
                      plans.</p>

                  <p><strong>2. No Conflicting Loyalty.</strong> You represent that you are not an employee or representative
                      of any HostHive competitor. </p>

                  <p><strong>3. Grant of Limited License.</strong> Subject to the terms of this Agreement, HostHive grants to
                      you a nonexclusive, non-sublicensable, revocable, worldwide right and license, to use the Beta
                      Product(s) solely in connection with your participation in the Beta Program and in accordance with this
                      Agreement (i.e., solely for the purposes of testing and evaluating the Beta Product(s) and not for any
                      commercial purpose or use). Your license will be valid only during the time that you are an active
                      participant in the Beta Program.</p>
                  <p><strong>4. Feedback.</strong> Upon HostHive’s request, you agree to provide suggestions, comments,
                      enhancement requests, recommendations, or any other feedback (collectively, “<strong>Feedback</strong>”)
                      regarding the Beta Product(s) or the Beta Program generally. Feedback shall include information
                      regarding the Beta Product(s) performance, ease of use, features that may be missing, any bugs
                      encountered during the use of the Beta Product, and any other information HostHive requests regarding
                      the Beta Product(s). You agree that upon request by HostHive you will make available a reasonable amount
                      of time to discuss the Beta Product(s) as part of your participation in the Beta Program. You agree that
                      HostHive shall have an unrestricted, royalty-free, worldwide, transferable, sublicensable, irrevocable,
                      perpetual license to use or incorporate this Feedback into the Beta Product(s) and other HostHive
                      products or services.</p>

                  <p><strong>5. Intellectual Property and Ownership.</strong> You acknowledge and agree that as between the
                      parties HostHive owns all Intellectual Property Rights in and to the Beta Product(s) and any and all
                      intellectual property contained therein or related thereto, including, but not limited to, any text,
                      graphics, user and visual interfaces, photographs, trademarks, logos, sounds, music, artwork,
                      applications, computer code and associated documentation (collectively, the “Content”). For the purposes
                      of this Agreement, “<strong>Intellectual Property Rights</strong>” means all patent rights, copyright
                      rights, moral rights, rights of publicity, trademark, trade dress and service mark rights, goodwill,
                      trade secret rights and other intellectual property rights as may now exist or hereafter come into
                      existence, and all applications therefore and registrations, renewals, and extensions thereof, under the
                      laws of any state, country, territory, or other jurisdiction. HostHive does not grant to you any
                      license, express or implied, to the intellectual property of HostHive or its licensors. The Beta
                      Products(s) and Content may not be copied, reproduced, sold, republished, transmitted, displayed,
                      reposted, or otherwise distributed for public or commercial purposes.</p>

                  <p><strong>6. Publicity and Marketing.</strong> You agree that, in HostHive’s sole discretion, HostHive may
                      use your name and/or logo and any Feedback you provide to HostHive Aps, LLC iluding testimonials or
                      quotes, (collectively “<strong>Promotional Information</strong>”) in HostHive’s marketing materials and
                      related assets. Pursuant to the foregoing, you hereby grant HostHive an unrestricted, royalty-free,
                      worldwide, transferable, sublicensable, irrevocable, perpetual license to reproduce, publish,
                      distribute, display, translate, summarize, modify, adapt, incorporate, or otherwise use such Promotional
                      Information, in whole or in part, alone or in other works in any form, media, or technology now known or
                      later developed. Notwithstanding the above, HostHive shall obtain your prior consent before the first
                      use of your quotes or testimonials in HostHive’s marketing materials, provided any subsequent use will
                      not require any such prior consent. For the avoidance of doubt, in no event is HostHive obligated to
                      use, reproduce, or publish any Promotional Information.</p>

                  <p><strong>7. Cooperation and Co-marketing.</strong> You agree to cooperate with HostHive in jointly
                      producing mutually-beneficial content and exploring other co-marketing opportunities from time to time.
                  </p>

                  <p><strong>8. DISCLAIMER OF WARRANTIES.</strong> THE BETA PRODUCT(S) ARE PROVIDED “AS IS” AND AS AVAILABLE,
                      EXCLUSIVE OF ANY WARRANTY WHATSOEVER. HOSTHIVE (1) MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
                      WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO ANY MATTER, INCLUDING ANY
                      REPRESENTATION THAT THE BETA PRODUCT(S) WILL BE UNINTERRUPTED OR ERROR-
                      FREE, AND (2) EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES OR CONDITIONS OF NON-INFRINGEMENT,
                      MERCHANTABILITY AND FITNESS FOR ANY PARTICULAR PURPOSE. ALL BETA PRODUCT(S) ARE PRELEASE, ARE EXPECTED
                      TO CONTAIN DEFECTS, SOME OF WHICH MAY BE MATERIAL, AND ARE NOT EXPECTED TO OPERATE AT THE LEVEL OR
                      PERFORMANCE OR COMPATIBILITY OF A FINAL, PUBLICLY AVAILABLE PRODUCT OFFERING. BETA PRODUCT(S) MAY NOT
                      OPERATE ACCURATELY AND MAY BE SUBSTANTIALLY MODIFIED PRIOR TO PUBLIC AVAILABILITY OR WITHDRAWN AT ANY
                      TIME. ACCORDINGLY, ACCESS TO AND USE OF THE BETA PRODUCT(S) IS ENTIRELY AT YOUR OWN RISK. YOU ASSUME ALL
                      RISK AND RESPONSIBILITY WITH RESPECT THERETO. IN NO EVENT SHALL HOSTHIVE BE LIABLE FOR ANY DAMAGES
                      WHATSOEVER ARISING OUT OF THE USE OF OR INABILITY TO USE THE BETA PRODUCT(S). YOU ARE ADVISED TO
                      SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR
                      PERFORMANCE OF ANY BETA PRODUCT.</p>
                  <p><strong>9. LIMITATION OF LIABILITY.</strong> UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO,
                      NEGLIGENCE, SYSTEM FAILURE OR NETWORK OUTAGE, WHETHER RESULTING FROM A TORT, BREACH OF CONTRACT,
                      WARRANTY OR OTHER FORM OF ACTION, WILL HOSTHIVE BE LIABLE FOR ANY SPECIAL, INDIRECT, INCIDENTAL,
                      CONSEQUENTIAL, PUNITIVE, RELIANCE, OR EXEMPLARY DAMAGES WHETHER OR NOT YOU HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. HOSTHIVE’S ENTIRE LIABILITY FOR DIRECT DAMAGES IN CONNECTION WITH THE BETA
                      PROGRAM AND THIS AGREEMENT SHALL BE LIMITED TO ONE HUNDRED DOLLARS ($100).</p>
                  <p><strong>10. Privacy.</strong> For purposes of the Beta Program, you agree and hereby consent to HostHive
                      Aps, LLC including its employees or agents, collecting your personal information including, but not
                      limited to, your name, email, address, and telephone number for the purposes of contacting you to
                      collect your Feedback and generally as part of your participation in the Beta Program. HostHive shall
                      handle your personal information in accordance with the applicable HostHive Privacy Policy located at:
                      HostHive Privacy .</p>

                  <p><strong>11. Termand Termination.</strong>This Agreement will automatically expire at the end of your
                      participation in the Beta Program unless terminated sooner in accordance with this Agreement, and in any
                      case shall expire not more than five (5) years after the date of this Agreement. Either party may
                      terminate this Agreement before the end of your participation in the Beta Program for any reason upon
                      written notice to the other party. This Agreement will automatically terminate upon any breach by you of
                      any of your obligations hereunder including breach of confidentiality obligations. Your breach of any of
                      your obligations under the Agreement may result in your immediate removal from the Beta Program. Upon
                      HostHive’s request, you will return to HostHive any HostHive Proprietary Information in your control or
                      possession.</p>
                  <p><strong>12. Indemnity.</strong> You agree to defend, indemnify, and hold HostHive harmless from and
                      against any demands, loss, liability, claims, or expenses (including attorneys’ fees) made against us by
                      any third party due to or arising out of or related to (1) your misuse of any Beta Product(s), or (2)
                      any act or omission in violation of this Agreement.
                  <p><strong>13. General Provisions.</strong> This Agreement is personal to you, is non-assignable by you, is
                      governed by the internal laws of the State of Florida, without regard to conflict of law principles, and
                      may be modified or waived only in writing signed by both parties. If any provision of this Agreement is
                      found to be unenforceable, such provision will be limited or deleted to the minimum extent necessary so
                      that the remaining terms remain in full force and effect. The prevailing party in any dispute or legal
                      action regarding the subject matter of this Agreement shall be entitled to recover attorneys’ fees and
                      costs.</p>
                  <p><strong>14. DISPUTERESOLUTION;ARBITRATION </strong></p>
                  <p><strong>14.1 Initial Dispute Resolution.</strong> To expedite resolution and reduce the cost of any
                      dispute, controversy or claim related to this Agreement (a “<strong>Dispute</strong>”), each party
                      agrees to first attempt to negotiate any Dispute (except those Disputes expressly excluded below)
                      informally for at least thirty (30) days before initiating any arbitration or court proceeding. Such
                      informal negotiations will commence upon written notice. HostHive’s address for such notices shall be as
                      set forth above, with an electronic copy to info@hosthive.io . Your address for such notices is the
                      email address you have previously exchanged and any mailing address you may have provided to HostHive.
                  </p>
                  <p><strong>14.2 Binding Arbitration.</strong> If you and HostHive are unable to resolve a Dispute as set
                      forth above, all claims arising from or under this Agreement (except those Disputes expressly excluded
                      below) shall be finally and exclusively resolved by binding arbitration. Any election to arbitrate by
                      one party will be final and binding on the other. You understand that if either party elects to
                      arbitrate, neither party will have the right to sue in court or have a jury trial. The arbitration will
                      be commenced and conducted under the Commercial Arbitration Rules (the “<strong>AAA Rules</strong>”) of
                      the American Arbitration Association (the “<strong>AAA</strong>”) and, where appropriate, the AAA’s
                      Supplementary Procedures for Consumer Related Disputes (the “<strong>AAA Consumer Rules</strong>”), both
                      of which are available at the AAA website www.adr.org. The parties’ arbitration fees and share of
                      arbitrator compensation will be governed by the AAA Rules (and, where appropriate, limited by the AAA
                      Consumer Rules). If your claim for damages does not exceed $100, HostHive will pay all such fees unless
                      the arbitrator finds that either the substance of your claim or the relief sought in its Demand for
                      Arbitration was frivolous or was brought for an improper purpose (as measured by the standards set forth
                      in Federal Rule of Civil Procedure 11(b)). The arbitration may be conducted in person, through the
                      submission of documents, by phone or online. The arbitrator will make a decision in writing, but need
                      not provide a statement of reasons unless requested by a party. The arbitrator must follow applicable
                      law, and any award may be challenged if the arbitrator fails to do so. Except as otherwise provided in
                      this Agreement, the parties may litigate in court to compel arbitration, stay proceeding pending
                      arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
                      Arbitration will take place in Broward County, Florida. For the avoidance of doubt, the parties agree
                      that for any Dispute not subject to arbitration (other than claims proceeding in any small claims
                      court), or where no election to arbitrate has been made, the Florida state and Federal courts located in
                      Broward County, Florida have exclusive jurisdiction and the parties agree to submit to the personal
                      jurisdiction of such courts.</p>
                  <p><strong>14.3 Exceptions to Alternative Dispute Resolution.</strong> Each party retains the right to bring
                      an individual action in small claims court or to seek injunctive or other equitable relief on an
                      individual basis in a federal or state court located within the Central District of Florida with respect
                      to any dispute related to the actual or threatened infringement, misappropriation, or violation of a
                      party’s intellectual property or proprietary rights.</p>
                  <p><strong>14.4 Waiver of Jury Trial.</strong> Notwithstanding anything to the contrary herein, each party
                      hereby waives to the fullest extent permitted by applicable law, any right it may have to a trial by
                      jury of any arbitrable claim under this Agreement and in connection with the enforcement of an arbitral
                      award rendered pursuant to this agreement. Each party (i) certifies that no representatives, agent or
                      attorney of any other party has represented, expressly or otherwise, that such other party would not, in
                      the event of such litigation, seek to enforce the foregoing waiver and (ii) acknowledges that it and the
                      other party hereto have been induced to enter into this Agreement. </p>



      </body>

      </html>

  `;

  // Convert the HTML string into a Blob
  const blob = new Blob([htmlContent], { type: "text/html" });

  // Create a URL for the Blob
  const url = URL.createObjectURL(blob);

  return (
    <>
      <iframe
        style={{ width: "100%", height: "100vh" }}
        frameBorder="0"
        src={url} // Set the src attribute to the created URL
      ></iframe>
    </>
  );
}

export default BetaAgreement;
