/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import { Link, useNavigate, useParams } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import Divider from "@mui/material/Divider";
import HostHiveImages from "components/HostHiveImages";
import HostHiveDialog from "components/HostHiveDialog";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";

import { useMemo, useState, useEffect, useContext } from "react";
import PropertiesApi from "api/propertiesApi";
import { useTranslation } from "react-i18next";
import GroupsApi from "api/groupsApi";
// Images
const orderImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/smartwatch.jpg";

function PropertyInfo({
  property,
  isOwner = false,
  propertyId,
  propertyName,
  propertyOccupancyDetails,
  contact,
}) {
  const propertiesApi = new PropertiesApi();

  const navigate = useNavigate();
  const [propertyPhotosOpen, setPropertyPhotosOpen] = useState(false);
  const [propertyImages, setPropertyImages] = useState([]);
  const { t } = useTranslation("common");
  const groupsApi = GroupsApi();
  const [propertyGroupSettings, setPropertyGroupSettings] = useState([]);
  const [gateAccessCode, setGateAccessCode] = useState("");
  const [doorAccessCode, setDoorAccessCode] = useState("");
  useEffect(() => {
    console.log(property);
    const getImages = async () => {
      const response = await propertiesApi.getProperty(propertyId);
      setPropertyImages(response.data.images);
    };

    async function fetchGroupSettings() {
      try {
        const queryParams = {
          requestedSettingType: "door_access_code,gate_access_code",
        };
        const response = await groupsApi.getPropertyGroupSettings(
          propertyId,
          queryParams
        );
        setPropertyGroupSettings(response.data);

        // find door_access_code and gate_access_code and set them to state
        response.data.forEach((setting) => {
          if (setting.type === "door_access_code") {
            setDoorAccessCode(setting.value);
          }
          if (setting.type === "gate_access_code") {
            setGateAccessCode(setting.value);
          }
        });

        console.log("fetchGroupSettings", response);
      } catch (error) {
        console.log(error);
      }
    }

    // getImages();
    fetchGroupSettings();
  }, []);

  const handleNavigateToServiceInvoicing = () => {
    navigate(`/service-invoicing/create/company/${contact.id}`);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={6}>
        <ArgonBox display="flex" alignItems="center">
          <HostHiveDialog
            includeDelete={false}
            includeSave={false}
            open={propertyPhotosOpen}
            onClose={() => {
              setPropertyPhotosOpen(false);
            }}
            title={t("properties.propertyOverview.viewPhotos")}
            fullScreen={false}
            maxWidth="sm"
            cancelButtonText="Close"
            submitButtonText="Save"
            dialogObjects={
              <>
                <Card>
                  <HostHiveImages
                    images={propertyImages}
                    handleDeleteImage={null}
                    imageGridSizeXs={12}
                    imageGridSizeMd={6}
                  />
                </Card>
              </>
            }
          />
          <ArgonBox mr={2}>
            {propertyImages.length > 0 ? (
              <>
                <ArgonAvatar
                  variant="rounded"
                  size="xxl"
                  src={propertyImages[0].src}
                  alt="Smart Watch"
                  sx={{
                    ":hover": { textDecoration: "underline" },
                    cursor: "pointer",
                  }}
                  onClick={() => setPropertyPhotosOpen(true)}
                />
                {/* <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": { textDecoration: "underline" },
                      cursor: "pointer",
                    }}
                    onClick={() => setPropertyPhotosOpen(true)}
                    //title={title}
                  >
                    View Photos
                  </ArgonTypography>
                </ArgonBox> */}
              </>
            ) : null}
          </ArgonBox>
          <ArgonBox lineHeight={1}>
            <ArgonTypography variant="h6" fontWeight="medium">
              {propertyName}
            </ArgonTypography>
            <ArgonBox mb={2}>
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {property?.address1}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {property?.city}, {property?.state} {property?.zipCode}
                </ArgonTypography>
              </ArgonBox>
              <Divider />
              <ArgonTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {t("properties.accessDetails.doorCode")}:{" "}
                <span sx={{ fontWeight: "bold" }}>{doorAccessCode}</span>
              </ArgonTypography>
              <ArgonTypography
                component="p"
                variant="button"
                fontWeight="regular"
                color="text"
              >
                {t("properties.accessDetails.gateCode")}:{" "}
                <span sx={{ fontWeight: "bold" }}>{gateAccessCode}</span>
              </ArgonTypography>
            </ArgonBox>
            <Tooltip
              title={`Occupancy status for ${propertyName}`}
              placement="top"
              arrow
            >
              <ArgonBadge
                color={propertyOccupancyDetails?.color}
                size="xs"
                badgeContent={propertyOccupancyDetails?.occupanceStatus}
                container
                variant="contained"
              />
            </Tooltip>
          </ArgonBox>
        </ArgonBox>
      </Grid>
      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
        {isOwner ? (
          <ArgonButton
            variant="gradient"
            color="info"
            onClick={() => navigate(`/properties/${propertyId}`)}
          >
            Go to Property Page
          </ArgonButton>
        ) : (
          <ArgonButton variant="gradient" color="secondary">
            {t("buttons.invoice")}
          </ArgonButton>
        )}
      </Grid>
    </Grid>
  );
}

export default PropertyInfo;
