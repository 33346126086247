/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Settings page components
import BaseLayout from "layouts/management/company/settings/components/BaseLayout";
import Sidenav from "layouts/management/company/settings/components/Sidenav";
import Header from "layouts/management/company/settings/components/Header";
import BasicInfo from "layouts/management/company/settings/components/BasicInfo";
import ServicePartners from "layouts/management/company/settings/components/ServicePartners";
import ApplicationSettings from "layouts/management/company/settings/components/ApplicationSettings";
import ServiceNames from "layouts/management/company/settings/components/ServiceNames";
import ServiceChecklists from "layouts/management/company/settings/components/ServiceChecklists";
import Account from "layouts/management/company/settings/components/AccountSettings";
import ServicePartnerProperties from "layouts/management/company/settings/components/ServicePartnerProperties";
import PropertyManagementSettings from "layouts/management/company/settings/components/PropertyManagementSettings";
import CategorySettings from "layouts/management/company/settings/components/CategorySettings";
import Authentication from "layouts/management/company/settings/components/Authentication";
import Accounts from "layouts/management/company/settings/components/Accounts";
import Notifications from "layouts/management/company/settings/components/Notifications";
import Sessions from "layouts/management/company/settings/components/Sessions";
import DeleteAccount from "layouts/management/company/settings/components/DeleteAccount";
import { useParams, useNavigate } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";
import CompaniesApi from "api/companyApi";
import { UserAuth } from "context/AuthContext";
import PropertiesCard from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/PropertyCard";
function CompanySettings() {
  // const [company, setCompany] = useState({}); //[userCompanyDetails
  //const company = JSON.parse(localStorage.getItem("companyDetails"));
  const companiesApi = CompaniesApi();

  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();

  // const { companyId, id } = useParams();
  const [error, setError] = useState(null);

  return (
    <BaseLayout>
      <ArgonBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <ArgonBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                {/* <Grid item xs={12}>
                  <BasicInfo />
                </Grid> */}
                <>
                  <Grid item xs={12}>
                    {/* <Accounts company={company} /> */}
                    <Account />
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Accounts company={company} /> */}
                    <ApplicationSettings />
                  </Grid>

                  <Grid item xs={12}>
                    {/* <Accounts company={company} /> */}
                    <PropertyManagementSettings />
                  </Grid>
                  <Grid item xs={12}>
                    <CategorySettings />
                  </Grid>

                  <Grid item xs={12}>
                    <ServicePartners />
                  </Grid>
                </>
                {/* <>
                  <Grid item xs={12}>
                    <ServicePartnerProperties>
                      <PropertiesCard title="Properties" />
                    </ServicePartnerProperties>
                  </Grid>
                </> */}
                <Grid item xs={12}>
                  <ServiceNames />
                </Grid>
                {subscriptionTier === "free" ||
                subscriptionTier === null ? null : (
                  <Grid item xs={12}>
                    <ServiceChecklists />
                  </Grid>
                )}
                {/* <Grid item xs={12}>
                  <Accounts />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                <Grid item xs={12}>
                  <Sessions />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <DeleteAccount />
                </Grid> */}
              </Grid>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </BaseLayout>
  );
}

export default CompanySettings;
