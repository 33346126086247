/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function Submit({ setNextDisabled, submitForm }) {
  useEffect(() => {
    setNextDisabled(false);
  }, []);
  return (
    <ArgonBox>
      <ArgonTypography variant="h5" fontWeight="bold">
        Submit
      </ArgonTypography>
      <ArgonBox mt={1.625}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ArgonButton
              variant="gradient"
              color="dark"
              onClick={() => submitForm()}
            >
              Submit
            </ArgonButton>
          </Grid>
        </Grid>
      </ArgonBox>
    </ArgonBox>
  );
}

export default Submit;
