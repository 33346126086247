import { Fragment, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import BookingsApi from "api/bookingsApi";
// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
import CreateEventWizard from "layouts/management/calendar/components/CreateEventWizard";

function ServiceGaps({ propertyDetails, propertyId, setExternalFetch }) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();
  const handleCancelClicked = () => {
    setOpen(false);
  };

  const [serviceGaps, setServiceGaps] = useState([]);

  useEffect(() => {
    async function fetchServiceGaps() {
      try {
        const response = await bookingsApi.getServiceGaps(propertyId);
        setServiceGaps(response.data);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchServiceGaps();
  }, []);

  const [startDate, setStartDate] = useState(null);

  const handleSubmitClicked = async () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);

  const handleScheduleServiceGapClicked = (availableServiceDate) => {
    setStartDate(availableServiceDate);
    console.log("availableServiceDate", availableServiceDate);
    setOpen(true);
  };
  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <ArgonBox p={3}>
        <ArgonBox>
          <HostHiveDialog
            open={open}
            fullWidth={true}
            includeClose={false}
            includeSave={false}
            maxWidth="md"
            onClose={handleCancelClicked}
            onSave={handleSubmitClicked}
            title="Schedule service"
            fullScreen={false}
            cancelButtonText="Cancel"
            submitButtonText="Save"
            dialogObjects={
              // <EventDataCard
              //   isCreation={true}
              //   scheduleStartDate={startDate}
              //   propertyInfo={propertyDetails}
              //   setExternalFetch={setExternalFetch}
              //   onClose={() => {
              //     setOpen(false);
              //   }}
              // />
              <CreateEventWizard
                closeWizard={() => {
                  setOpen(false);
                  setExternalFetch();
                }}
                propertyId={propertyId}
                serviceGapDate={startDate}
              />
            }
          />
        </ArgonBox>
        <ArgonTypography variant="h5" textTransform="capitalize">
          {t("properties.components.serviceGaps.title")}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pb={3} px={3}>
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {serviceGaps?.map(
            ({ availableServiceDate, nextCheckInDate }, key) => (
              <Fragment key={key}>
                <ArgonBox component="li" py={1}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item alignItems="center"></Grid>
                    <Grid item lineHeight={1.4}>
                      <ArgonBox>
                        <ArgonBox>
                          <ArgonTypography variant="h6">
                            {availableServiceDate}
                          </ArgonTypography>
                        </ArgonBox>
                        <ArgonBox>
                          <ArgonBadge
                            variant="contained"
                            color="success"
                            badgeContent={`${t(
                              "properties.components.serviceGaps.nextCheckin"
                            )}: ${nextCheckInDate}`}
                            container
                          />
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    <Grid item ml="auto">
                      <ArgonButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() =>
                          handleScheduleServiceGapClicked(availableServiceDate)
                        }
                      >
                        {t("buttons.schedule")}
                      </ArgonButton>
                    </Grid>
                  </Grid>
                </ArgonBox>
              </Fragment>
            )
          )}
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ServiceGaps;
