import React, { useMemo, useEffect, useState, useCallback } from "react";
import { Icon, MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import Card from "@mui/material/Card";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import AccountingApi from "api/accountingApi";
import { useTranslation } from "react-i18next";
import HostHiveDialog from "components/HostHiveDialog";
import PropertySelect from "layouts/management/components/PropertySelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import { convertTimeStamp } from "services/dateUtils";
import ArgonSelect from "components/ArgonSelect";
import CategoriesApi from "api/categoriesApi";
import PropertiesApi from "api/propertiesApi";
import ArgonInput from "components/ArgonInput";
import { split } from "lodash";
import SplitTransactionItem from "layouts/administration/accounting/components/SplitTransactionItem";
const MemoizedArgonSelect = React.memo(ArgonSelect);
function TransactionApprovalItem({
  transaction,
  selectedIds,
  setSelectedIds,
  handleCheckboxChange,
  bulkChangePayloadMapping,
  setTransactions,
  memoizedProperties,
  memoizedCategories,
  handleIndividualAction,
  handleIndividualApprove,
  setIndividualDelete,
  setIndividualUpdateId,
  setApplyCategoryOpen,
  setApplyPropertyOpen,
  individualDelete,
  workingId = null,
}) {
  const { t } = useTranslation("common");
  const [splitTransactionOpen, setSplitTransactionOpen] = useState(false);

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        disabled={
          bulkChangePayloadMapping[transaction.id]?.property?.value ===
            undefined ||
          bulkChangePayloadMapping[transaction.id]?.category?.value ===
            undefined
        }
        // onClick={() => {
        //   handleNavigateToReport() && handleCloseMenu();
        // }}
      >
        <ArgonButton
          disabled={
            bulkChangePayloadMapping[transaction.id]?.property?.value ===
              undefined ||
            bulkChangePayloadMapping[transaction.id]?.category?.value ===
              undefined
          }
          color="success"
          size="small"
          onClick={() =>
            handleIndividualAction(transaction.id, true, "approve")
          }
          sx={{
            borderRadius: "8px",
            borderWidth: "1px",
            transition: "background-color 0.3s, transform 0.2s",
            ":hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transform: "scale(1.02)",
            },
          }}
        >
          Approve
        </ArgonButton>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <MenuItem
      // onClick={() => {
      //   handleNavigateToReport() && handleCloseMenu();
      // }}
      >
        <ArgonButton
          disabled={workingId === transaction.id && individualDelete}
          color="error"
          size="small"
          onClick={() =>
            handleIndividualAction(transaction.id, false, "delete")
          }
          variant="gradient"
          sx={{
            borderRadius: "8px",
            borderWidth: "1px",
            transition: "background-color 0.3s, transform 0.2s",
            ":hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transform: "scale(1.02)",
            },
          }}
        >
          {workingId === transaction.id && individualDelete
            ? "Declining..."
            : "Decline"}
        </ArgonButton>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <MenuItem
      // onClick={() => {
      //   handleNavigateToReport() && handleCloseMenu();
      // }}
      >
        <ArgonButton
          color="primary"
          size="small"
          onClick={() => setSplitTransactionOpen(true)}
          sx={{
            borderRadius: "8px",
            borderWidth: "1px",
            transition: "background-color 0.3s, transform 0.2s",
            ":hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transform: "scale(1.02)",
            },
          }}
        >
          Split
        </ArgonButton>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 1 }} />

      <MenuItem
      // onClick={() => {
      //   handleNavigateToReport() && handleCloseMenu();
      // }}
      >
        <ArgonButton
          color="primary"
          size="small"
          onClick={() => {
            setIndividualUpdateId(transaction.id);
            setApplyPropertyOpen(true);
          }}
          sx={{
            borderRadius: "8px",
            borderWidth: "1px",
            transition: "background-color 0.3s, transform 0.2s",
            ":hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transform: "scale(1.02)",
            },
          }}
        >
          Update Property
        </ArgonButton>
      </MenuItem>
      <Divider sx={{ mt: 2, mb: 1 }} />
      <MenuItem
      // onClick={() => {
      //   handleNavigateToReport() && handleCloseMenu();
      // }}
      >
        <ArgonButton
          color="primary"
          size="small"
          onClick={() => {
            setIndividualUpdateId(transaction.id);
            setApplyCategoryOpen(true);
          }}
          sx={{
            borderRadius: "8px",
            borderWidth: "1px",
            transition: "background-color 0.3s, transform 0.2s",
            ":hover": {
              backgroundColor: "rgba(0,0,0,0.05)",
              transform: "scale(1.02)",
            },
          }}
        >
          Update Category
        </ArgonButton>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <ArgonBox
        pl={2}
        component="div"
        display="flex"
        flexDirection="column"
        p={2}
        m={0}
        key={transaction?.id}
      >
        <SplitTransactionItem
          transaction={transaction}
          memoizedCategories={memoizedCategories}
          memoizedProperties={memoizedProperties}
          splitTransactionOpen={splitTransactionOpen}
          setSplitTransactionOpen={setSplitTransactionOpen}
          setTransactions={setTransactions}
          setSelectedIds={setSelectedIds}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <ArgonBox display="flex" justifyContent="flex-end">
              {renderMenu()}

              <ArgonButton
                variant="outlined"
                color="secondary"
                onClick={handleOpenMenu}
                sx={{
                  ml: "auto",
                  alignSelf: "flex-start",
                  py: 1.25,
                  pr: 1.5,
                  borderRadius: "8px",
                  borderWidth: "1px",
                  transition: "background-color 0.3s, transform 0.2s",
                  ":hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    transform: "scale(1.02)",
                  },
                }}
                startIcon={
                  <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                    settings
                  </Icon>
                }
              >
                Actions
              </ArgonButton>
            </ArgonBox>
          </Grid>

          <Grid item xs={12} md={8}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedIds.includes(transaction.id)}
                  onChange={(event) =>
                    handleCheckboxChange(event, transaction.id)
                  }
                />
              }
              label={
                <ArgonTypography variant="h6" fontWeight="bold">
                  {transaction?.summary}
                </ArgonTypography>
              }
            />
            <ArgonBox>
              <ArgonBox>
                <ArgonTypography
                  variant="body1"
                  fontWeight="bold"
                  color={transaction?.amount > 0 ? "success" : "error"}
                  style={{ display: "inline" }}
                >
                  {transaction?.amount < 0 ? "$" : "$+"}
                  {transaction?.amount.toLocaleString()}
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <ArgonTypography
                  variant="body1"
                  color="textSecondary"
                  fontWeight="bold"
                  style={{ display: "inline" }}
                >
                  Created:{" "}
                </ArgonTypography>
                <ArgonTypography
                  variant="body1"
                  color="textSecondary"
                  style={{ display: "inline" }}
                >
                  {convertTimeStamp(transaction?.created)}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ArgonBox display="flex" justifyContent="flex-end">
              <ArgonTypography variant="body1" color="textSecondary">
                {transaction?.source?.institution} - {transaction?.source?.name}
              </ArgonTypography>
            </ArgonBox>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ArgonTypography variant="subtitle1" fontWeight="bold">
              Property:{" "}
              <span style={{ fontWeight: "normal" }}>
                {bulkChangePayloadMapping[transaction.id]?.property?.label}
              </span>
            </ArgonTypography>
          </Grid>

          <Grid item xs={4}>
            <ArgonTypography variant="subtitle1" fontWeight="bold">
              Category:{" "}
              <span style={{ fontWeight: "normal" }}>
                {bulkChangePayloadMapping[transaction.id]?.category?.label}
              </span>
            </ArgonTypography>
          </Grid>

          {/* <Grid item xs={12} lg={2}>
            <ArgonBox display="flex" justifyContent="flex-end">
              <ArgonBox>
                <ArgonButton
                  disabled={workingId === transaction.id && individualDelete}
                  color="error"
                  size="small"
                  onClick={() => handleIndividualAction(transaction.id,false,"delete")}
                  variant="gradient"
                  sx={{
                    borderRadius: "8px",
                    borderWidth: "1px",
                    transition: "background-color 0.3s, transform 0.2s",
                    ":hover": {
                      backgroundColor: "rgba(0,0,0,0.05)",
                      transform: "scale(1.02)",
                    },
                  }}
                >
                  {workingId === transaction.id && individualDelete
                    ? "Declining..."
                    : "Decline"}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Grid>
          <Grid item xs={12} lg={2}>
            <ArgonBox display="flex" justifyContent="flex-end">
              <ArgonBox>
                <ArgonButton
                  color="primary"
                  size="small"
                  onClick={() => setSplitTransactionOpen(true)}
                >
                  Split
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </Grid> */}
          <Grid item xs={12}>
            <Divider sx={{ mt: 2, mb: 1 }} />
          </Grid>
        </Grid>
      </ArgonBox>
    </>
  );
}

export default TransactionApprovalItem;
