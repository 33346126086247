import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import GroupsApi from "api/groupsApi";
import { useMemo, useState, useEffect, useContext } from "react";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";

function GroupSettingCard({
  newGroupSetting,
  setNewGroupSetting,
  savingNewSetting,
}) {
  //   const { t } = useTranslation();
  // const groupsApi = GroupsApi();
  const settingTypes = [
    {
      label: "Door Access Code",
      value: "door_access_code",
    },
    {
      label: "Gate Access Code",
      value: "gate_access_code",
    },
    {
      label: "URL Link",
      value: "link",
    },

    {
      label: "Password",
      value: "password",
    },
    {
      label: "Other",
      value: "other",
    },
  ];
  const [selectedSettingType, setSelectedSettingType] = useState(null);

  // based on newGroupSetting.type find the dictionary in settingTypes and set it to selectedSettingType
  useEffect(() => {
    if (newGroupSetting?.type) {
      const selectedType = settingTypes.find(
        (type) => type.value === newGroupSetting.type
      );
      setSelectedSettingType(selectedType);
      console.log("selectedType", selectedType);
    }
    console.log("newGroupSetting", newGroupSetting);
  }, [newGroupSetting]);

  // handle when the selectedSettingType changes
  const handleSettingTypeChange = (selectedType) => {
    setSelectedSettingType(selectedType);
    setNewGroupSetting({
      ...newGroupSetting,
      type: selectedType.value,
    });
  };

  const handleInternalChange = (event) => {
    console.log("event.target.checked", event.target.checked);
    setNewGroupSetting({
      ...newGroupSetting,
      isInternal: event.target.checked,
    });
  };

  // const [settingTypes, setSettingTypes] = useState();
  return (
    <ArgonBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ArgonBox mb={1.5}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Description
              </ArgonTypography>
            </ArgonBox>
            <ArgonInput
              disabled={savingNewSetting}
              value={newGroupSetting?.key || ""}
              onChange={(e) => {
                setNewGroupSetting({
                  ...newGroupSetting,
                  key: e.target.value,
                });
              }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ArgonBox mb={1.5}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Value
              </ArgonTypography>
            </ArgonBox>
            <ArgonInput
              disabled={savingNewSetting}
              value={newGroupSetting?.value || ""}
              onChange={(e) => {
                setNewGroupSetting({
                  ...newGroupSetting,
                  value: e.target.value,
                });
              }}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12}>
          <ArgonBox mb={1.5}>
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Setting Type
              </ArgonTypography>
            </ArgonBox>
            <ArgonSelect
              options={settingTypes}
              value={selectedSettingType}
              onChange={handleSettingTypeChange}
            />
          </ArgonBox>
        </Grid>
        <Grid item xs={12}>
          <ArgonBox mb={1.5} display="flex">
            <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
                textTransform="capitalize"
              >
                Internal
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox
              pl={2}
              mb={1}
              ml={0.5}
              lineHeight={0}
              display="inline-block"
            >
              <Checkbox
                checked={newGroupSetting?.isInternal === true ? true : false}
                onChange={(event) => handleInternalChange(event)}
              />
            </ArgonBox>
          </ArgonBox>
        </Grid>
      </Grid>
    </ArgonBox>
  );
}

export default GroupSettingCard;
