/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
// import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
// import DefaultCell from "layouts/administration/accounting/overview/components/DefaultCell";
import DefaultCell from "../DefaultCell";
//import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import StatusCell from "layouts/administration/accounting/overview/components/StatusCell";
import { useNavigate } from "react-router-dom";
import ArgonSelect from "components/ArgonSelect";
import ArgonBox from "components/ArgonBox";
import ArgonBadge from "components/ArgonBadge";
import Icon from "@mui/material/Icon";
import ArgonTypography from "components/ArgonTypography";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";

import i18n from "i18next";
import ActionCell from "./ActionCell";

export const generateColumns = ({ triggerFetch = null }) => {
  if (triggerFetch) {
    console.log("triggerFetch exists");
  }
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }) => {
        const [name, data] = value;
        const navigate = useNavigate();
        console.log("row", row.original.id);
        return (
          <DefaultCell
            value={typeof value === "string" ? `${value}` : `${name}`}
            sxValue={{
              ":hover": { textDecoration: "underline" },
              cursor: "pointer",
            }}
            onClick={() =>
              ///administration/customers/
              navigate(`/administration/customers/${row.original.id}`)
            }
            hidden={false}
          />
        );
      },
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={value} copyEnabled={true} hidden={true} />
        ) : null;
      },
    },

    {
      Header: "Subscription Tier",
      accessor: "subscriptionTier",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell
            value={
              value === "paid"
                ? "Paid"
                : value === "founder"
                ? "Founder"
                : "Free"
            }
            hidden={false}
          />
        ) : null;
      },
    },

    {
      Header: "Customer Payment Id",
      accessor: "customerPaymentId",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={value} copyEnabled={true} hidden={false} />
        ) : null;
      },
    },

    {
      Header: "HostHiv Id",
      accessor: "id",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={value} copyEnabled={true} hidden={false} />
        ) : null;
      },
    },

    {
      Header: "Property Count",
      accessor: "propertyCount",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={value} copyEnabled={false} hidden={false} />
        ) : null;
      },
    },
    {
      Header: "User Count",
      accessor: "userCount",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={value} copyEnabled={false} hidden={false} />
        ) : null;
      },
    },

    {
      Header: i18n.t("general.columns.created", { ns: "common" }),
      accessor: "created",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell
            value={convertTimeStamp(value, "America/New_York")}
            hidden={false}
          />
        ) : null;
      },
    },
    {
      Header: "Action",
      accessor: "actionColumn",
      Cell: ({ value, row }) => {
        return value ? (
          <ActionCell
            id={value}
            details={row.original}
            triggerFetch={triggerFetch}
          />
        ) : null;
      },
    },
  ];

  return columns;
};
