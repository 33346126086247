import React, { createContext, useContext, useState } from "react";
import { generateChecksum, verifyChecksum } from "services/checksums";

// Create the context
export const CompanyDetailsContext = createContext();

// Provider component
export const CompanyDetailsProvider = ({ children }) => {
  const [companyDetails, setCompanyDetails] = useState(() => {
    // On initial load, try to fetch the company details from Local Storage
    const storedDetails = localStorage.getItem("companyDetails");
    const storedChecksum = localStorage.getItem("companyDetailsChecksum");

    if (
      storedDetails &&
      storedChecksum &&
      verifyChecksum(storedDetails, storedChecksum)
    ) {
      console.log("storedDetails verified");
      return JSON.parse(storedDetails);
    }
    return null;
  });

  return (
    <CompanyDetailsContext.Provider
      value={{ companyDetails, setCompanyDetails }}
    >
      {children}
    </CompanyDetailsContext.Provider>
  );
};
