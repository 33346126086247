/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Card, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import {
  acceptedImageMimeTypes,
  acceptedImageSizeInMb,
  acceptedVideoMimeTypes,
  acceptedVideoSizeInMb,
  acceptedDocumentMimeTypes,
  acceptedPropertyImageSizeInMb,
  acceptedDocumentSizeInMb,
} from "services/acceptedMimeTypes";
import axios from "axios";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import { useState, useEffect, useContext } from "react";
import NotesApi from "api/notesApi";
import DocumentsApi from "api/documentsApi";
// sweetalert2 components
import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropertyContext from "context/PropertyContext";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import { set } from "lodash";
function UploadDocument({
  onClose,
  propertyDocuments,
  setPropertyDocuments,
  isDocumentReplacement,
  oldDocumentId,
  property,
}) {
  const { t } = useTranslation("common");
  const documentsApi = DocumentsApi();
  // const { property } = useContext(PropertyContext);
  const [note, setNote] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [fileName, setFileName] = useState("");
  const [description, setDescription] = useState("");
  const [fileUpload, setFileUpload] = useState(false);
  const [file, setFile] = useState(null);

  const [isSuccess, setIsSuccess] = useState(false);
  const [progress, setProgress] = useState(0);
  const [s3Response, setS3Response] = useState(null);
  const [uploading, setUploading] = useState(false);
  const handleFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const uploadHandler = async (event) => {
    const uploadedFiles = event.target.files;
    const uploadedFile = uploadedFiles[0];
    setFileName(uploadedFile.name);

    setFileUpload(true);

    setFile(uploadedFile);
  };
  const handleSubmitClicked = async () => {
    const allowedMimeTypes = acceptedDocumentMimeTypes;
    const maxSizeInMB = acceptedDocumentSizeInMb;
    let fileSize = file.size / (1024 * 1024);
    if (fileSize > maxSizeInMB * 1024 * 1024) {
      Swal.fire({
        icon: "error",
        title: "File too large",
        text: `File size must be less than ${maxSizeInMB}MB: ${fileSize}MB`,
      });

      setFileUpload(false);
      setFile(null);
      return;
    }

    if (!allowedMimeTypes.includes(file.type)) {
      Swal.fire({
        icon: "error",
        title: "Invalid file type",
        text: `File type must be one of the following: ${allowedMimeTypes.join(
          ", "
        )}`,
      });
      return;
    }

    try {
      setUploading(true);
      const data = {
        companyId: property?.companyId,
        propertyId: property?.id,
        name: file.name,
        fileFormat: file.type,
        description,
      };

      if (expirationDate) {
        data.expiration = expirationDate;
      }

      const res = await documentsApi.createDocument(data);

      if (res.status >= 200 && res.status < 300) {
        const documentId = res.data.id;
        const presignedUrl = await documentsApi.getDocumentPutUrl(documentId);
        const fields = presignedUrl.data.fields;
        const url = presignedUrl.data.url;
        const formData = new FormData();

        formData.append("Content-Type", file?.type);
        formData.append("key", fields?.key);
        formData.append("AWSAccessKeyId", fields?.AWSAccessKeyId);
        formData.append(
          "x-amz-security-token",
          fields?.["x-amz-security-token"]
        );
        formData.append("policy", fields?.policy);
        formData.append("signature", fields?.signature);
        formData.append("file", file);

        const uploadResponse = await axios.post(
          presignedUrl.data.url,
          formData
        );

        if (uploadResponse.status >= 200 && uploadResponse.status < 300) {
          if (isDocumentReplacement && oldDocumentId) {
            // const newPropertyDocuments = propertyDocuments.filter(
            //   (document) => document.id !== oldDocumentId
            // );
            // setPropertyDocuments(newPropertyDocuments); // Update state with filtered documents
            // await documentsApi.deleteDocument(oldDocumentId); // Await document deletion
            await updatePropertyDocuments(documentId, oldDocumentId);
          } else {
            await updatePropertyDocuments(documentId); // Update property documents
          }
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      onClose(); // Close modal in finally to ensure it happens regardless of success/error
    }
  };

  const updatePropertyDocuments = async (documentId, oldDocumentId = null) => {
    // Update propertyDocuments with new document
    let newPropertyDocuments = Array.isArray(propertyDocuments)
      ? [...propertyDocuments]
      : [];
    const addedDocument = await documentsApi.getDocument(documentId);
    console.log("--->", addedDocument);
    newPropertyDocuments.push(addedDocument.data);
    if (oldDocumentId) {
      await documentsApi.deleteDocument(oldDocumentId);
      // Assign the filtered results back to newPropertyDocuments
      newPropertyDocuments = newPropertyDocuments.filter(
        (document) => document.id !== oldDocumentId
      );
    }

    console.log("newPropertyDocuments", newPropertyDocuments);
    console.log("oldDocumentId", oldDocumentId);
    setPropertyDocuments(newPropertyDocuments);
  };

  const [expirationDate, setExpirationDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const handleDateChange = (date) => {
    setExpirationDate(dayjs(date).format("YYYY-MM-DD"));
  };

  return (
    <Card
      sx={{
        p: isSmallScreen ? 1 : 2,
        width: "150%",
        overflow: "visible",
        width: "100%",
        height: "100%",
        minWidth: "600px",
        "@media (max-width: 600px)": {
          minWidth: "auto",
        },
      }}
    >
      {uploading ? (
        <ArgonBox display="flex" justifyContent="center">
          <ArgonTypography variant="h5" fontWeight="regular">
            {t("generalWords.uploading")}...{fileName}
          </ArgonTypography>
          <HostHiveLoading />
        </ArgonBox>
      ) : (
        <>
          <ArgonBox p={2}>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
              pt={2}
              px={2}
            >
              <ArgonInput
                type="file"
                onChange={uploadHandler}
                // value={fileName}
              />
            </ArgonBox>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <ArgonBox pt={2} px={2}>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  gutterBottom
                >
                  {t("generalWords.name")}:
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox pt={2} px={2}>
                <ArgonTypography variant="caption" color="text">
                  {/* <ArgonInput
                value={fileName}
                onChange={handleFileNameChange}
              /> */}
                  {fileName}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>

            <ArgonBox
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <ArgonBox pt={2} px={2}>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  gutterBottom
                >
                  {t("generalWords.description")}:
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox pt={2} px={2}>
                <ArgonTypography variant="caption" color="text">
                  <ArgonInput
                    value={description}
                    onChange={handleDescriptionChange}
                  />
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
            <ArgonBox
              display="flex"
              justifyContent="space-between"
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <ArgonBox pt={2} px={2}>
                <ArgonTypography
                  variant="button"
                  fontWeight="medium"
                  gutterBottom
                >
                  Expiration Date (Optional):
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox pt={2} px={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={handleDateChange}
                    value={expirationDate}
                    orientation="landscape"
                  />
                </LocalizationProvider>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>

          <ArgonBox pt={1.25} pb={3} px={3} mt={3} lineHeight={1}>
            <ArgonTypography variant="h6" sx={{ fontSize: "1.2rem" }}>
              {/* Create Booking Report */}
            </ArgonTypography>
            <ArgonTypography
              variant="button"
              color="text"
              fontWeight="regular"
              sx={{ fontSize: "0.8rem" }}
            ></ArgonTypography>

            <ArgonBox>
              <ArgonBox display="flex" justifyContent="space-between" py={1}>
                <ArgonBox>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={onClose}
                  >
                    {t("buttons.close")}
                  </ArgonButton>
                </ArgonBox>

                <ArgonBox>
                  <ArgonButton
                    variant="gradient"
                    color="success"
                    onClick={handleSubmitClicked}
                  >
                    {t("buttons.upload")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </Card>
  );
}

export default UploadDocument;
