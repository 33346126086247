import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  Divider,
} from "@mui/material";

// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import MuiLink from "@mui/material/Link";
import Fade from "@mui/material/Fade";
import { useNavigate } from "react-router-dom";
import ArgonSnackbar from "components/ArgonSnackbar";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
import UploadDocument from "./components/UploadDocument";
import PropertyContext from "context/PropertyContext";

// sweetalert2 components
import Swal from "sweetalert2";

import useMediaQuery from "@mui/material/useMediaQuery";

import DocumentsApi from "api/documentsApi";
import DocumentItem from "./components/DocumentItem";
import { useTranslation } from "react-i18next";
function DocumentsDataCard({
  title,
  companyId,
  propertyId,
  handleClose,
  documentUploadDialogOpen,
  setDocumentUploadDialogOpen,
  property,
  // propertyDocuments,
  // setPropertyDocuments,
  ...rest
}) {
  const { t } = useTranslation("common");
  //const [notes,setPropertyNotes] = useState([])
  const navigate = useNavigate();
  const documentsApi = DocumentsApi();
  const [loading, setLoading] = useState(false);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  useEffect(() => {
    setLoading(true);
    async function fetchDocuments() {
      try {
        const response = await documentsApi.getDocuments({ propertyId });
        setPropertyDocuments(response.data);
      } catch (error) {
        console.log("error", error);
      }
      setLoading(false);
    }

    if (propertyId) {
      fetchDocuments();
    }
  }, []);

  // const { property, propertyDocuments, setPropertyDocuments } =
  //   useContext(PropertyContext);

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);

  const [isDocumentUpdated, setIsDocumentUpdated] = useState(false);

  // const handleCreateNoteSave = (note) => {
  //   console.log("Note to be saved: ", note);
  // };
  const [isDocumentReplacement, setIsDocumentReplacement] = useState(false);
  const [oldDocumentId, setOldDocumentId] = useState(null);
  const handleDocumentReplacement = (documentId) => {
    setIsDocumentReplacement(true);
    setDocumentUploadDialogOpen(true);
    setOldDocumentId(documentId);
  };
  return (
    <ArgonBox mt={6} mb={3}>
      <HostHiveDialog
        includeDelete={false}
        includeSave={false}
        includeClose={false}
        open={documentUploadDialogOpen}
        // onSave={handleSaveClicked}
        title={t("buttons.uploadDocument")}
        fullScreen={false}
        cancelButtonText={t("buttons.cancel")}
        submitButtonText={t("buttons.upload")}
        submitButtonColor="success"
        dialogObjects={
          <UploadDocument
            isDocumentReplacement={isDocumentReplacement}
            oldDocumentId={oldDocumentId}
            //onSave={handleCreateNoteSave}
            onClose={() => setDocumentUploadDialogOpen(false)}
            //setIsDocumentUpdated={setIsDocumentUpdated}
            propertyDocuments={propertyDocuments}
            setPropertyDocuments={setPropertyDocuments}
            property={property}
          />
        }
      />
      {/* If length of propertyDocuments is less then 1 "No documents" */}
      {loading ? (
        <HostHiveLoading message="Loading latest documents for property" />
      ) : (
        <>
          {propertyDocuments?.length < 1 && (
            <ArgonBox display="flex" justifyContent="center">
              {/* <ArgonTypography variant="h5" fontWeight="regular">
            No documents
          </ArgonTypography> */}
              <ArgonButton variant="outlined" color="info">
                {t("components.documents.noDocuments")}
              </ArgonButton>
            </ArgonBox>
          )}
          {propertyDocuments?.map((document, index) => (
            <DocumentItem
              key={document?.id || index}
              documentItem={document}
              propertyDocuments={propertyDocuments}
              setPropertyDocuments={setPropertyDocuments}
              handleDocumentReplacement={() =>
                handleDocumentReplacement(document?.id)
              }
              setOldDocumentId={setOldDocumentId}
              oldDocumentId={oldDocumentId}
            />
          ))}
        </>
      )}
    </ArgonBox>
  );
}

export default DocumentsDataCard;
