import * as Yup from "yup";

const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Category name is required"),
  category: Yup.string().required("Category type is required"),
  companyId: Yup.string().required("CompanyId is required"),
  status: Yup.string().oneOf(["active", "inactive"]).nullable(false),
});

export default CategorySchema;
