import React, { useState, useEffect, useMemo } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import IntegratedTransactions from "layouts/administration/accounting/components/IntegratedTransactions";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveDialog from "components/HostHiveDialog";
import AccountingApi from "api/accountingApi";
import PropertiesApi from "api/propertiesApi";
import CategoriesApi from "api/categoriesApi";
import Header from "layouts/administration/accounting/bulkTransactionManagement/components/Header";
import TransactionItem from "layouts/administration/accounting/bulkTransactionManagement/components/transactionItem";
import { useTranslation } from "react-i18next";
import HostHiveLoading from "components/HostHiveLoading";
import ImportMatchWizard from "layouts/management/components/ImportMatchWizard";
function BulkTransactionManagement({ inDialog = false, handleClose }) {
  const { t } = useTranslation("common");
  const accountingApi = AccountingApi();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const propertiesApi = new PropertiesApi();
  const categoriesApi = CategoriesApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [applyCategoryOpen, setApplyCategoryOpen] = useState(false);
  const [applyPropertyOpen, setApplyPropertyOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [skip, setSkip] = useState(0);
  const [searchField, setSearchField] = useState("");
  //const limit = 300; // Number of items to fetch each time
  const [limit, setLimit] = useState(10); // Number of items to fetch each time
  const [pendingApprovalLength, setPendingApprovalLength] = useState(0);
  const [selectedIds, setSelectedIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [properties, setProperties] = useState([]);
  const [transactionType, setTransactionType] = useState("all");
  const memoizedCategories = useMemo(() => categories, [categories]);
  const memoizedProperties = useMemo(() => properties, [properties]);
  const [bulkChangePayloadMapping, setBulkChangePayloadMapping] = useState({});
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [individualDelete, setIndividualDelete] = useState(false);
  const [matchOpen, setMatchOpen] = useState(false);
  const fetchTransactionsToApprove = async () => {
    setLoadingTransactions(true);
    setTransactions([]);
    try {
      let queryParams = {
        status: "pendingApproval",
        skip, // Add skip and limit to your query params
        limit,
        transactionType,
        search: searchField,
      };
      if (startDate) {
        queryParams.startDate = startDate;
      }
      if (endDate) {
        queryParams.endDate = endDate;
      }
      const response = await accountingApi.getTransactions(queryParams);
      console.log("response", response);

      if (response.data.hasMore > skip + limit) {
        setHasMore(true);
      } else {
        setHasMore(false);
      }

      // Appending new transactions to existing ones
      setTransactions((prevTransactions) => [
        ...prevTransactions,
        ...response.data.items,
      ]);
      setPendingApprovalLength(response.data.totalResults);
    } catch (error) {
      console.error(error);
    }
    setLoadingTransactions(false);
  };
  useEffect(() => {
    fetchTransactionsToApprove();
  }, [limit, skip, transactionType, searchField, startDate, endDate]);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const queryParams = {
          companyId: company?.id,
          type: "accounting",
        };

        const response = await categoriesApi.getCategories(queryParams);
        // setCategories(response.data);
        console.log(response.data);

        // generate options array, including the "Create New" option
        const options = response.data.map((category) => ({
          value: category.id,
          label: (
            <ArgonBadgeDot
              color={category?.color}
              size="md"
              badgeContent={category?.name}
            />
          ),
        }));

        // options.push({
        //   value: "new",
        //   label: `+ ${t("components.category.create.createNew")}`,
        // });
        // options.push({
        //   value: null,
        //   label: `Clear Category`,
        // });
        setCategories(options);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchProperties() {
      console.log("CompanyId", company?.id);
      try {
        const queryParams = {
          companyId: company?.id,
          ownerOnly: true,
        };

        const response = await propertiesApi.getProperties(queryParams);

        const options = response.data.map((property) => ({
          value: property.id,
          label: property.name,
        }));

        setProperties(options);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCategories();
    fetchProperties();
  }, []);

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };
  const MemoizedArgonSelect = React.memo(ArgonSelect);

  const [propertySelected, setPropertySelected] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [workingId, setWorkingId] = useState(null);
  const handleBulkChangeProperty = () => {
    // For each selected transaction, update the payload mapping
    // with the new value
    const newBulkChangePayloadMapping = { ...bulkChangePayloadMapping };

    if (individudalUpdateId !== null) {
      newBulkChangePayloadMapping[individudalUpdateId] = {
        ...newBulkChangePayloadMapping[individudalUpdateId],
        property: propertySelected,
      };
      setIndividualUpdateId(null);
    } else {
      selectedIds.forEach((id) => {
        newBulkChangePayloadMapping[id] = {
          ...newBulkChangePayloadMapping[id],
          property: propertySelected,
        };
      });
    }
    setBulkChangePayloadMapping(newBulkChangePayloadMapping);
    setPropertySelected(null);
    // setSelectedIds([]);
    setApplyPropertyOpen(false);
  };

  const handleBulkChangeCategory = () => {
    // For each selected transaction, update the payload mapping
    // with the new value
    const newBulkChangePayloadMapping = { ...bulkChangePayloadMapping };
    if (individudalUpdateId !== null) {
      newBulkChangePayloadMapping[individudalUpdateId] = {
        ...newBulkChangePayloadMapping[individudalUpdateId],
        category: categorySelected,
      };
      setIndividualUpdateId(null);
    } else {
      selectedIds.forEach((id) => {
        newBulkChangePayloadMapping[id] = {
          ...newBulkChangePayloadMapping[id],
          category: categorySelected,
        };
      });
    }

    setBulkChangePayloadMapping(newBulkChangePayloadMapping);
    setCategorySelected(null);
    // setSelectedIds([]);
    setApplyCategoryOpen(false);
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedIds(
      event.target.checked ? transactions.map((item) => item.id) : []
    );
  };

  const handleViewMore = () => {
    setSkip((prevSkip) => prevSkip + limit);
  };
  const [saving, setSaving] = useState(false);
  const handleSave = async () => {
    console.log("Bulk Change Payload Mapping", bulkChangePayloadMapping);
    setSaving(true);
    let invokeViewMore = false;
    if (selectedIds.length > limit / 2) {
      invokeViewMore = true;
    }
    // Create a new array of objects with the following structure for each item in bulkChangePayloadMapping:
    // {
    // "propertyId": "propertyId",
    // "categoryId": "categoryId",
    // }
    const newBulkChangePayload = [];
    Object.keys(bulkChangePayloadMapping).forEach((key) => {
      const newBulkChangePayloadItem = {
        propertyId: bulkChangePayloadMapping[key].property?.value,
        categoryId: bulkChangePayloadMapping[key].category?.value,
        id: key,
      };
      newBulkChangePayload.push(newBulkChangePayloadItem);
    });
    console.log(
      "New Bulk Change Payload",
      newBulkChangePayload.length,
      newBulkChangePayload
    );

    let approval = true;
    try {
      const response = await accountingApi.updateTransactionApprovalStateBulk({
        entityIds: newBulkChangePayload,
        approved: approval,
      });
      console.log(response);

      // Remove all transactions that were approved
      setTransactions((prevTransactions) =>
        prevTransactions.filter(
          (transaction) =>
            !newBulkChangePayload.some(
              (newBulkChangePayloadItem) =>
                newBulkChangePayloadItem.id === transaction.id
            )
        )
      );

      // Remove all selectedIds that were approved
      // setSelectedIds((prevSelectedIds) =>
      //   prevSelectedIds.filter(
      //     (selectedId) =>
      //       !newBulkChangePayload.some(
      //         (newBulkChangePayloadItem) =>
      //           newBulkChangePayloadItem.id === selectedId
      //       )
      //   )
      // );
      setSelectAll(false);
      setBulkChangePayloadMapping({});
      setSelectedIds([]);
      setSaveDisabled(true);

      if (transactions.length === 0) {
        handleClose();
      } else {
        if (invokeViewMore) {
          handleViewMore();
        }
      }
    } catch (error) {
      console.error(error);
    }
    setSaving(false);
  };

  useEffect(() => {
    // Disable save button initially if there are no selected transactions
    const initialSaveDisabled = selectedIds.length === 0;
    setSaveDisabled(initialSaveDisabled);

    // Disable save button if any selected transaction does not have both a category and property assigned
    const updateSaveDisabled = selectedIds.some((id) => {
      const mapping = bulkChangePayloadMapping[id];
      return (
        !mapping ||
        mapping.category?.value === undefined ||
        mapping.property?.value === undefined
      );
    });

    // Update the saveDisabled state only if there are selectedIds
    if (selectedIds.length > 0) {
      setSaveDisabled(updateSaveDisabled);
    }
  }, [bulkChangePayloadMapping, selectedIds]);

  const handleDeleteSelected = async () => {
    setSaving(true);
    setIndividualDelete(true);
    let approval = false;
    // Create a new array of objects with the following structure for each item in bulkChangePayloadMapping:
    // {
    // "propertyId": "propertyId",
    // "categoryId": "categoryId",
    // }
    console.log("Selected Ids to delete", selectedIds);
    const newBulkChangePayload = [];

    Object.keys(selectedIds).forEach((key) => {
      const newBulkChangePayloadItem = {
        propertyId: "none",
        categoryId: "none",
        id: selectedIds[key],
      };
      // console.log(key);
      newBulkChangePayload.push(newBulkChangePayloadItem);
    });

    try {
      const response = await accountingApi.updateTransactionApprovalStateBulk({
        entityIds: newBulkChangePayload,
        approved: approval,
      });
      console.log(response);

      // Remove all transactions that were approved
      setTransactions((prevTransactions) =>
        prevTransactions.filter(
          (transaction) =>
            !selectedIds.some((selectedId) => selectedId === transaction.id)
        )
      );

      // Remove all selectedIds from setBulkChangePayloadMapping
      const newBulkChangePayloadMapping = { ...bulkChangePayloadMapping };
      selectedIds.forEach((id) => {
        delete newBulkChangePayloadMapping[id];
      });
      setBulkChangePayloadMapping(newBulkChangePayloadMapping);

      // Remove all selectedIds that were approved
      setSelectedIds([]);
      setSaveDisabled(true);
      // handleViewMore();
    } catch (error) {
      console.error(error);
    }
  };
  const [individualApprove, setIndividualApprove] = useState(false);
  const [individudalUpdateId, setIndividualUpdateId] = useState(null);
  const handleIndividualAction = async (id, approval, action) => {
    if (id) {
      setWorkingId(id);
      if (action === "delete") {
        setIndividualDelete(true);
      } else {
        setIndividualApprove(true);
      }
    }
    setSaving(true);

    // Create a new array of objects with the following structure for each item in bulkChangePayloadMapping:
    // {
    // "propertyId": "propertyId",
    // "categoryId": "categoryId",
    // }
    const newBulkChangePayload = [];
    let newBulkChangePayloadItem;
    if (action === "delete") {
      newBulkChangePayloadItem = {
        propertyId: "none",
        categoryId: "none",
        id: id,
      };
    } else {
      newBulkChangePayloadItem = {
        propertyId: bulkChangePayloadMapping[id].property?.value,
        categoryId: bulkChangePayloadMapping[id].category?.value,
        id: id,
      };
    }

    newBulkChangePayload.push(newBulkChangePayloadItem);

    try {
      const response = await accountingApi.updateTransactionApprovalStateBulk({
        entityIds: newBulkChangePayload,
        approved: approval,
      });
      console.log(response);

      // Remove all transactions that were approved
      setTransactions((prevTransactions) =>
        prevTransactions.filter(
          (transaction) => transaction.id !== newBulkChangePayloadItem.id
        )
      );

      // Remove all selectedIds from setBulkChangePayloadMapping
      const newBulkChangePayloadMapping = { ...bulkChangePayloadMapping };
      delete newBulkChangePayloadMapping[id];
      setBulkChangePayloadMapping(newBulkChangePayloadMapping);

      // Remove all selectedIds that were approved

      setSelectedIds([]);
      setSaveDisabled(true);
      // handleViewMore();
    } catch (error) {
      console.error(error);
    }
    if (id) {
      setIndividualDelete(false);
      setIndividualApprove(false);
    }
    setSaving(false);
  };
  return (
    <>
      {inDialog ? (
        <ArgonBox py={3}>
          <ArgonBox mb={3}>
            <HostHiveDialog
              open={matchOpen}
              onClose={() => setMatchOpen(false)}
              // onSave={() => handleBulkChangeCategory()}
              includeSave={false}
              includeClose={true}
              title="Import Match Wizard"
              fullScreen={false}
              maxWidth="lg"
              cancelButtonText="Cancel"
              submitButtonText="Apply"
              dialogObjects={
                <>
                  <ImportMatchWizard
                    bulkChangePayloadMapping={bulkChangePayloadMapping}
                    setBulkChangePayloadMapping={setBulkChangePayloadMapping}
                    handleSave={handleSave}
                    closeWizard={() => setMatchOpen(false)}
                  />
                </>
              }
            />
            <HostHiveDialog
              open={applyCategoryOpen}
              onClose={() => setApplyCategoryOpen(false)}
              onSave={() => handleBulkChangeCategory()}
              includeSave={true}
              includeClose={true}
              title="Apply Category"
              fullScreen={false}
              maxWidth="sm"
              cancelButtonText="Cancel"
              submitButtonText="Apply"
              dialogObjects={
                <>
                  <MemoizedArgonSelect
                    label="Category"
                    value={categorySelected}
                    onChange={(e) => setCategorySelected(e)}
                    options={memoizedCategories}
                    fullWidth
                  />
                </>
              }
            />
            <HostHiveDialog
              open={applyPropertyOpen}
              includeSave={true}
              onClose={() => setApplyPropertyOpen(false)}
              onSave={() => handleBulkChangeProperty()}
              includeClose={true}
              title="Apply Property"
              fullScreen={false}
              maxWidth="sm"
              cancelButtonText="Cancel"
              submitButtonText="Apply"
              dialogObjects={
                <>
                  <MemoizedArgonSelect
                    label="Property"
                    value={propertySelected}
                    onChange={(e) => setPropertySelected(e)}
                    options={memoizedProperties}
                    fullWidth
                  />
                </>
              }
            />
          </ArgonBox>
          <Header
            title="Imported Transactions"
            setLimit={setLimit}
            limit={limit}
            subtitle=""
            setApplyCategoryOpen={setApplyCategoryOpen}
            setApplyPropertyOpen={setApplyPropertyOpen}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            handleSelectAllChange={handleSelectAllChange}
            handleSave={handleSave}
            saveDisabled={saveDisabled}
            saving={saving}
            handleDeleteSelected={handleDeleteSelected}
            transactionType={transactionType}
            setTransactionType={setTransactionType}
            setMatchOpen={setMatchOpen}
            searchField={searchField}
            setSearchField={setSearchField}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            resultsCount={transactions.length}
            loadingTransactions={loadingTransactions}
          />
          <ArgonBox pb={2}></ArgonBox>
          <Card>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                {loadingTransactions ? (
                  <ArgonBox p={2}>
                    <HostHiveLoading
                      message="Loading transactions"
                      elipse={true}
                    />
                  </ArgonBox>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                {transactions?.map((transaction) => (
                  <ArgonBox pl={2}>
                    <TransactionItem
                      key={transaction.id}
                      setIndividualUpdateId={setIndividualUpdateId}
                      setApplyCategoryOpen={setApplyCategoryOpen}
                      setApplyPropertyOpen={setApplyPropertyOpen}
                      selectedIds={selectedIds}
                      transaction={transaction}
                      setSelectedIds={setSelectedIds}
                      handleCheckboxChange={handleCheckboxChange}
                      bulkChangePayloadMapping={bulkChangePayloadMapping}
                      setTransactions={setTransactions}
                      memoizedCategories={memoizedCategories}
                      memoizedProperties={memoizedProperties}
                      handleIndividualAction={handleIndividualAction}
                      individualDelete={individualDelete}
                      setIndividualDelete={setIndividualDelete}
                      workingId={workingId}
                    />
                  </ArgonBox>
                ))}
              </Grid>
            </Grid>
          </Card>
        </ArgonBox>
      ) : (
        <DashboardLayout>
          {/* {/* <DashboardNavbar /> */}
          <ArgonBox mt={1} mb={20}>
            <Grid container justifyContent="center">
              <Grid item xs={12} lg={8}>
                <ArgonBox p={2}>
                  <ArgonBox>
                    <ArgonBox py={3}>
                      <ArgonBox mb={3}>
                        <HostHiveDialog
                          open={applyCategoryOpen}
                          onClose={() => setApplyCategoryOpen(false)}
                          onSave={() => handleBulkChangeCategory()}
                          includeSave={true}
                          includeClose={true}
                          title="Apply Category"
                          fullScreen={false}
                          maxWidth="sm"
                          cancelButtonText="Cancel"
                          submitButtonText="Apply"
                          dialogObjects={
                            <>
                              <MemoizedArgonSelect
                                label="Category"
                                value={categorySelected}
                                onChange={(e) => setCategorySelected(e)}
                                options={memoizedCategories}
                                fullWidth
                              />
                            </>
                          }
                        />
                        <HostHiveDialog
                          open={applyPropertyOpen}
                          includeSave={true}
                          onClose={() => setApplyPropertyOpen(false)}
                          onSave={() => handleBulkChangeProperty()}
                          includeClose={true}
                          title="Apply Property"
                          fullScreen={false}
                          maxWidth="sm"
                          cancelButtonText="Cancel"
                          submitButtonText="Save"
                          dialogObjects={
                            <>
                              <MemoizedArgonSelect
                                label="Property"
                                value={propertySelected}
                                onChange={(e) => setPropertySelected(e)}
                                options={memoizedProperties}
                                fullWidth
                              />
                            </>
                          }
                        />
                      </ArgonBox>
                      <Header
                        title="Imported Transactions"
                        subtitle=""
                        setApplyCategoryOpen={setApplyCategoryOpen}
                        setApplyPropertyOpen={setApplyPropertyOpen}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                        handleSelectAllChange={handleSelectAllChange}
                        handleSave={handleSave}
                        saveDisabled={saveDisabled}
                        saving={saving}
                        handleDeleteSelected={handleDeleteSelected}
                      />
                      <ArgonBox pb={2}></ArgonBox>
                      <Card>
                        <Grid container spacing={2} justifyContent="center">
                          <Grid item xs={12}>
                            {loadingTransactions ? (
                              <ArgonBox p={2}>
                                <HostHiveLoading
                                  message="Loading transactions"
                                  elipse={true}
                                />
                              </ArgonBox>
                            ) : null}
                          </Grid>
                          <Grid item xs={12}>
                            {transactions?.map((transaction) => (
                              <ArgonBox pl={2}>
                                <TransactionItem
                                  key={transaction.id}
                                  setIndividualUpdateId={setIndividualUpdateId}
                                  setApplyCategoryOpen={setApplyCategoryOpen}
                                  setApplyPropertyOpen={setApplyPropertyOpen}
                                  selectedIds={selectedIds}
                                  transaction={transaction}
                                  setSelectedIds={setSelectedIds}
                                  handleCheckboxChange={handleCheckboxChange}
                                  bulkChangePayloadMapping={
                                    bulkChangePayloadMapping
                                  }
                                  setTransactions={setTransactions}
                                  memoizedCategories={memoizedCategories}
                                  memoizedProperties={memoizedProperties}
                                  handleIndividualAction={
                                    handleIndividualAction
                                  }
                                  individualDelete={individualDelete}
                                  setIndividualDelete={setIndividualDelete}
                                  workingId={workingId}
                                />
                              </ArgonBox>
                            ))}
                          </Grid>
                        </Grid>
                      </Card>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default BulkTransactionManagement;
