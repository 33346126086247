/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Grid from "@mui/material/Grid";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import StatusCell from "layouts/management/serviceInvoicing/components/StatusCell";
// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { convertTimeStamp } from "services/dateUtils";
// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import ServiceInvoicingApi from "api/serviceInvoicing";

function PaymentItem({ invoice }) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);
  const serviceInvoicingApi = ServiceInvoicingApi();
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleRefund = async () => {
    try {
      const response = await serviceInvoicingApi.refundInvoice(
        invoice?.invoiceId
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }

    // console.log("refund", invoice?.id);
    handleCloseMenu();
  };
  const handleMarkAsPaid = () => {
    console.log("mark as paid", invoice?.id);
    handleCloseMenu();
  };

  const handleSendReminder = () => {
    console.log("send reminder", invoice?.id);
    handleCloseMenu();
  };

  const handleResendReciept = () => {
    console.log("resend reciept", invoice?.id);
    handleCloseMenu();
  };

  const handleViewInvoiceClicked = () => {
    let url = invoice?.invoiceInfo?.hostedInvoiceUrl;
    window.open(url, "_blank");
    // window.location.href = url;
    handleCloseMenu();
  };

  const handleDownloadInvoiceClicked = () => {
    let url = invoice?.invoiceInfo?.invoicePdf;
    window.open(url, "_blank");
    // window.location.href = url;
    handleCloseMenu();
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {invoice.statusInfo.status === "paid" ? (
        <>
          {invoice?.paymentIntentId ? (
            <MenuItem disabled={true} onClick={handleRefund}>
              Refund
            </MenuItem>
          ) : null}
          <MenuItem disabled={true} onClick={handleResendReciept}>
            Resend Reciept
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem disabled={true} onClick={handleSendReminder}>
            Send Reminder
          </MenuItem>
          <MenuItem disabled={true} onClick={handleMarkAsPaid}>
            Mark as Paid
          </MenuItem>
          <MenuItem disabled={true} onClick={handleMarkAsPaid}>
            Mark as Refunded
          </MenuItem>
        </>
      )}

      {invoice?.invoiceInfo?.invoicePdf ? (
        <>
          <MenuItem onClick={handleViewInvoiceClicked}>View Invoice</MenuItem>
          <MenuItem onClick={handleDownloadInvoiceClicked}>
            Download Invoice
          </MenuItem>
        </>
      ) : null}
      {/* <MenuItem onClick={handleViewInvoiceClicked}>View Invoice</MenuItem>
      <MenuItem onClick={handleDownloadInvoiceClicked}>
        Download Invoice
      </MenuItem> */}

      {/* <MenuItem onClick={handleCloseMenu}>Something else here</MenuItem> */}
    </Menu>
  );

  //  if (value === "paid") {
  //    status = <StatusCell icon="done" color="success" status="Paid" />;
  //  } else if (value === "refunded") {
  //    status = <StatusCell icon="replay" color="dark" status="Refunded" />;
  //  } else {
  //    status = <StatusCell icon="close" color="error" status="Canceled" />;
  //  }

  return (
    <Grid container component="li" style={{ listStyle: "none" }}>
      <Grid item xs={12} style={{ paddingLeft: 8, marginLeft: 16 }}>
        <Grid
          container
          alignItems="center"
          style={{ marginTop: 16, paddingLeft: 4, paddingRight: 4 }}
        >
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Customer
            </ArgonTypography> */}
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              {invoice?.invoiceNumber}
            </ArgonTypography>
          </Grid>

          {/* Customer Info */}
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Customer
            </ArgonTypography> */}
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              {invoice?.customerInfo?.name}
            </ArgonTypography>
          </Grid>

          {/* Date */}
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Date
            </ArgonTypography> */}
            <ArgonTypography variant="caption" fontWeight="bold" color="text">
              {convertTimeStamp(invoice?.created)}
            </ArgonTypography>
          </Grid>

          {/* Amount */}
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Amount
            </ArgonTypography> */}
            <ArgonTypography
              variant="caption"
              fontWeight="bold"
              color={
                invoice?.payoutAmount > 0
                  ? "success"
                  : invoice.payoutAmount < 0
                  ? "error"
                  : "text"
              }
            >
              ${invoice?.amount}
            </ArgonTypography>
          </Grid>

          {/* Payout */}
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Payout
            </ArgonTypography> */}
            <ArgonTypography
              variant="caption"
              fontWeight="bold"
              color={
                invoice?.payoutAmount > 0
                  ? "success"
                  : invoice.payoutAmount < 0
                  ? "error"
                  : "text"
              }
            >
              ${invoice?.payoutAmount}
            </ArgonTypography>
          </Grid>

          {/* Status */}
          <Grid item xs={12} sm={1.7}>
            {/* <ArgonTypography
              variant="caption"
              fontWeight="medium"
              color="secondary"
            >
              Status
            </ArgonTypography> */}
            <ArgonBadge
              color={invoice?.statusInfo?.color}
              badgeContent={invoice?.statusInfo?.status}
              // container
              variant="contained"
              size="lg"
            />
          </Grid>
          <Grid item xs={12} sm={1.7}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <ArgonButton
                  variant="gradient"
                  color="primary"
                  size="small"
                  onClick={handleOpenMenu}
                >
                  {/* <Icon>more_vert</Icon> */}
                  Options
                </ArgonButton>
                {renderMenu()}
              </Grid>
              {/* <Grid item xs={12} sm={6}></Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Uncomment below line if you need a divider */}
      {/* <Divider /> */}
    </Grid>
  );
}

// Setting default values for the props of Todo
PaymentItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
PaymentItem.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  noDivider: PropTypes.bool,
};

export default PaymentItem;
