/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
// import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import DefaultCell from "layouts/administration/accounting/overview/components/DefaultCell";
//import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import StatusCell from "layouts/administration/accounting/overview/components/StatusCell";
import { useNavigate } from "react-router-dom";
import ArgonSelect from "components/ArgonSelect";
import ArgonBox from "components/ArgonBox";
import ArgonBadge from "components/ArgonBadge";
import Icon from "@mui/material/Icon";
import ArgonTypography from "components/ArgonTypography";
import ViewDataItem from "../ViewDataItem";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";

import i18n from "i18next";

export const generateColumns = ({ triggerFetch = null }) => {
  if (triggerFetch) {
    console.log("triggerFetch exists");
  }
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value, row }) => {
        // const [name, data] = value;

        return (
          <ViewDataItem
            value={value}
            itemDetails={row?.original}
            id={row?.original?.id}
            triggerFetch={triggerFetch}
          />
        );
      },
    },

    // {
    //   Header: "Name",
    //   accessor: "name",
    //   Cell: ({ value }) => {
    //     return value ? <DefaultCell value={value} /> : null;
    //   },
    // },
    {
      Header: "Email",
      accessor: "email",
      Cell: ({ value }) => {
        return value ? <DefaultCell value={value} /> : null;
      },
    },
    {
      Header: "Phone Number",
      accessor: "phone",
      Cell: ({ value }) => {
        return value ? <DefaultCell value={value} /> : null;
      },
    },
  ];

  return columns;
};
