/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import ArgonAlert from "components/ArgonAlert";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ServicePartnersSelect from "layouts/management/components/ServicePartnerSelect";
import UsersSelect from "layouts/management/components/UsersSelect";
import CompaniesApi from "api/companyApi";
import UsersApi from "api/usersApi";
import BookingsApi from "api/bookingsApi";
import HostHiveDialog from "components/HostHiveDialog";
import ServiceNameItemDialog from "layouts/management/company/settings/components/ServiceNames/components/ServiceNameItemDialog";
import ServicePartnerItemDialog from "layouts/management/company/settings/components/ServicePartners/components/ServicePartnerItemDialog";
import { set } from "lodash";
function SelectServicePartner({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  responseData,
  setResponseData,
  isInCreationMode,
  isOwner = null,
  updateToSave = null,
  setUpdateToSave = null,
  onClose = null,
  handleRefetchBookings = null,
}) {
  const companiesApi = CompaniesApi();
  const bookingsApi = BookingsApi();
  const usersApi = UsersApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [partner, setPartner] = useState(
    {
      value: bookingPayload?.assignedCompanyId,
      label: userSelections[bookingPayload?.assignedCompanyId],
    } || null
  );
  const [subAssigneeSelection, setSubAssigneeSelection] = useState(false);
  const [user, setUser] = useState(
    {
      value: bookingPayload?.assignedUserSubId,
      label: userSelections[bookingPayload?.assignedUserSubId],
    } || null
  );
  const [servicePartners, setServicePartners] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (bookingPayload?.assignedCompanyId === company?.id) {
      setSubAssigneeSelection(true);
    } else {
      setSubAssigneeSelection(false);
    }
  }, [bookingPayload?.assignedCompanyId]);

  useEffect(() => {
    async function fetchPartners() {
      try {
        const queryParams = {
          companyId: company?.id,
        };

        const response = await companiesApi.getCompanyPartnerships(
          company?.id,
          {}
        );
        let partnersResponse = response.data;
        let partnersOptions = partnersResponse.map((partner) => ({
          value: partner.id,
          label: partner.name,
        }));
        partnersOptions.push({
          value: "new",
          label: `+ Invite Service Partner`,
        });
        setServicePartners(partnersOptions);
      } catch (error) {
        console.error(error);
      }
    }
    async function fetchUsers() {
      try {
        const queryParams = {
          companyId: company?.id,
        };
        const response = await usersApi.getUsers(queryParams);
        let usersResponse = response.data;
        const usersOptions = usersResponse.map((user) => ({
          value: user.id,
          label: user.email,
        }));

        setUsers(usersOptions);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUsers();
    fetchPartners();
  }, []);
  const [updatedSubAssignee, setUpdatedSubAssignee] = useState(null);
  const [updatedPartner, setUpdatedPartner] = useState(null);
  const updateBooking = async (key, value) => {
    if (key === "assignedCompanyId") {
      console.log("assignedCompanyId", value);
      setUpdatedPartner(value);

      const response = await bookingsApi.updateBookingAssignee(
        bookingPayload?.id,
        value
      );

      // if (!subAssigneeSelection) {
      //   console.log("Clear out sub assigneeid");
      // }
    } else {
      console.log("assignedUserSubId", value);
      setUpdatedSubAssignee(value);

      const response = await bookingsApi.updateBookingSubAssignee(
        bookingPayload?.id,
        value
      );
    }
    // if (handleRefetchBookings) {
    //   handleRefetchBookings();
    // }
    // if (onClose) {
    //   onClose();
    // }
  };
  const [isNewServicePartner, setIsNewServicePartner] = useState(false);
  const [partnerWarningMessage, setPartnerWarningMessage] = useState(null);
  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const handlePartnerSelected = (e) => {
    if (e.value === bookingPayload?.assignedCompanyId) {
      return;
    }

    if (e.value !== bookingPayload?.assignedCompanyId) {
      console.log("Clear out sub assigneeid");
      updateBooking("assignedUserSubId", null);
    }
    if (e.value === "new") {
      setIsNewServicePartner(true);
    } else {
      console.log("Using existing partner");
      setIsNewServicePartner(false);
      setPartner(e);
      console.log("e", e?.warning);
      if (e.warning === true) {
        setShowWarningMessage(true);
        setPartnerWarningMessage(
          "User company is the same as partner company. If this is unintentional, delete user from your company and try to add them again as a service partner."
        );
      } else {
        setShowWarningMessage(false);
      }

      setBookingPayload({ ...bookingPayload, assignedCompanyId: e?.value });
      setUserSelections({
        ...userSelections,
        [e?.value]: e?.label,
      });
      setNextDisabled(false);
      if (company?.id === e?.value) {
        setSubAssigneeSelection(true);
      }
      if (!isInCreationMode) {
        updateBooking("assignedCompanyId", e?.value);
      }
    }
  };

  const handleUserSelected = (e) => {
    setUser(e);
    setBookingPayload({ ...bookingPayload, assignedUserSubId: e?.value });
    setUserSelections({
      ...userSelections,
      [e?.value]: e?.label,
    });
    setNextDisabled(false);
    if (!isInCreationMode) {
      updateBooking("assignedUserSubId", e?.value);
    }
  };

  return (
    <Grid container spacing={2}>
      <HostHiveDialog
        includeDelete={false}
        includeClose={false}
        includeSave={false}
        open={isNewServicePartner}
        onClose={() => {
          setIsNewServicePartner(false);
        }}
        title="Add Service Partner"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <ServicePartnerItemDialog
            companyId={company?.id}
            handlePartnerSelected={handlePartnerSelected}
            handleSelection={true}
            onClose={() => {
              setIsNewServicePartner(false);
            }}
          />
        }
      />
      <Grid item xs={12}>
        {showWarningMessage ? (
          <ArgonAlert color="warning" dismissible>
            <ArgonBox display="flex" justifyContent="flex-end">
              <ArgonBox>
                <ArgonTypography variant="caption" color="white">
                  {partnerWarningMessage}
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </ArgonAlert>
        ) : null}
        <ArgonBox>
          <ArgonBox lineHeight={0}>
            {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
            <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the partner who is providing the service.
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox lineHeight={0}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
              pb={2}
            >
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Service Partner
                </ArgonTypography>
              </ArgonBox>
              {!isInCreationMode && isOwner !== true ? (
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {partner?.label}
                </ArgonTypography>
              ) : (
                <ArgonSelect
                  menuPlacement="bottom"
                  value={
                    partner
                      ? partner
                      : { value: "none", label: "Select a Service Partner" }
                  }
                  options={servicePartners}
                  onChange={handlePartnerSelected}
                />
              )}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Grid>
      {subAssigneeSelection
        ? null
        : // <Grid item xs={12}>
          //   <ArgonBox>
          //     <ArgonBox lineHeight={0}>
          //       {/* <ArgonTypography variant="h5" fontWeight="bold">
          //   File Upload
          // </ArgonTypography> */}
          //       <ArgonTypography
          //         variant="button"
          //         fontWeight="regular"
          //         color="text"
          //       >
          //         Select the user to assign the service to (Optional)
          //       </ArgonTypography>
          //     </ArgonBox>
          //     <ArgonBox lineHeight={0}>
          //       <ArgonBox
          //         display="flex"
          //         flexDirection="column"
          //         justifyContent="flex-end"
          //         height="100%"
          //         pb={2}
          //       >
          //         <ArgonBox
          //           mb={1}
          //           ml={0.5}
          //           mt={3}
          //           lineHeight={0}
          //           display="inline-block"
          //         >
          //           <ArgonTypography
          //             component="label"
          //             variant="caption"
          //             fontWeight="bold"
          //           >
          //             User
          //           </ArgonTypography>
          //         </ArgonBox>
          //         <ArgonSelect
          //           menuPlacement="bottom"
          //           value={
          //             user ? user : { value: "none", label: "Select a User" }
          //           }
          //           options={users}
          //           onChange={handleUserSelected}
          //         />
          //       </ArgonBox>
          //     </ArgonBox>
          //   </ArgonBox>
          // </Grid>
          null}
      {/* {!isInCreationMode ? (
        <ArgonButton variant="gradient" color="light" onClick={handleBack}>
          Save
        </ArgonButton>
      ) : null} */}
    </Grid>
  );
}

// typechecking props for SelectServicePartner
SelectServicePartner.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default SelectServicePartner;
