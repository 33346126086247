/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import { useState, useEffect, useContext, useMemo } from "react";
import HostHiveDialog from "components/HostHiveDialog";
import TaskItemDialog from "../components/TaskItemDialog";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Divider from "@mui/material/Divider";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonInput from "components/ArgonInput";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TaskTimeLine from "../components/TaskTimeLineDataCard";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "../components/DetailedStatisticsCard";
import HowToVideoButton from "layouts/management/components/VideoIconButton";

import { Link, useParams } from "react-router-dom";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";

import Card from "@mui/material/Card";
import TasksApi from "api/propertyTasksApi";

import TimelineItem from "../components/TimelineItem";
import HostHiveSnackbar from "components/HostHiveSnackbar";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import PropertiesApi from "api/propertiesApi";
// Data
import HostHiveDataTable from "components/HostHiveDataTable";
import { columns } from "layouts/administration/taskCenter/components/taskCenterColumns/";
import { useTranslation } from "react-i18next";
import { VideoLibraryHelper } from "services/videoLibraryHelper";
import useMediaQuery from "@mui/material/useMediaQuery";
import ComplexTaskCard from "layouts/administration/taskCenter/components/ComplexTaskCard";
function TasksCenter({ companyId, propertyId = null, createTask = false }) {
  const { t } = useTranslation("common");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { renderSuccessSB, openSuccessSB, openErrorSB, renderErrorSB } =
    HostHiveSnackbar();
  const tasksApi = TasksApi();
  const propertiesApi = PropertiesApi();
  const { size } = typography;

  const [taskTableData, setTaskTableData] = useState({
    columns: columns,
    rows: [
      {
        summary: "",
        description: "",
        status: "",
        ticketNumber: "",
        created: "",
        priority: "",
      },
    ],
  });

  //const [propertyId, setPropertyId] = useState(null);
  const createTaskVideoId = VideoLibraryHelper("taskManagement").videoId;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [tasks, setTasks] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [simpleTrasks, setSimpleTasks] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      try {
        let queryParams = {
          companyId: companyId,
          limit: 1000,
        };

        if (activeOnly) {
          queryParams.notStatus = "closed";
        }

        if (propertyId) {
          queryParams.propertyId = propertyId;
        }

        const tasksResponse = await tasksApi.getTasks(queryParams);

        if (tasksResponse.status >= 200 && tasksResponse.status < 300) {
          setTasks(tasksResponse.data);
          setSimpleTasks(tasksResponse.data.items);
          setTaskTableData({
            columns: taskTableData?.columns,
            rows: tasksResponse.data?.items,
          });
        }
        //
      } catch (error) {
        setError(error);
        console.log("error found", error);
      } finally {
        setIsLoading(false);
        console.log(tasks);
      }
    };
    loadData();

    if (createTask) {
      setOpen(true);
    }
  }, [triggerFetch, propertyId, activeOnly]);

  const filteredTickets = useMemo(() => {
    let result = tasks?.items || [];
    console.log("Result: ", result);
    // Filter by search text
    if (searchText) {
      result = result.filter(
        (transaction) =>
          transaction.summary
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          transaction.description
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
    }

    // Filter by date range
    if (startDate || endDate) {
      result = result.filter((transaction) => {
        const createdDate = new Date(transaction.created);
        return (
          (!startDate || createdDate >= new Date(startDate)) &&
          (!endDate || createdDate <= new Date(endDate))
        );
      });
    }

    return result;
  }, [tasks?.items, searchText, startDate, endDate]);
  const handleViewMore = () => {
    console.log("View More");
  };

  const [controller] = useArgonController();
  const { darkMode } = controller;
  //import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
  const statusIcon = (status) => {
    if (status === "open") {
      return <KeyboardDoubleArrowUpIcon />;
    } else if (status === "inProgress") {
      return "";
    } else if (status === "closed") {
      return "";
    }
  };

  const mapColorToPriority = (priority) => {
    if (priority === "high") {
      return "error";
    } else if (priority === "medium") {
      return "warning";
    } else if (priority === "low") {
      return "success";
    }
  };

  const [open, setOpen] = useState(false);
  const handleCancelClicked = () => {
    setOpen(false);
  };

  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const handleSubmitClicked = async () => {
    const payload = {
      companyId: company?.id,
    };
    try {
      const response = await tasksApi.createTask(payload);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };
  const [taskItem, setTaskItem] = useState({});
  const [property, setProperty] = useState(null);
  return (
    <>
      <ArgonBox py={3}>
        <HostHiveDialog
          open={open}
          fullWidth={true}
          includeSave={false}
          includeClose={false}
          maxWidth="md"
          onClose={() => setOpen(false)}
          onSave={handleSubmitClicked}
          title={t("components.tasks.create.createTask")}
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <TaskItemDialog
              propertyId={propertyId}
              taskItem={taskItem}
              onClose={() => {
                setOpen(false);
              }}
              reloadTimeLine={() => setTriggerFetch(!triggerFetch)}
              openSuccessSB={openSuccessSB}
              // openErrorSB={openErrorSB}
            />
          }
        />
        <Card>
          <Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  p={3}
                >
                  {renderSuccessSB}
                  {/* {renderErrorSB} */}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                      <ArgonBox lineHeight={1}>
                        <ArgonTypography variant="h5" fontWeight="medium">
                          {t("components.tasks.title")}
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          {t("components.tasks.subText")}
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1} direction="row">
                        <ArgonBox display="flex">
                          <ArgonBox>
                            <ArgonButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={() => setOpen(true)}
                            >
                              + {t("components.tasks.newTask")}
                            </ArgonButton>
                          </ArgonBox>
                          <ArgonBox pl={2}>
                            <HowToVideoButton videoId={createTaskVideoId} />
                          </ArgonBox>
                        </ArgonBox>
                      </Stack>
                    </Grid>
                  </Grid>
                </ArgonBox>
                <Divider />
                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("components.tasks.urgent")}
                      count={
                        tasks?.highPriorityTotal ? tasks?.highPriorityTotal : 0
                      }
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-money-coins" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("generalWords.open")}
                      count={tasks?.openTotal ? tasks?.openTotal : 0}
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-world" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("generalWords.inProgress")}
                      count={
                        tasks?.inProgressTotal ? tasks?.inProgressTotal : 0
                      }
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-paper-diploma" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("components.tasks.status.onHold")}
                      count={tasks?.onHoldTotal ? tasks?.onHoldTotal : 0}
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-cart" />,
                      // }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12}>
                    <>
                   
                    {
                      isSmallScreen ? (
                        <ArgonBox>
                          {simpleTrasks?.map((task, key) => (
                            // <UpcomingAssignmentItem
                            //   booking={assignment}
                            //   handleViewClicked={() => handleViewClicked(assignment?.id)}
                            // />
                            <ArgonBox pb={2}>
                              <ComplexTaskCard task={task} />
                            </ArgonBox>
                          ))}
                        </ArgonBox>
                      ):<HostHiveDataTable
                      header={t("components.tasks.title")}
                      table={taskTableData}
                      entriesPerPage={false}
                      canSearch
                      exportToCsv={() => console.log("export")}
                      importFromFile={() => console.log("import")}
                      setProperty={setProperty}
                      property={property}
                      activeOnly={activeOnly}
                      setActiveOnly={setActiveOnly}
                    />
                    }
                      
                    </>
                  </Grid>
                </Grid>

                <Divider />
              </Card>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
    </>
  );
}

export default TasksCenter;
