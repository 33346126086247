import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ContactSupportApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("common", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getSupportTickets = (queryParams) => {
    return axiosPrivate.get(`/support`, { params: queryParams });
  };
  const getSupportTicket = (supportTicketId) => {
    return axiosPrivate.get(`/support/${supportTicketId}`);
  };

  const createSupportTicket = (supportTicketData) => {
    return axiosPrivate.post(`/support`, supportTicketData);
  };

  //updateSupportTicket
  const updateSupportTicket = (ticketId, supportTicketData) => {
    return axiosPrivate.put(`/support/${ticketId}`, supportTicketData);
  };

  ///{support_ticket_id}/mark-resolved
  const resolveSupportTicket = (ticketId) => {
    return axiosPrivate.put(`/support/${ticketId}/mark-resolved`);
  };

  const faqSubmission = (faqData) => {
    return axiosPrivate.post(`/support/faq/need-help`, faqData);
  };

  // const upsertBookingReport = (bookingId, bookingData) => {
  //   return axiosPrivate.put(`/booking-report/${bookingId}`, bookingData);
  // };

  // const getBookingReport = (bookingId) => {
  //   return axiosPrivate.get(`/booking-report/${bookingId}`);
  // };

  // const updateBookingReport = (bookingId, bookingData) => {
  //   return axiosPrivate.put(`/booking-report/${bookingId}`, bookingData);
  // };

  // const updateBookingReportImages = (
  //   bookingId,
  //   companyId,
  //   propertyId,
  //   fileName,
  //   fileType,
  //   summary,
  //   image
  // ) => {
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //   };
  //   return axiosPrivate.put(
  //     `/booking-report/${bookingId}/images?name=${fileName}&type=${fileType}&companyId=${companyId}&propertyId=${propertyId}&summary=${summary}`,
  //     image,
  //     { headers }
  //   );
  // };

  // const deleteBookingReportImage = (
  //   bookingId,
  //   companyId,
  //   propertyId,
  //   fileName
  // ) => {
  //   return axiosPrivate.delete(
  //     `/booking-report/${bookingId}/images?name=${fileName}&companyId=${companyId}&propertyId=${propertyId}`
  //   );
  // };

  // const deleteBookingReport = (bookingId) => {
  //   return axiosPrivate.delete(`/booking-report/${bookingId}`);
  // };

  return {
    getSupportTickets,
    createSupportTicket,
    getSupportTicket,
    updateSupportTicket,
    resolveSupportTicket,
    faqSubmission,
  };
}

export default ContactSupportApi;
