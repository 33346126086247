import * as Yup from "yup";

const usStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const PropertySchema = Yup.object().shape({
  id: Yup.string().nullable(true),
  name: Yup.string().required("Property name is required"),
  occupancyStatus: Yup.string(),
  // propertyAddress: Yup.object()
  //   .shape({
  //     address1: Yup.string().required("Street address is required"),
  //     address2: Yup.string().nullable(true),
  //     city: Yup.string().required("City is required"),
  //     // Transform the input state to uppercase before validation
  //     state: Yup.string()
  //       .transform((value) => value.toUpperCase()) // Transform the input to uppercase
  //       .oneOf(usStates, "Invalid state")
  //       .required("State is required"),
  //     zipCode: Yup.string().required("Zip code is required"),
  //   })
  //   .nullable(true),
  status: Yup.string().oneOf(["active", "inactive", "pending"]).nullable(true),
  bed: Yup.number().required("Bed count is required"),
  bath: Yup.number().required("Bath count is required"),
  sqft: Yup.number().required("Sqft size is required"),
  terminationDate: Yup.string().nullable(true),
});

export default PropertySchema;
