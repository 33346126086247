import * as Yup from "yup";

const LogItemSchema = Yup.object().shape({
  note: Yup.string(),
  propertyId: Yup.string().nullable(true),
  companyId: Yup.string().required("Company is required"),
  entityType: Yup.string().required("Entity Type is required"),
  entityId: Yup.string().required("Entity Id is required"),
  intervalValue: Yup.number().required("Interval Value is required"),
  userId: Yup.string().required("User is required"),
  // summary: Yup.string().required("Summary is required"),
  // amount: Yup.number().required("Amount is required"),
  // description: Yup.string(),
});

export default LogItemSchema;
