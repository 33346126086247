/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import Grid from "@mui/material/Grid";

// Timeline context
import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import {
  timelineItem,
  timelineItemIcon,
} from "examples/Timeline/TimelineItem/styles";

import { useNavigate } from "react-router-dom";

function AssignmentsTimelineItem({
  color,
  icon,
  title,
  dateTime,
  description,
  badges,
  lastItem,
  assignmentId,
}) {
  const isDark = useTimeline();

  const navigate = useNavigate();

  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`;

          return (
            <ArgonBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <ArgonBadge
                color={color}
                size="xs"
                badgeContent={badge}
                container
              />
            </ArgonBox>
          );
        })
      : null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={9}>
        <ArgonBox
          position="relative"
          sx={(theme) => timelineItem(theme, { lastItem })}
        >
          <ArgonBox
            bgColor={isDark ? "dark" : "white"}
            width="1.625rem"
            height="1.625rem"
            borderRadius="50%"
            position="absolute"
            top="3.25%"
            left="2px"
            zIndex={2}
          >
            <Icon sx={(theme) => timelineItemIcon(theme, { color })}>
              {icon}
            </Icon>
          </ArgonBox>

          <ArgonBox
            ml={5.75}
            pt={description ? 0.7 : 0.5}
            lineHeight={0}
            maxWidth="30rem"
          >
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              color={isDark ? "white" : "dark"}
            >
              {title}{" "}
              <Tooltip
                title="Open Appointment in Calendar"
                placement="top"
                arrow
              >
                <IconButton
                  onClick={() => navigate(`/calendar/${assignmentId}`)}
                >
                  <Icon sx={{ fontWeight: "bold" }}>arrow_outward</Icon>
                </IconButton>
              </Tooltip>
            </ArgonTypography>
            <ArgonBox></ArgonBox>
            <ArgonBox mt={0.5}>
              <ArgonTypography
                variant="caption"
                fontWeight="medium"
                color={isDark ? "secondary" : "text"}
              >
                {dateTime}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mt={2} mb={1.5}>
              {description ? (
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  {description}
                </ArgonTypography>
              ) : null}
            </ArgonBox>
            {badges.length > 0 ? (
              <ArgonBox display="flex" pb={lastItem ? 1 : 2}>
                {renderBadges}
              </ArgonBox>
            ) : null}
          </ArgonBox>
        </ArgonBox>
      </Grid>
      <Grid item xs={3} md={3}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          {/* <ArgonButton color="primary" variant="gradient">
            View
          </ArgonButton> */}
        </ArgonBox>
      </Grid>
    </Grid>
  );
}

// Setting default values for the props of TimelineItem
AssignmentsTimelineItem.defaultProps = {
  color: "info",
  badges: [],
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
AssignmentsTimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  badges: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  lastItem: PropTypes.bool,
};

export default AssignmentsTimelineItem;
