import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function BookingReportApi() {
  const axiosPrivate = useAxiosPrivate();
  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();
  // const isAuthorized = isRouteAuthorized(
  //   "serviceReports",
  //   role,
  //   subscriptionTier
  // );

  // if (!isAuthorized) {
  //   return null;
  // }

  const getBookingReports = (queryParams) => {
    return axiosPrivate.get(`/booking-report`, { params: queryParams });
  };

  const upsertBookingReport = (bookingId, bookingData) => {
    return axiosPrivate.put(`/booking-report/${bookingId}`, bookingData);
  };

  const getBookingReport = (bookingId) => {
    return axiosPrivate.get(`/booking-report/${bookingId}`);
  };

  const updateBookingReport = (bookingId, bookingData) => {
    return axiosPrivate.put(`/booking-report/${bookingId}`, bookingData);
  };

  const updateBookingReportImages = (
    bookingId,
    companyId,
    propertyId,
    fileName,
    fileType,
    summary,
    image
  ) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.put(
      `/booking-report/${bookingId}/images?name=${fileName}&type=${fileType}&companyId=${companyId}&propertyId=${propertyId}&summary=${summary}`,
      image,
      { headers }
    );
  };

  const getBookingReportImages = (bookingId, companyId, propertyId) => {
    // booking_report_id: str,
    // companyId: str,
    // propertyId: str,
    return axiosPrivate.get(
      `/booking-report/${bookingId}/images?companyId=${companyId}&propertyId=${propertyId}`
    );
  };

  const deleteBookingReportImage = (
    bookingId,
    companyId,
    propertyId,
    fileName
  ) => {
    return axiosPrivate.delete(
      `/booking-report/${bookingId}/images?name=${fileName}&companyId=${companyId}&propertyId=${propertyId}`
    );
  };

  const deleteBookingReport = (bookingId) => {
    return axiosPrivate.delete(`/booking-report/${bookingId}`);
  };

  return {
    getBookingReports,
    upsertBookingReport,
    getBookingReport,
    updateBookingReport,
    deleteBookingReport,
    updateBookingReportImages,
    deleteBookingReportImage,
    getBookingReportImages,
  };
}

export default BookingReportApi;
