/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import CompanyApi from "api/companyApi";

import BookingsApi from "api/bookingsApi";
import ServiceNameApi from "api/service-nameApi";
import SettingsApi from "api/settingsApi";

import ChecklistsApi from "api/checkListsApi";
import ApplicationApi from "api/applicationApi";
import PropertiesApi from "api/propertiesApi";
import { Check } from "@mui/icons-material";

function NewUser() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [editorValue, setEditorValue] = useState(
    "<p>Hello World!</p><p>Some initial <strong>bold</strong> text</p><br><br>"
  );

  const [user, setUser] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const handleFirstNameChange = (e) => {
    setUser({
      ...user,
      firstName: e.target.value,
    });
  };

  const handleLastNameChange = (e) => {
    setUser({
      ...user,
      lastName: e.target.value,
    });
  };

  const handleEmailAddressChange = (e) => {
    setUser({
      ...user,
      email: e.target.value,
    });
  };

  const handleSubmitClicked = () => {
    console.log(user);
    console.log(adminRole);
    const roleConditions = {
      admin: adminRole,
      readOnly: readOnlyRole,
      owner: ownerRole,
      serviceTech: serviceTechRole,
    };

    const roles = Object.keys(roleConditions).filter(
      (role) => roleConditions[role] === true
    );

    setUser({ ...user, roles: roles });
  };

  // try {
  //   const response = await propertiesApi.createProperty(property);
  //

  //   if (response.status >= 200 && response.status < 300) {
  //     let propertyId = response.data.id;
  //     const settingsResponse = await settingsApi.upsertSetting(
  //       "integration",
  //       propertyId,
  //       settings
  //     );

  //     if (settingsResponse.status >= 200 && settingsResponse.status < 300) {
  //       console.log("Settings created successfully");
  //     } else{
  //       console.log(settingsResponse)
  //     }
  //   }
  //   navigate(`/properties/${response.data.id}`)
  // } catch (error) {
  //   console.error(error);
  const [adminRole, setAdminRole] = useState(false);
  const [readOnlyRole, setReadOnlyRole] = useState(false);
  const [ownerRole, setOwnerRole] = useState(false);
  const [serviceTechRole, setServiceTechRole] = useState(false);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="bold">
                  New User
                </ArgonTypography>

                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Add a new user to your company
                </ArgonTypography>
                <Divider />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Email Address"
                      placeholder="eg. bee@hosthive.io"
                      onChange={(e) => handleEmailAddressChange(e)}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="First Name"
                      placeholder="eg. Bee"
                      onChange={(e) => handleFirstNameChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormField
                      type="text"
                      label="Last Name"
                      placeholder="eg. HostHive"
                      onChange={(e) => handleLastNameChange(e)}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    {/* <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <ArgonBox>
                      <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Roles
                      </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <Checkbox/>
                        </ArgonBox>
                        <ArgonBox>
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="medium"
                      >
                        Admin
                      </ArgonTypography>
                      </ArgonBox>
                      
                      </ArgonBox>
                      
                    </ArgonBox>
                    
                  </ArgonBox> */}
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Roles
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <Checkbox
                            checked={adminRole}
                            onChange={() => setAdminRole(!adminRole)}
                          />
                        </ArgonBox>

                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                          >
                            Admin
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <Checkbox
                            checked={readOnlyRole}
                            onChange={() => setReadOnlyRole(!readOnlyRole)}
                          />
                        </ArgonBox>

                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                          >
                            Read-Only
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <Checkbox
                            checked={serviceTechRole}
                            onChange={() =>
                              setServiceTechRole(!serviceTechRole)
                            }
                          />
                        </ArgonBox>

                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                          >
                            Service Technician
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                      <ArgonBox display="flex">
                        <ArgonBox>
                          <Checkbox
                            checked={ownerRole}
                            onChange={() => setOwnerRole(!ownerRole)}
                          />
                        </ArgonBox>

                        <ArgonBox>
                          <ArgonTypography
                            component="label"
                            variant="caption"
                            fontWeight="medium"
                          >
                            Owner
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                </Grid>

                <ArgonBox display="flex" justifyContent="flex-end" mt={3}>
                  <ArgonBox mr={1}>
                    <ArgonButton color="light">Cancel</ArgonButton>
                  </ArgonBox>
                  <ArgonButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmitClicked}
                  >
                    Create User
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
