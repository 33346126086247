import { Fragment, useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";
import NewTransactionItem from "layouts/administration/accounting/components/NewTransactionItem";
// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
import AccountingApi from "api/accountingApi";
import Tooltip from "@mui/material/Tooltip";
import HostHiveDataTable from "components/HostHiveDataTable";
import { generateColumns } from "layouts/administration/accounting/overview/components/transactionsColumns";
import HostHiveLoading from "components/HostHiveLoading";
import ComplexTransactionCard from "layouts/administration/accounting/overview/components/ComplexTransactionCard";
import useMediaQuery from "@mui/material/useMediaQuery";

function PropertySpendTransactions({
  propertyInfo,
  onClose,
  hideClose = false,
}) {
  const { t } = useTranslation("common");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [newTransactionItemDiaglog, setNewTransactionItemDiaglog] =
    useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const accountingApi = AccountingApi();
  const transactionColumns = generateColumns({});
  const [transactionsTableData, setTransactionsTableData] = useState({
    columns: transactionColumns,
    rows: [
      {
        id: "",
        summary: "",
        description: "",
        created: "",
        amount: "",
        companyId: "",
      },
    ],
  });

  const [loadingTransactionData, setLoadingTransactionData] = useState(false);
  const [fetchTransactions, setFetchTransactions] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [simpleTransactions, setSimpleTransactions] = useState([]);
  useEffect(() => {
    async function fetchTransactions() {
      setLoadingTransactionData(true);
      try {
        let params = {
          companyId: company?.id,
          propertyId: propertyInfo?.propertyId,
        };
        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }
        const response = await accountingApi.getTransactions(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");
          setTransactionsTableData({
            columns: transactionsTableData?.columns,
            rows: response.data?.items,
          });
          setSimpleTransactions(response.data?.items);
          setDateRange(response.data?.dateRange);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingTransactionData(false);
    }
    fetchTransactions();
  }, [fetchTransactions, startDate, endDate]);

  return (
    <Card>
      <ArgonBox pt={3} px={3} display="flex" justifyContent="space-between">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium">
            {propertyInfo?.propertyName} {t("accounting.transactions.title")}
          </ArgonTypography>
        </ArgonBox>
        <HostHiveDialog
          open={newTransactionItemDiaglog}
          // onClose={handleCancelClicked}
          // onSave={handleSubmitClicked}
          includeSave={false}
          includeClose={false}
          title={`${t("generalWords.create")} ${t(
            "accounting.transactions.titleSingle"
          )} `}
          fullScreen={false}
          maxWidth="sm"
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <NewTransactionItem
              propertyInfo={propertyInfo}
              onClose={() => setNewTransactionItemDiaglog(false)}
              setFetchTransactions={() =>
                setFetchTransactions(!fetchTransactions)
              }
            />
          }
        />
      </ArgonBox>
      <ArgonBox py={1}>
        {loadingTransactionData ? (
          <HostHiveLoading
            message={t("accounting.transactions.loadingPropertyTransactions")}
            elipse={true}
          />
        ) : isSmallScreen ? (
          <>
            <ArgonBox>
              {simpleTransactions?.map((transaction, key) => (
                // <UpcomingAssignmentItem
                //   booking={assignment}
                //   handleViewClicked={() => handleViewClicked(assignment?.id)}
                // />
                <ArgonBox pb={2}>
                  <ComplexTransactionCard transaction={transaction} />
                </ArgonBox>
              ))}
            </ArgonBox>
          </>
        ) : (
          <HostHiveDataTable
            newEntryAction={() => setNewTransactionItemDiaglog(true)}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            stringDateRange={dateRange}
            newEntryText={t("accounting.transactions.newTransaction")}
            newEntryColor="info"
            newEntryVariant="outlined"
            table={transactionsTableData}
            entriesPerPage={true}
            showTotalEntries={true}
            isSorted={true}
            noEndBorder
            exportToCsv={() => console.log("export to csv")}
            importFromFile={() => console.log("import from file")}
          />
        )}
      </ArgonBox>
      {hideClose ? null : (
        <ArgonBox justifyContent="center" display="flex" p={2}>
          <ArgonBox display="flex">
            <ArgonBox pr={2}>
              <ArgonButton
                variant="gradient"
                color="dark"
                onClick={() => onClose()}
              >
                {t("buttons.close")}
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      )}
    </Card>
  );
}

export default PropertySpendTransactions;
