/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import React, { useState, useMemo, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import HostHiveDialog from "components/HostHiveDialog";
import AccountingApi from "api/accountingApi";
import PropertiesApi from "api/propertiesApi";
import CategoriesApi from "api/categoriesApi";
import TransactionItem from "layouts/administration/accounting/bulkTransactionManagement/components/transactionItem";
import Header from "layouts/management/components/ImportMatchWizard/components/ReviewImportedTransactions/components/Header";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import { useTranslation } from "react-i18next";
import HostHiveLoading from "components/HostHiveLoading";
function ReviewImportedTransactions({
  formData,
  uploadResponse,
  propertyAppMatch,
  bulkChangePayloadMapping,
  setBulkChangePayloadMapping,
  setNextDisabled,
  submitForm,
}) {
  const { formField, values, errors, touched } = formData;
  const { twitter, facebook, instagram } = formField;
  const {
    twitter: twitterV,
    facebook: facebookV,
    instagram: instagramV,
  } = values;
  const accountingApi = AccountingApi();
  const [transactions, setTransactions] = useState([]);
  const [transactionType, setTransactionType] = useState("all");
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const MemoizedArgonSelect = React.memo(ArgonSelect);
  const [selectedIds, setSelectedIds] = useState([]);
  const [individualUpdateId, setIndividualUpdateId] = useState(null);
  const [applyCategoryOpen, setApplyCategoryOpen] = useState(false);
  const [applyPropertyOpen, setApplyPropertyOpen] = useState(false);

  const [individualDelete, setIndividualDelete] = useState(false);
  const [workingId, setWorkingId] = useState(null);
  const propertiesApi = new PropertiesApi();
  const categoriesApi = new CategoriesApi();
  const [properties, setProperties] = useState([]);
  const [categories, setCategories] = useState([]);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const memoizedProperties = useMemo(() => properties, [properties]);
  const memoizedCategories = useMemo(() => categories, [categories]);
  const [idsToRemove, setIdsToRemove] = useState([]);
  const { t } = useTranslation("common");

  useEffect(() => {
    setNextDisabled(false);
  }, []);

  const findAmount = (searchAmount, transactionId) => {
    const amountStr = searchAmount.toString();
    let aggregatedData = uploadResponse?.aggregatedData;
    // let aggregatedData = uploadResponse;
    // Initialize an array to hold all the potential matches
    const matches = [];

    // Iterate over the aggregated data to find matches
    aggregatedData.forEach((listing) => {
      // Check if the amount is in the listing's amounts
      if (listing.amounts.includes(amountStr)) {
        // If it is, add the listing details to the matches array
        console.log("Match found", listing);
        console.log("App equivalent", propertyAppMatch[listing.listingId]);
        matches.push({
          listing: listing.listing,
          listingId: listing.listingId,
        });
      }
    });

    if (matches.length === 0 || matches.length > 1) {
      console.log("No matches found");
      // Remove transactionId from transactions
      // const updatedTransactions = transactions.filter(
      //   (transaction) => transaction.id !== transactionId
      // );
      idsToRemove.push(transactionId);
    } else {
      console.log("One match found");
      console.log(matches);
      // setMatchedListing(matches[0]);
      let listingId = matches[0].listingId;

      return propertyAppMatch[listingId];
    }
  };

  const fetchTransactionsToApprove = async () => {
    setLoadingTransactions(true);
    setTransactions([]);
    try {
      const queryParams = {
        status: "pendingApproval",
        transactionType,
        limit: 5000,
      };
      const response = await accountingApi.getTransactions(queryParams);
      console.log("response", response);
      if (response.data.items.length > 1) {
        // Loop through the transactions and find the match and do bulkChangePayloadMapping[transaction.id]?.property?.label
        {
          response.data.items.map((transaction) => {
            console.log(transaction.amount, transaction.id);
            const match = findAmount(transaction.amount, transaction.id);
            console.log("match", match);
            if (match) {
              setBulkChangePayloadMapping((prev) => ({
                ...prev,
                [transaction.id]: {
                  property: match,
                },
              }));
            }
          });
        }
      }
      // if (response.data.hasMore > skip + limit) {
      //   setHasMore(true);
      // } else {
      //   setHasMore(false);
      // }

      // Appending new transactions if transactions.id not in idsToRemove
      const newTransactions = response.data.items.filter(
        (transaction) => !idsToRemove.includes(transaction.id)
      );
      setTransactions(newTransactions);

      // setPendingApprovalLength(response.data.totalResults);
    } catch (error) {
      console.error(error);
    }
    setLoadingTransactions(false);
  };
  useEffect(() => {
    fetchTransactionsToApprove();
  }, []);

  useEffect(() => {
    async function fetchCategories() {
      try {
        const queryParams = {
          companyId: company?.id,
          type: "accounting",
        };

        const response = await categoriesApi.getCategories(queryParams);
        // setCategories(response.data);
        console.log(response.data);

        // generate options array, including the "Create New" option
        const options = response.data.map((category) => ({
          value: category.id,
          label: (
            <ArgonBadgeDot
              color={category?.color}
              size="md"
              badgeContent={category?.name}
            />
          ),
        }));

        options.push({
          value: "new",
          label: `+ ${t("components.category.create.createNew")}`,
        });
        options.push({
          value: null,
          label: `Clear Category`,
        });
        setCategories(options);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCategories();
  }, []);

  const [selectAll, setSelectAll] = useState(false);
  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    setSelectedIds(
      event.target.checked ? transactions.map((item) => item.id) : []
    );
  };
  const handleSave = async () => {};
  const handleDeleteSelected = async () => {};
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [saving, setSaving] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);
  const handleBulkChangeCategory = () => {
    // For each selected transaction, update the payload mapping
    // with the new value
    const newBulkChangePayloadMapping = { ...bulkChangePayloadMapping };
    if (individualUpdateId !== null) {
      newBulkChangePayloadMapping[individualUpdateId] = {
        ...newBulkChangePayloadMapping[individualUpdateId],
        category: categorySelected,
      };
      setIndividualUpdateId(null);
    } else {
      selectedIds.forEach((id) => {
        newBulkChangePayloadMapping[id] = {
          ...newBulkChangePayloadMapping[id],
          category: categorySelected,
        };
      });
    }

    setBulkChangePayloadMapping(newBulkChangePayloadMapping);
    setCategorySelected(null);
    // setSelectedIds([]);
    setApplyCategoryOpen(false);
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds((prevSelectedIds) => [...prevSelectedIds, id]);
    } else {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((selectedId) => selectedId !== id)
      );
    }
  };

  useEffect(() => {
    // Disable save button initially if there are no selected transactions
    const initialSaveDisabled = selectedIds.length === 0;
    setSaveDisabled(initialSaveDisabled);

    // Disable save button if any selected transaction does not have both a category and property assigned
    const updateSaveDisabled = selectedIds.some((id) => {
      const mapping = bulkChangePayloadMapping[id];
      return (
        !mapping ||
        mapping.category?.value === undefined ||
        mapping.property?.value === undefined
      );
    });

    // Update the saveDisabled state only if there are selectedIds
    if (selectedIds.length > 0) {
      setSaveDisabled(updateSaveDisabled);
    }
  }, [bulkChangePayloadMapping, selectedIds]);

  return (
    <ArgonBox>
      <HostHiveDialog
        open={applyCategoryOpen}
        onClose={() => setApplyCategoryOpen(false)}
        onSave={() => handleBulkChangeCategory()}
        includeSave={true}
        includeClose={true}
        title="Apply Category"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Apply"
        dialogObjects={
          <>
            <MemoizedArgonSelect
              label="Category"
              value={categorySelected}
              onChange={(e) => setCategorySelected(e)}
              options={memoizedCategories}
              fullWidth
            />
          </>
        }
      />
      <ArgonBox pl={2}>
        <ArgonBox>
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Review the imported transactions and apply any changes before
            submitting.
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            {transactions.length} transactions to review
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>

      <Header
        title="Imported Transactions"
        subtitle=""
        setApplyCategoryOpen={setApplyCategoryOpen}
        setApplyPropertyOpen={setApplyPropertyOpen}
        selectedIds={selectedIds}
        setSelectedIds={setSelectedIds}
        handleSelectAllChange={handleSelectAllChange}
        handleSave={handleSave}
        saveDisabled={saveDisabled}
        saving={saving}
        handleDeleteSelected={handleDeleteSelected}
        submitForm={submitForm}
        // transactionType={transactionType}
        // setTransactionType={setTransactionType}
      />
      <ArgonBox mt={1.625}>
        <Grid container spacing={1}>
          {loadingTransactions ? (
            <Grid item xs={12}>
              <HostHiveLoading
                message="Matching Transactions from imported file"
                elipse={true}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              {transactions?.map((transaction) => (
                <ArgonBox pl={2}>
                  <TransactionItem
                    key={transaction.id}
                    setIndividualUpdateId={setIndividualUpdateId}
                    setApplyCategoryOpen={setApplyCategoryOpen}
                    setApplyPropertyOpen={setApplyPropertyOpen}
                    selectedIds={selectedIds}
                    transaction={transaction}
                    setSelectedIds={setSelectedIds}
                    handleCheckboxChange={handleCheckboxChange}
                    bulkChangePayloadMapping={bulkChangePayloadMapping}
                    setTransactions={setTransactions}
                    memoizedCategories={memoizedCategories}
                    memoizedProperties={memoizedProperties}
                    // handleIndividualAction={handleIndividualAction}
                    individualDelete={individualDelete}
                    setIndividualDelete={setIndividualDelete}
                    workingId={workingId}
                  />
                </ArgonBox>
              ))}
            </Grid>
          )}
        </Grid>
      </ArgonBox>
    </ArgonBox>
  );
}

export default ReviewImportedTransactions;
