/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";

import { useState, useEffect } from "react";
// @mui material components
import InventoryItemDialog from "layouts/administration/inventory/components/InventoryItemDialog";
import {
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  Menu,
  MenuItem,
} from "@mui/material";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveLoading from "components/HostHiveLoading";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InventoryUsageDialog from "../InventoryUsageDialog";
import InventoryReplenishmentDialog from "../InventoryReplenishmentDialog";
import { useTranslation } from "react-i18next";
import ArgonBadgeDot from "components/ArgonBadgeDot";
function ViewInventoryItem({ inventoryItem, onClose, fetchInventoryItems }) {
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const navigate = useNavigate();
  const [savingAssignment, setSavingAssignment] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const [inventoryUsageOpen, setInventoryUsageOpen] = useState(false);
  const [inventoryReplenishmentOpen, setInventoryReplenishmentOpen] =
    useState(false);

  const [editDetailsDialogOpen, setEditDetailsDialogOpen] = useState(false);

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setEditDetailsDialogOpen(true);
          handleCloseMenu();
        }}
      >
        Edit Details
      </MenuItem>
      <MenuItem
        onClick={() => {
          setInventoryReplenishmentOpen(true);
          handleCloseMenu();
        }}
      >
        {t("components.inventory.replenish")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          setInventoryUsageOpen(true);
          handleCloseMenu();
        }}
      >
        {t("components.inventory.usage.addUsage")}
      </MenuItem>
    </Menu>
  );
  const [bookingInfoOpen, setBookingInfoOpen] = useState(false);
  const [bookingInfo, setBookingInfo] = useState(null);
  const handleViewBookingInfo = (bookingInfo) => {
    setBookingInfoOpen(true);
    setBookingInfo(bookingInfo);
  };
  return (
    <>
      <Card>
        {!savingAssignment ? (
          <>
            <HostHiveDialog
              open={bookingInfoOpen}
              fullWidth={true}
              includeSave={false}
              includeClose={false}
              maxWidth="md"
              title="Apointment Details"
              fullScreen={false}
              cancelButtonText="Cancel"
              submitButtonText="Save"
              showStatusBadge={false}
              // statusColor="success"
              // statusContent={bookingInfo?.status}
              dialogObjects={
                <EventDataCard
                  bookingInfo={bookingInfo}
                  // propertyInfo={propertyInfo}
                  isCreation={false}
                  onClose={() => {
                    setBookingInfoOpen(false);
                  }}
                  readOnly={true}
                />
              }
            />
            <HostHiveDialog
              open={editDetailsDialogOpen}
              onClose={() => {
                setEditDetailsDialogOpen(false);
              }}
              title="Edit Details"
              fullScreen={false}
              cancelButtonText="Close"
              submitButtonText="Save"
              includeSave={false}
              includeClose={false}
              includeDelete={false}
              maxWidth="xs"
              dialogObjects={
                <InventoryItemDialog
                  inventoryItem={inventoryItem}
                  handleClose={() => setEditDetailsDialogOpen(false)}
                  fetchInventoryItems={fetchInventoryItems}
                  edit={true}
                />
              }
            />
            <HostHiveDialog
              open={inventoryUsageOpen}
              onClose={() => {
                setInventoryUsageOpen(false);
              }}
              title={t("components.inventory.usage.recordUsage")}
              fullScreen={false}
              cancelButtonText="Close"
              submitButtonText="Save"
              includeSave={false}
              includeClose={false}
              includeDelete={false}
              maxWidth="sm"
              dialogObjects={
                <InventoryUsageDialog
                  inventoryItem={inventoryItem}
                  handleClose={() => setInventoryUsageOpen(false)}
                  fetchInventoryItems={fetchInventoryItems}
                />
              }
            />
            <HostHiveDialog
              open={inventoryReplenishmentOpen}
              onClose={() => {
                setInventoryReplenishmentOpen(false);
              }}
              title={`${t("components.inventory.replenish")} ${t(
                "components.inventory.iventoryItem"
              )}`}
              fullScreen={false}
              cancelButtonText={t("buttons.cancel")}
              submitButtonText={t("buttons.save")}
              includeSave={false}
              includeClose={false}
              includeDelete={false}
              maxWidth="xs"
              dialogObjects={
                <InventoryReplenishmentDialog
                  inventoryItem={inventoryItem}
                  handleClose={() => setInventoryReplenishmentOpen(false)}
                  fetchInventoryItems={fetchInventoryItems}
                />
              }
            />
            <ArgonBox pl={2} pb={3}>
              {/* <Grid container spacing={3} pb={3}>
                <Grid item xs={12} sm={6}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => setInventoryReplenishmentOpen(true)}
                    >
                      + Replenish
                    </ArgonButton>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={() => setInventoryUsageOpen(true)}
                    >
                      + Usage
                    </ArgonButton>
                  </ArgonBox>
                </Grid>
              </Grid> */}
              <Grid container spacing={1}>
                {/* <>
                  <Grid item xs={12} sm={6}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      pt={2}
                    >
                      <ArgonButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={() => setInventoryReplenishmentOpen(true)}
                      >
                        + {t("components.inventory.replenish")}
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      pt={2}
                    >
                      <ArgonButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() => setInventoryUsageOpen(true)}
                      >
                        + {t("components.inventory.usage.addUsage")}
                      </ArgonButton>
                    </ArgonBox>
                  </Grid>
                </> */}
                {/* <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Item Id:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {inventoryItem?.id}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid> */}
                {renderMenu()}
                <Grid
                  item
                  lg={12}
                  sm={12}
                  sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}
                  pr={2}
                >
                  <ArgonButton
                    // disabled={taskItem?.status === "closed"}
                    variant="outlined"
                    color="secondary"
                    onClick={handleOpenMenu}
                    sx={{
                      ml: "auto",
                      alignSelf: "flex-start",
                      py: 1.25,
                      pr: 1.5,
                      borderRadius: "8px",
                      borderWidth: "1px",
                      transition: "background-color 0.3s, transform 0.2s",
                      ":hover": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        transform: "scale(1.02)",
                      },
                    }}
                    startIcon={
                      <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                        settings
                      </Icon>
                    }
                  >
                    Options
                  </ArgonButton>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("components.inventory.dialog.itemName")}:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {inventoryItem?.itemName}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("components.inventory.quantity")}:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {inventoryItem?.count}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Category:
                    </ArgonTypography>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {inventoryItem?.categoryInfo?.categoryName}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                {/* <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Current Batch Number:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      {inventoryItem?.currentBatchNumber}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid> */}
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("components.inventory.usage.inventoryUsages")}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        {t("buttons.view")}
                      </ArgonTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {inventoryItem?.usages ? (
                          Object.keys(inventoryItem?.usages || {}).map(
                            (key, index) => (
                              <ArgonBox key={index} pb={2}>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <ArgonTypography
                                      variant="caption"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="uppercase"
                                    >
                                      {convertTimeStamp(
                                        inventoryItem?.usages[key]?.usageDate
                                      )}
                                    </ArgonTypography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.usage.usageAmount"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.usages[key]
                                                ?.usageAmount
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.batchNumber"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.usages[key]
                                                ?.batchNumber
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.usage.usagePrice"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" $"}
                                            {
                                              inventoryItem?.usages[key]
                                                ?.usagePrice
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            Category:
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.usages[key]
                                                ?.categoryInfo?.name
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        {inventoryItem?.usages[key]
                                          ?.bookingInfo ? (
                                          <ArgonBox
                                            ml={0.5}
                                            pl={0.5}
                                            lineHeight={0}
                                            display="inline-block"
                                          >
                                            <ArgonTypography
                                              component="label"
                                              variant="caption"
                                              fontWeight="bold"
                                              textTransform="capitalize"
                                            >
                                              Appointment:
                                            </ArgonTypography>

                                            <ArgonTypography
                                              variant="caption"
                                              textTransform="capitalize"
                                              sx={{
                                                ":hover": {
                                                  textDecoration: "underline",
                                                  color: "primary.main",
                                                },
                                                cursor: "pointer",
                                                fontStyle: "italic",
                                              }}
                                              onClick={() =>
                                                handleViewBookingInfo(
                                                  inventoryItem?.usages[key]
                                                    ?.bookingInfo
                                                )
                                              }
                                            >
                                              View Appointment
                                            </ArgonTypography>
                                          </ArgonBox>
                                        ) : null}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t("generalWords.comment")}:
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.usages[key]
                                                ?.comment
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </ArgonBox>
                            )
                          )
                        ) : (
                          <p>
                            {t("components.inventory.notAvailable.noUsages")}
                          </p>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {t("components.inventory.replenishment.title")}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        {t("buttons.view")}
                      </ArgonTypography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <>
                        {inventoryItem?.replenishments ? (
                          Object.keys(inventoryItem?.replenishments || {}).map(
                            (key, index) => (
                              <ArgonBox key={index} pb={2}>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <ArgonTypography
                                      variant="caption"
                                      fontWeight="bold"
                                      color="text"
                                      textTransform="uppercase"
                                    >
                                      {convertTimeStamp(
                                        inventoryItem?.replenishments[key]
                                          ?.created
                                      )}
                                    </ArgonTypography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Grid container spacing={1}>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.replenishment.initialQuantity"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.initialCount
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.replenishment.currentQuantity"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.count
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t("components.inventory.price")}:
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" $"}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.price
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.pricePerUsage"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" $"}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.pricePerUsage
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t(
                                              "components.inventory.batchNumber"
                                            )}
                                            :
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.batchNumber
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <ArgonBox
                                          ml={0.5}
                                          lineHeight={0}
                                          display="inline-block"
                                        >
                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                          >
                                            {t("generalWords.comment")}:
                                          </ArgonTypography>

                                          <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            textTransform="capitalize"
                                          >
                                            {" "}
                                            {
                                              inventoryItem?.replenishments[key]
                                                ?.comment
                                            }
                                          </ArgonTypography>
                                        </ArgonBox>
                                      </Grid>
                                    </Grid>
                                  </AccordionDetails>
                                </Accordion>
                              </ArgonBox>
                            )
                          )
                        ) : (
                          <p>
                            {t(
                              "components.inventory.notAvailable.noReplenishments"
                            )}
                          </p>
                        )}
                      </>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Comments
                    </ArgonTypography>
                  </ArgonBox>
                  <HostHiveCommentSection
                    title=""
                    entityType="inventoryItem"
                    entityId={inventoryItem?.id}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox pl={2} pb={3}></ArgonBox>

            <Grid container spacing={3} pb={3}>
              <Grid item xs={6}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={() => navigate(-1)}
                  >
                    Return
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <HostHiveLoading message={loadingText} elipse={true} />
        )}
      </Card>
    </>
  );
}

export default ViewInventoryItem;
