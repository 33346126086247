import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Switch from "@mui/material/Switch";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import SettingsApi from "api/settingsApi";
import HostHiveLoading from "components/HostHiveLoading";
import Slider from "@mui/material/Slider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
function DynamicSettings({
  settingsType = null,
  entityId = null,
  handleClose = null,
  snackBarOpen = null,
  userCompanyId = null,
}) {
  const settingsApi = new SettingsApi();
  const [settings, setSettings] = useState([]);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { t } = useTranslation("common");
  useEffect(() => {
    async function fetchSettings() {
      setSettingsLoading(true);
      try {
        const queryParams = {
          forUi: true,
        };
        const response = await settingsApi.getSetting(
          settingsType,
          entityId,
          queryParams
        );
        if (response.status >= 200 && response.status < 300) {
          setSettings(response.data);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status === 404) {
          setSettings([]);
        }
      }

      setSettingsLoading(false);
    }
    if (settingsType && entityId) {
      fetchSettings();
    }
  }, [settingsType, entityId]);

  const handleSettingChange = (section, index, newValue) => {
    const newSettings = { ...settings };
    newSettings[section].settings[index].value = newValue;
    setSettings(newSettings);
  };

  const handleSave = async () => {
    // Initialize the settingsObject that will hold the final key-value pairs
    const settingsObject = {
      companyId: userCompanyId,
    };

    // Loop through each section
    Object.keys(settings).forEach((section) => {
      // If the section has settings, then loop through them
      if (settings[section].settings) {
        settings[section].settings.forEach(({ id, value }) => {
          // If the value is an object that contains a "value" property, use it
          if (value && typeof value === "object" && "value" in value) {
            settingsObject[id] = value.value;
          } else {
            // Otherwise, just use the value directly
            settingsObject[id] = value;
          }
        });
      }
    });

    console.log("save as object", settingsObject);

    try {
      setSaving(true);
      const response = await settingsApi.upsertSetting(
        settingsType,
        entityId,
        settingsObject
      );
      if (response.status >= 200 && response.status < 300) {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }

    if (snackBarOpen) {
      snackBarOpen(
        t("company.settings.snackbar.title"),
        t("company.settings.snackbar.successfullyUpdated")
      );
    }
    setSaving(false);
    if (handleClose) {
      handleClose();
    }
  };

  const displaySettings = (section, settings) => {
    const renderSettings = settings?.map((setting, index) => (
      <ArgonBox key={index} pl={2} display="flex">
        <ArgonBox>
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            {setting.title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox pl={2} pb={2}>
          {setting.type === "boolean" ? (
            <Switch
              checked={setting.value}
              //onChange={() => handleSettingChange(index, !setting.value)}
              onChange={() =>
                handleSettingChange(section, index, !setting.value)
              }
            />
          ) : null}
          {setting.type === "select" ? (
            <>
              <ArgonSelect
                value={setting.value}
                options={setting.options}
                onChange={(newValue) =>
                  handleSettingChange(section, index, newValue)
                }
              />
            </>
          ) : null}
          {setting.type === "input" ? (
            <ArgonInput
              value={setting.value}
              onChange={(newValue) =>
                handleSettingChange(section, index, newValue.target.value)
              }
            />
          ) : null}
          {setting.type === "input_integer" ? (
            <ArgonInput
              type="number"
              value={setting.value}
              onChange={(newValue) =>
                handleSettingChange(section, index, newValue.target.value)
              }
            />
          ) : null}
          {setting.type === "slider" ? (
            <>
              <ArgonBox display="flex">
                <ArgonBox sx={{ width: 300 }} pr={2}>
                  <Slider
                    value={setting.value}
                    onChange={(newValue) =>
                      handleSettingChange(section, index, newValue.target.value)
                    }
                  />
                </ArgonBox>
                <ArgonTypography variant="caption">
                  {setting.value}
                </ArgonTypography>
              </ArgonBox>
            </>
          ) : null}
        </ArgonBox>
      </ArgonBox>
    ));

    return renderSettings;
  };

  const handleCancelClicked = () => {
    if (handleClose) {
      handleClose();
    }
  };
  return (
    <Card sx={{ overflow: "visible" }}>
      <ArgonBox p={2} lineHeight={1}>
        {settingsLoading ? (
          <HostHiveLoading message={t("company.settings.loading.title")} />
        ) : (
          <>
            {/* {settings?.userPreferences?.Header && (
              <>{settings.userPreferences.Header}</>
            )}
            {settings?.userPreferences?.settings && (
              <>{displaySettings(settings.userPreferences.settings)}</>
            )} */}
            {Object.keys(settings).map((key, index) => (
              <ArgonBox key={index} pb={2}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ArgonTypography
                      variant="caption"
                      fontWeight="bold"
                      color="text"
                      textTransform="uppercase"
                    >
                      {settings[key]?.Header || key}
                    </ArgonTypography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {displaySettings(key, settings[key]?.settings)}
                  </AccordionDetails>
                </Accordion>
                {/* Your header or title for each settings section */}

                {/* Your function to display the individual settings. 
             Replace `YourDisplayFunction` with the actual function you'll use. */}
              </ArgonBox>
            ))}
          </>
        )}
      </ArgonBox>
      <ArgonBox justifyContent="center" display="flex" p={2}>
        <ArgonBox display="flex">
          {handleClose ? (
            <ArgonBox pr={2}>
              <ArgonButton
                variant="gradient"
                color="dark"
                onClick={handleCancelClicked}
              >
                {t("buttons.cancel")}
              </ArgonButton>
            </ArgonBox>
          ) : (
            <></>
          )}
          <ArgonBox pr={2}>
            <ArgonButton
              variant="gradient"
              color="info"
              onClick={handleSave}
              disabled={saving}
            >
              {t("buttons.save")}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default DynamicSettings;
