import PricingNotice from "layouts/management/properties/properties-list/components/PricingNotice";

import HostHiveDialog from "components/HostHiveDialog";

import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";
import AddressInput from "./components";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import Divider from "@mui/material/Divider";
import PropertiesApi from "api/propertiesApi";
import ArgonAlert from "components/ArgonAlert";
import HostHiveLoading from "components/HostHiveLoading";
// import CompanySelect from "../CategorySelect/CompanySelect";
import PropertySchema from "schemas/propertySchema";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import { actions } from "react-table/dist/react-table.development";
import HostHiveAlert from "components/HostHiveAlert";
import { useTranslation } from "react-i18next";
function SettingsDataCard({
  title,
  handleClose,
  isCreation = false,
  company,
  property,
  setProperty,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [address, setAddress] = useState({});
  const [formattedAddress, setFormattedAddress] = useState("");
  // APIs
  const propertyApi = PropertiesApi();
  // console.log("Property", property);
  const { id } = useParams();
  // Slice:
  // const {
  //   property,
  //   setProperty,
  //   propertySettings,
  //   setPropertySettings,
  //   applicationIntegrationTypes,
  //   propertyServicePartners,
  //   companyChecklists,
  //   companyServiceNames,
  //   setCompanyServiceNames,
  //   occupancyStatusTypes,
  // } = useContext(PropertyContext);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("warning");
  const [alertMessage, setAlertMessage] = useState(
    "Some long alert message about something that went wrong."
  );
  const [subscriptionError, setSubscriptionError] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  useEffect(() => {
    if (isCreation) {
      setProperty({});
    } else {
      let address1 = property?.propertyAddress?.address1 || "";
      let city = property?.propertyAddress?.city || "";
      let state = property?.propertyAddress?.state || "";
      let zipCode = property?.propertyAddress?.zipCode || "";
      let country = property?.propertyAddress?.country || "";

      let existingFormatedAddress = `${address1}, ${city}, ${state} ${zipCode}, ${country}`;

      setAddress({
        address1,
        city,
        state,
        zipCode,
        country,
      });

      setFormattedAddress(existingFormatedAddress);
    }
  }, []);
  const DisplayEntry = ({ entryKey }) => {
    return (
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}
        </ArgonTypography>
      </ArgonBox>
    );
  };

  const onSubmit = async (values, actions) => {
    setSubscriptionError(false);
    setShowAlert(false);

    values.companyId = company?.id;

    values.propertyAddress = address;
    values.formattedAddress = formattedAddress;

    setIsSaving(true);
    try {
      let response;
      if (isCreation) {
        response = await propertyApi.createProperty(values);
      } else {
        values.companyId = property?.companyId;
        values.id = property?.id;

        let propertyId = property?.id || id;
        response = await propertyApi.updateProperty(propertyId, values);
        actions.resetForm();
      }
      if (response.status >= 200 && response.status < 300) {
        if (isCreation) {
          navigate(`/properties/${response.data.id}`);
        } else {
          setProperty(response.data);
        }
      }
      setIsSaving(false);
      console.log(values);
      handleClose();
    } catch (error) {
      console.error("Error saving property", error);
      setAlertColor("error");
      if (error?.response?.status === 400) {
        setAlertMessage(error?.response?.data?.detail);
        setSubscriptionError(true);
      } else {
        setAlertMessage("Error saving property");
      }

      setShowAlert(true);

      setIsSaving(false);
    }
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: property?.companyId || company?.id || "",
      name: property?.name || "",
      bed: property?.bed || "",
      bath: property?.bath || "",
      sqft: property?.sqft || "",
      occupancyStatus: property?.occupancyStatus || "ready",
      // propertyAddress: {
      //   address1: property?.propertyAddress?.address1 || "",
      //   address2: property?.propertyAddress?.address2 || "",
      //   city: property?.propertyAddress?.city || "",
      //   state: property?.propertyAddress?.state || "",
      //   zipCode: property?.propertyAddress?.zipCode || "",
      //   country: property?.propertyAddress?.country || "",
      // },
      // Add more fields here
    },
    validationSchema: PropertySchema,
    onSubmit,
  });

  const handleOpenSubscriptionSettings = () => {
    navigate(`/company/settings/${company?.id}`);
  };

  const [priceIncreaseNoticeOpen, setPriceIncreaseNoticeOpen] = useState(false);

  return (
    <>
      {isSaving ? (
        <HostHiveLoading
          message={`${t("generalWords.saving")} ${t(
            "properties.propertySettings"
          )}`}
        />
      ) : (
        <form onSubmit={handleSubmit}>
          <>
            <HostHiveDialog
              open={priceIncreaseNoticeOpen}
              includeClose={true}
              includeSave={false}
              onClose={() => setPriceIncreaseNoticeOpen(false)}
              // onSave={handleSubmitClicked}
              title={t("properties.priceIncreaseNotice.dataCardHeader")}
              fullScreen={false}
              cancelButtonText="Close"
              submitButtonText="Save"
              dialogObjects={
                <Card
                  sx={{
                    width: "150%",
                    overflow: "visible",
                    width: "100%",
                    height: "100%",
                    minWidth: "600px",
                    "@media (max-width: 600px)": {
                      minWidth: "auto",
                    },
                  }}
                >
                  {/* <ArgonBox p={2}>
                <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                  Terms and conditions
                </ArgonTypography>
              </ArgonBox> */}

                  <ArgonBox p={2}>
                    <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                      <PricingNotice />
                    </ArgonTypography>
                  </ArgonBox>
                </Card>
              }
            />
            {showAlert ? (
              <ArgonAlert color={alertColor} dismissible>
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {alertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                  {subscriptionError && (
                    <ArgonBox pl={3}>
                      <ArgonButton onClick={handleOpenSubscriptionSettings}>
                        {t("buttons.open")}{" "}
                        {t("company.settings.subscriptionSettings.title")}
                      </ArgonButton>
                    </ArgonBox>
                  )}
                </ArgonBox>
              </ArgonAlert>
            ) : null}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("properties.propertyName")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </ArgonBox>
                  {errors.name && touched.name && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.name} />
                  )}
                </ArgonBox>
              </Grid>
              <Grid item xs={6} md={4}>
                <ArgonBox>
                  <DisplayEntry
                    entryKey={t("properties.propertiesDataCard.bed")}
                  />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="bed"
                      value={values.bed}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.bed && Boolean(errors.bed)}
                      helperText={touched.bed && errors.bed}
                    />
                  </ArgonBox>
                  {errors.bed && touched.bed && (
                    <HostHiveErrorMessage message={errors.bed} />
                  )}
                </ArgonBox>
              </Grid>
              <Grid item xs={6} md={4}>
                <ArgonBox>
                  <DisplayEntry
                    entryKey={t("properties.propertiesDataCard.bath")}
                  />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="bath"
                      value={values.bath}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.bath && Boolean(errors.bath)}
                      helperText={touched.bath && errors.bath}
                    />
                  </ArgonBox>
                  {errors.bath && touched.bath && (
                    <HostHiveErrorMessage message={errors.bath} />
                  )}
                </ArgonBox>
              </Grid>
              <Grid item xs={12} md={4}>
                <ArgonBox pb={1}>
                  <DisplayEntry
                    entryKey={t("properties.propertiesDataCard.sqft")}
                  />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="sqft"
                      value={values.sqft}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.sqft && Boolean(errors.sqft)}
                      helperText={touched.sqft && errors.sqft}
                    />
                  </ArgonBox>
                  {errors.sqft && touched.sqft && (
                    <HostHiveErrorMessage message={errors.sqft} />
                  )}
                </ArgonBox>
              </Grid>
            </Grid>
            <Divider />

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AddressInput
                  address={address}
                  setAddress={setAddress}
                  formattedAddress={formattedAddress}
                  setFormattedAddress={setFormattedAddress}
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={6}>
                <ArgonBox pb={1}>
                  <DisplayEntry entryKey={t("general.address.address1")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="propertyAddress.address1"
                      value={values.propertyAddress?.address1 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.propertyAddress?.address1 &&
                        Boolean(errors.propertyAddress?.address1)
                      }
                      helperText={
                        touched.propertyAddress?.address1 &&
                        errors.propertyAddress?.address1
                      }
                    />
                  </ArgonBox>
                  {errors.propertyAddress?.address1 &&
                    touched.propertyAddress?.address1 && (
                      <HostHiveErrorMessage
                        message={errors.propertyAddress?.address1}
                      />
                    )}
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ArgonBox pb={1}>
                  <DisplayEntry entryKey={t("general.address.address2")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="propertyAddress.address2"
                      value={values.propertyAddress?.address2 || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.propertyAddress?.address2 &&
                        Boolean(errors.propertyAddress?.address2)
                      }
                      helperText={
                        touched.propertyAddress?.address2 &&
                        errors.propertyAddress?.address2
                      }
                    />
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <ArgonBox pb={1}>
                  <DisplayEntry entryKey={t("general.address.city")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="propertyAddress.city"
                      value={values.propertyAddress?.city || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.propertyAddress?.city &&
                        Boolean(errors.propertyAddress?.city)
                      }
                      helperText={
                        touched.propertyAddress?.city &&
                        errors.propertyAddress?.city
                      }
                    />
                  </ArgonBox>
                  {errors.propertyAddress?.city &&
                    touched.propertyAddress?.city && (
                      <HostHiveErrorMessage
                        message={errors.propertyAddress?.city}
                      />
                    )}
                </ArgonBox>
              </Grid>
              <Grid item xs={6} sm={6} md={4}>
                <ArgonBox pb={1}>
                  <DisplayEntry entryKey={t("general.address.state")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="propertyAddress.state"
                      value={values.propertyAddress?.state || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.propertyAddress?.state &&
                        Boolean(errors.propertyAddress?.state)
                      }
                      helperText={
                        touched.propertyAddress?.state &&
                        errors.propertyAddress?.state
                      }
                    />
                  </ArgonBox>
                  {errors.propertyAddress?.state &&
                    touched.propertyAddress?.state && (
                      <HostHiveErrorMessage
                        message={errors.propertyAddress?.state}
                      />
                    )}
                </ArgonBox>
              </Grid>
              <Grid item xs={6} md={4}>
                <ArgonBox pb={1}>
                  <DisplayEntry entryKey={t("general.address.zipCode")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      name="propertyAddress.zipCode"
                      value={values.propertyAddress?.zipCode || ""}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={
                        touched.propertyAddress?.zipCode &&
                        Boolean(errors.propertyAddress?.zipCode)
                      }
                      helperText={
                        touched.propertyAddress?.zipCode &&
                        errors.propertyAddress?.zipCode
                      }
                    />
                  </ArgonBox>
                  {errors.propertyAddress?.zipCode &&
                    touched.propertyAddress?.zipCode && (
                      <HostHiveErrorMessage
                        message={errors.propertyAddress?.zipCode}
                      />
                    )}
                </ArgonBox>
              </Grid> */}
            </Grid>

            <ArgonBox justifyContent="center" display="flex" p={2}>
              <ArgonBox display="flex">
                <ArgonBox pr={2}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={handleClose}
                  >
                    {t("buttons.cancel")}
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox pr={2}>
                  <ArgonButton variant="gradient" color="info" type="submit">
                    {t("buttons.save")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
            <ArgonBox pl={2}>
              <ArgonTypography component="label" variant="caption" color="text">
                {isCreation
                  ? "* New properties outside of trial will incure an additional fee."
                  : null}
              </ArgonTypography>
              <ArgonTypography
                component="label"
                variant="caption"
                color="text"
                textTransform="capitalize"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() => setPriceIncreaseNoticeOpen(true)}
              >
                {" "}
                View Pricing Notice
              </ArgonTypography>
            </ArgonBox>
          </>
        </form>
      )}
    </>
  );
}

SettingsDataCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default SettingsDataCard;
