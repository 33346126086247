/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import TasksApi from "api/propertyTasksApi";
import ArgonAlert from "components/ArgonAlert";

import { useNavigate } from "react-router-dom";

import TaskItem from "layouts/administration/taskCenter/components/TaskItem";
function TaskItemPage({}) {
  const { taskItemId } = useParams();
  const [taskItem, setTaskItem] = useState(null);
  const tasksApi = new TasksApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("success");
  const [alertMessage, setAlertMessage] = useState("");
  const [triggerFetch, setTriggerFetch] = useState(false);
  useEffect(() => {
    async function fetchTaskItem() {
      setIsLoading(true);
      console.log("task item", taskItemId);

      try {
        const response = await tasksApi.getTask(taskItemId);
        console.log("response", response);
        setTaskItem(response.data);
      } catch (error) {
        console.log("error", error);
        setAlertColor("error");
        setAlertMessage(`Error fetching task item: ${error.message}`);
        setShowAlert(true);
      }
      setIsLoading(false);
    }
    fetchTaskItem();
  }, [triggerFetch]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={1} mb={20}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ overflow: "visible" }}>
              {showAlert ? (
                <ArgonAlert color={alertColor} dismissible>
                  <ArgonBox display="flex" justifyContent="flex-end">
                    <ArgonBox>
                      <ArgonTypography variant="caption" color="white">
                        {alertMessage}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonAlert>
              ) : null}
              <ArgonBox p={2}>
                <ArgonBox>
                  {taskItem ? (
                    <>
                      <TaskItem
                        taskItem={taskItem}
                        fetchTaskItems={() => setTriggerFetch(!triggerFetch)}
                      />
                    </>
                  ) : (
                    <>
                      <ArgonBox display="flex" justifyContent="center">
                        <ArgonTypography variant="h5" fontWeight="regular">
                          Loading Task Details...
                        </ArgonTypography>
                        <HostHiveLoading />
                      </ArgonBox>
                    </>
                  )}
                </ArgonBox>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TaskItemPage;
