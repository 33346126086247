/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// sweetalert2 components
import Swal from "sweetalert2";

export const showDeleteConfirmation = (
  title = "Are you sure?",
  text = "You won't be able to revert this!",
  confirmationButtonText = "Yes, delete it!",
  icon = "warning"
) => {
  return new Promise((resolve, reject) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
        confirmButton: "button button-success",
      },
      buttonsStyling: false,
      reverseButtons: true,
    });

    newSwal
      .fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonText: confirmationButtonText,
      })
      .then((result) => {
        if (result.isConfirmed) {
          resolve(true); // Resolves the promise if confirmed
        } else {
          reject(false); // Rejects the promise if not confirmed or cancelled
        }
      });
  });
};
