import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function AccountingApi() {
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  const axiosPrivate = useAxiosPrivate();
  const isAuthorized = isRouteAuthorized("finance", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }
  const getTransactions = (queryParams) => {
    return axiosPrivate.get(`/accounting`, { params: queryParams });
  };

  const getTransaction = (id) => {
    return axiosPrivate.get(`/accounting/${id}`);
  };

  const getPropertiesNet = (queryParams) => {
    return axiosPrivate.get(`/accounting/summary/net-all-properties`, {
      params: queryParams,
    });
  };

  const getIncomeVsSpend = (queryParams) => {
    return axiosPrivate.get(`/accounting/summary/income-vs-spend`, {
      params: queryParams,
    });
  };

  const getFinanceReport = (queryParams) => {
    return axiosPrivate.get(`/accounting/report/all`, {
      params: queryParams,
    });
  };

  const sendFinanceReport = (queryParams) => {
    return axiosPrivate.post(`/accounting/report/send-via-email`, null, {
      params: queryParams,
    });
  };

  const getCategorySpend = (queryParams) => {
    return axiosPrivate.get(`/accounting/summary/category-spend`, {
      params: queryParams,
    });
  };

  const getCategorySummary = (queryParams) => {
    return axiosPrivate.get(`/accounting/summary/category-summary`, {
      params: queryParams,
    });
  };

  //{transaction_id}/category-update/{category_id}

  const updateTransactionCategoryId = (id, categoryId) => {
    return axiosPrivate.put(`/accounting/${id}/category-update/${categoryId}`);
  };

  const updateTransactionPropertyId = (id, propertyId) => {
    return axiosPrivate.put(`/accounting/${id}/property-update/${propertyId}`);
  };

  const updateTransactions = (id, data) => {
    return axiosPrivate.put(`/accounting/${id}`, data);
  };
  const createTransaction = (data) => {
    return axiosPrivate.post(`/accounting`, data);
  };

  const deleteTransaction = (id) => {
    return axiosPrivate.delete(`/accounting/${id}`);
  };

  const updateImages = (
    id,
    companyId,
    propertyId,
    fileName,
    fileType,
    summary,
    image
  ) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.put(
      `/accounting/${id}/images?name=${fileName}&type=${fileType}&companyId=${companyId}&propertyId=${propertyId}&summary=${summary}`,
      image,
      { headers }
    );
  };

  const deleteImage = (id, companyId, propertyId, fileName) => {
    return axiosPrivate.delete(
      `/accounting/${id}/images?name=${fileName}&companyId=${companyId}&propertyId=${propertyId}`
    );
  };

  const updateTransactionApprovalState = (id, approval, payload) => {
    return axiosPrivate.put(`/accounting/${id}/approved/${approval}`, payload);
  };

  const updateTransactionApprovalStateBulk = (payload) => {
    return axiosPrivate.put(`/accounting/approved/bulk`, payload);
  };

  const splitTransaction = (id, payload) => {
    return axiosPrivate.put(`/accounting/${id}/split`, payload);
  };

  return {
    getTransactions,
    updateTransactions,
    deleteTransaction,
    createTransaction,
    getPropertiesNet,
    getCategorySpend,
    getIncomeVsSpend,
    deleteImage,
    updateImages,
    getTransaction,
    updateTransactionCategoryId,
    updateTransactionApprovalState,
    updateTransactionApprovalStateBulk,
    splitTransaction,
    getCategorySummary,
    getFinanceReport,
    sendFinanceReport,
    updateTransactionPropertyId,
  };
}

export default AccountingApi;
