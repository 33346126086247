/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// ReadOnlyPropertyDetails page components
import Header from "layouts/management/properties/properties-list/components/read-only-property-details/components/Header";
import PropertyInfo from "layouts/management/properties/properties-list/components/read-only-property-details/components/PropertyInfo";
// import TrackOrder from "layouts/management/properties/properties-list/components/read-only-property-details/components/TrackOrder";
import ScheduledAssignments from "layouts/management/properties/properties-list/components/read-only-property-details/components/ScheduledAssignments";
import ServiceReports from "layouts/management/properties/properties-list/components/read-only-property-details/components/ServiceReports";

import PaymentDetails from "layouts/management/properties/properties-list/components/read-only-property-details/components/PaymentDetails";
import BillingInformation from "layouts/management/properties/properties-list/components/read-only-property-details/components/BillingInformation";
import OrderSummary from "layouts/management/properties/properties-list/components/read-only-property-details/components/OrderSummary";
import ContactsApi from "api/companyAddressBookApi";

function ReadOnlyPropertyDetails({
  property,
  isOwner = false,
  propertyId,
  propertyName,
  propertyOccupancyDetails,
}) {
  const contactsApi = new ContactsApi();

  const [contact, setContact] = useState({});
  useEffect(() => {
    async function fetchContact() {
      try {
        const foundContact = await contactsApi.getPropertyContact(propertyId);
        console.log("foundContact: ", foundContact?.data);
        setContact(foundContact?.data);
      } catch (error) {
        console.log(error);
      }
    }
    if (propertyId) {
      fetchContact();
    }
  }, [propertyId]);
  return (
    <Card>
      <ArgonBox pt={2} px={2}>
        <Header
          property={property}
          propertyId={propertyId}
          isOwner={isOwner}
          contact={contact}
        />
      </ArgonBox>
      <Divider />
      <ArgonBox pt={1} pb={3} px={2}>
        <ArgonBox mb={3}>
          <PropertyInfo
            property={property}
            isOwner={isOwner}
            propertyId={propertyId}
            propertyName={propertyName}
            propertyOccupancyDetails={propertyOccupancyDetails}
            contact={contact}
          />
        </ArgonBox>
        <Divider />
        <ArgonBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <ScheduledAssignments
                property={property}
                propertyId={propertyId}
                propertyName={propertyName}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ServiceReports
                property={property}
                propertyId={propertyId}
                propertyName={propertyName}
              />
            </Grid>

            {/* <Grid item xs={12} lg={4} sx={{ ml: "auto" }}>
              <OrderSummary />
            </Grid> */}
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ReadOnlyPropertyDetails;
