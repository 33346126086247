import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function GroupsApi() {
  const axiosPrivate = useAxiosPrivate();
  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();
  const isAuthorized = isRouteAuthorized("calendar", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }
  const getGroups = (queryParams) => {
    return axiosPrivate.get(`/groups`, { params: queryParams });
  };

  const createGroup = (groupData) => {
    return axiosPrivate.post(`/groups`, groupData);
  };

  const createGroupLink = (groupData) => {
    return axiosPrivate.post(`/groups/links`, groupData);
  };

  const createGroupSetting = (groupData) => {
    return axiosPrivate.post(`/groups/settings`, groupData);
  };
  const getPropertyGroupSettings = (propertyId, queryParams) => {
    console.log("getPropertyGroupSettings", propertyId, queryParams);
    return axiosPrivate.get(`/groups/${propertyId}/settings`, {
      params: queryParams,
    });
  };

  const getGroupPropertyAssociations = (groupId, queryParams) => {
    //associations
    return axiosPrivate.get(`/groups/${groupId}/associations`, {
      params: queryParams,
    });
  };

  const updateGroupPropertyAssociations = (groupId, groupData) => {
    //associations
    return axiosPrivate.post(`/groups/${groupId}/associations`, groupData);
  };

  const updateGroupName = (groupId, groupData) => {
    return axiosPrivate.put(`/groups/${groupId}/name`, groupData);
  };

  const updateGroupSettings = (settingsId, groupData) => {
    return axiosPrivate.put(`/groups/settings/${settingsId}`, groupData);
  };

  const deleteGroupSetting = (settingsId) => {
    return axiosPrivate.delete(`/groups/settings/${settingsId}`);
  };

  const updateGroupStatus = (groupId, status) => {
    return axiosPrivate.put(`/groups/${groupId}/status/${status}`);
  };

  return {
    getGroups,
    createGroup,
    createGroupLink,
    createGroupSetting,
    getPropertyGroupSettings,
    getGroupPropertyAssociations,
    updateGroupPropertyAssociations,
    updateGroupName,
    updateGroupSettings,
    deleteGroupSetting,
    updateGroupStatus,
  };
}

export default GroupsApi;
