/**
 * =========================================================
 * Argon Dashboard 2 PRO MUI - v3.0.0
 * =========================================================
 *
 * Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import moment from "moment";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactDOM from "react-dom";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import { showDeleteConfirmation } from "services/confirmDelete";
import Swal from "sweetalert2";
import { MenuItem } from "@mui/material";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
} from "services/dateUtils";
// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useParams } from "react-router-dom";
import HostHiveLoading from "components/HostHiveLoading";
import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
import CreateEventWizard from "layouts/management/calendar/components/CreateEventWizard";
import ListEventCard from "layouts/management/calendar/components/ListEventCard";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import Divider from "@mui/material/Divider";
// @mui material components
import Card from "@mui/material/Card";
// @mui material components
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import EventDataCard from "layouts/management/calendar/components/EventDataCard";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { useState, useEffect } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
import updateCalendarEvents from "services/calendarUtils";
import BookingsApi from "api/bookingsApi";
import { useTranslation } from "react-i18next";
import { create, set } from "lodash";
import TestNewEventWizard from "layouts/management/components/TestNewEventWizard";
function Calendar({
  header,
  externalFetch,
  setExternalFetch,
  propertyId = null,
  setIsLoadingBooking,
  disableViewPropertyDetails = false,
  createAppointment = false,
  ...rest
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const [loadingBookingDetail, setLoadingBookingDetail] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [bookings, setBookings] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [serviceNames, setServiceNames] = useState(null);
  const [isLoadingServiceNames, setIsLoadingServiceNames] = useState(true);
  const [bookingId, setBookingId] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const bookingsApi = BookingsApi();
  const [showCalendarView, setShowCalendarView] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [appointmentCounts, setAppointmentCounts] = useState(0);
  const [viewByReservation, setViewByReservation] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setCreateBooking(false);
  };

  const [createBooking, setCreateBooking] = useState(false);
  const [isCreation, setIsCreation] = useState(false);

  const [refetchBookings, setRefetchBookings] = useState(false);

  const translateDateValue = (key, isMonth = true) => {
    let loweKey = key.toLowerCase();
    let translatedValue = "";
    if (isMonth) {
      translatedValue = t(`calendar.months.${loweKey}`);
    } else {
      translatedValue = t(`calendar.days.${loweKey}`);
    }

    return translatedValue;
  };
  const [calendarHeader, setCalendarHeader] = useState({
    title: `${translateDateValue(monthName)}`,
    date: `${translateDateValue(`${dayOfWeek}`, false)}, ${year}`,
  });
  const [loadingEvents, setLoadingEvents] = useState(false);

  const [openViewEvent, setOpenViewEvent] = useState(false);
  const [openCreateEvent, setOpenCreateEvent] = useState(false);

  const [responseData, setResponseData] = useState({});
  const [bookingPayload, setBookingPayload] = useState(null);
  const [userSelections, setUserSelections] = useState({});
  const [nextDisabled, setNextDisabled] = useState(false);
  const [property, setProperty] = useState(null);
  const [sort, setSort] = useState(null);
  const [clickEventDate, setClickEventDate] = useState(null);
  useEffect(() => {
    const loadBookings = async () => {
      setIsLoading(true);

      let queryParams = {
        limit: 1000,
        includeTurnOvers: true,
      };
      if (propertyId === undefined || propertyId === null) {
        queryParams.companyId = company?.id;
        queryParams.propertyNameTitle = true;
      } else {
        // setPropertyInfo(property);
        queryParams.propertyId = propertyId;
      }

      if (startDate) {
        queryParams.startDate = startDate;
        queryParams.sort = "asc";
      } else {
        queryParams.sort = null;
      }

      try {
        console.log("queryParams", queryParams);
        const response = await bookingsApi.getBookings(queryParams);
        // console.log("bookings.data", response.data);
        setBookings(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        if (rest?.appointmentId) {
          const queryParams = {
            bookingId: rest?.appointmentId,
          };
          const bookingIdResponse = await bookingsApi.getBookings(queryParams);
          console.log("booking search", bookingIdResponse.data);
          const foundBookingInfo = findBooking(
            rest?.appointmentId,
            bookingIdResponse.data
          );
          // setBookingInfo(bookingInfo);
          // handleOpenModal("View Scheduled Service");
          // handleBookingClicked(null, rest?.appointmentId);
          setIsLoadingBooking(false);

          setBookingInfo(null);
          // let foundBookingInfo = findBooking(bookingId, bookings);
          console.log("foundBookingInfo", bookingId, foundBookingInfo);
          setIsLoadingBooking(false);
          if (!foundBookingInfo) {
            return;
          }
          setBookingInfo(foundBookingInfo);

          setBookingPayload(foundBookingInfo);
          let updatedUserSelections = {
            [foundBookingInfo?.assignedCompanyId]:
              foundBookingInfo?.assignedCompany,
            [foundBookingInfo?.checklistId]: foundBookingInfo?.checklistName,
            [foundBookingInfo?.serviceNameId]: foundBookingInfo?.serviceName,
            [foundBookingInfo?.propertyId]: foundBookingInfo?.propertyName,
            // [foundBookingInfo?.categoryId]: foundBookingInfo?.category?.name,
            [foundBookingInfo?.category?.id]: (
              <ArgonBadgeDot
                color={foundBookingInfo?.category?.color}
                size="md"
                badgeContent={foundBookingInfo?.category?.name}
              />
            ),
            [foundBookingInfo?.assignedUserSubId]:
              foundBookingInfo?.assignedUserEmail,
          };

          setUserSelections(updatedUserSelections);

          setOpenViewEvent(true);
        }
        if (createAppointment) {
          handleCreateBookingClicked();
        }
      }
    };

    async function eventsToClear() {
      try {
        let queryParams = {
          limit: 1000,
        };
        if (propertyId === undefined) {
          queryParams.companyId = company?.id;
        } else {
          // setPropertyInfo(property);
          queryParams.propertyId = propertyId;
        }
        const response = await bookingsApi.getEventsToClear(queryParams);
        console.log("response", response);
        setAppointmentCounts(response.data.count);
      } catch (error) {
        console.log("error", error);
      }
    }

    loadBookings();
    eventsToClear();
    setClickEventDate(null);
  }, [refetchBookings, externalFetch, startDate]);

  const handleCreateBookingClicked = () => {
    // console.log("serviceScheduleDate", serviceScheduleDate);
    setBookingId(null);
    setBookingInfo(null);
    setCreateBooking(true);
    // if (clickEventDate !== null) {
    //   setClickEventDate("2022-01-02");
    // } else {
    //   setClickEventDate(null);
    // }
    setOpenCreateEvent(true);
    // setIsCreation(true);
    // handleOpenModal(
    //   t("serviceAssignments.eventDataCard.addNewAssignment.title")
    // );
  };

  const findBooking = (bookingId, bookings) => {
    const foundBooking = bookings.find((booking) => booking.id === bookingId);
    return foundBooking || null;
  };

  const handleBookingClicked = async (events, directId) => {
    let bookingId = null;
    if (directId) {
      bookingId = directId;
    } else {
      bookingId = events.event.id;
    }
    setBookingInfo(null);
    let foundBookingInfo = findBooking(bookingId, bookings);
    console.log("foundBookingInfo", directId, foundBookingInfo);
    if (!foundBookingInfo) {
      return;
    }
    setBookingInfo(foundBookingInfo);

    setBookingPayload(foundBookingInfo);
    let updatedUserSelections = {
      [foundBookingInfo?.assignedCompanyId]: foundBookingInfo?.assignedCompany,
      [foundBookingInfo?.checklistId]: foundBookingInfo?.checklistName,
      [foundBookingInfo?.serviceNameId]: foundBookingInfo?.serviceName,
      [foundBookingInfo?.propertyId]: foundBookingInfo?.propertyName,
      [foundBookingInfo?.serviceScheduleTime]:
        foundBookingInfo?.serviceScheduleTime,
      [foundBookingInfo?.category?.id]: (
        <ArgonBadgeDot
          color={foundBookingInfo?.category?.color}
          size="md"
          badgeContent={foundBookingInfo?.category?.name}
        />
      ),
      [foundBookingInfo?.assignedUserSubId]:
        foundBookingInfo?.assignedUserEmail,
    };

    setUserSelections(updatedUserSelections);

    setOpenViewEvent(true);
  };

  const confirmUpdateDateAndTime = async (fromDate, toDate, serviceName) => {
    try {
      await showDeleteConfirmation(
        `${serviceName}`,
        `Change appointment date from ${fromDate} to ${toDate}?`,
        "Yes",
        "info"
      );
      // console.log(`User confirmed deletion!`);
      setAppointmentCounts(0);
      return true;
    } catch (response) {
      console.log("User cancelled date update");
      return false;
    }
  };

  const confirmUserAction = async () => {
    try {
      await showDeleteConfirmation(
        `Are you sure you want to clear all ${appointmentCounts} appointments?`,
        "Clear Appointments",
        "Yes, clear all appointments"
      );
      console.log(`User confirmed deletion!`);
      setAppointmentCounts(0);
      return true;
    } catch (response) {
      console.log("User cancelled deletion!");
      return false;
    }
  };

  const showClearAlert = (title, message, action) => {
    Swal.fire(title, message, action);
  };

  const handleEventsToClear = async () => {
    try {
      let queryParams = {
        propertyId: propertyInfo?.id,
      };

      const deleteConfirmation = await confirmUserAction();
      if (deleteConfirmation) {
        console.log("Delete bookings");

        if (appointmentCounts === 0) {
          showClearAlert("Error", "No appointments to clear", "error");
          return;
        }
        try {
          const deleteResponse = await bookingsApi.clearCalendarEvents(
            queryParams
          );
          if (deleteResponse.status >= 200) {
            showClearAlert(
              "Success",
              "Clearing service appointments",
              "success"
            );
          } else {
            showClearAlert(
              "Error",
              "Error clearing service appointments",
              "error"
            );
          }
        } catch (error) {}
      } else {
        console.log("Do not delete");
      }
    } catch (error) {
      console.log("error", error);
      console.log("User cancelled deletion!");
    }
  };

  const handleCloseViewEvent = () => {
    setOpenViewEvent(false);

    if (!rest?.appointmentId) {
      handleRefetchBookings();
    } else {
      navigate("/calendar");
    }
  };

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  // trigger refetchBookings
  const handleRefetchBookings = () => setRefetchBookings(!refetchBookings);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          handleCreateBookingClicked() && handleCloseMenu();
        }}
      >
        Create {t("serviceAssignments.buttons.addNewAssignment")}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleEventsToClear() && handleCloseMenu();
        }}
      >
        Clear All Calendar Events
      </MenuItem>
    </Menu>
  );
  // const [nextDisabled,setNextDisabled] = useState(false);

  const legendColors = [
    {
      color: "warning",
      text: "Scheduled",
    },
    {
      color: "primary",
      text: "Accepted",
    },
    {
      color: "info",
      text: "In Progress",
    },
    {
      color: "error",
      text: "Same Day Guest Checkin",
    },
    {
      color: "success",
      text: "Completed",
    },
  ];
  const ColorLegend = ({ color, text }) => {
    return (
      <Grid item xs={12} md={2}>
        <ArgonBox display="flex" lineHeight={1}>
          <ArgonBox>
            {/* <ArgonBadgeDot size="lg" color="warning" /> */}
            <IconButton
              sx={({
                borders: { borderWidth },
                palette: { white, dark },
                transitions,
              }) => ({
                width: "24px",
                height: "24px",
                padding: 0,
                // border: `${borderWidth[1]} solid ${white.main}`,
                // borderColor: categoryColor === setCategoryColor && dark.main,
                transition: transitions.create("border-color", {
                  easing: transitions.easing.sharp,
                  duration: transitions.duration.shorter,
                }),
                backgroundImage: ({
                  functions: { linearGradient },
                  palette: { gradients },
                }) =>
                  linearGradient(gradients[color].main, gradients[color].state),

                "&:not(:last-child)": {
                  mr: 1,
                },

                "&:hover, &:focus, &:active": {
                  borderColor: dark.main,
                },
              })}
              // onClick={() => setCategoryColor(color)}
            />
          </ArgonBox>
          <ArgonBox pl={1}>
            <ArgonTypography variant="caption" color="text">
              {text}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </Grid>
    );
  };

  const handleChangeCalendarView = () => {
    if (showCalendarView === true) {
      let startDate = new Date();
      const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
      setStartDate(formattedStartDate);
      setShowCalendarView(false);
      console.log("formattedMonth", formattedStartDate);
    } else {
      setShowCalendarView(true);
      setStartDate(null);
    }
  };

  const handleViewByReservation = () => {
    let action = !viewByReservation;
    let updatedEvents = [];
    console.log("action", action);
    if (action === true) {
      // update bookings where startDate = checkinDate and endDate = checkoutDate by mapping through bookings
      updatedEvents = bookings.map((booking) => {
        if (booking.checkIn && booking.checkOut && booking.isOwner) {
          let checkinTime = "16:00";
          let updatedScheduledTime = "10:00";

          const guestCheckinDate = booking.checkIn.split("T")[0];
          const guestCheckin = `${guestCheckinDate}T${checkinTime}`;

          booking.end = booking.checkOut;

          if (booking.serviceScheduleTime) {
            updatedScheduledTime = booking.serviceScheduleTime;
          }
          const checkout = booking.checkOut.split("T")[0];
          const scheduledEndTime = `${checkout}T${updatedScheduledTime}`;

          booking.start = guestCheckin;
          booking.end = scheduledEndTime;
        }
        return booking;
      });

      // setBookings(updatedEvents);
    } else {
      updatedEvents = bookings.map((booking) => {
        if (booking.serviceScheduleDate) {
          booking.start = booking.serviceScheduleDate;
          booking.end = booking.serviceScheduleDate;
        }
        return booking;
      });
    }
    console.log("updatedEvents", updatedEvents);
    setViewByReservation(action);
    setBookings(updatedEvents);
  };

  const handleNewEvent = (dateClickInfo) => {
    console.log("dateClickInfo", dateClickInfo.dateStr);
    setClickEventDate(dateClickInfo.dateStr);
    handleCreateBookingClicked();
  };

  const returnBookingToSpot = (bookingId, originalDate) => {
    let events = bookings;
    // find the event that was moved and reset the date
    let updatedEvents = events.map((event) => {
      if (event.id === bookingId) {
        event.start = originalDate;
      }
      return event;
    });

    setBookings(updatedEvents);
  };

  const handleDateChange = async (eventDropInfo) => {
    let bookingId = eventDropInfo.event.id;
    let foundBookingInfo = findBooking(bookingId, bookings);
    // console.log("foundBookingInfo", foundBookingInfo);
    let originalDate = foundBookingInfo?.serviceScheduleDate;
    let isOwner = foundBookingInfo?.isOwner;
    if (!isOwner) {
      returnBookingToSpot(bookingId, originalDate);
      return;
    }

    let serviceScheduleDate = eventDropInfo.event.startStr;
    let serviceName = foundBookingInfo?.serviceName;
    let propertyName = foundBookingInfo?.propertyName;
    const confirmAction = await confirmUpdateDateAndTime(
      originalDate,
      serviceScheduleDate,
      `${propertyName} - ${serviceName}`
    );
    if (!confirmAction) {
      returnBookingToSpot(bookingId, originalDate);
      return;
    }

    // console.log("handleUpdateDateAndTime", bookingPayload);
    let dateTimePayload = {
      serviceScheduleDate: serviceScheduleDate,
      serviceScheduleTime: foundBookingInfo?.serviceScheduleTime || "10:00",
    };
    try {
      await bookingsApi.updateBookingDateTime(bookingId, dateTimePayload);
      // setShowEditDateAndTime(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ArgonBox
      sx={{
        height: "100%",
        // transparent background
      }}
    >
      <ArgonBox m={2}>
        <Grid disply="flex" container justifyContent="space-between">
          <Grid item xs={12} md={4} mb={2}>
            <ArgonButton
              variant="gradient"
              color="info"
              onClick={handleChangeCalendarView}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
                pr: 1.5,
                borderRadius: "8px",
                borderWidth: "1px",
                transition: "background-color 0.3s, transform 0.2s",
                ":hover": {
                  backgroundColor: "rgba(0,0,0,0.05)",
                  transform: "scale(1.02)",
                },
              }}
              startIcon={
                <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                  {/* calendar_today */}
                  {showCalendarView ? "view_list" : "calendar_today"}
                </Icon>
              }
            >
              {showCalendarView ? "List View" : "Calendar View"}
            </ArgonButton>
          </Grid>
          {showCalendarView && (
            <Grid item xs={12} md={4} mb={2}>
              <ArgonButton
                variant="gradient"
                color="info"
                onClick={handleViewByReservation}
                sx={{
                  ml: "auto",
                  alignSelf: "flex-start",
                  py: 1.25,
                  pr: 1.5,
                  borderRadius: "8px",
                  borderWidth: "1px",
                  transition: "background-color 0.3s, transform 0.2s",
                  ":hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    transform: "scale(1.02)",
                  },
                }}
                startIcon={
                  <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                    {/* calendar_today */}
                    {showCalendarView ? "view_list" : "calendar_today"}
                  </Icon>
                }
              >
                {!viewByReservation
                  ? "View by Reservation"
                  : "View by Scheduled Service"}
              </ArgonButton>
            </Grid>
          )}

          <Grid item xs={12} md={4}>
            <ArgonBox>
              <ArgonButton
                variant="gradient"
                color="info"
                onClick={handleCreateBookingClicked}
                sx={{
                  ml: "auto",
                  alignSelf: "flex-start",
                  py: 1.25,
                  pr: 1.5,
                  borderRadius: "8px",
                  borderWidth: "1px",
                  transition: "background-color 0.3s, transform 0.2s",
                  ":hover": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                    transform: "scale(1.02)",
                  },
                }}
                startIcon={
                  <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                    {/* calendar_today */}
                    add
                  </Icon>
                }
              >
                New Appointment
              </ArgonButton>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>

      <ArgonBox
        pt={2}
        px={2}
        lineHeight={1}
        justifyContent="space-between"
        display="flex"
      >
        <ArgonBox></ArgonBox>
        <ArgonBox>{renderMenu()}</ArgonBox>
      </ArgonBox>
      {isLoading ? <HostHiveLoading message="Loading Calendar Events" /> : null}
      {showCalendarView ? (
        <Card
          sx={{
            boxShadow: 2,
            borderRadius: "8px",
            backgroundColor: "background.paper",
            overflow: "hidden",
            marginTop: "16px",
          }}
        >
          <CalendarRoot p={2} ownerState={{ darkMode }}>
            <FullCalendar
              {...rest}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              events={bookings}
              height="100%"
              eventClick={(calendarEvents) =>
                handleBookingClicked(calendarEvents)
              }
              dateClick={(dateClickInfo) => handleNewEvent(dateClickInfo)}
              eventDrop={(eventDropInfo) => handleDateChange(eventDropInfo)}

              // headerToolbar={{
              //   left: "prev,next,today",
              // right:"dayGridMonth",
              //   center: "title",
              // }}
            />
          </CalendarRoot>
        </Card>
      ) : (
        <ArgonBox>
          <Divider />
          {bookings?.map((booking, key) => (
            // <UpcomingAssignmentItem
            //   booking={assignment}
            //   handleViewClicked={() => handleViewClicked(assignment?.id)}
            // />
            <ArgonBox pb={2}>
              <ListEventCard
                booking={booking}
                handleBookingClicked={handleBookingClicked}
              />
            </ArgonBox>
          ))}
        </ArgonBox>
      )}
      <Card
        sx={{
          boxShadow: 2,
          borderRadius: "8px",
          backgroundColor: "background.paper",
          overflow: "hidden",
          marginTop: "16px",
        }}
      >
        <ArgonBox p={2}>
          <Grid container spacing={2}>
            {legendColors?.map((legend, index) => (
              <ColorLegend
                key={index}
                color={legend.color}
                text={legend.text}
              />
            ))}
          </Grid>
        </ArgonBox>
      </Card>
      <ArgonBox>
        <>
          <HostHiveDialog
            open={openCreateEvent}
            fullWidth={true}
            includeSave={false}
            includeClose={false}
            maxWidth="lg"
            onClose={() => setOpenCreateEvent(false)}
            // onSave={handleSubmitClicked}
            title={"Create Event"}
            fullScreen={false}
            cancelButtonText="Cancel"
            submitButtonText="Save"
            showStatusBadge={false}
            helpIntegrationLookupKey="serviceAssigmentEditCreate"
            dialogObjects={
              <CreateEventWizard
                closeWizard={() => {
                  handleRefetchBookings();
                  setOpenCreateEvent(false);
                  if (createAppointment) {
                    navigate("/calendar");
                  }
                }}
                propertyId={propertyId}
                serviceGapDate={clickEventDate}
              />
            }
          />
          <HostHiveDialog
            open={openViewEvent}
            fullWidth={true}
            includeSave={false}
            includeClose={true}
            maxWidth="lg"
            onClose={handleCloseViewEvent}
            // onSave={handleSubmitClicked}
            title={"View Event"}
            fullScreen={false}
            cancelButtonText="Close"
            submitButtonText="Save"
            showStatusBadge={true}
            statusColor={bookingPayload?.statusInfo?.color}
            statusContent={bookingPayload?.statusInfo?.text}
            helpIntegrationLookupKey="serviceAssigmentEditCreate"
            dialogObjects={
              <ViewEventDataCard
                setNextDisabled={setNextDisabled}
                bookingPayload={bookingPayload}
                setBookingPayload={setBookingPayload}
                setUserSelections={setUserSelections}
                userSelections={userSelections}
                responseData={responseData}
                setResponseData={setResponseData}
                isInCreationMode={false}
                closeEventDataCard={() => setOpenViewEvent(false)}
                handleRefetchBookings={handleRefetchBookings}
              />
            }
          />
        </>
      </ArgonBox>
    </ArgonBox>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
