import ArgonButton from "components/ArgonButton";

import Icon from "@mui/material/Icon";
// import {
//   Grid,
//   Card,
//   CardActionArea,
//   CardContent,
//   Typography,
//   makeStyles,
// } from "@material";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import { useMemo, useState, useEffect } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import { RouteProtection } from "services/routeProtectionHelper";
import ArgonAlert from "components/ArgonAlert";
import useMediaQuery from "@mui/material/useMediaQuery";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import HostHiveKbHelp from "components/HostHiveKbHelp";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import Table from "examples/Tables/Table";

import GradientLineChart from "layouts/dashboards/hosthive-home/components/GradientLineChart";
import HostHiveDialog from "components/HostHiveDialog";

import ConfirmationLists from "layouts/management/schedulePage/components/ConfirmationLists";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboards/default/components/Slider";
import TeamMembers from "layouts/dashboards/default/components/TeamMembers";
import TodoList from "layouts/dashboards/default/components/TodoList";
import ProgressTrack from "layouts/dashboards/default/components/ProgressTrack";
import BalanceCard from "layouts/dashboards/default/components/BalanceCard";
import CryptoCard from "layouts/dashboards/default/components/CryptoCard";

// Pages layout components
import Post from "layouts/pages/profile/teams/components/Post";
import CompanyApi from "api/companyApi";

// Data
import { UserAuth } from "context/AuthContext";
import {
  columns,
  smColumns,
} from "layouts/management/bookingReports/propertyBookingReportList/components/propertyBookingReportColumns";
// import AdministrationApi from "api/administrationApi";
import projectsTableData from "layouts/dashboards/default/data/projectsTableData";
import salesTableData from "layouts/dashboards/default/data/salesTableData";
import authorsTableData from "layouts/dashboards/default/data/authorsTableData";
import categoriesListData from "layouts/dashboards/default/data/categoriesListData";
import BookingsApi from "api/bookingsApi";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
} from "services/dateUtils";
import { useParams, useNavigate } from "react-router-dom";
import DashboardApi from "api/dashboardApi";
import Calendar from "layouts/management/calendar";
import { useTranslation } from "react-i18next";
import BookingReportApi from "api/bookingReportApi";
import HostHiveDataTable from "components/HostHiveDataTable";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import pageRoutes from "page.routes";
import { loopHooks } from "react-table";
import SubscriptionsApi from "api/subscriptionsApi";
function AppHomePage() {
  const { size } = typography;
  const subscriptionsApi = SubscriptionsApi();
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const companyApi = CompanyApi();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const [showAlert, setShowAlert] = useState(true);
  const [alertColor, setAlertColor] = useState("primary");
  const [alertMessage, setAlertMessage] = useState(
    "Looks like you haven't taken advantage of our free trial yet."
  );
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const { tokenCode } = useParams();
  const [invitationResponseLoading, setInvitationResponseLoading] =
    useState(false);
  const [invitationAcceptedShowing, setInvitationAcceptedShowing] =
    useState(false);
  const [acceptedPartnerInfo, setAcceptedPartnerInfo] = useState(null);
  const [invitationMessage, setInvitationMessage] = useState(null);
  const [invitationErrorMessage, setInvitationErrorMessage] = useState(null);
  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();
  const [companySubscription, setCompanySubscription] = useState();
  const [trialEligible, setTrialEligible] = useState(false);
  const [error, setError] = useState(null);

  const isEligibleForApp = (app) => {
    let useSubscriptionTier = null;
    if (!subscriptionTier) {
      useSubscriptionTier = "free";
    } else {
      useSubscriptionTier = subscriptionTier;
    }
    return !app.notEligibleSubscriptionTier.includes(useSubscriptionTier);
  };
  useEffect(() => {
    const invitationAcceptedCard = async () => {
      setInvitationResponseLoading(true);
      setInvitationAcceptedShowing(true);
      try {
        const response = await companyApi.acceptCompanyPartnershipViaEmail(
          tokenCode
        );
        if (response.status >= 200 && response.status < 300) {
          setAcceptedPartnerInfo(response?.data);
          console.log("Invitation has been accepted");
          setInvitationMessage(
            `You are now a service partner of ${response?.data?.name} !`
          );
        }
      } catch (error) {
        console.log(error);
        console.log("Error accepting invitation");
        setInvitationMessage(
          `Error accepting invitation: ${error?.response.data?.detail}`
        );
        setInvitationResponseLoading(false);
      }
      setInvitationResponseLoading(false);
      //navigate("/");
    };
    if (tokenCode && role) {
      invitationAcceptedCard();
    }
  }, [role]);
  const [companyId, setCompanyId] = useState(null);
  // useEffect(() => {
  //   if (company) {
  //     setCompanyId(company?.id);
  //   }
  // }, [company]);
  useEffect(() => {
    const loadSubscriptionDetails = async () => {
      console.log("Yay");
      try {
        console.log("company details", company?.id);
        const companiesResponse = await companyApi.getCompanySubscription(
          company?.id
        );

        if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
          setCompanySubscription(companiesResponse.data);
          const freeTrialResponse = companiesResponse.data.trialEligible;
          const subscriptionTierResponse =
            companiesResponse.data.subscriptionTier;
          if (
            freeTrialResponse &&
            subscriptionTierResponse !== "paid" &&
            subscriptionTierResponse !== "founder"
          ) {
            setTrialEligible(companiesResponse.data.trialEligible);
          }
        }
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };
    console.log("Subscription Tier:", subscriptionTier);
    if (subscriptionTier && company && company?.id) {
      loadSubscriptionDetails();
    }
  }, [subscriptionTier, company?.id]);

  const getRouteProtectionSubscriptionTier = (routeLookUpKey) => {
    const protector = RouteProtection(routeLookUpKey);
    let notEligibleSubscriptionTiers = protector?.notEligibleSubscriptionTiers;

    return notEligibleSubscriptionTiers;
  };

  const colors = [
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ];
  // t("buttons.create");
  const apps = [
    {
      title: t("appHomepage.apps.properties.title"),
      lookupKey: "properties",
      description: t("appHomepage.apps.properties.description"),
      url: "/properties",
      icon: "apartment",
      color: "info",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("properties"),
    },
    {
      title: t("appHomepage.apps.calendar.title"),
      lookupKey: "calendar",
      description: t("appHomepage.apps.calendar.description"),
      url: "/calendar",
      icon: "calendar_today",
      color: "info",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("calendar"),
    },
    {
      title: t("appHomepage.apps.finance.title"),
      lookupKey: "finance",
      description: t("appHomepage.apps.finance.description"),
      url: "/finance",
      icon: "account_balance",
      color: "success",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("finance"),
    },
    {
      title: t("appHomepage.apps.inventory.title"),
      lookupKey: "inventory",
      description: t("appHomepage.apps.inventory.description"),
      url: "/inventory",
      icon: "inventory",
      color: "primary",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("inventory"),
    },
    {
      title: t("appHomepage.apps.timeTracking.title"),
      lookupKey: "timeTracking",
      description: t("appHomepage.apps.timeTracking.description"),
      url: "/time-tracking",
      icon: "timer",
      color: "warning",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("timeTracking"),
    },
    {
      title: t("appHomepage.apps.tasks.title"),
      lookupKey: "tasks",
      description: t("appHomepage.apps.tasks.description"),
      url: "/tasks",
      icon: "task",
      color: "dark",
      notEligibleSubscriptionTier: getRouteProtectionSubscriptionTier("tasks"),
    },

    {
      title: t("appHomepage.apps.serviceReports.title"),
      lookupKey: "serviceReports",
      description: t("appHomepage.apps.serviceReports.description"),
      url: "/service-reports",
      icon: "description",
      color: "success",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("serviceReports"),
    },
    {
      title: t("appHomepage.apps.serviceInvoicing.title"),
      lookupKey: "serviceInvoicing",
      description: t("appHomepage.apps.serviceInvoicing.description"),
      url: "/service-invoicing",
      icon: "receipt",
      color: "primary",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("serviceInvoicing"),
    },
    {
      title: t("appHomepage.apps.settings.title"),
      lookupKey: "companySettings",
      description: t("appHomepage.apps.settings.description"),
      url: "/company/settings",
      icon: "settings",
      color: "secondary",
      notEligibleSubscriptionTier:
        getRouteProtectionSubscriptionTier("company"),
    },
    // Add more apps here
  ];

  // Function to handle card click
  const handleCardClick = (url) => {
    // window.location.href = url;
    navigate(url);
  };

  const handleOpenSubscriptionSettings = () => {
    navigate(`/company/settings/${company?.id}`);
  };
  const [navigatingToStripe, setNavigatingToStripe] = useState(false);
  const handleSubscriptionSignUp = async () => {
    if (!company?.id) return;
    // setCheckoutLoading(true);
    setNavigatingToStripe(true);
    try {
      const response = await subscriptionsApi.createCheckoutSession(
        company?.id,
        "paid"
      );
      if (response.status >= 200 && response.status <= 300) {
        //window.open(response.data.url, "noopener,noreferrer");
        window.location.href = response.data.url;
      }
    } catch (error) {
      console.log(error);
    }

    setNavigatingToStripe(false);
  };

  return (
    <>
      <DashboardLayout>
        {trialEligible ? (
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              {isSmallScreen ? (
                <ArgonBox
                  pt={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%", // You can set a specific height or leave as 100% to fill the container
                  }}
                >
                  <ArgonButton
                    disabled={navigatingToStripe}
                    variant="gradient"
                    color="primary"
                    onClick={handleSubscriptionSignUp}
                  >
                    {navigatingToStripe
                      ? "Starting free trial..."
                      : "Start Free Trial"}
                  </ArgonButton>
                </ArgonBox>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        {/* <DashboardNavbar /> */}
        <ArgonBox pt={3}>
          <HostHiveDialog
            open={invitationAcceptedShowing}
            fullWidth={true}
            includeSave={false}
            includeClose={true}
            maxWidth="md"
            onClose={() => {
              setInvitationAcceptedShowing(false);
              navigate("/");
            }}
            title="Service Partner invitation"
            fullScreen={false}
            cancelButtonText="Close"
            submitButtonText="Save"
            showStatusBadge={false}
            // statusColor="success"
            // statusContent={bookingInfo?.status}
            dialogObjects={
              <Card>
                {invitationResponseLoading ? (
                  <HostHiveLoading
                    message={t("company.acceptingInvitation.title")}
                  />
                ) : (
                  <>
                    <ArgonBox display="flex" justifyContent="center">
                      <ArgonTypography variant="h5" fontWeight="regular">
                        {invitationMessage}
                      </ArgonTypography>
                    </ArgonBox>
                  </>
                )}
              </Card>
            }
          />
        </ArgonBox>
        <ArgonBox py={3}>
          <Grid container spacing={3}>
            {trialEligible ? (
              <Grid item xs={12} sm={6} lg={4}>
                <Card
                  disabled={navigatingToStripe}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                >
                  <CardActionArea onClick={handleSubscriptionSignUp}>
                    <ArgonBox
                      p={2}
                      mx={3}
                      display="flex"
                      justifyContent="center"
                    >
                      <ArgonBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor="primary"
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">loyalty</Icon>
                      </ArgonBox>
                    </ArgonBox>
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                      }}
                    >
                      <ArgonTypography
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                        gutterBottom
                        variant="h5"
                        component="h2"
                      >
                        Start Free Trial
                      </ArgonTypography>
                      <ArgonTypography
                        sx={{
                          fontSize: "1rem",
                          color: "#666",
                        }}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {navigatingToStripe
                          ? "Starting free trial..."
                          : "You have not started your free trial yet. Start now to access all features."}
                      </ArgonTypography>
                    </CardContent>
                  </CardActionArea>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%", // Ensure it spans the entire width of the card
                      // padding: 1, // Add padding if necessary
                    }}
                  >
                    <HostHiveKbHelp
                      // helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
                      size="medium"
                      lookupKey="subscriptionBilling"
                    />
                  </ArgonBox>
                </Card>
              </Grid>
            ) : null}

            {apps.filter(isEligibleForApp).map((app, index) => (
              <Grid item xs={12} sm={6} lg={4} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f5f5f5",
                    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  }}
                >
                  <CardActionArea onClick={() => handleCardClick(app.url)}>
                    <ArgonBox
                      p={2}
                      mx={3}
                      display="flex"
                      justifyContent="center"
                    >
                      <ArgonBox
                        display="grid"
                        justifyContent="center"
                        alignItems="center"
                        bgColor={app.color || "primary"}
                        color="white"
                        width="4rem"
                        height="4rem"
                        shadow="md"
                        borderRadius="lg"
                        variant="gradient"
                      >
                        <Icon fontSize="default">
                          {app.icon || "account_balance"}
                        </Icon>
                      </ArgonBox>
                    </ArgonBox>
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        textAlign: "center",
                      }}
                    >
                      <ArgonTypography
                        sx={{
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          color: "#333",
                        }}
                        gutterBottom
                        variant="h5"
                        component="h2"
                      >
                        {app.title}
                      </ArgonTypography>
                      <ArgonTypography
                        sx={{
                          fontSize: "1rem",
                          color: "#666",
                        }}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {app.description}
                      </ArgonTypography>
                    </CardContent>
                  </CardActionArea>
                  <ArgonBox
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%", // Ensure it spans the entire width of the card
                      // padding: 1, // Add padding if necessary
                    }}
                  >
                    <HostHiveKbHelp
                      // helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
                      size="medium"
                      lookupKey={app.lookupKey}
                    />
                  </ArgonBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </ArgonBox>
        <Footer />
      </DashboardLayout>
    </>
  );
}

export default AppHomePage;
