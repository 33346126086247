import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import NewEventDataCard from "layouts/management/calendar/components/NewEventDataCard";
import HostHiveDialog from "components/HostHiveDialog";

import BookingsApi from "api/bookingsApi";
import ChecklistsApi from "api/checkListsApi";
import CompaniesApi from "api/companyApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import CategoriesApi from "api/categoriesApi";
import convertToOptions from "services/convertooptions";
import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
import CreateEventWizard from "layouts/management/calendar/components/CreateEventWizard";
import ArgonBadgeDot from "components/ArgonBadgeDot";

function TestNewEventWizard({
  bookingInfo = null,
  openViewEvent,
  setOpenViewEvent,
  open,
  setOpen,
}) {
  const [responseData, setResponseData] = useState({});
  const [bookingPayload, setBookingPayload] = useState(null);
  const [userSelections, setUserSelections] = useState({});
  const [nextDisabled, setNextDisabled] = useState(false);
  useEffect(() => {
    console.log("Am i loaded");
  }, []);
  useEffect(() => {
    console.log("booking info?", bookingInfo);
    if (!bookingInfo) {
      console.log("no booking info");
      return;
    }
    console.log("We got data", bookingInfo);
    setBookingPayload(bookingInfo);
    let updatedUserSelections = {
      [bookingInfo?.assignedCompanyId]: bookingInfo?.assignedCompany,
      [bookingInfo?.checklistId]: bookingInfo?.checklistName,
      [bookingInfo?.serviceNameId]: bookingInfo?.serviceName,
      [bookingInfo?.propertyId]: bookingInfo?.propertyName,

      [bookingInfo?.category?.id]: (
        <ArgonBadgeDot
          color={bookingInfo?.category?.color}
          size="md"
          badgeContent={bookingInfo?.category?.name}
        />
      ),
      [bookingInfo?.assignedUserSubId]: bookingInfo?.assignedUserEmail,
    };

    setUserSelections(updatedUserSelections);
  }, []);

  return (
    <>
      <HostHiveDialog
        open={open}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="lg"
        onClose={() => setOpen(false)}
        // onSave={handleSubmitClicked}
        title={"Create Event"}
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        showStatusBadge={false}
        helpIntegrationLookupKey="serviceAssigmentEditCreate"
        dialogObjects={<CreateEventWizard closeWizard={() => setOpen(false)} />}
      />
      <HostHiveDialog
        open={openViewEvent}
        fullWidth={true}
        includeSave={false}
        includeClose={false}
        maxWidth="lg"
        onClose={() => setOpenViewEvent(false)}
        // onSave={handleSubmitClicked}
        title={"View Event"}
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        showStatusBadge={true}
        helpIntegrationLookupKey="serviceAssigmentEditCreate"
        dialogObjects={
          <ViewEventDataCard
            setNextDisabled={false}
            bookingPayload={bookingPayload}
            setBookingPayload={setBookingPayload}
            setUserSelections={setUserSelections}
            userSelections={userSelections}
            responseData={responseData}
            setResponseData={setResponseData}
            isInCreationMode={false}
          />
        }
      />
    </>
  );
}

export default TestNewEventWizard;
