/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext, useEffect } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { Typography, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Avatar from "@mui/material/Avatar";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";
import { UserAuth } from "context/AuthContext";
import { auth } from "layouts/authentication/firebase/firebase";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import BetaAgreement from "layouts/authentication/sign-in/basic/betaAgreement";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";

// Images
import github from "assets/images/logos/github.svg";
import google from "assets/images/logos/google.svg";

import CompaniesApi from "api/companyApi";

import axios from "axios";
import getApiUrl from "services/apiBaseUrl";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg";

function Basic() {
  const [t, i18n] = useTranslation("common");
  const { redirectUrl } = useParams();
  const apiUrl = getApiUrl();
  const [rememberMe, setRememberMe] = useState(false);
  const companiesApi = new CompaniesApi();

  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [signInLoading, setSignInLoading] = useState(false);
  const [error, setError] = useState(false);
  const handleSetRememberMe = () => {
    setRememberMe(!rememberMe);
    console.log("Set Remember me");
  };

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const { signIn, user, isAuthenticated, setCompany } = UserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      console.log("Production");
    } else {
      setEmail(process.env.REACT_APP_USERNAME);
      setPassword(process.env.REACT_APP_PASSWORD);
    }
  }, []);

  useEffect(() => {
    const userAuthenticated = isAuthenticated(user);
    if (userAuthenticated) {
      setUserAuthenticated(true);
      console.log("User is authenticated");
      if (redirectUrl) {
        // Decoding
        const decodedURL = atob(redirectUrl);

        navigate(decodedURL);
      } else {
        navigate("/");
      }
    } else {
      console.log("User is not authenticated authenticated", user);
    }
  }, [user]);

  const handleFormSubmit = async (e) => {
    setSignInLoading(true);
    e.preventDefault();
    setError("");
    try {
      const resp = await signIn(email, password, rememberMe);

      if (resp._tokenResponse.idToken) {
        const url = `${apiUrl}/users/${resp.user.uid}?includeDetails=true`;

        const accessToken = resp._tokenResponse.idToken;

        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
            Accept: "*/*",
            "Access-Control-Allow-Origin": "*",
          },
        });
        setCompany(response.data.userCompanyDetails);
        localStorage.setItem(
          "companyDetails",
          JSON.stringify(response.data.userCompanyDetails)
        );

        localStorage.setItem("userDetails", JSON.stringify(response.data));

        localStorage.setItem(
          "language",
          JSON.stringify(response.data.userSettings.preferredLanguage)
        );
        i18n.changeLanguage(response.data.userSettings.preferredLanguage);
        if (redirectUrl) {
          const decodedURL = atob(redirectUrl);

          navigate(decodedURL);
        } else {
          navigate("/");
        }
        //navigate("/");
      }
    } catch (e) {
      setSignInLoading(false);
      setError(e.message);
      console.log(e.message);
    }
  };

  const [agreeToBeta, setAgreeToBeta] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [betaPdfOpen, setBetaPdfOpen] = useState(false);
  const [termsPdfOpen, setTermsPdfOpen] = useState(false);
  const PdfIframeViewer = ({ url }) => (
    <iframe
      src={url}
      // src="/Users/mitch2/Documents/Github/HostHive/frontend/hh-frontend-ui/src/layouts/authentication/sign-in/basic/hh_beta.pdf"
      style={{ width: "100%", height: "100vh" }}
      frameBorder="0"
    ></iframe>
  );
  // https://app.termly.io/document/terms-of-service/274ffdda-d1cd-44b1-b88e-0b5bfe885470

  return (
    <BasicLayout image={bgImage}>
      <Card>
        {userAuthenticated ? (
          <ArgonBox p={3} textAlign="center">
            <ArgonTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
              Navigating Home...
            </ArgonTypography>
          </ArgonBox>
        ) : (
          <>
            <HostHiveDialog
              open={betaPdfOpen}
              onClose={() => {
                setBetaPdfOpen(false);
              }}
              onSave={() => {
                setAgreeToBeta(true);
                setBetaPdfOpen(false);
              }}
              onDelete={() => {
                setAgreeToBeta(false);
                setBetaPdfOpen(false);
              }}
              title="Beta Agreement"
              fullScreen={true}
              cancelButtonText="I do not Agree"
              submitButtonText="I Agree"
              deleteButtonText="I do not Agree"
              includeSave={true}
              includeClose={false}
              includeDelete={true}
              dialogObjects={<BetaAgreement />}
            />

            <HostHiveDialog
              open={termsPdfOpen}
              onClose={() => {
                setTermsPdfOpen(false);
              }}
              onSave={() => {
                setAgreeToTerms(true);
                setTermsPdfOpen(false);
              }}
              onDelete={() => {
                setAgreeToTerms(false);
                setTermsPdfOpen(false);
              }}
              title="Terms of Service"
              fullScreen={true}
              cancelButtonText="I do not Agree"
              submitButtonText="I Agree"
              deleteButtonText="I do not Agree"
              includeSave={true}
              includeClose={false}
              includeDelete={true}
              dialogObjects={
                <PdfIframeViewer url="https://app.termly.io/document/terms-of-service/274ffdda-d1cd-44b1-b88e-0b5bfe885470" />
              }
            />
            <ArgonBox p={3} textAlign="center">
              {!signInLoading ? (
                <ArgonTypography
                  variant="h5"
                  fontWeight="medium"
                  sx={{ my: 2 }}
                >
                  Sign in
                </ArgonTypography>
              ) : (
                <>
                  <ArgonTypography
                    variant="h5"
                    fontWeight="medium"
                    sx={{ my: 2 }}
                  >
                    Signing in
                  </ArgonTypography>
                  <HostHiveLoading />
                </>
              )}
              {/* <ArgonBox display="flex" justifyContent="center">
            <ArgonButton size="small" sx={{ mr: 1 }}>
              <ArgonBox
                component="img"
                src={github}
                alt="github"
                width="23px"
                height="23px"
              />
              &nbsp; Github
            </ArgonButton>
            <ArgonButton size="small">
              <ArgonBox
                component="img"
                src={google}
                alt="github"
                width="23px"
                height="23px"
              />
              &nbsp; Google
            </ArgonButton>
          </ArgonBox> */}
            </ArgonBox>
            {!signInLoading ? (
              <ArgonBox px={6} pb={3} textAlign="center">
                {/* <ArgonTypography
            display="block"
            variant="button"
            color="secondary"
            fontWeight="regular"
            sx={{ mb: 3 }}
          >
            Or sign in with credentials
          </ArgonTypography> */}
                <ArgonBox
                  component="form"
                  role="form"
                  onSubmit={handleFormSubmit}
                >
                  <ArgonBox mb={2}>
                    <ArgonInput
                      type="email"
                      value={email}
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonInput
                      type="password"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </ArgonBox>
                  <ArgonBox display="flex" alignItems="center">
                    {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;Remember me
                </ArgonTypography> */}

                    <ArgonBox mb={2}>
                      <ArgonTypography
                        variant="caption"
                        textTransform="capitalize"
                        sx={{
                          ":hover": { textDecoration: "underline" },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate("/authentication/reset-password")
                        }
                        //title={title}
                      >
                        Forgot Password?
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>

                  <ArgonBox display="flex" alignItems="center" pb={2}>
                    <Checkbox
                      checked={agreeToBeta}
                      onChange={() => setAgreeToBeta(!agreeToBeta)}
                    />
                    {/* <PdfIframeViewer file="HostHive_beta_agreement.pdf" /> */}
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": { textDecoration: "underline" },
                        cursor: "pointer",
                      }}
                      // onClick={() =>
                      //   window.open(
                      //     "https://www.hosthive.io/terms-of-service",
                      //     "_blank"
                      //   )
                      // }
                      onClick={() => setBetaPdfOpen(true)}
                    >
                      I Agree to Beta Agreement
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox display="flex" alignItems="center">
                    <Checkbox
                      checked={agreeToTerms}
                      onChange={() => setAgreeToTerms(!agreeToTerms)}
                    />
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": { textDecoration: "underline" },
                        cursor: "pointer",
                      }}
                      // onClick={() =>
                      //   window.open(
                      //     "https://www.hosthive.io/terms-of-service",
                      //     "_blank"
                      //   )
                      // }
                      onClick={() => setTermsPdfOpen(true)}
                    >
                      I Agree to Terms of Service
                    </ArgonTypography>
                  </ArgonBox>

                  <ArgonBox mt={4} mb={1}>
                    <ArgonButton
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={!agreeToBeta || !agreeToTerms}
                    >
                      Sign In
                    </ArgonButton>
                  </ArgonBox>
                  <Separator />
                  <ArgonBox mt={1} mb={3}>
                    {/* <ArgonButton
                  component={Link}
                  href="https://docs.hosthive.io/"
                  target="_blank"
                  rel="noreferrer"
                  color="dark"
                  size="small"
                  fullWidth
                  sx={{ mb: 1 }}
                >
                  Documentation
                </ArgonButton> */}
                    {process.env.NODE_ENV === "production" ? (
                      <ArgonButton
                        component={Link}
                        href="https://www.hosthive.io/join-today"
                        rel="noreferrer"
                        variant="gradient"
                        color="dark"
                        fullWidth
                      >
                        Sign Up
                      </ArgonButton>
                    ) : (
                      <ArgonButton
                        component={Link}
                        href="https://www.hosthive.io/join-today"
                        rel="noreferrer"
                        variant="gradient"
                        color="dark"
                        fullWidth
                      >
                        Sign up to use HostHive
                      </ArgonButton>
                    )}
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            ) : null}
          </>
        )}
      </Card>
    </BasicLayout>
  );
}

export default Basic;
