/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import HostHiveDialog from "components/HostHiveDialog";
import InventoryItemDialog from "layouts/administration/inventory/components/InventoryItemDialog";
// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import InventoryDataCard from "layouts/management/components/InventoryDataCard";
import ViewInventoryItem from "layouts/administration/inventory/components/ViewInventoryItem";
import { Card, Grid, Paper, Tooltip, FormControl, Select } from "@mui/material";
function InventoryItem({ inventoryItem }) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const [inventoryItemOpen, setInventoryItemOpen] = useState(false);

  const handleViewInventoryItemDetails = (entityId) => {
    setInventoryItemOpen(true);
  };

  const renderMenu = (id) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem onClick={() => handleViewInventoryItemDetails(id)}>
        View
      </MenuItem>
      <MenuItem onClick={handleCloseMenu}>Edit</MenuItem>
      <MenuItem onClick={handleCloseMenu}>Delete</MenuItem>
    </Menu>
  );

  const amountColor = inventoryItem?.amount > 0 ? "text" : "error";
  const AmountColorTransform = () => {
    if (inventoryItem?.amount > 0) {
      return (
        <ArgonTypography variant="caption" fontWeight="bold" color="success">
          ${inventoryItem?.amount}
        </ArgonTypography>
      );
    } else {
      console.log("amount", inventoryItem?.amount);
      return (
        <ArgonTypography variant="caption" fontWeight="bold" color="error">
          ${inventoryItem?.amount}
        </ArgonTypography>
      );
    }
  };
  const formateDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        mt: 2,
        ml: 3,
        pl: 0.5,
      }}
    >
      <div
        component="li"
        style={{
          width: "100%",
          pr: 2,
          mb: 2,
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <div style={{ width: "100%", pl: 1, ml: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
              ml: 3,
              pl: 0.5,
              alignItems: { xs: "flex-start", sm: "center" },
              overflow: "hidden",
            }}
          >
            <HostHiveDialog
              open={inventoryItemOpen}
              onClose={() => {
                setInventoryItemOpen(false);
              }}
              title="Inventory Item Details"
              fullScreen={false}
              cancelButtonText="Close"
              submitButtonText="Save"
              includeSave={false}
              includeClose={false}
              includeDelete={false}
              dialogObjects={
                <ViewInventoryItem
                  inventoryItem={inventoryItem}
                  onClose={() => setInventoryItemOpen(false)}
                  //fetchInventoryItems={fetchInventoryItems}
                />
              }
            />
            {/* Date */}
            <div style={{ lineHeight: 1, mb: { xs: 1, sm: 0 }, flex: 1 }}>
              <ArgonTypography variant="caption" fontWeight="bold" color="text">
                {formateDate(inventoryItem?.created)}
              </ArgonTypography>
            </div>

            {/* Summary */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {inventoryItem?.itemName}
              </ArgonTypography>
            </div>
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {inventoryItem?.propertyId ? null : (
                  <Tooltip title="Company level asset">
                    <Icon fontSize="default">domain</Icon>
                  </Tooltip>
                )}
              </ArgonTypography>
            </div>

            {/* Description */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {inventoryItem?.currentCount}
              </ArgonTypography>
            </div>

            {/* Amount */}
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              {/* <AmountColorTransform amount={amount} /> */}
              <ArgonBadge
                color="success"
                badgeContent=">50%"
                variant="contained"
                container
              />
            </div>
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              {/* <AmountColorTransform amount={amount} /> */}
              <ArgonBadge
                variant="contained"
                color="success"
                badgeContent="Kitchen"
                container
              />
            </div>
            {renderMenu(inventoryItem?.id)}
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </div>
          </div>
        </div>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

// Setting default values for the props of Todo
InventoryItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
InventoryItem.propTypes = {
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  currentCount: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default InventoryItem;
