import { generateColumns } from "layouts/administration/customerAddressBook/components/contactColumns";

import { useState, useEffect } from "react";
import FinanaceReport from "layouts/administration/accounting/components/FinanaceReport";
import useMediaQuery from "@mui/material/useMediaQuery";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import CustomerSelection from "layouts/management/serviceInvoicing/components/CreateInvoiceWizard/components/CustomerSelection";
import ArgonDatePicker from "components/ArgonDatePicker";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import HostHiveDataTable from "components/HostHiveDataTable";

import AccountingApi from "api/accountingApi";
import ContactsApi from "api/companyAddressBookApi";

import HostHiveLoading from "components/HostHiveLoading";

import { useTranslation } from "react-i18next";
import { set } from "lodash";
import HostHiveDialog from "components/HostHiveDialog";
import ContactItem from "layouts/administration/customerAddressBook/components/ContactItem/index.js";
function Contacts({}) {
  const { t } = useTranslation();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const contactsApi = ContactsApi();
  const [contactDialogOpen, setContactDialogOpen] = useState(false);
  const [fetchContacts, setFetchContacts] = useState(false);
  const triggerFetch = () => setFetchContacts(!fetchContacts);
  const contactColumns = generateColumns({
    triggerFetch,
  });
  const [contactTableData, setContactTableData] = useState({
    columns: contactColumns,
    rows: [
      {
        id: "",
        summary: "",
        description: "",
        created: "",
        amount: "",
        companyId: "",
      },
    ],
  });

  const [loadingContacts, setLoadingContacts] = useState(false);
  useEffect(() => {
    async function fetchContacts() {
      setLoadingContacts(true);
      //   setLoadingPendingApprovalSize(true);
      try {
        let params = {};
        const response = await contactsApi.getContacts(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data");

          setContactTableData({
            columns: contactTableData?.columns,
            rows: response?.data,
          });
        } else {
          console.log("Error getting contacts");
        }
      } catch (error) {
        console.log("Error getting contacts", error);
      }
      setLoadingContacts(false);
    }
    fetchContacts();
  }, [fetchContacts]);
  const [contactPayload, setContactPayload] = useState({});
  const handleSaveContact = async () => {
    console.log("contactPayload", contactPayload);
    try {
      // add company id to contact payload
      contactPayload.companyId = company.id;
      const response = await contactsApi.createContact(contactPayload);
      if (response.status >= 200 && response.status < 300) {
        console.log("Contact created");
        setContactDialogOpen(false);
        triggerFetch();
      } else {
        console.log("Error creating contact");
      }
    } catch (error) {
      console.log("Error creating contact", error);
    }
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <HostHiveDialog
            open={contactDialogOpen}
            onClose={() => setContactDialogOpen(false)}
            onSave={handleSaveContact}
            includeSave={true}
            includeClose={true}
            title="New Contact"
            fullScreen={false}
            maxWidth="sm"
            cancelButtonText="Cancel"
            submitButtonText="Save"
            dialogObjects={
              <>
                <ContactItem
                  contactPayload={contactPayload}
                  setContactPayload={setContactPayload}
                />
              </>
            }
          />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ArgonBox position="relative">
                <Card
                  sx={{
                    py: 2,
                    px: 2,
                    boxShadow: ({ boxShadows: { md } }) => md,
                    justifyContent: { xs: "center" },
                  }}
                >
                  <Grid container spacing={3} alignItems="center">
                    <Grid item>
                      <ArgonBox height="100%" mt={0.5} lineHeight={1}>
                        <ArgonTypography variant="h5" fontWeight="medium">
                          Contacts
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          color="text"
                          fontWeight="medium"
                        >
                          A list of all contacts in your address book.
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                  </Grid>
                </Card>
              </ArgonBox>
            </Grid>
          </Grid>
        </ArgonBox>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <ArgonBox py={1}>
                {loadingContacts ? (
                  <HostHiveLoading message={"Loading Contacts"} elipse={true} />
                ) : (
                  <HostHiveDataTable
                    table={contactTableData}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    canSearch
                    isSorted={true}
                    noEndBorder
                    newEntryAction={() => setContactDialogOpen(true)}
                    newEntryText="New Contact"
                    newEntryColor="info"
                    newEntryVariant="outlined"
                    exportToCsv={() => console.log("export")}
                    importFromFile={() => console.log("import")}
                    // setProperty={setProperty}
                    // property={property}
                  />
                )}
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Contacts;
