/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import { useState, useEffect, useContext, useMemo } from "react";
import HostHiveDialog from "components/HostHiveDialog";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import Divider from "@mui/material/Divider";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonInput from "components/ArgonInput";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import { Link, useParams } from "react-router-dom";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";

import Card from "@mui/material/Card";
import TasksApi from "api/propertyTasksApi";

import HostHiveSnackbar from "components/HostHiveSnackbar";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import PropertiesApi from "api/propertiesApi";
// Data
import { columns } from "layouts/administration/taskCenter/components/taskCenterColumns/";
import { useTranslation } from "react-i18next";
import TaskCenter from "layouts/administration/taskCenter/TaskCenter";
function TasksCenterPage({ createTask = false }) {
  const { t } = useTranslation("common");
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const tasksApi = TasksApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox pt={3}>
        <ArgonBox mb={3}>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} xl={7}></Grid>
          </Grid> */}
          <TaskCenter
            companyId={company?.id}
            propertyId={null}
            createTask={createTask}
          />
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default TasksCenterPage;
