import { Card } from "@mui/material";

import ArgonBox from "components/ArgonBox";
import HostHiveLoading from "components/HostHiveLoading";

function SavingDataCard({ loadingText }) {
  return (
    <>
      <Card>
        <ArgonBox p={2}>
          <HostHiveLoading message={loadingText} elipse={true} />
        </ArgonBox>
      </Card>
    </>
  );
}

export default SavingDataCard;
