// API client for /tasks endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function TimeTrackingApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized(
    "timeTracking",
    role,
    subscriptionTier
  );

  if (!isAuthorized) {
    return null;
  }

  const getLoggedTimes = (queryParams) => {
    return axiosPrivate.get(`/time-tracking`, { params: queryParams });
  };

  const createLogTimeEntry = (taskData) => {
    return axiosPrivate.post(`/time-tracking`, taskData);
  };

  const getLoggedTime = (taskId) => {
    return axiosPrivate.get(`/time-tracking/${taskId}`);
  };

  const getTotalTimeData = (queryParams) => {
    return axiosPrivate.get(`/time-tracking/totalTime/all`, {
      params: queryParams,
    });
  };

  return {
    getLoggedTime,
    createLogTimeEntry,
    getLoggedTimes,
    getTotalTimeData,
  };
}

export default TimeTrackingApi;
