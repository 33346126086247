/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useMemo, useEffect, useState, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link, useNavigate } from "react-router-dom";
function ConfirmationLists({
  title,
  date,
  rankings,
  handleConfirmBooking,
  handleDeclineBooking,
}) {
  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false);
  const limitedRankings = showAll ? rankings : rankings.slice(0, 4);
  const renderRankings = limitedRankings.map(
    (
      {
        color,
        icon,
        name,
        id,
        description,
        value,
        propertyName,
        servicePropertyInfo,
        serviceNameInfo,
        serviceScheduleDate,
        serviceCompanyInfo,
      },
      key
    ) => (
      <ArgonBox key={id} component="li" pt={1} pr={2}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArgonBox display="flex" alignItems="center">
            <ArgonBox display="flex" flexDirection="column">
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                gutterBottom
              >
                {serviceNameInfo?.name}
              </ArgonTypography>
              <ArgonTypography variant="caption" color="text">
                {serviceScheduleDate}
              </ArgonTypography>
              <ArgonTypography variant="caption" color="text">
                {serviceCompanyInfo?.name} : {servicePropertyInfo?.name}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox display="flex">
            <ArgonBox mr={2}>
              <ArgonButton
                onClick={() =>
                  handleDeclineBooking(id, {
                    name: serviceNameInfo?.name,
                    serviceScheduleDate: serviceScheduleDate,
                    serviceCompanyInfo: serviceCompanyInfo?.name,
                    servicePropertyInfo: servicePropertyInfo?.name,
                  })
                }
                variant="outlined"
                color="error"
                size="small"
                iconOnly
                circular
                sx={({ functions: { pxToRem } }) => ({
                  width: pxToRem(34),
                  minWidth: pxToRem(34),
                  height: pxToRem(34),
                  minHeight: pxToRem(34),
                })}
              >
                <CloseIcon>{icon}</CloseIcon>
              </ArgonButton>
            </ArgonBox>
            <ArgonBox>
              <ArgonButton
                onClick={() => handleConfirmBooking(id)}
                variant="outlined"
                color="success"
                size="small"
                iconOnly
                circular
                sx={({ functions: { pxToRem } }) => ({
                  width: pxToRem(34),
                  minWidth: pxToRem(34),
                  height: pxToRem(34),
                  minHeight: pxToRem(34),
                })}
              >
                <DoneIcon>{icon}</DoneIcon>
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        {key === rankings.length - 1 ? null : (
          <Divider
            sx={{
              mt: 2,
              mb: 1,
            }}
          />
        )}
      </ArgonBox>
    )
  );

  const handleViewMore = () => {
    navigate(`/properties`);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <ArgonTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          {title}
        </ArgonTypography>
        {/* <ArgonTypography
          variant="button"
          color="text"
          fontWeight="regular"
          sx={{ display: "flex" }}
        >
          <Icon
            color="inherit"
            fontSize="small"
            sx={{
              mr: 0.75,
              mt: -0.125,
            }}
          >
            date_range
          </Icon>
          {date}
        </ArgonTypography> */}
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {renderRankings}
        </ArgonBox>
        {!showAll && rankings.length > 4 && (
          <ArgonBox display="flex" justifyContent="center" mt={2}>
            <ArgonButton
              onClick={handleViewMore}
              color="primary"
              variant="outlined"
            >
              View More
            </ArgonButton>
          </ArgonBox>
        )}
      </ArgonBox>
    </Card>
  );
}

export default ConfirmationLists;
