/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Card from "@mui/material/Card";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import IconButton from "@mui/material/IconButton";
import HowToVideoButton from "layouts/management/components/VideoIconButton";

import { Grid } from "@mui/material";
// @mui material components
import Tooltip from "@mui/material/Tooltip";
import HostHiveDialog from "components/HostHiveDialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Icon from "@mui/material/Icon";
import PropertySelect from "layouts/management/components/PropertySelect";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonPagination from "components/ArgonPagination";
import ArgonButton from "components/ArgonButton";
// Argon Dashboard 2 PRO MUI example components
import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import { useTranslation } from "react-i18next";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { set } from "lodash";
import ArgonDatePicker from "components/ArgonDatePicker";
function HostHiveDataTable({
  entriesPerPage,
  canSearch = true,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  header = null,
  newEntryAction = null,
  newEntryText = null,
  exportToCsv = null,
  navigateTo = null,
  importFromFile = null,
  activeOnly = null,
  setActiveOnly = null,
  newEntryColor = "info",
  newEntryVariant = "gradient",
  setProperty = null,
  property = null,
  setEndDate = null,
  setStartDate = null,
  stringDateRange = null,
  newEntryVideoHelp = null,
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries
    : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <ArgonPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </ArgonPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const [propertyFilterOpen, setSetPropertyFilterOpen] = useState(false);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [startFilterStage, setStartFilterStage] = useState(null);
  const [endFilterStage, setEndFilterStage] = useState(null);
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {setStartDate && setEndDate ? (
        <MenuItem onClick={() => setDateFilterOpen(true)}>
          Adjust Dates
        </MenuItem>
      ) : null}
      {importFromFile ? <MenuItem>{t("buttons.import")}</MenuItem> : null}
      {exportToCsv ? <MenuItem>{t("buttons.export")}</MenuItem> : null}

      {setProperty ? (
        <MenuItem onClick={() => setSetPropertyFilterOpen(true)}>
          {t("buttons.filterByProperty")}
        </MenuItem>
      ) : null}
    </Menu>
  );

  const handleChangeFilterDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month}-${day}`;
    if (setStartDate && setEndDate) {
      if (param === "startDate") {
        setStartFilterStage(useDate);
      } else {
        setEndFilterStage(useDate);
      }
    }
  };

  const handleApplyFilterDate = () => {
    if (setStartDate && setEndDate) {
      setStartDate(startFilterStage);
      setEndDate(endFilterStage);
      setDateFilterOpen(false);
    }
  };

  const handleActiveOnly = () => {
    if ((activeOnly, setActiveOnly)) {
      setActiveOnly(!activeOnly);
    } else {
      console.log("activeOnly and setActiveOnly are not defined");
    }
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <ArgonBox p={3} borderBottom="1px solid #E0E0E0" mb={3}>
          <HostHiveDialog
            open={propertyFilterOpen}
            onClose={() => setSetPropertyFilterOpen(false)}
            onSave
            fullWidth={true}
            includeClose={true}
            includeSave={true}
            maxWidth="md"
            title={t("properties.selectProperty")}
            fullScreen={false}
            cancelButtonText={t("buttons.cancel")}
            submitButtonText={t("buttons.apply")}
            dialogObjects={
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <PropertySelect
                    property={property}
                    setProperty={setProperty}
                    filterSelection={true}
                  />
                </Grid>
              </Grid>
            }
          />
          <HostHiveDialog
            open={dateFilterOpen}
            onClose={() => setDateFilterOpen(false)}
            onSave={handleApplyFilterDate}
            fullWidth={true}
            includeClose={true}
            includeSave={true}
            maxWidth="md"
            title="Adjust Dates"
            fullScreen={false}
            cancelButtonText={t("buttons.cancel")}
            submitButtonText={t("buttons.apply")}
            dialogObjects={
              // <Grid container spacing={2} alignItems="center">
              //   <Grid item xs={12}>
              //     <PropertySelect
              //       property={property}
              //       setProperty={setProperty}
              //       filterSelection={true}
              //     />
              //   </Grid>
              // </Grid>
              <Grid container alignItems="center">
                <Grid item xs={7}>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      From Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "startDate")}
                    />
                  </ArgonBox>
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                      textTransform="capitalize"
                    >
                      To Date
                    </ArgonTypography>
                    <ArgonDatePicker
                      input={{ placeholder: "Select a date" }}
                      onChange={(e) => handleChangeFilterDate(e, "endDate")}
                    />
                  </ArgonBox>
                </Grid>
              </Grid>
            }
          />
          <Grid container spacing={2} alignItems="center">
            {header && (
              <Grid item xs={12}>
                <ArgonBox display="flex">
                  <ArgonTypography variant="h5" fontWeight="medium">
                    {header}
                  </ArgonTypography>

                  {navigateTo ? (
                    <Tooltip title={navigateTo?.title} placement="top" arrow>
                      <IconButton onClick={() => navigate(navigateTo?.url)}>
                        <Icon sx={{ fontWeight: "bold" }}>open_in_new</Icon>
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </ArgonBox>
              </Grid>
            )}
            {stringDateRange ? (
              <Grid item xs={12}>
                <ArgonBox width="100%" textAlign="right" lineHeight={1} pb={2}>
                  <ArgonTypography variant="caption" color="secondary">
                    {stringDateRange?.startDate
                      ? stringDateRange?.startDate
                      : null}{" "}
                    -{" "}
                    {stringDateRange?.endDate ? stringDateRange?.endDate : null}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            ) : null}
            {/* Search Input */}
            <Grid item xs={12} sm={4}>
              <ArgonInput
                placeholder={`${t("generalWords.search")}...`}
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>

            {/* Entries Per Page Selector */}
            <Grid item xs={6} sm={3}>
              <ArgonBox display="flex" alignItems="center">
                <ArgonSelect
                  defaultValue={{
                    value: defaultValue,
                    label: defaultValue,
                  }}
                  options={entries.map((entry) => ({
                    value: entry,
                    label: entry,
                  }))}
                  onChange={setEntriesPerPage}
                  size="small"
                  variant="outlined"
                  fullWidth
                />
                <ArgonTypography variant="caption" color="secondary" ml={1}>
                  {t("components.dataTable.entriesPerPage")}
                </ArgonTypography>
              </ArgonBox>
            </Grid>

            {setActiveOnly ? (
              <Grid item xs={6} sm={3}>
                <ArgonBox display="flex" alignItems="center">
                  <Checkbox onChange={handleActiveOnly} checked={activeOnly} />
                  <ArgonTypography variant="caption" color="secondary" ml={1}>
                    Active Only
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            ) : null}

            {/* New Entry Button */}
            <Grid item xs={6} sm={2}>
              {newEntryAction ? (
                <>
                  <ArgonBox display="flex">
                    <ArgonBox>
                      <Tooltip title="" placement="left" arrow>
                        <ArgonButton
                          variant={newEntryVariant}
                          color={newEntryColor}
                          size="small"
                          onClick={newEntryAction}
                        >
                          {newEntryText}
                        </ArgonButton>
                      </Tooltip>
                    </ArgonBox>
                    {newEntryVideoHelp && (
                      <ArgonBox pl={2}>
                        <HowToVideoButton videoId={newEntryVideoHelp} />
                      </ArgonBox>
                    )}
                  </ArgonBox>
                </>
              ) : null}
            </Grid>

            {renderMenu()}
            {/* Menu Icon */}

            <Grid item xs={6} sm={1}>
              {exportToCsv || importFromFile || setProperty ? (
                <ArgonTypography
                  color="secondary"
                  onClick={handleOpenMenu}
                  sx={{
                    alignSelf: "flex-start",
                    py: 1.25,
                    cursor: "pointer",
                  }}
                >
                  <Icon fontSize="default">more_vert</Icon>
                </ArgonTypography>
              ) : null}
            </Grid>
          </Grid>
        </ArgonBox>
      ) : null}

      <Table {...getTableProps()}>
        <ArgonBox component="thead">
          {headerGroups.map((headerGroup, key) => (
            <TableRow key={key} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <DataTableHeadCell
                  key={index}
                  {...column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  )}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </ArgonBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow key={key} {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                  <DataTableBodyCell
                    key={index}
                    noBorder={noEndBorder && rows.length - 1 === key}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <ArgonBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <ArgonBox mb={{ xs: 3, sm: 0 }}>
            <ArgonTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              {t("adverbs.showing")} {entriesStart} to {entriesEnd} of{" "}
              {rows.length} {t("components.dataTable.entries")}
            </ArgonTypography>
          </ArgonBox>
        )}
        {pageOptions.length > 1 && (
          <ArgonPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <ArgonPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </ArgonPagination>
            )}
            {renderPagination.length > 6 ? (
              <ArgonBox width="5rem" mx={1}>
                <ArgonInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </ArgonBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <ArgonPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </ArgonPagination>
            )}
          </ArgonPagination>
        )}
      </ArgonBox>
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
HostHiveDataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
};

// Typechecking props for the DataTable
HostHiveDataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default HostHiveDataTable;
