import { UserAuth } from "context/AuthContext";
import { RouteProtection } from "services/routeProtectionHelper";

export const isRouteAuthorized = (lookupKey, role, subscriptionTier) => {
  let useSubscriptionTier = null;
  if (!subscriptionTier) {
    useSubscriptionTier = "free";
  } else {
    useSubscriptionTier = subscriptionTier;
  }
  const protector = RouteProtection(lookupKey);
  let notEligibleSubscriptionTiers = protector?.notEligibleSubscriptionTiers;
  let requiredRoles = protector?.permittedRoles;

  let hasPermittedSubscriptionTier = false;
  let hasPermittedRole = false;

  if (requiredRoles && requiredRoles.length > 0) {
    if (requiredRoles[0] === "all" || requiredRoles.includes(role)) {
      // console.log("Has role", requiredRoles, role);
      hasPermittedRole = true;
    } else {
      hasPermittedRole = false;
    }
  } else {
    // Handle the case where requiredRoles is empty or undefined
    console.log("No role requirements specified");
    hasPermittedRole = false;
  }

  if (
    notEligibleSubscriptionTiers &&
    notEligibleSubscriptionTiers.includes(useSubscriptionTier)
  ) {
    hasPermittedSubscriptionTier = false;
  } else if (
    useSubscriptionTier === null ||
    useSubscriptionTier === undefined
  ) {
    hasPermittedSubscriptionTier = false;
  } else {
    hasPermittedSubscriptionTier = true;
  }

  if (hasPermittedRole && hasPermittedSubscriptionTier) {
    // console.log subscriptionTier type

    return true;
  } else {
    return false;
  }
};
