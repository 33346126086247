// Generate a checksum for given data using Web Crypto API
export async function generateChecksum(data) {
  const encoder = new TextEncoder();
  const dataUint8 = encoder.encode(data);
  const hashBuffer = await crypto.subtle.digest("SHA-256", dataUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((byte) => byte.toString(16).padStart(2, "0")).join("");
}

// Verify data against a checksum using Web Crypto API
export function verifyChecksum(data, checksum) {
  return generateChecksum(data) === checksum;
}
