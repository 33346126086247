/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";
import BookingsApi from "api/bookingsApi";
import { useState, useEffect } from "react";
import { UserAuth } from "context/AuthContext";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import Tooltip from "@mui/material/Tooltip";

import { set } from "lodash";
import { Grid } from "@mui/material";

function UpcomingAssignmentItem({ booking, handleViewClicked }) {
  return (
    <>
      <ArgonBox
        key={booking?.id}
        component="li"
        display="flex"
        alignItems="center"
        py={1}
        mb={1}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
            >
              <ArgonBox display="flex" justifyContent="flex-end">
                <ArgonBox>
                  <ArgonTypography variant="button" fontWeight="medium">
                    {booking?.title}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox pl={2}>
                  <Tooltip title="Service status" placement="top" arrow>
                    <ArgonBadge
                      variant="contained"
                      color="success"
                      badgeContent={booking?.status}
                    />
                  </Tooltip>
                </ArgonBox>
              </ArgonBox>

              <ArgonTypography variant="caption" color="text">
                {booking?.serviceScheduleDate}
              </ArgonTypography>

              {/* <ArgonTypography variant="caption" color="text">
            {propertyInfo?.propertyAddress?.address1}
          </ArgonTypography> */}
              <ArgonTypography variant="caption" color="text">
                {booking?.propertyAddress?.city},{" "}
                {booking?.propertyAddress?.state},{" "}
                {booking?.propertyAddress?.zipCode}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <ArgonBox ml="auto" textAlign="center">
              <ArgonButton
                onClick={handleViewClicked}
                variant="text"
                color="info"
              >
                View
              </ArgonButton>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </>
  );
}

// Typechecking props for the UpcomingAssignments
UpcomingAssignmentItem.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UpcomingAssignmentItem;
