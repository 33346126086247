import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import convertToOptions from "services/convertooptions";
import ArgonAlert from "components/ArgonAlert";
import { showDeleteConfirmation } from "services/confirmDelete";
import HostHiveCaption from "components/HostHiveCaption";
import useMediaQuery from "@mui/material/useMediaQuery";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import { check } from "prettier";
import HostHiveAlert from "components/HostHiveAlert";
import GuestBookApi from "api/guestBookApi";
import PropertyIntegrationSchema from "schemas/propertyIntegrationSettingsSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import HostHiveDialog from "components/HostHiveDialog";
import BookingsApi from "api/bookingsApi";
import CompaniesApi from "api/companyApi";
import ChecklistsApi from "api/checkListsApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import ApplicationApi from "api/applicationApi";
import ContactSupportApi from "api/contactSupportApi";
import { set } from "lodash";

function NewIntegrationItem({
  propertyId,
  companyId,
  triggerIntegrationFetch,
  setTriggerIntegrationFetch,
  setAvailableIntegrations,
  availableIntegrations,
  newIntegrationModalVisible,
  setNewIntegrationModalVisible,
}) {
  const settingsApi = SettingsApi();

  const { t } = useTranslation("common");
  const [integrationModalVisible, setIntegrationModalVisible] = useState(false);
  const guestBookApi = GuestBookApi();
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedIntegrationType, setSelectedIntegrationType] = useState({
    id: "",
    name: "",
  });

  const handleCloseIntegrationModal = () => {
    setIntegrationModalVisible(false);
  };
  const handdleIntegrationModalSelected = (integration) => {};

  const handleIntegrationModalSave = async () => {
    let payload = {
      propertyId: propertyId,
      companyId: companyId,
      integrationType: selectedIntegrationType.id,
      integrationUrl: newIntegrationUrl,
    };

    console.log("Integration Modal Save", payload);

    let validUrl = false;
    validUrl = await handleTestIcalUrl(true);
    console.log("validUrl", validUrl);
    if (!validUrl) {
      // setIsSaving(false);
      return;
    }

    try {
      await settingsApi.createIntegration(propertyId, payload);
      setTriggerIntegrationFetch(!triggerIntegrationFetch);
      setNewIntegrationUrl("");
      setSelectedIntegrationType(null);
      setNewIntegrationModalVisible(false);
    } catch (error) {
      console.log(error);
    }
  };

  const [newIntegrationUrl, setNewIntegrationUrl] = useState("");

  const handleCloseNewIntegrationModal = () => {
    setNewIntegrationUrl("");
    setSelectedIntegrationType(null);
    setNewIntegrationModalVisible(false);
  };

  // Integration types are availableIntegrationTypes where disabled is false
  const integrationTypes = availableIntegrations.filter(
    (integration) => integration.disabled === false
  );

  // Existing integration types are availableIntegrationTypes where disabled is true
  const existingIntegrationTypes = availableIntegrations.filter(
    (integration) => integration.disabled === true
  );
  const [requestNewIntegrationSupported, setRequestNewIntegrationSupported] =
    useState(false);
  useEffect(() => {
    console.log(integrationTypes);

    if (newIntegrationUrl.length > 0) {
      // Check if the new integration URL matches any of the integration types
      const matchedIntegration = integrationTypes.find(
        (integration) => newIntegrationUrl.includes(integration.urlIdentifier) // Assuming each integration type has a unique URL identifier
      );

      const existingIntegration = existingIntegrationTypes.find(
        (integration) => newIntegrationUrl.includes(integration.urlIdentifier) // Assuming each integration type has a unique URL identifier
      );

      if (matchedIntegration) {
        setRequestNewIntegrationSupported(false);
        setSelectedIntegrationType(matchedIntegration);
      } else if (existingIntegration) {
        setRequestNewIntegrationSupported(false);
        setSelectedIntegrationType({
          id: null,
          name: "Unavailable - Integration Type is already in use.",
        });
      } else {
        setRequestNewIntegrationSupported(true);
        setSelectedIntegrationType({
          id: null,
          name: "", //"Request new Integration",
        });
      }
    }
  }, [
    newIntegrationUrl,
    integrationTypes,
    existingIntegrationTypes,
    setSelectedIntegrationType,
  ]);
  const [showTestUrlAlert, setShowTestUrlAlert] = useState(false);
  const [testUrlAlertMessage, setTestUrlAlertMessage] = useState(null);
  const [testUrlAlertColor, setTestUrlAlertColor] = useState("success");
  const [saving, setIsSaving] = useState(false);
  const handleTestIcalUrl = async (submission = false) => {
    setShowTestUrlAlert(false);
    setTestUrlAlertMessage(null);
    setTestUrlAlertColor("success");
    if (newIntegrationUrl === null || newIntegrationUrl === undefined) {
      setTestUrlAlertMessage(
        "Integration URL cannot be empty or uknown, please input a value and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
    }

    let integrationUrlData = {
      url: newIntegrationUrl,
    };
    try {
      const response = await guestBookApi.testIcalUrl(integrationUrlData);
      if (response.status >= 200 && response.status < 300) {
        if (submission === true) {
          return true;
        }
        setTestUrlAlertMessage("Integration URL is valid!");
        setTestUrlAlertColor("success");
        setShowTestUrlAlert(true);
      }
    } catch (error) {
      setTestUrlAlertMessage(
        "Integration URL is invalid, please check the URL and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
      return false;
    }
  };

  return (
    <>
      <HostHiveDialog
        includeDelete={false}
        includeClose={true}
        includeSave={true}
        open={newIntegrationModalVisible}
        onClose={() => {
          handleCloseNewIntegrationModal();
        }}
        onSave={handleIntegrationModalSave}
        title="Create New Integration"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        maxWidth="sm"
        dialogObjects={
          <>
            {showTestUrlAlert ? (
              <ArgonAlert
                color={testUrlAlertColor}
                dismissible
                onDismiss={() => {
                  setTestUrlAlertMessage(null);
                  setShowTestUrlAlert(false);
                }}
              >
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {testUrlAlertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <ArgonBox>
                    {/* <DisplayEntry
                    entryKey={selectedIntegration?.name}
                    // lookupKey="servicePartner"
                  /> */}
                  </ArgonBox>
                  {/* <ArgonBox>
                    <Switch />
                  </ArgonBox> */}
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonInput
                  fullWidth
                  value={newIntegrationUrl}
                  onChange={(e) => setNewIntegrationUrl(e.target.value)}
                  multiline

                  // disabled
                />
              </Grid>
              <Grid item xs={12}>
                <ArgonTypography variant="caption" fontWeight="bold">
                  {selectedIntegrationType?.name}
                </ArgonTypography>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <ArgonButton
                    //   disabled={settingsDisabled}
                    variant="gradient"
                    color="success"
                    onClick={handleTestIcalUrl}
                  >
                    {t("buttons.test")}
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
}
export default NewIntegrationItem;
