/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveDataTable from "components/HostHiveDataTable";
import { useState, useEffect, useContext } from "react";
import ArgonButton from "components/ArgonButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import { Link } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ivancik from "assets/images/ivancik.jpg";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import WeatherCard from "examples/Cards/WeatherCard";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import ReportsDoughnutChart from "examples/Charts/DoughnutCharts/ReportsDoughnutChart";
import ThinBarChart from "examples/Charts/BarCharts/ThinBarChart";
import ControllerCard from "examples/Cards/ControllerCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Inventory dashboard components
import Cameras from "layouts/dashboards/smart-home/components/Cameras";
import UsersTable from "layouts/management/team/components/UsersTable";
import TemperatureSlider from "layouts/dashboards/smart-home/components/TemperatureSlider";
import UserDialog from "layouts/management/users/new-user/userDialog";
// Data
import reportsDoughnutChartData from "layouts/dashboards/smart-home/data/reportsDoughnutChartData";
import thinBarChartData from "layouts/dashboards/smart-home/data/thinBarChartData";
import controllerCardIcons from "layouts/dashboards/smart-home/data/controllerCardIcons";
import { useParams } from "react-router-dom";
import { generateColumns } from "layouts/management/team/components/teamColumns";
// Images
import iconSunCloud from "assets/images/small-logos/icon-sun-cloud.png";
import HostHiveLoading from "components/HostHiveLoading";
import UsersApi from "api/usersApi";

import { UserAuth } from "context/AuthContext";
import { useTranslation } from "react-i18next";
function Team() {
  const { user } = UserAuth();
  const { t } = useTranslation("common");
  const usersApi = UsersApi();
  const [activeOnly, setActiveOnly] = useState(true);
  const [users, setUsers] = useState([]);
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const actionType = "users";
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [refreshList, setRefreshList] = useState(false);
  const handleRefreshLists = () => {
    console.log("Refresh list");
    setRefreshList(!refreshList);
  };
  const [teamTableData, setTeamTableData] = useState({
    columns: generateColumns({ handleRefreshLists }),
    rows: [
      {
        summary: "",
        description: "",
        status: "",
        ticketNumber: "",
        created: "",
        priority: "",
      },
    ],
  });

  useEffect(() => {
    async function fetchUsers() {
      try {
        let queryParams = {
          limit: 10,
          companyId: company.id,
        };

        if (activeOnly) {
          queryParams.status = "active";
        }

        const response = await usersApi.getUsers(queryParams);

        const currentUserId = user?.uid;

        // Filter out currentUserId from response.data
        const filteredUsers = response.data.filter(
          (user) => user.id !== currentUserId
        );

        setUsers(filteredUsers);
        setTeamTableData({
          columns: teamTableData?.columns,
          rows: filteredUsers,
        });
        setIsUsersLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchUsers();
  }, [refreshList, activeOnly]);
  const [userDiaglogOpen, setUserDiaglogOpen] = useState(false);

  const [usersTeam, setUserTeam] = useState({});
  const handleSubmitClicked = async () => {
    console.log("Submit clicked");
    handleCancelClicked();
  };
  const handleCancelClicked = () => {
    setUserDiaglogOpen(false);
    setUserTeam({});
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {renderSuccessSB}
      <HostHiveDialog
        open={userDiaglogOpen}
        includeClose={false}
        includeSave={false}
        title={`${t("generalWords.create")} ${t(
          "components.users.team.newUser"
        )}`}
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        maxWidth="sm"
        dialogObjects={
          <UserDialog
            setRefreshList={handleRefreshLists}
            handleClose={handleCancelClicked}
            setUser={setUserTeam}
            handleSubmitClicked={handleSubmitClicked}
            isCreation={true}
            snackBarOpen={openSuccessSB}
          />
        }
      />

      <ArgonBox pt={3}>
        <ArgonBox mb={3}>
          <Card>
            {/* <ArgonBox
              p={3}
              lineHeight={1}
              display="flex"
              justifyContent="space-between"
            >
              <ArgonBox>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Team Members
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox>
                <Stack spacing={1} direction="row">
                  <ArgonButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => setUserDiaglogOpen(true)}
                  >
                    + New User
                  </ArgonButton>

                  <ArgonButton variant="outlined" color="info" size="small">
                    Export
                  </ArgonButton>
                </Stack>
              </ArgonBox>
            </ArgonBox> */}

            {isUsersLoading ? (
              <HostHiveLoading />
            ) : (
              <Grid container spacing={3} mb={3}>
                <Grid item xs={12}>
                  {/* {renderTimelineItems} */}
                  <>
                    <HostHiveDataTable
                      header={t("components.users.team.title")}
                      table={teamTableData}
                      entriesPerPage={false}
                      canSearch
                      newEntryAction={() => setUserDiaglogOpen(true)}
                      newEntryText={t("components.users.team.newUser")}
                      importButton={() => console.log("import")}
                      exportToCsv={() => console.log("export")}
                      activeOnly={activeOnly}
                      setActiveOnly={setActiveOnly}
                    />
                  </>
                </Grid>
              </Grid>
            )}
          </Card>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox my={6} width="100%">
        <Divider />
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Team;
