/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Fade from "@mui/material/Fade";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Custom styles for the ArgonAlert
import ArgonAlertRoot from "components/ArgonAlert/ArgonAlertRoot";
import ArgonAlertCloseIcon from "components/ArgonAlert/ArgonAlertCloseIcon";
import ArgonBadge from "components/ArgonBadge";
function ArgonAlert({
  color,
  dismissible,
  children,
  redirectUrl = null,
  onDismiss,
  ...rest
}) {
  const [alertStatus, setAlertStatus] = useState("mount");

  const handleAlertStatus = () => {
    setAlertStatus("fadeOut");
    onDismiss(); // Invoke the passed in function
  };

  const handleNavigate = () => {
    console.log("Navigating to the alert");
  };

  // The base template for the alert
  const alertTemplate = (mount = true) => (
    <Fade in={mount} timeout={300}>
      <ArgonAlertRoot ownerState={{ color }} {...rest}>
        <ArgonBox display="flex" alignItems="center" color="white">
          {children}
        </ArgonBox>
        {redirectUrl ? (
          <IconButton>
            <Tooltip title="View" placement="top" onClick={handleNavigate}>
              {/* <Icon color="info"> add</Icon> */}
              <ArgonBadge
                color="info"
                size="xs"
                badgeContent="View"
                container
                onclick={handleNavigate}
              />
            </Tooltip>
          </IconButton>
        ) : null}
        {dismissible ? (
          <ArgonAlertCloseIcon onClick={mount ? handleAlertStatus : null}>
            &times;
          </ArgonAlertCloseIcon>
        ) : null}
      </ArgonAlertRoot>
    </Fade>
  );

  switch (true) {
    case alertStatus === "mount":
      return alertTemplate();
    case alertStatus === "fadeOut":
      setTimeout(() => setAlertStatus("unmount"), 400);
      return alertTemplate(false);
    default:
      alertTemplate();
      break;
  }

  return null;
}

// Setting default values for the props of ArgonAlert
ArgonAlert.defaultProps = {
  color: "info",
  dismissible: false,
  onDismiss: () => {},
};

// Typechecking props of the ArgonAlert
ArgonAlert.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  dismissible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onDismiss: PropTypes.func,
};

export default ArgonAlert;
