/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// General page components
import PaymentItem from "layouts/management/serviceInvoicing/components/RecentPaymentItem";

function RecentServicePayments({ invoices, setInvoices }) {
  return (
    <Card>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <ArgonTypography variant="h6" fontWeight="medium">
          Recent Service Payments
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text">
          23 - 30 March 2020
        </ArgonTypography>
      </ArgonBox>
      <Divider />
      <ArgonBox pb={2} px={2}>
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
        >
          <Grid container component="li" style={{ listStyle: "none" }}>
            <Grid item xs={12} style={{ paddingLeft: 8, marginLeft: 16 }}>
              <Grid
                container
                alignItems="center"
                style={{ marginTop: 16, paddingLeft: 4 }}
              >
                {/* Customer Info */}
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Invoice Number
                  </ArgonTypography>
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="text"
                  >
                    {invoice?.customerInfo?.name}
                  </ArgonTypography> */}
                </Grid>
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Customer
                  </ArgonTypography>
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="text"
                  >
                    {invoice?.customerInfo?.name}
                  </ArgonTypography> */}
                </Grid>

                {/* Date */}
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Date
                  </ArgonTypography>
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color="text"
                  >
                    {convertTimeStamp(invoice?.created)}
                  </ArgonTypography> */}
                </Grid>

                {/* Amount */}
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Amount
                  </ArgonTypography>
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color={invoice?.payoutAmount > 0 ? "success" : "error"}
                  >
                    ${invoice?.amount}
                  </ArgonTypography> */}
                </Grid>

                {/* Payout */}
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Payout
                  </ArgonTypography>
                  {/* <ArgonTypography
                    variant="caption"
                    fontWeight="bold"
                    color={invoice?.payoutAmount > 0 ? "success" : "error"}
                  >
                    ${invoice?.payoutAmount}
                  </ArgonTypography> */}
                </Grid>

                {/* Status */}
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Status
                  </ArgonTypography>
                  {/* <ArgonBadge
                    color={invoice?.statusInfo?.color}
                    badgeContent={invoice?.statusInfo?.status}
                    container
                  /> */}
                </Grid>
                <Grid item xs={12} sm={1.7}>
                  <ArgonTypography
                    variant="caption"
                    fontWeight="medium"
                    color="secondary"
                  >
                    Action
                  </ArgonTypography>
                  {/* <ArgonBadge
                    color={invoice?.statusInfo?.color}
                    badgeContent={invoice?.statusInfo?.status}
                    container
                  /> */}
                </Grid>
              </Grid>
            </Grid>
            {/* Uncomment below line if you need a divider */}
            {/* <Divider /> */}
          </Grid>
          {/* Loop through invoices */}
          {invoices?.map((invoice) => {
            return <PaymentItem invoice={invoice} />;
          })}
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default RecentServicePayments;
