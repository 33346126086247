// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonProgress from "components/ArgonProgress";

function Completion({ value, color }) {
  return (
    <ArgonBox display="flex" alignItems="center">
      <ArgonTypography variant="caption" color="text">
        {value}%
      </ArgonTypography>
      <ArgonBox width="8rem" ml={1}>
        <ArgonProgress value={value} color={color} label={false} />
      </ArgonBox>
    </ArgonBox>
  );
}

export default Completion;
