/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import CircularProgress from "@mui/material/CircularProgress";
const HostHiveLoading = ({ message, elipse = false }) => {
  return (
    <>
      <ArgonBox display="flex">
        <ArgonTypography variant="caption">
          {message}
          {elipse ? "..." : null}
        </ArgonTypography>
        <ArgonBox>
          <ArgonBox
            component="i"
            display="inline-block"
            borderRadius="50%"
            mr={1}
          />
          <CircularProgress />
        </ArgonBox>
      </ArgonBox>
    </>
  );
};

export default HostHiveLoading;
