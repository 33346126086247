import * as Yup from "yup";

const SupportTicketSchema = Yup.object().shape({
  propertyId: Yup.string().nullable(true),
  propertyInfo: Yup.object().nullable(true),
  companyId: Yup.string().required("Company is required"),
  summary: Yup.string().required("Summary is required"),
  description: Yup.string(),
});

export default SupportTicketSchema;
