/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
// import DefaultCell from "layouts/management/bookingReports/propertyBookingReportList/components/DefaultCell";
import DefaultCell from "layouts/administration/accounting/overview/components/DefaultCell";
//import StatusCell from "layouts/management/bookingReports/propertyBookingReportList/components/StatusCell";
import StatusCell from "layouts/administration/accounting/overview/components/StatusCell";
import { useNavigate } from "react-router-dom";
import ArgonSelect from "components/ArgonSelect";
import ArgonBox from "components/ArgonBox";
import ArgonBadge from "components/ArgonBadge";
import Icon from "@mui/material/Icon";
import ArgonTypography from "components/ArgonTypography";
import ViewDataItem from "../ViewDataItem";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";

import i18n from "i18next";

export const generateColumns = ({ triggerFetch = null }) => {
  if (triggerFetch) {
    console.log("triggerFetch exists");
  }
  const columns = [
    {
      Header: i18n.t("general.columns.summary", { ns: "common" }),
      accessor: "summary",
      Cell: ({ value, row }) => {
        const [name, data] = value;

        return (
          <ViewDataItem
            value={typeof value === "string" ? value : name}
            itemDetails={row?.original}
            id={row?.original?.id}
            triggerFetch={triggerFetch}
          />
        );
      },
    },

    {
      Header: i18n.t("general.columns.created", { ns: "common" }),
      accessor: "created",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell value={convertTimeStamp(value, "America/New_York")} />
        ) : null;
      },
    },

    {
      Header: i18n.t("accounting.general.amount", { ns: "common" }),
      accessor: "amount",
      Cell: ({ value }) => {
        return value ? (
          <DefaultCell
            prefix="$"
            value={value.toLocaleString()}
            color={value > 0 ? "success" : "error"}
          />
        ) : null;
      },
    },
    {
      Header: i18n.t("components.category.title", { ns: "common" }),
      accessor: "categoryInfo",
      Cell: ({ value }) => {
        return (
          <ArgonBadge
            variant="contained"
            container
            badgeContent={value?.categoryName}
            color={value?.categoryColor}
          />
        );
      },
    },
    {
      Header: i18n.t("properties.property", { ns: "common" }),
      accessor: "propertyName",
      Cell: ({ value }) => {
        return value ? <DefaultCell value={value} /> : null;
      },
    },
    // {
    //   Header: i18n.t("general.columns.details", { ns: "common" }),
    //   accessor: "details",
    //   Cell: ({ value }) => {
    //     return (
    //       <ArgonTypography
    //         color="secondary"
    //         //onClick={handleOpenMenu}
    //         sx={{
    //           ml: "auto",
    //           alignSelf: "flex-start",
    //           py: 1.25,
    //         }}
    //       >
    //         <Icon fontSize="default" sx={{ cursor: "pointer" }}>
    //           more_vert
    //         </Icon>
    //       </ArgonTypography>
    //     );
    //   },
    // },
  ];

  return columns;
};
