/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import CategoriesApi from "api/categoriesApi";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import PropertyContext from "context/PropertyContext";
import IconButton from "@mui/material/IconButton";
import ArgonSelect from "components/ArgonSelect";
import { useTranslation } from "react-i18next";
function CategoryDialog({
  companyId = null,
  onClose,
  handleCategorySelected,
  handleSelection = false,
  fetchCategories,
  snackBarOpen,
  categoryDetails,
  setCategoryDetails = null,
  categoryType = null,
  idEdit = false,
  existingColor = "info",
}) {
  const [newCategory, setNewCategory] = useState();
  const { t } = useTranslation("common");
  const [isEdit, setIsEdit] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    if (categoryDetails) {
      console.log(categoryDetails);
      setNewCategory(categoryDetails?.name);

      setIsEdit(true);
    }
  }, []);

  const categoryApi = CategoriesApi();
  const toggleLoading = () => setLoadingResponse((prevState) => !prevState);
  const [newCategoryType, setNewCategoryType] = useState(null);
  const [categoryColor, setCategoryColor] = useState(existingColor);
  const handleSubmitClicked = async () => {
    toggleLoading();

    const commonResponseTasks = async (response, actionVerb) => {
      if (response.status >= 200 && response.status < 300) {
        if (setCategoryDetails) {
          setCategoryDetails(response.data);
        }
        // setCompanyCategories((prevCategories) => [
        //   ...(Array.isArray(prevCategories) ? prevCategories : []),
        //   response.data,
        // ]);

        if (fetchCategories) {
          fetchCategories();
        }

        if (handleSelection) {
          handleCategorySelected({
            label: `${response.data.name}`, //response.data.name + " - $" + response.data.cost,
            value: response.data.id,
          });
        }
        if (snackBarOpen) {
          snackBarOpen(
            `${t("components.category.title")} ${actionVerb}`,
            `${t("generalWords.successfully")} ${actionVerb} ${t(
              "components.category.title"
            )} ${newCategory}`
          );
        }

        onClose();
      }
    };

    const createCategory = async () => {
      let typeCategory = null;
      if (categoryType === null) {
        typeCategory = newCategoryType?.value;
      } else {
        typeCategory = categoryType;
      }
      try {
        const categoryData = {
          companyId: company?.id,
          name: newCategory,
          type: typeCategory,
          status: "active",
          color: categoryColor,
        };

        const response = await categoryApi.createCategory(categoryData);
        await commonResponseTasks(response, "created");
      } catch (error) {
        console.error("Error creating category:", error);
        toggleLoading();
      }
    };

    const updateCategory = async () => {
      try {
        const categoryData = {
          ...categoryDetails,
          name: newCategory,
          type: categoryType,
          color: categoryColor,
        };

        const response = await categoryApi.updateCategory(
          categoryData.id,
          categoryData
        );
        await commonResponseTasks(response, "updated");
      } catch (error) {
        console.error("Error updating category:", error);
        toggleLoading();
      }
    };

    if (isEdit) {
      console.log("Editing category");
      await updateCategory();
    } else {
      console.log("Creating category:", newCategory);
      await createCategory();
    }
  };

  const [loadingResponse, setLoadingResponse] = useState(false);
  const sidenavColors = [
    "primary",
    "dark",
    "info",
    "success",
    "warning",
    "error",
  ];

  const categoryOptions = [
    {
      label: "Service Assignments",
      value: "serviceAssignments",
    },
    {
      label: "Inventory",
      value: "inventory",
    },
    {
      label: "Accounting",
      value: "accounting",
    },
    {
      label: "Property",
      value: "property",
    },
    {
      label: "Company",
      value: "company",
    },
    {
      label: "Task",
      value: "task",
    },
    {
      label: "Time Tracking",
      value: "timeTracking",
    },
  ];

  return (
    <>
      {loadingResponse ? (
        <HostHiveLoading
          message={`${
            isEdit ? t("adverbs.updating") : t("adverbs.addingNew")
          }${" "}
              ${categoryType} ${t("components.category.title")}`}
          elipse={true}
        />
      ) : (
        <>
          <ArgonBox p={2} lineHeight={1}>
            <ArgonBox mb={2}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {categoryType?.toUpperCase()}{" "}
                    {t("components.category.title")}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonInput
                  type="text"
                  value={newCategory}
                  onChange={(e) => setNewCategory(e.target.value)}
                />
              </ArgonBox>
              {categoryType ? null : (
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      {t("components.category.title")} {t("generalWords.type")}
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox display="flex" pl={0.5}>
                    <ArgonSelect
                      options={categoryOptions}
                      value={newCategoryType}
                      onChange={(e) => setNewCategoryType(e)}
                    />
                  </ArgonBox>
                </ArgonBox>
              )}

              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("colors.selection.colorsSelection")}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox display="flex" pl={0.5}>
                  <ArgonBox display="flex" pl={0.5}>
                    {sidenavColors.map((color) => (
                      <IconButton
                        key={color}
                        sx={({
                          borders: { borderWidth },
                          palette: { white, dark },
                          transitions,
                        }) => ({
                          width: "24px",
                          height: "24px",
                          padding: 0,
                          border: `${borderWidth[1]} solid ${white.main}`,
                          borderColor: categoryColor === color && dark.main,
                          transition: transitions.create("border-color", {
                            easing: transitions.easing.sharp,
                            duration: transitions.duration.shorter,
                          }),
                          backgroundImage: ({
                            functions: { linearGradient },
                            palette: { gradients },
                          }) =>
                            linearGradient(
                              gradients[color].main,
                              gradients[color].state
                            ),

                          "&:not(:last-child)": {
                            mr: 1,
                          },

                          "&:hover, &:focus, &:active": {
                            borderColor: dark.main,
                          },
                        })}
                        onClick={() => setCategoryColor(color)}
                      />
                    ))}
                  </ArgonBox>
                  {" = "}
                  <ArgonBox display="flex" pl={0.5}>
                    <IconButton
                      sx={({
                        borders: { borderWidth },
                        palette: { white, dark },
                        transitions,
                      }) => ({
                        width: "24px",
                        height: "24px",
                        padding: 0,
                        // border: `${borderWidth[1]} solid ${white.main}`,
                        // borderColor: categoryColor === setCategoryColor && dark.main,
                        transition: transitions.create("border-color", {
                          easing: transitions.easing.sharp,
                          duration: transitions.duration.shorter,
                        }),
                        backgroundImage: ({
                          functions: { linearGradient },
                          palette: { gradients },
                        }) =>
                          linearGradient(
                            gradients[categoryColor].main,
                            gradients[categoryColor].state
                          ),

                        "&:not(:last-child)": {
                          mr: 1,
                        },

                        "&:hover, &:focus, &:active": {
                          borderColor: dark.main,
                        },
                      })}
                      // onClick={() => setCategoryColor(color)}
                    />
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>

              <ArgonBox mb={0.5} pl={5}></ArgonBox>
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox p={1}>
                <ArgonBox
                  display="flex"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={onClose}
                    >
                      {t("buttons.cancel")}
                    </ArgonButton>
                  </ArgonBox>
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmitClicked}
                    >
                      {isEdit ? t("buttons.update") : t("buttons.create")}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
              {/* Display role checkboxes here based on viewMode */}
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
}

export default CategoryDialog;
