/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import HostHiveLoading from "components/HostHiveLoading";
import CompaniesApi from "api/companyApi";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
function RecurrenceSeriesDialog({
  onClose,
  setUpdateSeries,
  handleSubmitClicked,
  hideSingleOption = false,
  allAppointmentsText = "All Appointments in this series",
  onlyNewAppointmentsText = "This and all following",
  submiteButtonText = "Update",
  allAppointmentsValue = "all",
  onlyNewValue = "thisAndAllFollowing",
}) {
  const handleUpdate = async () => {
    handleSubmitClicked();
    onClose();
  };

  const handleRadioSelection = (e) => {
    console.log(e.target.value);
    setUpdateSeries(e.target.value);
  };
  return (
    <>
      <ArgonBox p={2} lineHeight={1}>
        <ArgonBox mb={2}>
          <ArgonBox
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            height="100%"
          >
            <Grid container spacing={1}>
              <ArgonBox
                mb={1}
                ml={0.5}
                mt={3}
                lineHeight={0}
                display="inline-block"
              >
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  Apply change to:
                </ArgonTypography>
              </ArgonBox>
              <Grid item xs={12}>
                <ArgonBox display="flex">
                  <ArgonBox>
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="single"
                        name="radio-buttons-group"
                        onChange={(e) => handleRadioSelection(e)}
                      >
                        <ArgonBox pb={2}>
                          {hideSingleOption ? null : (
                            <FormControlLabel
                              value="single"
                              control={<Radio />}
                              label="Only this Appointment"
                            />
                          )}
                        </ArgonBox>
                        <ArgonBox pb={2}>
                          <FormControlLabel
                            value={allAppointmentsValue}
                            control={<Radio />}
                            label={allAppointmentsText}
                          />
                        </ArgonBox>
                        <ArgonBox pb={2}>
                          <FormControlLabel
                            value={onlyNewValue}
                            control={<Radio />}
                            label={onlyNewAppointmentsText}
                          />
                        </ArgonBox>
                      </RadioGroup>
                    </FormControl>
                  </ArgonBox>
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>
        </ArgonBox>

        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox p={1}>
            <ArgonBox display="flex" justifyContent="flex-end" height="100%">
              <ArgonBox pr={2}>
                <ArgonButton variant="gradient" color="dark" onClick={onClose}>
                  Cancel
                </ArgonButton>
              </ArgonBox>
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="info"
                  onClick={handleUpdate}
                >
                  {submiteButtonText}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
          {/* Display role checkboxes here based on viewMode */}
        </ArgonBox>
      </ArgonBox>
    </>
  );
}

export default RecurrenceSeriesDialog;
