import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Switch from "@mui/material/Switch";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import SettingsApi from "api/settingsApi";
import HostHiveLoading from "components/HostHiveLoading";
import DynamicSettings from "layouts/management/components/DynamicSettings";
function TestDynamicSettings({}) {
  return (
    <DashboardLayout>
      <ArgonBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <DynamicSettings settingsType="users" entityId="afasfasf" />
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default TestDynamicSettings;
