import { useEffect, useState } from "react";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import { useFormik } from "formik";
import * as Yup from "yup";
import CompaniesApi from "api/companyApi";
// import UsersApi from "api/partnersApi";
function ServicePartnersSelect({
  setPartner,
  partner,
  menuPlacement = "bottom",
  companyId,
  setServicePartners = null,
}) {
  const companiesApi = CompaniesApi();
  const [partners, setPartners] = useState([]);
  const [saving, setSaving] = useState(false);
  useEffect(() => {
    async function fetchPartners() {
      try {
        const queryParams = {
          companyId: companyId,
        };
        const response = await companiesApi.getCompanyPartnerships(
          companyId,
          {}
        );
        let partnersResponse = response.data;
        const partnersOptions = partnersResponse.map((partner) => ({
          value: partner.id,
          label: partner.name,
        }));
        setPartners(partnersOptions);
        if (setServicePartners) {
          setServicePartners(partnersOptions);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchPartners();
  }, []);

  const handlePartnerSelected = (selectedOption) => {
    setPartner(selectedOption);
  };

  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
      pb={2}
    >
      <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          Service Partner
        </ArgonTypography>
      </ArgonBox>
      <ArgonSelect
        menuPlacement={menuPlacement}
        value={
          partner
            ? partner
            : { value: "none", label: "Select a Service Partner" }
        }
        options={partners}
        onChange={handlePartnerSelected}
      />
    </ArgonBox>
  );
}

export default ServicePartnersSelect;
