import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// sweetalert2 components
import Swal from "sweetalert2";
import HowToVideoButton from "layouts/management/components/VideoIconButton";

import { useState, useEffect, useContext } from "react";
import IntegrationSettingsDataCard from "layouts/management/components/PropertyIntegrationSettingsDataCard";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import useAxiosPrivate from "hooks/useAxiosPrivate";
import PropertyContext from "context/PropertyContext";

import GuestBookApi from "api/guestBookApi";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SettingsApi from "api/settingsApi";
import Grid from "@mui/material/Grid";
import ApplicationApi from "api/applicationApi";
import CompaniesApi from "api/companyApi";
import ChecklistsApi from "api/checkListsApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import { set } from "lodash";
import { VideoLibraryHelper } from "services/videoLibraryHelper";

function PropertyGroupSettingsCard({
  propertyId,
  companyId,
  triggerExternalCalendarFetch,
  propertyGroupSettings,
}) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();
  const guestBookApi = GuestBookApi();
  const settingsApi = SettingsApi();

  const checkListsApi = ChecklistsApi();
  const serviceNamesApi = ServiceNameApi();
  const companiesApi = CompaniesApi();
  const usersApi = UsersApi();

  const labels = [];
  const values = [];
  const [integrationSettingsOpen, setIntegrationSettingsOpen] = useState(false);
  const [propertySettingsOpen, setPropertySettingsOpen] = useState(false);

  const applicationApi = ApplicationApi();

  // const { property, setProperty } = useContext(PropertyContext);

  const [propertySettings, setPropertySettings] = useState(null);
  const [integrationSettingsTypeName, setIntegrationSettingsTypeName] =
    useState(null);

  const [applicationIntegrationTypes, setApplicationIntegrationTypes] =
    useState([]);

  const [availableServicers, setAvailableServicers] = useState([]);
  const [availableChecklists, setAvailableChecklists] = useState([]);
  const [availableServiceNames, setAvailableServiceNames] = useState([]);
  const [availableSubAssigneeUsers, setAvailableSubAssigneeUsers] = useState(
    []
  );

  const [showAll, setShowAll] = useState(false);

  const video = VideoLibraryHelper("syncCalendar").videoId;

  useEffect(() => {
    async function fetchPropertySettings() {
      try {
        const response = await settingsApi.getSetting(
          "integration",
          propertyId
        );
        setPropertySettings(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    if (propertyId) {
      fetchPropertySettings();
      console.log("propertyId", propertyId);
    }
  }, []);

  // useEffect(() => {
  //   async function fetchOtherElements() {
  //     try {
  //       const [
  //         applicationIntegrationTypesResponse,
  //         checklistResponse,
  //         serviceNamesResponse,
  //         servicePartnersResponse,
  //         companyUsersResponse,
  //       ] = await Promise.all([
  //         applicationApi.getIntegrationTypes(),
  //         checkListsApi.getChecklists({
  //           companyId: companyId,
  //         }),
  //         serviceNamesApi.getServiceNames({
  //           companyId: companyId,
  //         }),
  //         companiesApi.getCompanyPartnerships(companyId, { includeSelf: true }),
  //         usersApi.getUsers({ companyId: companyId }),
  //       ]);
  //       // console.log(
  //       //   "applicationIntegrationTypesResponse",
  //       //   applicationIntegrationTypesResponse
  //       // );
  //       // setApplicationIntegrationTypes(
  //       //   applicationIntegrationTypesResponse?.data?.integrationTypes || []
  //       // );
  //       // Add "Request Integration" to the applicationIntegrationTypes
  //       const responseApplicationIntegrationTypes =
  //         applicationIntegrationTypesResponse?.data?.integrationTypes || [];
  //       responseApplicationIntegrationTypes.push({
  //         name: "+ Request Integration Support",
  //         id: "requestIntegration",
  //       });

  //       setApplicationIntegrationTypes(responseApplicationIntegrationTypes);

  //       // setApplicationIntegrationTypes(...applicationIntegrationTypes);

  //       if (applicationIntegrationTypesResponse?.data?.integrationTypes) {
  //         setSelectedFromResponse(
  //           applicationIntegrationTypesResponse,
  //           "integrationType",
  //           setIntegrationSettingsTypeName,
  //           "name"
  //         );
  //       }
  //       setAvailableChecklists(checklistResponse?.data || []);
  //       setAvailableServiceNames(serviceNamesResponse?.data || []);
  //       setAvailableServicers(servicePartnersResponse?.data || []);
  //       setAvailableSubAssigneeUsers(companyUsersResponse?.data || []);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }

  //   const setSelectedFromResponse = (
  //     response,
  //     propertySettingsKey,
  //     setState,
  //     labelKey
  //   ) => {
  //     let matchingItem = {};
  //     if (propertySettingsKey === "integrationType") {
  //       console.log("response from match", response);
  //       matchingItem = response?.data?.integrationTypes?.find(
  //         (item) => item.id === propertySettings?.[propertySettingsKey]
  //       );
  //     } else {
  //       matchingItem = response?.data?.find(
  //         (item) => item.id === propertySettings?.[propertySettingsKey]
  //       );
  //     }

  //     if (matchingItem) {
  //       // console.log("matchingItem", matchingItem);
  //       setState(`${matchingItem?.[labelKey]} Integration`);
  //     } else {
  //       // console.log("No matching item found");
  //       console.log("response", response);
  //     }
  //   };

  //   fetchOtherElements();
  // }, [propertySettings, companyId]);

  const newSwal = Swal.mixin({
    customClass: {
      confirmButton: "button button-success",
      cancelButton: "button button-error",
    },
    buttonsStyling: false,
    reverseButtons: true,
  });

  const showAlert = (title, message, action) => {
    Swal.fire(title, message, action);
  };

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <ArgonBox key={label} display="flex" py={1} pr={2}>
      <ArgonTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </ArgonTypography>
      <ArgonTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </ArgonTypography>
    </ArgonBox>
  ));
  const [syncLoading, setSyncLoading] = useState(false);

  const handleLinkClicked = (link) => {
    window.open(link, "_blank");
  };
  const maxItems = 5;
  return (
    <Card
      sx={{
        height: "100%",
        justifyContent: { xs: "center" },
        width: "100%",
        "@media screen and (min-width: 768px)": { width: "100%" },
      }}
    >
      <ArgonBox display="flex" alignItems="center" pt={2} px={2}>
        <ArgonTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Property Group Settings
        </ArgonTypography>
        <ArgonTypography
          variant="body2"
          color="secondary"
          sx={{
            ":hover": {
              textDecoration: "underline",
            },
            cursor: "pointer",
          }}
        >
          <Tooltip
            title="Edit Property Group Settings"
            placement="top"
            onClick={() => {
              navigate("/property-group-settings");
            }}
          >
            <Icon>edit</Icon>
          </Tooltip>
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox pl={3}>
        {propertyGroupSettings?.length > 0 ? (
          <Grid container spacing={3}>
            {propertyGroupSettings
              ?.slice(0, showAll ? propertyGroupSettings.length : maxItems) // Show all or just the first 5
              .map((settings, key) => (
                <Grid item xs={12} key={key}>
                  <ArgonTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {settings?.key}{" "}
                  </ArgonTypography>
                  {settings?.type === "link" ? (
                    <ArgonTypography
                      pl={3}
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                        },
                        cursor: "pointer",
                      }}
                      onClick={() => handleLinkClicked(settings?.value)}
                    >
                      View
                    </ArgonTypography>
                  ) : (
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {settings?.value}
                    </ArgonTypography>
                  )}
                </Grid>
              ))}
            {propertyGroupSettings.length > maxItems &&
              !showAll && ( // Only show the "View All" button if there are more than 5 items and we're not already showing all
                <Grid item xs={12}>
                  <ArgonTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => setShowAll(true)} // Toggle to show all items
                  >
                    View All
                  </ArgonTypography>
                </Grid>
              )}
          </Grid>
        ) : null}
      </ArgonBox>
    </Card>
  );
}

// Typechecking props for the PropertyGroupSettingsCard
PropertyGroupSettingsCard.propTypes = {
  title: PropTypes.string,
};

export default PropertyGroupSettingsCard;
