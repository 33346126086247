/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import CompaniesApi from "api/companyApi";
import ArgonButton from "components/ArgonButton";
import SubscriptionsApi from "api/subscriptionsApi";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveDialog from "components/HostHiveDialog";

import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import RecentSubscriptionInvoices from "layouts/management/company/settings/components/AccountSettings/components/ComplexProjectCard/SubscriptionDetailsCard/components/RecentSubscriptionInvoices";
// Custom styles for ComplexProjectCard
function SubscriptionDetailsCard({ title }) {
  const [viewInvoicesDialogOpen, setViewInvoicesDialogOpen] = useState(false);
  const companiesApi = CompaniesApi();
  const navigate = useNavigate();
  const subscriptionsApi = SubscriptionsApi();
  const [companySubscription, setCompanySubscription] = useState();
  const [error, setError] = useState(null);
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("inactive");
  const [subscriptionTier, setSubscriptionTier] = useState(null);
  const [activating, setActivating] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [companyPortalLoading, setCompanyPortalLoading] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [companySubscriptionInvoices, setCompanySubscriptionInvoices] =
    useState([]);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
  const [
    companyPortalLoadingCancelSubscription,
    setCompanyPortalLoadingCancelSubscription,
  ] = useState(false);
  const [
    companySubscriptionPortalLoading,
    setCompanySubscriptionPortalLoading,
  ] = useState(false);
  useEffect(() => {
    setLoadingDetails(true);
    const loadData = async () => {
      try {
        const companiesResponse = await companiesApi.getCompanySubscription(
          company?.id
        );

        if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
          setCompanySubscription(companiesResponse.data);
          setSubscriptionStatus(companiesResponse.data.subscriptionStatus);
          setSubscriptionTier(companiesResponse.data.subscriptionTier);
          // console.log("setCompanySubscription.data", companiesResponse.data);
        }
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setLoadingDetails(false);
      }
    };

    async function loadCompanyInvoices() {
      try {
        const companiesResponse = await subscriptionsApi.getCompanyInvoices(
          company?.id
        );

        if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
          // setCompanySubscription(companiesResponse.data);
          // setSubscriptionStatus(companiesResponse.data.subscriptionStatus);
          // setSubscriptionTier(companiesResponse.data.subscriptionTier);
          setCompanySubscriptionInvoices(companiesResponse.data);
          // console.log("setCompanySubscription.data", companiesResponse.data);
        }
      } catch (error) {
        // setError(error);
        console.log("Error getting invoices", error);
      }
    }

    loadData();
    loadCompanyInvoices();
  }, [activating]);
  const handleSubscriptionSignUp = async () => {
    if (!company?.id) return;
    setCheckoutLoading(true);
    const response = await subscriptionsApi.createCheckoutSession(company?.id);
    if (response.status >= 200 && response.status <= 300) {
      //window.open(response.data.url, "noopener,noreferrer");
      window.location.href = response.data.url;
    }
  };
  const handleSubscriptionActivate = async () => {
    if (!company?.id) return;
    setActivating(true);
    const response = await subscriptionsApi.activateSubscription(company?.id);
    if (response.status >= 200 && response.status <= 300) {
      setSubscriptionStatus("active");
    }
    setActivating(false);
  };

  const handleEditInfoClicked = async () => {
    if (!company?.id) {
      // Alert the user or set an error state here
      return;
    }
    setCompanyPortalLoading(true);
    // console.log("company.id", company.id);
    try {
      const response = await companiesApi.getCompanyPaymentPortal(company.id);

      if (response.status >= 200 && response.status < 300) {
        // Redirect to the payment portal URL
        window.location.href = response.data.portalUrl;
      } else {
        // Handle other statuses here, perhaps show an error message
      }
    } catch (error) {
      // Handle errors here, perhaps show an error message
    } finally {
      //setCompanyPortalLoading(false);
    }
  };

  const handleCancelClicked = async () => {
    if (!company?.id) {
      // Alert the user or set an error state here
      return;
    }
    setCompanyPortalLoadingCancelSubscription(true);

    try {
      const response = await companiesApi.getCompanyPaymentPortal(company.id);

      if (response.status >= 200 && response.status < 300) {
        // Redirect to the payment portal URL
        window.location.href = `${response.data.portalUrl}/subscriptions/${companySubscription?.subscriptionId}/cancel`;
      } else {
        // Handle other statuses here, perhaps show an error message
      }
    } catch (error) {
      // Handle errors here, perhaps show an error message
    } finally {
      //setCompanyPortalLoading(false);
    }
  };

  const formatDate = (periodDate) => {
    let convertedDate = convertTimeStamp(periodDate);
    const date = new Date(convertedDate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };
  return (
    <ArgonBox p={2}>
      <ArgonBox display="flex" alignItems="center">
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox mb={1} lineHeight={0}>
            <ArgonTypography
              variant="h6"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {title}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        {/* {dropdown && (
            <ArgonTypography
              color="secondary"
              onClick={dropdown.action}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
                py: 1.25,
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                more_vert
              </Icon>
            </ArgonTypography>
          )}
          {dropdown.menu} */}
      </ArgonBox>
      {loadingDetails ? (
        <HostHiveLoading />
      ) : (
        <>
          <>
            <ArgonBox ml={2} lineHeight={0} pt={2}>
              <ArgonBox display="flex" justifyContent="space-between">
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color="text"
                  >
                    Subscription Tier
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    {subscriptionTier}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </>
          {subscriptionStatus === "active" && subscriptionTier === "paid" ? (
            <div>
              <ArgonBox ml={2} lineHeight={0}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      {companySubscription?.currentPeriodEnd ===
                      companySubscription?.cancellationDate
                        ? "Scheduled Cancellation Date"
                        : "Renewal Date"}
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {formatDate(companySubscription?.currentPeriodEnd * 1000)}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                {companySubscription?.upcomingInvoiceAmountDue > 0 ? (
                  <ArgonBox display="flex" justifyContent="space-between">
                    <ArgonBox>
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        color="text"
                      >
                        Estimated Payment
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        {/* <span style={{ textTransform: "uppercase" }}>
                        {companySubscription?.paymentDetails?.brand}
                      </span>{" "}
                      ending in {companySubscription?.paymentDetails?.last4} */}
                        $ {companySubscription?.upcomingInvoiceAmountDue / 100}
                      </ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                ) : null}
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      Payment Method
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      <span style={{ textTransform: "uppercase" }}>
                        {companySubscription?.paymentDetails?.brand}
                      </span>{" "}
                      ending in {companySubscription?.paymentDetails?.last4}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>

                {/* Your next estimated payment will be */}
              </ArgonBox>
              <Divider />
              <HostHiveDialog
                includeDelete={false}
                includeSave={false}
                includeClose={true}
                open={pdfDialogOpen}
                onClose={() => {
                  setPdfDialogOpen(false);
                }}
                title="Subscription Invoices"
                fullScreen={false}
                cancelButtonText="Cancel"
                submitButtonText="Save"
                dialogObjects={
                  <>
                    <RecentSubscriptionInvoices
                      invoices={companySubscriptionInvoices}
                    />
                  </>
                }
              />
              <ArgonBox
                ml={2}
                lineHeight={0}
                display="flex"
                justifyContent="space-between"
              >
                <ArgonBox>
                  <ArgonTypography
                    variant="button"
                    fontWeight="medium"
                    color="text"
                  >
                    PDF Invoices
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  {companyPortalLoading ? (
                    <HostHiveLoading message="Loading Billing portal..." />
                  ) : (
                    <ArgonButton
                      // component={Link}
                      // href={company?.portalUrl}
                      disabled={!company?.id}
                      onClick={() => setPdfDialogOpen(true)}
                      rel="noreferrer"
                      variant="outlined"
                      color="dark"
                      fullWidth
                    >
                      View
                    </ArgonButton>
                  )}
                </ArgonBox>
              </ArgonBox>

              <Divider />
              {companySubscription?.cancellationDate ? null : (
                <ArgonBox
                  ml={2}
                  lineHeight={0}
                  display="flex"
                  justifyContent="space-between"
                >
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      Cancel Subscription
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox>
                    {companyPortalLoadingCancelSubscription ? (
                      <HostHiveLoading message="Loading Billing portal..." />
                    ) : (
                      <ArgonButton
                        // component={Link}
                        // href={company?.portalUrl}
                        disabled={!company?.id}
                        onClick={handleCancelClicked}
                        rel="noreferrer"
                        variant="outlined"
                        color="error"
                        fullWidth
                      >
                        Cancel
                      </ArgonButton>
                    )}
                  </ArgonBox>
                </ArgonBox>
              )}
            </div>
          ) : null}

          {subscriptionStatus === "trialing" ? (
            <div>
              <ArgonBox ml={2} lineHeight={0}>
                <ArgonBox display="flex" justifyContent="space-between">
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      Trial Days Remaining
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {companySubscription?.remainingDays}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
                <ArgonButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={handleSubscriptionActivate}
                  disabled={!company?.id || subscriptionStatus === "active"}
                >
                  Start Subscription Now
                </ArgonButton>
              </ArgonBox>
            </div>
          ) : null}
          {subscriptionStatus === "inactive" ||
          subscriptionTier === "free" ||
          subscriptionTier === null ? (
            <>
              {!checkoutLoading ? (
                <>
                  <ArgonBox ml={2} lineHeight={0}>
                    <ArgonButton
                      variant="outlined"
                      color="info"
                      size="small"
                      // onClick={handleSubscriptionSignUp}
                      onClick={() => {
                        navigate("/subscription");
                      }}
                      // disabled={!company?.id || subscriptionStatus === "active"}
                    >
                      Manage Subscription
                    </ArgonButton>
                  </ArgonBox>
                </>
              ) : (
                <HostHiveLoading message="Loading Subscription portal..." />
              )}
            </>
          ) : null}
        </>
      )}
    </ArgonBox>
  );
}

// Setting default values for the props of ComplexProjectCard
SubscriptionDetailsCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
SubscriptionDetailsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
};

export default SubscriptionDetailsCard;
