/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ContactCard from "layouts/management/components/ContactCard";
import HostHiveDialog from "components/HostHiveDialog";
function Header({
  property,
  isOwner = false,
  propertyName,
  propertyId,
  contact,
}) {
  console.log("Property: ", property);
  const { t } = useTranslation("common");
  const [contactDialogOpen, setContactDialogOpen] = useState(false);

  return (
    <ArgonBox display="flex" justifyContent="space-between" alignItems="center">
      <HostHiveDialog
        open={contactDialogOpen}
        onClose={() => setContactDialogOpen(false)}
        includeSave={false}
        includeClose={true}
        showFullScreenArrow={false}
        title="Contact"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Close"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ContactCard
              contact={contact}
              onClose={() => setContactDialogOpen(false)}
            />
          </>
        }
      />
      <ArgonBox>
        <ArgonBox mb={1}>
          <ArgonTypography variant="h6" fontWeight="medium">
            {propertyName}
          </ArgonTypography>
          <ArgonBox mb={1}>
            {/* <ArgonTypography variant="button" color="text" fontWeight="medium">
              KMB Realty LLC.
            </ArgonTypography> */}
          </ArgonBox>
        </ArgonBox>
        {/* <ArgonTypography
          component="p"
          variant="button"
          fontWeight="regular"
          color="text"
        >
          PO Box 1234
        </ArgonTypography> */}
      </ArgonBox>
      {isOwner ? null : (
        <ArgonButton
          variant="gradient"
          color="info"
          onClick={() => setContactDialogOpen(true)}
        >
          {t("buttons.contact")}
        </ArgonButton>
      )}
    </ArgonBox>
  );
}

export default Header;
