import { useState, useEffect } from "react";
// @mui material components

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonAlert from "components/ArgonAlert";
import HostHiveLoading from "components/HostHiveLoading";
import PropertiesApi from "api/propertiesApi";
import { useTranslation } from "react-i18next";
function PropertySelect({
  companyId = null,
  setProperty,
  property,
  menuPlacement = "bottom",
  optional = false,
  filterSelection = false,
}) {
  const propertiesApi = PropertiesApi();
  const [properties, setProperties] = useState([]);
  const [propertiesFound, setPropertiesFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation("common");
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    async function fetchProperties() {
      if (company) {
        console.log("CompanyId", company?.id);
        try {
          const queryParams = {
            companyId: company?.id,
            ownerOnly: true,
          };

          const response = await propertiesApi.getProperties(queryParams);
          console.log("Properties", response.data);
          setProperties(response.data);
          if (response.data.length > 0) {
            setPropertiesFound(true);
          } else {
          }
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    }

    fetchProperties();
  }, []);

  const handlePropertySelected = (e) => {
    setProperty(e);
  };

  // generate options array, including the "Create New" option
  const options = properties.map((property) => ({
    value: property.id,
    label: property.name,
  }));

  // Add "clear selection" option
  options.push({
    value: null,
    label: !filterSelection ? "Clear Selection" : "All Properties",
  });

  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      {propertiesFound ? (
        <>
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {t("properties.components.propertySelect.title")}{" "}
              {optional ? "(Optional)" : null}
            </ArgonTypography>
          </ArgonBox>
          <ArgonSelect
            menuPlacement={menuPlacement}
            defaultValue={{
              value: "none",
              label: t("properties.components.propertySelect.placeHolder"),
            }}
            options={options}
            onChange={handlePropertySelected}
          />
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <ArgonTypography variant="caption">
                {t("generalWords.loading")} {t("properties.pluralNoun")}...
              </ArgonTypography>
              <HostHiveLoading />
            </>
          ) : (
            <>
              {optional ? null : (
                <ArgonAlert color="error" dismissible>
                  <ArgonBox display="flex" justifyContent="flex-end">
                    <ArgonBox>
                      <ArgonTypography variant="caption" color="white">
                        {t("properties.components.propertySelect.noneFound")}
                      </ArgonTypography>
                    </ArgonBox>
                    {/* <ArgonBox pl={3}>
                <ArgonButton onClick={handleOpenSubscriptionSettings}>
                  Open subscription settings
                </ArgonButton>
              </ArgonBox> */}
                  </ArgonBox>
                </ArgonAlert>
              )}
            </>
          )}
        </>
      )}
    </ArgonBox>
  );
}

export default PropertySelect;
