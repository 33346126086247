import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import { IconButton } from "@mui/material";
// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import ArgonBadge from "components/ArgonBadge";
import ArgonEditor from "components/ArgonEditor";
import ArgonSnackbar from "components/ArgonSnackbar";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import DocumentsApi from "api/documentsApi";
import HostHiveDialog from "components/HostHiveDialog";
import MuiLink from "@mui/material/Link";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
function DocumentItem({
  documentItem,
  propertyDocuments,
  setPropertyDocuments,
  handleDocumentReplacement,
  setOldDocumentId,
}) {
  const { t } = useTranslation("common");
  const documentsApi = DocumentsApi();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const handleDeleteDocument = async () => {
    setDeleteInProgress(true);
    try {
      const response = await documentsApi.deleteDocument(documentItem?.id);
      console.log("delete document response", response);
      setPropertyDocuments(
        propertyDocuments.filter((document) => document.id !== documentItem?.id)
      );
    } catch (error) {
      console.log("error deleting document", error);
    }
  };
  return (
    <>
      {deleteInProgress ? (
        <HostHiveLoading
          message={`${t("generalWords.deleting")}...${documentItem?.name}`}
        />
      ) : (
        <>
          <ArgonBox display="flex" justifyContent="space-between">
            <ArgonBox p={1}>
              <ArgonBox sx={{ maxWidth: "60ch" }}>
                <MuiLink
                  href={documentItem?.documentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ArgonTypography variant="h5" fontWeight="regular">
                    {documentItem?.name}
                  </ArgonTypography>
                </MuiLink>
                {documentItem?.expiration ? (
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      // ":hover": {
                      //   textDecoration: "underline",
                      //   color: "primary.main",
                      // },
                      // cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    // onClick={() => setShowEditDueDate(true)}
                  >
                    Expiration: {documentItem?.expiration}
                  </ArgonTypography>
                ) : null}
              </ArgonBox>
              <ArgonBox display="flex">
                <ArgonBox>
                  <MuiLink
                    href={documentItem?.documentUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                          color: "primary.main",
                        },
                        cursor: "pointer",
                      }}
                      // onClick={() => setShowEditDueDate(true)}
                    >
                      View
                    </ArgonTypography>
                  </MuiLink>
                </ArgonBox>
                {documentItem?.expiration ? (
                  <>
                    <ArgonBox>
                      <ArgonTypography
                        variant="caption"
                        textTransform="capitalize"
                      >
                        {" "}
                        |{" "}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonTypography
                        variant="caption"
                        textTransform="capitalize"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                            color: "primary.main",
                          },
                          cursor: "pointer",
                        }}
                        onClick={handleDocumentReplacement}
                      >
                        Replace
                      </ArgonTypography>
                    </ArgonBox>
                  </>
                ) : null}
                <ArgonBox>
                  <ArgonTypography variant="caption" textTransform="capitalize">
                    {" "}
                    |{" "}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                    }}
                    onClick={handleDeleteDocument}
                  >
                    Delete
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
          <Divider />
        </>
      )}
    </>
  );
}

export default DocumentItem;
