/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Argon Dashboard 2 PRO MUI components

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

import React, { useEffect, useState, useMemo } from "react";
import FilesApi from "api/filesApi";
import PropertiesApi from "api/propertiesApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";

import ImportMatchWizard from "layouts/management/components/ImportMatchWizard";
import HostHiveDialog from "components/HostHiveDialog";

function FileUpload({ formData, setUploadResponse, setNextDisabled }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, email, password, repeatPassword } = formField;
  const {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  const [file, setFile] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  // const [uploadResponse, setUploadResponse] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const filesApi = FilesApi();
  const propertiesApi = new PropertiesApi();
  const [uploading, setUploading] = useState(false);
  const [matchedListing, setMatchedListing] = useState(null);
  const [fileProperties, setFileProperties] = useState([]);
  const [propertyAppMatch, setPropertyAppMatch] = useState({});
  const [propertySelected, setPropertySelected] = useState(null);
  const [properties, setProperties] = useState([]);
  const memoizedProperties = useMemo(() => properties, [properties]);
  const MemoizedArgonSelect = React.memo(ArgonSelect);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [openWizard, setOpenWizard] = useState(false);

  const handleFileUpload = async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      if (!file) {
        console.log("No file selected");
      }
      formData.append("file", file);

      const response = await filesApi.uploadFile(formData, uploadType);
      setUploadResponse(response.data);
      // setFileProperties(response.data.properties);
      console.log(response);
    } catch (error) {
      setUploadError(error);
    }
    setUploading(false);
    setNextDisabled(false);
  };

  const uploadHandler = async (event) => {
    const files = event.target.files;
    const file = files[0];
    setUploadType("importTransactionMatch");
    setFile(file);
  };

  return (
    <ArgonBox>
      <ArgonBox lineHeight={0}>
        {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
        <ArgonTypography variant="button" fontWeight="regular" color="text">
          Upload income statement from Airbnb and we will try to match
          transactions to your properties.
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <ArgonInput
              placeholder="Upload Type"
              type="file"
              onChange={uploadHandler}
            />
          </Grid>
          <Grid item xs={12}>
            <ArgonButton
              variant="gradient"
              color="dark"
              onClick={() => handleFileUpload()}
              disabled={uploading || file === null}
            >
              Upload
            </ArgonButton>
          </Grid>
        </Grid>
      </ArgonBox>
    </ArgonBox>
  );
}

export default FileUpload;
