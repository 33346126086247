import { useState, useEffect, useContext } from "react";
import "./comment.css";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Card,
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonProgress from "components/ArgonProgress";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonAlert from "components/ArgonAlert";

import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import convertToOptions from "services/convertooptions";
import useMediaQuery from "@mui/material/useMediaQuery";
import statusBadges from "services/statusBadges";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonTimePicker from "components/ArgonTimePicker";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveImages from "components/HostHiveImages";

import DialogTitle from "@mui/material/DialogTitle";

import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import CategorySelect from "layouts/management/components/CategorySelect";
import BookingReportApi from "api/bookingReportApi";
import BookingReportCompletedDataCard from "layouts/management/components/BookingReportDataCard/BookingReportCompletedDataCard";
import CommentsApi from "api/commentsApi";
import { UserAuth } from "context/AuthContext";
const HostHiveCommentSection = ({
  title,
  entityType,
  entityId,
  disabled = false,
}) => {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const { role, hasRole, user, hasOneOfRoles, subscriptionTier } = UserAuth();
  const commentsApi = new CommentsApi();
  const [newComment, setNewComment] = useState("");
  const [savingNewComment, setSavingNewComment] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [loadingCommentSection, setLoadingCommentSection] = useState(false);
  const [commentSubscribed, setCommentSubscribed] = useState(false);
  useEffect(() => {
    console.log(user);
    async function fetchComments() {
      setLoadingCommentSection(true);
      try {
        const response = await commentsApi.getComments(entityId, entityType);
        const subscription = await commentsApi.confirmUserSubscription(
          entityId,
          entityType,
          user?.uid
        );
        console.log("subscription", subscription);
        setCommentSubscribed(subscription.data.subscribed);
        setComments(response.data.items);
      } catch (error) {
        console.error(error);
      }
      setLoadingCommentSection(false);
    }
    fetchComments();
  }, []);
  const handleSaveComment = async () => {
    let payload = {
      comment: newComment,
      entityType: entityType,
      companyId: company.id,
    };
    const queryParams = {
      returnOnlyNewItem: true,
    };
    try {
      if (newComment.trim()) {
        setSavingNewComment(true);
        const response = await commentsApi.addComment(
          entityId,
          payload,
          queryParams
        );
        console.log(response);
        setComments([response.data, ...comments]);

        // sort comments by created date
        // setComments(
        //   comments.sort((a, b) => {
        //     return new Date(b.created) - new Date(a.created);
        //   })
        // );
        setSavingNewComment(false);
        setNewComment("");
      }
    } catch (error) {
      console.error(error);
      // setShowError(true);
      // setError(error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    console.log(commentId);
    //entityId, entityType, entityItemId
    try {
      const response = await commentsApi.removeComment(
        entityId,
        entityType,
        commentId
      );

      console.log(response);
      if (response.status !== 200) {
        setError("Unable to delete comment.");
        if (error) {
          setShowError(true);
        }
        return;
      }
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      console.error(error);
      setShowError(true);
    }
  };

  const [isTopScrolled, setIsTopScrolled] = useState(false);

  const handleScroll = (e) => {
    if (e.target.scrollTop > 10) {
      setIsTopScrolled(true);
    } else {
      setIsTopScrolled(false);
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const handleEditComment = (commentId, existingComment) => {
    setEditingCommentId(commentId);
    setNewComment(existingComment);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditingCommentId(null); // Reset editingCommentId.
    setNewComment("");
  };

  const handleUpdateComment = async (commentId) => {
    let payload = {
      comment: newComment,
      entityType: entityType,
      companyId: company.id,
    };

    try {
      if (newComment.trim()) {
        setSavingNewComment(true);
        //(entityId, entityType, entityItemId, commentData)
        const response = await commentsApi.editComment(
          entityId,
          entityType,
          commentId,
          payload
        );
        console.log(response);
        // Update the comment in the comments array
        const updatedComments = comments.map((comment) => {
          if (comment.id === commentId) {
            return {
              ...comment,
              comment: newComment,
            };
          }
          return comment;
        });
        setComments(updatedComments);
        setSavingNewComment(false);
        setIsEditing(false);
        setNewComment("");
      }
    } catch (error) {
      console.error(error);
      // setShowError(true);
      // setError(error);
    }
  };

  const handleCommentSubscription = async () => {
    let payload = {
      entityType: entityType,
      entityId: entityId,
      userId: user?.uid,
      subscribed: !commentSubscribed,
    };

    console.log("handleCommentSubscription", payload);
    try {
      await commentsApi.commentSubscription(payload);
      setCommentSubscribed(!commentSubscribed);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <ArgonBox className="comment-section">
        {/* Service Comments Header */}
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          {title}
        </ArgonTypography>

        {/* {showError ? (
        <ArgonAlert
          color="error"
          dismissible
          onDismiss={() => {
            setShowError(false);
            setError(null);
          }}
        >
          <ArgonBox display="flex" justifyContent="flex-end">
            <ArgonBox>
              <ArgonTypography variant="caption" color="white">
                Unable to delete comment
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </ArgonAlert>
      ) : null} */}
        {/* Add a Comment */}
        {isEditing ? (
          <ArgonInput placeholder="Add a comment..." />
        ) : (
          <ArgonInput
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            multiline
            rows={4}
            disabled={disabled}
          />
        )}

        {/* Save Comment Button */}
        {newComment && !isEditing && (
          <ArgonBox pt={2} display="flex" justifyContent="flex-end">
            <ArgonButton variant="text" color="dark">
              <Icon onClick={() => setNewComment("")}>cancel</Icon>
              &nbsp;
              <span>Clear</span>
            </ArgonButton>
            <ArgonButton
              variant="contained"
              color="primary"
              onClick={handleSaveComment}
              disabled={savingNewComment}
            >
              {!savingNewComment ? "Save" : "Saving..."}
            </ArgonButton>
          </ArgonBox>
        )}
        <ArgonBox pt={2} display="flex" justifyContent="flex-end">
          <ArgonButton
            variant="text"
            color={commentSubscribed ? "dark" : "error"}
            onClick={handleCommentSubscription}
          >
            <Icon>
              {commentSubscribed ? "notifications_active" : "notifications_off"}
            </Icon>
            &nbsp;
            <span>
              {commentSubscribed ? "Notifications on" : "Notifications off"}
            </span>
          </ArgonButton>
        </ArgonBox>
        {/* Display Existing Comments */}
        <div
          className={`existing-comments ${isTopScrolled ? "top-scrolled" : ""}`}
          onScroll={handleScroll}
        >
          {loadingCommentSection ? (
            <HostHiveLoading message="Loading Comments" elipse={true} />
          ) : (
            comments.map((comment) => (
              <div key={comment?.id} className="comment">
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  pl={2}
                >
                  <strong>{comment?.fullName}</strong>
                  <span>
                    {convertTimeStamp(comment?.created, "America/New_York")}
                  </span>
                </ArgonBox>
                <ArgonBox pl={2}>
                  {isEditing && editingCommentId === comment?.id ? (
                    <>
                      <ArgonInput
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        multiline
                        rows={4}
                      />
                      <ArgonBox pt={2} display="flex" justifyContent="flex-end">
                        <ArgonButton
                          variant="contained"
                          color="primary"
                          onClick={() => handleUpdateComment(comment?.id)}
                          disabled={savingNewComment}
                        >
                          {!savingNewComment ? "Save" : "Saving..."}
                        </ArgonButton>
                      </ArgonBox>
                    </>
                  ) : (
                    <>
                      <ArgonTypography component="div" variant="body2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: comment?.comment,
                          }}
                        />
                      </ArgonTypography>
                    </>
                  )}
                </ArgonBox>
                {user?.uid === comment?.createdBy ? (
                  <>
                    <ArgonBox
                      display="flex-end"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ArgonButton
                        variant="text"
                        color="error"
                        onClick={() => handleDeleteComment(comment?.id)}
                      >
                        <Icon>delete</Icon>&nbsp;<span>Delete</span>
                      </ArgonButton>

                      {isEditing && editingCommentId === comment?.id ? (
                        <ArgonButton variant="text" color="dark">
                          <Icon onClick={() => handleCancelEdit()}>cancel</Icon>
                          &nbsp;
                          <span>Cancel Edit</span>
                        </ArgonButton>
                      ) : (
                        <ArgonButton variant="text" color="dark">
                          <Icon
                            onClick={() =>
                              handleEditComment(comment?.id, comment?.comment)
                            }
                          >
                            edit
                          </Icon>
                          &nbsp;
                          <span>Edit</span>
                        </ArgonButton>
                      )}
                    </ArgonBox>
                  </>
                ) : null}
              </div>
            ))
          )}
        </div>
      </ArgonBox>
    </>
  );
};

export default HostHiveCommentSection;
