import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import useMediaQuery from "@mui/material/useMediaQuery";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import BookingsApi from "api/bookingsApi";
import PropertiesApi from "api/propertiesApi";

function DeclineMessageDataCard({
  title,
  onClose,
  bookingPayload,
  setExternalFetch,
  eventDataCardClose,
  handleRefetchBookings,
  confirmBookingsFetch = null,
  setConfirmBookingsFetch = null,
}) {
  const navigate = useNavigate();
  const bookingsApi = BookingsApi();
  // Media Queries
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [isDirty, setIsDirty] = useState(false);

  const DisplayEntry = ({ entryKey, displayValue }) => {
    return (
      <ArgonBox
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <ArgonBox>
          <ArgonTypography variant="h5">{entryKey}:</ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            {displayValue}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    );
  };

  const handleSubmitClicked = async () => {
    try {
      let messagePayload = { message: declineMessage };
      const response = await bookingsApi.updateBookingStatusDecline(
        bookingPayload?.id,
        messagePayload
      );
      if (response.status >= 200 && response.status < 300) {
        console.log("Booking status updated", response);
      }
    } catch (error) {
      console.log(error);
    }
    if (setExternalFetch) {
      setExternalFetch();
    }
    if (handleRefetchBookings) {
      handleRefetchBookings();
    }
    onClose();
    eventDataCardClose();
    if (setConfirmBookingsFetch) {
      setConfirmBookingsFetch(!confirmBookingsFetch);
    }
  };

  const [declineMessage, setDeclineMessage] = useState("");
  const handleDeclineMessage = (event) => {
    setDeclineMessage(event.target.value);
  };

  return (
    <Card
      sx={{
        p: isSmallScreen ? 1 : 2,
        width: "150%",
        overflow: "visible",
        width: "100%",
        height: "100%",
        minWidth: "600px",
        "@media (max-width: 600px)": {
          minWidth: "auto",
        },
      }}
    >
      {isDirty && isSmallScreen && (
        <Grid item sx={{ pt: 2 }}>
          <ArgonTypography variant="body2" color="warning">
            Warning: You have unsaved changes. If you close this modal, your
            changes will be lost.
          </ArgonTypography>
        </Grid>
      )}

      <ArgonBox p={2}>
        <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
          {title}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox p={2}>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="button" fontWeight="medium" gutterBottom>
              Service name:
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="caption" color="text">
              {bookingPayload?.serviceName}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="button" fontWeight="medium" gutterBottom>
              Date:
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="caption" color="text">
              {bookingPayload?.serviceScheduleDate}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="button" fontWeight="medium" gutterBottom>
              Company/Property:
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="caption" color="text">
              {bookingPayload?.assignedCompany}
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="button" fontWeight="medium" gutterBottom>
              Provide reason for decline
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox pt={2} px={2}>
            <ArgonTypography variant="caption" color="text">
              <ArgonInput
                value={declineMessage}
                onChange={handleDeclineMessage}
              />
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox pt={1.25} pb={3} px={3} mt={3} lineHeight={1}>
        <ArgonTypography variant="h6" sx={{ fontSize: "1.2rem" }}>
          {/* Create Booking Report */}
        </ArgonTypography>
        <ArgonTypography
          variant="button"
          color="text"
          fontWeight="regular"
          sx={{ fontSize: "0.8rem" }}
        ></ArgonTypography>

        <ArgonBox>
          <ArgonBox display="flex" justifyContent="space-between" py={1}>
            <ArgonBox>
              <ArgonButton variant="gradient" color="dark" onClick={onClose}>
                Close
              </ArgonButton>
            </ArgonBox>

            <ArgonBox>
              <ArgonButton
                variant="gradient"
                color="error"
                onClick={handleSubmitClicked}
              >
                Decline Appointment
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

DeclineMessageDataCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default DeclineMessageDataCard;
