/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
// import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import { Link, useNavigate, useParams } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import UsersApi from "api/usersApi";
// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg";

function SubscribeToBlog() {
  const { oobCode } = useParams();
  const navigate = useNavigate();
  const usersApi = UsersApi();
  const [resetPasswordInProgress, setResetPasswordInProgress] = useState(false);
  const onSubmit = async (values, actions) => {
    try {
      await usersApi.subscribeUser(values.email, oobCode);
    } catch (error) {
      console.error(error);
    }
    setResetPasswordInProgress(true);
    actions.resetForm();
  };

  const EmailSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required"),
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      // Add more fields here
    },
    validationSchema: EmailSchema,
    onSubmit,
  });

  return (
    <BasicLayout
      image={bgImage}
      button={{ variant: "gradient", color: "dark" }}
    >
      <Card>
        <ArgonBox pt={3} px={3} pb={1} textAlign="center">
          <ArgonTypography variant="h4" fontWeight="bold" textGradient>
            Subscribe to our blog
          </ArgonTypography>
          <ArgonTypography variant="body2" color="text">
            You will be notified when we post new content.
          </ArgonTypography>
        </ArgonBox>
        {resetPasswordInProgress ? (
          <>
            <ArgonBox pt={3} px={3} pb={3} textAlign="center">
              <ArgonTypography variant="body2" color="text">
                Thank you for subscribing.
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox pt={3} px={3} pb={3} textAlign="center">
              <ArgonTypography
                variant="button"
                color="text"
                fontWeight="regular"
              >
                {/* Already have an account?&nbsp; */}
                {/* <ArgonTypography
                  component={Link}
                  to="/authentication/login"
                  variant="button"
                  color="dark"
                  fontWeight="bold"
                  textGradient
                >
                  Sign in
                </ArgonTypography> */}
              </ArgonTypography>
            </ArgonBox>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <ArgonBox p={3}>
              <ArgonBox>
                <ArgonBox mb={2}>
                  <ArgonInput
                    placeholder="bee@hosthive.io"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // Add error handling from Formik state
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  {errors.email && touched.email && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.email} />
                  )}
                </ArgonBox>
                <ArgonBox mt={5} mb={1}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    size="large"
                    fullWidth
                    type="submit"
                  >
                    Subscribe
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </form>
        )}
      </Card>
    </BasicLayout>
  );
}

export default SubscribeToBlog;
