/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import { useState, useEffect, useContext, useMemo } from "react";
import HostHiveDialog from "components/HostHiveDialog";
import TaskItemDialog from "../components/TaskItemDialog";
import ArgonBadgeDot from "components/ArgonBadgeDot";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

import Divider from "@mui/material/Divider";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonInput from "components/ArgonInput";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TaskTimeLine from "../components/TaskTimeLineDataCard";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "../components/DetailedStatisticsCard";
import HorizontalBarChart from "layouts/administration/timeTracking/components/HorizontalBarChart";
import { Link, useParams } from "react-router-dom";
// Argon Dashboard 2 PRO MUI base styles
import typography from "assets/theme/base/typography";

import Card from "@mui/material/Card";
import TasksApi from "api/propertyTasksApi";

import TimelineItem from "../components/TimelineItem";
import HostHiveSnackbar from "components/HostHiveSnackbar";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import PropertiesApi from "api/propertiesApi";
// Data
import HostHiveDataTable from "components/HostHiveDataTable";
import { columns } from "layouts/administration/timeTracking/components/timeTrackingColumns/";
import { useTranslation } from "react-i18next";
import TimeTrackingApi from "api/timeTrackingApi";
import convertToOptions from "services/convertooptions";
import NewTimeTrackingItem from "layouts/administration/timeTracking/components/NewTimeTrackingItem/index.js";
import UsersApi from "api/usersApi";
import CategoriesApi from "api/categoriesApi";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import { VideoLibraryHelper } from "services/videoLibraryHelper";
// import PropertiesApi from "api/propertiesApi";
function TimeTracker({ companyId, propertyId = null }) {
  const { t } = useTranslation("common");
  const createTimeEntryVideoId = VideoLibraryHelper("timeTracking").videoId;
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const usersApi = new UsersApi();
  const timeTrackingApi = TimeTrackingApi();
  const propertiesApi = PropertiesApi();
  const categoriesApi = CategoriesApi();
  const { size } = typography;
  const [stringDateRange, setStringDateRange] = useState(null);
  const [timeTrackingData, setTimeTrackingData] = useState({
    columns: columns,
    rows: [
      {
        summary: "",
        description: "",
        status: "",
        ticketNumber: "",
        created: "",
        priority: "",
      },
    ],
  });

  //const [propertyId, setPropertyId] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [searchUser, setSearchUser] = useState({
    label: "All",
    value: null,
  });
  const [users, setUsers] = useState([]);
  // const [property, setProperty] = useState(null);
  const [property, setProperty] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [tasks, setTasks] = useState({});
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [loggedTime, setLoggedTime] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [loadingItems, setLoadingItems] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [entityType, setEntityType] = useState({
    label: "All",
    value: "all",
  });
  const [category, setCategory] = useState({
    label: "All",
    value: "all",
  });
  const [categories, setCategories] = useState([]);
  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [startFilterStage, setStartFilterStage] = useState(null);
  const [endFilterStage, setEndFilterStage] = useState(null);
  const [searchStage, setSearchStage] = useState(null);
  const [limit, setLimit] = useState(10);
  const [transactionType, setTransactionType] = useState("all");
  const [searchField, setSearchField] = useState(null);
  const [propertyHoursData, setPropertyHoursData] = useState(null);
  const [typeChartData, setTypeChartData] = useState(null);
  const [categoryChartData, setCategoryChartData] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  useEffect(() => {
    const loadData = async () => {
      try {
        let queryParams = {
          companyId: companyId,

          limit: 1000,
          forUi: true,
        };
        if (property) {
          queryParams.propertyId = property?.value;
        }

        if (startDate) {
          queryParams.startDate = startDate;
        }

        if (endDate) {
          queryParams.endDate = endDate;
        }
        if (entityType?.value) {
          queryParams.entityType = entityType?.value;
        }

        if (searchField) {
          queryParams.search = searchField;
        }

        if (searchUser?.value) {
          queryParams.userId = searchUser?.value;
        }

        if (category?.value) {
          queryParams.categoryId = category?.value;
        }

        const response = await timeTrackingApi.getLoggedTimes(queryParams);
        queryParams.formatUi = true;
        const chartResponse = await timeTrackingApi.getTotalTimeData(
          queryParams
        );

        console.log("chart response", chartResponse);

        if (response.status >= 200 && response.status < 300) {
          setLoggedTime(response?.data?.items);
          setTotalHours(response?.data?.totalHours);
          setStringDateRange(response?.data?.dateRange);
          setPropertyHoursData(
            formatHorizontalBarChartData(
              chartResponse?.data?.propertiesTotalTimeSpent,
              "Hours",
              "primary"
            )
          );
          setTypeChartData(
            formatHorizontalBarChartData(
              chartResponse?.data?.entityTypeTotalTimeSpent,
              "Hours",
              "dark"
            )
          );
          setCategoryChartData(
            formatHorizontalBarChartData(
              chartResponse?.data?.categoriesTotalTimeSpent,
              "Hours",
              "info"
            )
          );
          // setEndDate(response?.data?.dateRange?.endDate);
          // setStartDate(response?.data?.dateRange?.startDate);
          setResultsCount(response?.data?.total || 0);
          setTimeTrackingData({
            columns: timeTrackingData?.columns,
            rows: response?.data?.items || [],
          });
        }
        //
      } catch (error) {
        setError(error);
        console.log("error found", error);
      } finally {
        setIsLoading(false);
        console.log(tasks);
      }
    };

    loadData();
  }, [
    startDate,
    endDate,
    property,
    triggerFetch,
    entityType,
    limit,
    searchField,
    searchUser,
    category,
  ]);
  const [properties, setProperties] = useState([]);
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await propertiesApi.getProperties({
          companyId: company?.id,
          ownerOnly: true,
        });

        const options = convertToOptions(response?.data);
        // options.push({ label: "All", value: "all" });
        options.unshift({ label: "All", value: "all" });
        setProperties(options);

        setProperty(options[0]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await usersApi.getUsers({
          companyId: company?.id,
        });

        // const options = convertToOptions(response?.data);
        const options = response?.data.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));
        // options.push({ label: "All", value: "all" });
        options.unshift({ label: "All", value: null });
        setUsers(options);

        setSearchUser(options[0]);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await categoriesApi.getCategories({
          companyId: company?.id,
          type: "timeTracking",
        });

        // const options = convertToOptions(response?.data);
        const options = response.data.map((category) => ({
          value: category.id,
          label: (
            <ArgonBadgeDot
              color={category?.color}
              size="md"
              badgeContent={category?.name}
            />
          ),
        }));
        // options.push({ label: "All", value: "all" });
        options.unshift({ label: "All", value: null });
        setCategories(options);

        // setSearchUser(options[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
    fetchProperties();
    fetchCategories();
  }, []);

  const [controller] = useArgonController();
  const { darkMode } = controller;
  //import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
  const statusIcon = (status) => {
    if (status === "open") {
      return <KeyboardDoubleArrowUpIcon />;
    } else if (status === "inProgress") {
      return "";
    } else if (status === "closed") {
      return "";
    }
  };

  const [open, setOpen] = useState(false);

  const handleSubmitClicked = async () => {
    const payload = {
      companyId: company?.id,
    };
    try {
      const response = await timeTrackingApi.logTime(payload);
    } catch (error) {
      console.log(error);
    }
    setOpen(false);
  };

  const limitOptions = [
    { label: 10, value: 10 },
    { label: 20, value: 20 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 400, value: 400 },
  ];

  const entityTypes = [
    { label: "All", value: "all" },
    { label: "Service Appointments", value: "serviceAppointment" },
    { label: "Property Tasks", value: "propertyTasks" },
  ];

  const handleSearchClicked = () => {
    setSearchField(searchStage);
    setSearchStage(null);
  };

  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const handleSetStartDate = (date, param) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    if (param === "startDate") {
      setStartDate(useDate);
    } else {
      setEndDate(useDate);
    }
  };

  const resetSearch = () => {
    setSearchField(null);
    setStartDate(null);
    setEndDate(null);
    setSearchStage("");
  };
  const TableFilterOptions = ({}) => {
    return (
      <>
        <Grid item xs={12} lg={3}>
          <ArgonTypography
            variant="caption"
            fontWeight="bold"
            disabled={loadingItems}
          >
            Property
          </ArgonTypography>
          <ArgonSelect
            value={property}
            onChange={(e) => setProperty(e)}
            options={properties}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography
            variant="caption"
            fontWeight="bold"
            disabled={loadingItems}
          >
            Uer
          </ArgonTypography>
          <ArgonSelect
            value={searchUser}
            onChange={(e) => setSearchUser(e)}
            options={users}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography
            variant="caption"
            fontWeight="bold"
            disabled={loadingItems}
          >
            Type
          </ArgonTypography>
          <ArgonSelect
            value={{
              label: entityType?.label,
              value: entityType?.value,
            }}
            onChange={(e) => setEntityType(e)}
            options={entityTypes}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography
            variant="caption"
            fontWeight="bold"
            disabled={loadingItems}
          >
            Category
          </ArgonTypography>
          <ArgonSelect
            value={{
              label: category?.label,
              value: category?.value,
            }}
            onChange={(e) => setCategory(e)}
            options={categories}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography variant="caption" fontWeight="bold">
            FROM
          </ArgonTypography>
          <ArgonDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => handleSetStartDate(e, "startDate")}
            disabled={loadingItems}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography variant="caption" fontWeight="bold">
            TO
          </ArgonTypography>
          <ArgonDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => handleSetStartDate(e, "endDate")}
            disabled={loadingItems}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <ArgonTypography variant="caption" fontWeight="bold">
            Display
          </ArgonTypography>
          <ArgonSelect
            value={{ label: limit, value: limit }}
            onChange={(e) => setLimit(e.value)}
            options={limitOptions}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <ArgonTypography variant="caption" fontWeight="bold">
            Search
          </ArgonTypography>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <ArgonInput
                value={searchStage}
                onChange={(e) => setSearchStage(e.target.value)}
                disabled={loadingItems}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <ArgonButton
                variant="gradient"
                color="warning"
                onClick={handleSearchClicked}
                disabled={loadingItems}
              >
                Find
              </ArgonButton>
              {/* <ArgonTypography variant="caption" fontWeight="bold">
                          {` Results found (${resultsCount})`}
                        </ArgonTypography> */}
            </Grid>
            <Grid item xs={12} lg={2}>
              <ArgonButton
                variant="gradient"
                color="light"
                onClick={resetSearch}
              >
                Reset
              </ArgonButton>
            </Grid>
            <Grid item xs={12}>
              <ArgonTypography variant="caption" fontWeight="bold">
                {`${loadingItems ? "-" : resultsCount} Results found`}
              </ArgonTypography>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  const [openFilterOptions, setOpenFilterOptions] = useState(false);

  const formatHorizontalBarChartData = (data, barChartType, barChartColor) => {
    const formattedData = {
      labels: data?.labels,
      datasets: [
        {
          label: barChartType,
          color: barChartColor,
          data: data?.datasets,
        },
      ],
    };
    return formattedData;
  };
  return (
    <>
      <ArgonBox py={3}>
        <HostHiveDialog
          open={openFilterOptions}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          maxWidth="md"
          onClose={() => setOpenFilterOptions(false)}
          onSave={() => setOpenFilterOptions(false)}
          title="Filter Options"
          fullScreen={false}
          cancelButtonText="View Results"
          submitButtonText="Save"
          dialogObjects={<TableFilterOptions />}
        />
        <HostHiveDialog
          open={open}
          fullWidth={true}
          includeSave={false}
          includeClose={false}
          maxWidth="md"
          onClose={() => setOpen(false)}
          onSave={handleSubmitClicked}
          title="Log Time"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <NewTimeTrackingItem
              // setFetchLoggedItems={setTriggerFetch}
              onClose={() => {
                setOpen(false);
              }}
              setFetchLoggedItems={() => setTriggerFetch(!triggerFetch)}
              openSuccessSB={openSuccessSB}
            />
          }
        />
        <Card>
          <Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  p={3}
                >
                  {renderSuccessSB}
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                      <ArgonBox lineHeight={1}>
                        <ArgonTypography variant="h5" fontWeight="medium">
                          Time Tracking
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          fontWeight="regular"
                          color="text"
                        >
                          The Time Tracking dashboard is a powerful tool within
                          HostHive that allows for the meticulous tracking of
                          time spent on various tasks related to property
                          management.
                        </ArgonTypography>
                      </ArgonBox>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Stack spacing={1} direction="row">
                        {/* <ArgonBox>
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            size="small"
                            onClick={() => setOpen(true)}
                          >
                            + Log Time
                          </ArgonButton>
                        </ArgonBox> */}
                        <ArgonBox>
                          <ArgonButton
                            variant="gradient"
                            color="warning"
                            size="small"
                            onClick={() => setOpenFilterOptions(true)}
                          >
                            Apply Filters
                          </ArgonButton>
                        </ArgonBox>
                      </Stack>
                    </Grid>
                  </Grid>
                </ArgonBox>
                <Divider />
                {/* <Grid container spacing={3} mb={3}>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("components.tasks.urgent")}
                      count={
                        tasks?.highPriorityTotal ? tasks?.highPriorityTotal : 0
                      }
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-money-coins" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("generalWords.open")}
                      count={tasks?.openTotal ? tasks?.openTotal : 0}
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-world" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("generalWords.inProgress")}
                      count={
                        tasks?.inProgressTotal ? tasks?.inProgressTotal : 0
                      }
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-paper-diploma" />,
                      // }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={3}>
                    <DetailedStatisticsCard
                      title={t("components.tasks.status.onHold")}
                      count={tasks?.onHoldTotal ? tasks?.onHoldTotal : 0}
                      // icon={{
                      //   color: "secondary",
                      //   component: <i className="ni ni-cart" />,
                      // }}
                    />
                  </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                    <DefaultCounterCard
                      count={totalHours}
                      suffix="hrs"
                      title="Total Hours Logged"
                      description=""
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                    <DefaultCounterCard
                      count={100}
                      suffix="hrs"
                      title="Total Hours Logged"
                      description=""
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DefaultCounterCard
                      count={100}
                      suffix="hrs"
                      title="Total Hours Logged"
                      description=""
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DefaultCounterCard
                      count={100}
                      suffix="hrs"
                      title="Total Hours Logged"
                      description=""
                    />
                  </Grid> */}
                </Grid>
                <ArgonBox mb={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4}>
                      {propertyHoursData ? (
                        <HorizontalBarChart
                          title="Total Hours by Property"
                          chart={propertyHoursData}
                          transactionType="income"
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {typeChartData ? (
                        <HorizontalBarChart
                          title="Total Hours by Type"
                          chart={typeChartData}
                          transactionType="expense"
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      {typeChartData ? (
                        <HorizontalBarChart
                          title="Total Hours by Category"
                          chart={categoryChartData}
                          transactionType="expense"
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                </ArgonBox>

                <Grid container spacing={3} mb={3}>
                  <Grid item xs={12}>
                    <>
                      <HostHiveDataTable
                        setOpenFilterOptions={setOpenFilterOptions}
                        // header="Logged Time"
                        table={timeTrackingData}
                        entriesPerPage={false}
                        canSearch
                        exportToCsv={() => console.log("export")}
                        importFromFile={() => console.log("import")}
                        setProperty={setProperty}
                        property={property}
                        newEntryAction={() => setOpen(true)}
                        newEntryVariant="gradient"
                        newEntryColor="info"
                        newEntryText="Log Time"
                        newEntryVideoHelp={createTimeEntryVideoId}
                      />
                    </>
                  </Grid>
                </Grid>

                <Divider />
              </Card>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
    </>
  );
}

export default TimeTracker;
