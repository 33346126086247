/**
 * =========================================================
 * Argon Dashboard 2 PRO MUI - v3.0.0
 * =========================================================
 *
 * Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 *
 * Coded by www.creative-tim.com
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// @mui material components
import Card from "@mui/material/Card";
// @mui material components
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// import EventDataCard fro./components/EventDataCard/index1ard";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { useState, useEffect } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
// Custom styles for Calendar
import CalendarRoot from "examples/Calendar/CalendarRoot";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
import updateCalendarEvents from "services/calendarUtils";
import BookingsApi from "api/bookingsApi";
function Calendar({ header, externalFetch, property = null, ...rest }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [bookings, setBookings] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [propertyInfo, setPropertyInfo] = useState(property);
  const [serviceNames, setServiceNames] = useState(null);
  const [isLoadingServiceNames, setIsLoadingServiceNames] = useState(true);
  const [bookingId, setBookingId] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const bookingsApi = BookingsApi();

  const handleClose = () => {
    setOpen(false);
    setCreateBooking(false);
  };

  const [createBooking, setCreateBooking] = useState(false);
  const [isCreation, setIsCreation] = useState(false);
  const [events, setEvents] = useState(null);
  const [refetchBookings, setRefetchBookings] = useState(false);
  useEffect(() => {
    const loadBookings = async () => {
      let queryParams = {
        limit: 1000,
      };
      if (propertyInfo === undefined) {
        queryParams.companyId = company?.id;
      } else {
        queryParams.propertyId = propertyInfo?.id;
      }

      try {
        const response = await bookingsApi.getBookings(queryParams);
        console.log(response.data, "response.data");
        setBookings(response.data);
        setEvents(updateCalendarEvents(response.data, [], [], []));
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadBookings();
  }, [refetchBookings, externalFetch]);

  const handleOpenModal = (type) => {
    setModalTitle(type);
    setOpen(true);
  };

  const handleCreateBookingClicked = () => {
    setBookingId(null);
    setBookingInfo(null);
    setCreateBooking(true);
    setIsCreation(true);
    handleOpenModal("New Service Assignment");
  };

  const findBooking = (bookingId, bookings) => {
    const foundBooking = bookings.find((booking) => booking.id === bookingId);
    return foundBooking || null;
  };

  const handleBookingClicked = async (events) => {
    console.log(events.event.id, "bookingId in Calendar");
    // const bookingId = events.event.id;
    // setIsCreation(false);
    // setBookingId(bookingId);

    let bookingInfo = findBooking(events.event.id, bookings);
    console.log(bookingInfo, "bookingInfo");
    setBookingInfo(bookingInfo);
    handleOpenModal("View Scheduled Service");
    // if (bookingInfo !== null) {
    //   setBookingInfo(bookingInfo);
    //   handleOpenModal("View Scheduled Service");
    // }
  };

  const handleCancelClicked = () => {
    setOpen(false);
  };

  const handleSubmitClicked = async () => {
    setOpen(false);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        pt={2}
        px={2}
        lineHeight={1}
        justifyContent="space-between"
        display="flex"
      >
        <ArgonBox>
          {header.title ? (
            <ArgonTypography
              variant="h6"
              fontWeight="medium"
              textTransform="capitalize"
            >
              {header.title}
            </ArgonTypography>
          ) : null}
          {header.date ? (
            <ArgonTypography
              component="p"
              variant="button"
              color="text"
              fontWeight="medium"
            >
              {header.date}
            </ArgonTypography>
          ) : null}
        </ArgonBox>
        <ArgonBox>
          <ArgonButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleCreateBookingClicked}
          >
            + New Service Assigment
          </ArgonButton>
        </ArgonBox>
      </ArgonBox>
      <CalendarRoot p={2} ownerState={{ darkMode }}>
        <FullCalendar
          {...rest}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          events={events}
          height="100%"
          eventClick={(events) => handleBookingClicked(events)}
        />
      </CalendarRoot>

      {/* Create Booking Modal */}
      <ArgonBox>
        {/* <HostHiveDialog
          open={open}
          fullWidth={true}
          includeSave={false}
          includeClose={false}
          maxWidth="md"
          onClose={handleCancelClicked}
          onSave={handleSubmitClicked}
          title={modalTitle}
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          showStatusBadge={true}
          statusColor="success"
          statusContent={bookingInfo?.status}
          dialogObjects={
            <EventDataCard
              bookingInfo={bookingInfo}
              propertyInfo={propertyInfo}
              isCreation={isCreation}
              onClose={() => {
                setOpen(false);
              }}
              setRefetchBookings={() => setRefetchBookings(!refetchBookings)}
            />
          }
        /> */}
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of Calendar
Calendar.defaultProps = {
  header: {
    title: "",
    date: "",
  },
};

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
};

export default Calendar;
