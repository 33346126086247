import React from "react";
import moment from "moment-timezone";
export const today = new Date();
export const year = today.getFullYear();
export const month = String(today.getMonth() + 1).padStart(2, "0");
export const day = String(today.getDate()).padStart(2, "0");
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const dayOfWeek = daysOfWeek[today.getDay()];
export const monthName = today.toLocaleString("default", { month: "long" });
export const formattedDate = `${year}-${month}-${day}`;
export const start_date = new Date().toISOString().slice(0, 10);

export const timeAgo = (unixTimestamp, timezone = "America/New_York") => {
  // Convert the Unix timestamp to a Date object
  const date = new Date(unixTimestamp); // If the timestamp is in milliseconds, no need to multiply by 1000

  // Create a moment instance from the date and convert it to the specified timezone
  const momentDate = moment(date).tz(timezone);

  // Use moment's fromNow() function to get a time ago format
  return momentDate.fromNow();
};

export const convertTimeStamp = (
  unixTimestamp,
  timezone = "America/New_York"
) => {
  // Convert the Unix timestamp to a Date object
  const date = new Date(parseInt(unixTimestamp)); // Multiply by 1000 to convert to milliseconds

  // Use moment-timezone to format the date in the specified timezone
  const formattedDate = moment.tz(date, timezone).format("YYYY-MM-DD HH:mm:ss");

  return formattedDate;
};

export const formatIsoDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDate = date.toISOString().split("T")[0];
  return formattedDate;
};
export const intervalDisplayValue = (value, intervalValue) => {
  if (value === "weekly") {
    return "Weeks";
  } else if (value === "monthly") {
    if (intervalValue === "1") {
      return "Month";
    } else {
      return "Months";
    }
  } else if (value === "daily") {
    return "Days";
  } else if (value === "yearly") {
    return "Years";
  }
};
