const convertToOptions = (array, returnEmail = false) => {
  // If array is empty, return the empty array
  if (array.length === 0) return [];
  if (returnEmail) {
    return array.map((item) => ({
      value: item.id,
      label: item.email,
    }));
  }
  return array.map((item) => ({
    value: item.id,
    label: item.name,
  }));
};

export default convertToOptions;
