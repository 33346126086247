/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// @mui material components
import TransactionItemSchema from "schemas/newTransaction";
import LogItemSchema from "schemas/newTimeLog";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import CategoryDialog from "layouts/management/components/CategoryDialog";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import CompanyApi from "api/companyApi";
import PropertySelect from "layouts/management/components/PropertySelect";
import BookingsApi from "api/bookingsApi";
import ServiceNameApi from "api/service-nameApi";
import SettingsApi from "api/settingsApi";
import ArgonAlert from "components/ArgonAlert";
import ChecklistsApi from "api/checkListsApi";
import ApplicationApi from "api/applicationApi";
import PropertiesApi from "api/propertiesApi";
import AccountingApi from "api/accountingApi";
import { UserAuth } from "context/AuthContext";
import convertToOptions from "services/convertooptions";
import TasksApi from "api/propertyTasksApi";
import TimeTrackingApi from "api/timeTrackingApi";
import CategoriesApi from "api/categoriesApi";
import HostHiveDialog from "components/HostHiveDialog";
function NewTimeTrackingItem({ onClose, setFetchLoggedItems, propertyInfo }) {
  const navigate = useNavigate();
  const { user } = UserAuth();
  const tasksApi = TasksApi();
  const timeTrackingApi = TimeTrackingApi();
  const categoriesApi = CategoriesApi();

  const [property, setProperty] = useState(null);

  const { t } = useTranslation("common");
  const [categories, setCategories] = useState([]);
  const [isNewCategory, setIsNewCategory] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState(null);
  const [fetchCategories, setFetchCategories] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [timeSpentValue, setTimeSpentValue] = useState(60);
  const [timeSpentInterval, setTimeSpentInterval] = useState("minutes");
  const [showEntitySelection, setShowEntitySelection] = useState(false);
  const [entityType, setEntityType] = useState({});
  const [entity, setEntity] = useState({});
  const bookingsApi = new BookingsApi();
  const [foundEntities, setFoundEntities] = useState([
    {
      value: 1,
      label: "Select to link to time log",
    },
  ]);
  const [triggerOptionsFetch, setTriggerOptionsFetch] = useState(false);
  useEffect(() => {
    let queryParams = {
      companyId: company?.id,
    };
    if (property) {
      queryParams.propertyId = property?.value;
    }
    async function fetchTasks() {
      console.log("fetchTasks");
      const response = await tasksApi.getTasks(queryParams);
      const items = response?.data?.items?.map((item) => ({
        value: item.id,
        label: `${item.summary} - #${item.ticketNumber}`,
      }));
      console.log("items", items);
      setFoundEntities(items);
      setShowEntitySelection(true);
    }

    async function fetchBookings() {
      console.log("fetchBookings");
      const response = await bookingsApi.getBookings(queryParams);
      const items = response.data.map((item) => ({
        value: item.id,
        label: `${item.serviceName} - ${item.serviceScheduleDate}`,
      }));
      console.log("items", items);
      setFoundEntities(items);
      setShowEntitySelection(true);
    }

    if (entityType === "propertyTasks") {
      fetchTasks();
    }
    if (entityType === "serviceAppointment") {
      // console.log("serviceAppointment");
      fetchBookings();
    }
  }, [entityType, triggerOptionsFetch]);

  useEffect(() => {
    async function fetchCategories() {
      if (company) {
        try {
          const queryParams = {
            companyId: company?.id,
            type: "timeTracking",
          };

          const response = await categoriesApi.getCategories(queryParams);
          const categoriesResponse = response.data;

          let options = categoriesResponse.map((category) => ({
            color: category?.color,
            value: category.id,
            text: category.name,
            label: (
              <ArgonBadgeDot
                color={category?.color}
                size="md"
                badgeContent={category?.name}
              />
            ),
          }));

          options.push({
            value: "new",
            label: `+ ${t("components.category.create.createNew")}`,
          });
          setCategories(options);
        } catch (error) {
          console.error(error);
        }
      }
    }

    fetchCategories();
    // fetchChecklists();
  }, [fetchCategories]);

  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("warning");
  const [alertMessage, setAlertMessage] = useState(
    "Some long alert message about something that went wrong."
  );
  const [note, setNote] = useState(null);
  const [category, setCategory] = useState({});
  const onSubmit = async () => {
    if (!property?.value && !propertyInfo?.propertyId) {
      console.log(propertyInfo);
      console.error("property is required");
      setAlertColor("error");
      setAlertMessage("Property selection is Required");
      setShowAlert(true);
      return;
    }
    console.log("Here");
    let payload = {
      companyId: company?.id,
      propertyId: property?.value,
      entityId: entity?.value,
      entityType: entityType,
      status: "active",
      userId: user?.uid,
      timeSpent: {
        value: timeSpentValue,
        interval: timeSpentInterval,
      },
      categoryId: category?.value,
    };
    if (note) {
      payload.note = note;
    }

    console.log("Payload:", payload);
    try {
      const response = await timeTrackingApi.createLogTimeEntry(payload);
      console.log(response);
      setFetchLoggedItems();
      onClose();
    } catch (error) {
      console.error(error);
    }
    // if (property?.value && !propertyInfo?.propertyId) {
    //   values.propertyId = property?.value;
    // }

    // if (!category?.value) {
    //   values.categoryId = "none";
    // } else {
    //   values.categoryId = category?.value;
    // }

    // if (loggingType?.value === "expense") {
    //   values.amount = -Math.abs(values.amount);
    // } else {
    //   values.amount = Math.abs(values.amount);
    // }
    // values.companyId = company?.id;
    // console.log(values);
    // setIsSaving(true);
    // try {
    //   let response;
    //   response = await accountingApi.createTransaction(values);
    //   //  response = await propertyApi.createProperty(values);
    //   if (response.status >= 200 && response.status < 300) {
    //     console.log("response", response);
    //     setFetchLoggedItems();
    //   }
    //   setIsSaving(false);

    //   onClose();
    // } catch (error) {
    //   setAlertColor("error");
    //   setAlertMessage(error.response.data.detail);
    //   setShowAlert(true);

    //   setIsSaving(false);
    // }
  };

  useEffect(() => {
    setLoggingType({
      value: null,
      label: "Select Log Type",
    });
    setEntity({});
    setTriggerOptionsFetch(!triggerOptionsFetch);
    setShowEntitySelection(false);
  }, [property]);

  const [loggingType, setLoggingType] = useState({});
  const intervalOptionsDuration = [
    { value: "minutes", label: t("time.selection.minutes") },
    { value: "hour", label: t("time.selection.hours") },
    { value: "days", label: t("time.selection.days") },
  ];
  const loggingTypeOptions = [
    {
      value: "propertyTasks",
      label: "Tasks",
    },
    {
      value: "serviceAppointment",
      label: "Service Appointment",
    },
  ];

  const handleCategorySelected = (e) => {
    if (e.value === "new") {
      setIsNewCategory(true);
    } else {
      setIsNewCategory(false);
      console.log("category selected", e);
      setCategory(e);
      // setBookingPayload({ ...bookingPayload, categoryId: e?.value });
      // setUserSelections({
      //   ...userSelections,
      //   [e?.value]: e?.label,
      // });
    }

    // if (!isInCreationMode) {
    //   updateBooking("categoryId", e?.value);
    // }
  };
  return (
    <>
      {isSaving ? (
        <HostHiveLoading message={t("generalWords.saving")} elipse={true} />
      ) : (
        <>
          {showAlert ? (
            <ArgonAlert color={alertColor} dismissible>
              <ArgonBox display="flex" justifyContent="flex-end">
                <ArgonBox>
                  <ArgonTypography variant="caption" color="white">
                    {alertMessage}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonAlert>
          ) : null}

          <HostHiveDialog
            includeDelete={false}
            includeSave={false}
            includeClose={false}
            open={isNewCategory}
            onClose={() => {
              setIsNewCategory(false);
            }}
            title={t("components.category.create.createNew")}
            fullScreen={false}
            cancelButtonText="Close"
            submitButtonText="Create"
            maxWidth="sm"
            dialogObjects={
              <CategoryDialog
                categoryType="timeTracking"
                companyId={company?.id}
                handleCategorySelected={handleCategorySelected}
                setCategoryDetails={setCategoryDetails}
                handleSelection={true}
                // fetchServiceNames={() =>
                //   setCreatedServiceName(!createdServiceName)
                // }
                fetchCategories={() => setFetchCategories(!fetchCategories)}
                onClose={() => {
                  setIsNewCategory(false);
                }}
                // snackBarOpen={openSuccessSB}
              />
            }
          />

          <ArgonBox p={2} lineHeight={1}>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} lg={9}>
                {" "}
                <ArgonBox>
                  {propertyInfo?.propertyName ? (
                    <>{propertyInfo?.propertyName}</>
                  ) : (
                    <PropertySelect
                      menuPlacement="bottom"
                      property={property}
                      setProperty={setProperty}
                      companyId={company?.id}
                    />
                  )}
                </ArgonBox>
              </Grid>
              {property?.value ? (
                <Grid item xs={12} lg={9}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <ArgonBox
                      mb={1}
                      ml={0.5}
                      mt={3}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Type
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonSelect
                      value={loggingType}
                      onChange={(e) => {
                        setLoggingType(e);
                        setEntityType(e?.value);
                      }}
                      options={loggingTypeOptions}
                    />
                  </ArgonBox>
                </Grid>
              ) : null}
              {showEntitySelection ? (
                <Grid item xs={12} lg={9}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <ArgonBox
                      mb={1}
                      ml={0.5}
                      mt={3}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                      >
                        Entity to link
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonSelect
                      value={entity}
                      onChange={(e) => {
                        setEntity(e);
                      }}
                      options={foundEntities}
                    />
                  </ArgonBox>
                </Grid>
              ) : null}

              <Grid item xs={12} lg={9}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Category
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonSelect
                    menuPlacement="bottom"
                    defaultValue={{
                      value: "none",
                      label: t("components.category.select"),
                    }}
                    value={category}
                    options={categories}
                    onChange={handleCategorySelected}
                    fullWidth
                  />
                </ArgonBox>
              </Grid>

              <Grid item xs={12} lg={9}>
                {" "}
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Time Spent
                    </ArgonTypography>
                  </ArgonBox>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <ArgonInput
                        name="intervalValue"
                        type="text"
                        label="Interval Value"
                        placeholder="1"
                        value={timeSpentValue}
                        onChange={(e) => setTimeSpentValue(e.target.value)}
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <ArgonSelect
                        options={intervalOptionsDuration}
                        value={{
                          value: timeSpentInterval,
                          label: timeSpentInterval,
                        }}
                        onChange={(e) => setTimeSpentInterval(e?.value)}
                      />
                    </Grid>
                  </Grid>

                  {/* {errors.summary && touched.summary && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.summary} />
                  )} */}
                </ArgonBox>
              </Grid>
              <Grid item xs={12} lg={9}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox
                    mb={1}
                    ml={0.5}
                    mt={3}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                    >
                      Note
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonInput
                    name="note"
                    type="text"
                    label="Note"
                    placeholder=""
                    value={note}
                    onChange={(e) => setNote(e.target.value)}

                    // onChange={(e) => console.log(e)}
                  />
                </ArgonBox>
              </Grid>
            </Grid>
          </ArgonBox>

          <ArgonBox justifyContent="center" display="flex" p={2}>
            <ArgonBox display="flex">
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={() => onClose()}
                >
                  {t("buttons.cancel")}
                </ArgonButton>
              </ArgonBox>
              <ArgonBox pr={2}>
                <ArgonButton variant="gradient" color="info" onClick={onSubmit}>
                  {t("buttons.save")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
}

export default NewTimeTrackingItem;
