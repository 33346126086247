import React, { useState, useEffect } from "react";
import ArgonSnackbar from "components/ArgonSnackbar";

function HostHiveSnackbar() {
  const [snackTitle, setSnackTitle] = useState("");
  const [snackContent, setSnackContent] = useState("");
  const [successSB, setSuccessSB] = useState(false);

  useEffect(() => {
    if (successSB) {
      setTimeout(() => {
        setSuccessSB(false);
      }, 5000); // Delay for 5 seconds
    }
  }, [successSB]);

  const openSuccessSB = (title, content) => {
    setSnackTitle(title);
    setSnackContent(content);
    setSuccessSB(true);
  };

  const closeSuccessSB = () => {
    setSuccessSB(false);
  };

  const renderSuccessSB = (
    <ArgonSnackbar
      color="success"
      icon="check"
      title={snackTitle}
      content={snackContent}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <ArgonSnackbar
      color="error"
      icon="error"
      title={snackTitle}
      content={snackContent}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const openErrorSB = (title, content) => {
    setSnackTitle(title);
    setSnackContent(content);
    setSuccessSB(true);
  };

  return { renderSuccessSB, openSuccessSB, openErrorSB, renderErrorSB };
}

export default HostHiveSnackbar;
