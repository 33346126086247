import * as Yup from "yup";

const TransactionItemSchema = Yup.object().shape({
  comment: Yup.string(),
  propertyId: Yup.string().nullable(true),
  companyId: Yup.string().required("Company is required"),
  summary: Yup.string().required("Summary is required"),
  amount: Yup.number().required("Amount is required"),
  description: Yup.string(),
});

export default TransactionItemSchema;
