/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
import ArgonBadgeDot from "components/ArgonBadgeDot";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import { showDeleteConfirmation } from "services/confirmDelete";
import ServiceNameApi from "api/service-nameApi";
import CategoriesApi from "api/categoriesApi";
// import ServiceNameItemDialog from "./ServiceNameItemDialog";
import HostHiveDialog from "components/HostHiveDialog";
import CategoryDialog from "layouts/management/components/CategoryDialog";
function CategoryItem({ key, category, fetchCategories, openSuccessSB }) {
  const { borderWidth } = borders;
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const serviceNamesApi = new ServiceNameApi();
  const categoriesApi = new CategoriesApi();

  const handleDeleteAction = async () => {
    try {
      await showDeleteConfirmation(); // Wait for the promise to resolve/reject
      console.log(`User confirmed deletion! ${category?.id}`);

      const deleteResponse = await categoriesApi.deleteCategory(category?.id);
      if (deleteResponse.status === 200) {
        console.log("Category deleted successfully");
        fetchCategories();
      }

      // Proceed with delete action
    } catch (error) {
      console.log("User cancelled deletion!");
    }
    setOpenMenu(null);
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {/* <MenuItem onClick={() => handleViewServiceNameItemDetails(id)}>
        View
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          setCategoryDialogOpen(true);
          handleCloseMenu();
        }}
      >
        Edit
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleDeleteAction();
          handleCloseMenu();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        mt: 2,
        ml: 3,
        pl: 0.5,
      }}
    >
      <div
        component="li"
        style={{
          width: "100%",
          pr: 2,
          mb: 2,
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <div style={{ width: "100%", pl: 1, ml: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
              ml: 3,
              pl: 0.5,
              alignItems: { xs: "flex-start", sm: "center" },
              overflow: "hidden",
            }}
          >
            {/* Summary */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                <ArgonBadgeDot
                  color={category?.color}
                  size="lg"
                  badgeContent={category?.name}
                  // sx={{
                  //   ":hover": { textDecoration: "underline" },
                  //   cursor: "pointer",
                  // }}
                  // onClick={() => setCategoryDialogOpen(true)}
                />
              </ArgonTypography>
            </div>

            {renderMenu()}
            <HostHiveDialog
              includeDelete={false}
              includeClose={false}
              includeSave={false}
              open={categoryDialogOpen}
              onClose={() => {
                setCategoryDialogOpen(false);
              }}
              title="Edit Category"
              fullScreen={false}
              cancelButtonText="Close"
              submitButtonText="Create"
              maxWidth="sm"
              dialogObjects={
                <>
                  <CategoryDialog
                    onClose={() => setCategoryDialogOpen(false)}
                    categoryDetails={category}
                    categoryType={category?.type}
                    existingColor={category?.color}
                    fetchCategories={fetchCategories}
                  />
                </>
              }
            />
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </div>
          </div>
        </div>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

export default CategoryItem;
