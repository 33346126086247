//application/integrationTypes

import useAxiosPrivate from "hooks/useAxiosPrivate";

function ApplicationApi() {
    const axiosPrivate = useAxiosPrivate();


    const getIntegrationTypes = () => {
        return axiosPrivate.get(`/application?requestType=integrationTypes`);
    }

    const getOccupancyStatusTypes = () => {
        return axiosPrivate.get(`/application?requestType=occupancyStatusTypes`);
    }

    const getReportStatusTypes = () => {
        return axiosPrivate.get(`/application?requestType=reportStatusTypes`);
    }

    
    return {
        getIntegrationTypes,
        getOccupancyStatusTypes,
        getReportStatusTypes
    };
}

export default ApplicationApi;