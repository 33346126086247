/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonBadge from "components/ArgonBadge";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
function OccupancyStatusDialog({
  propertyId,
  currentStatus,
  occupancyStatusTypes,
  setUpdatedOccupancyStatus,
  updatedOccupancyStatu,
}) {
  const { t } = useTranslation("common");
  const [successColor, setSuccessColor] = useState("success");
  const [errorColor, setErrorColor] = useState("error");
  const [warningColor, setWarningColor] = useState("warning");
  const [infoColor, setInfoColor] = useState("primary");
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusClicked = (displayName, selectKey, color) => {
    console.log(selectKey);
    const statusDetails = {
      displayName: displayName,
      key: selectKey,
      color: color,
    };
    setSelectedStatus(selectKey);
    setUpdatedOccupancyStatus(statusDetails);
  };

  const statusColor = (color, statusKey) => {
    if (selectedStatus === null) {
      console.log(selectedStatus, statusKey, color);
      return color;
    } else if (selectedStatus === statusKey) {
      return color;
    } else {
      return "default";
    }
  };

  const StatusBadge = ({ selectKey, status, color }) => {
    return (
      <ArgonBox pb={1}>
        <IconButton>
          <Tooltip
            title="Update Occupancy Status"
            placement="top"
            onClick={() => handleStatusClicked(status, selectKey, color)}
          >
            <ArgonBadge
              color={statusColor(color, selectKey)}
              variant="contained"
              container
              badgeContent={status}
            />
          </Tooltip>
        </IconButton>
      </ArgonBox>
    );
  };

  return (
    <ArgonBox p={2} lineHeight={1} height="100%">
      <ArgonBox mb={2} height="100%">
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            <ArgonTypography
              component="label"
              variant="caption"
              fontWeight="bold"
            >
              {`${t("generalWords.select")} ${t("generalWords.a")} ${t(
                "generalWords.new"
              )} ${t("properties.propertiesDataCard.occupancyStatus")}`}
            </ArgonTypography>
          </ArgonBox>
          <StatusBadge
            status={t("properties.propertiesDataCard.occupancyStatuses.ready")}
            color={successColor}
            selectKey="ready"
          />
          <StatusBadge
            status={t(
              "properties.propertiesDataCard.occupancyStatuses.occupied"
            )}
            color={infoColor}
            selectKey="occupied"
          />
          <StatusBadge
            status={t(
              "properties.propertiesDataCard.occupancyStatuses.pending"
            )}
            color={warningColor}
            selectKey="pending"
          />
          <StatusBadge
            status={t(
              "properties.propertiesDataCard.occupancyStatuses.serviceRequired"
            )}
            color={errorColor}
            selectKey="serviceRequired"
          />
          <StatusBadge
            status={t("properties.propertiesDataCard.occupancyStatuses.error")}
            color={errorColor}
            selectKey="error"
          />
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

export default OccupancyStatusDialog;
