import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_pt from "./translations/pt/common.json";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        common: common_en, // 'common' is our custom namespace
      },
      es: {
        common: common_es,
      },
      fr: {
        common: common_fr, // 'common' is our custom namespace
      },
      pt: {
        common: common_pt,
      },
    },
  });

export default i18n;
