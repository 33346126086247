import { Fragment, useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
import AccountingApi from "api/accountingApi";
import PropertySpendTransactions from "./components/PropertySpendTransactionsDataCard";
import HostHiveLoading from "components/HostHiveLoading";
function PropertySpend({ startDate, endDate, stringDateRange }) {
  const { t } = useTranslation("common");
  const accountingApi = AccountingApi();
  const handleCancelClicked = () => {
    setOpen(false);
  };

  const company = JSON.parse(localStorage.getItem("companyDetails"));

  const handleSubmitClicked = async () => {
    setOpen(false);
  };

  const [open, setOpen] = useState(false);
  const [workingPropertyInfo, setWorkingPropertyInfo] = useState(null);
  const handleViewPropertyTransactionsClicked = (propertyName, propertyId) => {
    setWorkingPropertyInfo({
      propertyName: propertyName,
      propertyId: propertyId,
    });
    setOpen(true);
  };
  const [loadingTransactionData, setLoadingTransactionData] = useState(false);
  const [propertySpend, setPropertySpend] = useState([]);
  useEffect(() => {
    async function fetchPropertyNet() {
      setLoadingTransactionData(true);
      try {
        let params = {
          companyId: company?.id,
        };
        // const response = {
        //   status: 200,
        // };

        if (startDate) {
          params.startDate = startDate;
        }
        if (endDate) {
          params.endDate = endDate;
        }

        const response = await accountingApi.getPropertiesNet(params);

        if (response.status >= 200 && response.status < 300) {
          // setBookingReports(response.data);
          console.log("response.data", response.data);
          setPropertySpend(response.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingTransactionData(false);
    }
    fetchPropertyNet();
  }, [startDate, endDate]);

  return (
    <Card sx={{ height: "100%", overflow: "hidden" }}>
      <ArgonBox p={3}>
        <ArgonBox>
          <HostHiveDialog
            open={open}
            fullWidth={true}
            includeClose={true}
            includeSave={false}
            maxWidth="lg"
            onClose={() => setOpen(false)}
            onSave={handleSubmitClicked}
            title="View"
            fullScreen={false}
            cancelButtonText="Close"
            submitButtonText="Save"
            dialogObjects={
              <PropertySpendTransactions
                propertyInfo={workingPropertyInfo}
                hideClose={true}
              />
            }
          />
        </ArgonBox>
        <ArgonTypography variant="h5" textTransform="capitalize">
          {t("accounting.transactions.netPerProperty")}
        </ArgonTypography>
      </ArgonBox>

      <ArgonBox pb={3} px={3}>
        <ArgonBox
          component="ul"
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          sx={{ listStyle: "none" }}
        >
          {loadingTransactionData ? (
            <HostHiveLoading />
          ) : (
            propertySpend.length > 0 &&
            propertySpend?.map(({ propertyName, amount, propertyId }, key) => (
              <Fragment key={key}>
                <ArgonBox component="li" py={1}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item alignItems="center"></Grid>
                    <Grid item lineHeight={1.4}>
                      <ArgonBox>
                        <ArgonBox>
                          <ArgonTypography
                            variant="h6"
                            sx={{
                              ":hover": { textDecoration: "underline" },
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleViewPropertyTransactionsClicked(
                                propertyName,
                                propertyId
                              )
                            }
                          >
                            {propertyName}
                          </ArgonTypography>
                          <ArgonTypography
                            variant="button"
                            color="text"
                            fontWeight="regular"
                          >
                            {t("accounting.general.amount")}:{" "}
                            <ArgonTypography
                              variant="button"
                              color={
                                amount > 0
                                  ? "success"
                                  : amount < 0
                                  ? "error"
                                  : "text"
                              }
                              fontWeight="regular"
                            >
                              ${amount > 0 ? "+" : null}
                              {amount.toLocaleString()}
                            </ArgonTypography>
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </Grid>
                    {/* <Grid item ml="auto">
                      <ArgonButton
                        variant="outlined"
                        color="info"
                        size="small"
                        onClick={() =>
                          handleViewPropertyTransactionsClicked(
                            propertyName,
                            propertyId
                          )
                        }
                      >
                        {t("buttons.view")}
                      </ArgonButton>
                    </Grid> */}
                  </Grid>
                </ArgonBox>
              </Fragment>
            ))
          )}
        </ArgonBox>
      </ArgonBox>
      <ArgonBox p={2}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <ArgonBox width="100%" textAlign="right" lineHeight={1}>
              <ArgonTypography
                variant="caption"
                color="secondary"
                // sx={{ cursor: "pointer" }}
                // onClick={() => setDateFilterOpen(true)}
              >
                {stringDateRange?.startDate ? stringDateRange?.startDate : null}{" "}
                - {stringDateRange?.endDate ? stringDateRange?.endDate : null}
              </ArgonTypography>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
    </Card>
  );
}

export default PropertySpend;
