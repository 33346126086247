/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";
import DefaultCell from "layouts/administration/accounting/overview/components/DefaultCell";
import { useState, useEffect } from "react";
import ArgonProgress from "components/ArgonProgress";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import {
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
// @mui material components
import TransactionItemSchema from "schemas/newTransaction";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import { setIn, useFormik } from "formik";
import CategorySelect from "layouts/management/components/CategorySelect";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveImages from "components/HostHiveImages";
import HostHiveDialog from "components/HostHiveDialog";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import CompanyApi from "api/companyApi";
import PropertySelect from "layouts/management/components/PropertySelect";
import BookingsApi from "api/bookingsApi";
import ServiceNameApi from "api/service-nameApi";
import SettingsApi from "api/settingsApi";
import ArgonAlert from "components/ArgonAlert";
import ChecklistsApi from "api/checkListsApi";
import ApplicationApi from "api/applicationApi";
import PropertiesApi from "api/propertiesApi";
import AccountingApi from "api/accountingApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import { set } from "lodash";
import { item } from "examples/Sidenav/styles/sidenavItem";
const image1 =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/product-thumb.jpg";
function ViewTransactionItem({
  onClose,
  itemDetails,
  id,
  triggerFetch = null,
}) {
  const navigate = useNavigate();
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();

  const accountingApi = AccountingApi();
  const [property, setProperty] = useState({});
  const [createIntegrationSettings, setCreateIntegrationSettings] =
    useState(false);
  const { t } = useTranslation("common");
  const [receiptImages, setReceiptImages] = useState([]);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    async function fetchRecieptPhotos() {
      if (id) {
        const response = await accountingApi.getTransaction(id);
        console.log("response for reciepts", response);
        setReceiptImages(response?.data?.images || []);
      }
    }

    fetchRecieptPhotos();
  }, [property]);
  const [isSaving, setIsSaving] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertColor, setAlertColor] = useState("warning");
  const [alertMessage, setAlertMessage] = useState(
    "Some long alert message about something that went wrong."
  );
  const onSubmit = async (values, actions) => {
    console.log(values);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: company?.id,
      propertyId: "",
      summary: "",
      description: "",
      amount: "",
      categoryId: "",
      status: "active",
      // Add more fields here
    },
    validationSchema: TransactionItemSchema,
    onSubmit,
  });
  const [category, setCategory] = useState({
    value: itemDetails?.categoryInfo?.categoryId,
    label: (
      <ArgonBadgeDot
        color={itemDetails?.categoryInfo?.categoryColor}
        size="md"
        badgeContent={itemDetails?.categoryInfo?.categoryName}
      />
    ),
    color: itemDetails?.categoryInfo?.categoryColor,
  });

  const [transactionType, setTransactionType] = useState({
    value: "expense",
    label: t("accounting.general.expense"),
  });

  const [showSaveCategoryButton, setShowSaveCategoryButton] = useState(false);
  const [savingNewCategory, setSavingNewCategory] = useState(false);
  useEffect(() => {
    async function saveNewCategory() {
      if (itemDetails?.categoryInfo?.categoryId !== category?.value) {
        console.log("Test", category?.value);

        setShowSaveCategoryButton(true);

        // Update category using Api
      } else {
        setShowSaveCategoryButton(false);
      }
    }

    saveNewCategory();
  }, [category]);

  const handleSaveUpdatedCategory = async () => {
    try {
      setSavingNewCategory(true);
      const response = await accountingApi.updateTransactionCategoryId(
        itemDetails?.id,
        category?.value
      );
    } catch (error) {
      console.error(error);
    }

    setShowSaveCategoryButton(false);
  };

  const [photoUploadOpen, setPhotoUploadOpen] = useState(false);
  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [photoProgress, setPhotoProgress] = useState(0);
  const [showEditProperty, setShowEditProperty] = useState(false);
  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);

    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    const photoLength = uploadedImagesArray.length;

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      const image = uploadedImagesArray[i];
      const fileType = image.type;
      const fileName = image.name;

      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await accountingApi.updateImages(
          itemDetails?.id,
          itemDetails?.companyId,
          itemDetails?.propertyId,
          fileName,
          fileType,
          "receipt",
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setReceiptImages(response.data.images);
        openSuccessSB(
          `Image uploaded successfully`,
          `Successfully uploaded image`
        );
      } catch (error) {
        console.error(error);
      }
    }

    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", fileName);
    try {
      const response = await accountingApi.deleteImage(
        itemDetails?.id,
        itemDetails?.companyId,
        itemDetails?.propertyId,
        fileName
      );

      // Update the bookingImages state by removing the image at the specified index
      setReceiptImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleTransactionDelete = async () => {
    try {
      const response = await accountingApi.deleteTransaction(itemDetails?.id);
      if (triggerFetch) {
        triggerFetch();
      } else {
        console.log("triggerFetch does not exist");
      }
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  const triggerFetchTransactions = () => {
    if (triggerFetch) {
      triggerFetch();
    } else {
      console.log("triggerFetch does not exist");
    }
  };

  const handlePropertyChange = async () => {
    console.log("handlePropertyChange", property);
    itemDetails.propertyId = property?.value;
    itemDetails.propertyName = property?.label;
    try {
      const response = await accountingApi.updateTransactionPropertyId(
        itemDetails?.id,
        property?.value
      );
      console.log("response", response);
      setShowEditProperty(false);
    } catch (error) {
      console.error(error);
    }

    // setShowEditProperty(false);
  };

  return (
    <>
      {isSaving ? (
        <HostHiveLoading message={t("generalWords.saving")} elipse={true} />
      ) : (
        <form onSubmit={handleSubmit}>
          {showAlert ? (
            <ArgonAlert color={alertColor} dismissible>
              <ArgonBox display="flex" justifyContent="flex-end">
                <ArgonBox>
                  <ArgonTypography variant="caption" color="white">
                    {alertMessage}
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonAlert>
          ) : null}
          <ArgonBox p={2} lineHeight={1}>
            <HostHiveDialog
              open={showEditProperty}
              fullWidth={true}
              includeSave={true}
              includeClose={true}
              maxWidth="xs"
              onClose={() => setShowEditProperty(false)}
              onSave={() => handlePropertyChange()}
              // saveDisabled={savingUser}
              title="Update Property Selection"
              fullScreen={false}
              cancelButtonText="Cancel"
              submitButtonText="Update"
              showStatusBadge={false}
              statusColor="success"
              statusContent="Confirmed"
              dialogObjects={
                <>
                  <PropertySelect
                    menuPlacement="bottom"
                    property={property}
                    setProperty={setProperty}
                    companyId={company?.id}
                  />
                </>
              }
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Property:
                  </ArgonTypography>

                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {" "}
                    {itemDetails?.propertyName}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox pl={2}>
                  <ArgonTypography
                    variant="caption"
                    textTransform="capitalize"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        color: "primary.main",
                      },
                      cursor: "pointer",
                      fontStyle: "italic",
                    }}
                    onClick={() => setShowEditProperty(true)}
                  >
                    {/* {t("serviceAssignments.buttons.editAssignee")} */}
                    Edit
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Summary:
                  </ArgonTypography>

                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {" "}
                    {itemDetails?.summary}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description:
                  </ArgonTypography>

                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {" "}
                    {itemDetails?.description}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Amount:
                  </ArgonTypography>

                  <DefaultCell
                    color={itemDetails?.amount < 0 ? "error" : "success"}
                    prefix={itemDetails?.amount < 0 ? "$" : "$+"}
                    value={itemDetails?.amount.toLocaleString()}
                  />
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0}>
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Category:
                  </ArgonTypography>
                  <ArgonBox pb={0.5} />
                  <CategorySelect
                    menuPlacement="bottom"
                    categoryType="accounting"
                    setCategory={setCategory}
                    category={category}
                    companyId={company?.id}
                    includeTitle={false}
                  />

                  <ArgonBox pt={2} display="flex" justifyContent="flex-end">
                    {showSaveCategoryButton ? (
                      <ArgonButton
                        variant="contained"
                        color="primary"
                        onClick={handleSaveUpdatedCategory}
                        disabled={savingNewCategory}
                      >
                        {!savingNewCategory ? "Save" : "Saving..."}
                      </ArgonButton>
                    ) : null}
                  </ArgonBox>
                </ArgonBox>
              </Grid>

              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Created:
                  </ArgonTypography>

                  <ArgonTypography
                    component="label"
                    variant="caption"
                    textTransform="capitalize"
                  >
                    {" "}
                    {convertTimeStamp(itemDetails?.created, "America/New_York")}
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Reciept:
                  </ArgonTypography>

                  {receiptImages?.length > 0 ? null : (
                    <ArgonBox
                      component="input"
                      name="file"
                      type="file"
                      multiple
                      onChange={uploadHandler}
                      mb={2} // Add some margin-bottom for better spacing
                      pl={1}
                    />
                  )}

                  {photoUploadInProgress && (
                    <ArgonProgress value={photoProgress} color="success" />
                  )}
                </ArgonBox>
                <ArgonBox>
                  {" "}
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6} xl={5}>
                      <ArgonBox pb={2} px={2}>
                        {" "}
                        <HostHiveImages
                          images={receiptImages}
                          handleDeleteImage={handleImageDelete}
                          showNoImagesMessage={true}
                          imageGridSizeXs={12}
                          imageGridSizeMd={6}
                          buttonTextRemove={true}
                        />
                      </ArgonBox>
                    </Grid>

                    {/* If you have more grid items, you can add them here */}
                  </Grid>
                </ArgonBox>
              </Grid>
              <Grid item xs={12}>
                <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Comments:
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
            </Grid>
            <HostHiveCommentSection entityType="transactions" entityId={id} />
          </ArgonBox>

          <ArgonBox justifyContent="center" display="flex" p={2}>
            <ArgonBox display="flex">
              <ArgonBox pr={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={() => onClose()}
                    >
                      {t("buttons.close")}
                    </ArgonButton>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <ArgonButton
                      variant="gradient"
                      color="error"
                      onClick={handleTransactionDelete}
                    >
                      {t("buttons.delete")}
                    </ArgonButton>
                  </Grid>
                </Grid>
              </ArgonBox>
              {/* <ArgonBox pr={2}>
                <ArgonButton variant="gradient" color="info" type="submit">
                  {t("buttons.save")}
                </ArgonButton>
              </ArgonBox> */}
            </ArgonBox>
          </ArgonBox>
        </form>
      )}
    </>
  );
}

export default ViewTransactionItem;
