import { useState, useEffect } from "react";
// @mui material components

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import { Link, useNavigate } from "react-router-dom";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ServiceNameApi from "api/service-nameApi";
import ServiceNameItemDialog from "layouts/management/company/settings/components/ServiceNames/components/ServiceNameItemDialog";
import HostHiveLoading from "components/HostHiveLoading";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
function ServiceNameSelect({
  companyId,
  setServiceNames,
  serviceNames,
  serviceName,
  setServiceName,
  loading = false,
  disabled = false,
}) {
  const [isNewServiceName, setIsNewServiceName] = useState(false);
  const [createdServiceName, setCreatedServiceName] = useState(false);
  const { t } = useTranslation("common");
  const serviceNamesApi = ServiceNameApi();

  const [serviceNameOptions, setServiceNameOptions] = useState([]);

  useEffect(() => {
    async function fetchServiceNames() {
      try {
        const queryParams = {
          companyId: companyId,
        };

        const response = await serviceNamesApi.getServiceNames(queryParams);

        let options = response.data.map((serviceName) => ({
          value: serviceName.id,
          // label: `${serviceName.name} - $${
          //   serviceName.cost ? serviceName.cost : 0.0
          // }`,
          label: serviceName.name,
        }));

        options.push({
          value: "new",
          label: `+ ${t("components.serviceName.create.createNewServiceName")}`,
        });
        console.log("options", options);
        if (setServiceNames) {
          setServiceNames(options);
        }
        setServiceNameOptions(options);
      } catch (error) {
        console.error(error);
      }
    }

    fetchServiceNames();
  }, []);

  const handleServiceNameSelected = (e) => {
    if (e.value === "new") {
      setIsNewServiceName(true);
    } else {
      setIsNewServiceName(false);

      setServiceName(e);
    }
    // other logic you may need when a company is selected
  };

  // generate options array, including the "Create New" option
  // const options = serviceNames.map((serviceName) => ({
  //   value: serviceName.id,
  //   label: serviceName.name,
  // }));

  // const options = serviceNames.map((serviceName) => ({
  //   value: serviceName.id,
  //   label: `${serviceName.name} - $${
  //     serviceName.cost ? serviceName.cost : 0.0
  //   }`,
  // }));

  // options.push({
  //   value: "new",
  //   label: `+ ${t("components.serviceName.create.createNewServiceName")}`,
  // });
  const [serviceNameDetails, setServiceNameDetails] = useState(null);
  return (
    <ArgonBox
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      height="100%"
    >
      <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0} display="inline-block">
        <HostHiveDialog
          includeDelete={false}
          includeClose={false}
          includeSave={false}
          open={isNewServiceName}
          onClose={() => {
            setIsNewServiceName(false);
          }}
          title={t("components.serviceName.create.createNewServiceName")}
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Create"
          maxWidth="sm"
          dialogObjects={
            <ServiceNameItemDialog
              companyId={companyId}
              handleServiceNameSelected={handleServiceNameSelected}
              setServiceNameDetails={setServiceNameDetails}
              handleSelection={true}
              onClose={() => {
                setIsNewServiceName(false);
              }}
            />
          }
        />
        <ArgonTypography component="label" variant="caption" fontWeight="bold">
          {t("components.serviceName.title")}
        </ArgonTypography>
        <HostHiveKbHelp marginBottom={0} lookupKey="serviceName" />
      </ArgonBox>
      {loading ? (
        <HostHiveLoading />
      ) : (
        <>
          {disabled ? (
            <>
              <ArgonSelect />
            </>
          ) : (
            <ArgonSelect
              defaultValue={{
                value: "none",
                label: t("components.serviceName.select"),
              }}
              options={serviceNameOptions}
              value={serviceName}
              onChange={handleServiceNameSelected}
            />
          )}
        </>
      )}
    </ArgonBox>
  );
}

export default ServiceNameSelect;
