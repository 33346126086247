import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function PlaidApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("finance", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const linkToken = (companyData) => {
    return axiosPrivate.post(
      `/finance-integration/create-link-token`,
      companyData
    );
  };

  const exchangePublicToken = (publicTokenData) => {
    return axiosPrivate.post(
      `/finance-integration/exchange-public-token`,
      publicTokenData
    );
  };

  const getInstitutions = (queryParams, companyId) => {
    return axiosPrivate.get(`/finance-integration/${companyId}`, {
      params: queryParams,
    });
  };

  const syncCompanyTransactions = (companyId) => {
    return axiosPrivate.put(
      `/finance-integration/sync-company-transactions/${companyId}`
    );
  };

  const syncTransactions = (queryParams) => {
    return axiosPrivate.put(`/finance-integration/sync-transactions`, null, {
      params: queryParams,
    });
  };

  const deleteInstitution = (queryParams) => {
    return axiosPrivate.delete(`/finance-integration/delete-institution`, {
      params: queryParams,
    });
  };

  ///delete-institution

  return {
    linkToken,
    exchangePublicToken,
    getInstitutions,
    syncCompanyTransactions,
    syncTransactions,
    deleteInstitution,
  };
}

export default PlaidApi;
