/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
import { useMemo, useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Icon from "@mui/material/Icon";
import ArgonSelect from "components/ArgonSelect";
// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsDataCard from "layouts/management/components/PropertySettingsDataCard";
import ServicePartnerDialog from "layouts/management/components/ServicePartnerDialog";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

import CompaniesApi from "api/companyApi";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import ComplexProjectCard from "layouts/management/servicePartners/service-partners-list/components/ComplexProjectCard";
import { useNavigate } from "react-router-dom";
import CompanyFilterSelect from "layouts/management/components/CompanyFilterSelect";

// Data

import HostHiveLoading from "components/HostHiveLoading";
// Images

import logoSlack from "assets/images/small-logos/logo-slack.svg";
import HostHiveDialog from "components/HostHiveDialog";
import NewProperty from "layouts/management/properties/new-property";
import ArgonInput from "components/ArgonInput";
import { useTranslation } from "react-i18next";
function ServicePartnersList() {
  const { t } = useTranslation();
  const companyDetails = JSON.parse(localStorage.getItem("companyDetails"));

  const companiesApi = CompaniesApi();
  const navigate = useNavigate();
  const [partnerAdded, setPartnerAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companyPartnerships, setCompanyPartnerships] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [propertyDialogOpen, setPropertyDialogOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [loadingResponse, setLoadingResponse] = useState(false);
  // TeamProfileCard dropdown menu handlers

  const [loadingMore, setLoadingMore] = useState(false);
  // ComplexProjectCard dropdown menu state

  const [company, setCompany] = useState(null);
  // Dropdown menu template for the ComplexProjectCard

  const [currentPage, setCurrentPage] = useState(0); // Add current page state
  const limit = 2; // You can adjust this to fit your needs
  const [searchText, setSearchText] = useState("");
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [invitedCompany, setInvitedCompany] = useState({});
  useEffect(() => {
    const loadData = async () => {
      try {
        //setIsLoading(true);
        const queryParams = {
          limit: limit,
          skip: currentPage * limit,
          name: searchText,
        };
        const companiesResponse = await companiesApi.getCompany(
          companyDetails.id,
          queryParams
        ); // Pass limit and skip
        // const companiesResponse = await companiesApi.getCompanies();
        // if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
        //   setCompanies(companiesResponse.data);
        // }

        if (companiesResponse.status >= 200 && companiesResponse.status < 300) {
          console.log("Getting partnerships");
          const queryParams = {
            includeSelf: false,
          };
          const companyPartnerShipsResponse =
            await companiesApi.getCompanyPartnerships(
              companyDetails.id,
              queryParams
            );

          if (
            companyPartnerShipsResponse.status >= 200 &&
            companyPartnerShipsResponse.status < 300
          ) {
            const companyPartnershipQuery = companyPartnerShipsResponse.data;
            console.log("?", companyPartnershipQuery);
            if (searchPerformed) {
              setCompanyPartnerships(companyPartnershipQuery); // Replace properties for a new search
              setSearchPerformed(false); // Reset flag
            } else {
              setCompanyPartnerships((prevPartnerships) => [
                ...prevPartnerships,
                ...companyPartnershipQuery,
              ]); // Append new properties for pagination
            }
          }
        }
      } catch (error) {
        setError(error);
        console.log(error);
      } finally {
        setIsLoading(false);
        setLoadingMore(false);
        console.log("Partners?", companyPartnerships);
      }
    };

    loadData();
  }, [currentPage, searchText, company, partnerAdded]);

  const handleNextPage = () => {
    setLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1); // Create a function to handle page changes
  };

  const handleExportClicked = () => {
    console.log("Export clicked");
    setDialogOpen(true);
  };

  const handleCancelClicked = () => {
    setPropertyDialogOpen(false);
  };
  const handleSubmitClicked = () => {
    setLoadingResponse(!loadingResponse);
    console.log(invitedCompany);

    //setPropertyDialogOpen(false);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
    setCurrentPage(0); // reset to the first page
    setSearchPerformed(true);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <HostHiveDialog
        open={propertyDialogOpen}
        includeSave={false}
        includeClose={false}
        onClose={handleCancelClicked}
        onSave={handleSubmitClicked}
        title="Add new service partner"
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ServicePartnerDialog
            setInvitedCompany={setInvitedCompany}
            invitedCompany={invitedCompany}
            onClose={handleCancelClicked}
            companyId={companyDetails?.id}
          />
        }
      />

      <ArgonBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {companyPartnerships ? (
              <Card
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={8}>
                    <ArgonBox lineHeight={1} p={3}>
                      <ArgonTypography variant="h5" fontWeight="medium">
                        My Partners
                      </ArgonTypography>
                      <ArgonTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        A list of all of your service partners.
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={4}>
                    <ArgonBox lineHeight={1} p={3}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                          <ArgonBox display="flex">
                            <ArgonBox pr={2}>
                              <ArgonButton
                                variant="gradient"
                                color="info"
                                size="small"
                                onClick={() => setPropertyDialogOpen(true)}
                              >
                                + Add Partner
                              </ArgonButton>
                            </ArgonBox>

                            <ArgonBox>
                              <ArgonButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={handleExportClicked}
                              >
                                Import
                              </ArgonButton>
                            </ArgonBox>
                          </ArgonBox>
                        </Grid>
                      </Grid>
                    </ArgonBox>
                    {/* <ArgonBox lineHeight={1} p={3}>
                      <Stack spacing={1} direction="row"></Stack>
                    </ArgonBox> */}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <ArgonBox p={2}>
                      <ArgonInput
                        placeholder="Search by Name"
                        startAdornment={
                          <Icon fontSize="small" style={{ marginRight: "6px" }}>
                            search
                          </Icon>
                        }
                        value={searchText}
                        onChange={handleSearchTextChange}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <ArgonBox p={2}>
                      <CompanyFilterSelect
                        setCompany={setCompany}
                        setSearchPerformed={setSearchPerformed}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>

                {isLoading ? (
                  // <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1} justifyContent="center">
                  //   <CircularProgress />
                  // </ArgonBox>

                  <HostHiveLoading />
                ) : (
                  <ArgonBox mt={{ xs: 1, lg: 3 }} mb={1}>
                    <Grid container spacing={3}>
                      {companyPartnerships?.map(({ id, name }, key) => (
                        <Grid item xs={12} md={6} lg={4} key={id}>
                          <ComplexProjectCard
                            name={name} // Assuming the property name should be the title
                            id={id}
                            // description={occupancyStatus} // Assuming the description in the properties object should be the card's description
                            // status={status} // Assuming the serviceScheduleDate is the dateTime for the card
                            // // Assuming members, images, dropdown action and menu aren't contained in the properties object
                            // bed={bed}
                            // id={id}
                            // bath={bath}
                            // sqft={sqft}
                            // occupancyStatusDetails={occupancyStatusDetails}
                            // image={logoSlack}
                            // companyInfo={propertyCompanyInfo}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </ArgonBox>
                )}
                {loadingMore ? <HostHiveLoading /> : null}
                <ArgonBox display="flex" justifyContent="center" mt={2}>
                  <ArgonBox p={2}>
                    <ArgonButton
                      onClick={handleNextPage}
                      color="primary"
                      variant="outlined"
                    >
                      {t("buttons.viewMore")}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </Card>
            ) : (
              <Card>
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  p={3}
                >
                  <ArgonBox lineHeight={1}>
                    <ArgonTypography variant="h5" fontWeight="medium">
                      No Properties
                    </ArgonTypography>
                    <ArgonTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Use the buttons on the right to new properties.
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </Card>
            )}
          </Grid>
        </Grid>
      </ArgonBox>

      <Footer />
    </DashboardLayout>
  );
}

export default ServicePartnersList;
