import useAxiosPrivate from "hooks/useAxiosPrivate";
import Default from "layouts/dashboards/default";

function AlertsApi() {
  const axiosPrivate = useAxiosPrivate();

  const getUserAlerts = (userId, queryParams) => {
    return axiosPrivate.get(`/user-alerts/${userId}`, { params: queryParams });
  };

  const dismissAlert = (alertId) => {
    return axiosPrivate.put(`/alerts/${alertId}`);
  };

  const dismissAllAlerts = (alertIds) => {
    return axiosPrivate.put(`/alerts/dismiss/all`, alertIds);
  };

  const createAlert = (alertData) => {
    return axiosPrivate.post(`/alerts`, alertData);
  };

  const getAlert = (alertId) => {
    return axiosPrivate.get(`/alerts/${alertId}`);
  };

  const updateAlert = (alertId, alertData) => {
    return axiosPrivate.put(`/alerts/${alertId}`, alertData);
  };

  const deleteAlert = (alertId) => {
    return axiosPrivate.delete(`/alerts/${alertId}`);
  };

  const getAlerts = (queryParams) => {
    return axiosPrivate.get(`/alerts`, { params: queryParams });
  };

  return {
    getUserAlerts,
    dismissAlert,
    createAlert,
    getAlert,
    updateAlert,
    deleteAlert,
    getAlerts,
    dismissAllAlerts,
  };
}

export default AlertsApi;
