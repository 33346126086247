/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-countup components
import CountUp from "react-countup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function DefaultCounterCard({
  thisWeek,
  pendindAvailableBalance,
  balance,
  hasGoodStandingAccount,
}) {
  const ViewLinkModal = ({ title }) => {
    return (
      <ArgonTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
        sx={{ ":hover": { textDecoration: "underline" }, cursor: "pointer" }}
        // onClick={handleOpen}
        title={title}
      >
        {title}
      </ArgonTypography>
    );
  };
  return (
    <Card>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <ArgonBox p={3} textAlign="center" lineHeight={1.25}>
            <ArgonTypography
              variant="h1"
              color="info"
              fontWeight="bold"
              textGradient
            >
              {thisWeek?.prefix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {thisWeek?.prefix}
                </ArgonTypography>
              )}
              <ArgonBox display="inline-block" mx={0.5}>
                <CountUp end={thisWeek?.count} duration={1} separator="," />
              </ArgonBox>
              {thisWeek?.suffix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {thisWeek?.suffix}
                </ArgonTypography>
              )}
            </ArgonTypography>
            <ArgonTypography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {thisWeek?.title}
            </ArgonTypography>
            {thisWeek?.description && (
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                textTransform="capitalize"
              >
                {thisWeek?.description}
              </ArgonTypography>
            )}
            {thisWeek?.action && <ArgonBox mt={2}>{thisWeek?.action}</ArgonBox>}
          </ArgonBox>
        </Grid>
        <Grid item xs={12} md={6}>
          <ArgonBox p={3} textAlign="center" lineHeight={1.25}>
            <ArgonTypography
              variant="h1"
              color="info"
              fontWeight="bold"
              textGradient
            >
              {pendindAvailableBalance?.prefix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {pendindAvailableBalance?.prefix}
                </ArgonTypography>
              )}
              <ArgonBox display="inline-block" mx={0.5}>
                <CountUp
                  end={pendindAvailableBalance?.count}
                  duration={1}
                  separator=","
                />
              </ArgonBox>
              {pendindAvailableBalance?.suffix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {pendindAvailableBalance?.suffix}
                </ArgonTypography>
              )}
            </ArgonTypography>
            <ArgonTypography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {pendindAvailableBalance?.title}
            </ArgonTypography>
            {pendindAvailableBalance?.description && (
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                textTransform="capitalize"
              >
                {pendindAvailableBalance?.description}
              </ArgonTypography>
            )}
            {/* {balance?.action && <ArgonBox mt={2}>{balance?.action}</ArgonBox>} */}
          </ArgonBox>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <ArgonBox p={3} textAlign="center" lineHeight={1.25}>
            <ArgonTypography
              variant="h1"
              color="info"
              fontWeight="bold"
              textGradient
            >
              {balance?.prefix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {balance?.prefix}
                </ArgonTypography>
              )}
              <ArgonBox display="inline-block" mx={0.5}>
                <CountUp end={balance?.count} duration={1} separator="," />
              </ArgonBox>
              {balance?.suffix && (
                <ArgonTypography
                  component="span"
                  variant="h5"
                  fontWeight="bold"
                >
                  {balance?.suffix}
                </ArgonTypography>
              )}
            </ArgonTypography>
            <ArgonTypography
              variant="h6"
              fontWeight="bold"
              textTransform="capitalize"
            >
              {balance?.title}
            </ArgonTypography>
            {balance?.description && (
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                opacity={0.8}
                textTransform="capitalize"
              >
                {balance?.description}
              </ArgonTypography>
            )}
            {balance?.action && <ArgonBox mt={2}>{balance?.action}</ArgonBox>}
          </ArgonBox>
        </Grid> */}
      </Grid>
    </Card>
  );
}

// Setting default values for the props of DefaultCounterCard
// DefaultCounterCard.defaultProps = {
//   color: "info",
//   prefix: "",
//   suffix: "",
//   description: "",
// };

// // Typechecking props for the BlogCard
// DefaultCounterCard.propTypes = {
//   color: PropTypes.oneOf([
//     "primary",
//     "secondary",
//     "info",
//     "success",
//     "warning",
//     "error",
//     "dark",
//   ]),
//   count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
// };

export default DefaultCounterCard;
