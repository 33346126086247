import React, { useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import PlaidApi from "api/plaidApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Menu from "@mui/material/Menu";
import { showDeleteConfirmation } from "services/confirmDelete";
import Swal from "sweetalert2";
import { MenuItem } from "@mui/material";
function PlaidIntegration({ buttonOnly = false, setCompanyInstitutions }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [linkToken, setLinkToken] = useState(null);
  const plaidApi = PlaidApi();

  const generateToken = async () => {
    const companyData = {
      companyId: company.id,
      companyName: company.name,
    };
    try {
      const response = await plaidApi.linkToken(companyData);
      const data = response.data;
      setLinkToken(data.linkToken);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    generateToken();
  }, []);

  const onSuccess = async (public_token, metadata) => {
    const publicTokenData = {
      publicToken: public_token,
      metadata: metadata,
      companyId: company.id,
    };
    try {
      const response = await plaidApi.exchangePublicToken(publicTokenData);
      console.log(response.data);
      setCompanyInstitutions(response.data.institutions);
    } catch (error) {
      console.log(error);
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <>
      {!buttonOnly ? (
        <>
          <DashboardLayout>
            <ArgonBox mt={3} mb={4}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={9}>
                  <Card sx={{ overflow: "visible" }}>
                    <ArgonBox p={2} lineHeight={1}>
                      <ArgonButton
                        variant="gradient"
                        color="dark"
                        onClick={() => open()}
                        disabled={!ready || !linkToken}
                      >
                        Link Account
                      </ArgonButton>
                    </ArgonBox>
                  </Card>
                </Grid>
              </Grid>
            </ArgonBox>
          </DashboardLayout>
        </>
      ) : (
        <>
          {/* <MenuItem
            // onClick={() => {
            //   handleCreateBookingClicked() && handleCloseMenu();
            // }}
            onClick={() => {
              open();
              closeMenu();
            }}
            disabled={!ready || !linkToken}
          >
            Link Account
          </MenuItem> */}
          <ArgonButton
            variant="gradient"
            color="dark"
            onClick={() => open()}
            disabled={!ready || !linkToken}
          >
            Link Account
          </ArgonButton>
        </>
      )}
    </>
  );
}

export default PlaidIntegration;
