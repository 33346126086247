/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// import {
//   Card,
//   Grid,
//   Tooltip,
//   Icon,
//   FormControl,
//   Select,
//   MenuItem,
//   Divider,
//   TextareaAutosize,
// } from "@mui/material";
// @mui material components
import OccupancyStatusDialog from "layouts/management/components/OccupancyStatusDialog";

import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import BedIcon from "@mui/icons-material/Bed";
import BathtubIcon from "@mui/icons-material/Bathtub";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import Icon from "@mui/material/Icon";
import { useState, useEffect, useContext } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonBadge from "components/ArgonBadge";
import { useNavigate } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import HostHiveDialog from "components/HostHiveDialog";
import ReadOnlyPropertyDetails from "layouts/management/properties/properties-list/components/read-only-property-details";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import { useTranslation } from "react-i18next";
import PropertiesApi from "api/propertiesApi";
import CreateEventWizard from "layouts/management/calendar/components/CreateEventWizard";

// Custom styles for ComplexProjectCard
function ComplexProjectCard({
  id,
  // bed,
  // bath,
  // sqft,
  // title,
  // companyInfo,
  // occupancyStatusDetails,
  // isOwner,
  property,
  setShowAlert,
  setError,
  setProperties,
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const propertiesApi = new PropertiesApi();
  // Badges
  const propertyOpen = (
    <ArgonBadge
      variant="contained"
      color="success"
      badgeContent="ready"
      container
    />
  );
  const propertyOccupied = (
    <ArgonBadge
      variant="contained"
      color="caution"
      badgeContent="occupied"
      container
    />
  );

  const propertyError = (
    <ArgonBadge
      variant="contained"
      color="error"
      badgeContent="pending"
      container
    />
  );
  const propertyPending = (
    <ArgonBadge
      variant="contained"
      color="warning"
      badgeContent="pending"
      container
    />
  );
  const propertyRequireService = (
    <ArgonBadge
      variant="contained"
      color="error"
      badgeContent="Service Required"
      container
    />
  );

  const readStatus = (status) => {
    status = status.toLowerCase();
    if (status === "ready") {
      return propertyOpen;
    }
    if (status === "occupied") {
      return propertyOccupied;
    }
    if (status === "pending") {
      return propertyPending;
    }
    if (status === "error") {
      return propertyError;
    }
    if (status === "serviceRequired") {
      return propertyRequireService;
    } else {
      return propertyPending;
    }
  };

  const navigateToProperty = (id) => {
    navigate(`/properties/${id}}`);
  };
  const [occupancyStatusColor, setOccupancyStatusColor] = useState("error");
  const [occupancyStatusContent, setOccupancyStatusContent] = useState("");
  useEffect(() => {
    setOccupancyStatusColor(property?.occupancyStatusDetails?.color || "error");
    setOccupancyStatusContent(
      property?.occupancyStatusDetails?.displayName || "UNKNOWN"
    );
  }, [id]);

  const [showPropertyDataCard, setShowPropertyDataCard] = useState(false);
  const handlePropertyTitleClicked = (isOwner, id) => {
    if (isOwner) {
      navigate(`/properties/${id}`);
    } else {
      setShowPropertyDataCard(true);
    }
  };
  const [propertyDetails, setPropertyDetails] = useState({});
  const [propertyStatus, setPropertyStatus] = useState(property.status);
  const handleDeactiveProperty = async (id) => {
    try {
      const response = await propertiesApi.deactivateProperty(id);
      if (response.status === 200) {
        setOccupancyStatusColor("error");
        setOccupancyStatusContent("INACTIVE");
        setPropertyStatus("inactive");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleReactivateProperty = async (id) => {
    try {
      const resp = await propertiesApi.reactivateProperty(id);
      if (resp.status >= 200 && resp.status < 300) {
        setOccupancyStatusColor("success");
        setOccupancyStatusContent("ready");
      }
    } catch (error) {
      console.error("Raised error:", error);
      setError(error);
      setShowAlert();
    }
  };

  const handleDeleteProperty = async (id) => {
    try {
      const resp = await propertiesApi.deleteProperty(id);
      if (resp.status >= 200 && resp.status < 300) {
        setOccupancyStatusColor("error");
        setOccupancyStatusContent("INACTIVE");
      }
      setProperties((prev) => prev.filter((property) => property.id !== id));
    } catch (error) {
      console.error("Raised error:", error);
      setError(error);
      setShowAlert();
    }
  };

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const renderMenu = (id, property) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      {property?.isOwner ? (
        <>
          <MenuItem onClick={() => setOpen(true)}>
            {t("properties.propertiesDataCard.menu.scheduleService")}
          </MenuItem>
        </>
      ) : (
        <></>
      )}
      {property?.isOwner ? (
        <>
          <MenuItem
            onClick={() => {
              setUpdateOccupancyStatusSelect(true) && handleCloseMenu();
            }}
          >
            {t("properties.propertiesDataCard.menu.updateOccupancyStatus")}
          </MenuItem>
        </>
      ) : (
        <></>
      )}
      <MenuItem
        onClick={() => {
          navigate(`/service-reports/${property?.id}`) && handleCloseMenu();
        }}
      >
        {t("properties.propertiesDataCard.menu.reportsNavigate")}
      </MenuItem>
      {property?.isOwner ? (
        <>
          {propertyStatus === "active" ? (
            <MenuItem
              onClick={() => {
                handleDeactiveProperty(id) && handleCloseMenu();
              }}
            >
              {t("hostHiveSpecificActions.inactivate")}
            </MenuItem>
          ) : (
            <>
              <MenuItem
                onClick={() => {
                  handleReactivateProperty(id) && handleCloseMenu();
                }}
              >
                Reactivate Property
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleDeleteProperty(id) && handleCloseMenu();
                }}
              >
                Delete Property
              </MenuItem>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </Menu>
  );
  const [open, setOpen] = useState(false);
  const [updatedOccupancyStatus, setUpdatedOccupancyStatus] = useState(null);
  const [updateOccupancyStatusSelect, setUpdateOccupancyStatusSelect] =
    useState(false);
  const [updateOccupancyStatusLoading, setUpdateOccupancyStatusLoading] =
    useState(false);
  const handleUpdatedOccupancyStatus = async () => {
    handleCloseMenu();
    setUpdateOccupancyStatusLoading(true);
    try {
      console.log("handleUpdatedOccupancyStatus", updatedOccupancyStatus);
      const resp = await propertiesApi.updatePropertyOccupancyStatus(
        property.id,
        updatedOccupancyStatus.key
      );
      if (resp.status >= 200 && resp.status < 300) {
        console.log("Updated occupancy status", resp.data);
        setOccupancyStatusColor(updatedOccupancyStatus.color);
        setOccupancyStatusContent(updatedOccupancyStatus.displayName);
        setUpdateOccupancyStatusSelect(false);
        setUpdateOccupancyStatusLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <Card
      sx={{
        boxShadow: ({ boxShadows: { xl } }) => xl, // Increased shadow
        borderRadius: "8px",
        padding: 3,
      }}
    >
      <ArgonBox p={3}>
        <HostHiveDialog
          includeDelete={false}
          includeSave={true}
          onSave={handleUpdatedOccupancyStatus}
          open={updateOccupancyStatusSelect}
          onClose={() => {
            setUpdateOccupancyStatusSelect(false);
          }}
          title={`${t("generalWords.update")} ${t(
            "properties.propertiesDataCard.occupancyStatus"
          )}`}
          fullScreen={false}
          maxWidth="sm"
          cancelButtonText={t("buttons.close")}
          submitButtonText={t("buttons.save")}
          dialogObjects={
            <OccupancyStatusDialog
              // occupancyStatusTypes={occupancyStatusTypes}
              // propertyId={property?.id}
              // currentStatus={property?.occupancyStatusDetails}
              // updatedOccupancyStatus={updatedOccupancyStatus}
              setUpdatedOccupancyStatus={setUpdatedOccupancyStatus}
            />
          }
        />
        <HostHiveDialog
          open={open}
          fullWidth={true}
          includeClose={false}
          includeSave={false}
          maxWidth="md"
          title="Schedule service"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          dialogObjects={
            <CreateEventWizard
              closeWizard={() => {
                setOpen(false);
                // setExternalFetch();
              }}
              propertyId={property?.id}
              // serviceGapDate={startDate}
            />
          }
        />
        <HostHiveDialog
          includeDelete={false}
          includeSave={false}
          open={showPropertyDataCard}
          onClose={() => {
            setShowPropertyDataCard(false);
          }}
          title={t("properties.propertyDetails")}
          fullScreen={false}
          maxWidth="xl"
          cancelButtonText="Close"
          submitButtonText="Save"
          dialogObjects={
            <>
              {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
              <ReadOnlyPropertyDetails
                property={property?.propertyAddress}
                propertyName={property?.name}
                propertyId={property?.id}
                propertyOccupancyDetails={{
                  occupanceStatus: property?.occupancyStatus,
                  color: occupancyStatusColor,
                  guestCheckIn: property?.guestCheckIn,
                  guestCheckOut: property?.guestCheckOut,
                }}
              />
            </>
          }
        />
        <ArgonBox display="flex" alignItems="center">
          <ArgonBox ml={2} lineHeight={0}>
            <ArgonBox lineHeight={0}>
              {/* <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
              > */}
              <ArgonTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="medium"
                sx={{
                  ":hover": { textDecoration: "underline" },
                  cursor: "pointer",
                }}
                onClick={() =>
                  handlePropertyTitleClicked(property?.isOwner, id)
                }
                //title={title}
              >
                {/* <MuiLink
                  href={`/properties/${id}`}
                  rel="noreferrer"
                  onClick={() => console.log("Clicked title")}
                >
                  {title}
                </MuiLink> */}
                {property?.name}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={1}>
              <ArgonTypography
                variant="button"
                color="text"
                fontWeight="medium"
              >
                {property?.isOwner ? "" : `${property?.ownerName}`}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          {renderMenu(id, property)}

          <ArgonTypography
            color="secondary"
            onClick={handleOpenMenu}
            sx={{
              ml: "auto",
              alignSelf: "flex-start",
              py: 1.25,
            }}
          >
            <Icon fontSize="default" sx={{ cursor: "pointer" }}>
              more_vert
            </Icon>
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox ml={2} lineHeight={0}>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                {t("properties.propertiesDataCard.bed")}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center">
              <Icon fontSize="default">bed_icon</Icon>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
                ml={1} // added a margin to the left of the number for spacing
              >
                {property?.bed}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                {t("properties.propertiesDataCard.bath")}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center">
              <Icon fontSize="default">bathtub_icon</Icon>
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
                ml={1} // added a margin to the left of the number for spacing
              >
                {property?.bath}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArgonBox>
              <ArgonTypography
                variant="button"
                fontWeight="medium"
                color="text"
              >
                {t("properties.propertiesDataCard.sqft")}
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox display="flex" alignItems="center">
              <SquareFootIcon />
              <ArgonTypography
                variant="button"
                fontWeight="regular"
                color="text"
                ml={1} // added a margin to the left of the number for spacing
              >
                {property?.sqft}
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
        <Divider />
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
            <ArgonTypography variant="button" fontWeight="medium">
              <ArgonBadge
                color={occupancyStatusColor}
                badgeContent={occupancyStatusContent}
                container
                variant="contained"
              />
            </ArgonTypography>
            <ArgonTypography
              variant="button"
              fontWeight="medium"
              color="secondary"
            >
              {t("properties.propertiesDataCard.occupancyStatus")}
            </ArgonTypography>
            <ArgonTypography
              variant="caption"
              textTransform="capitalize"
              sx={{
                ":hover": { textDecoration: "underline" },
                cursor: "pointer",
                // add italics to the text
                fontStyle: "italic",
              }}
            >
              {property?.guestCheckIn} - {property?.guestCheckOut}
            </ArgonTypography>
          </ArgonBox>

          <ArgonBox display="flex" flexDirection="column" lineHeight={0}>
            <>
              {property?.isOwner ? (
                <>
                  {/* <Icon color="info"> add</Icon> */}
                  <ArgonTypography
                    color="secondary"
                    //onClick={handleOpenMenu}
                    sx={{
                      ml: "auto",
                      pt: 2,
                      alignSelf: "flex-start",
                      py: 1.25,
                    }}
                  >
                    <Tooltip
                      title={
                        property?.calendarSyncEnabled
                          ? "Calendar Sync Active"
                          : "Calendar Sync Disabled"
                      }
                      placement="top"
                      //onClick={handleDeleteNote}
                    >
                      <Icon
                        color={
                          property?.calendarSyncEnabled ? "success" : "error"
                        }
                        fontSize="default"
                        sx={{ cursor: "pointer" }}
                      >
                        {property?.calendarSyncEnabled
                          ? "sync"
                          : "sync_disabled"}
                      </Icon>
                    </Tooltip>
                  </ArgonTypography>
                </>
              ) : null}
            </>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

// Setting default values for the props of ComplexProjectCard
ComplexProjectCard.defaultProps = {
  color: "dark",
  status: "",
  occupancyStatus: [],
  dropdown: false,
};

// Typechecking props for the ProfileInfoCard
ComplexProjectCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  description: PropTypes.node.isRequired,
  occupancyStatus: PropTypes.arrayOf(PropTypes.string),
  dropdown: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      action: PropTypes.func,
      menu: PropTypes.node,
    }),
  ]),
};

export default ComplexProjectCard;
