import React, { useState } from "react";
import PropertyContext from "./PropertyContext";

export function PropertyContextProvider(props) {
  const [property, setProperty] = useState(null);
  const [propertySettings, setPropertySettings] = useState(null);
  const [propertyNotes, setPropertyNotes] = useState([]);
  const [propertyDocuments, setPropertyDocuments] = useState([]);
  const [companyChecklists, setCompanyChecklists] = useState([]);
  const [companyServiceNames, setCompanyServiceNames] = useState([]);
  const [applicationIntegrationTypes, setApplicationIntegrationTypes] =
    useState(null);
  const [propertyServicePartners, setPropertyServicePartners] = useState([]);
  const [occupancyStatusTypes, setOccupancyStatusTypes] = useState(null);
  const [userCompany, setUserCompany] = useState(null);
  const [propertyTransactions, setPropertyTransactions] = useState(null);
  const contextValue = {
    property,
    setProperty,
    propertySettings,
    setPropertySettings,
    propertyNotes,
    setPropertyNotes,
    propertyDocuments,
    setPropertyDocuments,
    companyChecklists,
    setCompanyChecklists,
    companyServiceNames,
    setCompanyServiceNames,
    applicationIntegrationTypes,
    setApplicationIntegrationTypes,
    propertyServicePartners,
    setPropertyServicePartners,
    occupancyStatusTypes,
    setOccupancyStatusTypes,
    userCompany,
    setUserCompany,
    propertyTransactions,
    setPropertyTransactions,
  };

  return (
    <PropertyContext.Provider value={contextValue}>
      {props.children}
    </PropertyContext.Provider>
  );
}
