/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
//import TimelineItem from "examples/Timeline/TimelineItem";
import AssignmentsTimelineItem from "layouts/management/components/AssignmentsTimelineItem";
import UpcomingAssignmentItem from "layouts/management/components/UpcomingAssignmentItem";
import { useMemo, useState, useEffect, useContext } from "react";
import BookingsApi from "api/bookingsApi";
import { UserAuth } from "context/AuthContext";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function ScheduledAssignments({ property, propertyName, propertyId }) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();
  const navigate = useNavigate();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const { user, role } = UserAuth();
  const [assignments, setAssignments] = useState([]);
  const [bookingInfo, setBookingInfo] = useState(null);

  useEffect(() => {
    const loadBookings = async () => {
      setIsLoading(true);
      console.log("company", company);
      console.log("user", user);
      console.log("userRole", role);
      let queryParams = {
        // limit: 4,
        //companyId: company.id,
        assigneeId: company?.id,
        assigneeSubId: user.uid,
        includeTurnOvers: true,
      };

      if (role === "admin") {
        // Delete assigneeSubId from query params
        delete queryParams.assigneeSubId;
      }

      try {
        const response = await bookingsApi.getBookings(queryParams);
        console.log(response.data, "response.data");
        setAssignments(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadBookings();
  }, []);
  const handleViewClicked = (bookingId) => {
    console.log("handleViewClicked", bookingId);
  };

  const assignmentIcon = (assignmentStatus) => {
    if (assignmentStatus === "pending") {
      return "notifications";
    } else if (assignmentStatus === "completed") {
      return "verified";
    } else if (assignmentStatus === "inprogress") {
      return "access_time";
    } else if (assignmentStatus === "scheduled") {
      return "calendar_today";
    } else if (assignmentStatus === "confirmed") {
      return "event_available";
    } else if (assignmentStatus === "cancelled") {
      return "cancel";
    } else {
      return "notifications";
    }
  };

  const assignmentColor = (assignmentStatus) => {
    if (assignmentStatus === "pending") {
      return "secondary";
    } else if (assignmentStatus === "completed") {
      return "success";
    } else if (assignmentStatus === "inprogress") {
      return "info";
    } else if (assignmentStatus === "scheduled") {
      return "warning";
    } else if (assignmentStatus === "confirmed") {
      return "primary";
    } else if (assignmentStatus === "cancelled") {
      return "error";
    } else {
      return "secondary";
    }
  };

  return (
    <>
      <ArgonBox display="flex">
        <ArgonBox>
          <ArgonTypography variant="h6" fontWeight="medium">
            {t("components.serviceAppointments.scheduledAssignments.title")}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox>
          <Tooltip title="Open Calendar" placement="top" arrow>
            <IconButton onClick={() => navigate("/calendar")}>
              <Icon sx={{ fontWeight: "bold" }}>arrow_outward</Icon>
            </IconButton>
          </Tooltip>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox mt={2}>
        {assignments?.map((assignment, key) => (
          // <UpcomingAssignmentItem
          //   booking={assignment}
          //   handleViewClicked={() => handleViewClicked(assignment?.id)}
          // />
          <AssignmentsTimelineItem
            color={assignmentColor(assignment?.status)}
            icon={assignmentIcon(assignment?.status)}
            title={assignment?.title}
            dateTime={assignment?.serviceScheduleDate}
            assignmentId={assignment?.id}
          />
        ))}
      </ArgonBox>
    </>
  );
}

export default ScheduledAssignments;
