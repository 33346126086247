import React, { useState, useEffect } from "react";
import ArgonSnackbar from "components/ArgonSnackbar";
import ArgonAlert from "components/ArgonAlert";
import ArgonTypography from "components/ArgonTypography";
import MuiLink from "@mui/material/Link";
function HostHiveAlert() {
  // const alertContent = (content, helpUrl = null) => (

  // );
  const [alertContents, setAlertContents] = useState("");
  const [alerHelpUrl, setAlertHelpUrl] = useState(null);
  const alertContent = (content, helpUrl = null) => {
    setAlertContents(content);
    setAlertHelpUrl(helpUrl);
  };

  const renderAlert = (
    <ArgonAlert color="error" dismissible>
      <ArgonTypography variant="body2" color="white">
        Error: {alertContents}.{" "}
        {alerHelpUrl && (
          <MuiLink href={alerHelpUrl} rel="noreferrer">
            Get help here.
          </MuiLink>
        )}
        {/* . Give it a click if you like. */}
      </ArgonTypography>
    </ArgonAlert>
  );

  return { alertContent, renderAlert };
}

export default HostHiveAlert;
