/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import ArgonBadgeDot from "components/ArgonBadgeDot";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonButton from "components/ArgonButton";
import BookingsApi from "api/bookingsApi";
import { useState, useEffect } from "react";
import { UserAuth } from "context/AuthContext";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonBadge from "components/ArgonBadge";
import Tooltip from "@mui/material/Tooltip";
import UpcomingAssignmentItem from "layouts/management/components/UpcomingAssignmentItem";
import { set } from "lodash";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
function UpcomingAssignments({ title, profiles }) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [isLoading, setIsLoading] = useState(false);
  const { user } = UserAuth();
  const [assignments, setAssignments] = useState([]);
  const [open, setOpen] = useState(false);
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [bookingInfo, setBookingInfo] = useState(null);
  useEffect(() => {
    const loadBookings = async () => {
      setIsLoading(true);
      console.log("company", company);
      console.log("user", user);
      let queryParams = {
        limit: 4,
        //companyId: company.id,
        assigneeSubId: user.uid,
        includeTurnOvers: true,
      };

      try {
        const response = await bookingsApi.getBookings(queryParams);
        console.log("Assigned Bookings", response.data);
        setAssignments(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadBookings();
  }, []);

  const handleViewClicked = (id) => {
    let foundBookingInfo = findBooking(id);
    console.log("bookingInfo", foundBookingInfo);

    let updatedUserSelections = {
      [foundBookingInfo?.assignedCompanyId]: foundBookingInfo?.assignedCompany,
      [foundBookingInfo?.checklistId]: foundBookingInfo?.checklistName,
      [foundBookingInfo?.serviceNameId]: foundBookingInfo?.serviceName,
      [foundBookingInfo?.propertyId]: foundBookingInfo?.propertyName,
      // [foundBookingInfo?.categoryId]: foundBookingInfo?.category?.name,
      [foundBookingInfo?.category?.id]: (
        <ArgonBadgeDot
          color={foundBookingInfo?.category?.color}
          size="md"
          badgeContent={foundBookingInfo?.category?.name}
        />
      ),
      [foundBookingInfo?.assignedUserSubId]:
        foundBookingInfo?.assignedUserEmail,
    };

    setUserSelections(updatedUserSelections);

    setBookingInfo(foundBookingInfo);
    // setPropertyInfo(bookingInfo?.propertyInfo);
    setOpen(true);
  };
  const renderAssignments = assignments.map((assignment) => (
    // <ArgonBox
    //   key={id}
    //   component="li"
    //   display="flex"
    //   alignItems="center"
    //   py={1}
    //   mb={1}
    // >
    //   <Grid container spacing={3}>
    //     <Grid item xs={12} lg={8}>
    //       <ArgonBox
    //         display="flex"
    //         flexDirection="column"
    //         alignItems="flex-start"
    //         justifyContent="center"
    //       >
    //         <ArgonBox display="flex" justifyContent="flex-end">
    //           <ArgonBox>
    //             <ArgonTypography variant="button" fontWeight="medium">
    //               {title}
    //             </ArgonTypography>
    //           </ArgonBox>
    //           <ArgonBox pl={2}>
    //             <Tooltip title="Service status" placement="top" arrow>
    //               <ArgonBadge
    //                 variant="contained"
    //                 color="success"
    //                 badgeContent={status}
    //               />
    //             </Tooltip>
    //           </ArgonBox>
    //         </ArgonBox>

    //         <ArgonTypography variant="caption" color="text">
    //           {serviceScheduleDate}
    //         </ArgonTypography>

    //         {/* <ArgonTypography variant="caption" color="text">
    //       {propertyInfo?.propertyAddress?.address1}
    //     </ArgonTypography> */}
    //         <ArgonTypography variant="caption" color="text">
    //           {propertyInfo?.propertyAddress?.city},{" "}
    //           {propertyInfo?.propertyAddress?.state},{" "}
    //           {propertyInfo?.propertyAddress?.zipCode}
    //         </ArgonTypography>
    //       </ArgonBox>
    //     </Grid>
    //     <Grid item xs={12} lg={4}>
    //       <ArgonBox ml="auto" textAlign="center">
    //         <ArgonButton
    //           onClick={() => handleViewClicked(id)}
    //           variant="text"
    //           color="info"
    //         >
    //           View
    //         </ArgonButton>
    //       </ArgonBox>
    //     </Grid>
    //   </Grid>
    // </ArgonBox>
    <UpcomingAssignmentItem
      booking={assignment}
      handleViewClicked={() => handleViewClicked(assignment?.id)}
    />
  ));

  const findBooking = (bookingId) => {
    const foundBooking = assignments.find(
      (booking) => booking.id === bookingId
    );
    return foundBooking || null;
  };
  const [nextDisabled, setNextDisabled] = useState(true);
  const [userSelections, setUserSelections] = useState({});
  const [responseData, setResponseData] = useState({});
  return (
    <>
      <Card sx={{ height: "100%" }}>
        <ArgonBox pt={2} px={2}>
          <ArgonTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {title}
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox p={2}>
          <ArgonBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            {renderAssignments}
          </ArgonBox>
        </ArgonBox>
        <ArgonBox textAlign="center" mt={2}>
          <ArgonButton variant="outlined" color="primary">
            {t("buttons.viewMore")}
          </ArgonButton>
        </ArgonBox>
      </Card>
      <HostHiveDialog
        open={open}
        fullWidth={true}
        includeSave={false}
        includeClose={true}
        maxWidth="md"
        title="View Assignment"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Save"
        showStatusBadge={false}
        onClose={() => {
          setOpen(false);
        }}
        // statusColor="success"
        // statusContent={bookingInfo?.status}
        dialogObjects={
          <>
            <ViewEventDataCard
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingInfo}
              setBookingPayload={setBookingInfo}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={false}
              closeEventDataCard={() => {
                setOpen(false);
              }}
              // handleRefetchBookings={handleRefetchBookings}
            />

            {/* <EventDataCard
              bookingInfo={bookingInfo}
              propertyInfo={propertyInfo}
              isCreation={false}
              onClose={() => {
                setOpen(false);
              }}
              readOnly={true}
            /> */}
          </>
        }
      />
    </>
  );
}

// Typechecking props for the UpcomingAssignments
UpcomingAssignments.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UpcomingAssignments;
