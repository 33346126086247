/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";

import { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import ServiceNameApi from "api/service-nameApi";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import PropertyContext from "context/PropertyContext";
import { useTranslation } from "react-i18next";
function ServiceNameItemDialog({
  companyId,
  onClose,
  handleServiceNameSelected,
  handleSelection = false,
  fetchServiceNames,
  snackBarOpen,
  serviceNameDetails,
  setServiceNameDetails,
}) {
  const { t } = useTranslation("common");
  const [newServiceName, setNewServiceName] = useState();
  const [newServiceCost, setNewServiceCost] = useState(0.0);
  const [isEdit, setIsEdit] = useState(false);
  const { setCompanyServiceNames } = useContext(PropertyContext);
  // const [serviceName, setServiceName] = useState({});
  useEffect(() => {
    if (serviceNameDetails) {
      console.log(serviceNameDetails);
      setNewServiceName(serviceNameDetails?.name);
      setNewServiceCost(serviceNameDetails?.cost);
      setIsEdit(true);
    }
  }, []);

  const serviceNameApi = ServiceNameApi();
  const toggleLoading = () => setLoadingResponse((prevState) => !prevState);

  const handleSubmitClicked = async () => {
    toggleLoading();

    const commonResponseTasks = async (response, actionVerb) => {
      if (response.status >= 200 && response.status < 300) {
        if (setServiceNameDetails) {
          console.log("setting service name details", response.data);
          setServiceNameDetails(response.data);
        }

        setCompanyServiceNames((prevServiceNames) => [
          ...(Array.isArray(prevServiceNames) ? prevServiceNames : []),
          response.data,
        ]);

        if (fetchServiceNames) {
          fetchServiceNames();
        }

        //     `${serviceName.name} - $${
        //   serviceName.cost ? serviceName.cost : 0.0
        // }`,

        // if (handleSelection) {
        //   handleServiceNameSelected({
        //     label: `${response.data.name} - $${response.data.cost}`, //response.data.name + " - $" + response.data.cost,
        //     value: response.data.id,
        //   });
        // }

        if (handleSelection) {
          handleServiceNameSelected({
            label: `${response.data.name}`, //response.data.name + " - $" + response.data.cost,
            value: response.data.id,
          });
        }
        if (snackBarOpen) {
          snackBarOpen(
            `Service name ${actionVerb}`,
            `Successfully ${actionVerb} service name ${newServiceName}`
          );
        }

        onClose();
      }
    };

    const createServiceName = async () => {
      try {
        const serviceNameData = {
          companyId: companyId,
          name: newServiceName,
          cost: newServiceCost,
        };

        const response = await serviceNameApi.createServiceName(
          serviceNameData
        );
        await commonResponseTasks(response, "created");
      } catch (error) {
        console.error("Error creating service name:", error);
      }
    };

    const updateServiceName = async () => {
      try {
        const serviceNameData = {
          ...serviceNameDetails,
          name: newServiceName,
          cost: newServiceCost,
        };

        const response = await serviceNameApi.updateServiceName(
          serviceNameData.id,
          serviceNameData
        );
        await commonResponseTasks(response, "updated");
      } catch (error) {
        console.error("Error updating service name:", error);
      }
    };

    if (isEdit) {
      console.log("Editing service name");
      await updateServiceName();
    } else {
      console.log("Creating service name:", newServiceName);
      await createServiceName();
    }
  };

  const [loadingResponse, setLoadingResponse] = useState(false);
  return (
    <>
      {loadingResponse ? (
        <>
          <ArgonBox display="flex">
            <ArgonTypography variant="caption"></ArgonTypography>
            <HostHiveLoading
              message={`${
                isEdit ? t("adverbs.updating") : t("adverbs.addingNew")
              }${" "}
              ${t("components.serviceName.title")}`}
              elipse={true}
            />
          </ArgonBox>
        </>
      ) : (
        <>
          <ArgonBox p={2} lineHeight={1}>
            <ArgonBox mb={2}>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    {t("generalWords.name")}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonInput
                  placeholder={t("placeHolders.serviceName.turnoverService")}
                  type="text"
                  value={newServiceName}
                  onChange={(e) => setNewServiceName(e.target.value)}
                />
              </ArgonBox>
              {/* <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <ArgonBox
                  mb={1}
                  ml={0.5}
                  mt={3}
                  lineHeight={0}
                  display="inline-block"
                >
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Service Cost (optional)
                  </ArgonTypography>
                </ArgonBox>
                <ArgonInput
                  placeholder="$0.00"
                  type="text"
                  value={newServiceCost}
                  onChange={(e) => setNewServiceCost(e.target.value)}
                />
              </ArgonBox> */}
            </ArgonBox>

            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <ArgonBox p={1}>
                <ArgonBox
                  display="flex"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="dark"
                      onClick={onClose}
                    >
                      {t("buttons.cancel")}
                    </ArgonButton>
                  </ArgonBox>
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="info"
                      onClick={handleSubmitClicked}
                    >
                      {isEdit ? t("buttons.create") : t("buttons.update")}
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
              {/* Display role checkboxes here based on viewMode */}
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
}

export default ServiceNameItemDialog;
