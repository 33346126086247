import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components

import Menu from "@mui/material/Menu";
import { IconButton, MenuItem } from "@mui/material";
// Data

import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";

import { Grid, Icon } from "@mui/material";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonInput from "components/ArgonInput";
import { set } from "lodash";
import ArgonSelect from "components/ArgonSelect";
function UserItem({ user, refetchUsers }) {
  useEffect(() => {
    console.log("User", user);
    // Generate a new password as user firstName initial + lastName + current year
    setNewPassword(
      `${user.firstName[0]}${user.lastName}${new Date().getFullYear()}`
    );
    setNewEmail(user.email);
  }, []);
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const administrationApi = new AdministrationApi();
  const [newPassword, setNewPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [passwordResetOpen, setPasswordResetOpen] = useState(false);
  const [emailOpen, setEmailOpen] = useState(false);
  const [roleOpen, setRoleOpen] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const deleteUser = async () => {
    try {
      await administrationApi.deleteUser(user.companyId, user.id);
      refetchUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserEmail = async () => {
    try {
      await administrationApi.updateUserEmail(
        user.companyId,
        user.id,
        newEmail
      );
      setEmailOpen(false);
      refetchUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const resetPassword = async () => {
    try {
      await administrationApi.resetPassword(
        user.companyId,
        user.id,
        newPassword
      );
      setPasswordResetOpen(false);
      refetchUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const updateUserRole = async () => {
    try {
      await administrationApi.updateUserRole(
        user.companyId,
        user.id,
        userRole.value
      );
      setRoleOpen(false);
      refetchUsers();
    } catch (error) {
      console.error(error);
    }
  };

  const roleOptions = [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "supervisor",
      label: "Supervisor",
    },
    {
      value: "manager",
      label: "Manager",
    },
    {
      value: "service_associate",
      label: "Service Associate",
    },
    {
      value: "readOnly",
      label: "Read Only",
    },
  ];

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setPasswordResetOpen(true);
          setOpenMenu(null);
        }}
      >
        Reset Password
      </MenuItem>
      <MenuItem
        onClick={() => {
          setEmailOpen(true);
          setOpenMenu(null);
        }}
      >
        Update email
      </MenuItem>
      <MenuItem
        onClick={() => {
          setRoleOpen(true);
          setOpenMenu(null);
        }}
      >
        Update Role
      </MenuItem>
      <MenuItem
        onClick={() => {
          deleteUser();
          setOpenMenu(null);
        }}
      >
        Delete User
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <HostHiveDialog
        open={roleOpen}
        onClose={() => setRoleOpen(false)}
        onSave={() => updateUserRole()}
        includeSave={true}
        includeClose={true}
        title="Update User Role"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ArgonBox
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonSelect
                    options={roleOptions}
                    value={userRole}
                    onChange={(e) => setUserRole(e)}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </>
        }
      />
      <HostHiveDialog
        open={passwordResetOpen}
        onClose={() => setPasswordResetOpen(false)}
        onSave={() => resetPassword()}
        includeSave={true}
        includeClose={true}
        title="Update Password"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ArgonBox
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="New Password"
                    placeholder="New Password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </>
        }
      />

      <HostHiveDialog
        open={emailOpen}
        onClose={() => setEmailOpen(false)}
        onSave={() => updateUserEmail()}
        includeSave={true}
        includeClose={true}
        title="Update Email"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <>
            <ArgonBox
              sx={{
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonInput
                    fullWidth
                    label="New Email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </>
        }
      />
      {/* <HostHiveDialog
        open={matchOpen}
        onClose={() => setMatchOpen(false)}
        // onSave={() => handleBulkChangeCategory()}
        includeSave={false}
        includeClose={true}
        title="Import Match Wizard"
        fullScreen={false}
        maxWidth="lg"
        cancelButtonText="Cancel"
        submitButtonText="Apply"
        dialogObjects={
          <>
            <ImportMatchWizard
              bulkChangePayloadMapping={bulkChangePayloadMapping}
              setBulkChangePayloadMapping={setBulkChangePayloadMapping}
              handleSave={handleSave}
              closeWizard={() => setMatchOpen(false)}
            />
          </>
        }
      /> */}
      {renderMenu()}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {user.firstName} {user.lastName}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {user.email}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={12} sm={3}>
          <ArgonBox>
            <ArgonTypography variant="h6" fontWeight="medium">
              {user.applicationRole}
            </ArgonTypography>
          </ArgonBox>
        </Grid>
        <Grid item xs={12} sm={3}>
          <IconButton onClick={handleOpenMenu}>
            <Icon>more_vert</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
}

export default UserItem;
