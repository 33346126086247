/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// @mui material components
import Icon from "@mui/material/Icon";

// react-router-dom components
import { Link } from "react-router-dom";

// sweetalert2 components
import Swal from "sweetalert2";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
import ArgonButton from "components/ArgonButton";
import { useState } from "react";
import AlertsList from "layouts/management/components/AlertsList";
import FeedList from "layouts/management/components/FeedList";

import { Feed } from "@mui/icons-material";

function InfoWidgets({ title, value, type, handleOnClick }) {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");

  const handleSyncIntegration = (type) => {
    console.log(`${type} has been clicked`);
    setModalTitle(type);
    setOpen(true);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const showAlert = (title) =>
    Swal.fire({
      title: title,
      text: "Modal with a custom image.",
      imageUrl: "https://unsplash.it/400/200",
      imageWidth: 400,
      imageAlt: "Custom image",
    });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const recentModalTitle = (title) => {
    return `Recent ${title}`;
  };
  return (
    <Card
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <ArgonBox
              mt={5}
              mb={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} lg={3}>
                {/* <AlertsList
                  title={recentModalTitle(modalTitle)}
                  categories={alertsListData}
                /> */}
              </Grid>
            </ArgonBox>
          </Fade>
        </Modal>
      </div>
      <ArgonBox
        p={3}
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <ArgonTypography variant="body2" color="text" fontWeight="regular">
          {title}
        </ArgonTypography>
        <ArgonBox
          mt={2}
          mb={1}
          lineHeight={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ArgonTypography variant="h3" fontWeight="bold" textAlign="center">
            {value}
          </ArgonTypography>
        </ArgonBox>
        <ArgonButton
          variant="gradient"
          color="info"
          onClick={() => handleOnClick()}
        >
          View {type}
        </ArgonButton>
      </ArgonBox>
    </Card>
  );
}

export default InfoWidgets;
