/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import ServicePartnerItemDialog from "./components/ServicePartnerItemDialog";
import ServicePartnerDialog from "layouts/management/components/ServicePartnerDialog";
import ServicePartnerItem from "./components/ServicePartnerItem";
import HostHiveLoading from "components/HostHiveLoading";
import CompanyApi from "api/companyApi";
import { useParams } from "react-router-dom";

function ServicePartners({}) {
  const companyApi = CompanyApi();
  const [servicePartners, setServicePartners] = useState([]);

  const [servicePartner, setServicePartner] = useState({});
  const [servicePartnerDialogOpen, setServicePartnerDialogOpen] =
    useState(false);
  const [servicePartnersLoading, setServicePartnersLoading] = useState(true);
  const [invitedCompany, setInvitedCompany] = useState({});
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [fetchServicePartners, setFetchServicePartners] = useState(false);
  useEffect(() => {
    async function fetchServicePartners() {
      if (company?.id) {
        try {
          const queryParams = {
            includeSelf: false,
          };

          const response = await companyApi.getCompanyPartnerships(
            company?.id,
            queryParams
          );

          setServicePartners(response.data);

          setServicePartnersLoading(false);
        } catch (error) {
          console.log("We got an error here");
          console.error(error);
        }
      }
    }
    fetchServicePartners();
  }, [fetchServicePartners]);

  const handlePartnerSelected = () => {
    setServicePartnerDialogOpen(false);
    setFetchServicePartners(!fetchServicePartners);
  };

  return (
    <Card
      id="service-partners"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      <HostHiveDialog
        open={servicePartnerDialogOpen}
        includeSave={false}
        includeClose={false}
        title="Add Service Partner"
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ServicePartnerItemDialog
            companyId={company?.id}
            handlePartnerSelected={handlePartnerSelected}
            handleSelection={true}
            onClose={() => {
              setServicePartnerDialogOpen(false);
            }}
          />
        }
      />
      <ArgonBox p={3}>
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">Service Partners</ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => setServicePartnerDialogOpen(true)}
            >
              {" "}
              + Add Service Partner
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        {servicePartnersLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            p={2}
            mt={1}
            width="100%"
            //height="26.25rem"
            height="auto"
            style={{ overflow: "auto" }}
          >
            {servicePartners.map(({ id, name, email, status = null }, key) => (
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                key={id}
              >
                <ServicePartnerItem
                  name={name}
                  isPending={status === "pending" ? true : false}
                  id={id}
                  company={company}
                  email={email}
                  refetchServicePartners={() =>
                    setFetchServicePartners(!fetchServicePartners)
                  }
                />
              </ArgonBox>
            ))}
          </ArgonBox>
        )}
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}></ArgonBox>
    </Card>
  );
}

export default ServicePartners;
