// API client for /tasks endpoint
// User sends payload, payload is validated and sent to axiosPrivate
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function TasksApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized("tasks", role, subscriptionTier);

  if (!isAuthorized) {
    return null;
  }

  const getTasks = (queryParams) => {
    return axiosPrivate.get(`/tasks`, { params: queryParams });
  };

  const createTask = (taskData) => {
    return axiosPrivate.post(`/tasks`, taskData);
  };

  const getTask = (taskId) => {
    return axiosPrivate.get(`/tasks/${taskId}`);
  };

  const updateTask = (taskId, taskData) => {
    console.log(taskData);
    return axiosPrivate.put(`/tasks/${taskId}`, taskData);
  };

  const updateTaskStatus = (taskId, status) => {
    return axiosPrivate.put(`/tasks/${taskId}/status/${status}`);
  };

  const updateTaskPriority = (taskId, priority) => {
    return axiosPrivate.put(`/tasks/${taskId}/priority/${priority}`);
  };

  const updateTaskDueDate = (taskId, dueDate) => {
    return axiosPrivate.put(`/tasks/${taskId}/dueDate/${dueDate}`);
  };

  const updateTaskSubAssigneeId = (taskId, subAssigneeId) => {
    return axiosPrivate.put(`/tasks/${taskId}/subAssigneeId/${subAssigneeId}`);
  };

  const deleteTask = (taskId) => {
    return axiosPrivate.delete(`/tasks/${taskId}`);
  };

  const updateTaskImages = (
    taskId,
    companyId,
    propertyId,
    fileName,
    fileType,
    summary,
    image
  ) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.put(
      `/tasks/${taskId}/images?name=${fileName}&type=${fileType}&companyId=${companyId}&propertyId=${propertyId}&summary=${summary}`,
      image,
      { headers }
    );
  };

  const deleteTaskImage = (taskId, companyId, propertyId, fileName) => {
    return axiosPrivate.delete(
      `/tasks/${taskId}/images?name=${fileName}&companyId=${companyId}&propertyId=${propertyId}`
    );
  };

  return {
    getTasks,
    createTask,
    getTask,
    updateTask,
    deleteTask,
    updateTaskStatus,
    updateTaskPriority,
    updateTaskSubAssigneeId,
    updateTaskDueDate,
    updateTaskImages,
    deleteTaskImage,
  };
}

export default TasksApi;
