import * as Yup from "yup";

const InventoryUsageSchema = Yup.object().shape({
  comment: Yup.string(),
  propertyId: Yup.string().nullable(true),
  companyId: Yup.string().required("Company is required"),
  inventoryId: Yup.string().required("Inventory is required"),
  usageAmount: Yup.number().required("Usage amount is required"),
  bookingId: Yup.string().nullable(true),
});

export default InventoryUsageSchema;
