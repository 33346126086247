/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import ArgonSelect from "components/ArgonSelect";
import PropertiesApi from "api/propertiesApi";
function Address({
  formData,
  uploadResponse,
  propertyAppMatch,
  setPropertyAppMatch,
  setNextDisabled,
}) {
  const [state, setState] = useState("...");
  const { formField, values, errors, touched } = formData;
  const { address1, address2, city, zip } = formField;
  const {
    address1: address1V,
    address2: address2V,
    city: cityV,
    zip: zipV,
  } = values;

  const handleSetState = (event) => setState(event.target.value);
  const propertiesApi = new PropertiesApi();
  const [properties, setProperties] = useState([]);

  const [fileProperties, setFileProperties] = useState(
    uploadResponse?.properties || []
  );

  const MemoizedArgonSelect = React.memo(ArgonSelect);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  // const [propertyAppMatch, setPropertyAppMatch] = useState({});

  const memoizedProperties = useMemo(() => properties, [properties]);

  useEffect(() => {
    async function fetchProperties() {
      try {
        const queryParams = {
          companyId: company?.id,
          ownerOnly: true,
        };

        const response = await propertiesApi.getProperties(queryParams);

        const options = response.data.map((property) => ({
          value: property.id,
          label: property.name,
        }));

        setProperties(options);
      } catch (error) {
        console.error(error);
      }
    }
    setNextDisabled(false);
    fetchProperties();
  }, []);

  const handlePropertyMatch = (e, property) => {
    setPropertyAppMatch((prev) => ({
      ...prev,
      [property.value]: e,
    }));

    // Remove the e.value from memoizedProperties
    // const updatedProperties = memoizedProperties.filter(
    //   (prop) => prop.value !== e.value
    // );

    // setProperties(updatedProperties);
  };

  return (
    <ArgonBox>
      <ArgonBox lineHeight={0}>
        {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
        <ArgonTypography variant="button" fontWeight="regular" color="text">
          Match the properties in your file to the properties in your properties
          in HostHive.
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox mt={1.625}>
        {fileProperties.map((property) => {
          return (
            <ArgonBox p={2} lineHeight={1}>
              <ArgonTypography variant="h5" fontWeight="medium">
                {property.label}
              </ArgonTypography>
              <MemoizedArgonSelect
                label="Property"
                value={propertyAppMatch[property.value]}
                onChange={(e) => handlePropertyMatch(e, property)}
                options={memoizedProperties}
                fullWidth
              />
            </ArgonBox>
          );
        })}
      </ArgonBox>
      <ArgonButton
        variant="gradient"
        color="light"
        onClick={() => console.log(propertyAppMatch)}
      >
        Console log
      </ArgonButton>
    </ArgonBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
