import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import Swal from "sweetalert2";
import convertToOptions from "services/convertooptions";
import ArgonAlert from "components/ArgonAlert";
import { showDeleteConfirmation } from "services/confirmDelete";
import HostHiveCaption from "components/HostHiveCaption";
import useMediaQuery from "@mui/material/useMediaQuery";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropertyContext from "context/PropertyContext";
import SettingsApi from "api/settingsApi";
import ServiceNameSelect from "layouts/management/components/ServiceNameSelect";
import ChecklistSelect from "layouts/management/components/ChecklistSelect";
import { check } from "prettier";
import HostHiveAlert from "components/HostHiveAlert";
import GuestBookApi from "api/guestBookApi";
import PropertyIntegrationSchema from "schemas/propertyIntegrationSettingsSchema";
import { useFormik } from "formik";
import * as Yup from "yup";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import RecurrenceSeriesDialog from "layouts/management/components/RecurrenceSeriesDialog";
import HostHiveDialog from "components/HostHiveDialog";
import BookingsApi from "api/bookingsApi";
import CompaniesApi from "api/companyApi";
import ChecklistsApi from "api/checkListsApi";
import ServiceNameApi from "api/service-nameApi";
import UsersApi from "api/usersApi";
import ApplicationApi from "api/applicationApi";
import ContactSupportApi from "api/contactSupportApi";
import IntegrationItem from "./components/IntegrationItem";
import { set } from "lodash";
import ServicePartnerItemDialog from "layouts/management/company/settings/components/ServicePartners/components/ServicePartnerItemDialog";
import { use } from "i18next";
import NewIntegrationItem from "./components/NewIntegrationItem";
function IntegrationSettingsDataCard({
  title,
  handleClose,
  propertyId,
  propertySettings,
  setPropertySettings,
  companyId,
  setIntegrationSettingsTypeName,

  setApplicationIntegrationTypes,
  setAvailableChecklists,
  setAvailableServiceNames,
  setAvailableServicers,
  setAvailableSubAssigneeUsers,
  availableChecklists,
  availableServiceNames,
  availableServicers,
  availableSubAssigneeUsers,
  applicationIntegrationTypes,
  triggerExternalCalendarFetch,
  hasPlusMembership,
}) {
  const { t } = useTranslation("common");
  // APIs
  const settingsApi = SettingsApi();
  const contactSupportApi = ContactSupportApi();
  const [showAlert, setShowAlert] = useState(false);

  const { alertContent, renderAlert } = HostHiveAlert();
  const guestBookApi = GuestBookApi();
  const bookingsApi = BookingsApi();
  const checkListsApi = ChecklistsApi();
  const serviceNamesApi = ServiceNameApi();
  const companiesApi = CompaniesApi();
  const usersApi = UsersApi();
  const applicationApi = ApplicationApi();

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [error, setError] = useState(null);
  const [integrationType, setIntegrationType] = useState({});
  const [serviceProvider, setServiceProvider] = useState();
  const [checklist, setChecklist] = useState();
  const [serviceName, setServiceName] = useState(
    propertySettings?.defaultServiceName
  );

  const [selectedServicer, setSelectedServicer] = useState(null);
  const [selectedChecklist, setSelectedChecklist] = useState(null);
  const [selectedSubAssignee, setSelectedSubAssignee] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const [triggerIntegrationFetch, setTriggerIntegrationFetch] = useState(false);
  const [testUrlAlertMessage, setTestUrlAlertMessage] = useState(null);
  const [showTestUrlAlert, setShowTestUrlAlert] = useState(false);
  const [testUrlAlertColor, setTestUrlAlertColor] = useState("success");
  // Media Queries
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [settingsDisabled, setSettingsDisabled] = useState(false);
  const [calendarSyncEnabled, setCalendarSyncEnabled] = useState(
    propertySettings?.calendarSyncEnabled
  );
  // Input States
  const [integrationUrl, setIntegrationUrl] = useState(
    propertySettings?.integrationUrl
  );

  // const [applicationIntegrationTypes, setApplicationIntegrationTypes] =
  //   useState([]);
  const [appointmentIntegrationCounts, setAppointmentIntegrationCounts] =
    useState(0);
  const [availableIntegrations, setAvailableIntegrations] = useState([]);
  useEffect(() => {
    async function fetchAvailableIntegrations() {
      try {
        const response = await settingsApi.getPropertyAvailbleIntegrations(
          propertyId
        );
        setAvailableIntegrations(response.data);
        console.log("Available integrations", response.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAvailableIntegrations();
  }, [triggerIntegrationFetch]);

  useEffect(() => {
    const loadCompanyDetails = async () => {
      setIsloading(true);
      console.log("Fetching details");
      try {
        setSelectedFromResponse(
          applicationIntegrationTypes,
          "integrationType",
          setIntegrationType,
          "name"
        );

        setSelectedFromResponse(
          availableChecklists,
          "defaultChecklist",
          setChecklist,
          "name"
        );
        setSelectedFromResponse(
          availableServiceNames,
          "defaultServiceName",
          setServiceName,
          "name"
        );
        setSelectedFromResponse(
          availableServicers,
          "defaultServiceProvider",
          setServiceProvider,
          "name"
        );
        // setSelectedFromResponse(
        //   companyUsersResponse,
        //   "assignedUserSubId",
        //   setSelectedSubAssignee,
        //   "email"
        // );
      } catch (error) {
        console.error(error);
      } finally {
        setIsloading(false);
      }
    };

    const setSelectedFromResponse = (
      response,
      propertySettingsKey,
      setState,
      labelKey
    ) => {
      let matchingItem = {};
      if (propertySettingsKey === "integrationType") {
        matchingItem = response?.find(
          (item) => item.id === propertySettings?.[propertySettingsKey]
        );
      } else {
        matchingItem = response?.find(
          (item) => item.id === propertySettings?.[propertySettingsKey]
        );
      }

      if (matchingItem) {
        console.log("matchingItem", matchingItem);
        setState({
          label: matchingItem[labelKey],
          value: matchingItem.id,
        });
      }
    };
    async function fetchAppointmentIntegrationCounts() {
      try {
        const queryParams = {
          propertyId: propertyId,
          isIntegration: true,
        };
        const response = await bookingsApi.getEventsToClear(queryParams);
        // console.log("INT response", response);
        setAppointmentIntegrationCounts(response.data.count);
      } catch (error) {
        console.error(error);
      }
    }
    fetchAppointmentIntegrationCounts();
    loadCompanyDetails();
  }, []); // Assuming all dependencies like company, propertySettings are stable

  const [openSeriesDialog, setOpenSeriesDialog] = useState(false);

  const DisplayEntry = ({
    entryKey,
    displayValue,
    kbHelpUrl = null,
    kbHelpText = null,
    lookupKey,
  }) => {
    return (
      <ArgonBox
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        p={1}
      >
        <ArgonBox>
          <ArgonTypography
            component="label"
            variant="caption"
            fontWeight="bold"
            textTransform="capitalize"
          >
            {entryKey}:
          </ArgonTypography>
          {lookupKey ? (
            <HostHiveKbHelp
              // helpUrl={kbHelpUrl}
              marginBottom={0}
              // toolTipText={kbHelpText}
              lookupKey={lookupKey}
            />
          ) : null}
        </ArgonBox>
        <ArgonBox>
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            {displayValue}
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    );
  };

  const confirmUserAction = async () => {
    try {
      await showDeleteConfirmation(
        `Are you sure you want to clear all ${appointmentIntegrationCounts} appointments?`,
        "Clear Appointments",
        "Yes, clear all appointments"
      );
      console.log(`User confirmed deletion!`);
      setAppointmentIntegrationCounts(0);
      return true;
    } catch (response) {
      console.log("User cancelled deletion!");
      return false;
    }
  };

  const showClearAlert = (title, message, action) => {
    Swal.fire(title, message, action);
  };
  const [requestNewIntegrationOpen, setRequestNewIntegrationOpen] =
    useState(false);
  const handleIntegrationTypeChange = (e) => {
    if (e.value === "requestIntegration") {
      console.log("Request Integration");
      setRequestNewIntegrationOpen(true);
    } else {
      setIntegrationType(e);
    }
  };

  const handleIntegrationUrlChange = (event) => {
    setIntegrationUrl(event.target.value);
  };

  const handleDefaultServiceProviderChange = (event) => {
    setServiceProvider(event);
    console.log(event);
  };

  // const integrationTypeOption = applicationIntegrationTypes.find(
  //   (integration) => integration.id === propertySettings?.integrationType
  // );

  // const propertyServicePartnerOption = propertyServicePartners.find(
  //   (servicePartner) =>
  //     servicePartner.id === propertySettings?.defaultServiceProvider
  // );

  // const integrationTypeOptions = applicationIntegrationTypes?.map(
  //   (integrationType) => ({
  //     value: integrationType.id,
  //     label: integrationType.name,
  //   })
  // );

  // const serviceProviderOptions = propertyServicePartners?.map(
  //   (servicePartner) => ({
  //     value: servicePartner.id,
  //     label: servicePartner.name,
  //   })
  // );

  // serviceProviderOptions.push({
  //   value: "clear",
  //   label: " - Unassign - ",
  // });

  const [alertMessage, setAlertMessage] = useState(null);

  // const [showTestUrlAlert, setShowTestUrlAlert] = useState(false);
  // const [testUrlAlertColor, setTestUrlAlertColor] = useState("success");
  const handleTestIcalUrl = async (submission = false) => {
    setShowTestUrlAlert(false);
    setTestUrlAlertMessage(null);
    setTestUrlAlertColor("success");
    if (integrationUrl === null || integrationUrl === undefined) {
      setTestUrlAlertMessage(
        "Integration URL cannot be empty or uknown, please input a value and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
    }
    let integrationUrlData = {
      url: integrationUrl,
    };
    try {
      const response = await guestBookApi.testIcalUrl(integrationUrlData);
      if (response.status >= 200 && response.status < 300) {
        if (submission === true) {
          return true;
        }
        setTestUrlAlertMessage("Integration URL is valid!");
        setTestUrlAlertColor("success");
        setShowTestUrlAlert(true);
      }
    } catch (error) {
      setTestUrlAlertMessage(
        "Integration URL is invalid, please check the URL and try again."
      );
      setTestUrlAlertColor("error");
      setShowTestUrlAlert(true);
      return false;
    }
  };
  const submitForm = async () => {
    setShowAlert(false);
    setIsSaving(true);
    setError(null);

    let validUrl = false;
    validUrl = await handleTestIcalUrl(true);
    console.log("validUrl", validUrl);
    if (!validUrl) {
      setIsSaving(false);
      return;
    }
    let propertySettingsData = {
      integrationType: integrationType?.value,
      integrationUrl: integrationUrl,
      defaultChecklist: checklist?.value,
      defaultServiceProvider:
        serviceProvider?.value === "clear" ? null : serviceProvider?.value,
      defaultServiceName: serviceName?.value,
      companyId: companyId,
      calendarSyncEnabled: calendarSyncEnabled,
      updateSeries: updateSeries,
    };
    console.log(propertySettingsData["defaultServiceName"]);
    // Only add the defaultChecklist property if checklist.value is not null
    // if (checklist?.value !== null) {
    //   propertySettingsData.defaultChecklist = checklist.value;
    // }
    if (!propertySettingsData["integrationUrl"]) {
      setAlertMessage(
        `The field Integration Url cannot be empty or unknown, please select a value`
      );
      setShowAlert(true);
      // setSubmitFormReady(false);
      setIsSaving(false);
      return; // exit from the function early as the validation failed
    }
    if (!propertySettingsData["defaultServiceName"]) {
      setAlertMessage(
        `The field Service Name cannot be empty or unknown, please select a value`
      );
      setShowAlert(true);
      // setSubmitFormReady(false);
      setIsSaving(false);
      return; // exit from the function early as the validation failed
    }

    // if (!propertySettingsData["integrationType"]) {
    //   setAlertMessage(
    //     `The field Integration type cannot be empty or unknown, please select a value`
    //   );
    //   setShowAlert(true);
    //   // setSubmitFormReady(false);
    //   setIsSaving(false);
    //   return; // exit from the function early as the validation failed
    // }

    // for (let key in propertySettingsData) {
    //   if (propertySettingsData[key] === null) {
    //     alertContent(
    //       `The field ${key} cannot be empty or uknown, please select a value.`
    //     );
    //     setShowAlert(true);
    //     setSubmitFormReady(false);
    //     setIsSaving(false);
    //     return; // exit from the function early as the validation failed
    //   }
    // }

    try {
      const response = await settingsApi.upsertSetting(
        "integration",
        propertyId,
        propertySettingsData
      );
      console.log("response", response);
      if (response.status >= 200 && response.status < 300) {
        setIntegrationSettingsTypeName(`${integrationType.label} Integration`);
        setPropertySettings(response.data);
      } else {
        setIsSaving(false);
        setError(response);
      }

      if (triggerExternalCalendarFetch) {
        // Wait 5 seconds, then refresh calendar
        setTimeout(() => {
          triggerExternalCalendarFetch();
        }, 5000);
      } else {
        console.log("No triggerExternalCalendarFetch function provided");
      }

      setIsSaving(false);
      handleClose();
    } catch (error) {
      setError(error.message);
      setIsSaving(false);
      console.error(error);
    }

    setIsSaving(false);
  };

  const onSubmit = async (values) => {
    console.log(values);
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      defaultChecklist: propertySettings?.defaultChecklist || null,
      defaultServiceName: propertySettings?.defaultServiceName || null,
      defaultServiceProvider: propertySettings?.defaultServiceProvider || null,
      integrationType: propertySettings?.integrationType || null,
      integrationUrl: propertySettings?.integrationUrl || null,
      companyId: companyId,
      status: "active",
      // Add more fields here
    },
    validationSchema: PropertyIntegrationSchema,
    onSubmit,
  });

  const [recurrenceSeriesDialogOpen, setRecurrenceSeriesDialogOpen] =
    useState(false);
  const [updateSeries, setUpdateSeries] = useState(null);
  const handleSaveClicked = () => {
    if (
      (propertySettings?.defaultChecklist !== checklist?.value ||
        propertySettings?.defaultServiceProvider !== serviceProvider?.value ||
        propertySettings?.defaultServiceName !== serviceName?.value) &&
      appointmentIntegrationCounts > 0
    ) {
      setRecurrenceSeriesDialogOpen(true);
    } else {
      submitForm();
    }
  };
  const [newIntegrationUrl, setNewIntegrationUrl] = useState(null);
  const [newIntegrationEntity, setNewIntegrationEntity] = useState(null);

  const submitNewIntegrationRequest = async () => {
    // companyId: company?.id,
    //   propertyId: property?.id || propertyInfo?.propertyId || "",
    //   summary: supportItem?.summary || "",
    //   description: supportItem?.description || "",
    //   status: "active",

    let values = {
      companyId: companyId,
      propertyId: propertyId,
      summary: "Request New Integration",
      description: `Integration URL: ${newIntegrationUrl} \n Integration Entity: ${newIntegrationEntity}`,
      status: "active",
    };

    try {
      const response = await contactSupportApi.createSupportTicket(values);
      if (response.status >= 200 && response.status < 300) {
        const serviceDeskKey = response.data.serviceDeskKey;
        setRequestNewIntegrationOpen(false);
        showClearAlert(
          "Success",
          `We have received your integration request, check ticket ${serviceDeskKey} in support for updates.`,
          "success"
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const convertServiceProviderOptions = (servicePartners) => {
    let options = servicePartners.map((servicePartner) => ({
      value: servicePartner.id,
      label: servicePartner.name,
    }));
    options.push({
      value: "new",
      label: `+ Add New Service Partner`,
    });

    return options;
  };
  const [isNewServicePartner, setIsNewServicePartner] = useState(false);
  const handlePartnerSelected = (e) => {
    console.log("Partner selected", e);
    if (e.value === "new") {
      setIsNewServicePartner(true);
    } else {
      setIsNewServicePartner(false);
      setServiceProvider(e);
    }
  };

  const [newIntegrationModalVisible, setNewIntegrationModalVisible] =
    useState(false);
  const [createNewIntegration, setCreateNewIntegration] = useState(false);
  const handleCreateNewIntegration = () => {
    setNewIntegrationModalVisible(true);
    setCreateNewIntegration(true);
  };
  const handleCloseCreateNewIntegration = () => {
    setCreateNewIntegration(false);
    setNewIntegrationModalVisible(false);
  };
  return (
    <Card
      sx={{
        p: isSmallScreen ? 1 : 2,
        width: "150%",
        overflow: "visible",
        width: "100%",
        height: "100%",
        minWidth: "600px",
        "@media (max-width: 600px)": {
          minWidth: "auto",
        },
      }}
    >
      <HostHiveDialog
        includeDelete={false}
        includeClose={false}
        includeSave={false}
        open={isNewServicePartner}
        onClose={() => {
          setIsNewServicePartner(false);
        }}
        title="Add Service Partner"
        fullScreen={false}
        cancelButtonText="Close"
        submitButtonText="Create"
        maxWidth="sm"
        dialogObjects={
          <ServicePartnerItemDialog
            companyId={companyId}
            handlePartnerSelected={handlePartnerSelected}
            handleSelection={true}
            onClose={() => {
              setIsNewServicePartner(false);
            }}
          />
        }
      />
      {showAlert && (
        <ArgonAlert
          color="error"
          dismissible
          onDismiss={() => {
            setAlertMessage(null);
            setShowAlert(false);
          }}
        >
          <ArgonTypography variant="body2" color="white">
            Error: {alertMessage}
          </ArgonTypography>
        </ArgonAlert>
      )}
      {error ? (
        <Grid item sx={{ pt: 2 }}>
          <ArgonTypography variant="body2" color="warning">
            {error}
          </ArgonTypography>
        </Grid>
      ) : null}
      {isSaving ? (
        <>
          <HostHiveLoading
            message={`${t("generalWords.saving")}${" "}
              ${t(
                "properties.components.integrationSettingsDataCard.propertyIntegrationSettings.title"
              )}`}
            elipse={true}
          />
        </>
      ) : (
        <>
          <ArgonBox p={2}>
            {showTestUrlAlert ? (
              <ArgonAlert
                color={testUrlAlertColor}
                dismissible
                onDismiss={() => {
                  setTestUrlAlertMessage(null);
                  setShowTestUrlAlert(false);
                }}
              >
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {testUrlAlertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}
            <HostHiveDialog
              open={recurrenceSeriesDialogOpen}
              fullWidth={true}
              includeSave={false}
              includeClose={false}
              maxWidth="sm"
              title="Appointment Integration Settings"
              fullScreen={false}
              showStatusBadge={false}
              statusColor="success"
              statusContent="Confirmed"
              dialogObjects={
                <RecurrenceSeriesDialog
                  hideSingleOption={true}
                  allAppointmentsText="All New & Existing Appointments"
                  onlyNewAppointmentsText="Only New Appointments"
                  submiteButtonText="Apply"
                  allAppointmentsValue="existingAndNew"
                  onlyNewValue="onlyNew"
                  onClose={() => setRecurrenceSeriesDialogOpen(false)}
                  setUpdateSeries={setUpdateSeries}
                  handleSubmitClicked={submitForm}
                />
              }
            />
            <HostHiveDialog
              open={requestNewIntegrationOpen}
              fullWidth={true}
              includeSave={true}
              includeClose={true}
              onClose={() => setRequestNewIntegrationOpen(false)}
              onSave={submitNewIntegrationRequest}
              maxWidth="sm"
              title="Request New Integration Support"
              fullScreen={false}
              submitButtonText="Request"
              cancelButtonText="Cancel"
              dialogObjects={
                <>
                  <ArgonBox>
                    <ArgonBox>
                      <DisplayEntry
                        entryKey={t(
                          "properties.components.integrationSettingsDataCard.settingsOpen.integrationUrl"
                        )}
                        lookupKey="integrationUrl"
                        // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                        // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                      />
                    </ArgonBox>
                    <ArgonBox display="flex">
                      <ArgonInput
                        disabled={settingsDisabled}
                        fullWidth
                        value={newIntegrationUrl ? newIntegrationUrl : ""}
                        onChange={(e) => setNewIntegrationUrl(e.target.value)}
                        multiline
                      />
                      <ArgonBox pl={2}></ArgonBox>
                    </ArgonBox>
                    <ArgonBox>
                      <DisplayEntry
                        entryKey="Integration Entity"
                        // lookupKey="integrationUrl"
                        // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                        // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                      />
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonInput
                        // disabled={settingsDisabled}
                        fullWidth
                        value={newIntegrationEntity ? newIntegrationEntity : ""}
                        onChange={(e) =>
                          setNewIntegrationEntity(e.target.value)
                        }
                        placeholder="e.g. VRBO, Expedia, etc."
                        // multiline
                      />
                    </ArgonBox>
                  </ArgonBox>
                </>
              }
            />

            <ArgonBox>
              <ArgonBox>
                <DisplayEntry
                  entryKey={t("components.servicePartner.title")}
                  // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                  // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                  lookupKey="servicePartner"
                />
              </ArgonBox>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                {settingsDisabled ? (
                  <>
                    <ArgonSelect />
                  </>
                ) : (
                  // <ArgonSelect
                  //   fullWidth
                  //   options={convertToOptions(availableServicers)}
                  //   onChange={(e) => handleDefaultServiceProviderChange(e)}
                  //   value={serviceProvider}
                  // />

                  <ArgonSelect
                    menuPlacement="bottom"
                    value={serviceProvider}
                    options={convertServiceProviderOptions(availableServicers)}
                    onChange={handlePartnerSelected}
                  />
                )}
              </ArgonBox>
              {/* <HostHiveCaption
                caption="The company that will be automatically assigned to the scheduled appointment."
                helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
              /> */}
            </ArgonBox>

            <ArgonBox>
              <ServiceNameSelect
                disabled={settingsDisabled}
                serviceNames={availableServiceNames}
                setServiceNames={setAvailableServiceNames}
                companyId={companyId}
                serviceName={serviceName}
                setServiceName={setServiceName}
              />
            </ArgonBox>
            {hasPlusMembership && (
              <ArgonBox>
                <ChecklistSelect
                  optional={true}
                  disabled={settingsDisabled}
                  companyId={companyId}
                  checklists={availableChecklists}
                  setChecklists={setAvailableChecklists}
                  checklist={checklist}
                  setChecklist={setChecklist}
                />
              </ArgonBox>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DisplayEntry
                  entryKey="Calendar Integrations"
                  lookupKey="calendarSyncEnabled"
                  //kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                  // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                />
              </Grid>
              {/* map only where disabled = false */}

              <Grid item xs={12}>
                <ArgonBox
                  display="flex"
                  sx={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                  }}
                >
                  <ArgonBox>
                    <ArgonTypography
                      component="label"
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Add New Integration
                    </ArgonTypography>
                    <ArgonBox>
                      <ArgonTypography></ArgonTypography>
                    </ArgonBox>
                  </ArgonBox>
                  <ArgonBox onClick={() => handleCreateNewIntegration()}>
                    <Icon fontSize="default" sx={{ cursor: "pointer" }}>
                      add_outline
                    </Icon>
                    {/* outlined add icon */}
                  </ArgonBox>
                </ArgonBox>
              </Grid>
              {
                <NewIntegrationItem
                  propertyId={propertyId}
                  companyId={companyId}
                  setTriggerIntegrationFetch={setTriggerIntegrationFetch}
                  triggerIntegrationFetch={triggerIntegrationFetch}
                  setAvailableIntegrations={setAvailableIntegrations}
                  availableIntegrations={availableIntegrations}
                  newIntegrationModalVisible={newIntegrationModalVisible}
                  setNewIntegrationModalVisible={setNewIntegrationModalVisible}
                />
              }

              {availableIntegrations.map(
                (integration, index) =>
                  integration.disabled === true && (
                    <IntegrationItem
                      position={index}
                      integration={integration}
                      propertyId={propertyId}
                      companyId={companyId}
                      setTriggerIntegrationFetch={setTriggerIntegrationFetch}
                      triggerIntegrationFetch={triggerIntegrationFetch}
                      setAvailableIntegrations={setAvailableIntegrations}
                      availableIntegrations={availableIntegrations}
                      setNewIntegrationModalVisible={
                        setNewIntegrationModalVisible
                      }
                      newIntegrationModalVisible={newIntegrationModalVisible}
                      createNew={createNewIntegration}
                      handleCloseCreateNewIntegration={
                        handleCloseCreateNewIntegration
                      }
                    />
                  )
              )}
            </Grid>

            {/* <ArgonBox display="flex">
              <ArgonBox>
                <DisplayEntry
                  entryKey={t(
                    "properties.components.integrationSettingsDataCard.settingsOpen.calendarSyncEnabled"
                  )}
                  lookupKey="calendarSyncEnabled"
                  //kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                  // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                />
              </ArgonBox>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                p={1}
                // pb={1}
              >
                <Switch
                  checked={calendarSyncEnabled}
                  onChange={() => setCalendarSyncEnabled(!calendarSyncEnabled)}
                />
              </ArgonBox>
            </ArgonBox>
            <ArgonBox>
              <ArgonBox>
                <DisplayEntry
                  entryKey={t(
                    "properties.components.integrationSettingsDataCard.settingsOpen.integrationType"
                  )}
                  lookupKey="integrationType"
                  // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                  // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                />
              </ArgonBox>
              <ArgonBox
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
                pb={1}
                disabled={settingsDisabled}
              >
                <ArgonSelect
                  value={integrationType}
                  options={convertToOptions(applicationIntegrationTypes)}
                  onChange={(e) => handleIntegrationTypeChange(e)}
                />
              </ArgonBox>
            </ArgonBox>
            <ArgonBox>
              <ArgonBox>
                <DisplayEntry
                  entryKey={t(
                    "properties.components.integrationSettingsDataCard.settingsOpen.integrationUrl"
                  )}
                  lookupKey="integrationUrl"
                  // kbHelpUrl="https://docs.hosthive.io/en/key-features-glossary"
                  // kbHelpText="Assign reliable business allies to handle specific maintenance and cleaning tasks"
                />
              </ArgonBox>
              <ArgonBox display="flex">
                <ArgonInput
                  disabled={settingsDisabled}
                  fullWidth
                  value={integrationUrl ? integrationUrl : ""}
                  onChange={handleIntegrationUrlChange}
                  multiline
                />
                <ArgonBox pl={2}></ArgonBox>

                <ArgonButton
                  disabled={settingsDisabled}
                  variant="gradient"
                  color="success"
                  onClick={handleTestIcalUrl}
                >
                  {t("buttons.test")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox> */}
          </ArgonBox>

          <ArgonBox justifyContent="center" display="flex" p={2}>
            {/* <ArgonBox displayContent="flex-start" pr={2}>
              <ArgonButton
                variant="gradient"
                color="error"
                onClick={handleClose}
              >
                Delete
              </ArgonButton>
            </ArgonBox> */}
            <ArgonBox display="flex">
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={handleClose}
                >
                  {t("buttons.close")}
                </ArgonButton>
              </ArgonBox>
              <ArgonBox pr={2}>
                <ArgonButton
                  variant="gradient"
                  color="info"
                  onClick={handleSaveClicked}
                >
                  {t("buttons.save")}
                </ArgonButton>
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </Card>
  );
}

IntegrationSettingsDataCard.propTypes = {
  title: PropTypes.string.isRequired,
};

export default IntegrationSettingsDataCard;
