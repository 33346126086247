/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import HostHiveDialog from "components/HostHiveDialog";
import ChecklistItem from "./components/ChecklistItem";
import ChecklistItemDialog from "./components/ChecklistItemDialog";
import HostHiveLoading from "components/HostHiveLoading";
import ChecklistsApi from "api/checkListsApi";
import { useParams } from "react-router-dom";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveAlert from "components/HostHiveAlert";
import { useTranslation } from "react-i18next";
function ServiceChecklists({}) {
  const { t } = useTranslation("common");
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const { companyId } = useParams();

  // const checklistApi = new ChecklistsApi();
  const checklistApi = ChecklistsApi();
  const [serviceChecklistsLoading, setServiceChecklistsLoading] =
    useState(false);
  const [checklists, setChecklists] = useState([]);
  const [checklistItem, setChecklistItem] = useState({});
  const [checklistDialogOpen, setChecklistDialogOpen] = useState(false);
  const [updatedChecklist, setUpdatedChecklist] = useState(false);
  const handleCancelClicked = () => {
    setChecklistDialogOpen(false);
  };
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  useEffect(() => {
    console.log("Fetching checklists");
    async function fetchChecklists() {
      setServiceChecklistsLoading(true);
      // If companyId is null, use company.id as the companyId

      try {
        const queryParams = {
          companyId: company?.id,
          status: "active",
        };

        const response = await checklistApi.getChecklists(queryParams);
        console.log("Checklist response", response.data);
        setChecklists(response.data);

        setServiceChecklistsLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    if (checklistApi) {
      fetchChecklists();
    } else {
      console.error("Requires calendar plus");
      setServiceChecklistsLoading(false);
    }
  }, [updatedChecklist]);
  return (
    <Card
      id="service-checklists"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      {renderSuccessSB}
      <HostHiveDialog
        open={checklistDialogOpen}
        onClose={() => setChecklistDialogOpen(false)}
        includeSave={false}
        includeClose={false}
        title={t("components.checklist.create.createNewChecklist")}
        fullScreen={false}
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ChecklistItemDialog
            checklistItem={checklistItem}
            setChecklistItem={setChecklistItem}
            onClose={() => setChecklistDialogOpen(false)}
            companyId={company?.id}
            fetchChecklists={() => setUpdatedChecklist(!updatedChecklist)}
            snackBarOpen={openSuccessSB}
          />
        }
      />
      <ArgonBox p={3}>
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">
              {t("components.checklist.serviceTitle")}
            </ArgonTypography>
          </ArgonBox>
          <ArgonBox>
            <ArgonButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => setChecklistDialogOpen(true)}
            >
              {" "}
              {`+ ${t("components.checklist.title")}`}
            </ArgonButton>
          </ArgonBox>
        </ArgonBox>
        {serviceChecklistsLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            p={2}
            mt={1}
            width="100%"
            //height="26.25rem"
            height="auto"
            style={{ overflow: "auto" }}
          >
            {checklists?.map((checklist, key) => (
              <ArgonBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
                key={checklist?.id}
              >
                <ChecklistItem
                  // name={name}
                  // checklist={checklist?.checkList}
                  // id={id}
                  checklistDetails={checklist}
                  fetchChecklists={() => setUpdatedChecklist(!updatedChecklist)}
                  openSuccessSB={openSuccessSB}
                />
              </ArgonBox>
            ))}
          </ArgonBox>
        )}
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}></ArgonBox>
    </Card>
  );
}

export default ServiceChecklists;
