const videoLibrary = {
  createProperty: {
    videoId: "pcTVMbmOR9g",
    thumbnailUrl: "https://i3.ytimg.com/vi/pcTVMbmOR9g/maxresdefault.jpg",
    title: "Create Property",
    description:
      "Join us in this video as we walk you through the initial setup of your property on HostHive. This tutorial is the perfect starting point for new HostHive users, showing you exactly how to input your property's details into our system.",
  },
  addServicePartners: {
    videoId: "Pi2g6_ZQ6AI",
    thumbnailUrl: "https://i3.ytimg.com/vi/Pi2g6_ZQ6AI/maxresdefault.jpg",
    title: "Adding Service Partners",
    description:
      "Welcome to our tutorial on how to seamlessly integrate service providers into your HostHive property management team. In this video, we'll guide you through the simple steps to invite a service provider to join your network, ensuring your property management tasks are handled efficiently.",
  },
  syncCalendar: {
    videoId: "cXV6qFKx7kQ",
    thumbnailUrl: "https://i3.ytimg.com/vi/cXV6qFKx7kQ/maxresdefault.jpg",
    title: "Calendar Integration",
    description:
      "Dive into the functionalities of HostHive with our latest tutorial, where we show you how to bring synergy between your calendar reservations and service schedules. This video is a must-watch for all HostHive users looking to optimize their property management experience.",
  },
  taskManagement: {
    videoId: "l1TSl3ewqV4",
    thumbnailUrl: "https://i3.ytimg.com/vi/l1TSl3ewqV4/maxresdefault.jpg",
    title: "Task Management",
    description:
      "Unlock the full potential of property management with our comprehensive tutorial on creating and managing tasks within HostHive.",
  },
  timeTracking: {
    videoId: "9bPByDCYESc",
    thumbnailUrl: "https://i3.ytimg.com/vi/9bPByDCYESc/maxresdefault.jpg",
    title: "Time Tracking",
    description:
      "Explore the powerful time-tracking feature of HostHive in our latest tutorial, designed for property managers and service providers alike. This feature is ideal for anyone looking to measure efficiency, manage billing, or keep accurate records for tax purposes.",
  },
  getSupport: {
    videoId: "7acYXFOUQYs",
    thumbnailUrl: "https://i3.ytimg.com/vi/7acYXFOUQYs/maxresdefault.jpg",
    title: "Getting Support",
    description: "",
  },
};
//7acYXFOUQYs
export const VideoLibraryHelper = (lookUpKey) => {
  // Look up the service info using the key provided
  return videoLibrary[lookUpKey];
};
