/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";
import { Grid, Icon } from "@mui/material";
import UserItem from "./components/UserItem";
function CompanyUsers() {
  const administrationApi = new AdministrationApi();
  const { companyId } = useParams();
  const [permitted, setPermitted] = useState(false);
  const [fetchUsers, setFetchUsers] = useState(false);
  const attemptReachability = async () => {
    try {
      await administrationApi.getReachability();
      setPermitted(true);
      console.log("Permitted");
      getUsers();
    } catch (error) {
      console.error(error);
    }
  };
  const [users, setUsers] = useState([]);
  const getUsers = async () => {
    try {
      const response = await administrationApi.getUsers(companyId);
      setUsers(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    attemptReachability();
  }, [fetchUsers]);

  return (
    <>
      {permitted && (
        <DashboardLayout>
          <ArgonBox pt={6} pb={3}>
            <Card>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => window.history.back()}
                >
                  Back to to Company
                </ArgonButton>
              </ArgonBox>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Users
                </ArgonTypography>
                {/* <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  A lightweight, extendable, dependency-free javascript HTML
                  table plugin.
                </ArgonTypography> */}
              </ArgonBox>
              <ArgonBox
                sx={{
                  overflow: "auto",
                  maxHeight: "500px",
                  padding: "0 20px",
                  marginBottom: "20px",
                }}
              >
                {users.map((user) => (
                  <UserItem
                    user={user}
                    refetchUsers={() => setFetchUsers(!fetchUsers)}
                  />
                ))}
              </ArgonBox>
            </Card>
          </ArgonBox>
        </DashboardLayout>
      )}
    </>
  );
}

export default CompanyUsers;
