/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";

import { useState, useEffect, useContext } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import Tooltip from "@mui/material/Tooltip"; // @mui material components
import { IconButton } from "@mui/material";
import Icon from "@mui/material/Icon";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import { v4 as uuidv4 } from "uuid";
import ChecklistsApi from "api/checkListsApi";
import PropertyContext from "context/PropertyContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
function ChecklistItemDialog({
  companyId,
  fetchChecklists,
  onClose,
  checklistDetails,
  snackBarOpen,
  setChecklist,
}) {
  const { t } = useTranslation("common");
  const checklistApi = new ChecklistsApi();
  const [isEdit, setIsEdit] = useState(false);
  const [newChecklistSection, setNewChecklistSection] = useState("");
  const [newChecklistItem, setNewChecklistItem] = useState("");
  const [listedItem, setListedItem] = useState("");
  const [checklistName, setChecklistName] = useState("");
  const [checklistItems, setChecklistItems] = useState([]);
  const {
    property,
    setProperty,
    propertySettings,
    setPropertySettings,
    applicationIntegrationTypes,
    propertyServicePartners,
    companyChecklists,
    companyServiceNames,
    setCompanyServiceNames,
    setCompanyChecklists,
  } = useContext(PropertyContext);

  const [totalUnchecked, setTotalUnchecked] = useState(0);
  useEffect(() => {
    console.log("Details", checklistDetails);
    if (checklistDetails) {
      setChecklistItems(checklistDetails?.checklist);
      setChecklistName(checklistDetails?.name);
    }
  }, [checklistDetails]);

  const handleSaveChecklistItem = () => {
    if (listedItem) {
      setChecklistItems((prevItems) => [
        ...prevItems,
        { id: uuidv4(), summary: listedItem },
      ]);
      setListedItem(""); // Clear the input after saving
    }
  };

  const [loadingResponse, setLoadingResponse] = useState(false);

  const toggleLoading = () => setLoadingResponse((prevState) => !prevState);

  //const [checklistItems, setChecklistItems] = useState(checklistData.checklist);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllToggle = (checklistId) => {
    console.log(checklistId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) => ({
                ...item,
                checked: !selectAll,
              })),
            }
          : checklist
      )
    );
    setSelectAll((prev) => !prev);
  };

  const handleIndividualToggle = (checklistId, itemId) => {
    console.log(checklistId, itemId);
    setChecklistItems((prevItems) =>
      prevItems.map((checklist) =>
        checklist.id === checklistId
          ? {
              ...checklist,
              items: checklist.items.map((item) =>
                item.id === itemId ? { ...item, checked: !item.checked } : item
              ),
            }
          : checklist
      )
    );
  };

  const totalCheckedCount = checklistItems.reduce(
    (acc, { items }) => acc + items.filter((item) => item.checked).length,
    0
  );
  const totalItemCount = checklistItems.reduce(
    (acc, { items }) => acc + items.length,
    0
  );

  const handleSave = async () => {
    console.log("Saving checklist", checklistItems);
  };

  return (
    <ArgonBox p={2} lineHeight={1}>
      <ArgonBox mb={2}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox mb={1} ml={0.5} mt={3} lineHeight={0}>
            <ArgonBox>
              <ArgonTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {checklistName}{" "}
              </ArgonTypography>
            </ArgonBox>

            <ArgonBox>
              {totalCheckedCount === totalItemCount ? (
                <ArgonTypography
                  component="label"
                  variant="caption"
                  fontWeight="regular"
                  color="success"
                  sx={{ fontStyle: "italic" }}
                >
                  {t("components.checklist.itemsComplete")}
                </ArgonTypography>
              ) : (
                <>
                  <ArgonTypography
                    component="label"
                    variant="caption"
                    fontWeight="regular"
                    color="warning"
                    sx={{ fontStyle: "italic" }}
                  >
                    ({totalCheckedCount}/{totalItemCount}){" "}
                    {((totalCheckedCount / totalItemCount) * 100).toFixed(2)}%
                    {t("generalWords.complete")}
                  </ArgonTypography>
                </>
              )}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox mb={2}>
        <ArgonBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          height="100%"
        >
          <ArgonBox
            mb={1}
            ml={0.5}
            mt={3}
            lineHeight={0}
            display="inline-block"
          >
            {checklistItems?.map(({ summary, id, items }) => {
              const selectedCount = items.filter((item) => item.checked).length;
              const totalCount = items.length;
              return (
                <ArgonBox display="flex" justifyContent="space-between" p={1}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedCount === totalCount}
                            onChange={() => handleSelectAllToggle(id)}
                          />
                        }
                      />
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                        textTransform="uppercase"
                      >
                        {summary}
                      </ArgonTypography>
                      <ArgonBox pl={2}>
                        <ArgonTypography
                          variant="caption"
                          fontWeight="bold"
                          color="text"
                          textTransform="uppercase"
                        >
                          ({selectedCount}/{totalCount})
                        </ArgonTypography>
                      </ArgonBox>
                    </AccordionSummary>

                    {items?.map((item) => {
                      return (
                        <AccordionDetails key={item.id}>
                          <ArgonBox
                            display="flex"
                            justifyContent="space-between"
                            p={1}
                          >
                            <ArgonBox>
                              <ArgonTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={item.checked}
                                      onChange={() =>
                                        handleIndividualToggle(id, item.id)
                                      }
                                    />
                                  }
                                  label={item.itemName}
                                />
                              </ArgonTypography>
                            </ArgonBox>
                          </ArgonBox>
                        </AccordionDetails>
                      );
                    })}
                  </Accordion>
                </ArgonBox>
              );
            })}
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>

      <ArgonBox
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        height="100%"
      >
        <ArgonBox p={1}>
          <ArgonBox display="flex" justifyContent="flex-end" height="100%">
            <ArgonBox pr={2}>
              <ArgonButton variant="gradient" color="dark" onClick={onClose}>
                {t("buttons.cancel")}
              </ArgonButton>
            </ArgonBox>
            <ArgonBox pr={2}>
              <ArgonButton variant="gradient" color="info" onClick={handleSave}>
                {isEdit ? t("buttons.update") : t("buttons.create")}
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

export default ChecklistItemDialog;
