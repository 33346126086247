/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useNavigate } from "react-router-dom";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import HostHiveDialog from "components/HostHiveDialog";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonBadge from "components/ArgonBadge";
// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import InventoryDataCard from "layouts/management/components/InventoryDataCard";
import MuiLink from "@mui/material/Link";
import PropertyManagementItemDialog from "./PropertyManagementItemDialog";
import SettingsApi from "api/settingsApi";
function PropertyManagementItem({
  assigneeServicePartnerId,
  companyId,
  propertySetting,
  users,
  snackBarOpen,
  fetchPropertySettings,
}) {
  const { borderWidth } = borders;
  const settingsApi = new SettingsApi();
  const [openMenu, setOpenMenu] = useState(null);
  const navigate = useNavigate();
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);

  const handlePropertySetingsUpdate = () => {
    console.log("handleUpdateSubAssignee", propertySetting);
    setEditDialogOpen(true);
  };

  const handleUnassign = async () => {
    console.log("handleUnassign", propertySetting?.id);
    try {
      const response = await settingsApi.unassignSubAssignee(
        propertySetting?.id
      );
      console.log("response", response);
      if (snackBarOpen) {
        snackBarOpen(
          `Assignee cleared successfully`,
          `Successfully cleared property assignee`
        );
      }
      fetchPropertySettings();
    } catch (error) {
      console.log("handleSubmitClicked error", error);
    }
  };

  const renderMenu = (id, propertySetting) => (
    <Menu
      anchorEl={openMenu}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      keepMounted
    >
      <MenuItem>
        {/* <MuiLink
          href={`/service-partners/${id}/settings-overview`}
          rel="noreferrer"
        >
          View
        </MuiLink> */}
      </MenuItem>
      <MenuItem
        onClick={() => {
          handlePropertySetingsUpdate();
          handleCloseMenu();
        }}
      >
        Edit
      </MenuItem>
      {propertySetting?.subAssigneeInfo?.value ? (
        <MenuItem
          onClick={() => {
            handleUnassign();
            handleCloseMenu();
          }}
        >
          Unassign
        </MenuItem>
      ) : (
        <MenuItem
          onClick={() => {
            //handleUnassign();
            setEditDialogOpen(true);
            handleCloseMenu();
          }}
        >
          Assign
        </MenuItem>
      )}
      {/* <MenuItem onClick={handleCloseMenu}>Delete</MenuItem> */}
    </Menu>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: { xs: "flex-start", sm: "center" },
        flexDirection: { xs: "column", sm: "row" },
        mt: 2,
        ml: 3,
        pl: 0.5,
      }}
    >
      <HostHiveDialog
        open={editDialogOpen}
        includeSave={false}
        includeClose={false}
        title="Property Management Settings"
        fullScreen={false}
        maxWidth="xs"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <PropertyManagementItemDialog
            assigneeServicePartnerId={assigneeServicePartnerId}
            companyId={companyId}
            propertyId={propertySetting?.propertyId}
            availableUsers={users}
            currentValue={{
              label: propertySetting?.userId
                ? `${propertySetting?.firstName} ${propertySetting?.lastName}`
                : "Select User",
              value: propertySetting?.userId,
            }}
            handleClose={() => setEditDialogOpen(false)}
            snackBarOpen={snackBarOpen}
            fetchPropertySettings={fetchPropertySettings}
          />
        }
      />
      <div
        component="li"
        style={{
          width: "100%",
          pr: 2,
          mb: 2,
          listStyle: "none",
          maxHeight: "200px",
          overflowY: "scroll",
        }}
      >
        <div style={{ width: "100%", pl: 1, ml: 2 }}>
          <div
            style={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              mt: 2,
              ml: 3,
              pl: 0.5,
              alignItems: { xs: "flex-start", sm: "center" },
              overflow: "hidden",
            }}
          >
            {/* Summary */}
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {propertySetting?.propertyName}
              </ArgonTypography>
            </div>

            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {propertySetting?.firstName} {propertySetting?.lastName}
              </ArgonTypography>
            </div>
            <div
              style={{
                ml: { xs: 0, sm: "auto" },
                mb: { xs: 1, sm: 0 },
                lineHeight: 1,
                flex: 1,
              }}
            >
              <ArgonTypography
                variant="caption"
                fontWeight="bold"
                color="text"
                style={{ wordWrap: "break-word" }}
              >
                {propertySetting?.ownerInfo?.name}
              </ArgonTypography>
            </div>

            {renderMenu(propertySetting?.id, propertySetting)}
            <div style={{ mx: { xs: 0, sm: "auto" }, lineHeight: 1, flex: 1 }}>
              <ArgonTypography
                color="text"
                onClick={handleOpenMenu}
                sx={{ lineHeight: 0, cursor: "pointer" }}
              >
                <Icon fontSize="default">more_vert</Icon>
              </ArgonTypography>
            </div>
          </div>
        </div>
        <Divider style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
}

// Setting default values for the props of Todo
PropertyManagementItem.defaultProps = {
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
PropertyManagementItem.propTypes = {
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  currentCount: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  noDivider: PropTypes.bool,
};

export default PropertyManagementItem;
