// App.js
import React from "react";
import "./App.css";
import { useState, useEffect, useContext } from "react";
import HostHiveLoading from "components/HostHiveLoading";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import BookingReportDataCard from "layouts/management/components/BookingReportDataCard";
import { useParams } from "react-router-dom";
import ArgonBox from "components/ArgonBox";

import InventoryApi from "api/inventoryApi";
import ArgonAlert from "components/ArgonAlert";
import borders from "assets/theme/base/borders";
import ArgonButton from "components/ArgonButton";
import { useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import { convertTimeStamp, timeAgo } from "services/dateUtils";
import MessagesApi from "api/messagesApi";
import {
  Box,
  Card,
  Grid,
  Typography,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  CardContent,
  CardActions,
  Paper,
} from "@mui/material";
import ArgonInput from "components/ArgonInput";
import { set } from "lodash";
import ArgonSelect from "components/ArgonSelect";
import MessageItem from "./components/MessageItem";
function Messages() {
  const { borderWidth, borderColor } = borders;
  const messagesApi = new MessagesApi();
  const [messages, setMessages] = useState({
    messages: [],
    total: 0,
  });

  // const [messages, setMessages] = useState([]);
  const [filters, setFilters] = useState({
    companyId: "",
    status: "",
    sortBy: "",
    sortOrder: "",
    userId: "",
    subject: "",
    message: "",
    skip: 0,
    limit: 10,
  });

  const [subject, setSubject] = useState("");
  const sortByOptions = [
    { value: "created", label: "Created" },
    { value: "subject", label: "Subject" },
  ];
  const sortOrderOptions = [
    { value: "asc", label: "Ascending" },
    { value: "desc", label: "Descending" },
  ];
  const [selectedSortBy, setSelectedSortBy] = useState(sortByOptions[0]);
  const [selectedSortOrder, setSelectedSortOrder] = useState(
    sortOrderOptions[1]
  );

  const fetchMessages = async () => {
    console.log("fetching messages");
    if (subject.length === 0) {
      setSubject("");
    }
    // sortBy: str = None,
    // sortOrder: str = None,
    const queryParams = {
      subject: subject,
      sortBy: selectedSortBy.value,
      sortOrder: selectedSortOrder.value,
    };
    const response = await messagesApi.getMessages(queryParams);
    console.log("response", response.data);

    setMessages({
      messages: response.data.messages,
      total: response.data.total,
    });
    // const queryParams = new URLSearchParams(filters).toString();
    // const response = await fetch(`/api/messages?${queryParams}`);
    // const data = await response.json();
    // setMessages(data.messages);
  };

  useEffect(() => {
    fetchMessages();
  }, [subject, selectedSortBy, selectedSortOrder]);

  function HtmlContent({ html }) {
    return (
      <Paper style={{ padding: "20px" }}>
        <Typography
          variant="body1"
          component="div"
          dangerouslySetInnerHTML={{ __html: html }}
        >
          {/* This children will not be rendered */}
        </Typography>
      </Paper>
    );
  }

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <ArgonBox mt={1} mb={20}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card sx={{ mb: 3, overflow: "visible", boxShadow: 3 }}>
              <ArgonBox mb={3}>
                <ArgonBox
                  sx={{
                    padding: "1rem",
                  }}
                >
                  <ArgonTypography variant="h4" fontWeight="bold" color="text">
                    Messages
                  </ArgonTypography>
                </ArgonBox>

                <Grid container spacing={3}>
                  {/* Filter options */}
                  <Grid item xs={12} lg={4}>
                    <ArgonBox
                      sx={{
                        padding: "1rem",
                      }}
                    >
                      <ArgonInput
                        placeholder="Search by subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                      >
                        Search
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <ArgonBox
                      sx={{
                        padding: "1rem",
                      }}
                    >
                      <ArgonSelect
                        options={sortOrderOptions}
                        onChange={(e) => setSelectedSortOrder(e)}
                      />
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                      >
                        Sort Order
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <ArgonBox
                      sx={{
                        padding: "1rem",
                      }}
                    >
                      <ArgonSelect
                        options={sortByOptions}
                        onChange={(e) => setSelectedSortBy(e)}
                      />
                      <ArgonTypography
                        variant="caption"
                        fontWeight="bold"
                        color="text"
                      >
                        Sort By
                      </ArgonTypography>
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            {messages.messages.map((message) => (
              <MessageItem message={message} />
            ))}
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

function MainContent() {
  return (
    <div className="main-content">
      {/* Conversation */}
      <Message />
      {/* ... Add more Message components as needed */}
    </div>
  );
}

function Message() {
  return (
    <div className="message">
      Sample Conversation Message lasfhvilasfhbvlkasfbhvklasdbhvklasdbvl
      aksdhgvialsdhvblasd
    </div>
  );
}

export default Messages;
