import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArgonButton from "components/ArgonButton";
import ArgonBox from "components/ArgonBox";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArgonBadge from "components/ArgonBadge";
import ArgonTypography from "components/ArgonTypography";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { over } from "lodash";
import Link from "@mui/material/Link";
import HostHiveKbHelp from "components/HostHiveKbHelp";
const HostHiveDialog = ({
  open,
  onClose,
  onSave,
  onDelete,
  saveType = "save",
  title,
  dialogObjects,
  cancelButtonText,
  submitButtonText,
  deleteDisabled = false,
  deleteButtonText = "Delete",
  fullScreen = false,
  fullwidth = true,
  maxWidth = "md",
  includeDelete = false,
  includeClose = true,
  includeSave = true,
  saveDisabled = false,
  customButton = null,
  submitButtonColor = "info",
  showFullScreenArrow = true,
  fullScreenArrorNavigate = "/",
  showStatusBadge = false,
  helpIntegrationLookupKey = null,
  ...rest
}) => {
  // const navigate = useNavigate();

  // const navigateToUrl = (url) => {
  //   navigate(url);
  // };

  const [overrideFullScreen, setOverrideFullScreen] = useState(false);

  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <Dialog
        fullScreen={overrideFullScreen ? true : fullScreen}
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        fullWidth={true}
      >
        <ArgonBox display="flex" justifyContent="space-between">
          <ArgonBox display="flex">
            <DialogTitle>
              <ArgonTypography variant="h4" fontWeight="bold">
                {title}
              </ArgonTypography>
            </DialogTitle>
            {helpIntegrationLookupKey ? (
              <HostHiveKbHelp
                // helpUrl="https://docs.hosthive.io/en/properties/integration-settings"
                size="medium"
                lookupKey={helpIntegrationLookupKey}
              />
            ) : null}
          </ArgonBox>
          <ArgonBox p={2} display="flex">
            <ArgonBox>
              {showStatusBadge ? (
                <Tooltip title="Service status" placement="top" arrow>
                  <ArgonBadge
                    variant="contained"
                    color={rest.statusColor}
                    badgeContent={rest.statusContent}
                  />
                </Tooltip>
              ) : null}
            </ArgonBox>
            <ArgonBox>
              {showFullScreenArrow ? (
                <Tooltip title="Open in full screen" placement="top" arrow>
                  <IconButton
                    onClick={() => setOverrideFullScreen(!overrideFullScreen)}
                  >
                    <Icon sx={{ fontWeight: "bold" }}>
                      {overrideFullScreen ? "close_fullscreen" : "open_in_full"}
                    </Icon>
                  </IconButton>
                </Tooltip>
              ) : null}
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>

        <DialogContent>{dialogObjects}</DialogContent>

        <DialogActions>
          <ArgonBox justifyContent="center" display="flex" p={2}>
            <ArgonBox display="flex">
              <ArgonBox pr={2}>{customButton ? customButton : null}</ArgonBox>
              <ArgonBox pr={2}>
                {includeClose ? (
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={onClose}
                    sx={{
                      ml: "auto",
                      alignSelf: "flex-start",
                      py: 1.25,
                      pr: 1.5,
                      borderRadius: "8px",
                      borderWidth: "1px",
                      transition: "background-color 0.3s, transform 0.2s",
                      ":hover": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    {cancelButtonText}
                  </ArgonButton>
                ) : null}
              </ArgonBox>
              <ArgonBox displayConten="flex-start" pr={2}>
                {includeDelete ? (
                  <ArgonButton
                    variant="gradient"
                    color="error"
                    onClick={onDelete}
                    disabled={deleteDisabled}
                  >
                    {deleteButtonText}
                  </ArgonButton>
                ) : null}
              </ArgonBox>
              <ArgonBox pr={2}>
                {includeSave ? (
                  <ArgonButton
                    variant="gradient"
                    color={submitButtonColor}
                    onClick={onSave}
                    saveType={saveType}
                    disabled={saveDisabled}
                    sx={{
                      ml: "auto",
                      alignSelf: "flex-start",
                      py: 1.25,
                      pr: 1.5,
                      borderRadius: "8px",
                      borderWidth: "1px",
                      transition: "background-color 0.3s, transform 0.2s",
                      ":hover": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                        transform: "scale(1.02)",
                      },
                    }}
                  >
                    {submitButtonText}
                  </ArgonButton>
                ) : null}
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HostHiveDialog;
