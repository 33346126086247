/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Argon Dashboard 2 PRO MUI page layout routes
import pageRoutes from "page.routes";
import ContactSupportApi from "api/contactSupportApi";
// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import { useState, useEffect } from "react";
// Images
import bgImage from "assets/images/pricing-header-bg.jpg";
import ArgonInput from "components/ArgonInput";
import { m } from "framer-motion";
import ArgonButton from "components/ArgonButton";
import { set } from "lodash";

function Header({ tabValue, tabHandler, hideHelp = false }) {
  const [controller] = useArgonController();
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const { darkMode } = controller;
  const contactSupportApi = new ContactSupportApi();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [requestSent, setRequestSent] = useState(false);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleInfoRequestSent = async () => {
    if (!validateEmail(email)) {
      alert("Please enter a valid email address");
      return;
    }

    if (subject.length < 1) {
      alert("Please enter a subject");
      return;
    }

    if (message.length < 1) {
      alert("Please enter a message");
      return;
    }

    try {
      const response = await contactSupportApi.faqSubmission({
        email,
        subject,
        message,
      });
      console.log(response);
      setRequestSent(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (email.length > 0 && subject.length > 0 && message.length > 0) {
      setReadyToSubmit(true);
    } else {
      setReadyToSubmit(false);
    }
  }, [email, subject, message]);
  return (
    <>
      <ArgonBox
        position="relative"
        // height="50vh"
        overflow="hidden"
        pt={12}
        pb={20}
        sx={({
          palette: { gradients },
          functions: { linearGradient, rgba },
        }) => ({
          backgroundImage: `${linearGradient(
            rgba(gradients.info.main, 0.5),
            rgba(gradients.info.state, 0.5)
          )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          width: "100%",
        })}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          {hideHelp ? null : (
            <Grid item xs={10} lg={5}>
              <ArgonBox mt={3} mb={1}>
                <ArgonTypography variant="h3" color="white" fontWeight="bold">
                  Need help?
                </ArgonTypography>
              </ArgonBox>
              <ArgonBox mb={2}>
                <ArgonTypography
                  variant="body2"
                  color="white"
                  fontWeight="regular"
                >
                  Reach out to us today.
                </ArgonTypography>
              </ArgonBox>
              <Grid
                container
                item
                xs={12}
                sm={10}
                md={8}
                lg={7}
                sx={{ mx: "auto" }}
              >
                <ArgonBox
                  width="100%"
                  mt={6}
                  sx={{
                    backgroundColor: darkMode
                      ? "rgba(0,0,0,0.6)"
                      : "rgba(255,255,255,0.6)",
                    borderRadius: 8,
                    padding: 2,
                  }}
                >
                  {!requestSent ? (
                    <>
                      <ArgonInput
                        sx={{
                          backgroundColor: darkMode
                            ? "rgba(0,0,0,0.6)"
                            : "rgba(255,255,255,0.6)",
                          color: darkMode ? "white" : "black",
                          marginBottom: 2,
                        }}
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <ArgonInput
                        sx={{
                          backgroundColor: darkMode
                            ? "rgba(0,0,0,0.6)"
                            : "rgba(255,255,255,0.6)",
                          color: darkMode ? "white" : "black",
                          marginBottom: 2,
                        }}
                        placeholder="Subject"
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <ArgonInput
                        sx={{
                          backgroundColor: darkMode
                            ? "rgba(0,0,0,0.6)"
                            : "rgba(255,255,255,0.6)",
                          color: darkMode ? "white" : "black",
                          marginBottom: 2,
                        }}
                        placeholder="Message"
                        multiline
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <ArgonBox>
                        <ArgonButton
                          sx={{ width: "100%" }}
                          color="info"
                          onClick={handleInfoRequestSent}
                          // disabled={!readyToSubmit}
                        >
                          Send
                        </ArgonButton>
                      </ArgonBox>
                    </>
                  ) : (
                    <ArgonTypography variant="body2" color="white">
                      Your request has been sent.
                    </ArgonTypography>
                  )}
                </ArgonBox>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ArgonBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
