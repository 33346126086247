/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useMemo, useEffect } from "react";

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// NewUser page components

import ArgonSelect from "components/ArgonSelect";
import CategoryDialog from "layouts/management/components/CategoryDialog";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import HostHiveKbHelp from "components/HostHiveKbHelp";
import HostHiveDialog from "components/HostHiveDialog";

import { useTranslation } from "react-i18next";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import { set } from "lodash";
import FormField from "../FormField";
function ContactItem({ contactPayload, setContactPayload }) {
  // const company = JSON.parse(localStorage.getItem("companyDetails"));

  const { t } = useTranslation("common");

  // setNextDisabled(false);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ArgonBox lineHeight={0} pt={2}>
          {/* <ArgonTypography variant="h5" fontWeight="bold">
          File Upload
        </ArgonTypography> */}
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            Enter the contact information for the person or company you would
            like to add to your address book.
          </ArgonTypography>
        </ArgonBox>
      </Grid>
      <ArgonBox lineHeight={0} pt={2} pl={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type="text"
              label="Name"
              value={contactPayload?.name || ""}
              placeholder="eg. HostHive/ Joe Green"
              onChange={(e) => {
                console.log(e.target.value);
                setContactPayload({
                  ...contactPayload,
                  name: e.target.value,
                });
                console.log(contactPayload);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type="text"
              label="Email"
              placeholder="eg. busy-bee@hosthive.io"
              value={contactPayload?.email || ""}
              onChange={(e) => {
                console.log(e.target.value);
                setContactPayload({ ...contactPayload, email: e.target.value });
                console.log(contactPayload);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              type="text"
              label="Phone Number"
              placeholder="eg. (954) 123-4567"
              value={contactPayload?.phone || null}
              onChange={(e) => {
                console.log(e.target.value);
                setContactPayload({ ...contactPayload, phone: e.target.value });
                console.log(contactPayload);
              }}
            />
          </Grid>
        </Grid>
      </ArgonBox>
    </Grid>
  );
}

// typechecking props for EventOptions
ContactItem.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ContactItem;
