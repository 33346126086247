/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useParams, useNavigate, Link } from "react-router-dom";
import { useMemo, useState, useEffect, useContext } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/sign-up/components/CoverLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import SignUpSchema from "schemas/signUpSchema";
import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import HostHiveLoading from "components/HostHiveLoading";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import HostHiveDialog from "components/HostHiveDialog";
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";
import TermsAndConditions from "layouts/authentication/sign-up/components/TermsOfService";
// Images
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-cover.jpg";

function SignUp() {
  const { email } = useParams();

  const onSubmit = async (values, actions) => {
    console.log(values);
  };

  const [termsAndConditionsOpen, setTermsAndConditionsOpen] = useState(false);

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: email || "",
      companyName: "",
      // Add more fields here
    },
    validationSchema: SignUpSchema,
    onSubmit,
  });

  return (
    <CoverLayout
      title={`Welcome ${values.email} !`}
      description="Try for FREE Today! 
For a limited time: Experience HostHive for FREE until year-end!"
      image={bgImage}
      imgPosition="top"
      button={{ color: "dark", variant: "gradient" }}
      hideNavbar={true}
    >
      <Card>
        <HostHiveDialog
          open={termsAndConditionsOpen}
          onClose={() => setTermsAndConditionsOpen(false)}
          title="Terms and Conditions"
          fullScreen={false}
          maxWidth="lg"
          cancelButtonText="Close"
          includeSave={false}
          includeClose={false}
          dialogObjects={
            <Card
              sx={{
                width: "150%",
                overflow: "visible",
                width: "100%",
                height: "100%",
                minWidth: "600px",
                "@media (max-width: 600px)": {
                  minWidth: "auto",
                },
              }}
            >
              {/* <ArgonBox p={2}>
                <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                  Terms and conditions
                </ArgonTypography>
              </ArgonBox> */}

              <ArgonBox p={2}>
                <ArgonTypography variant="h5" sx={{ fontSize: "1.5rem" }}>
                  <TermsAndConditions />
                </ArgonTypography>
              </ArgonBox>

              <ArgonBox pt={1.25} pb={3} px={3} mt={3} lineHeight={1}>
                <ArgonBox>
                  <ArgonBox
                    display="flex"
                    justifyContent="space-between"
                    py={1}
                  >
                    <ArgonBox>
                      <ArgonButton
                        variant="gradient"
                        color="dark"
                        onClick={() => setTermsAndConditionsOpen(false)}
                      >
                        Close
                      </ArgonButton>
                    </ArgonBox>

                    <ArgonBox>
                      <ArgonButton variant="gradient" color="info">
                        Agree
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </Card>
          }
        />
        <ArgonBox pt={2} pb={3} px={3}>
          <form onSubmit={handleSubmit}>
            <ArgonBox component="form" role="form">
              <ArgonBox mb={2}>
                <ArgonInput
                  placeholder="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // Add error handling from Formik state
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                {errors.firstName && touched.firstName && (
                  // <p className="error">{errors.name}</p>
                  <HostHiveErrorMessage message={errors.firstName} />
                )}
              </ArgonBox>
              <ArgonBox mb={2}>
                <ArgonInput
                  placeholder="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // Add error handling from Formik state
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />

                {errors.lastName && touched.lastName && (
                  // <p className="error">{errors.name}</p>
                  <HostHiveErrorMessage message={errors.lastName} />
                )}
              </ArgonBox>
              <ArgonBox mb={2}>
                <ArgonInput
                  placeholder="Company Name"
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // Add error handling from Formik state
                  error={touched.companyName && Boolean(errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                />

                {errors.companyName && touched.companyName && (
                  // <p className="error">{errors.name}</p>
                  <HostHiveErrorMessage message={errors.companyName} />
                )}
              </ArgonBox>
              <ArgonBox mb={2}>
                <ArgonInput
                  placeholder="Email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // Add error handling from Formik state
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />

                {errors.email && touched.email && (
                  // <p className="error">{errors.name}</p>
                  <HostHiveErrorMessage message={errors.email} />
                )}
              </ArgonBox>

              <ArgonBox display="flex" alignItems="center">
                <Checkbox defaultChecked />
                <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ cursor: "pointer", userSelect: "none" }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </ArgonTypography>
                <ArgonButton onClick={() => setTermsAndConditionsOpen(true)}>
                  View Terms and Conditions
                </ArgonButton>
                {/* <ArgonTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  textGradient
                >
                  Terms and Conditions
                </ArgonTypography> */}
              </ArgonBox>
              <ArgonBox mt={4} mb={1}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  fullWidth
                  type="submit"
                >
                  sign up
                </ArgonButton>
              </ArgonBox>
              <ArgonBox mt={2}>
                <ArgonTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  Already have an account?&nbsp;
                  <ArgonTypography
                    component={Link}
                    to="/authentication/login"
                    variant="button"
                    color="dark"
                    fontWeight="bold"
                    textGradient
                  >
                    Sign in
                  </ArgonTypography>
                </ArgonTypography>
              </ArgonBox>
            </ArgonBox>
          </form>
        </ArgonBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUp;
