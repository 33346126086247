/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/properties/new-property/components/FormField";
import ArgonProgress from "components/ArgonProgress";
import { useState, useEffect } from "react";
// @mui material components
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Grid,
  Paper,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import HostHiveImages from "components/HostHiveImages";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import HostHiveDialog from "components/HostHiveDialog";
import { Link, useNavigate } from "react-router-dom";
import UsersSelect from "layouts/management/components/UsersSelect";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonEditor from "components/ArgonEditor";
import ArgonDropzone from "components/ArgonDropzone";
import ArgonButton from "components/ArgonButton";
import HostHiveCommentSection from "components/HostHiveCommentSection";
import Header from "layouts/administration/taskCenter/components/TaskItem/components/Header";
import { intervalDisplayValue } from "services/dateUtils";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import HostHiveLoading from "components/HostHiveLoading";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonBadge from "components/ArgonBadge";
import { useTranslation } from "react-i18next";
import ReadOnlyPropertyDetails from "layouts/management/properties/properties-list/components/read-only-property-details";
import TaskItemDialog from "layouts/administration/taskCenter/components/TaskItemDialog";
import { UserAuth } from "context/AuthContext";
import HostHiveSnackbar from "components/HostHiveSnackbar";
import TasksApi from "api/propertyTasksApi";

function TaskItem({ taskItem, onClose, fetchTaskItems }) {
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const tasksApi = new TasksApi();
  const [updatedAssignee, setUpdatedAssignee] = useState(
    taskItem?.subAssigneeInfo
  );

  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const [taskItemImages, setTaskItemImages] = useState(taskItem?.images || []);

  const [startDate, setStartDate] = useState(dayjs(taskItem?.dueDate));
  const [hasDueDate, setHasDueDate] = useState(
    taskItem?.dueDate ? true : false
  );
  const [hasAssigneeSubId, setHasAssigneeSubId] = useState(
    taskItem?.subAssigneeId ? true : false
  );
  const [subAssigneeName, setSubAssigneeName] = useState(
    taskItem?.subAssigneeInfo?.label
  );
  const [showEditAssignee, setShowEditAssignee] = useState(false);
  const navigate = useNavigate();
  const { role, hasRole, user, hasOneOfRoles } = UserAuth();
  const [savingAssignment, setSavingAssignment] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading...");
  const { t } = useTranslation("common");
  const [showPropertyDataCard, setShowPropertyDataCard] = useState(false);
  const [taskItemDiaglogOpen, setTaskItemDiaglogOpen] = useState(false);
  const [savingUser, setSavingUser] = useState(false);
  const [savingDueDate, setSavingDueDate] = useState(false);
  const [showEditDueDate, setShowEditDueDate] = useState(false);
  const [newDueDate, setNewDueDate] = useState(null);
  const [recurrenceConfigurationOpen, setRecurrenceConfigurationOpen] =
    useState(false);
  const [recurrenceData, setRecurrenceData] = useState(
    taskItem?.recurrenceData || null
  );
  const [photoUploadInProgress, setPhotoUploadInProgress] = useState(false);
  const [photoProgress, setPhotoProgress] = useState(0);
  const updateAssignee = async () => {
    setSavingUser(true);
    let userValue;
    if (updatedAssignee === null) {
      userValue = "none";
      setSubAssigneeName(null);
    } else {
      userValue = updatedAssignee?.value;
      setSubAssigneeName(updatedAssignee?.label);
      setHasAssigneeSubId(true);
    }
    try {
      const response = await tasksApi.updateTaskSubAssigneeId(
        taskItem?.id,
        userValue
      );
    } catch (error) {
      console.error(error);
    }
    setSavingUser(false);
    setShowEditAssignee(false);
  };

  const handleDateChange = async () => {
    // Parse the date with dayjs when updating state
    let newDate;
    if (newDueDate === null) {
      newDate = "none";
      setHasDueDate(false);
      setStartDate(null);
    } else {
      setSavingDueDate(true);
      newDate = dayjs(newDueDate).format("YYYY-MM-DD");
      setStartDate(dayjs(newDueDate));
      setHasDueDate(true);
    }
    console.log("The New Date: ", newDate);
    try {
      const response = await tasksApi.updateTaskDueDate(taskItem?.id, newDate);
      console.log(response);
    } catch (error) {
      console.error(error);
    }

    setSavingDueDate(false);
    setShowEditDueDate(false);
  };

  const handleClearDueDate = async () => {
    // Parse the date with dayjs when updating state
    setNewDueDate(null);
    setHasDueDate(false);
    setStartDate(null);
    // try {
    //   const response = await tasksApi.updateTaskDueDate(taskItem?.id, "none");
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }

    // setShowEditDueDate(false);
  };

  const handleClearAssignedUser = async () => {
    // Parse the date with dayjs when updating state
    setUpdatedAssignee(null);
    setHasAssigneeSubId(false);
    setSubAssigneeName(null);
    // try {
    //   const response = await tasksApi.updateTaskDueDate(taskItem?.id, "none");
    //   console.log(response);
    // } catch (error) {
    //   console.error(error);
    // }

    // setShowEditDueDate(false);
  };

  const uploadHandler = async (event) => {
    setPhotoUploadInProgress(true);

    const uploadedImages = event.target.files;
    const uploadedImagesArray = Array.from(uploadedImages);
    const imagesArray = [];
    const photoLength = uploadedImagesArray.length;

    for (let i = 0; i < uploadedImagesArray.length; i++) {
      setPhotoProgress((i / photoLength) * 100);
      const image = uploadedImagesArray[i];
      const fileType = image.type;
      const fileName = image.name;
      console.log("image", image);
      const formData = new FormData();
      formData.append("file", image);
      try {
        const response = await tasksApi.updateTaskImages(
          taskItem?.id,
          taskItem?.companyId,
          taskItem?.propertyId,
          fileName,
          fileType,
          taskItem?.summary,
          formData
        );

        imagesArray.push(response.data.images); // Add the uploaded image to the imagesArray
        setTaskItemImages(response.data.images);
        openSuccessSB(
          `Image uploaded successfully`,
          `Successfully uploaded image`
        );
      } catch (error) {
        console.error(error);
      }
    }

    setPhotoUploadInProgress(false);
    setPhotoProgress(0);

    // Update the bookingImages state with the uploaded images
  };

  const handleImageDelete = async (index, fileName) => {
    console.log("handleImageDelete", fileName);
    try {
      const response = await tasksApi.deleteTaskImage(
        taskItem?.id,
        taskItem?.companyId,
        taskItem?.propertyId,
        fileName
      );

      // Update the bookingImages state by removing the image at the specified index
      setTaskItemImages((prevImages) => {
        const newImages = [...prevImages];
        newImages.splice(index, 1);
        return newImages;
      });
      openSuccessSB(`Image deleted successfully`, `Successfully deleted image`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header
        title={taskItem?.summary}
        subtitle={`#${taskItem?.ticketNumber}`}
        taskItem={taskItem}
        setShowEditDueDate={setShowEditDueDate}
        setShowEditAssignee={setShowEditAssignee}
        hasDueDate={hasDueDate}
        hasAssigneeSubId={hasAssigneeSubId}
        setTaskItemDiaglogOpen={setTaskItemDiaglogOpen}
      />
      <ArgonBox pb={2}></ArgonBox>
      <Card>
        {!savingAssignment ? (
          <>
            <ArgonBox pl={2} pb={3}>
              <HostHiveDialog
                open={recurrenceConfigurationOpen}
                fullWidth={true}
                includeSave={false}
                includeClose={false}
                maxWidth="sm"
                // onClose={handleCancelClicked}
                // onSave={handleSubmitClicked}
                title="Recurrence Configuration"
                fullScreen={false}
                // cancelButtonText="Cancel"
                // submitButtonText="Save"
                showStatusBadge={false}
                statusColor="success"
                statusContent="Confirmed"
                dialogObjects={
                  <RecurrenceDialog
                    onClose={() => setRecurrenceConfigurationOpen(false)}
                    setRecurrenceData={setRecurrenceData}
                    recurrenceData={recurrenceData}
                  />
                }
              />
              <HostHiveDialog
                includeDelete={false}
                includeSave={false}
                includeClose={false}
                open={taskItemDiaglogOpen}
                onClose={() => {
                  setTaskItemDiaglogOpen(false);
                }}
                title="Edit Task"
                fullScreen={false}
                maxWidth="xl"
                cancelButtonText="Close"
                submitButtonText="Save"
                dialogObjects={
                  <>
                    {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
                    <TaskItemDialog
                      taskItem={taskItem}
                      propertyId={taskItem?.propertyId}
                      edit={true}
                      onClose={() => {
                        setTaskItemDiaglogOpen(false);
                        fetchTaskItems();
                      }}
                    />
                  </>
                }
              />
              <HostHiveDialog
                open={showEditDueDate}
                fullWidth={true}
                includeDelete={false}
                includeSave={true}
                includeClose={true}
                maxWidth="sm"
                onClose={() => setShowEditDueDate(false)}
                onSave={handleDateChange}
                saveDisabled={savingDueDate}
                title="Change Due Date"
                fullScreen={false}
                cancelButtonText="Cancel"
                submitButtonText={savingDueDate ? "Saving..." : "Save"}
                deleteButtonText="Remove Due Date"
                showStatusBadge={false}
                statusColor="success"
                statusContent="Confirmed"
                dialogObjects={
                  <Card>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("serviceAssignments.eventDataCard.serviceDate")}
                        </ArgonTypography>
                      </ArgonBox>

                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={(newValue) =>
                                setNewDueDate(dayjs(newValue))
                              }
                              value={startDate}
                              orientation="landscape"
                            />
                          </LocalizationProvider>
                        </Grid>
                        {hasDueDate && !savingDueDate ? (
                          <Grid item xs={12} sm={6}>
                            <ArgonButton
                              variant="gradient"
                              color="error"
                              onClick={handleClearDueDate}
                            >
                              Remove Due Date
                            </ArgonButton>
                          </Grid>
                        ) : null}
                      </Grid>
                    </ArgonBox>
                  </Card>
                }
              />
              <HostHiveDialog
                open={showEditAssignee}
                fullWidth={true}
                includeSave={true}
                includeClose={true}
                maxWidth="xs"
                onClose={() => setShowEditAssignee(false)}
                onSave={updateAssignee}
                saveDisabled={savingUser}
                title="Update Assigneed User"
                fullScreen={false}
                cancelButtonText="Cancel"
                submitButtonText={savingUser ? "Saving..." : "Save"}
                showStatusBadge={true}
                statusColor="success"
                statusContent="Confirmed"
                dialogObjects={
                  <Card>
                    <ArgonBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                      pb={10}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <UsersSelect
                            setUser={setUpdatedAssignee}
                            user={updatedAssignee}
                            companyId={company?.id}
                          />
                        </Grid>
                        {hasAssigneeSubId && !savingUser ? (
                          <Grid item xs={12}>
                            <ArgonBox
                              display="flex"
                              flexDirection="column"
                              justifyContent="flex-end"
                              height="100%"
                              pb={2}
                            >
                              <ArgonBox
                                mb={1}
                                ml={0.5}
                                mt={3}
                                lineHeight={0}
                                display="inline-block"
                              >
                                <ArgonTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                ></ArgonTypography>
                              </ArgonBox>
                              <ArgonButton
                                variant="gradient"
                                color="error"
                                onClick={handleClearAssignedUser}
                              >
                                Remove Assignee
                              </ArgonButton>
                            </ArgonBox>
                          </Grid>
                        ) : null}
                      </Grid>
                    </ArgonBox>
                  </Card>
                }
              />
              <HostHiveDialog
                includeDelete={false}
                includeSave={false}
                open={showPropertyDataCard}
                onClose={() => {
                  setShowPropertyDataCard(false);
                }}
                title="Property Details"
                fullScreen={false}
                maxWidth="xl"
                cancelButtonText="Close"
                submitButtonText="Save"
                dialogObjects={
                  <>
                    {/* <Card>
                <ArgonBox p={2}>
                  <ArgonBox>Test</ArgonBox>
                </ArgonBox>
              </Card> */}
                    <ReadOnlyPropertyDetails
                      property={taskItem?.propertyInfo}
                    />
                  </>
                }
              />
              <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Task ID:
                    </ArgonTypography>

                    <ArgonTypography
                      component="label"
                      variant="caption"
                      textTransform="capitalize"
                    >
                      {" "}
                      #{taskItem?.ticketNumber}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid> */}
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Summary:
                    </ArgonTypography>

                    <ArgonTypography variant="body1" color="textSecondary">
                      {" "}
                      {taskItem?.summary}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      description:
                    </ArgonTypography>

                    <ArgonTypography variant="body1" color="textSecondary">
                      {" "}
                      {taskItem?.description}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  <ArgonTypography variant="h6" fontWeight="bold">
                    Due Date:
                  </ArgonTypography>
                  <ArgonTypography variant="body1" color="textSecondary">
                    {hasDueDate ? startDate.format("YYYY-MM-DD") : "None"}
                  </ArgonTypography>
                  <ArgonBox pl={2}>
                    <ArgonTypography
                      variant="caption"
                      textTransform="capitalize"
                      sx={{
                        ":hover": {
                          textDecoration: "underline",
                          color: "primary.main",
                        },
                        cursor: "pointer",
                        fontStyle: "italic",
                      }}
                      onClick={() => setShowEditDueDate(true)}
                    >
                      {hasDueDate ? "Change" : "Add"} Due Date
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="h6" fontWeight="bold">
                    {t("serviceAssignments.eventDataCard.assignee")}:
                  </ArgonTypography>
                  <ArgonTypography variant="body1" color="textSecondary">
                    {subAssigneeName ? subAssigneeName : "None"}
                  </ArgonTypography>
                  {hasOneOfRoles(["admin", "supervisor", "manager"]) && (
                    <ArgonBox pl={2}>
                      <ArgonTypography
                        variant="caption"
                        textTransform="capitalize"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                            color: "primary.main",
                          },
                          cursor: "pointer",
                          fontStyle: "italic",
                        }}
                        onClick={() => setShowEditAssignee(true)}
                      >
                        {/* {t("serviceAssignments.buttons.editAssignee")} */}
                        {hasAssigneeSubId
                          ? t("serviceAssignments.buttons.editAssignee")
                          : "Add Assignee"}
                      </ArgonTypography>
                    </ArgonBox>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Property:
                    </ArgonTypography>
                    {/* <ArgonTypography variant="body1" color="textSecondary"></ArgonTypography> */}
                    <ArgonTypography
                      variant="body1"
                      color="textSecondary"
                      textTransform="capitalize"
                      sx={{
                        ":hover": { textDecoration: "underline" },
                        cursor: "pointer",
                      }}
                      onClick={() => setShowPropertyDataCard(true)}
                    >
                      {" "}
                      {taskItem?.propertyInfo?.name}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                {recurrenceData?.recurrenceIntervalValue ? (
                  <Grid item xs={12}>
                    <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                      <ArgonTypography
                        variant="h6"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Repeats:
                      </ArgonTypography>

                      <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          variant="body1"
                          color="textSecondary"
                          textTransform="capitalize"
                        >
                          {t("serviceAssignments.eventDataCard.every")}{" "}
                          {recurrenceData?.recurrenceIntervalValue}{" "}
                          {intervalDisplayValue(
                            recurrenceData?.recurrenceInterval,
                            recurrenceData?.recurrenceIntervalValue
                          )}{" "}
                        </ArgonTypography>
                        {/* <ArgonTypography
                        pl={3}
                        variant="caption"
                        textTransform="capitalize"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => setRecurrenceConfigurationOpen(true)}
                      >
                        {t("generalWords.edit")}
                      </ArgonTypography> */}
                      </ArgonBox>
                    </ArgonBox>
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Task Photos:
                    </ArgonTypography>
                    {/* <ArgonTypography variant="body1" color="textSecondary"></ArgonTypography> */}
                  </ArgonBox>
                </Grid>

                <Grid item xs={12} lg={6} xl={5}>
                  <ArgonBox
                    component="input"
                    name="file"
                    type="file"
                    multiple
                    onChange={uploadHandler}
                    mb={2} // Add some margin-bottom for better spacing
                  />

                  {photoUploadInProgress && (
                    <ArgonProgress value={photoProgress} color="success" />
                  )}

                  <ArgonBox pt={2.5} px={2}>
                    {" "}
                    <HostHiveImages
                      images={taskItemImages}
                      handleDeleteImage={handleImageDelete}
                      showNoImagesMessage={true}
                      imageGridSizeXs={2.4}
                      imageGridSizeMd={2.4}
                    />
                  </ArgonBox>
                </Grid>

                <Grid item xs={12}>
                  <ArgonBox ml={0.5} lineHeight={0} display="inline-block">
                    <ArgonTypography
                      variant="h6"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Comments:
                    </ArgonTypography>
                  </ArgonBox>
                  <HostHiveCommentSection
                    title=""
                    entityType="propertyTasks"
                    entityId={taskItem?.id}
                    disabled={taskItem?.status === "closed"}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
            <ArgonBox pl={2} pb={3}></ArgonBox>

            <Grid container spacing={3} pb={3}>
              <Grid item xs={6}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    size="small"
                    onClick={() => navigate(-1)}
                  >
                    Return
                  </ArgonButton>
                </ArgonBox>
              </Grid>
              <Grid item xs={6}>
                <ArgonBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <ArgonButton
                    variant="gradient"
                    color="info"
                    size="small"
                    onClick={() => setTaskItemDiaglogOpen(true)}
                    disabled={taskItem?.status === "closed"}
                  >
                    Edit
                  </ArgonButton>
                </ArgonBox>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <ArgonTypography variant="h5" fontWeight="medium" sx={{ my: 2 }}>
              {loadingText}
            </ArgonTypography>
            <HostHiveLoading />
          </>
        )}
      </Card>
    </>
  );
}

export default TaskItem;
