/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState, useEffect, useContext } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonButton from "components/ArgonButton";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonDrawer from "components/ArgonDrawer";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import PropertyInfoCard from "layouts/management/properties/components/PropertyInfoCard";
import PropertyGroupSettingsCard from "layouts/management/properties/components/PropertyGroupSettingsCard";
import TodoList from "layouts/management/properties/components/TodoList";

import PropertyContext from "context/PropertyContext";
// Overview page components
import Header from "layouts/management/properties/property-overview/components/Header";

import Transactions from "layouts/management/components/Transactions";
import ApplicationApi from "api/applicationApi";
import HostHiveDialog from "components/HostHiveDialog";

import PropertiesApi from "api/propertiesApi";
import { useParams, useNavigate } from "react-router-dom";
import Calendar from "layouts/management/calendar";
import HostHiveLoading from "components/HostHiveLoading";

import ServiceGaps from "./components/ServiceGaps";

import MiniGradientLineChart from "examples/Charts/LineCharts/MiniGradientLineChart";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import BackgroundBlogCard from "examples/Cards/BlogCards/BackgroundBlogCard";
import CategoriesList from "examples/Lists/CategoriesList";
import MessageCard from "examples/Cards/MessageCard";
import RankingsList from "examples/Lists/RankingsList";
import BookingReportList from "examples/Lists/BookingReports";
import TaskTimeLine from "layouts/administration/taskCenter/components/TaskTimeLineDataCard";
// Data
import miniGradientLineChartData from "layouts/dashboards/crm/data/miniGradientLineChartData";
import categoriesListData from "layouts/dashboards/crm/data/categoriesListData";
import rankingsListData from "layouts/dashboards/crm/data/rankingsListData";
import calendarEventsData from "layouts/dashboards/crm/data/calendarEventsData";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
// Images
import ivancik from "assets/images/ivancik.jpg";
import kalVisualsSquare from "assets/images/kal-visuals-square.jpg";
import PropertySpendTransactions from "layouts/administration/accounting/overview/components/PropertySpend/components/PropertySpendTransactionsDataCard";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
} from "services/dateUtils";
import updateCalendarEvents from "services/calendarUtils";
import TasksCenter from "layouts/administration/taskCenter/TaskCenter";
import { useTranslation } from "react-i18next";
import AccountingApi from "api/accountingApi";
import GroupsApi from "api/groupsApi";
import TasksApi from "api/propertyTasksApi";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function Overview() {
  const { t } = useTranslation("common");
  const groupsApi = GroupsApi();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  // const isAuthorized = isRouteAuthorized(
  //   "calendarPlus",
  //   role,
  //   subscriptionTier
  // );

  // const {
  //   property,
  //   setProperty,

  //   // setPropertyTransactions,
  //   // propertyTransactions,
  // } = useContext(PropertyContext);
  const [property, setProperty] = useState(null);
  const [propertyTransactions, setPropertyTransactions] = useState({
    amount: 0,
  });
  const applicationApi = ApplicationApi();
  const accountingApi = AccountingApi();
  const propertiesApi = PropertiesApi();
  const tasksApi = TasksApi();
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const { id } = useParams();

  const [propertyTasks, setPropertyTasks] = useState(null);

  const [externalFetch, setExternalFetch] = useState(false);
  const [propertyStatus, setPropertyStatus] = useState(null);
  const [propertyGroupSettings, setPropertyGroupSettings] = useState([]);
  const [hasPlusMembership, setHasPlusMembership] = useState(false);
  useEffect(() => {
    if (!id) return;

    if (
      subscriptionTier === null ||
      subscriptionTier === undefined ||
      subscriptionTier === "free"
    ) {
      console.log("No plus membership");
      setHasPlusMembership(false);
    } else {
      console.log("Subscription Tier", subscriptionTier);
      setHasPlusMembership(true);
    }

    const loadData = async () => {
      try {
        console.log("Load Data");
        const propertyResponse = await propertiesApi.getProperty(id);
        setProperty(propertyResponse.data);
        setPropertyStatus(propertyResponse.data.status);
      } catch (error) {
        // setError(error);
        // console.log("Got error!", error);
        navigate("/properties");
      }
    };

    async function fetchPropertyNet() {
      try {
        let params = {
          propertyId: id,
        };
        const response = await accountingApi.getPropertiesNet(params);
        if (response.status >= 200 && response.status < 300) {
          console.log("transaction response.data", response.data);
          setPropertyTransactions(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchTasks() {
      try {
        let params = {
          propertyId: id,
        };
        const response = await tasksApi.getTasks(params);
        if (response.status >= 200 && response.status < 300) {
          console.log("tasks response.data", response.data);
          setPropertyTasks(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchGroupSettings() {
      try {
        const response = await groupsApi.getPropertyGroupSettings(id);
        setPropertyGroupSettings(response.data);
        console.log("fetchGroupSettings", response);
      } catch (error) {
        console.log(error);
      }
    }

    loadData();
    fetchPropertyNet();
    fetchTasks();
    fetchGroupSettings();
  }, [id, start_date]);

  const [propertyTransactionsOpen, setPropertyTransactionsOpen] =
    useState(false);
  const [propertyTasksOpen, setPropertyTasksOpen] = useState(false);
  const GroupSettingsBox = () => {
    return (
      <Grid item xs={12} sm={6}>
        <Card>
          <ArgonBox
            p={3}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {/* <ArgonTypography
                        variant="body2"
                        color="text"
                        fontWeight="regular"
                      >
                        Expenses
                      </ArgonTypography> */}
            <ArgonBox
              mt={2}
              mb={1}
              lineHeight={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Card>
                <ArgonBox p={3} textAlign="center" lineHeight={1.25}>
                  {propertyGroupSettings?.length > 0 ? (
                    <Grid container spacing={3}>
                      <>
                        {propertyGroupSettings?.map((settings, key) => (
                          // <UpcomingAssignmentItem
                          //   booking={assignment}
                          //   handleViewClicked={() => handleViewClicked(assignment?.id)}
                          // />

                          <>
                            <Grid container spacing={3}></Grid>
                            <Grid item xs={6}>
                              <ArgonTypography
                                variant="h6"
                                color="text"
                                fontWeight="regular"
                              >
                                {settings?.key}
                              </ArgonTypography>
                            </Grid>
                            <Grid item xs={6}>
                              <ArgonTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                {settings?.value}
                              </ArgonTypography>
                            </Grid>
                          </>
                        ))}
                      </>
                    </Grid>
                  ) : null}
                </ArgonBox>
              </Card>
            </ArgonBox>
          </ArgonBox>
        </Card>
      </Grid>
    );
  };
  return (
    <DashboardLayout>
      {/* {/* <DashboardNavbar /> */}
      <Header
        title={property?.name}
        subtitle="Jim Bob"
        property={property}
        setProperty={setProperty}
        propertyStatus={propertyStatus}
        setPropertyStatus={setPropertyStatus}
      />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <HostHiveDialog
            open={propertyTasksOpen}
            onClose={() => setPropertyTasksOpen(false)}
            title={`${t("properties.property")} ${t("components.tasks.title")}`}
            //fullScreen={false}
            maxWidth="lg"
            cancelButtonText={t("buttons.close")}
            includeSave={false}
            showFullScreenArrow={true}
            dialogObjects={
              <TasksCenter companyId={property?.companyId} propertyId={id} />
            }
          />
          <HostHiveDialog
            open={propertyTransactionsOpen}
            fullWidth={true}
            includeClose={true}
            includeSave={false}
            maxWidth="lg"
            onClose={() => setPropertyTransactionsOpen(false)}
            // onSave={handleSubmitClicked}
            title={t("generalWords.view")}
            fullScreen={false}
            cancelButtonText={t("buttons.close")}
            submitButtonText={t("buttons.save")}
            dialogObjects={
              <PropertySpendTransactions
                onClose={() => setPropertyTransactionsOpen(false)}
                hideClose={true}
                propertyInfo={{
                  propertyName: property?.name,
                  propertyId: id,
                }}
              />
            }
          />
          {propertyStatus === "active" ? (
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7} xl={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <ArgonBox
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        {/* <ArgonTypography
                        variant="body2"
                        color="text"
                        fontWeight="regular"
                      >
                        Urgent Tasks
                      </ArgonTypography> */}
                        <ArgonBox
                          mt={2}
                          mb={1}
                          lineHeight={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DefaultCounterCard
                            count={propertyTasks?.highPriorityTotal}
                            prefix=""
                            suffix=""
                            title={`Open ${t("components.tasks.title")}`}
                            description=""
                          />
                        </ArgonBox>
                        <ArgonButton
                          color="info"
                          variant="outlined"
                          onClick={() =>
                            // navigate(`/tasks/${companyId}/${property.id}`)
                            setPropertyTasksOpen(true)
                          }
                        >
                          {t("buttons.view")} {t("components.tasks.title")}
                        </ArgonButton>
                      </ArgonBox>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Card>
                      <ArgonBox
                        p={3}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        {/* <ArgonTypography
                        variant="body2"
                        color="text"
                        fontWeight="regular"
                      >
                        Expenses
                      </ArgonTypography> */}
                        <ArgonBox
                          mt={2}
                          mb={1}
                          lineHeight={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <DefaultCounterCard
                            count={`${propertyTransactions?.amount}`}
                            suffix=""
                            prefix="$"
                            title={`${t("accounting.general.propertyNet")}`}
                            description=""
                          />
                        </ArgonBox>
                        <ArgonButton
                          color="info"
                          variant="outlined"
                          onClick={() => setPropertyTransactionsOpen(true)}
                        >
                          {t("buttons.view")}{" "}
                          {t("accounting.transactions.title")}
                        </ArgonButton>
                      </ArgonBox>
                    </Card>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ArgonBox mt={3}>
                    {id && propertyStatus === "active" ? (
                      <Calendar
                        header={{
                          title: `${monthName}`,
                          date: `${dayOfWeek}, ${year}`,
                        }}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek",
                        }}
                        initialView="dayGridMonth"
                        initialDate={formattedDate}
                        propertyId={id}
                        // events={updateCalendarEvents(propertyBookings, companyServiceNames)}
                        // bookings={propertyBookings}
                        fixedWeekCount={false}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        externalFetch={externalFetch}
                        disableViewPropertyDetails={true}
                        // eventClick={(events) => handleBookingClicked(events)}
                        //dayMaxEvents={true}
                      />
                    ) : null}
                  </ArgonBox>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={5} xl={4}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ArgonBox overflow="auto" maxHeight="100vh">
                      <PropertyInfoCard
                        propertyId={id}
                        companyId={property?.companyId}
                        triggerExternalCalendarFetch={() =>
                          setExternalFetch(!externalFetch)
                        }
                        hasPlusMembership={hasPlusMembership}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12}>
                    <ArgonBox overflow="auto" maxHeight="100vh">
                      <PropertyGroupSettingsCard
                        propertyId={id}
                        companyId={property?.companyId}
                        triggerExternalCalendarFetch={() =>
                          setExternalFetch(!externalFetch)
                        }
                        propertyGroupSettings={propertyGroupSettings}
                      />
                    </ArgonBox>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={12}>
                    {propertyStatus === "active" ? (
                      <ServiceGaps
                        propertyDetails={property}
                        propertyId={id}
                        setExternalFetch={() =>
                          setExternalFetch(!externalFetch)
                        }
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={12}></Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </ArgonBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            {/* <BookingReportList
              title="Service Reports"
              date="23 - 30 March 2021"
              rankings={transactionsData}
            /> */}
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <RankingsList
              title="transactions"
              date="01 - 07 June 2021"
              rankings={revenueData}
            />
          </Grid> */}
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
