/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import ArgonButton from "components/ArgonButton";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import HostHiveDialog from "components/HostHiveDialog";
import CardActionArea from "@mui/material/CardActionArea";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ChangeRequestsApi from "api/changeRequestsApi";

import Calendar from "layouts/management/calendar";
// Calendar application components
import Header from "layouts/management/schedulePage/components/Header";
import NextEvents from "layouts/management/schedulePage/components/NextEvents";
import ProductivityChart from "layouts/management/schedulePage/components/ProductivityChart";
import HostHiveLoading from "components/HostHiveLoading";
import BookingsApi from "api/bookingsApi";
import { useParams } from "react-router-dom";
// Data
import ConfirmationLists from "layouts/management/schedulePage/components/ConfirmationLists";
import AssignmentLIsts from "layouts/management/schedulePage/components/AssignmentLists";
import ChangeRequests from "layouts/management/schedulePage/components/ChangeRequests";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Divider from "@mui/material/Divider";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
} from "services/dateUtils";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
function SchedulePage({ createAppointment = false }) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();
  const changeRequestsApi = ChangeRequestsApi();
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [showOpenChangeRequests, setShowOpenChangeRequests] = useState(false);
  const [changeRequestsOpen, setChangeRequestsOpen] = useState(false);
  const [externalFetch, setExternalFetch] = useState(false);
  const [externalFetchCounts, setExternalFetchCounts] = useState(false);
  const [unassignedBookings, setUnassignedBookings] = useState([]);
  const [unassignedSubBookings, setUnassignedSubBookings] = useState([]);
  const [isLoadingBooking, setIsLoadingBooking] = useState(false);
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [loadingBookingDetail, setLoadingBookingDetail] = useState(false);
  const { appointmentId } = useParams();
  const [openChangeRequestCounts, setOpenChangeRequestCounts] = useState(0);
  const [
    changeRequestPendingApprovalCounts,
    setChangeRequestPendingApprovalCounts,
  ] = useState(0);
  const [newAppointmentCounts, setNewAppointmentCounts] = useState(0);
  const [unassignedCounts, setUnassignedCounts] = useState(0);
  const [unassignedSubCounts, setUnassignedSubCounts] = useState(0);
  const [confirmationBookings, setConfirmationBookings] = useState([]);

  const [openRequests, setOpenRequests] = useState([]);
  const [openRequestsData, setOpenRequestsData] = useState(null);

  const [changeRequestsData, setChangeRequestsData] = useState();
  const [changeRequests, setChangeRequests] = useState([]);

  useEffect(() => {
    const fetchChangeRequestsToApprove = async () => {
      try {
        const queryParams = {
          entityType: "booking",
        };
        const response = await changeRequestsApi.getChangeRequests(queryParams);
        console.log("changeRequestsApi response", response);
        setChangeRequestsData(response.data);
        setChangeRequests(response.data.items);
        setChangeRequestPendingApprovalCounts(response.data.count);
        // if length of response.data is < 1, then console.log("No bookings to confirm")
      } catch (error) {
        console.error(error);
      }
    };

    const fetchOpenChangeRequests = async () => {
      try {
        const queryParams = {
          entityType: "booking",
          openRequests: true,
        };
        const response = await changeRequestsApi.getChangeRequests(queryParams);
        console.log("open changeRequestsApi response", response);
        setOpenRequestsData(response.data);
        setOpenRequests(response.data.items);
        setOpenChangeRequestCounts(response.data.count);
        // if length of response.data is < 1, then console.log("No bookings to confirm")
        // if (response.data.count < 1) {
        //   console.log("No change requests to confirm");
        //   setShowAssignmentsUnassigned(false);
        // } else {
        //   setShowAssignmentsUnassigned(true);
        // }
      } catch (error) {
        console.error(error);
      }
    };
    fetchChangeRequestsToApprove();
    fetchOpenChangeRequests();
    setExternalFetch(!externalFetch);
  }, [externalFetchCounts]);

  useEffect(() => {
    const appointmentIdCard = async () => {
      setIsLoadingBooking(true);
    };
    if (appointmentId) {
      appointmentIdCard();
    }
  }, []);

  useEffect(() => {
    const fetchConfirmationBookings = async () => {
      try {
        const queryParams = {
          status: "scheduled",
          assigneeId: company?.id,
        };
        const response = await bookingsApi.getBookings(queryParams);
        console.log("response", response);
        if (response.data.length < 1) {
          console.log("No bookings to confirm");
        }
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUnassignedBookings = async () => {
      try {
        const queryParams = {
          assigneeUnassigned: true,
        };
        const response = await bookingsApi.getBookings(queryParams);
        console.log("response", response);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUnassignedSubBookings = async () => {
      try {
        const queryParams = {
          assigneeSubUnassigned: true,
          assigneeId: company?.id,
          status: "confirmed",
        };
        const response = await bookingsApi.getBookings(queryParams);
        console.log("response", response);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData = async () => {
      const [
        confirmationBookingsData,
        unassignedBookingsData,
        unassignedSubBookingsData,
      ] = await Promise.all([
        fetchConfirmationBookings(),
        fetchUnassignedBookings(),
        fetchUnassignedSubBookings(),
      ]);

      if (confirmationBookingsData) {
        setConfirmationBookings(confirmationBookingsData);
        setNewAppointmentCounts(confirmationBookingsData.length);
      }
      if (unassignedBookingsData) {
        setUnassignedBookings(unassignedBookingsData);
        setUnassignedCounts(unassignedBookingsData.length);
      }
      if (unassignedSubBookingsData) {
        setUnassignedSubBookings(unassignedSubBookingsData);
        setUnassignedSubCounts(unassignedSubBookingsData.length);
      }
    };

    fetchData().then(() => setExternalFetch(!externalFetch));
  }, [externalFetchCounts, company?.id]); // Add company?.id as a dependency if it's used in the API calls

  const translateDateValue = (key, isMonth = true) => {
    let loweKey = key.toLowerCase();
    let translatedValue = "";
    if (isMonth) {
      translatedValue = t(`calendar.months.${loweKey}`);
    } else {
      translatedValue = t(`calendar.days.${loweKey}`);
    }

    return translatedValue;
  };

  const CalendarComponent = isLoadingBookings ? (
    <ArgonBox display="felx">
      <ArgonBox>
        <HostHiveLoading />
      </ArgonBox>
    </ArgonBox>
  ) : (
    <Calendar
      header={{
        title: `${translateDateValue(monthName)}`,
        date: `${translateDateValue(`${dayOfWeek}`, false)}, ${year}`,
      }}
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,dayGridWeek",
      }}
      initialView="dayGridMonth"
      initialDate={formattedDate}
      createAppointment={createAppointment}
      // events={updateCalendarEvents(propertyBookings, companyServiceNames)}
      // bookings={propertyBookings}
      fixedWeekCount={false}
      editable={true}
      selectable={true}
      selectMirror={true}
      externalFetch={externalFetch}
      appointmentId={appointmentId}
      setIsLoadingBooking={setIsLoadingBooking}
      // eventClick={(events) => handleBookingClicked(events)}
      //dayMaxEvents={true}
    />
  );

  const [showAppointmentsChangeRequests, setShowAppointmentsChangeRequests] =
    useState(false);
  const [showNewAppointments, setShowNewAppointments] = useState(false);
  const [showUnassignedAppointments, setShowUnassignedAppointments] =
    useState(false);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* const [changeRequestsData, setChangeRequestsData] = useState(); const
      [changeRequests, setChangeRequests] = useState([]); */}
      <ArgonBox pt={3}>
        <HostHiveDialog
          open={showAppointmentsChangeRequests}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          onClose={() => setShowAppointmentsChangeRequests(false)}
          maxWidth="lg"
          title="Change Requests"
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Save"
          showStatusBadge={false}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <ChangeRequests
              title={t("components.changeRequests.title")}
              date="23 - 30 March 2021"
              setExternalFetch={setExternalFetchCounts}
              externalFetch={externalFetchCounts}
              openRequests={openRequests}
              setOpenRequests={setOpenRequests}
              changeRequests={changeRequests}
              setChangeRequests={setChangeRequests}
            />
          }
        />
        {/* openRequestsData, setOpenRequestsData, changeRequestsData,
        setChangeRequestsData, */}
        <HostHiveDialog
          open={showUnassignedAppointments}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          onClose={() => setShowUnassignedAppointments(false)}
          maxWidth="lg"
          title="Unassigned Assignments"
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          showStatusBadge={false}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <AssignmentLIsts
              title={t("components.unassignedAssignements.title")}
              setExternalFetch={setExternalFetchCounts}
              externalFetch={externalFetchCounts}
              unassignedBookings={unassignedBookings}
              setUnassignedBookings={setUnassignedBookings}
              unassignedSubBookings={unassignedSubBookings}
              setUnassignedSubBookings={setUnassignedSubBookings}
            />
          }
        />
        <HostHiveDialog
          open={showNewAppointments}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          onClose={() => setShowNewAppointments(false)}
          maxWidth="xs"
          title=""
          fullScreen={false}
          cancelButtonText="Cancel"
          submitButtonText="Save"
          showStatusBadge={false}
          // statusColor="success"
          // statusContent={bookingInfo?.status}
          dialogObjects={
            <ConfirmationLists
              title={t("components.serviceAppointments.toBeConfirmed.title")}
              date="23 - 30 March 2021"
              setExternalFetch={setExternalFetchCounts}
              externalFetch={externalFetchCounts}
              confirmationBookings={confirmationBookings}
              setConfirmationBookings={setConfirmationBookings}
            />
          }
        />
        <Card>
          <ArgonBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            {/* {renderSuccessSB} */}
            <Grid container spacing={3}>
              <Grid item xs={12} md={9}>
                <ArgonBox lineHeight={1}>
                  <ArgonTypography variant="h5" fontWeight="medium">
                    Calendar
                  </ArgonTypography>
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {/* {t("components.tasks.subText")} */}
                    Manage integrated & scheduled appointments
                  </ArgonTypography>
                </ArgonBox>
              </Grid>
              <Grid item xs={12} md={3}>
                <Stack spacing={1} direction="row">
                  {/* <ArgonBox>
                          <ArgonButton
                            variant="gradient"
                            color="info"
                            size="small"
                            onClick={() => setOpen(true)}
                          >
                            + Log Time
                          </ArgonButton>
                        </ArgonBox> */}
                  {/* <ArgonBox>
                    <ArgonButton
                      variant="gradient"
                      color="warning"
                      size="small"
                      // onClick={() => setOpenFilterOptions(true)}
                    >
                      Apply Filters
                    </ArgonButton>
                  </ArgonBox> */}
                </Stack>
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3}>
                <DefaultCounterCard
                  count={
                    openChangeRequestCounts + changeRequestPendingApprovalCounts
                  }
                  suffix=""
                  title="Change Requests"
                  action={
                    <ArgonButton
                      color="info"
                      variant="gradient"
                      onClick={() =>
                        // navigate(`/tasks/${companyId}/${property.id}`)
                        setShowAppointmentsChangeRequests(true)
                      }
                    >
                      {t("buttons.view")}
                    </ArgonButton>
                  }
                  // description="Open change requests from service partners"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <DefaultCounterCard
                  count={newAppointmentCounts}
                  suffix=""
                  title="New Appointments"
                  action={
                    <ArgonButton
                      color="info"
                      variant="gradient"
                      onClick={() =>
                        //   // navigate(`/tasks/${companyId}/${property.id}`)
                        setShowNewAppointments(true)
                      }
                    >
                      {t("buttons.view")}
                    </ArgonButton>
                  }
                  // description="Assignements that need to be accepted by service partners"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DefaultCounterCard
                  count={unassignedCounts + unassignedSubCounts}
                  suffix=""
                  title="Unassigned"
                  action={
                    <ArgonButton
                      color="info"
                      variant="gradient"
                      onClick={() =>
                        // navigate(`/tasks/${companyId}/${property.id}`)
                        setShowUnassignedAppointments(true)
                      }
                    >
                      {t("buttons.view")}
                    </ArgonButton>
                  }
                  // description="Assignements missing a service partner or assignee"
                />
              </Grid>
              {/* <Grid item xs={12} md={3}>
                <PlaceholderCard
                  title={{ variant: "h5", text: "New Appointment" }}
                />
              </Grid> */}
            </Grid>
          </ArgonBox>
        </Card>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ height: "max-content" }}>
            <ArgonBox mt={3} mb={3}>
              {CalendarComponent}
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default SchedulePage;
