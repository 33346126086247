/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

function StatusCell({ icon, color, status, includeStatusText = true }) {
  return (
    <ArgonBox display="flex" alignItems="center">
      <ArgonBox mr={1}>
        <ArgonButton
          variant="outlined"
          color={color}
          size="small"
          iconOnly
          circular
        >
          <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
        </ArgonButton>
      </ArgonBox>
      {includeStatusText ? (
        <ArgonTypography
          variant="caption"
          fontWeight="medium"
          color="text"
          sx={{ lineHeight: 0 }}
        >
          {status}
        </ArgonTypography>
      ) : (
        <></>
      )}
    </ArgonBox>
  );
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusCell;
