import * as React from "react";
import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import ContactsApi from "api/companyAddressBookApi";
import HostHiveDialog from "components/HostHiveDialog";
import ContactItem from "layouts/administration/customerAddressBook/components/ContactItem/index.js";
import ArgonTypography from "components/ArgonTypography";
import ArgonBox from "components/ArgonBox";

const CustomListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

export default function ContactCard({
  contact,
  onClose,
  onEdit,
  setOnEdit,
  triggerFetch = null,
  includeContactButton = true,
  includeClose = true,
}) {
  const [contactPayload, setContactPayload] = useState(contact);
  const contactsApi = ContactsApi();

  const updateContact = async () => {
    let payload = {
      name: contactPayload.name,
      email: contactPayload.email,
    };
    if (contactPayload.phone) {
      // payload.phone = contactPayload.phone; as int
      payload.phone = contactPayload.phone;
    }
    try {
      await contactsApi.updateContact(contact?.id, payload);
      if (triggerFetch) {
        triggerFetch();
      }
    } catch (error) {
      console.log(error);
    }

    setOnEdit(false);
  };
  return (
    <>
      <HostHiveDialog
        open={onEdit}
        onClose={() => setOnEdit(false)}
        onSave={updateContact}
        includeSave={true}
        includeClose={true}
        includeDelete={false}
        showFullScreenArrow={false}
        title="Edit Contact"
        fullScreen={false}
        maxWidth="sm"
        cancelButtonText="Cancel"
        submitButtonText="Save"
        dialogObjects={
          <ContactItem
            contactPayload={contactPayload}
            setContactPayload={setContactPayload}
            // onClose={() => setOnEdit(false)}
          />
        }
      />
      <List sx={{ pt: 0 }}>
        <CustomListItem>
          <Avatar sx={{ bgcolor: "secondary.main", mr: 2 }}>
            {/* You can put initials of the contact name here */}
          </Avatar>
          <ListItemText primary={contact?.name} secondary={contact?.email} />

          <div>
            {contact?.phone && includeContactButton && (
              <IconButton
                edge="end"
                aria-label="call"
                href={`tel:${contact.phone}`}
                sx={{ mr: 1 }}
              >
                <PhoneIcon />
              </IconButton>
            )}
            {includeContactButton && (
              <IconButton
                edge="end"
                aria-label="send email"
                href={`mailto:${contact?.email}`}
              >
                <EmailIcon />
              </IconButton>
            )}
          </div>
        </CustomListItem>
        <ArgonBox pl={7}>
          <ArgonTypography variant="button" fontWeight="regular" color="text">
            {contact?.phone}
          </ArgonTypography>
        </ArgonBox>
      </List>
      <IconButton
        edge="start"
        aria-label="close"
        onClick={onClose}
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        {includeClose && <CloseIcon />}
      </IconButton>
    </>
  );
}
