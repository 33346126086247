import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Divider,
  TextareaAutosize,
} from "@mui/material";
import {
  today,
  year,
  month,
  day,
  daysOfWeek,
  dayOfWeek,
  monthName,
  formattedDate,
  start_date,
  convertTimeStamp,
} from "services/dateUtils";
import { IconButton } from "@mui/material";
// @mui material components
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import HostHiveSnackbar from "components/HostHiveSnackbar";

import ArgonBadge from "components/ArgonBadge";
import ArgonEditor from "components/ArgonEditor";
import ArgonSnackbar from "components/ArgonSnackbar";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import NotesApi from "api/notesApi";
import HostHiveDialog from "components/HostHiveDialog";
import HostHiveLoading from "components/HostHiveLoading";
import { useFormik } from "formik";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import NoteSchema from "schemas/noteSchema";
import { useTranslation } from "react-i18next";
function NoteItem({
  noteItem,
  propertyNotes,
  setPropertyNotes,
  openSuccessSB,
}) {
  const [editNote, setEditNote] = useState(false);
  const notesApi = NotesApi();
  const [savingNote, setSavingNote] = useState(false);
  const { t } = useTranslation("common");
  const handleDeleteNote = async () => {
    console.log("delete note", noteItem?.id);
    try {
      const deleteNoteResponse = await notesApi.deleteNote(noteItem?.id);
      if (deleteNoteResponse.status >= 200 && deleteNoteResponse.status < 300) {
        console.log("Note deleted successfully");
        const updatedNotes = propertyNotes.filter(
          (note) => note.id !== noteItem.id
        );
        setPropertyNotes(updatedNotes);
        openSuccessSB(
          `${t("components.notes.note")} ${t("generalWords.deleted")}`,
          `${t("generalWords.successfullyDeleted")} ${t(
            "components.notes.note"
          )}`
        );
      } else {
        console.log("Note delete failed", deleteNoteResponse);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const toggleSavingNote = () => {
    setSavingNote(!savingNote);
  };
  const handleSaveNoteEdit = async (values) => {
    setSavingNote(true);
    try {
      const updatedNoteResponse = await notesApi.updateNote(
        noteItem?.id,
        values
      );
      if (
        updatedNoteResponse.status >= 200 &&
        updatedNoteResponse.status < 300
      ) {
        console.log("Note updated successfully");
        const updatedNotes = propertyNotes.map((note) =>
          note.id === noteItem.id ? { ...note, note: values.note } : note
        );
        setPropertyNotes(updatedNotes);
        setEditNote(!editNote);
        openSuccessSB(
          `${t("components.notes.note")} ${t("generalWords.updated")}`,
          `${t("generalWords.successfullyUpdated")} ${t(
            "components.notes.note"
          )}`
        );
      } else {
        console.log("Note update failed", updatedNoteResponse);
      }
    } catch (error) {
      console.log("error", error);
    }
    setSavingNote(false);
  };

  const trimNote = (note) => {
    let trimmedNote = note.slice(0, 45) + "...";
    return trimmedNote;
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      companyId: noteItem?.companyId,
      propertyId: noteItem?.propertyId,
      note: noteItem?.note,
      status: noteItem?.status,
      // Add more fields here
    },
    validationSchema: NoteSchema,
    handleSaveNoteEdit,
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <ArgonBox pl={1} sx={{ maxWidth: "60ch" }}>
            {!editNote ? (
              <ArgonTypography variant="h5" fontWeight="regular">
                {values.note}
              </ArgonTypography>
            ) : (
              <form onSubmit={handleSubmit}>
                <ArgonInput
                  disabled={savingNote}
                  multiline
                  name="note"
                  value={values.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // Add error handling from Formik state
                  error={touched.note && Boolean(errors.note)}
                  helperText={touched.note && errors.note}
                  // value={newNote}
                  // onChange={(e) => setNewNote(e.target.value)}
                />

                {errors.note && touched.note && (
                  // <p className="error">{errors.name}</p>
                  <HostHiveErrorMessage message={errors.note} />
                )}
              </form>
            )}
          </ArgonBox>
        </Grid>
        <Grid item xs={12} sm={12}>
          <ArgonBox display="flex">
            <ArgonBox key={noteItem?.id}>
              <IconButton>
                {editNote ? (
                  <>
                    {savingNote ? (
                      <HostHiveLoading />
                    ) : (
                      <Tooltip
                        title={`${t("buttons.save")}`}
                        placement="top"
                        onClick={() => handleSaveNoteEdit(values)}
                      >
                        {/* <Icon color="info"> add</Icon> */}
                        <ArgonBadge
                          color="success"
                          size="xs"
                          badgeContent={`${t("buttons.save")}`}
                          container
                          onClick={() => handleSaveNoteEdit(values)}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <Tooltip
                    title={`${t("buttons.edit")} ${t("components.notes.note")}`}
                    placement="top"
                  >
                    {/* <Icon color="info"> add</Icon> */}
                    <ArgonBadge
                      color="info"
                      size="xs"
                      badgeContent={t("buttons.edit")}
                      container
                      onClick={() => setEditNote(true)}
                    />
                  </Tooltip>
                )}
              </IconButton>
            </ArgonBox>

            <ArgonBox>
              <IconButton>
                <Tooltip
                  title={`${t("buttons.delete")} ${t("components.notes.note")}`} //"Delete note"
                  placement="top"
                  onClick={handleDeleteNote}
                >
                  {/* <Icon color="info"> add</Icon> */}
                  <ArgonBadge
                    color="error"
                    size="xs"
                    badgeContent={t("buttons.delete")}
                    container
                    onClick={handleDeleteNote}
                  />
                </Tooltip>
              </IconButton>
            </ArgonBox>
            <ArgonBox>
              <IconButton>
                <Tooltip
                  title={`${t("generalWords.createdBy")}: ${
                    noteItem?.subAssigneeFirstName
                  } ${noteItem?.subAssigneeLastName} on${" "}
              ${convertTimeStamp(noteItem?.created)}`}
                  placement="top"
                >
                  <Icon color="info"> info</Icon>
                </Tooltip>
              </IconButton>
            </ArgonBox>
          </ArgonBox>
        </Grid>
        {/* <Grid item xs={12} sm={12}>
          <ArgonBox>
            <ArgonTypography
              variant="button"
              fontWeight="regular"
              color="text"
              sx={{ fontStyle: "italic" }}
            >
              {t("generalWords.createdBy")}: {noteItem?.createdBy} on{" "}
              {noteItem?.created}
            </ArgonTypography>
          </ArgonBox>
        </Grid> */}
      </Grid>
      <Divider />
    </>
  );
}

export default NoteItem;
