/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AdministrationApi from "api/administrationApi";
import PropertyItem from "./components/PropertyItem";
function CompanyProperties() {
  const administrationApi = new AdministrationApi();
  const { companyId } = useParams();
  const [permitted, setPermitted] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const attemptReachability = async () => {
    try {
      await administrationApi.getReachability();
      setPermitted(true);
      console.log("Permitted");
      getProperties();
    } catch (error) {
      console.error(error);
    }
  };
  const [properties, setProperties] = useState([]);
  const getProperties = async () => {
    try {
      const response = await administrationApi.getProperties(companyId);
      setProperties(response.data);
      //
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProperties = async () => {
    // deleteAllProperties;
    try {
      await administrationApi.deleteAllProperties(companyId);
      setTriggerFetch(!triggerFetch);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    attemptReachability();
  }, [triggerFetch]);

  return (
    <>
      {permitted && (
        <DashboardLayout>
          <ArgonBox pt={6} pb={3}>
            <Card>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="secondary"
                  onClick={() => window.history.back()}
                >
                  Back to to Company
                </ArgonButton>
              </ArgonBox>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="error"
                  onClick={() => deleteProperties()}
                >
                  Delete All Properties
                </ArgonButton>
              </ArgonBox>
              <ArgonBox p={3} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Properties
                </ArgonTypography>
                <ArgonBox
                  sx={{
                    overflow: "auto",
                    maxHeight: "500px",
                    padding: "0 20px",
                    marginBottom: "20px",
                  }}
                >
                  {properties.map((property) => (
                    <PropertyItem
                      property={property}
                      refetchProperties={() => setTriggerFetch(!triggerFetch)}
                    />
                  ))}
                </ArgonBox>
                {/* <ArgonTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  A lightweight, extendable, dependency-free javascript HTML
                  table plugin.
                </ArgonTypography> */}
              </ArgonBox>
            </Card>
          </ArgonBox>
          <Footer />
        </DashboardLayout>
      )}
    </>
  );
}

export default CompanyProperties;
