// @mui material components
import Icon from "@mui/material/Icon";

import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import { RouteProtection } from "services/routeProtectionHelper";
import { UserAuth } from "./context/AuthContext";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
const pageRoutes = [
  {
    name: i18n.t("navBar.topNavbar.apps.title", { ns: "common" }), //"apps",
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: i18n.t("appHomepage.apps.properties.title", { ns: "common" }),
        protectionLookUpKey: "properties",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-building" />
        ),
        collapse: [
          {
            name: i18n.t("navBar.topNavbar.apps.properties.viewProperties", {
              ns: "common",
            }),
            protectionLookUpKey: "properties",
            route: "/properties",
          },
          {
            name: "create property",
            protectionLookUpKey: "properties",
            route: "/properties/create",
          },
          {
            name: "Property Group Settings",
            protectionLookUpKey: "properties",
            route: "/property-group-settings",
          },
        ],
      },

      {
        name: "Appointments",
        protectionLookUpKey: "calendar",
        icon: (
          <ArgonBox
            component="i"
            color="info"
            className="ni ni-calendar-grid-58"
          />
        ),
        collapse: [
          {
            name: "View Calendar",
            protectionLookUpKey: "calendar",
            route: "/calendar",
          },
          {
            name: "create appointment",
            protectionLookUpKey: "calendar",
            route: "/calendar/create",
          },
          {
            name: "Change Requests",
            protectionLookUpKey: "calendar",
            route: "/calendar/change-requests",
          },
        ],
      },
      {
        name: "service reports",
        protectionLookUpKey: "serviceReports",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-folder-17" />
        ),
        collapse: [
          {
            name: "View Service Reports",
            protectionLookUpKey: "serviceReports",
            route: "/service-reports",
          },
        ],
      },
      {
        name: "Inventory",
        protectionLookUpKey: "inventory",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-chart-bar-32" />
        ),
        collapse: [
          {
            name: "View Inventory",
            protectionLookUpKey: "inventory",
            route: "/inventory",
          },
        ],
      },
      {
        name: "Tasks",
        protectionLookUpKey: "tasks",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-single-02" />
        ),
        collapse: [
          {
            name: "View Tasks",
            protectionLookUpKey: "tasks",
            route: "/tasks",
          },
          {
            name: "Create Task",
            protectionLookUpKey: "tasks",
            route: "/tasks/create",
          },
        ],
      },
    ],
  },
  //navBar.topNavbar.administration.title
  {
    name: i18n.t("navBar.topNavbar.administration.title", { ns: "common" }),
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Finance",
        protectionLookUpKey: "finance",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-money-coins" />
        ),
        collapse: [
          {
            name: "View Finance",
            protectionLookUpKey: "finance",
            route: "/finance",
          },
          {
            name: "view imported transactions",
            protectionLookUpKey: "finance",
            route: "/finance/imported-transactions",
          },
          {
            name: "Create Transaction",
            protectionLookUpKey: "finance",
            route: "/finance/create",
          },
        ],
      },
      {
        name: "service invoicing",
        protectionLookUpKey: "serviceInvoicing",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-briefcase-24" />
        ),
        collapse: [
          {
            name: "View Service Invoicing",
            protectionLookUpKey: "serviceInvoicing",
            route: "/service-invoicing",
          },
        ],
      },
      {
        name: "users",
        protectionLookUpKey: "company",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-circle-08" />
        ),
        collapse: [
          {
            name: "View Users",
            protectionLookUpKey: "company",
            route: "/users",
          },
          // {
          //   name: "new user",
          //   protectionLookUpKey: "company",
          //   route: "/pages/users/new-user",
          // },
        ],
      },

      {
        name: "Time Tracking",
        protectionLookUpKey: "timeTracking",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-watch-time" />
        ),
        collapse: [
          {
            name: "View logged time",
            protectionLookUpKey: "timeTracking",
            route: "/time-tracking",
          },
          {
            name: "Log Time",
            protectionLookUpKey: "timeTracking",
            route: "/time-tracking/create",
          },
        ],
      },
      {
        name: "Customer Address Book",
        protectionLookUpKey: "company",
        icon: (
          <ArgonBox component="i" color="info" className="ni ni-watch-time" />
        ),
        collapse: [
          {
            name: "View Contact List",
            protectionLookUpKey: "company",
            route: "/contacts",
          },
        ],
      },
    ],
  },
  {
    name: "settings",
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Company Settings",
        protectionLookUpKey: "company",
        icon: (
          <ArgonBox
            component="i"
            color="info"
            className="ni ni-settings-gear-65"
          />
        ),
        collapse: [
          {
            name: "settings",
            protectionLookUpKey: "company",
            route: "/company/settings",
          },
          {
            name: "Account settings",
            protectionLookUpKey: "company",
            route: "/company/settings#account-settings",
          },
        ],
      },
      {
        name: "user settings",
        protectionLookUpKey: "company",
        icon: (
          <ArgonBox
            component="i"
            color="info"
            className="ni ni-settings-gear-65"
          />
        ),
        collapse: [
          {
            name: "My Profile",
            protectionLookUpKey: "company",
            route: "/my-profile",
          },
        ],
      },
    ],
  },
  {
    name: "help",
    columns: 2,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Support",
        protectionLookUpKey: "company",
        icon: (
          <ArgonBox
            component="i"
            color="info"
            className="ni ni-settings-gear-65"
          />
        ),
        collapse: [
          {
            name: "getting started",
            protectionLookUpKey: "company",
            href: "https://docs.hosthive.io/en/home",
            description:
              "Activate your subscripiption and start getting the most from HostHive",
            icon: (
              <ArgonBox component="i" color="info" className="ni ni-planet" />
            ),
          },
          {
            name: "documentation",
            protectionLookUpKey: "company",
            href: "https://docs.hosthive.io/en/home",
            description:
              "Review our documentation with examples and informations",
            icon: (
              <ArgonBox
                component="i"
                color="info"
                className="ni ni-single-copy-04"
              />
            ),
          },
          {
            name: "Get Support",
            protectionLookUpKey: "company",
            route: "/support",
            // href: "https://www.creative-tim.com/learning-lab/react/alerts/argon-dashboard/",
            description: "Explore our collection of fully designed components",
            icon: <ArgonBox component="i" color="info" className="ni ni-app" />,
          },
          {
            name: "Video Resources",
            protectionLookUpKey: "company",
            route: "/video-resources",
            // href: "https://www.creative-tim.com/learning-lab/react/alerts/argon-dashboard/",
            description: "",
            icon: <ArgonBox component="i" color="info" className="ni ni-app" />,
          },
          // {
          //   name: "Frequency Asked Question",
          //   protectionLookUpKey: "company",
          //   href: "https://www.creative-tim.com/learning-lab/react/datepicker/argon-dashboard/",
          //   description: "Check how you can integrate our plugins",
          //   icon: (
          //     <ArgonBox
          //       component="i"
          //       color="info"
          //       className="ni ni-chart-bar-32"
          //     />
          //   ),
          // },
        ],
      },
    ],
  },
];

export default pageRoutes;
