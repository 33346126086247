/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Property Page: https://www.creative-tim.com/property/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useMemo, useEffect, useState, useContext } from "react";
// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import Icon from "@mui/material/Icon";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { Link, useNavigate } from "react-router-dom";
import BookingsApi from "api/bookingsApi";
import ServicePartnersSelect from "layouts/management/components/ServicePartnerSelect";

import DeclineMessageDataCard from "layouts/management/components/DeclineMessageDataCard";
import HostHiveDialog from "components/HostHiveDialog";
import EventDataCard from "layouts/management/calendar/components/EventDataCard";
import SelectServicePartner from "layouts/management/calendar/components/CreateEventWizard/components/ServicePartnerSelection";
import ViewEventDataCard from "layouts/management/calendar/components/ViewEventDataCard";
import { useTranslation } from "react-i18next";
import UsersSelect from "layouts/management/components/UsersSelect";
import { set } from "lodash";

function AssignmentLists({
  title,
  date,
  setExternalFetch,
  externalFetch,
  unassignedBookings,
  setUnassignedBookings,
  unassignedSubBookings,
  setUnassignedSubBookings,
}) {
  const { t } = useTranslation("common");
  const bookingsApi = BookingsApi();

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    console.log(newValue);
  };

  const [declineMessage, setDeclineMessage] = useState(false);
  const [eventDataCardOpen, setEventDataCardOpen] = useState(false);
  const [declineBookingInfo, setDeclineBookingInfo] = useState({});
  const [fetchBookingsToConfirm, setFetchBookingsToConfirm] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [bookingInfo, setBookingInfo] = useState({});
  const [propertyInfo, setPropertyInfo] = useState({});
  const [bookingPayload, setBookingPayload] = useState(null);
  const [userSelections, setUserSelections] = useState({});
  const [nextDisabled, setNextDisabled] = useState(false);

  const [updateServicePartner, setUpdateServicePartner] = useState(false);
  const [updateServiceSubAssignee, setUpdateServiceSubAssignee] =
    useState(false);
  const [updateToSave, setUpdateToSave] = useState(false);
  const [responseData, setResponseData] = useState({});

  const handleConfirmBooking = async (id) => {
    console.log("Booking confirmed", id);
    const status = "confirmed";
    try {
      const updateBookingStatusResponse = await bookingsApi.updateBookingStatus(
        id,
        status
      );
      console.log("Booking status updated", updateBookingStatusResponse);
      // Remove the confirmed booking from the unassignedBookings list
      setUnassignedBookings((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== id)
      );
      setExternalFetch(!externalFetch);
    } catch (error) {
      console.error("Error updating booking status", error);
    }
  };

  const findBooking = (bookingId, bookings) => {
    const foundBooking = bookings.find((booking) => booking.id === bookingId);
    return foundBooking || null;
  };
  const [workingBookingId, setWorkingBookingId] = useState(null);
  const handleSubAssigneeUpdate = (bookingId) => {
    setUpdateServiceSubAssignee(true);
    setWorkingBookingId(bookingId);
  };

  const handleAssigneeUpdate = (bookingId) => {
    setUpdateServicePartner(true);
    setWorkingBookingId(bookingId);
  };

  const handleViewBooking = (
    bookingId,
    bookingResponse,
    setUpdateServicePartner = null
  ) => {
    console.log("Booking view", bookingId);

    let foundBookingInfo = findBooking(bookingId, bookingResponse);
    if (!foundBookingInfo) {
      console.log("Booking not found");
    } else {
      console.log("Booking found", foundBookingInfo);
      setBookingPayload(foundBookingInfo);
      setBookingInfo(foundBookingInfo);
      let updatedUserSelections = {
        [foundBookingInfo?.assignedCompanyId]:
          foundBookingInfo?.assignedCompany,
        [foundBookingInfo?.checklistId]: foundBookingInfo?.checklistName,
        [foundBookingInfo?.serviceNameId]: foundBookingInfo?.serviceName,
        [foundBookingInfo?.propertyId]: foundBookingInfo?.propertyName,

        [foundBookingInfo?.category?.id]: (
          <ArgonBadgeDot
            color={foundBookingInfo?.category?.color}
            size="md"
            badgeContent={foundBookingInfo?.category?.name}
          />
        ),
        [foundBookingInfo?.assignedUserSubId]:
          foundBookingInfo?.assignedUserEmail,
      };

      setUserSelections(updatedUserSelections);

      if (setUpdateServicePartner) {
        setUpdateServicePartner(true);
      } else {
        setEventDataCardOpen(true);
      }
    }
  };

  const UnassignedBookings = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            {unassignedBookings?.map((booking, key) => (
              <ArgonBox key={booking?.id} pt={1} pr={2} pl={2}>
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonBox display="flex" flexDirection="column">
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        gutterBottom
                        textTransform="capitalize"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleViewBooking(booking?.id, unassignedBookings)
                        }
                      >
                        {booking?.title}
                      </ArgonTypography>
                      <ArgonTypography variant="caption" color="text">
                        {booking?.serviceScheduleDate}
                      </ArgonTypography>
                      <ArgonBox>
                        <ArgonBox>
                          <ArgonTypography component="label" variant="caption">
                            {booking?.propertyAddress?.city},{" "}
                            {booking?.propertyAddress?.state},{" "}
                            {booking?.propertyAddress?.zipCode}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  <ArgonBox display="flex">
                    <ArgonBox mr={2}>
                      <ArgonButton
                        onClick={() => handleAssigneeUpdate(booking?.id)}
                        variant="gradient"
                        color="primary"
                        size="small"
                      >
                        Assign Partner
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
                <Divider
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                />
              </ArgonBox>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };

  const UnassignedSubBookings = () => {
    return (
      <>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            {unassignedSubBookings?.map((booking, key) => (
              <ArgonBox key={booking?.id} pt={1} pr={2} pl={2}>
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ArgonBox display="flex" alignItems="center">
                    <ArgonBox display="flex" flexDirection="column">
                      <ArgonTypography
                        variant="button"
                        fontWeight="medium"
                        gutterBottom
                        textTransform="capitalize"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleViewBooking(booking?.id, unassignedSubBookings)
                        }
                      >
                        {booking?.title}
                      </ArgonTypography>
                      <ArgonTypography variant="caption" color="text">
                        {booking?.serviceScheduleDate}
                      </ArgonTypography>
                      <ArgonBox>
                        <ArgonBox>
                          <ArgonTypography component="label" variant="caption">
                            {booking?.propertyAddress?.city},{" "}
                            {booking?.propertyAddress?.state},{" "}
                            {booking?.propertyAddress?.zipCode}
                          </ArgonTypography>
                        </ArgonBox>
                      </ArgonBox>
                    </ArgonBox>
                  </ArgonBox>
                  <ArgonBox display="flex">
                    <ArgonBox mr={2}>
                      <ArgonButton
                        onClick={() => handleSubAssigneeUpdate(booking?.id)}
                        variant="gradient"
                        color="primary"
                        size="small"
                      >
                        Assign User
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
                <Divider
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                />
              </ArgonBox>
            ))}
          </Grid>
        </Grid>
      </>
    );
  };
  const [updatedSubAssignee, setUpdatedSubAssignee] = useState(null);
  const [updatedAssignee, setUpdatedAssignee] = useState(null);
  const updateBookingAssignee = async () => {
    let bookingId = workingBookingId;
    let assigneeId = updatedAssignee?.value;
    try {
      const response = await bookingsApi.updateBookingAssignee(
        bookingId,
        assigneeId
      );
      console.log("Booking assignee updated", response);
      // remove the booking from the unassigned bookings list
      setUnassignedBookings((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== bookingId)
      );
      if (setExternalFetch) {
        setExternalFetch(!externalFetch);
      }
      setWorkingBookingId(null);
      setUpdatedAssignee(null);
      setUpdateServicePartner(false);
    } catch (error) {
      console.error("Error updating booking assignee", error);
    }
  };

  const updateBookingSubAssignee = async () => {
    let subAssigneeId = updatedSubAssignee?.value;
    let bookingId = workingBookingId;
    try {
      const response = await bookingsApi.updateBookingSubAssignee(
        bookingId,
        subAssigneeId
      );
      console.log("Booking sub assignee updated", response);
      // remove the booking from the unassigned bookings list
      setUnassignedSubBookings((prevBookings) =>
        prevBookings.filter((booking) => booking.id !== bookingId)
      );
      if (setExternalFetch) {
        setExternalFetch(!externalFetch);
      }
      setWorkingBookingId(null);
      setUpdatedSubAssignee(null);
      setUpdateServiceSubAssignee(false);
    } catch (error) {
      console.error("Error updating booking sub assignee", error);
    }
  };

  const updateAssignmentDetails = async () => {
    console.log("save to update", updatedAssignee);
  };

  return (
    <Card sx={{ height: "100%" }}>
      <ArgonBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={2}
        px={2}
      >
        <HostHiveDialog
          open={updateServiceSubAssignee}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          onSave={updateBookingSubAssignee}
          onClose={() => setUpdateServiceSubAssignee(false)}
          maxWidth="md"
          title="Update Assignee"
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Save"
          showStatusBadge={true}
          statusColor="success"
          statusContent={bookingInfo?.status}
          dialogObjects={
            <UsersSelect
              setUser={setUpdatedSubAssignee}
              user={updatedSubAssignee}
              companyId={company?.id}
            />
          }
        />
        <HostHiveDialog
          open={updateServicePartner}
          fullWidth={true}
          includeSave={true}
          includeClose={true}
          onSave={updateBookingAssignee}
          onClose={() => setUpdateServicePartner(false)}
          maxWidth="md"
          title="Update Service Partner"
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Save"
          showStatusBadge={true}
          statusColor="success"
          statusContent={bookingInfo?.status}
          dialogObjects={
            <ServicePartnersSelect
              setPartner={setUpdatedAssignee}
              partner={updatedAssignee}
              companyId={company?.id}
            />
          }
        />
        <HostHiveDialog
          open={eventDataCardOpen}
          fullWidth={true}
          includeSave={false}
          includeClose={true}
          onClose={() => setEventDataCardOpen(false)}
          maxWidth="md"
          title="View Service Appointment"
          fullScreen={false}
          cancelButtonText="Close"
          submitButtonText="Save"
          showStatusBadge={true}
          statusColor="success"
          statusContent={bookingInfo?.status}
          dialogObjects={
            <ViewEventDataCard
              setNextDisabled={setNextDisabled}
              bookingPayload={bookingPayload}
              setBookingPayload={setBookingPayload}
              setUserSelections={setUserSelections}
              userSelections={userSelections}
              responseData={responseData}
              setResponseData={setResponseData}
              isInCreationMode={false}
            />
          }
        />
      </ArgonBox>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sx={{ ml: "auto" }}>
          <AppBar position="static">
            <Tabs
              orientation={tabsOrientation}
              value={tabValue}
              onChange={handleSetTabValue}
            >
              <Tab
                label={`No Assignee (${unassignedSubBookings.length})`}
                icon={
                  <i
                    className="ni ni-app"
                    style={{ marginTop: "6px", marginRight: "8px" }}
                  />
                }
              />
              <Tab
                label={`No Sevice Partner (${unassignedBookings.length})`}
                icon={
                  <i
                    className="ni ni-email-83"
                    style={{ marginTop: "6px", marginRight: "8px" }}
                  />
                }
              />
            </Tabs>
          </AppBar>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <ArgonBox display="flex" justifyContent="center" pt={2}>
            {tabValue === 0 ? (
              <UnassignedSubBookings />
            ) : (
              <UnassignedBookings />
            )}
          </ArgonBox>
        </Grid>
      </Grid>
      {/* <ArgonBox p={2}>
        {showAssignmentsUnassigned ? (
          <ArgonBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            {renderBookings}
          </ArgonBox>
        ) : (
          <>
            <ArgonBox display="flex" justifyContent="center" mt={2}>
              <ArgonButton color="primary" variant="outlined">
                No Appointments to Assign
              </ArgonButton>
            </ArgonBox>
          </>
        )}

        {!showAll && unassignedBookings.length > 4 && (
          <ArgonBox display="flex" justifyContent="center" mt={2}>
            <ArgonButton
              onClick={handleViewMore}
              color="primary"
              variant="outlined"
            >
              {t("buttons.viewMore")}
            </ArgonButton>
          </ArgonBox>
        )}
      </ArgonBox> */}
    </Card>
  );
}

export default AssignmentLists;
