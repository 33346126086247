/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components

// Argon Dashboard 2 PRO MUI components

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import Switch from "@mui/material/Switch";

import React, { useEffect, useState, useMemo } from "react";
import FilesApi from "api/filesApi";
import PropertiesApi from "api/propertiesApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";

import ImportMatchWizard from "layouts/management/components/ImportMatchWizard";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonDatePicker from "components/ArgonDatePicker";
import RecurrenceDialog from "layouts/management/components/RecurrenceDialog";
import { useTranslation } from "react-i18next";
import { use } from "i18next";
import { set } from "lodash";
function DateSelection({
  setNextDisabled,
  bookingPayload,
  setBookingPayload,
  setUserSelections,
  userSelections,
  isInCreationMode,
  serviceGapDate = null,
}) {
  const { t } = useTranslation("common");

  const intervalOptions = [
    { value: "daily", label: "Day" },
    { value: "weekly", label: "Week" },
    { value: "monthly", label: "Month" },
    { value: "yearly", label: "Year" },
  ];

  const intervalOptionsDuration = [
    { value: "minutes", label: t("time.selection.minutes") },
    { value: "hours", label: t("time.selection.hours") },
    { value: "days", label: t("time.selection.days") },
  ];

  const twentyFourHoursTimeOptions = [
    { value: "00:00", label: "12:00 AM" },
    { value: "01:00", label: "1:00 AM" },
    { value: "02:00", label: "2:00 AM" },
    { value: "03:00", label: "3:00 AM" },
    { value: "04:00", label: "4:00 AM" },
    { value: "05:00", label: "5:00 AM" },
    { value: "06:00", label: "6:00 AM" },
    { value: "07:00", label: "7:00 AM" },
    { value: "08:00", label: "8:00 AM" },
    { value: "09:00", label: "9:00 AM" },
    { value: "10:00", label: "10:00 AM" },
    { value: "11:00", label: "11:00 AM" },
    { value: "12:00", label: "12:00 PM" },
    { value: "13:00", label: "1:00 PM" },
    { value: "14:00", label: "2:00 PM" },
    { value: "15:00", label: "3:00 PM" },
    { value: "16:00", label: "4:00 PM" },
    { value: "17:00", label: "5:00 PM" },
    { value: "18:00", label: "6:00 PM" },
    { value: "19:00", label: "7:00 PM" },
    { value: "20:00", label: "8:00 PM" },
    { value: "21:00", label: "9:00 PM" },
    { value: "22:00", label: "10:00 PM" },
    { value: "23:00", label: "11:00 PM" },
  ];

  const [serviceScheduleDate, setServiceDate] = useState(
    bookingPayload?.serviceScheduleDate || serviceGapDate || null
  );
  const [repeatEndDate, setRepeatEndDate] = useState(
    bookingPayload?.recurrenceData?.repeatEndDate || null
  );
  const [error, setError] = useState(null);

  const [repeatEvent, setRepeatEvent] = useState(
    bookingPayload?.repeatEvent || false
  );
  const [estimatedDurationValue, setEstimatedDurationValue] = useState(
    bookingPayload?.estimatedDuration?.value || null
  );
  const [estimatedDurationInterval, setEstimatedDurationInterval] = useState({
    value: bookingPayload?.estimatedDuration?.interval,
    label: userSelections?.estimatedDurationIntervalLabel,
  });

  const [repeatValue, setRepeatValue] = useState(
    bookingPayload?.recurrenceData?.value || null
  );
  const [repeatValueInterval, setRepeatValueInterval] = useState(
    {
      value: bookingPayload?.recurrenceData?.interval,
      label: userSelections?.recurrenceIntervalLabel,
    } || null
  );

  const [recurrenceDoesNotEnd, setRecurrenceDoesNotEnd] = useState(
    bookingPayload?.recurrenceData?.repeatEndDate || true
  );

  const [serviceScheduleTime, setServiceScheduleTime] = useState({
    value: bookingPayload?.serviceScheduleTime || "10:00",
    label: userSelections?.serviceScheduleTime || "10:00 AM",
  });
  useEffect(() => {
    if (
      serviceGapDate != null &&
      bookingPayload?.serviceScheduleTime === undefined
    ) {
      console.log("Setting serviceScheduleDate to", serviceGapDate);
      setServiceDate(serviceGapDate);

      setBookingPayload({
        ...bookingPayload,
        serviceScheduleDate: serviceGapDate,
        serviceScheduleTime: serviceScheduleTime.value,
      });

      setUserSelections({
        ...userSelections,
        serviceScheduleTime: serviceScheduleTime.label,
      });
    }
    if (
      serviceGapDate === null &&
      bookingPayload?.serviceScheduleTime === undefined
    ) {
      console.log("Setting time to", serviceScheduleDate);

      setBookingPayload({
        ...bookingPayload,
        serviceScheduleTime: serviceScheduleTime.value,
      });

      setUserSelections({
        ...userSelections,
        serviceScheduleTime: serviceScheduleTime.label,
      });
    }
    // if (bookingPayload?.serviceScheduleTime === undefined) {
    //   console.log("Setting serviceScheduleTime to", serviceScheduleTime.value);
    //   setBookingPayload({
    //     ...bookingPayload,
    //     serviceScheduleTime: serviceScheduleTime.value,
    //   });

    //   setUserSelections({
    //     ...userSelections,
    //     serviceScheduleTime: serviceScheduleTime.label,
    //   });
    // }

    if (setNextDisabled) {
      setNextDisabled(false);
    }
    if (serviceScheduleDate != null && serviceScheduleTime != null) {
      if (setNextDisabled) {
        setNextDisabled(false);
      }
    }
  }, [serviceScheduleDate, serviceScheduleTime]);
  useEffect(() => {
    console.log("User Selections In date selection", userSelections);
  }, []);

  // useEffect(()=>{

  // })

  const updateBooking = async (key, value) => {
    if (key === "serviceScheduleTime") {
      console.log("Updating serviceScheduleTime to", value);
    } else if (key === "serviceScheduleDate") {
      console.log("Updating serviceScheduleDate to", value);
    } else if (key === "estimatedDuration") {
      console.log("Updating estimatedDuration to", value);
    } else if (key === "recurrenceData") {
      console.log("Updating recurrenceData to", value);
    }
  };

  const handleServiceDateSelection = (date) => {
    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let useDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    console.log(useDate);
    setServiceDate(useDate);
    setBookingPayload({ ...bookingPayload, serviceScheduleDate: useDate });
    if (!isInCreationMode) {
      updateBooking("serviceScheduleDate", useDate);
    }
    // setUserSelections({
    //   ...userSelections,
    //   serviceScheduleDate: useDate,
    // });
  };
  const handleTimeSeletion = (e) => {
    console.log(e);
    setServiceScheduleTime(e);
    setBookingPayload({ ...bookingPayload, serviceScheduleTime: e.value });
    setUserSelections({
      ...userSelections,
      serviceScheduleTime: e.label,
    });
    if (!isInCreationMode) {
      updateBooking("serviceScheduleTime", e.value);
    }
  };

  const handleRecurrenceData = (e, fieldName) => {
    console.log("Event:", e); // Check the event object
    let newRepeatEndDate = repeatEndDate;
    if (e === undefined || e === null) {
      console.log("No value");
      return;
    }

    let newValue = repeatValue;
    let newInterval = repeatValueInterval;

    if (fieldName === "recurrenceValue") {
      console.log("Interval Value:", e.target.value); // Check the value from the event
      newValue = e.target.value;
      setRepeatValue(newValue);
    } else if (fieldName === "interval") {
      newInterval = e;
      console.log("New Interval:", newInterval); // Check the new interval
      setRepeatValueInterval(newInterval);
    } else if (fieldName === "repeatEndDate") {
      let year = e[0].getFullYear();
      let month = e[0].getMonth() + 1;
      let day = e[0].getDate();

      newRepeatEndDate = `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
      console.log("end date", newRepeatEndDate);

      setRepeatEndDate(newRepeatEndDate);
    }

    console.log("New Interval:", newInterval, newRepeatEndDate); // Check the new interval

    let recurrenceDataUpdate = {
      value: newValue,
      interval: newInterval?.value,
      repeatEndDate: newRepeatEndDate,
      repeatDoesNotEnd: recurrenceDoesNotEnd,
    };

    setUserSelections({
      ...userSelections,
      recurrenceData: recurrenceDataUpdate,
    });

    if (fieldName === "interval") {
      console.log("Interval label:", e.label); // Check the value from the event
      setUserSelections({
        ...userSelections,
        recurrenceIntervalLabel: e.label,
      });
    }

    setBookingPayload({
      ...bookingPayload,
      recurrenceData: recurrenceDataUpdate,
    });

    if (!isInCreationMode) {
      updateBooking("recurrenceData", recurrenceDataUpdate);
    }
  };

  const updateEstimatedDuration = (e, fieldName) => {
    console.log("Event:", e); // Check the event object
    console.log("Target:", e?.target); // Check the target of the event

    if (e === undefined || e === null) {
      console.log("No value");
      return;
    }

    let newEstimatedDurationValue = estimatedDurationValue;
    let newEstimatedDurationInterval = estimatedDurationInterval;

    if (fieldName === "intervalValue") {
      console.log("Interval Value:", e.target.value); // Check the value from the event
      newEstimatedDurationValue = e.target.value;
      setEstimatedDurationValue(newEstimatedDurationValue);
    } else if (fieldName === "interval") {
      newEstimatedDurationInterval = e;
      setEstimatedDurationInterval(newEstimatedDurationInterval);
    }
    console.log("New Interval:", newEstimatedDurationInterval); // Check the new interval

    let estimatedDuration = {
      value: newEstimatedDurationValue,
      interval: newEstimatedDurationInterval?.value,
    };

    setUserSelections({
      ...userSelections,
      estimatedDuration: estimatedDuration,
      estimatedDurationIntervalLabel: newEstimatedDurationInterval?.label,
    });

    setBookingPayload({
      ...bookingPayload,
      estimatedDuration: estimatedDuration,
    });

    if (!isInCreationMode) {
      updateBooking("estimatedDuration", estimatedDuration);
    }
  };

  return (
    <ArgonBox>
      <ArgonTypography variant="button" fontWeight="regular" color="text">
        Select the date and time of the service
      </ArgonTypography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            <ArgonTypography variant="h5" fontWeight="bold">
              Date
            </ArgonTypography>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
          </ArgonBox>
          <ArgonDatePicker
            input={{ placeholder: "Select a date" }}
            onChange={(e) => handleServiceDateSelection(e)}
            enableTime={true}
            value={serviceScheduleDate}
          />
        </Grid>
        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
            <ArgonTypography variant="h5" fontWeight="bold">
              Time
            </ArgonTypography>
          </ArgonBox>
          <ArgonSelect
            options={twentyFourHoursTimeOptions}
            onChange={(e) => handleTimeSeletion(e)}
            value={serviceScheduleTime}
          />
        </Grid>
        {/* <Grid container spacing={3}>
          <ArgonBox lineHeight={0}>
            <Grid item xs={12} sm={6}>
              <ArgonInput
                value={estimatedDurationValue}
                onChange={(e) => setEstimatedDurationValue(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ArgonSelect
                options={intervalOptions}
                value={estimatedDurationInterval}
              />
            </Grid>
          </ArgonBox>
        </Grid> */}
        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
            <ArgonTypography variant="h5" fontWeight="bold">
              Estimated Duration
            </ArgonTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <ArgonInput
                  type="number"
                  value={estimatedDurationValue}
                  onChange={(e) => updateEstimatedDuration(e, "intervalValue")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <ArgonSelect
                  options={intervalOptionsDuration}
                  value={estimatedDurationInterval}
                  onChange={(e) => updateEstimatedDuration(e, "interval")}
                />
              </Grid>
            </Grid>
          </ArgonBox>
        </Grid>
        <Grid item xs={12}>
          <ArgonBox lineHeight={0}>
            {/* <ArgonTypography variant="button" fontWeight="regular" color="text">
              Select the date and time of the service
            </ArgonTypography> */}
            <ArgonTypography variant="h5" fontWeight="bold">
              Repeat
            </ArgonTypography>
            <Switch
              // disabled={repeatEvent && bookingInfo?.id != null}
              checked={repeatEvent}
              onChange={() => {
                setRepeatEvent(!repeatEvent);
                setBookingPayload({
                  ...bookingPayload,
                  repeatEvent: !repeatEvent,
                });
                setUserSelections({
                  ...userSelections,
                  repeatEvent: !repeatEvent,
                });
              }}
            />
          </ArgonBox>
        </Grid>
        {repeatEvent ? (
          <Grid item xs={12}>
            <>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="bold" color="error">
                  {" "}
                  {error}
                </ArgonTypography>

                <ArgonBox mb={2}>
                  <ArgonBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-end"
                    height="100%"
                  >
                    <Grid container spacing={1}>
                      <ArgonBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <ArgonTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          Repeat every
                        </ArgonTypography>
                      </ArgonBox>
                      <Grid item xs={6}>
                        <ArgonBox display="flex">
                          <ArgonBox>
                            <ArgonInput
                              placeholder="eg. 1"
                              type="number"
                              value={repeatValue}
                              onChange={(e) =>
                                handleRecurrenceData(e, "recurrenceValue")
                              }
                            />
                          </ArgonBox>
                          <ArgonBox pl={2}>
                            <ArgonSelect
                              options={intervalOptions}
                              onChange={(e) =>
                                handleRecurrenceData(e, "interval")
                              }
                              value={repeatValueInterval}
                            />
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ArgonBox display="flex">
                          {" "}
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            mt={3}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <ArgonTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                            >
                              Ending on
                            </ArgonTypography>
                          </ArgonBox>
                          <ArgonBox
                            mb={1}
                            ml={0.5}
                            mt={3}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <ArgonBox display="flex">
                              {!recurrenceDoesNotEnd ? (
                                <ArgonBox>
                                  <ArgonDatePicker
                                    input={{ placeholder: "Select a date" }}
                                    onChange={(e) =>
                                      handleRecurrenceData(e, "repeatEndDate")
                                    }
                                    enableTime={true}
                                  />
                                </ArgonBox>
                              ) : (
                                <></>
                              )}
                              <ArgonBox display="flex">
                                <ArgonBox pl={2}>
                                  <Switch
                                    checked={recurrenceDoesNotEnd}
                                    onChange={() => {
                                      setRecurrenceDoesNotEnd(
                                        !recurrenceDoesNotEnd
                                      );
                                      setBookingPayload({
                                        ...bookingPayload,
                                        recurrenceData: {
                                          ...bookingPayload.recurrenceData,
                                          repeatDoesNotEnd:
                                            !recurrenceDoesNotEnd,
                                        },
                                      });
                                      setUserSelections({
                                        ...userSelections,
                                        recurrenceData: {
                                          ...userSelections.recurrenceData,
                                          repeatDoesNotEnd:
                                            !recurrenceDoesNotEnd,
                                        },
                                      });
                                    }}
                                  />
                                </ArgonBox>
                                <ArgonBox pl={1}>
                                  <ArgonTypography
                                    component="label"
                                    variant="caption"
                                    fontWeight="bold"
                                    textTransform="capitalize"
                                  >
                                    Does not end
                                  </ArgonTypography>
                                </ArgonBox>
                              </ArgonBox>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </>
          </Grid>
        ) : null}
      </Grid>
    </ArgonBox>
  );
}

export default DateSelection;
