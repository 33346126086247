import React, { useState,useEffect } from 'react';
import ApplicationContext from './ApplicationContext';
import ApplicationApi from '../api/applicationApi';

export function ApplicationContextProvider(props) {
  
  const [applicationIntegrationTypes, setApplicationIntegrationTypes] = useState(null);
  const [occupancyStatusTypes, setOccupancyStatusTypes] = useState(null);
  const [reportStatusTypes,setReportStatusTypes] = useState(null);
  // useEffect(() => {
  //   // Fetch report status types
  //   applicationApi.getIntegrationTypes()
  //     .then(response => {
  //       // Set the applicationIntegrationTypes state with the fetched data
  //       setApplicationIntegrationTypes(response.data);

  //     })
  //     .catch(error => {
  //       // Handle error if necessary
  //       console.error('Error fetching report status types:', error);
  //     });

  //   // Fetch occupancy status types
  //   applicationApi.getOccupancyStatusTypes()
  //     .then(response => {
  //       // Set the occupancyStatusTypes state with the fetched data
  //       setOccupancyStatusTypes(response.data);
  //     })
  //     .catch(error => {
  //       // Handle error if necessary
  //       console.error('Error fetching occupancy status types:', error);
  //     });

  //     // Fetch report status types
  //   applicationApi.getReportStatusTypes()
  //   .then(response => {
  //     // Set the occupancyStatusTypes state with the fetched data
  //     setReportStatusTypes(response.data);
  //     console.log("Report context",response.data);
  //   })
  //   .catch(error => {
  //     // Handle error if necessary
  //     console.error('Error fetching occupancy status types:', error);
  //   });
  // }, []); // Empty dependency array to execute the effect only once

  const contextValue = {
    applicationIntegrationTypes,
    setApplicationIntegrationTypes,
    occupancyStatusTypes,
    setOccupancyStatusTypes,
    setReportStatusTypes,
    reportStatusTypes
  };

  return (
    <ApplicationContext.Provider value={contextValue}>
      {props.children}
    </ApplicationContext.Provider>
  );
}
