import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ChecklistsApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized(
    "calendarPlus",
    role,
    subscriptionTier
  );

  if (!isAuthorized) {
    return null;
  }

  const getChecklists = (queryParams) => {
    return axiosPrivate.get(`/checklists`, { params: queryParams });
  };

  const createChecklist = (checklistData) => {
    return axiosPrivate.post(`/checklists`, checklistData);
  };

  const getChecklist = (checklistId) => {
    return axiosPrivate.get(`/checklists/${checklistId}`);
  };

  const updateChecklist = (checklistId, checklistData) => {
    return axiosPrivate.put(`/checklists/${checklistId}`, checklistData);
  };

  const updateChecklistStatus = (checklistId, updatedStatus) => {
    return axiosPrivate.put(`/checklists/${checklistId}/${updatedStatus}`);
  };

  const deleteChecklist = (checklistId) => {
    return axiosPrivate.delete(`/checklists/${checklistId}`);
  };

  return {
    getChecklists,
    createChecklist,
    getChecklist,
    updateChecklist,
    deleteChecklist,
    updateChecklistStatus,
  };
}

export default ChecklistsApi;
