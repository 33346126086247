import React, { useState, useEffect } from "react";
// import { TextField, Button, Grid, Paper, Typography } from "@material-ui/core";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// const initialLineItems = [
//   { amount: 300, description: "Service charge for cleaning" },
//   { amount: 30, description: "take out trash monthly" },
// ];
const serviceFeePercentage = 0.05;
const InvoiceLineItemComponent = ({
  setNextDisabled,
  setInvoicePayload,
  invoicePayload,
}) => {
  const [lineItems, setLineItems] = useState(invoicePayload?.items || []);
  const [passServiceFee, setPassServiceFee] = useState(false);
  setNextDisabled(false);
  const [newLineItem, setNewLineItem] = useState({
    amount: "",
    description: "",
  });

  useEffect(() => {
    if (passServiceFee) {
      const totalAmount = lineItems.reduce(
        (acc, curr) => acc + parseFloat(curr.amount || 0),
        0
      );
      const serviceFee = totalAmount * serviceFeePercentage;
      setInvoicePayload({
        ...invoicePayload,
        items: [
          ...lineItems,
          { amount: serviceFee, description: "Service Fee", currency: "usd" },
        ],
      });
    }
  }, [lineItems, passServiceFee]);

  const handleInputChange = (e) => {
    setNewLineItem({
      ...newLineItem,
      [e.target.name]: e.target.value,
      currency: "usd",
    });
  };

  const addLineItem = () => {
    if (newLineItem.amount && newLineItem.description) {
      setLineItems([...lineItems, newLineItem]);
      setNewLineItem({ amount: "", currency: "usd", description: "" }); // Reset form
      setInvoicePayload({
        ...invoicePayload,
        items: [...lineItems, newLineItem],
      });
    }
  };

  const removeLineItem = (index) => {
    const updatedItems = lineItems.filter((_, i) => i !== index);
    setLineItems(updatedItems);
    setInvoicePayload({ ...invoicePayload, items: updatedItems });
  };

  const addServiceFeeLineItem = () => {
    if (passServiceFee) {
      const totalAmount = lineItems.reduce(
        (acc, curr) => acc + parseFloat(curr.amount || 0),
        0
      );
      const serviceFee = totalAmount * serviceFeePercentage;

      return [...lineItems, { amount: serviceFee, description: "Service Fee" }];
    }
    return lineItems;
  };

  const updatedLineItems = addServiceFeeLineItem();

  return (
    <>
      <ArgonTypography variant="h6">Add Invoice Line Item</ArgonTypography>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          <ArgonInput
            label="Amount"
            name="amount"
            value={newLineItem.amount}
            onChange={handleInputChange}
            type="number"
          />
        </Grid>
        <Grid item xs={5}>
          <ArgonInput
            label="Description"
            name="description"
            value={newLineItem.description}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={2}>
          <ArgonButton
            variant="contained"
            color="primary"
            onClick={addLineItem}
            disabled={!newLineItem.amount || !newLineItem.description}
          >
            Add
          </ArgonButton>
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 16 }}>
        {updatedLineItems?.map((item, index) => (
          <Grid
            item
            xs={12}
            key={index}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArgonTypography
              style={{ flexGrow: 1 }}
            >{`$${item.amount} USD - ${item.description}`}</ArgonTypography>
            <IconButton onClick={() => removeLineItem(index)}>
              <DeleteIcon color="secondary" />
            </IconButton>
          </Grid>
        ))}
      </Grid>

      <FormControlLabel
        control={
          <Switch
            checked={passServiceFee}
            onChange={(e) => setPassServiceFee(e.target.checked)}
          />
        }
        label="Pass service fee to customer"
      />

      <Grid container spacing={2} style={{ marginTop: 16 }} justify="flex-end">
        {/* Total Amount */}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ArgonTypography variant="h6">
            Total: $
            {lineItems.reduce(
              (acc, curr) => acc + parseFloat(curr.amount || 0),
              0
            )}{" "}
            USD
          </ArgonTypography>
        </Grid>

        {/* App Fee */}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ArgonTypography variant="h6">
            App Fee: $
            {(
              lineItems.reduce(
                (acc, curr) => acc + parseFloat(curr.amount || 0),
                0
              ) * serviceFeePercentage
            ).toFixed(2)}{" "}
            USD
          </ArgonTypography>
        </Grid>

        {/* Final Payout */}
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ArgonTypography variant="h6">
            Payout: $
            {(passServiceFee
              ? lineItems.reduce(
                  (acc, curr) => acc + parseFloat(curr.amount || 0),
                  0
                )
              : lineItems.reduce(
                  (acc, curr) => acc + parseFloat(curr.amount || 0),
                  0
                ) *
                (1 - serviceFeePercentage)
            ).toFixed(2)}{" "}
            USD
          </ArgonTypography>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ArgonTypography variant="h6">
            Customer Invoice Amount: $
            {(passServiceFee
              ? lineItems.reduce(
                  (acc, curr) => acc + parseFloat(curr.amount || 0),
                  0
                ) +
                lineItems.reduce(
                  (acc, curr) => acc + parseFloat(curr.amount || 0),
                  0
                ) *
                  serviceFeePercentage
              : lineItems.reduce(
                  (acc, curr) => acc + parseFloat(curr.amount || 0),
                  0
                )
            ).toFixed(2)}{" "}
            USD
          </ArgonTypography>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceLineItemComponent;
