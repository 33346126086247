import useAxiosPrivate from "hooks/useAxiosPrivate";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function ChangeRequestsApi() {
  const axiosPrivate = useAxiosPrivate();

  // const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
  //   UserAuth();

  // const isAuthorized = isRouteAuthorized(
  //   "calendarPlus",
  //   role,
  //   subscriptionTier
  // );

  // if (!isAuthorized) {
  //   return null;
  // }

  const getChangeRequests = (queryParams) => {
    return axiosPrivate.get(`/change-requests`, { params: queryParams });
  };

  const createChangeRequest = (changeRequestData) => {
    return axiosPrivate.post(`/change-requests`, changeRequestData);
  };

  const getChangeRequest = (changeRequestId) => {
    return axiosPrivate.get(`/change-requests/${changeRequestId}`);
  };

  const updateChangeRequest = (changeRequestId, changeRequestData) => {
    return axiosPrivate.put(
      `/change-requests/${changeRequestId}`,
      changeRequestData
    );
  };

  ///{change_request_id}/status/{approval}
  const updateChangeRequestApprovalState = (changeRequestId, approval) => {
    return axiosPrivate.put(
      `/change-requests/${changeRequestId}/approved/${approval}`
    );
  };

  const updateChangeRequestApprovalStateBulk = (payload) => {
    return axiosPrivate.put(`/change-requests/approved/bulk`, payload);
  };

  const cancelChangeRequest = (changeRequestId) => {
    return axiosPrivate.put(
      `/change-requests/${changeRequestId}/status/cancel`
    );
  };

  const updateChangeRequestStatusCancelBulk = (payload) => {
    return axiosPrivate.put(`/change-requests/status/cancel/bulk`, payload);
  };

  //   const updateChangeRequestStatus = (changeRequestId, updatedStatus) => {
  //     return axiosPrivate.put(
  //       `/change-requests/${changeRequestId}/${updatedStatus}`
  //     );
  //   };

  ///dashboard/counts/all

  const approveOrDeclineChangeRequest = (changeRequestId, approvalStatus) => {
    return axiosPrivate.put(
      `/change-requests/${changeRequestId}/approved/${approvalStatus}`
    );
  };

  //   const deleteChangeRequest = (changeRequestId) => {
  //     return axiosPrivate.delete(`/change-requests/${changeRequestId}`);
  //   };

  return {
    getChangeRequests,
    createChangeRequest,
    getChangeRequest,
    updateChangeRequest,
    updateChangeRequestApprovalState,
    updateChangeRequestApprovalStateBulk,
    cancelChangeRequest,
    updateChangeRequestStatusCancelBulk,
    // deleteChangeRequest,
    // updateChangeRequestStatus,
  };
}

export default ChangeRequestsApi;
