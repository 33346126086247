import { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import HostHiveKbHelp from "components/HostHiveKbHelp";

const DisplayEntry = ({
  entryKey,
  displayValue,
  kbHelpUrl = null,
  kbHelpText = null,
  lookupKey,
}) => {
  return (
    <ArgonBox
      justifyContent="space-between"
      flexDirection={{ xs: "column", sm: "row" }}
      p={1}
    >
      <ArgonBox>
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}:
        </ArgonTypography>
        {lookupKey ? (
          <HostHiveKbHelp
            // helpUrl={kbHelpUrl}
            marginBottom={0}
            // toolTipText={kbHelpText}
            lookupKey={lookupKey}
          />
        ) : null}
      </ArgonBox>
      <ArgonBox>
        <ArgonTypography variant="button" color="text" fontWeight="regular">
          {displayValue}
        </ArgonTypography>
      </ArgonBox>
    </ArgonBox>
  );
};

export default DisplayEntry;
