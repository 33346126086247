/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import FormField from "layouts/management/users/new-user/components/FormField";
import {
  Card,
  Grid,
  Tooltip,
  Icon,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import ArgonAlert from "components/ArgonAlert";

import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";
import UserSchema from "schemas/userSchema";
import HostHiveLoading from "components/HostHiveLoading";
import * as Yup from "yup";
import { useFormik } from "formik";
import HostHiveErrorMessage from "components/HostHiveErrorMessage";
import { set } from "lodash";
import UsersApi from "api/usersApi";
import IamApi from "api/iamApi";
import { useTranslation } from "react-i18next";
function UserDialog({
  user = null,
  setUser,
  handleSubmitClicked,
  handleClose,
  setRefreshList = null,
  snackBarOpen = null,
  isCreation = false,
  ownUser = false,
}) {
  const { t } = useTranslation("common");
  const usersApi = new UsersApi();
  const iamApi = new IamApi();
  const userCompanyDetails = JSON.parse(localStorage.getItem("companyDetails"));
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState("Some error occurred");
  const [alertColor, setAlertColor] = useState("warning");
  const onSubmit = async (values, actions) => {
    setIsSaving(true);
    try {
      if (user === null) {
        console.log("creating user", values);
        const params = {
          joinCompanyNotification: true,
        };
        const response = await usersApi.createUser(values, params);
        if (response.status >= 200 && response.status < 300) {
          // navigate(`/management/users/${response.data.id}`);
          // Add roleName to iAM membership
          const membershipData = {
            userId: response.data.id,
            roleName: userRoleName.value,
            companyId: values?.companyId,
            status: "active",
          };
          const membershipResponse = await iamApi.createMembership(
            membershipData
          );
          if (
            membershipResponse.status >= 200 &&
            membershipResponse.status < 300
          ) {
            setRefreshList();
            handleClose();
          }
        }
      } else {
        console.log("Updating user", user);
        const response = await usersApi.updateUser(user.id, values);
        console.log("User update response", response);
        // update roleName in iAM membership
        const membershipData = {
          userId: user.id,
          roleName: userRoleName?.value,
          companyId: values?.companyId,
          status: "active",
        };
        const membershipResponse = await iamApi.updateUserMembership(
          user.id,
          membershipData
        );

        console.log("Membership update response", membershipResponse);
      }
    } catch (error) {
      setIsSaving(false);
      setAlertColor("error");

      setAlertMessage(error.message);
      setShowAlert(true);

      console.error("Error:", error);
    }
    if (user === null) {
      if (snackBarOpen) {
        snackBarOpen(
          `User Added`,
          `Successfully added user ${values.firstName} ${values.lastName}`
        );
      }
    } else {
      if (snackBarOpen) {
        snackBarOpen(
          `User Updated`,
          `Successfully updated user ${values.firstName} ${values.lastName}`
        );
      }
    }

    actions.resetForm();
    if (setRefreshList) {
      setRefreshList();
    }

    handleClose();
  };

  const {
    values,
    errors,
    touched,
    isSubmitting,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      companyId: userCompanyDetails?.id || null,
      companyName: userCompanyDetails?.name || null,
      phoneNumber: user?.phoneNumber || "",
      status: user?.status || "active",
      // Add more fields here
    },
    validationSchema: UserSchema,
    onSubmit,
  });

  const DisplayEntry = ({ entryKey }) => {
    return (
      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
        <ArgonTypography
          component="label"
          variant="caption"
          fontWeight="bold"
          textTransform="capitalize"
        >
          {entryKey}
        </ArgonTypography>
      </ArgonBox>
    );
  };
  const roleOptions = [
    {
      value: "admin",
      label: t("components.users.team.roles.admin"),
    },
    {
      value: "supervisor",
      label: t("components.users.team.roles.supervisor"),
    },
    {
      value: "manager",
      label: t("components.users.team.roles.manager"),
    },
    {
      value: "service_associate",
      label: t("components.users.team.roles.service_associate"),
    },
    {
      value: "readOnly",
      label: t("components.users.team.roles.read_only"),
    },
  ];

  const [userRoleName, setUserRoleName] = useState(
    {
      value: user?.applicationRole?.roleName,
      label: user?.applicationRole?.displayName,
    } || ""
  );
  const handleDeleteUser = async () => {
    try {
      const response = await usersApi.deleteUser(user?.id);
    } catch {
      console.log("Error deleting user");
    } finally {
      setRefreshList();
      handleClose();
      // setUserDialogOpen(false);
      // handleCloseMenu();
    }
  };
  return (
    <>
      {isSaving ? (
        <>
          <ArgonBox display="flex">
            <ArgonTypography variant="caption">Saving User</ArgonTypography>
            <HostHiveLoading />
          </ArgonBox>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          <>
            {showAlert ? (
              <ArgonAlert color={alertColor} dismissible>
                <ArgonBox display="flex" justifyContent="flex-end">
                  <ArgonBox>
                    <ArgonTypography variant="caption" color="white">
                      {alertMessage}
                    </ArgonTypography>
                  </ArgonBox>
                </ArgonBox>
              </ArgonAlert>
            ) : null}

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("general.emailAddress")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      disabled={!isCreation && !ownUser}
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </ArgonBox>
                  {errors.email && touched.email && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.email} />
                  )}
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("general.person.firstName")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      disabled={!isCreation && !ownUser}
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.firstName && Boolean(errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </ArgonBox>
                  {errors.firstName && touched.firstName && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.firstName} />
                  )}
                </ArgonBox>
              </Grid>

              <Grid item xs={12} sm={6}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("general.person.lastName")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      disabled={!isCreation && !ownUser}
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.lastName && Boolean(errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </ArgonBox>
                  {errors.lastName && touched.lastName && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.lastName} />
                  )}
                </ArgonBox>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ArgonBox>
                  <DisplayEntry entryKey={t("general.phoneNumber")} />
                  <ArgonBox display="flex">
                    <ArgonInput
                      disabled={!isCreation && !ownUser}
                      name="phoneNumber"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      // Add error handling from Formik state
                      error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                    />
                  </ArgonBox>
                  {errors.phoneNumber && touched.phoneNumber && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.phoneNumber} />
                  )}
                </ArgonBox>
              </Grid>

              {ownUser ? null : (
                <>
                  <Grid item xs={12}>
                    <ArgonBox>
                      <DisplayEntry entryKey={t("general.person.roleName")} />
                      <ArgonBox display="flex">
                        <ArgonSelect
                          defaultValue={{
                            value: "none",
                            label: t("components.users.team.selectARole"),
                          }}
                          options={roleOptions}
                          value={userRoleName}
                          onChange={(e) => {
                            setUserRoleName(e);
                          }}
                        />
                      </ArgonBox>
                      {/* {errors.lastName && touched.lastName && (
                    // <p className="error">{errors.name}</p>
                    <HostHiveErrorMessage message={errors.lastName} />
                  )} */}
                    </ArgonBox>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider />

            <ArgonBox justifyContent="center" display="flex" p={2}>
              <ArgonBox display="flex">
                <ArgonBox pr={2}>
                  <ArgonButton
                    variant="gradient"
                    color="dark"
                    onClick={handleClose}
                  >
                    {t("buttons.cancel")}
                  </ArgonButton>
                </ArgonBox>
                {ownUser || isCreation ? null : (
                  <ArgonBox pr={2}>
                    <ArgonButton
                      variant="gradient"
                      color="error"
                      onClick={handleDeleteUser}
                      // disabled={user?.status === "inactive"}
                    >
                      Delete
                    </ArgonButton>
                  </ArgonBox>
                )}
                <ArgonBox pr={2}>
                  <ArgonButton variant="gradient" color="info" type="submit">
                    {t("buttons.save")}
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </>
        </form>
      )}
    </>
  );
}

export default UserDialog;
