/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import HostHiveDialog from "components/HostHiveDialog";
// Settings page components
import FormField from "layouts/pages/account/components/FormField";

import PropertyManagementItem from "./components/PropertyManagementItem";
import HostHiveLoading from "components/HostHiveLoading";
import CompanyApi from "api/companyApi";
import UsersApi from "api/usersApi";
import { useParams } from "react-router-dom";
import DynamicSettings from "layouts/management/components/DynamicSettings";
import SettingsApi from "api/settingsApi";
import HostHiveSnackbar from "components/HostHiveSnackbar";

function PropertyManagementSettings({}) {
  const { renderSuccessSB, openSuccessSB } = HostHiveSnackbar();
  const companyApi = CompanyApi();
  const usersApi = UsersApi();
  const settingsApi = new SettingsApi();
  const [propertySettings, setPropertySettings] = useState([]);
  const [myUsers, setMyUsers] = useState([]);
  const [servicePartner, setServicePartner] = useState({});
  const [servicePartnerDialogOpen, setServiceNameDialogOpen] = useState(false);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [
    propertyManagementSettingsLoading,
    setPropertyManagementSettingsLoading,
  ] = useState(true);
  const [propertySettingsUpdated, setPropertySettingsUpdated] = useState(false);
  useEffect(() => {
    async function fetchPropertySettings() {
      try {
        const [propertyManagementResponse, usersResponse] = await Promise.all([
          settingsApi.getPropertyManagementSetting("properties", company?.id, {
            forUi: true,
          }),
          usersApi.getUsers({
            companyId: company?.id,
            returnAsOptions: true,
          }),
        ]);

        setPropertySettings(propertyManagementResponse?.data, []);
        setMyUsers(usersResponse?.data, []);
      } catch (error) {
        console.error(error);
      } finally {
        setPropertyManagementSettingsLoading(false);
      }
    }

    fetchPropertySettings();
  }, [propertySettingsUpdated]);

  const handleSubmitClicked = () => {
    setServiceNameDialogOpen(false);
  };

  const handleCancelClicked = () => {
    setServiceNameDialogOpen(false);
  };
  return (
    <Card
      id="property-management-settings"
      sx={{
        boxShadow: ({ boxShadows: { md } }) => md,
      }}
    >
      {renderSuccessSB}
      <ArgonBox p={3}>
        <ArgonBox display="flex" justifyContent="space-between" mb={3}>
          <ArgonBox>
            <ArgonTypography variant="h5">
              Team Property Assignements
            </ArgonTypography>
          </ArgonBox>
        </ArgonBox>
        {propertyManagementSettingsLoading ? (
          <HostHiveLoading />
        ) : (
          <ArgonBox
            p={2}
            mt={1}
            width="100%"
            // height={propertySettings.length > 1 ? "26.25rem" : "auto"}
            height="auto"
            style={{ overflow: "auto" }}
          >
            {propertySettings?.length > 0 ? (
              <>
                {propertySettings.map((propertySetting, key) => (
                  <ArgonBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                    key={propertySetting?.id}
                  >
                    <PropertyManagementItem
                      assigneeServicePartnerId={company?.id}
                      companyId={propertySetting?.propertyCompanyId}
                      propertySetting={propertySetting}
                      users={myUsers}
                      snackBarOpen={openSuccessSB}
                      fetchPropertySettings={() =>
                        setPropertySettingsUpdated(!propertySettingsUpdated)
                      }
                    />
                  </ArgonBox>
                ))}
              </>
            ) : (
              "Properties where your company is the Default Service Provider will appear here."
            )}
          </ArgonBox>
        )}
      </ArgonBox>
      <ArgonBox component="form" pb={3} px={3}></ArgonBox>
    </Card>
  );
}

export default PropertyManagementSettings;
