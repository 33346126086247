import React, { useEffect, useState, useMemo } from "react";
import FilesApi from "api/filesApi";
import PropertiesApi from "api/propertiesApi";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ArgonInput from "components/ArgonInput";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import PropertySelect from "../PropertySelect";
import ImportMatchWizard from "layouts/management/components/ImportMatchWizard";
import HostHiveDialog from "components/HostHiveDialog";
import ArgonDatePicker from "components/ArgonDatePicker";
function TestFileUpload({}) {
  const [file, setFile] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const filesApi = FilesApi();
  const propertiesApi = new PropertiesApi();
  const [uploading, setUploading] = useState(false);
  const [matchedListing, setMatchedListing] = useState(null);
  const [fileProperties, setFileProperties] = useState([]);
  const [propertyAppMatch, setPropertyAppMatch] = useState({});
  const [propertySelected, setPropertySelected] = useState(null);
  const [properties, setProperties] = useState([]);
  const memoizedProperties = useMemo(() => properties, [properties]);
  const MemoizedArgonSelect = React.memo(ArgonSelect);
  const company = JSON.parse(localStorage.getItem("companyDetails"));
  const [openWizard, setOpenWizard] = useState(false);
  const [testDate, setTestDate] = useState(null);
  const handleSetStartDate = (date, name) => {
    setTestDate(date);
    console.log(date);
    console.log(date[0].toDateString());
    console.log(date[0].toISOString());
    console.log(date[0].toLocaleDateString());
    console.log(date[0].toDateString());

    let year = date[0].getFullYear();
    let month = date[0].getMonth() + 1;
    let day = date[0].getDate();

    let startDate = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
    console.log(startDate);
  };
  useEffect(() => {
    async function fetchProperties() {
      try {
        const queryParams = {
          companyId: company?.id,
          ownerOnly: true,
        };

        const response = await propertiesApi.getProperties(queryParams);

        const options = response.data.map((property) => ({
          value: property.id,
          label: property.name,
        }));

        setProperties(options);
      } catch (error) {
        console.error(error);
      }
    }

    fetchProperties();
  }, []);

  const handleFileUpload = async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      if (!file) {
        console.log("No file selected");
      }
      formData.append("file", file);

      const response = await filesApi.uploadFile(formData, uploadType);
      setUploadResponse(response.data.aggregatedData);
      setFileProperties(response.data.properties);
      console.log(response);
    } catch (error) {
      setUploadError(error);
    }
    setUploading(false);
  };

  const uploadHandler = async (event) => {
    const files = event.target.files;
    const file = files[0];
    setUploadType("importTransactionMatch");
    setFile(file);
  };

  const [searchAmount, setSearchAmount] = useState(null);
  const findAmount = async () => {
    const amountStr = searchAmount.toString();
    let aggregatedData = uploadResponse;
    // Initialize an array to hold all the potential matches
    const matches = [];

    // Iterate over the aggregated data to find matches
    aggregatedData.forEach((listing) => {
      // Check if the amount is in the listing's amounts
      if (listing.amounts.includes(amountStr)) {
        // If it is, add the listing details to the matches array
        matches.push({
          listing: listing.listing,
          listingId: listing.listingId,
        });
      }
    });
    console.log(matches);
    if (matches.length === 0) {
      console.log("No matches found");
    } else if (matches.length === 1) {
      console.log("One match found");
      setMatchedListing(matches[0]);
    } else {
      console.log("Multiple matches found");
    }
  };

  const handlePropertyMatch = (e, property) => {
    setPropertyAppMatch((prev) => ({
      ...prev,
      [property.value]: e,
    }));

    // Remove the e.value from memoizedProperties
    const updatedProperties = memoizedProperties.filter(
      (prop) => prop.value !== e.value
    );

    setProperties(updatedProperties);
  };
  const [bulkChangePayloadMapping, setBulkChangePayloadMapping] = useState({});
  return (
    <DashboardLayout>
      <HostHiveDialog
        open={openWizard}
        onClose={() => setOpenWizard(false)}
        // onSave={() => handleBulkChangeCategory()}
        includeSave={false}
        includeClose={true}
        title="Import Match Wizard"
        fullScreen={false}
        maxWidth="lg"
        cancelButtonText="Cancel"
        submitButtonText="Apply"
        dialogObjects={
          <>
            <ImportMatchWizard
              bulkChangePayloadMapping={bulkChangePayloadMapping}
              setBulkChangePayloadMapping={setBulkChangePayloadMapping}
            />
          </>
        }
      />
      <ArgonBox mt={3} mb={4}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9}>
            <Card sx={{ overflow: "visible" }}>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="caption" fontWeight="bold">
                  FROM
                </ArgonTypography>
                <ArgonDatePicker
                  input={{ placeholder: "Select a date" }}
                  onChange={(e) => handleSetStartDate(e, "startDate")}
                />
              </ArgonBox>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonInput
                  placeholder="Upload Type"
                  type="file"
                  onChange={uploadHandler}
                />
              </ArgonBox>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={() => handleFileUpload()}
                  disabled={uploading || file === null}
                >
                  Upload
                </ArgonButton>
              </ArgonBox>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonInput
                  placeholder="Enter amount to find"
                  value={searchAmount}
                  type="number"
                  disabled={uploadResponse === null}
                  onChange={(e) => setSearchAmount(e.target.value)}
                />
              </ArgonBox>
              <ArgonBox p={2} lineHeight={1}>
                <ArgonButton
                  variant="gradient"
                  color="dark"
                  onClick={findAmount}
                  disabled={uploadResponse === null}
                >
                  Search
                </ArgonButton>
              </ArgonBox>
              {/* Loop through file properties and display property.label */}
              {fileProperties.map((property) => {
                return (
                  <ArgonBox p={2} lineHeight={1}>
                    <ArgonTypography variant="h5" fontWeight="medium">
                      {property.label}
                    </ArgonTypography>
                    <MemoizedArgonSelect
                      label="Property"
                      value={propertyAppMatch[property.value]}
                      onChange={(e) => handlePropertyMatch(e, property)}
                      options={memoizedProperties}
                      fullWidth
                    />
                  </ArgonBox>
                );
              })}
              <ArgonBox p={2} lineHeight={1}>
                <ArgonTypography variant="h5" fontWeight="medium">
                  Airbnb name:{" "}
                  {matchedListing
                    ? matchedListing.listing
                    : "No listing Matched"}
                </ArgonTypography>
                <ArgonTypography variant="h5" fontWeight="medium">
                  HostHive Name:{" "}
                  {matchedListing
                    ? propertyAppMatch[matchedListing.listingId]?.label
                    : "No App listing Matched"}
                </ArgonTypography>
              </ArgonBox>
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default TestFileUpload;
