import useAxiosPrivate from "hooks/useAxiosPrivate";
import ArgonSnackbar from "components/ArgonSnackbar";
import { useEffect, useState } from "react";
import { UserAuth } from "context/AuthContext";
import { isRouteAuthorized } from "services/checkRouteProjection";
function FilesApi() {
  const axiosPrivate = useAxiosPrivate();

  const { role, hasRole, hasOneOfRoles, subscriptionTier, user, pending } =
    UserAuth();

  const isAuthorized = isRouteAuthorized(
    "propertiesPlus",
    role,
    subscriptionTier
  );

  if (!isAuthorized) {
    return null;
  }

  const uploadFile = (file, uploadType) => {
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    return axiosPrivate.post(`/files?uploadType=${uploadType}`, file, {
      headers,
    });
  };

  return {
    uploadFile,
  };
}

export default FilesApi;
