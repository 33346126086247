const knowledgeBaseObject = {
  clearIntegrationAppointments: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#clear-integration-appointments",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Delete all scheduled appointments for this integration.",
  },
  comments: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Add any special instructions or remarks.",
  },
  properties: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/overview",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "View all of your properties, either that you own or manage.",
  },
  subscriptionBilling: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/company-settings/subscription-and-billing",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Manage your company's subscription and billing settings.",
  },
  inventory: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/inventory",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Track and manage your property's inventory.",
  },
  calendar: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/calendar",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Manage the service appointments you have scheduled.",
  },
  serviceReports: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/service-reports",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Recieve payments for services rendered, and track the status of your invoices.",
  },
  serviceInvoicing: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/service-invoicing",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Request payment for services rendered, and track the status of your invoices.",
  },
  companySettings: {
    kbHelpUrl: "https://docs.hosthive.io/en/company-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Manage your company settings, including subscriptions, service partners, and more.",
  },
  // https://docs.hosthive.io/en/apps/service-reports
  tasks: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/tasks",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Manage property tasks and to-do lists.",
  },
  finance: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/finance",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Track and manage your all your property finances.",
  },
  timeTracking: {
    kbHelpUrl: "https://docs.hosthive.io/en/apps/timeTracking",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Log time against properties, tasks and appointments to keep a record of time spent where.",
  },
  propertySelection: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip: "Select the property requiring the service.",
  },
  categorySelection: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Select the category this is associated with. Categories can be color coded and will be used for analytics.",
  },
  serviceAppointmentAssignee: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/company-settings/service-partners/adding-new",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Choose the business partner responsible for servicing this integration. Toggle on to choose assignee manually.",
  },
  serviceAppointmentDateAndTime: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "The Property Integration Settings enables you to configure and manage how your property integrates with various external platforms and service providers.",
  },
  estimatedDuration: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "The Property Integration Settings enables you to configure and manage how your property integrates with various external platforms and service providers.",
  },
  serviceAssigmentEditCreate: {
    kbHelpUrl: "https://docs.hosthive.io/en/service-assignments",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Create or edit a service assignment. Service assignments are used to assign a service to a property, and to schedule the service.",
  },
  propertyIntegrationSettings: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#how-to-configure-integration-settings",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "The Property Integration Settings enables you to configure and manage how your property integrates with various external platforms and service providers.",
  },
  integrationType: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#integration-type",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Select the external platform for property management and scheduling.",
  },
  calendarSyncEnabled: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#calendar-sync-enabled",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Turn on/off the automatic syncing of your property's calendar with external integrations.",
  },
  integrationUrl: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#integration-url",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Enter the web address for the desired integration. Click 'Test' to verify connectivity.",
  },
  servicePartner: {
    kbHelpUrl:
      "https://docs.hosthive.io/en/properties/overview#service-partner",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Choose the business partner responsible for servicing this integration.",
  },
  serviceName: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/overview#service-name",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Descriptive labels for services, providing quick insight into scheduled tasks.",
  },
  checklist: {
    kbHelpUrl: "https://docs.hosthive.io/en/properties/overview#checklist",
    kbHelpText: "This is the help text for the service",
    kbHelpToolTip:
      "Link a detailed task list for the service partner to follow.",
  },
  // ... add other services as needed
};

export const KbHelper = (lookUpKey) => {
  // Look up the service info using the key provided
  return knowledgeBaseObject[lookUpKey];
};
