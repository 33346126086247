import useAxiosPrivate from "hooks/useAxiosPrivate";

function DashboardApi() {
  const axiosPrivate = useAxiosPrivate();

  const getDashboard = (queryParams) => {
    return axiosPrivate.get(`/dashboard`, { params: queryParams });
  };

  return {
    getDashboard,
  };
}

export default DashboardApi;
